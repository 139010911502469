import { useEffect, useState } from 'react';
import { Data } from 'plotly.js';

import { LP_OSS_1M } from '../OpenOfficeParameters/constants';
import { NC_CURVE_OPTIONS, PARAMETERS_WITH_TRENDLINES, SPL_OF_SPEECH_AT_1_METER } from './constants';

import { calculateDistance, linearRegression } from '../OpenOfficeParameters/utils';
import { calculateAWeightedSpl } from './utils';

import { ParsedParameterData } from '../ParameterResults/types';
import { PlotType, ReceiverData } from './types';

export const useSpatialDecayPlot = (
  parameterData: ParsedParameterData[],
  selectedPlotType: PlotType,
  selectedParameter: string,
  selectedFrequency: string,
  seletedNc: string
) => {
  const [plotlyData, setPlotlyData] = useState<Data[]>([]);

  useEffect(() => {
    if (selectedPlotType == 'Spatial decay' && parameterData.filter((x) => !x.isSummedSource).length > 0) {
      const plotlyData = parameterData.reduce<Data[]>((acc, current) => {
        if (current.resultParametersForReceievers.length) {
          // Map to a custom object where each receiver contains their respective result parameters as well as the calculated distance from the source
          let receiversData: ReceiverData[] = current.resultParametersForReceievers.map((receiverParameters, i) => ({
            resultParameters: receiverParameters,
            distanceFromSource: calculateDistance(current.sourcePosition, current.receiverDetails[i].position),
            label: current.receiverDetails[i].label,
          }));

          // Lets order the receivers by distance from the source, ascending
          receiversData = receiversData.sort((a, b) => a.distanceFromSource - b.distanceFromSource);

          // Calculate x and y for the plot
          const markerData = receiversData.reduce<{ x: number[]; y: number[]; labels: string[] }>(
            (acc, curr) => {
              let valueIndex: number | null = null;
              if (selectedParameter === 'sti') {
                valueIndex = NC_CURVE_OPTIONS.indexOf(seletedNc);
              } else {
                valueIndex = current.frequencies.indexOf(Number(selectedFrequency));
              }

              // We need to calculate the A weighted SPL and A weighted Speech since these results are not coming from the backend
              if (
                selectedParameter === 'spl' &&
                ['aWeighted', 'aWeightedSpeech'].includes(selectedFrequency) &&
                curr.resultParameters['spl']?.length
              ) {
                if (selectedFrequency === 'aWeighted') {
                  // Calculate A-weighted SPL
                  const aWeightedSpl = calculateAWeightedSpl(curr.resultParameters['spl']);
                  return {
                    x: [...acc.x, curr.distanceFromSource],
                    y: [...acc.y, aWeightedSpl],
                    labels: [...acc.labels, curr.label],
                  };
                } else if (selectedFrequency === 'aWeightedSpeech') {
                  // Calculate the difference between the sound power level of the source and the sound pressure level at the receiver at 1m
                  // TODO: Check if we need to change the LP_OSS_1M from being a constant and read it from the source
                  const attenuation = curr.resultParameters['spl'].map((x) => LP_OSS_1M - x);
                  const splSpeech = SPL_OF_SPEECH_AT_1_METER.map((x, i) => x - attenuation[i]);
                  const aWeightedSplSpeech = calculateAWeightedSpl(splSpeech);
                  return {
                    x: [...acc.x, curr.distanceFromSource],
                    y: [...acc.y, aWeightedSplSpeech],
                    labels: [...acc.labels, curr.label],
                  };
                } else {
                  return acc;
                }
              } else if (
                valueIndex !== null &&
                valueIndex > -1 &&
                curr.resultParameters[selectedParameter]?.length &&
                !isNaN(curr.resultParameters[selectedParameter][valueIndex])
              ) {
                return {
                  x: [...acc.x, curr.distanceFromSource],
                  y: [...acc.y, curr.resultParameters[selectedParameter][valueIndex]],
                  labels: [...acc.labels, curr.label],
                };
              } else {
                return acc;
              }
            },
            { x: [], y: [], labels: [] }
          );

          const plotlyMarkerData: Data = {
            marker: current.marker,
            y: markerData.y,
            x: markerData.x,
            type: 'scatter',
            mode: 'text+markers',
            name: current.name,
            text: markerData.labels,
            textposition: 'top center',
          };

          // For certain parameters we add a trendline
          if (PARAMETERS_WITH_TRENDLINES.includes(selectedParameter)) {
            const isLogarithmic = selectedParameter === 'spl';

            // It depends on the parameter if the x axis is logarithmic or linear
            const { slope, intercept } = linearRegression(
              isLogarithmic ? markerData.x.map((x) => Math.log10(x)) : markerData.x,
              markerData.y
            );

            // Due to the fact we might have a logarightmic x axis we do not start from zero, 1000 is an arbitrary number just to keep the line long "enough"
            const lineX = [0.001, 1000];

            const plotlyTrendlineData: Data = {
              marker: current.marker,
              y: isLogarithmic
                ? lineX.map((x) => slope * Math.log10(x) + intercept)
                : lineX.map((x) => slope * x + intercept),
              x: lineX,
              type: 'scatter',
              mode: 'lines',
              name: current.name,
            };

            return [...acc, plotlyMarkerData, plotlyTrendlineData];
          } else {
            return [...acc, plotlyMarkerData];
          }
        }

        return acc;
      }, []);

      setPlotlyData(plotlyData);
    } else {
      setPlotlyData([]);
    }
  }, [parameterData, selectedPlotType, selectedParameter, selectedFrequency, seletedNc]);

  return plotlyData;
};
