import { FC, useState } from 'react';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';

type SelectCellProps = {
  value: string;
  menuItems: SelectOption[];
  disabled?: boolean;
  onSelect: (value: string) => void;
  confirmationText: string;
};

export const SelectCell: FC<SelectCellProps> = ({ onSelect, value, menuItems, disabled, confirmationText }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    setShowConfirmation(true);
  };

  return (
    <>
      <div style={{ marginLeft: '-9px' }}>
        <TrblSelect
          disabled={disabled}
          value={value}
          setValue={handleSelect}
          menuItems={menuItems}
          minimal
          style={{
            height: '26px',
            lineHeight: 2,
            backgroundColor: 'transparent',
          }}
        />
      </div>

      {showConfirmation && (
        <ConfirmationDialog
          title="Confirm change "
          message={confirmationText}
          onConfirm={() => {
            onSelect(selectedValue);
            setShowConfirmation(false);
          }}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};
