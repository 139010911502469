import { config } from '@/__config__/config';

import { Layout, SubscriptionParams, TokenParams } from '../types';

import axios from '@/axios';

const { adminApiUrl } = config;

export const getCheckoutOptions = (
  checkoutType: 'Subscribe' | 'Update' | 'TokensVolume',
  params: SubscriptionParams | TokenParams,
  onSuccess?: () => void
) => ({
  hostedPage: async () => {
    const { data } = await axios.post(`/api/Checkout/${checkoutType}`, params, {
      baseURL: adminApiUrl,
    });
    console.log('data', data);
    return data.hosted_page;
  },
  layout: Layout.IN_APP, // Or: FULL_PAGE
  loaded: () => {
    console.log('Checkout loaded');
  },
  error: () => {
    console.log('Checkout error');
  },
  success: (hostedPageId: string) => {
    console.log('Checkout success', hostedPageId);
    onSuccess && onSuccess();
  },
  close: () => {
    console.log('Checkout close');
  },
  step: (currentStep: string) => {
    console.log('Checkout step', currentStep);
  },
});
