import styles from './styles.module.scss';

type FilterTagProps = {
  tag: string;
  selectTag?: (tag: string, event: any) => void;
  isSelected?: boolean;
};

export const FilterTag = ({ tag, selectTag, isSelected }: FilterTagProps) => {
  return (
    <>
      {selectTag ? (
        <button
          className={`${styles['tag']} ${isSelected ? styles['selected'] : ''} `}
          onClick={(event) => selectTag(tag, event)}>
          <span>{tag}</span>
        </button>
      ) : (
        <span className={`${styles['tag']} ${styles['readonly']}`}> {tag}</span>
      )}
    </>
  );
};
