import { ComplexData } from '../types';

export const getFirstValuesForKeys = (input: ComplexData, keysToFilter: number[]): Record<number, number> => {
  const realValues: Record<number, number> = {}; // Initialize an empty object to hold the real values

  keysToFilter.forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(key)) {
      realValues[key] = input[key].real; // Add the key and its real value to the object
    }
  });

  return realValues; // Return the object of real values
};
