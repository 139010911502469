export const getScatterText = (scatterValues: number[] | null) => {
  const numberOfValues = scatterValues?.length;

  if (numberOfValues) {
    if (scatterValues.length === 8) {
      return scatterValues.join(', ');
    } else {
      return scatterValues[0].toString();
    }
  } else {
    return '';
  }
};
