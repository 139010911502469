import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { ActionType as ResultsActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { ResultComparison } from '@/components/Results/types';
import { useCreateResultPreset } from './useCreateResultPreset';
import { useDeleteResultPreset } from './useDeleteResultPreset';
import { useUpdateResultPreset } from './useUpdateResultPreset';

import { ResultPresetDto, UpdateResultSimulation } from '../types';

export const useResultPresetActions = () => {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { availableComparisons, selectedPreset, dispatch: resultsDispatch } = useResultsContext();
  const { dispatch: simDispatch } = useSimulationContext();

  const { mutate: updateResultPreset } = useUpdateResultPreset();
  const { mutate: createResultPreset } = useCreateResultPreset();
  const { mutate: deleteResultPreset } = useDeleteResultPreset();

  const onReset = () => {
    // go through the comparisons, select the first one and remove the other ones
    availableComparisons.forEach((comparison, index) => {
      if (index == 0 && comparison.formState?.selectedSimulation) {
        setSearchParams({
          mid: comparison.formState.selectedSimulation.modelId,
          sid: comparison.formState.selectedSimulation.id,
        });
      }
      if (index > 0) {
        resultsDispatch({ type: ResultsActionType.REMOVE_COMPARISON, color: comparison.color });
      }
    });
  };

  const onLoadResultPreset = (resultsId: string) => {
    setSearchParams({ resultsId });
  };

  const onSaveResultPreset = async (saveResultsName: string) => {
    return new Promise((resolve) => {
      if (availableComparisons.length > 0) {
        const { resultSimulations, projectId } = populateResultPreset(availableComparisons);

        createResultPreset(
          {
            name: saveResultsName,
            description: '',
            projectId: projectId,
            resultSimulations: resultSimulations,
          },
          {
            onSuccess: (savedResultPreset) => {
              resolve(true);
              setSearchParams({ resultsId: savedResultPreset.id });
              queryClient.invalidateQueries(['result-presets-by-project-id', projectId]);
            },
          }
        );
      } else {
        resolve(false);
      }
    });
  };

  const onUpdateResultPreset = () => {
    if (selectedPreset) {
      const { resultSimulations, projectId } = populateResultPreset(availableComparisons);

      updateResultPreset({
        id: selectedPreset.id,
        name: selectedPreset.name,
        description: selectedPreset.description,
        projectId: projectId,
        resultSimulations: resultSimulations,
      });
      resultsDispatch({
        type: ResultsActionType.SET_PRESET_EDITED,
        presetEdited: false,
      });
    }
  };

  const populateResultPreset = (resultComparisons: ResultComparison[]) => {
    const resultSimulations: UpdateResultSimulation[] = [];
    let projectId = '';
    resultComparisons.forEach((comparison, index) => {
      if (comparison.formState) {
        const state = comparison.formState;

        let gridReceiverPointIds = [];
        if (state.gridReceiverPointIds) {
          gridReceiverPointIds = state.gridReceiverPointIds;
        } else {
          // If gridReceiverPointIds is undefined, we default to them all selected
          gridReceiverPointIds = state.selectedSimulation?.gridReceivers?.map((x) => x.id) ?? [];
        }

        resultSimulations.push({
          simulationId: state.simulationId,
          title: state.title,
          resultTypeSelected: state.resultType,
          sourcesSelected: state.sourcePointIds ?? [],
          receiversSelected: state.receiverPointIds || [],
          surfaceReceiversSelected: gridReceiverPointIds,
          orderNumber: index,
          color: comparison.color,
        });
        if (index == 0) projectId = state.projectId || '';
      }
    });

    return { resultSimulations, projectId };
  };

  const onUpdateResultPresetDetails = async (newName: string, newDescription: string) => {
    return new Promise((resolve) => {
      if (selectedPreset) {
        updateResultPreset(
          {
            ...selectedPreset,
            name: newName ?? selectedPreset.name,
            description: newDescription ?? selectedPreset.description,
          },
          {
            onSuccess: (data) => {
              updateSelectedResultPreset(data);
              resolve(true);
            },
            onError: () => {
              resolve(false);
            },
          }
        );
      }
    });
  };

  const updateSelectedResultPreset = (data: ResultPresetDto) => {
    resultsDispatch({
      type: ResultsActionType.SET_SELECTED_PRESET,
      preset: data,
    });
    queryClient.invalidateQueries(['result-preset-by-id', data.id]);
    queryClient.invalidateQueries(['result-presets-by-project-id', data.projectId]);
  };

  const onConfirmDelete = (resultPreset: ResultPresetDto, inLibrary?: boolean) => {
    if (resultPreset) {
      deleteResultPreset(
        { resultId: resultPreset.id, resultName: resultPreset.name },
        {
          onSuccess: () => {
            if (selectedPreset?.id == resultPreset.id) {
              queryClient.invalidateQueries(['result-presets-by-project-id', resultPreset.projectId]);
              resultsDispatch({
                type: ResultsActionType.RESET_STATE,
              });
              simDispatch({ type: SimActionType.SET_SELECTED_SIMULATION, simulation: null });
              simDispatch({ type: SimActionType.SET_ORIGINAL_SIMULATION, simulation: null });
              setSearchParams({}, { replace: true });
            }
            if (inLibrary) {
              queryClient.invalidateQueries(['all-result-presets']);
            }
          },
        }
      );
    }
  };

  return {
    onReset,
    onLoadResultPreset,
    onSaveResultPreset,
    onUpdateResultPreset,
    onUpdateResultPresetDetails,
    onConfirmDelete,
  };
};
