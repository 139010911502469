import { useMemo } from 'react';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { getSplValues } from '../../utils';

export const useGetYAxisRange = (
  data: ReflectionDetails[],
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
) => {
  return useMemo(() => {
    const splValues = getSplValues(data, selectedFrequencyWeighting, selectedFrequencyBandIndex);
    const splMin = Math.min(...splValues);
    const splMax = Math.max(...splValues);
    const yaxisRange: [number, number] = [Math.max(splMin - 3, -150), Math.max(splMax + 3, 0)];
    return yaxisRange;
  }, [data, selectedFrequencyWeighting, selectedFrequencyBandIndex]);
};
