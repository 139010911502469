import { useQuery } from '@tanstack/react-query';

import { Organization } from '../../components/ManagementPortal/hooks';

import axios from '@/axios';

const getOrganizationMine = async (): Promise<Organization> => {
  const { data } = await axios.get(`/api/Organization/Mine`);

  return data;
};

export const useGetOrganizationMine = () => {
  return useQuery<Organization>(['organization_mine'], () => getOrganizationMine(), {
    refetchOnWindowFocus: false,
  });
};
