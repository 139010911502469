import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblBinauralIcon: FC<IconProps> = ({ fillExtra = '#ADADAD' }) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill={fillExtra} stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path d="m12.1978 32.1678a10.8384 10.8384 0 0 0 .4418 5.7205c1.1 3.0411 4.2542 4.6949 4.3522 5.6117" />
      <path d="m10.2931 31.8558c-2.58-13.65-7.2368-24.8671 1.6727-27.2546 2.5352-.6763 5.4876 2.1093 6.5942 6.2219s-.0512 7.9949-2.5865 8.6713a5.0415 5.0415 0 0 1 -4.4541-1.0465s2.5452 12.67 2.1365 12.99a2.7007 2.7007 0 0 1 -3.3629.4181z" />
      <path d="m35.8022 32.1678a10.8384 10.8384 0 0 1 -.4418 5.7205c-1.1 3.0411-1.9644 4.6949-2.0623 5.6117" />
      <path d="m37.7069 31.8558c2.58-13.65 7.2368-24.8671-1.6727-27.2546-2.5352-.6763-5.4876 2.1093-6.5942 6.2219s.0512 7.9949 2.5865 8.6713a5.0415 5.0415 0 0 0 4.4541-1.0465s-2.5452 12.67-2.1365 12.99a2.7007 2.7007 0 0 0 3.3629.4181z" />
      <path d="m20.8631 16.2928a9.3239 9.3239 0 0 0 -.8656-10.0594" />
      <path d="m26.6657 16.4836s-2.9882-3.9822.6042-9.714" />
    </g>
  </svg>
);
