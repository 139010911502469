import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

interface UseMutationVariables {
  spaceId: string | undefined;
}

export const useDeleteSpace = () =>
  useMutation(async ({ spaceId }: UseMutationVariables) => {
    const { data } = await axios.delete(`/api/Space/DeleteSpace?spaceId=${spaceId}`);
    return data;
  });
