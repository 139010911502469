import { useQuery } from '@tanstack/react-query';

import { ResultPresetDto } from '../types';

import axios from '@/axios';

export const getResultPresetsForProject = async (projectId: string): Promise<ResultPresetDto[]> => {
  const { data } = await axios.get(`/api/ResultPreset/GetResultPresetsForProject?projectId=${projectId}`);
  return data;
};

export const useGetResultPresetsForProject = (projectId: string) => {
  return useQuery<ResultPresetDto[]>(
    ['result-presets-by-project-id', projectId],
    () => getResultPresetsForProject(projectId),
    {
      enabled: projectId.length > 0,
      refetchOnWindowFocus: false,
    }
  );
};
