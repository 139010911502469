import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblDotIcon: FC<IconProps> = ({ fill }) => (
  <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.25 2C0.25 2.9665 1.0335 3.75 2 3.75C2.9665 3.75 3.75 2.9665 3.75 2C3.75 1.0335 2.9665 0.25 2 0.25C1.0335 0.25 0.25 1.0335 0.25 2Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
