import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { GeometryImportProvider } from '@/context/GeometryImportContext';

import { useFeatureFlags } from '../FeatureToggles';
import { Trbl3DWireframeIcon, TrblFileIcon } from '../Icons';
import { MultiSpaceImportPopup, SimpleMultiSpaceImportPopup } from '../MultiSpaceImport';
import { TertiaryButton } from '../Shared/Buttons';

import { useGetLatestSpaceExtractionTasks } from '@/hooks';

export const GeometryImportButton = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [showSimpleModelImportPopup, setShowSimpleModelImportPopup] = useState(false);
  const [showModelImportPopup, setShowModelImportPopup] = useState(false);

  const { multiSpaceImportFeature } = useFeatureFlags();

  const { data: spaceExtractionTasks } = useGetLatestSpaceExtractionTasks(100, showModelImportPopup);

  const uploadModelDone = (id?: string, type?: string) => {
    setShowSimpleModelImportPopup(false);
    setShowModelImportPopup(false);

    if (type == 'project') {
      navigate(`/project/${id}`);
    } else if (type == 'space') {
      navigate(`/space/${id}`);
    } else {
      queryClient.invalidateQueries(['projects']);
      queryClient.invalidateQueries(['spaces-for-project']);
    }
  };

  return (
    <div>
      <TertiaryButton
        label="Import model"
        icon={<TrblFileIcon width="16" height="16" fill="#00f5ba" />}
        onClick={() => setShowSimpleModelImportPopup(true)}></TertiaryButton>
      {showSimpleModelImportPopup && (
        <GeometryImportProvider>
          <SimpleMultiSpaceImportPopup setShowPopup={setShowSimpleModelImportPopup} uploadModelDone={uploadModelDone} />
        </GeometryImportProvider>
      )}

      {multiSpaceImportFeature && (
        <>
          <TertiaryButton
            sx={{ margin: '0 -32px 0 32px', filter: 'hue-rotate(30deg)', opacity: 0.95 }}
            label="Import model (ifc)"
            icon={<Trbl3DWireframeIcon width="16" height="16" fill="#00f5ba" />}
            onClick={() => setShowModelImportPopup(true)}></TertiaryButton>

          {showModelImportPopup && spaceExtractionTasks && (
            <GeometryImportProvider>
              <MultiSpaceImportPopup
                setShowPopup={setShowModelImportPopup}
                uploadModelDone={uploadModelDone}
                spaceExtractionTasksOnLoad={spaceExtractionTasks}
              />
            </GeometryImportProvider>
          )}
        </>
      )}
    </div>
  );
};
