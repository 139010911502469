import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Stack } from '@mui/material';

import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '../../Shared/Text';

import { MAX_NUMBER_INPUT } from '../constants';

import { AirCavityFormValues } from '../types';

type AirCavityForm = {
  disabled: boolean;
};

export const AirCavityForm = ({ disabled }: AirCavityForm) => {
  const { airCavityFormValues, dispatch } = useCreateMaterialContext();

  const setAirCavityFormValues = (airCavityFormValues: AirCavityFormValues) => {
    dispatch({
      type: ActionType.SET_AIR_CAVITY_FORM_VALUES,
      formValues: airCavityFormValues,
    });
  };

  return (
    <Stack gap={1} flex={'1 1 0'}>
      <Text type="semibold-14px">Air cavity in front of rigid backing</Text>
      <TrblNumberInput
        label="Thickness [mm]"
        toFixed={false}
        disabled={disabled}
        value={airCavityFormValues.thickness}
        step={1}
        min={0}
        max={MAX_NUMBER_INPUT}
        onChange={(newValue) => {
          setAirCavityFormValues({
            ...airCavityFormValues,
            thickness: newValue,
          });
        }}
        alignment="center"
        style={{ width: 50, margin: '0 auto' }}
      />
    </Stack>
  );
};
