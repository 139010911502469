import { Vector3 } from 'three';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';

import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';

import { Receiver } from '@/types';

export const useClickReceiver = () => {
  const { selectedAurSim, selectedReceiver, dispatch } = useAuralizerContext();

  const { dispatch: editorDispatch, selectedAurSource } = useEditorContext();

  const clickReceiver = (receiver: Receiver, index: number) => {
    // This is a toggle functionality so we need to deselect
    // the receiver if it is clicked when it is already selected
    if (selectedReceiver?.receiver.id !== receiver.id && selectedAurSim) {
      // get the selected source or the first source to set the coordinates to look at
      const source = selectedAurSource
        ? selectedAurSource.source
        : selectedAurSim.sources.find((src) => src.orderNumber === 0) || selectedAurSim.sources[0];

      editorDispatch({
        type: EditorActionType.UPDATE_COORDINATES,
        coordinates: [new Vector3(receiver.x, receiver.y, receiver.z), new Vector3(source.x, source.y, source.z)],
      });

      editorDispatch({
        type: EditorActionType.SET_IS_CAMERA_INSIDE_MODEL,
        payload: true,
      });
      dispatch({
        type: ActionType.SET_SELECTED_RECEIVER,
        receiver,
        index,
      });
      console.log('[Auralization] Clicked: ' + receiver.id + ' position: ' + index + ' name: ' + selectedAurSim.name);
    } else {
      editorDispatch({
        type: EditorActionType.UPDATE_COORDINATES,
        coordinates: null,
      });
      // else if it is the same receiver as already selected, go back to birdsview
      editorDispatch({
        type: EditorActionType.SET_IS_CAMERA_INSIDE_MODEL,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_SELECTED_RECEIVER,
        receiver: null,
        index: null,
      });
      AudioEngine.getInstance().bypassAll();
    }
  };

  return clickReceiver;
};
