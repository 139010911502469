import { Data, Layout } from 'plotly.js';

import { EMPTY_GUID } from '@/components/Shared/constants';

export const plotLayoutConfig: Partial<Layout> = {
  xaxis: {
    color: 'black',
  },
  yaxis: {
    color: 'black',
  },
  polar: {
    bgcolor: '#202020',
    angularaxis: {
      tickwidth: 2,
      linewidth: 3,
    },
    radialaxis: {
      range: [-25, 5],
      color: '#DADADA',
    },
  },
  legend: {
    font: {
      color: '#DADADA',
    },
  },
  font: {
    size: 12,
    color: '#DADADA',
  },
  showlegend: true,
  orientation: -90,
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  margin: { t: 60, b: 60, l: 60, r: 60 },
  width: 590,
};

export const frequencyBands = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];

export const getSoundPowerValues = (sourceDefinition: string) => {
  switch (sourceDefinition) {
    case EMPTY_GUID:
      return [105, 105, 105, 105, 105, 105, 105, 105];
    default:
      return [null, null, null, null, null, null, null, null];
  }
};

export const getSPLValues = (sourceDefinition: string) => {
  switch (sourceDefinition) {
    case EMPTY_GUID:
      return [94, 94, 94, 94, 94, 94, 94, 94];
    case '8edf5d39-8cd3-4f45-afb3-f54ebb4889fd':
      return [94, 94, 94, 94, 94, 94, 94, 94];
    case '03a86569-d432-4797-b2cc-233b48e69ce2':
      return [94, 94, 94, 94, 94, 94, 94, 94];
    default:
      return [null, null, null, null, null, null, null, null];
  }
};

export const sourceDefinitions = [
  { id: EMPTY_GUID, name: 'Omni' },
  { id: '8edf5d39-8cd3-4f45-afb3-f54ebb4889fd', name: 'Speech' },
  { id: '03a86569-d432-4797-b2cc-233b48e69ce2', name: '4" studio monitor' },
];

type GraphValues = Array<{
  y: number;
  x1: number;
  x2: number;
  x3: number;
  x4: number;
  x5: number;
  x6: number;
  x7: number;
  x8: number;
}>;

export const getPlotData = (sourceDefinitions: string): Array<Data> => {
  let graphValues: GraphValues | undefined;

  switch (sourceDefinitions) {
    case EMPTY_GUID:
      graphValues = omni_freq;
      break;
    case '8edf5d39-8cd3-4f45-afb3-f54ebb4889fd':
      graphValues = speech_freq;
      break;
    case '03a86569-d432-4797-b2cc-233b48e69ce2':
      graphValues = gen_freq;
      break;
    default:
      break;
  }

  const traces: Array<Data> = [];

  if (graphValues) {
    const y = graphValues.map((val) => val.y);
    const x1 = graphValues.map((val) => val.x1);
    const x2 = graphValues.map((val) => val.x2);
    const x3 = graphValues.map((val) => val.x3);
    const x4 = graphValues.map((val) => val.x4);
    const x5 = graphValues.map((val) => val.x5);
    const x6 = graphValues.map((val) => val.x6);
    const x7 = graphValues.map((val) => val.x7);
    const x8 = graphValues.map((val) => val.x8);

    traces.push({
      r: x1,
      theta: y,
      mode: 'lines',
      name: '63 Hz',
      line: { color: 'peru' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x2,
      theta: y,
      mode: 'lines',
      name: '125 Hz',
      line: { color: 'darkviolet' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x3,
      theta: y,
      mode: 'lines',
      name: '250 Hz',
      line: { color: 'deepskyblue' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x4,
      theta: y,
      mode: 'lines',
      name: '500 Hz',
      line: { color: '#f6845b' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x5,
      theta: y,
      mode: 'lines',
      name: '1k Hz',
      line: { color: '#d15bf6' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x6,
      theta: y,
      mode: 'lines',
      name: '2k Hz',
      type: 'scatterpolar',
      line: { color: '#f65b80' },
    });

    traces.push({
      r: x7,
      theta: y,
      mode: 'lines',
      name: '4k Hz',
      type: 'scatterpolar',
      line: { color: '#f6d15b' },
    });

    traces.push({
      r: x8,
      theta: y,
      mode: 'lines',
      name: '8k Hz',
      type: 'scatterpolar',
      line: { color: '#5bcef6' },
    });
  }

  return traces;
};

const omni_freq = [
  {
    y: 0,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 5,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 10,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 14.999999999999998,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 20,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 25,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 29.999999999999996,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 35,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 40,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 45,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 50,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 55,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 59.99999999999999,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 65,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 70,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 75.00000000000001,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 80,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 85,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 90,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 95,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 100,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 104.99999999999999,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 110,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 114.99999999999999,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 119.99999999999999,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 124.99999999999999,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 130,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 135,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 140,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 145,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 150.00000000000003,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 155,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 160,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 164.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 170,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 175.00000000000003,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 180,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 185,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 190,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 195.00000000000003,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 200,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 205,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 209.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 215,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 220,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 225,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 229.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 235,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 239.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 244.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 249.99999999999997,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 255,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 260,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 265,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 270,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 275,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 280,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 285,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 290,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 295,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 300.00000000000006,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 305,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 310,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 315,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 320,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 325,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 329.99999999999994,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 335,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 340,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 345,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 350.00000000000006,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 355,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 360,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
];

const speech_freq = [
  {
    y: 0,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
  {
    y: 5,
    x1: 0.05000000000000049,
    x2: 0,
    x3: 0,
    x4: 0.010000000000000514,
    x5: 0,
    x6: 0.040000000000000535,
    x7: -0.019999999999999414,
    x8: -0.0599999999999996,
  },
  {
    y: 10,
    x1: 0.17999999999999994,
    x2: 0,
    x3: -0.009999999999999617,
    x4: 0.010000000000000514,
    x5: 0.009999999999998586,
    x6: 0.15999999999999948,
    x7: -0.07000000000000055,
    x8: -0.24000000000000105,
  },
  {
    y: 14.999999999999998,
    x1: 0.33000000000000085,
    x2: 0,
    x3: -0.02999999999999988,
    x4: 0.02000000000000146,
    x5: 0.019999999999999536,
    x6: 0.35000000000000053,
    x7: -0.1299999999999995,
    x8: -0.5000000000000009,
  },
  {
    y: 20,
    x1: 0.4200000000000001,
    x2: 0,
    x3: -0.0599999999999996,
    x4: 0.03000000000000063,
    x5: 0.03000000000000063,
    x6: 0.559999999999999,
    x7: -0.20000000000000012,
    x8: -0.8100000000000003,
  },
  {
    y: 25,
    x1: 0.43999999999999934,
    x2: -0.010000000000000581,
    x3: -0.1199999999999999,
    x4: 0.05000000000000049,
    x5: 0.06000000000000013,
    x6: 0.7699999999999995,
    x7: -0.28000000000000064,
    x8: -1.17,
  },
  {
    y: 29.999999999999996,
    x1: 0.39000000000000096,
    x2: -0.010000000000000581,
    x3: -0.19000000000000034,
    x4: 0.06000000000000013,
    x5: 0.11000000000000063,
    x6: 0.9199999999999993,
    x7: -0.37000000000000033,
    x8: -1.6000000000000003,
  },
  {
    y: 35,
    x1: 0.33000000000000085,
    x2: -0.010000000000000581,
    x3: -0.27999999999999964,
    x4: 0.06000000000000013,
    x5: 0.18999999999999964,
    x6: 0.9799999999999993,
    x7: -0.5200000000000005,
    x8: -2.1200000000000006,
  },
  {
    y: 40,
    x1: 0.27999999999999964,
    x2: -0.02000000000000038,
    x3: -0.37000000000000033,
    x4: 0.06000000000000013,
    x5: 0.3099999999999998,
    x6: 0.9100000000000004,
    x7: -0.7400000000000008,
    x8: -2.770000000000001,
  },
  {
    y: 45,
    x1: 0.2699999999999988,
    x2: -0.02999999999999988,
    x3: -0.46999999999999975,
    x4: 0.040000000000000535,
    x5: 0.43999999999999934,
    x6: 0.6900000000000001,
    x7: -1.04,
    x8: -3.5300000000000002,
  },
  {
    y: 50,
    x1: 0.26000000000000123,
    x2: -0.050000000000000766,
    x3: -0.5699999999999995,
    x4: -0.009999999999999617,
    x5: 0.5799999999999985,
    x6: 0.33000000000000085,
    x7: -1.4099999999999993,
    x8: -4.36,
  },
  {
    y: 55,
    x1: 0.24000000000000032,
    x2: -0.07000000000000055,
    x3: -0.6899999999999994,
    x4: -0.08999999999999883,
    x5: 0.7200000000000002,
    x6: -0.1799999999999996,
    x7: -1.8100000000000003,
    x8: -5.18,
  },
  {
    y: 59.99999999999999,
    x1: 0.18999999999999964,
    x2: -0.12000000000000087,
    x3: -0.8300000000000001,
    x4: -0.20999999999999952,
    x5: 0.8799999999999994,
    x6: -0.8300000000000012,
    x7: -2.2100000000000004,
    x8: -5.92,
  },
  {
    y: 65,
    x1: 0.11000000000000063,
    x2: -0.16000000000000036,
    x3: -0.9899999999999988,
    x4: -0.359999999999999,
    x5: 1.0500000000000003,
    x6: -1.5999999999999992,
    x7: -2.5700000000000007,
    x8: -6.59,
  },
  {
    y: 70,
    x1: 0.06000000000000013,
    x2: -0.22000000000000058,
    x3: -1.1599999999999997,
    x4: -0.5500000000000005,
    x5: 1.2299999999999998,
    x6: -2.4499999999999997,
    x7: -2.8899999999999997,
    x8: -7.240000000000001,
  },
  {
    y: 75.00000000000001,
    x1: 0.05000000000000049,
    x2: -0.26999999999999974,
    x3: -1.3499999999999992,
    x4: -0.7700000000000001,
    x5: 1.4099999999999993,
    x6: -3.3100000000000005,
    x7: -3.1699999999999995,
    x8: -7.940000000000001,
  },
  {
    y: 80,
    x1: 0.11000000000000063,
    x2: -0.32000000000000034,
    x3: -1.5499999999999998,
    x4: -1.0300000000000007,
    x5: 1.5500000000000012,
    x6: -4.08,
    x7: -3.45,
    x8: -8.72,
  },
  {
    y: 85,
    x1: 0.19999999999999973,
    x2: -0.37000000000000033,
    x3: -1.7500000000000002,
    x4: -1.3399999999999994,
    x5: 1.6000000000000003,
    x6: -4.680000000000001,
    x7: -3.7699999999999996,
    x8: -9.55,
  },
  {
    y: 90,
    x1: 0.2699999999999988,
    x2: -0.42000000000000126,
    x3: -1.9599999999999997,
    x4: -1.6899999999999995,
    x5: 1.5200000000000005,
    x6: -5.060000000000001,
    x7: -4.180000000000001,
    x8: -10.36,
  },
  {
    y: 95,
    x1: 0.2999999999999987,
    x2: -0.4800000000000007,
    x3: -2.169999999999999,
    x4: -2.0699999999999994,
    x5: 1.299999999999999,
    x6: -5.290000000000001,
    x7: -4.720000000000001,
    x8: -11.059999999999999,
  },
  {
    y: 100,
    x1: 0.27999999999999964,
    x2: -0.5500000000000005,
    x3: -2.4,
    x4: -2.4699999999999993,
    x5: 0.9499999999999997,
    x6: -5.44,
    x7: -5.3900000000000015,
    x8: -11.630000000000003,
  },
  {
    y: 104.99999999999999,
    x1: 0.22000000000000036,
    x2: -0.6200000000000008,
    x3: -2.6399999999999997,
    x4: -2.8599999999999994,
    x5: 0.4699999999999992,
    x6: -5.580000000000002,
    x7: -6.200000000000001,
    x8: -12.160000000000002,
  },
  {
    y: 110,
    x1: 0.14999999999999908,
    x2: -0.6900000000000005,
    x3: -2.88,
    x4: -3.22,
    x5: -0.14000000000000032,
    x6: -5.73,
    x7: -7.130000000000001,
    x8: -12.770000000000001,
  },
  {
    y: 114.99999999999999,
    x1: 0.10000000000000057,
    x2: -0.75,
    x3: -3.1099999999999994,
    x4: -3.5400000000000005,
    x5: -0.8800000000000003,
    x6: -5.859999999999999,
    x7: -8.12,
    x8: -13.56,
  },
  {
    y: 119.99999999999999,
    x1: 0.05000000000000049,
    x2: -0.7999999999999997,
    x3: -3.3400000000000003,
    x4: -3.8200000000000003,
    x5: -1.7900000000000014,
    x6: -5.910000000000001,
    x7: -9.11,
    x8: -14.54,
  },
  {
    y: 124.99999999999999,
    x1: 0.010000000000000514,
    x2: -0.8399999999999999,
    x3: -3.5599999999999996,
    x4: -4.0600000000000005,
    x5: -2.8699999999999997,
    x6: -5.93,
    x7: -10.020000000000003,
    x8: -15.61,
  },
  {
    y: 130,
    x1: -0.0599999999999996,
    x2: -0.8800000000000003,
    x3: -3.7699999999999996,
    x4: -4.28,
    x5: -4.110000000000001,
    x6: -6.020000000000001,
    x7: -10.839999999999998,
    x8: -16.619999999999997,
  },
  {
    y: 135,
    x1: -0.12000000000000087,
    x2: -0.9400000000000004,
    x3: -3.9799999999999995,
    x4: -4.450000000000001,
    x5: -5.380000000000002,
    x6: -6.35,
    x7: -11.570000000000002,
    x8: -17.470000000000002,
  },
  {
    y: 140,
    x1: -0.17000000000000043,
    x2: -1.0100000000000002,
    x3: -4.160000000000001,
    x4: -4.5600000000000005,
    x5: -6.4399999999999995,
    x6: -7.08,
    x7: -12.299999999999997,
    x8: -18.189999999999998,
  },
  {
    y: 145,
    x1: -0.19000000000000034,
    x2: -1.0799999999999998,
    x3: -4.3199999999999985,
    x4: -4.599999999999999,
    x5: -7.039999999999999,
    x6: -8.280000000000001,
    x7: -13.09,
    x8: -18.880000000000003,
  },
  {
    y: 150.00000000000003,
    x1: -0.1499999999999999,
    x2: -1.1299999999999994,
    x3: -4.4399999999999995,
    x4: -4.5600000000000005,
    x5: -7.02,
    x6: -9.87,
    x7: -13.99,
    x8: -19.660000000000004,
  },
  {
    y: 155,
    x1: -0.09999999999999984,
    x2: -1.1400000000000008,
    x3: -4.5299999999999985,
    x4: -4.47,
    x5: -6.489999999999999,
    x6: -11.510000000000002,
    x7: -14.97,
    x8: -20.560000000000002,
  },
  {
    y: 160,
    x1: -0.0599999999999996,
    x2: -1.1000000000000005,
    x3: -4.569999999999999,
    x4: -4.36,
    x5: -5.710000000000002,
    x6: -12.64,
    x7: -15.939999999999998,
    x8: -21.44,
  },
  {
    y: 164.99999999999997,
    x1: -0.039999999999999765,
    x2: -1.0300000000000007,
    x3: -4.6000000000000005,
    x4: -4.259999999999999,
    x5: -4.94,
    x6: -12.820000000000002,
    x7: -16.8,
    x8: -22.12,
  },
  {
    y: 170,
    x1: -0.0599999999999996,
    x2: -0.9400000000000004,
    x3: -4.6000000000000005,
    x4: -4.179999999999999,
    x5: -4.33,
    x6: -12.26,
    x7: -17.430000000000003,
    x8: -22.490000000000002,
  },
  {
    y: 175.00000000000003,
    x1: -0.09000000000000077,
    x2: -0.8800000000000003,
    x3: -4.6000000000000005,
    x4: -4.14,
    x5: -3.96,
    x6: -11.59,
    x7: -17.799999999999997,
    x8: -22.6,
  },
  {
    y: 180,
    x1: -0.1100000000000005,
    x2: -0.8500000000000003,
    x3: -4.6000000000000005,
    x4: -4.130000000000001,
    x5: -3.83,
    x6: -11.31,
    x7: -17.92,
    x8: -22.61,
  },
  {
    y: 185,
    x1: -0.09000000000000077,
    x2: -0.8800000000000003,
    x3: -4.6000000000000005,
    x4: -4.14,
    x5: -3.96,
    x6: -11.59,
    x7: -17.799999999999997,
    x8: -22.6,
  },
  {
    y: 190,
    x1: -0.0599999999999996,
    x2: -0.9400000000000004,
    x3: -4.6000000000000005,
    x4: -4.179999999999999,
    x5: -4.33,
    x6: -12.26,
    x7: -17.430000000000003,
    x8: -22.490000000000002,
  },
  {
    y: 195.00000000000003,
    x1: -0.039999999999999765,
    x2: -1.0300000000000007,
    x3: -4.6000000000000005,
    x4: -4.259999999999999,
    x5: -4.94,
    x6: -12.820000000000002,
    x7: -16.8,
    x8: -22.12,
  },
  {
    y: 200,
    x1: -0.0599999999999996,
    x2: -1.1000000000000005,
    x3: -4.569999999999999,
    x4: -4.36,
    x5: -5.710000000000002,
    x6: -12.64,
    x7: -15.939999999999998,
    x8: -21.44,
  },
  {
    y: 205,
    x1: -0.09999999999999984,
    x2: -1.1400000000000008,
    x3: -4.5299999999999985,
    x4: -4.47,
    x5: -6.489999999999999,
    x6: -11.510000000000002,
    x7: -14.97,
    x8: -20.560000000000002,
  },
  {
    y: 209.99999999999997,
    x1: -0.1499999999999999,
    x2: -1.1299999999999994,
    x3: -4.4399999999999995,
    x4: -4.5600000000000005,
    x5: -7.02,
    x6: -9.87,
    x7: -13.99,
    x8: -19.660000000000004,
  },
  {
    y: 215,
    x1: -0.19000000000000034,
    x2: -1.0799999999999998,
    x3: -4.3199999999999985,
    x4: -4.599999999999999,
    x5: -7.039999999999999,
    x6: -8.280000000000001,
    x7: -13.09,
    x8: -18.880000000000003,
  },
  {
    y: 220,
    x1: -0.17000000000000043,
    x2: -1.0100000000000002,
    x3: -4.160000000000001,
    x4: -4.5600000000000005,
    x5: -6.4399999999999995,
    x6: -7.08,
    x7: -12.299999999999997,
    x8: -18.189999999999998,
  },
  {
    y: 225,
    x1: -0.12000000000000087,
    x2: -0.9400000000000004,
    x3: -3.9799999999999995,
    x4: -4.450000000000001,
    x5: -5.380000000000002,
    x6: -6.35,
    x7: -11.570000000000002,
    x8: -17.470000000000002,
  },
  {
    y: 229.99999999999997,
    x1: -0.0599999999999996,
    x2: -0.8800000000000003,
    x3: -3.7699999999999996,
    x4: -4.28,
    x5: -4.110000000000001,
    x6: -6.020000000000001,
    x7: -10.839999999999998,
    x8: -16.619999999999997,
  },
  {
    y: 235,
    x1: 0.010000000000000514,
    x2: -0.8399999999999999,
    x3: -3.5599999999999996,
    x4: -4.0600000000000005,
    x5: -2.8699999999999997,
    x6: -5.93,
    x7: -10.020000000000003,
    x8: -15.61,
  },
  {
    y: 239.99999999999997,
    x1: 0.05000000000000049,
    x2: -0.7999999999999997,
    x3: -3.3400000000000003,
    x4: -3.8200000000000003,
    x5: -1.7900000000000014,
    x6: -5.910000000000001,
    x7: -9.11,
    x8: -14.54,
  },
  {
    y: 244.99999999999997,
    x1: 0.10000000000000057,
    x2: -0.75,
    x3: -3.1099999999999994,
    x4: -3.5400000000000005,
    x5: -0.8800000000000003,
    x6: -5.859999999999999,
    x7: -8.12,
    x8: -13.56,
  },
  {
    y: 249.99999999999997,
    x1: 0.14999999999999908,
    x2: -0.6900000000000005,
    x3: -2.88,
    x4: -3.22,
    x5: -0.14000000000000032,
    x6: -5.73,
    x7: -7.130000000000001,
    x8: -12.770000000000001,
  },
  {
    y: 255,
    x1: 0.22000000000000036,
    x2: -0.6200000000000008,
    x3: -2.6399999999999997,
    x4: -2.8599999999999994,
    x5: 0.4699999999999992,
    x6: -5.580000000000002,
    x7: -6.200000000000001,
    x8: -12.160000000000002,
  },
  {
    y: 260,
    x1: 0.27999999999999964,
    x2: -0.5500000000000005,
    x3: -2.4,
    x4: -2.4699999999999993,
    x5: 0.9499999999999997,
    x6: -5.44,
    x7: -5.3900000000000015,
    x8: -11.630000000000003,
  },
  {
    y: 265,
    x1: 0.2999999999999987,
    x2: -0.4800000000000007,
    x3: -2.169999999999999,
    x4: -2.0699999999999994,
    x5: 1.299999999999999,
    x6: -5.290000000000001,
    x7: -4.720000000000001,
    x8: -11.059999999999999,
  },
  {
    y: 270,
    x1: 0.2699999999999988,
    x2: -0.42000000000000126,
    x3: -1.9599999999999997,
    x4: -1.6899999999999995,
    x5: 1.5200000000000005,
    x6: -5.060000000000001,
    x7: -4.180000000000001,
    x8: -10.36,
  },
  {
    y: 275,
    x1: 0.19999999999999973,
    x2: -0.37000000000000033,
    x3: -1.7500000000000002,
    x4: -1.3399999999999994,
    x5: 1.6000000000000003,
    x6: -4.680000000000001,
    x7: -3.7699999999999996,
    x8: -9.55,
  },
  {
    y: 280,
    x1: 0.11000000000000063,
    x2: -0.32000000000000034,
    x3: -1.5499999999999998,
    x4: -1.0300000000000007,
    x5: 1.5500000000000012,
    x6: -4.08,
    x7: -3.45,
    x8: -8.72,
  },
  {
    y: 285,
    x1: 0.05000000000000049,
    x2: -0.26999999999999974,
    x3: -1.3499999999999992,
    x4: -0.7700000000000001,
    x5: 1.4099999999999993,
    x6: -3.3100000000000005,
    x7: -3.1699999999999995,
    x8: -7.940000000000001,
  },
  {
    y: 290,
    x1: 0.06000000000000013,
    x2: -0.22000000000000058,
    x3: -1.1599999999999997,
    x4: -0.5500000000000005,
    x5: 1.2299999999999998,
    x6: -2.4499999999999997,
    x7: -2.8899999999999997,
    x8: -7.240000000000001,
  },
  {
    y: 295,
    x1: 0.11000000000000063,
    x2: -0.16000000000000036,
    x3: -0.9899999999999988,
    x4: -0.359999999999999,
    x5: 1.0500000000000003,
    x6: -1.5999999999999992,
    x7: -2.5700000000000007,
    x8: -6.59,
  },
  {
    y: 300.00000000000006,
    x1: 0.18999999999999964,
    x2: -0.12000000000000087,
    x3: -0.8300000000000001,
    x4: -0.20999999999999952,
    x5: 0.8799999999999994,
    x6: -0.8300000000000012,
    x7: -2.2100000000000004,
    x8: -5.92,
  },
  {
    y: 305,
    x1: 0.24000000000000032,
    x2: -0.07000000000000055,
    x3: -0.6899999999999994,
    x4: -0.08999999999999883,
    x5: 0.7200000000000002,
    x6: -0.1799999999999996,
    x7: -1.8100000000000003,
    x8: -5.18,
  },
  {
    y: 310,
    x1: 0.26000000000000123,
    x2: -0.050000000000000766,
    x3: -0.5699999999999995,
    x4: -0.009999999999999617,
    x5: 0.5799999999999985,
    x6: 0.33000000000000085,
    x7: -1.4099999999999993,
    x8: -4.36,
  },
  {
    y: 315,
    x1: 0.2699999999999988,
    x2: -0.02999999999999988,
    x3: -0.46999999999999975,
    x4: 0.040000000000000535,
    x5: 0.43999999999999934,
    x6: 0.6900000000000001,
    x7: -1.04,
    x8: -3.5300000000000002,
  },
  {
    y: 320,
    x1: 0.27999999999999964,
    x2: -0.02000000000000038,
    x3: -0.37000000000000033,
    x4: 0.06000000000000013,
    x5: 0.3099999999999998,
    x6: 0.9100000000000004,
    x7: -0.7400000000000008,
    x8: -2.770000000000001,
  },
  {
    y: 325,
    x1: 0.33000000000000085,
    x2: -0.010000000000000581,
    x3: -0.27999999999999964,
    x4: 0.06000000000000013,
    x5: 0.18999999999999964,
    x6: 0.9799999999999993,
    x7: -0.5200000000000005,
    x8: -2.1200000000000006,
  },
  {
    y: 329.99999999999994,
    x1: 0.39000000000000096,
    x2: -0.010000000000000581,
    x3: -0.19000000000000034,
    x4: 0.06000000000000013,
    x5: 0.11000000000000063,
    x6: 0.9199999999999993,
    x7: -0.37000000000000033,
    x8: -1.6000000000000003,
  },
  {
    y: 335,
    x1: 0.43999999999999934,
    x2: -0.010000000000000581,
    x3: -0.1199999999999999,
    x4: 0.05000000000000049,
    x5: 0.06000000000000013,
    x6: 0.7699999999999995,
    x7: -0.28000000000000064,
    x8: -1.17,
  },
  {
    y: 340,
    x1: 0.4200000000000001,
    x2: 0,
    x3: -0.0599999999999996,
    x4: 0.03000000000000063,
    x5: 0.03000000000000063,
    x6: 0.559999999999999,
    x7: -0.20000000000000012,
    x8: -0.8100000000000003,
  },
  {
    y: 345,
    x1: 0.33000000000000085,
    x2: 0,
    x3: -0.02999999999999988,
    x4: 0.02000000000000146,
    x5: 0.019999999999999536,
    x6: 0.35000000000000053,
    x7: -0.1299999999999995,
    x8: -0.5000000000000009,
  },
  {
    y: 350.00000000000006,
    x1: 0.17999999999999994,
    x2: 0,
    x3: -0.009999999999999617,
    x4: 0.010000000000000514,
    x5: 0.009999999999998586,
    x6: 0.15999999999999948,
    x7: -0.07000000000000055,
    x8: -0.24000000000000105,
  },
  {
    y: 355,
    x1: 0.05000000000000049,
    x2: 0,
    x3: 0,
    x4: 0.010000000000000514,
    x5: 0,
    x6: 0.040000000000000535,
    x7: -0.019999999999999414,
    x8: -0.0599999999999996,
  },
  {
    y: 360,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: 0,
  },
];

const gen_freq = [
  {
    y: 0,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: -9.643274665532871e-16,
  },
  {
    y: 5,
    x1: -0.0003462541199535825,
    x2: -0.000017648421927581067,
    x3: -0.0012771250817655991,
    x4: -0.001758309705107863,
    x5: -0.006669504034320116,
    x6: -0.004927649022829699,
    x7: 0.026417308818174202,
    x8: 0.03928226356387465,
  },
  {
    y: 10,
    x1: -0.0005824231748097298,
    x2: -0.00002734532717576429,
    x3: -0.004881393451557232,
    x4: -0.029024770274873733,
    x5: -0.05233332818833297,
    x6: -0.09569962448063983,
    x7: -0.06480030900021534,
    x8: -0.04842638327542944,
  },
  {
    y: 14.999999999999998,
    x1: -0.0005835933678476271,
    x2: -0.000026573870573777744,
    x3: -0.01277916192990918,
    x4: -0.07919333036666093,
    x5: -0.13742599102304703,
    x6: -0.26811361930414046,
    x7: -0.2742764737437076,
    x8: -0.25045245542490097,
  },
  {
    y: 20,
    x1: -0.0007556002745947362,
    x2: -0.00003620168018134224,
    x3: -0.02627569617569657,
    x4: -0.15122543465128993,
    x5: -0.261264192496407,
    x6: -0.523151049969615,
    x7: -0.598051123110902,
    x8: -0.5630445489468523,
  },
  {
    y: 25,
    x1: -0.0011596234987826656,
    x2: -0.00005527808666354301,
    x3: -0.04294779312839776,
    x4: -0.24802483375346698,
    x5: -0.4236224251004709,
    x6: -0.8563787655524844,
    x7: -1.0291711114625417,
    x8: -0.9702551095310528,
  },
  {
    y: 29.999999999999996,
    x1: -0.001248709724490926,
    x2: -0.000057931284028054194,
    x3: -0.05927246796381909,
    x4: -0.37183910489944005,
    x5: -0.6255181973507423,
    x6: -1.255338093828681,
    x7: -1.5541801726596884,
    x8: -1.4683858230747635,
  },
  {
    y: 35,
    x1: -0.0007576410553229178,
    x2: -0.00003704247287908873,
    x3: -0.07552237493914418,
    x4: -0.5213398383483072,
    x5: -0.86765832839347,
    x6: -1.7154275840672994,
    x7: -2.149988122207081,
    x8: -2.083708912757816,
  },
  {
    y: 40,
    x1: -0.00012480688029026458,
    x2: -0.000013287196056350549,
    x3: -0.09484424265290242,
    x4: -0.6950202268289084,
    x5: -1.1496169193288295,
    x6: -2.239046325244874,
    x7: -2.7983554007915816,
    x8: -2.838679234072419,
  },
  {
    y: 45,
    x1: 0.00023528560393597716,
    x2: -2.0191255750893323e-7,
    x3: -0.11751362999526656,
    x4: -0.8944337882320998,
    x5: -1.4715754016934282,
    x6: -2.818971855892389,
    x7: -3.502188794586167,
    x8: -3.7441191044152333,
  },
  {
    y: 50,
    x1: 0.0003960001674304228,
    x2: 0.0000068408661565969554,
    x3: -0.14008978798862387,
    x4: -1.1223303212171014,
    x5: -1.8350134967576872,
    x6: -3.446959289389671,
    x7: -4.277603035880561,
    x8: -4.817009966197049,
  },
  {
    y: 55,
    x1: 0.0005127635442316045,
    x2: 0.000011158295973856516,
    x3: -0.1610898567762854,
    x4: -1.3796131211889093,
    x5: -2.240104019317299,
    x6: -4.13026768998622,
    x7: -5.143265644534089,
    x8: -6.021311185419186,
  },
  {
    y: 59.99999999999999,
    x1: 0.0006112440312838628,
    x2: 0.000013301741776086552,
    x3: -0.18289084660844468,
    x4: -1.665669399331375,
    x5: -2.6838628468997667,
    x6: -4.8736132500790585,
    x7: -6.124413442082571,
    x8: -7.22868736618706,
  },
  {
    y: 65,
    x1: 0.0007899866738024768,
    x2: 0.00001980698284827548,
    x3: -0.2064430547062662,
    x4: -1.9803226140951413,
    x5: -3.1616522947097847,
    x6: -5.663798209297348,
    x7: -7.230020993790368,
    x8: -8.37653469330196,
  },
  {
    y: 70,
    x1: 0.0010529997522807821,
    x2: 0.000029753161120895388,
    x3: -0.2290525120442527,
    x4: -2.3234185004992183,
    x5: -3.667766126610055,
    x6: -6.493575954592141,
    x7: -8.401580509307003,
    x8: -9.561810552794421,
  },
  {
    y: 75.00000000000001,
    x1: 0.0011127338322832942,
    x2: 0.000028078908224782715,
    x3: -0.24914798843333055,
    x4: -2.692184406722479,
    x5: -4.192708998429479,
    x6: -7.365434889359027,
    x7: -9.519795281561658,
    x8: -10.797517213931949,
  },
  {
    y: 80,
    x1: 0.0008545413761839909,
    x2: 0.000011204793882782089,
    x3: -0.2679474003614548,
    x4: -3.0801947668883205,
    x5: -4.722280332822522,
    x6: -8.257032342117059,
    x7: -10.474589324572058,
    x8: -11.935552171251707,
  },
  {
    y: 85,
    x1: 0.0006457393076616618,
    x2: -0.0000013825816613207133,
    x3: -0.28502397559615694,
    x4: -3.479328399951961,
    x5: -5.241076907472499,
    x6: -9.131968041326699,
    x7: -11.18128199327936,
    x8: -13.044782572919527,
  },
  {
    y: 90,
    x1: 0.0007902684845614231,
    x2: 0.000004489742762762606,
    x3: -0.29813224431689456,
    x4: -3.88072575967646,
    x5: -5.734971656617135,
    x6: -9.978296686419913,
    x7: -11.594357007728641,
    x8: -14.387546557484116,
  },
  {
    y: 95,
    x1: 0.001144782539423322,
    x2: 0.000020338001913876468,
    x3: -0.3082571333840582,
    x4: -4.271678570704577,
    x5: -6.190334517646209,
    x6: -10.768548529665807,
    x7: -11.844213884256769,
    x8: -15.830005389370957,
  },
  {
    y: 100,
    x1: 0.0014739675287891675,
    x2: 0.0000326339959620398,
    x3: -0.3184080916369959,
    x4: -4.632748418555909,
    x5: -6.595649166795862,
    x6: -11.418146634632631,
    x7: -12.248990183938545,
    x8: -16.99376405123506,
  },
  {
    y: 104.99999999999999,
    x1: 0.001597362824729225,
    x2: 0.00003307575217504512,
    x3: -0.3273656976854558,
    x4: -4.94060920787997,
    x5: -6.946072532422074,
    x6: -11.857523657414067,
    x7: -13.043791603790606,
    x8: -18.13075331077411,
  },
  {
    y: 110,
    x1: 0.0012339039491333717,
    x2: 0.000013717101446469099,
    x3: -0.3313563412430038,
    x4: -5.173793800096048,
    x5: -7.243147284061081,
    x6: -12.075219370617136,
    x7: -14.153199009500948,
    x8: -19.5975563732389,
  },
  {
    y: 114.99999999999999,
    x1: 0.00044425476325826556,
    x2: -0.000019972907733204586,
    x3: -0.33097210504306723,
    x4: -5.315538346610376,
    x5: -7.488353863461713,
    x6: -12.078358897370016,
    x7: -15.247155460757796,
    x8: -20.644504238379298,
  },
  {
    y: 119.99999999999999,
    x1: -0.000020540424686967494,
    x2: -0.00003988949819506875,
    x3: -0.32963805494604237,
    x4: -5.355512270281899,
    x5: -7.678427582140877,
    x6: -11.962253399849816,
    x7: -16.016554895481253,
    x8: -20.60605291522814,
  },
  {
    y: 124.99999999999999,
    x1: 0.0003511182025428923,
    x2: -0.000028577983356793513,
    x3: -0.3273365861033726,
    x4: -5.294125948533099,
    x5: -7.805838449321777,
    x6: -11.959633390256656,
    x7: -16.403955969197284,
    x8: -20.510315053493304,
  },
  {
    y: 130,
    x1: 0.0009666452343944878,
    x2: -0.000008094154489686654,
    x3: -0.32239696189178685,
    x4: -5.144878840357837,
    x5: -7.860931402977594,
    x6: -12.273164104472947,
    x7: -16.574333213669078,
    x8: -21.00538714752807,
  },
  {
    y: 135,
    x1: 0.0010335526461043147,
    x2: -0.0000075797520227567906,
    x3: -0.3162415920052281,
    x4: -4.9296613090843655,
    x5: -7.834648163773455,
    x6: -12.99011364015145,
    x7: -16.666197389689263,
    x8: -21.32984795835977,
  },
  {
    y: 140,
    x1: 0.0007304610645792922,
    x2: -0.000021456585324239526,
    x3: -0.3105036496150253,
    x4: -4.67175858596742,
    x5: -7.723324569027458,
    x6: -14.267770957761268,
    x7: -16.594928884891566,
    x8: -21.260857527111693,
  },
  {
    y: 145,
    x1: 0.0006349385755503654,
    x2: -0.00002794805857103386,
    x3: -0.3038025689549688,
    x4: -4.393454536628213,
    x5: -7.533882833608417,
    x6: -16.384826515613423,
    x7: -16.330543211591024,
    x8: -21.6817183292414,
  },
  {
    y: 150.00000000000003,
    x1: 0.0006939501105060473,
    x2: -0.0000269171564903743,
    x3: -0.29598441029854383,
    x4: -4.116717857753369,
    x5: -7.285686317046482,
    x6: -19.156610736107574,
    x7: -16.394571752850315,
    x8: -22.39651677211127,
  },
  {
    y: 155,
    x1: 0.0005931856189088164,
    x2: -0.00003065435043855318,
    x3: -0.28989508093302074,
    x4: -3.861379275680292,
    x5: -7.007111789149989,
    x6: -20.84374676706188,
    x7: -17.666024664159668,
    x8: -22.17387796159063,
  },
  {
    y: 160,
    x1: 0.0004656742506620762,
    x2: -0.00003669868476757765,
    x3: -0.2857408718018588,
    x4: -3.640727541645821,
    x5: -6.728452532735022,
    x6: -19.617688153523968,
    x7: -20.630341647496493,
    x8: -21.52952470472989,
  },
  {
    y: 164.99999999999997,
    x1: 0.0005281473932474951,
    x2: -0.0000354992778934201,
    x3: -0.28062310621501685,
    x4: -3.4600930421990173,
    x5: -6.476308721402429,
    x6: -17.042813915943576,
    x7: -23.834790477100682,
    x8: -21.91206826868499,
  },
  {
    y: 170,
    x1: 0.0006115496018444629,
    x2: -0.00003048672654186444,
    x3: -0.2754907717358944,
    x4: -3.3213069106823885,
    x5: -6.272850829180824,
    x6: -14.961729943201483,
    x7: -23.021661268885968,
    x8: -23.380757340067838,
  },
  {
    y: 175.00000000000003,
    x1: 0.0005320670093255776,
    x2: -0.0000313923549000733,
    x3: -0.27458056958383653,
    x4: -3.228127514387824,
    x5: -6.136537777715677,
    x6: -13.74258000890033,
    x7: -19.495557712932698,
    x8: -25.07568323075253,
  },
  {
    y: 180,
    x1: 0.00039402089103337395,
    x2: -0.00004529818695152476,
    x3: -0.27569831679251766,
    x4: -3.1856208304852833,
    x5: -6.1460265495651525,
    x6: -13.232681272571224,
    x7: -17.86801984331354,
    x8: -26.50027221691854,
  },
  {
    y: 185,
    x1: 0.0003656825363784909,
    x2: -0.00004183984952149608,
    x3: -0.27962170171249534,
    x4: -3.19741625701956,
    x5: -6.10717917475868,
    x6: -13.50334938642964,
    x7: -18.076713043690248,
    x8: -26.754831412802975,
  },
  {
    y: 190,
    x1: 0.0003878464632928423,
    x2: -0.00003870430572258547,
    x3: -0.28177365050898234,
    x4: -3.2601912925815597,
    x5: -6.215156948963658,
    x6: -14.467884547885188,
    x7: -21.39410568662807,
    x8: -24.45528197909939,
  },
  {
    y: 195.00000000000003,
    x1: 0.00047110867442424576,
    x2: -0.00003105665200115118,
    x3: -0.2877319590027711,
    x4: -3.370586296249049,
    x5: -6.3962455566570595,
    x6: -16.29149084118208,
    x7: -25.404092670158093,
    x8: -22.09268716983548,
  },
  {
    y: 200,
    x1: 0.0006075046606017141,
    x2: -0.00002396158707464592,
    x3: -0.2968557365562381,
    x4: -3.5261960721066368,
    x5: -6.6377309267069995,
    x6: -18.879636686278463,
    x7: -24.47025350731447,
    x8: -21.805621178155373,
  },
  {
    y: 205,
    x1: 0.0007136782899147863,
    x2: -0.00001892387954363058,
    x3: -0.30539639965533333,
    x4: -3.724783790778968,
    x5: -6.919541463336945,
    x6: -20.98722611062236,
    x7: -20.485055984323303,
    x8: -23.058629173952752,
  },
  {
    y: 209.99999999999997,
    x1: 0.0007850119612377038,
    x2: -0.000013113217926913667,
    x3: -0.3133898123820874,
    x4: -3.9609194041193008,
    x5: -7.214364426473279,
    x6: -20.362291386771687,
    x7: -18.06925540038347,
    x8: -23.411156873877633,
  },
  {
    y: 215,
    x1: 0.0008869070889954459,
    x2: -0.00000610048913372425,
    x3: -0.32284870326607323,
    x4: -4.2234126074780285,
    x5: -7.490244038693591,
    x6: -17.67922501417583,
    x7: -17.625907976723852,
    x8: -22.499046189607167,
  },
  {
    y: 220,
    x1: 0.0009475968152602955,
    x2: -0.000002423988819041675,
    x3: -0.3324763547083308,
    x4: -4.495393259323681,
    x5: -7.714872618250465,
    x6: -15.233402243928882,
    x7: -18.168774647642735,
    x8: -22.371660597992012,
  },
  {
    y: 225,
    x1: 0.0008788610179611649,
    x2: -0.000002999796369848928,
    x3: -0.34026626518164677,
    x4: -4.757569439860643,
    x5: -7.862545904451258,
    x6: -13.703193756265112,
    x7: -18.511150439204762,
    x8: -23.02170626422754,
  },
  {
    y: 229.99999999999997,
    x1: 0.0008206464972192338,
    x2: -0.000001428793625770937,
    x3: -0.34725240734460916,
    x4: -4.9911774239149045,
    x5: -7.920640518477042,
    x6: -12.86388227483914,
    x7: -18.275943994454845,
    x8: -22.71323817227913,
  },
  {
    y: 235,
    x1: 0.0009284579703359439,
    x2: 0.0000057695774165259406,
    x3: -0.3539718839990811,
    x4: -5.176368726304796,
    x5: -7.889798747407893,
    x6: -12.468179216018484,
    x7: -17.899305688481167,
    x8: -21.634201200434603,
  },
  {
    y: 239.99999999999997,
    x1: 0.0011082200778249089,
    x2: 0.000014394826759746911,
    x3: -0.3579543931905915,
    x4: -5.290156533298777,
    x5: -7.779328536378317,
    x6: -12.408266709614397,
    x7: -17.628242001762622,
    x8: -21.195823418859415,
  },
  {
    y: 244.99999999999997,
    x1: 0.0012444397385287494,
    x2: 0.000022149006570958168,
    x3: -0.3581184489005787,
    x4: -5.311719544774047,
    x5: -7.602982709341932,
    x6: -12.515003278765935,
    x7: -17.22248549958431,
    x8: -21.130475325975073,
  },
  {
    y: 249.99999999999997,
    x1: 0.001378938805635583,
    x2: 0.000031283610017866446,
    x3: -0.35650475297040374,
    x4: -5.231484349909543,
    x5: -7.373932726959708,
    x6: -12.532640547435193,
    x7: -16.36494797439469,
    x8: -20.404795509031004,
  },
  {
    y: 255,
    x1: 0.0014491170128062589,
    x2: 0.00003840777247865907,
    x3: -0.35346238167370364,
    x4: -5.053699181186303,
    x5: -7.0982927591791665,
    x6: -12.322916675164489,
    x7: -15.143653237428316,
    x8: -19.25223908991702,
  },
  {
    y: 260,
    x1: 0.0012687037142977866,
    x2: 0.00003556976426319132,
    x3: -0.3464977867062072,
    x4: -4.792713957884221,
    x5: -6.774078684985135,
    x6: -11.894683146028498,
    x7: -14.00885236086481,
    x8: -18.28426322394959,
  },
  {
    y: 265,
    x1: 0.0009395864366172559,
    x2: 0.000025426006005463708,
    x3: -0.33518007387242976,
    x4: -4.46907873727561,
    x5: -6.398058264904748,
    x6: -11.272057029916386,
    x7: -13.298838509180083,
    x8: -17.286643033313073,
  },
  {
    y: 270,
    x1: 0.0008261393574680269,
    x2: 0.000021532990096864253,
    x3: -0.3219000614870181,
    x4: -4.1049376666023,
    x5: -5.971895027986783,
    x6: -10.499287870405134,
    x7: -12.960466118948396,
    x8: -16.017239705351834,
  },
  {
    y: 275,
    x1: 0.0009879278829204399,
    x2: 0.000028736843465016975,
    x3: -0.3071426264365832,
    x4: -3.718763464001382,
    x5: -5.5020279741735845,
    x6: -9.660290382856218,
    x7: -12.651398437764044,
    x8: -14.74709918892734,
  },
  {
    y: 280,
    x1: 0.0010570034063969488,
    x2: 0.000034747671484396946,
    x3: -0.2889922329975956,
    x4: -3.324636169867012,
    x5: -4.9982047945413495,
    x6: -8.803033481860343,
    x7: -12.074384211081266,
    x8: -13.642110422106189,
  },
  {
    y: 285,
    x1: 0.0007646815060734555,
    x2: 0.00002636685381389124,
    x3: -0.26758209072850747,
    x4: -2.935017095391318,
    x5: -4.474052612127191,
    x6: -7.933993623002181,
    x7: -11.184580050392146,
    x8: -12.540529053325752,
  },
  {
    y: 290,
    x1: 0.00026950318610761146,
    x2: 0.000006624433946464034,
    x3: -0.24550098929626854,
    x4: -2.560448560541282,
    x5: -3.946041716897825,
    x6: -7.075918780537167,
    x7: -10.08231958091934,
    x8: -11.348251912733678,
  },
  {
    y: 295,
    x1: -0.00006174925287204567,
    x2: -0.00000835175536383403,
    x3: -0.22355492227401497,
    x4: -2.2073600325124474,
    x5: -3.429583136069862,
    x6: -6.25544135301496,
    x7: -8.88472132848592,
    x8: -10.155242028231125,
  },
  {
    y: 300.00000000000006,
    x1: -0.000012517172095825792,
    x2: -0.0000065640499452748634,
    x3: -0.20001286234650523,
    x4: -1.8792199269174197,
    x5: -2.9363079351200017,
    x6: -5.471354461755787,
    x7: -7.703145395387731,
    x8: -9.0069799352339,
  },
  {
    y: 305,
    x1: 0.000280286646263607,
    x2: 0.000006992630110353225,
    x3: -0.17443794053222453,
    x4: -1.5784291115549967,
    x5: -2.4743947948439753,
    x6: -4.721242585099353,
    x7: -6.610242547973914,
    x8: -7.848113335443755,
  },
  {
    y: 310,
    x1: 0.00041559498445445435,
    x2: 0.000014735686352380006,
    x3: -0.14905540817808055,
    x4: -1.3052351362456183,
    x5: -2.049592109313871,
    x6: -4.018411736830448,
    x7: -5.6185332496682125,
    x8: -6.652550903842998,
  },
  {
    y: 315,
    x1: 0.00016846113826594422,
    x2: 0.000006224483533566758,
    x3: -0.1256205469143091,
    x4: -1.058050118450054,
    x5: -1.6653409782215247,
    x6: -3.365634554356715,
    x7: -4.713458219899024,
    x8: -5.483315897793098,
  },
  {
    y: 320,
    x1: -0.00018937554174830343,
    x2: -0.00000792845926461956,
    x3: -0.10346219011403027,
    x4: -0.8371819065433784,
    x5: -1.3228046502899744,
    x6: -2.7534146749340342,
    x7: -3.8836622854883824,
    x8: -4.42943239571376,
  },
  {
    y: 325,
    x1: -0.0002451253148291607,
    x2: -0.000009267677215840437,
    x3: -0.08170633240643156,
    x4: -0.6449397690432289,
    x5: -1.0214877399938296,
    x6: -2.1853808798074366,
    x7: -3.119453607937439,
    x8: -3.5256968364539776,
  },
  {
    y: 329.99999999999994,
    x1: -0.00005415518913603364,
    x2: 0.000001072619303443016,
    x3: -0.06150948456285535,
    x4: -0.48077315479698335,
    x5: -0.7601200082156929,
    x6: -1.6732386835753155,
    x7: -2.414774683266698,
    x8: -2.744658394162491,
  },
  {
    y: 335,
    x1: -0.00006446518391030284,
    x2: 0.0000012957161045434683,
    x3: -0.044534284415447864,
    x4: -0.340896894413178,
    x5: -0.5374951152089326,
    x6: -1.2182286645272502,
    x7: -1.777156536028043,
    x8: -2.055482005096108,
  },
  {
    y: 340,
    x1: -0.00041681882594089316,
    x2: -0.000017410647035277012,
    x3: -0.03048664530881115,
    x4: -0.22404980053685963,
    x5: -0.3531144789864401,
    x6: -0.8214835185201645,
    x7: -1.221565635086955,
    x8: -1.4567007213498337,
  },
  {
    y: 345,
    x1: -0.0006769401718147187,
    x2: -0.00003229877636070452,
    x3: -0.01807859991938722,
    x4: -0.1325438952382938,
    x5: -0.20722468238141403,
    x6: -0.49529131416272715,
    x7: -0.758638703082162,
    x8: -0.9470760694711884,
  },
  {
    y: 350.00000000000006,
    x1: -0.00045871695459484985,
    x2: -0.000021529535649044993,
    x3: -0.007801991524709487,
    x4: -0.06648806009899902,
    x5: -0.10007463215676365,
    x6: -0.24917160187439513,
    x7: -0.3959864343818578,
    x8: -0.5178836277690256,
  },
  {
    y: 355,
    x1: -0.000042181626657186274,
    x2: -6.07548683108873e-7,
    x3: -0.0016308145639781918,
    x4: -0.02258972188744697,
    x5: -0.031221743826708163,
    x6: -0.08318221057935797,
    x7: -0.14148618732631957,
    x8: -0.18879442526592055,
  },
  {
    y: 360,
    x1: 0,
    x2: 0,
    x3: 0,
    x4: 0,
    x5: 0,
    x6: 0,
    x7: 0,
    x8: -9.643274665532871e-16,
  },
];
