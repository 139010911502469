export const menuPaperStyleOverrides = {
  elevation: 0,
  sx: {
    bgcolor: '#1F1F1F',
    boxShadow:
      '0px 6px 20px rgba(0, 0, 0, 0.5), inset 0px 0.5px 0px rgba(255, 255, 255, 0.12), inset -0.5px 0px 0px rgba(96, 96, 96, 0.28), inset 1px 0px 0px rgba(96, 96, 96, 0.26);',
    borderRadius: '4px',
    width: '200px',
  },
};
