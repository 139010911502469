import { useUpdateObjMats } from '@/context/hooks/useUpdateObjMats';
import { useUpdateObjScats } from '@/context/hooks/useUpdateObjScats';
import { useUpdateSurfaceLayers } from '@/context/hooks/useUpdateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';
import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useEstimatedImpulseLength } from '@/components/SolverSettings/hooks/useEstimatedImpulseLength';
import { useShareAndUpdateMaterial } from './useShareAndUpdateMaterial';

import { MATERIAL_SAVE_TEXT } from '../constants';

import { Material, Simulation } from '@/types';

// We need a "global" state variable so if the user assigns materials to
// layers really quickly the newest one doesn't overwrite the other ones
const newMaterialIdByObjectId: { [key: string]: { [key: string]: string } } = {};
const newScatteringByObjectId: { [key: string]: { [key: string]: number[] } } = {};

export const useAssignNewMaterial = () => {
  const saveSimulation = useSaveUpdatedSimulation();
  const confirmEdit = useConfirmEdit();
  const estimateImpulseLength = useEstimatedImpulseLength();
  const { dispatch } = useSimulationContext();
  const { dispatch: libraryDispatch, isMaterialsLibraryOpen } = useLibraryPanelContext();
  const { modelInformation } = useModelContext();
  const updateSurfaceLayers = useUpdateSurfaceLayers();
  const updateObjMats = useUpdateObjMats();
  const updateObjScats = useUpdateObjScats();
  const shareAndUpdateMaterial = useShareAndUpdateMaterial();

  const assignNewMaterial = async (
    simulation: Simulation | null,
    material: Material,
    layerIds: string[],
    userId?: string
  ) => {
    const canContinue = await confirmEdit();

    return new Promise<Simulation | null>((resolve) => {
      if (simulation) {
        const newSurfaceLayers = updateSurfaceLayers(layerIds, material);
        // update the global state object for the new materials, this is to avoid
        // overwriting previous updates before they are updated in the SimulationContext
        // TODO: in the future we should probably cancel a promise that hasn't finished and
        // just fire one for multiple-quick-updates - we should be able to use these object for that

        newScatteringByObjectId[simulation.id] = {
          ...newScatteringByObjectId[simulation.id],
          ...simulation.modelSettings?.scatteringByObjectId,
        };

        newMaterialIdByObjectId[simulation.id] = {
          ...simulation.modelSettings?.materialIdByObjectId,
          ...newMaterialIdByObjectId[simulation.id],
        };

        // only updates the new material with the default scatter that comes to that material
        const defaultScattering = material.defaultScattering ? [material.defaultScattering] : [0.1];
        const newObjScats = updateObjScats(newScatteringByObjectId[simulation.id], layerIds, defaultScattering);

        const newObjMats = updateObjMats(newMaterialIdByObjectId[simulation.id], layerIds, material.id);

        newScatteringByObjectId[simulation.id] = {
          ...newObjScats,
        };

        newMaterialIdByObjectId[simulation.id] = {
          ...newObjMats,
        };
        let estImpulseLength = simulation.solverSettings.dgSettings.impulseLengthSeconds;

        // Re-calculate the impulseLengthSeconds if energyDecayThreshold has a value (autostop is select)
        if (simulation.solverSettings.dgSettings.energyDecayThreshold !== null) {
          estImpulseLength = estimateImpulseLength(newMaterialIdByObjectId[simulation.id]) as number;
        }

        // create a new simulation object that is:
        // 1. either saved right away if the user doesn't need to confirm
        // 2. or sent back to the component that initiated this call to confirm
        const updatedSimulation = {
          ...simulation,
          modelSettings: {
            scatteringByObjectId: newScatteringByObjectId[simulation.id],
            materialIdByObjectId: newMaterialIdByObjectId[simulation.id],
          },
          solverSettings: {
            ...simulation.solverSettings,
            gaSettings: {
              ...simulation.solverSettings.gaSettings,
              impulseLengthSeconds: estImpulseLength ?? simulation.solverSettings.gaSettings.impulseLengthSeconds,
            },
            dgSettings: {
              ...simulation.solverSettings.dgSettings,
              impulseLengthSeconds: estImpulseLength ?? simulation.solverSettings.dgSettings.impulseLengthSeconds,
            },
          },
        };
        if (canContinue) {
          dispatch({
            type: ActionType.UPDATE_SURFACE_LAYERS,
            newSurfaceLayers: newSurfaceLayers,
          });

          if (!isMaterialsLibraryOpen) {
            libraryDispatch({
              type: LibraryActionType.SET_MATERIALS_PANEL_OPEN,
              isOpen: true,
            });
          }

          libraryDispatch({
            type: LibraryActionType.SET_SELECTED_MATERIAL,
            material: material,
            highlightedItemId: material.id,
          });
          saveSimulation(updatedSimulation, MATERIAL_SAVE_TEXT);
          // if the model is in a shared project and material is created by the user and not shared with the organization
          // then share the material
          if (modelInformation?.isProjectShared && material.userId == userId && !material.isSharedWithOrganization) {
            shareAndUpdateMaterial(material);
          }
          resolve(null);
        }
        resolve(updatedSimulation);
      }
    });
  };

  return assignNewMaterial;
};
