import { useEffect, useRef, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { LibraryRow, UserOwnedIconIndicator } from '@/components/LibraryPanel';
import { AssignButton } from '@/components/LibraryPanel/components/AssignButton';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { setElementFocus } from '@/components/LibraryPanel/utils';

import { useAssignNewSourceDefinition } from '../hooks/useAssignNewSourceDefinition';
import { useEditSourceDefinitionPopup } from '../hooks/useEditSourceDefinitionPopup';

import { SourceDefinition } from '@/types';

import './style.scss';

export const SourceDefinitionRow = ({
  sourceDefinition,
  index,
  inPopup,
}: {
  sourceDefinition: SourceDefinition;
  index: number;
  inPopup?: boolean;
}) => {
  const listItemRef = useRef<HTMLLIElement>(null);
  const {
    state: { userInfo },
  } = useBaseContext();

  const [isAssigned, setIsAssigned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [showAssignButton, setShowAssignButton] = useState(false);

  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const { highlightedItemId, selectedSourceDefinition, selectedSource } = useLibraryPanelContext();
  const [EditSourceDefinitionPopup, openPopup, isPopupOpen] = useEditSourceDefinitionPopup();
  const assignNewSourceDefinition = useAssignNewSourceDefinition();

  useEffect(() => {
    if (highlightedItemId === sourceDefinition.id) {
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else if (index === 0 && highlightedItemId === null) {
      // if the source pattern has been deleted then pick the topmost pattern as highlighted
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else {
      setIsHighlighted(false);
    }
  }, [highlightedItemId, listItemRef, selectedSource]);

  useEffect(() => {
    if (selectedSourceDefinition && selectedSourceDefinition.id === sourceDefinition.id) {
      setIsAssigned(true);
      setElementFocus(listItemRef);
    } else {
      setIsAssigned(false);
    }
  }, [selectedSourceDefinition, listItemRef, selectedSource]);

  const assignSourceDefinition = async () => {
    if (!isAssigned) {
      const updatedSimulation = await assignNewSourceDefinition(
        selectedSimulation,
        selectedSource,
        sourceDefinition,
        userInfo?.id
      );
      if (updatedSimulation) {
        openPopup(updatedSimulation, sourceDefinition, userInfo?.id);
      }
    }
  };

  return (
    <>
      <LibraryRow
        listItemRef={listItemRef}
        itemId={sourceDefinition.id}
        isHighlighted={isHighlighted}
        inPopup={inPopup}
        isAssigned={isAssigned}
        onMouseEnter={() => setShowAssignButton(true)}
        onMouseLeave={() => setShowAssignButton(false)}
        onAssign={assignSourceDefinition}>
        <>
          <div className="definition-row-content">
            <p className="library-item category" title={sourceDefinition.manufacturer}>
              {sourceDefinition.manufacturer || '--'}
            </p>
            <p className="library-item name" title={sourceDefinition.name}>
              {sourceDefinition.name}
            </p>
            <p className="library-item type" title={sourceDefinition.subCategory}>
              {sourceDefinition.subCategory || '--'}
            </p>
          </div>
          {!inPopup && (
            <div style={{ marginRight: '6px' }}>
              <UserOwnedIconIndicator
                showUserIcon={sourceDefinition.isUserCreated && sourceDefinition.userId === userInfo?.id}
                showOrganization={sourceDefinition.isSharedWithOrganization}
                shouldOverlap={true}
              />
            </div>
          )}
          {!inPopup && (
            <div className="assign-button-container">
              <AssignButton
                label="Assign Source definition"
                isSelected={isAssigned}
                show={showAssignButton}
                isHighlighted={isHighlighted}
                onAssign={assignSourceDefinition}
              />
            </div>
          )}
        </>
      </LibraryRow>
      {isPopupOpen && <EditSourceDefinitionPopup />}
    </>
  );
};
