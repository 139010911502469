import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';

import { ResultPresetDto } from './types';

type EditResultPresetPopupProps = {
  onSubmit: (name: string, description: string) => Promise<boolean>;
  onClose: () => void;
  resultPreset: ResultPresetDto;
};

export const EditResultPresetPopup: React.FC<EditResultPresetPopupProps> = ({ onClose, onSubmit, resultPreset }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  // Form values
  const [name, setName] = useState(resultPreset.name);
  const [description, setDescription] = useState(resultPreset.description);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form validation
  useEffect(() => {
    if (name.length > 0 && (resultPreset.name !== name || resultPreset.description !== description)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, description, resultPreset.name, resultPreset.description]);

  const handleUpdateResultPreset = async (event: React.MouseEvent<Element, MouseEvent>) => {
    setIsSubmitting(true);
    event.preventDefault();
    await onSubmit(name, description);
    setIsSubmitting(false);
  };

  return (
    <TrblPopup width="400px" aria-labelledby="Edit details" open={true}>
      <form>
        <TrblPopupTitle onClose={onClose}>Edit details</TrblPopupTitle>
        <TrblPopupContent>
          <Stack gap={1}>
            <TextField
              label="Name"
              maxLength={128}
              placeholder="Give your results a name"
              value={name}
              onChange={setName}
            />
            <TextArea
              label="Description"
              placeholder="Give your results a description"
              value={description}
              onChange={setDescription}
            />
          </Stack>
        </TrblPopupContent>
        <TrblPopupActions>
          <SecondaryButton
            type="submit"
            disabled={!isFormValid || isSubmitting}
            width={'fit-content'}
            label="Update"
            onClick={handleUpdateResultPreset}
          />
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
