import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAzimuthIcon: FC<IconProps> = ({ fill = '#616161', fillExtra = '#7f7f7f' }) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3.86682C7.27614 3.86682 7.5 3.64296 7.5 3.36682C7.5 3.09068 7.27614 2.86682 7 2.86682V3.86682ZM8.65567 9.40155L9.00443 9.75982C9.10418 9.66272 9.15893 9.52844 9.15552 9.38927C9.1521 9.25011 9.09082 9.11867 8.98643 9.02659L8.65567 9.40155ZM6.83076 7.12504C6.62367 6.94236 6.30771 6.96215 6.12504 7.16924C5.94236 7.37633 5.96215 7.69229 6.16924 7.87496L6.83076 7.12504ZM6.15123 11.1417C5.95336 11.3343 5.94911 11.6509 6.14172 11.8488C6.33434 12.0466 6.6509 12.0509 6.84877 11.8583L6.15123 11.1417ZM7 8.99998C5.24392 8.99998 3.69527 8.66732 2.60453 8.15177C1.47697 7.61882 1 6.97951 1 6.43744H0C0 7.58899 0.960847 8.48095 2.1772 9.05587C3.43036 9.64818 5.13171 9.99998 7 9.99998V8.99998ZM1 6.43744C1 5.89485 1.47767 5.25363 2.60516 4.71877C3.69587 4.20136 5.24433 3.86682 7 3.86682V2.86682C5.1313 2.86682 3.42976 3.22078 2.17656 3.81527C0.96015 4.39232 0 5.2864 0 6.43744H1ZM8.59538 8.9052C8.08682 8.96697 7.55212 8.99998 7 8.99998V9.99998C7.59173 9.99998 8.16671 9.96461 8.71595 9.8979L8.59538 8.9052ZM8.98643 9.02659L6.83076 7.12504L6.16924 7.87496L8.32491 9.77651L8.98643 9.02659ZM8.3069 9.04327L6.15123 11.1417L6.84877 11.8583L9.00443 9.75982L8.3069 9.04327Z"
      fill={fill}
    />
    <path
      d="M10.75 8.95032C12.4501 8.39617 13.5 7.47946 13.5 6.43738C13.5 5.15909 11.8618 4.06121 9.5 3.59961"
      stroke={fillExtra}
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
  </svg>
);
