import { SimpleData } from '../types';

export const parseObjectToString = (obj: SimpleData) => {
  let result = '';

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      result += `${key}       ${obj[key]}\n`;
    }
  }

  // Remove the trailing newline character
  return result.trim();
};
