import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGeometryCheckResult = async (taskId: string | null): Promise<any> => {
  if (taskId) {
    const { data } = await axios.get(`/api/GeometryCheck/GetGeometryCheckResult?taskId=${taskId}`);

    return data;
  }
};

export const useGetGeometryCheckResult = (taskId: string | null) => {
  return useQuery(['geometryCheckResult', taskId], () => getGeometryCheckResult(taskId), {
    enabled: !!taskId,
    refetchOnWindowFocus: false,
  });
};
