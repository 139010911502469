import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

export type StartSpaceExtractionTask = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const startSpaceExtractionTask = async (request: StartSpaceExtractionTask): Promise<any> => {
  const url = `/api/SpaceExtraction/StartSpaceExtractionTask`;

  const { data } = await axios.post(`${url}`, request.body, {});
  return data.id;
};

export const useStartSpaceExtractionTask = () => {
  return useMutation(async (request: StartSpaceExtractionTask) => await startSpaceExtractionTask(request));
};
