import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useEditorContext } from '@/context/EditorContext';

import { AuralizerPanel } from './components/AuralizerPanel';
import { PerformanceDialog } from './components/PerformanceDialog';
import { AuralizerProvider } from './AuralizerContext';
import { PresetProvider } from './PresetContext';

export const Auralizer = () => {
  const { performanceMode } = useEditorContext();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (performanceMode) {
      datadogRum.addAction(`User enters performance mode in Auralizer`);
      const hasSeenPerformanceMode = localStorage.getItem('hasSeenPerformanceMode');
      if (!hasSeenPerformanceMode) {
        // Show the warning if the user is seeing it for the first time
        setShowPopup(true);
        // Set the flag in localStorage
        localStorage.setItem('hasSeenPerformanceMode', 'true');
      }
    }
  }, [performanceMode]);

  return (
    <AuralizerProvider>
      <PresetProvider>
        <AuralizerPanel />
        {showPopup && <PerformanceDialog setShowPopup={setShowPopup} />}
      </PresetProvider>
    </AuralizerProvider>
  );
};
