import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Box, Stack } from '@mui/material';

import { TabButtons } from '@/components/Shared';
import { Label } from '@/components/Shared/Label';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '../../Shared/Text';

import { MAX_DENSITY, MAX_NUMBER_INPUT, MIN_DENSITY } from '../constants';

import { PorousAbsorberFormValues, PorousAbsorberInputType, PorousAbsorberWoolType } from '../types';

type PorousAbsorberForm = {
  disabled: boolean;
};

export const PorousAbsorberForm = ({ disabled }: PorousAbsorberForm) => {
  const { porousAbsorberFormValues, dispatch } = useCreateMaterialContext();

  const setPorousAbsorberFormValues = (porousAbsorberFormValues: PorousAbsorberFormValues) => {
    dispatch({
      type: ActionType.SET_POROUS_ABSORBER_FORM_VALUES,
      formValues: porousAbsorberFormValues,
    });
  };

  const setInputType = (inputType: string) => {
    dispatch({
      type: ActionType.SET_POROUS_ABSORBER_FORM_VALUES,
      formValues: { ...porousAbsorberFormValues, inputType: inputType as PorousAbsorberInputType },
    });
  };

  return (
    <Stack gap={1.5} flex={'1 1 0'} minWidth="40%">
      <Text type="semibold-14px">Porous material</Text>
      <TrblNumberInput
        disabled={disabled}
        label="Thickness [mm]"
        toFixed={false}
        value={porousAbsorberFormValues.thickness}
        step={1}
        min={5}
        max={MAX_NUMBER_INPUT}
        onChange={(newValue) =>
          setPorousAbsorberFormValues({
            ...porousAbsorberFormValues,
            thickness: newValue,
          })
        }
        onBlur={(newValue) =>
          setPorousAbsorberFormValues({
            ...porousAbsorberFormValues,
            thickness: newValue,
          })
        }
        alignment="center"
        style={{ width: 50, margin: '0 auto' }}
      />

      <Box component="div" display="flex" flexDirection={'row'} justifyContent={'space-between'}>
        <Stack width={'730px'} padding={'2px 0 0'}>
          <TabButtons
            options={[
              {
                key: PorousAbsorberInputType.Density,
                label: (
                  <p style={{ marginBottom: '3px' }}>
                    Density kg/m<sup>3</sup>
                  </p>
                ),
                disabled: disabled,
              },
              {
                key: PorousAbsorberInputType.FlowResistivity,
                label: (
                  <p style={{ marginBottom: '3px' }}>
                    Flow resistivity Pa*s/m<sup>2</sup>
                  </p>
                ),
                disabled: disabled,
              },
            ]}
            selectedValue={porousAbsorberFormValues.inputType}
            onChange={setInputType}
          />
        </Stack>
        <Stack spacing={'8px'} width={'50%'} alignItems={'self-end'}>
          {porousAbsorberFormValues.inputType === PorousAbsorberInputType.FlowResistivity ? (
            <TrblNumberInput
              disabled={disabled}
              toFixed={false}
              value={porousAbsorberFormValues.flowResistivity}
              step={500}
              min={0}
              onChange={(newValue) =>
                setPorousAbsorberFormValues({
                  ...porousAbsorberFormValues,
                  flowResistivity: newValue,
                })
              }
              onBlur={(newValue) =>
                setPorousAbsorberFormValues({
                  ...porousAbsorberFormValues,
                  flowResistivity: newValue,
                })
              }
              alignment="center"
              style={{ width: 50, margin: '0 auto' }}
            />
          ) : (
            <TrblNumberInput
              disabled={disabled}
              toFixed={false}
              value={porousAbsorberFormValues.density}
              step={10}
              min={MIN_DENSITY}
              max={MAX_DENSITY}
              onChange={(newValue) =>
                setPorousAbsorberFormValues({
                  ...porousAbsorberFormValues,
                  density: newValue,
                })
              }
              onBlur={(newValue) =>
                setPorousAbsorberFormValues({
                  ...porousAbsorberFormValues,
                  density: newValue,
                })
              }
              alignment="center"
              style={{ width: 50, margin: '0 auto' }}
            />
          )}
        </Stack>
      </Box>
      <Box component="div" display="flex" flexDirection={'row'} justifyContent={'space-between'} minHeight={'24px'}>
        {porousAbsorberFormValues.inputType === PorousAbsorberInputType.Density && (
          <>
            <Stack width={'730px'} padding={'2px 0 0'}>
              <Label>Type of wool </Label>
            </Stack>
            <Stack spacing={'8px'} width={'50%'} alignItems={'self-end'}>
              <TabButtons
                options={[
                  {
                    key: PorousAbsorberWoolType.Rockwool,
                    label: 'Rockwool',
                    disabled: porousAbsorberFormValues.inputType !== PorousAbsorberInputType.Density || disabled,
                  },
                  {
                    key: PorousAbsorberWoolType.Glasswool,
                    label: 'Glasswool',
                    disabled: porousAbsorberFormValues.inputType !== PorousAbsorberInputType.Density || disabled,
                  },
                ]}
                selectedValue={porousAbsorberFormValues.woolType ?? PorousAbsorberWoolType.Rockwool}
                onChange={(newValue) =>
                  setPorousAbsorberFormValues({
                    ...porousAbsorberFormValues,
                    woolType: newValue,
                  })
                }
              />
            </Stack>
          </>
        )}
      </Box>
    </Stack>
  );
};
