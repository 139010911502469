import { CSSProperties, FC } from 'react';

import { HeadsetIRIcon } from '@/components/Icons';

import componentStyles from './styles.module.scss';

type ControlButtonProps = {
  style?: CSSProperties;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ByPassIRButton: FC<ControlButtonProps> = ({ style, ...props }) => (
  <button {...props} className={componentStyles['bypass-button']} style={style}>
    <HeadsetIRIcon />
  </button>
);
