import { usePresetContext } from '../PresetContext';
import { MasterGain } from './MasterGain';

import '../style.scss';

export const MasterColumn = () => {
  const { selectedPreset } = usePresetContext();

  return (
    <div className={`master-column fade-out`}>
      <p className="column-title"> Master </p>
      <MasterGain selectedPreset={selectedPreset} />
    </div>
  );
};
