import { useEffect } from 'react';

import { useResultComparisonContext } from '../context/ResultComparisonContext';

import { useGetLastGridReceiverResultsBySimulationId } from '.';

import { ActionType } from '../constants';

type UseLastGridResultsProps = {
  simulationId: string;
  isInGridReceiverView: boolean;
};

export const useLastGridResults = ({ simulationId, isInGridReceiverView }: UseLastGridResultsProps) => {
  const { dispatch } = useResultComparisonContext();

  const { data: lastGridResults } = useGetLastGridReceiverResultsBySimulationId(simulationId);

  // Dispatch an action to set the lastGridResults when we get new grid result
  // or when we switch to the grid receiver view
  useEffect(() => {
    if (lastGridResults?.length && isInGridReceiverView) {
      dispatch({
        type: ActionType.UPDATE_GRID_RECEIVERS_SOLVE_RESULTS,
        payload: lastGridResults,
      });
    }
  }, [lastGridResults, isInGridReceiverView]);

  return lastGridResults;
};
