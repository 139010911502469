import { useMutation } from '@tanstack/react-query';

import { SpaceDto } from '@/types';

import axios from '@/axios';

type CreateSpaceRequest = {
  name: string;
  description?: string;
  tag?: string;
};

const createSpace = async (space: CreateSpaceRequest) => {
  const { data } = await axios.post<SpaceDto>(`/api/Space/CreateSpace`, space);
  return data;
};

export const useCreateSpace = () => {
  return useMutation(async (space: CreateSpaceRequest) => await createSpace(space));
};
