import { ChangeEventHandler, CSSProperties, FocusEventHandler, forwardRef, ReactNode, useEffect, useRef } from 'react';

import { Label } from '@/components/Shared/Label';

import styles from './styles.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stepTimeout: any = null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stepInterval: any = null;
type NumberInputProps = {
  label?: string | ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  startAdornment?: string | React.ReactElement;
  endAdornment?: string;
  value?: number;
  decimals?: number;
  toFixed?: boolean;
  onChange?: (value?: number) => void;
  onBlur?: (value?: number) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>, value?: number) => void;
  onDoubleClick?: React.MouseEventHandler<HTMLInputElement>;
  alignment?: 'left' | 'right' | 'center';
  stepper?: boolean;
  style?: CSSProperties;
  underlined?: boolean;
  center?: boolean;
  allowEmpty?: boolean;
  blurOnStep?: boolean;
  autofocus?: boolean;
  selectOnClick?: boolean;
};

export const TrblNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      label,
      disabled,
      readOnly,
      min,
      max,
      step = 1,
      startAdornment,
      endAdornment,
      value,
      decimals = 0,
      alignment = 'left',
      toFixed = true,
      onChange,
      onBlur,
      onKeyDown,
      onDoubleClick,
      style,
      underlined = false,
      center = false,
      stepper = true,
      allowEmpty = false,
      blurOnStep = true,
      autofocus,
      selectOnClick = true,
    },
    ref
  ) => {
    const internalInputElement = useRef<HTMLInputElement>(null);

    // Assign the internal ref to the forwarded ref
    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(internalInputElement.current);
        } else {
          ref.current = internalInputElement.current;
        }
      }
    }, [ref]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const value = event.target.value != '' ? Number(event.target.value) : undefined;

      onChange && onChange(value);
    };

    const validateInput = (value?: number) => {
      if (value == undefined && !allowEmpty) {
        if (min && min > 0) value = min;
        else value = 0;
      }

      if (value !== undefined) {
        if (min != undefined && (Number.isNaN(value) || value < min)) {
          value = min;
        } else if (max && value > max) {
          value = max;
        }

        if (toFixed) {
          value = Number(value.toFixed(decimals));
        }
      }

      return value;
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      let value = event.target.value != '' ? Number(event.target.value) : undefined;
      value = validateInput(value);

      onChange && onChange(value);
      onBlur && onBlur(value);
    };

    const stepUp = () => {
      if (internalInputElement.current) {
        let value = Number(internalInputElement.current.value);
        value += step;

        if (max != undefined && value > max) value = max;

        if (step < 1) value = roundToDecimals(value);

        onChange && onChange(value);
        if (blurOnStep) onBlur && onBlur(value);

        if (document.activeElement !== internalInputElement?.current) internalInputElement.current?.focus();
      }
    };

    const stepDown = () => {
      if (internalInputElement.current) {
        let value = Number(internalInputElement.current.value);
        value -= step;

        if (min != undefined && value < min) value = min;

        if (step < 1) value = roundToDecimals(value);

        onChange && onChange(value);
        if (blurOnStep) onBlur && onBlur(value);

        if (document.activeElement !== internalInputElement?.current) internalInputElement.current?.focus();
      }
    };

    function clickStepUp(e: React.MouseEvent) {
      e.preventDefault();

      stepUp();
      stepTimeout = setTimeout(() => {
        stepInterval = setInterval(() => stepUp(), 40);
      }, 400);
    }

    function clickStepDown(e: React.MouseEvent) {
      e.preventDefault();

      stepDown();
      stepTimeout = setTimeout(() => {
        stepInterval = setInterval(() => stepDown(), 40);
      }, 400);
    }

    function clearTimers() {
      clearTimeout(stepTimeout);
      clearInterval(stepInterval);
    }

    const roundToDecimals = (value: number) => {
      return Math.round(value * 1e12) / 1e12;
    };

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.key === 'Enter') {
        internalInputElement.current?.blur();
        internalInputElement.current?.focus();
      }

      // If we have a keydown handler we pass the validated value to the handler since this is the default behavior of the input (blur event triggered)
      if (onKeyDown) {
        let value = event.target.value != '' ? Number(event.target.value) : undefined;
        value = validateInput(value);

        onKeyDown(event, value);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputClicked: React.MouseEventHandler<HTMLInputElement> = (event: any) => {
      event.target.select();
    };

    return (
      <div
        className={`${styles['number-input-container']} ${center ? styles['center'] : ''}`}
        onDoubleClick={onDoubleClick}>
        {label && <Label>{label}</Label>}
        <div className={`${styles['trbl-input-wrap']} ${disabled ? styles['disabled'] : ''}`}>
          <span className={styles['input-prefix']}>{startAdornment}</span>
          <input
            ref={internalInputElement}
            style={{ ...style }}
            className={`${styles['number-input']} ${alignment == 'right' ? styles['right-align'] : ''} ${
              alignment == 'center' ? styles['center-align'] : ''
            } ${startAdornment ? styles['prefix'] : ''} ${underlined ? styles['underlined'] : ''}  ${
              endAdornment
                ? endAdornment == '°'
                  ? styles['postfix-small']
                  : endAdornment.length > 1
                  ? styles['postfix-big']
                  : styles['postfix']
                : ''
            }`}
            type="number"
            value={value !== undefined && !isNaN(value) ? value : ''}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyPress}
            onClick={selectOnClick ? handleInputClicked : () => {}}
            tabIndex={readOnly ? -1 : undefined}
            autoFocus={autofocus}
          />
          {endAdornment && (
            <span
              className={`${styles['input-postfix']} ${endAdornment == '°' ? styles['postfix-small'] : ''} ${
                !stepper ? styles['no-stepper'] : ''
              }`}>
              {endAdornment}
            </span>
          )}
          {stepper && (
            <>
              <div
                onMouseDown={clickStepUp}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                className={`${styles['step']} ${styles['up']} ${readOnly ? 'hidden' : ''}`}>
                <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 0L6 3H0L3 0Z" />
                </svg>
              </div>
              <div
                onMouseDown={clickStepDown}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                className={`${styles['step']} ${styles['down']} ${readOnly ? 'hidden' : ''}`}>
                <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 3L6 0H0L3 3Z" />
                </svg>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);
