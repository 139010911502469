import { TrblTooltip } from '@/components/Shared';
import { TrblInfoIcon } from '@/components/Icons';

import { PERFORMANCE_INFO_TEXT, PERFORMANCE_INFO_TEXT_2 } from './constants';

import styles from './styles.module.scss';

export const ViewportInfo = () => {
  return (
    <div className={`${styles['performance-alert']}`}>
      <TrblTooltip title={CustomTooltip()} placement="bottom-end" dark arrow>
        <span>
          <TrblInfoIcon width="16" height="16" />
        </span>
      </TrblTooltip>
    </div>
  );
};

const CustomTooltip = () => {
  return (
    <div
      style={{
        padding: '8px',
        fontSize: '12px',
      }}>
      <p>{PERFORMANCE_INFO_TEXT}</p>
      <p style={{ marginTop: '8px' }}>{PERFORMANCE_INFO_TEXT_2}</p>
    </div>
  );
};
