import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

type ModelSourceIds = {
  [key: string]: string;
};

export const getModelSourceIds = async (modelId: string): Promise<ModelSourceIds> => {
  const { data } = await axios.get(`/api/Model/GetModelSourceIds?modelId=${modelId}`);

  return data;
};

export const useGetModelSourceIds = (modelId: string) => {
  return useQuery<ModelSourceIds>(['modelSourceIds', modelId], () => getModelSourceIds(modelId), {
    enabled: !!modelId,
    refetchOnWindowFocus: false,
  });
};
