import { cloneDeep } from 'lodash';

import { createGridPlane } from '@/components/Editor/components/Viewport/GridReceivers';
import { useCreateGridPoints } from '@/components/Editor/hooks/useCreateGridPoints';

import { GridReceiverPoint, GridReceiverUserInput, Simulation } from '@/types';

export const useDuplicateSimulation = () => {
  const createGridPoints = useCreateGridPoints();

  const duplicateSimulation = async (selectedSimulation: Simulation | null) => {
    if (selectedSimulation) {
      const simToCopy = cloneDeep(selectedSimulation);

      // parameters needed to be removed from the object so the Create simulation API call can run correctly
      // @ts-expect-error this because we shouldn't be able to delete id here but we have to
      delete simToCopy.id;
      delete simToCopy.extra;
      delete simToCopy.lastSimulationRun;

      simToCopy.hasBeenEdited = false;
      if (selectedSimulation.gridReceivers && selectedSimulation.gridReceivers.length) {
        for (let i = 0; i < selectedSimulation.gridReceivers.length; i++) {
          const grUserInput: GridReceiverUserInput = selectedSimulation.gridReceivers[i].userInput;
          const mesh = createGridPlane(grUserInput);

          let newPoints: GridReceiverPoint[] = [];
          [newPoints] = await createGridPoints(mesh, grUserInput);
          simToCopy.gridReceivers[i].points = newPoints;
        }
      }

      return simToCopy;
    }
  };
  return duplicateSimulation;
};
