import { RunStatus, Simulation } from '@/types';

export const getSimStatus = (simulation: Simulation, status: number | null): [number, string] => {
  if (!status) {
    return checkSimStatusAndRunDate(simulation); // 0 = empty, 1 = running, 2 = completed
  } else {
    return [status, ''];
  }
};

function checkSimStatusAndRunDate(simulation: Simulation): [number, string] {
  let returnStatus = 0;
  let lastSimRunDate = '';

  if (simulation && simulation.lastSimulationRun !== null && simulation.lastSimulationRun !== undefined) {
    const status = simulation.lastSimulationRun.status;
    lastSimRunDate = simulation.lastSimulationRun.createdAt;

    if (
      status &&
      [RunStatus.Queued, RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults].includes(status)
    ) {
      returnStatus = 1;
      lastSimRunDate = '';
    }
    if (status === RunStatus.Completed) {
      returnStatus = 2;
    }
    if (status === RunStatus.Cancelled) {
      returnStatus = 3;
    }
    if (status && [RunStatus.Error, RunStatus.TaskError].includes(status)) {
      returnStatus = 4;
    }
    if (status === RunStatus.InsufficientTokens) {
      returnStatus = 5;
    }
  }

  return [returnStatus, lastSimRunDate];
}
