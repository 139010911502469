import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@mui/material';

import { ChatWidgetIcon, TrblCloseIcon } from '@/components/Icons';
import { TrblTooltip } from '../Shared';
import { useHubspotChat } from './useHubspotChat';

import { HUBSPOT_INLINE_EMBED_ELEMENT_ID } from './constants';

import './style.scss';

export const Support: FC = () => {
  const location = useLocation();
  const { toggleWidget, isOpen, isReady, unreadMessagesCount } = useHubspotChat();

  return (
    <>
      <TrblTooltip
        title={
          !isReady ? (
            <div>
              <p style={{ marginBottom: '12px' }}>
                Chat couldn't load. Try disabling your ad blocker for this site and refresh.{' '}
              </p>
              <p>
                If this doesn't work please send an email to{' '}
                <a style={{ textDecoration: 'underline' }} href="mailto: support@treble.tech">
                  support@treble.tech
                </a>
                .
              </p>
            </div>
          ) : (
            ''
          )
        }>
        <span>
          <Button
            className={`support-button ${location.pathname === '/results' ? 'pushed-up' : 'normal'} ${
              location.pathname === '/auralizer' ? 'pushed-up auralizer' : 'normal'
            } ${!isReady ? 'disable' : ''} ${isOpen ? 'open' : ''} `}
            variant="text"
            startIcon={
              isOpen ? <TrblCloseIcon /> : <ChatWidgetIcon fill={unreadMessagesCount > 0 ? '#00BFFF' : '#dadada'} />
            }
            onClick={() => {
              if (isReady) toggleWidget();
            }}>
            {isOpen ? 'Close chat' : 'Support chat'}
          </Button>
        </span>
      </TrblTooltip>
      <div id={HUBSPOT_INLINE_EMBED_ELEMENT_ID} className={`live-chat-container ${isOpen ? 'open' : 'close'}`}></div>
    </>
  );
};
