import React from 'react';

import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';

import { AXIS_TYPE_OPTIONS } from './constants';

import { AxisType } from './types';

import styles from './styles.module.scss';

type PlotHeaderProps = {
  selectedXAxisType: AxisType;
  onXAxisTypeChange: (value: AxisType) => void;
};

export const PlotHeader: React.FC<PlotHeaderProps> = ({ selectedXAxisType, onXAxisTypeChange }) => (
  <div className={styles['plot-header']}>
    <Text type="semibold-12px">Reflections</Text>
    <TrblSelect
      menuItems={AXIS_TYPE_OPTIONS}
      value={selectedXAxisType}
      setValue={(value) => onXAxisTypeChange(value as AxisType)}
      minimal
      className={styles['header-dropdown']}
    />
  </div>
);
