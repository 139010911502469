import { ReactNode } from 'react';

import styles from './styles.module.scss';

type IconFilterTagProps = {
  tag: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectTag: (tag: string, event: any) => void;
  isSelected?: boolean;
  icon?: ReactNode;
};

export const IconFilterTag = ({ tag, selectTag, isSelected, icon }: IconFilterTagProps) => {
  return (
    <button
      className={`${styles['tag']} ${styles['icon-tag']}  ${icon ? styles['has-icon'] : ''} ${
        isSelected ? styles['selected'] : ''
      } `}
      onClick={(event) => selectTag(tag, event)}>
      <span>{tag}</span>
      {icon}
    </button>
  );
};
