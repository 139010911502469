export const TrblAlertIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98944 1.00066C5.85191 1.03565 3.81402 1.91044 2.31629 3.43591C0.802123 4.94342 -0.0336826 7.00201 0.00104028 9.13838C-0.00144312 11.225 0.826688 13.2268 2.3026 14.7019C3.77851 16.1769 5.78081 17.0038 7.86744 17.0001H8.0101C12.4621 16.9543 16.0368 13.3132 16.0005 8.86106C16.0055 6.74856 15.1581 4.72337 13.6502 3.24385C12.1424 1.76434 10.1015 0.955588 7.98944 1.00066ZM7.00029 12.0281C6.99058 11.764 7.08748 11.5072 7.26917 11.3153C7.45086 11.1235 7.70207 11.0128 7.96626 11.0081H7.98425C8.53092 11.0092 8.97915 11.4418 8.99955 11.9881C9.00945 12.2522 8.91261 12.5091 8.73088 12.701C8.54915 12.8929 8.29783 13.0036 8.03359 13.008H8.01559C7.4692 13.0063 7.02137 12.5741 7.00029 12.0281ZM7.33443 5.33371V9.33358C7.33443 9.70176 7.6329 10.0002 8.00108 10.0002C8.36925 10.0002 8.66772 9.70176 8.66772 9.33358V5.33371C8.66772 4.96554 8.36925 4.66707 8.00108 4.66707C7.6329 4.66707 7.33443 4.96554 7.33443 5.33371Z"
      fill="#00F5BA"
    />
  </svg>
);
