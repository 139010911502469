import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblAddIcon } from '@/components/Icons';
import { OrganizationsGrid } from './OrganizationsGrid';
import { OrganizationFormState, OrganizationSidepanel } from './OrganizationSidepanel';

import { useCreateOrganization, useGetOrganizations } from '../hooks';

export const Organizations: FC = () => {
  const navigate = useNavigate();

  const [showAddOrganization, setShowAddOrganization] = useState(false);

  const { data: organizations } = useGetOrganizations();
  const { mutate: createOrganization } = useCreateOrganization();

  const handleAddButtonClick = () => {
    setShowAddOrganization(true);
  };

  const handleSubmitOrganization = (values: OrganizationFormState) => {
    createOrganization(values, {
      onSuccess: (organization) => {
        setShowAddOrganization(false);
        navigate(organization.id);
      },
    });
  };

  return (
    <Box component="div" display="flex" flexDirection={'column'} height="100%" gap={'6px'} padding={'13px 30px'}>
      <Box component={'div'} display="flex" alignItems={'end'} justifyContent={'space-between'}>
        <p style={{ fontWeight: '600' }}>Organizations</p>
        <PrimaryButton
          label="Add organization"
          icon={<TrblAddIcon fill="none" />}
          onClick={handleAddButtonClick}
          width={170}
          sx={{ marginBottom: '2px' }}
        />
      </Box>
      <Box component="div" flex="1 1 auto">
        {organizations && <OrganizationsGrid organizations={organizations} />}
      </Box>
      {showAddOrganization && (
        <OrganizationSidepanel onCancel={() => setShowAddOrganization(false)} onSubmit={handleSubmitOrganization} />
      )}
    </Box>
  );
};
