import { useMemo } from 'react';

import { useGetDownloadUrl, useLoadAndExtractFileFromUrl } from '@/hooks';

export const useGetThumbnail = (modelUploadId: string) => {
  const { data: downloadUrl } = useGetDownloadUrl(modelUploadId);
  const { data: thumbnailFile } = useLoadAndExtractFileFromUrl(
    downloadUrl || null,
    'thumbnail_' + modelUploadId,
    '_thumbnail'
  );

  const thumbnailSrc = useMemo(
    () => (thumbnailFile ? URL.createObjectURL(new Blob([thumbnailFile.fileData])) : undefined),
    [thumbnailFile]
  );

  return { thumbnailSrc, thumbnailFile };
};
