import { FC } from 'react';

import { NameMarker } from '@/components/Shared/NameMarker';
import { Text } from '@/components/Shared/Text';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import classes from './styles.module.scss';

type ShareRowProps = {
  isAnonymous?: boolean;
  email?: string;
  color?: string;
  showMenu?: boolean;
  onDeleteShare: (email?: string) => void;
  onResendShare?: (email: string) => void;
  onCopyAnonymousLink?: () => void;
};

export const ShareRow: FC<ShareRowProps> = ({
  isAnonymous = false,
  email,
  color,
  showMenu = true,
  onDeleteShare,
  onResendShare,
  onCopyAnonymousLink,
}) => {
  const actions = [];
  if (email && onResendShare) {
    actions.push({ key: 'resend', value: 'Resend', title: 'Resend', onClick: () => onResendShare(email) });
  } else if (isAnonymous && onCopyAnonymousLink) {
    actions.push({ key: 'copy', value: 'Copy link', title: 'Copy link', onClick: () => onCopyAnonymousLink() });
  }
  actions.push({ key: 'delete', value: 'Delete', title: 'Delete', onClick: () => onDeleteShare(email) });

  return (
    <div className={classes['share-row']}>
      <div className={classes['details']}>
        <NameMarker
          isAnonymous={isAnonymous}
          tooltip={email}
          color={color}
          content={email?.charAt(0).toUpperCase() || ''}
        />
        <Text type="regular-12px">{isAnonymous ? 'Anonymous link' : email}</Text>
      </div>
      <div className={classes['actions']} style={{ visibility: showMenu ? undefined : 'hidden' }}>
        <TrblActionsMenu id="row-actions" actions={actions} />
      </div>
    </div>
  );
};
