import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const getFeedbackByUrl = async (url: string) => {
  const { data } = await axios.get(url);
  return data;
};

export const useGetGeometryFeedbackByUrl = (url?: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQuery<any, boolean>(['feedback-url', url], () => getFeedbackByUrl(url!), {
    enabled: !!url,
    refetchOnWindowFocus: false,
  });
