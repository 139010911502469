import { FC } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '../Buttons';

import styles from './styles.module.scss';

type ConfirmationDialogProps = {
  title: string;
  message?: string | (() => JSX.Element);
  onConfirm: () => void;
  onCancel: () => void;
  onSecondOption?: () => void;
  confirmLabel?: string;
  secondOptionLabel?: string;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
  onSecondOption,
  confirmLabel,
  secondOptionLabel,
}) => {
  return (
    <Dialog open={true} aria-labelledby={title} className={styles['confirmation-dialog-container']}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{typeof message === 'function' ? message() : message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={styles['dialog-action-buttons']}>
          <SecondaryButton label="Cancel" onClick={onCancel} width={150} />
          <Box component="div" display={'flex'}>
            {secondOptionLabel && (
              <Box component="div" marginRight="10px">
                <button className={styles['confirmation-button']} onClick={onSecondOption} id="EditConfirmationButton">
                  {secondOptionLabel}
                </button>
              </Box>
            )}
            <PrimaryButton label={`${confirmLabel ? confirmLabel : 'Confirm'}`} onClick={onConfirm} width={150} />
          </Box>
        </div>
      </DialogActions>
    </Dialog>
  );
};
