import React from 'react';

import { useMediaQuery } from '@mui/material';

import { TabButtons } from '@/components/Shared';
import { TrblSelect } from '@/components/Shared/TrblSelect';

import { IR_TYPE_OPTIONS, SPL_TYPE_OPTIONS } from './constants';

import { IRType, SingleReflectionPlotType, SPLType } from './types';

import styles from './styles.module.scss';

type PlotHeaderProps = {
  selectedPlotType: SingleReflectionPlotType;
  selectedSplType: SPLType;
  selectedIrType: IRType;
  onPlotTypeChange: (value: SingleReflectionPlotType) => void;
  onSplTypeChange: (value: SPLType) => void;
  onIrTypeChange: (value: IRType) => void;
};

export const PlotHeader: React.FC<PlotHeaderProps> = ({
  selectedPlotType,
  selectedSplType,
  selectedIrType,
  onPlotTypeChange,
  onSplTypeChange,
  onIrTypeChange,
}) => {
  const isSmallHeightScreen = useMediaQuery('(max-height: 1000px)');

  const handlePlotTypeChange = (value: string) => {
    onPlotTypeChange(value as SingleReflectionPlotType);
  };

  return (
    <div className={styles['plot-header']}>
      <span style={{ width: 'fit-content' }}>
        <TabButtons
          small={isSmallHeightScreen}
          options={[
            { key: 'spl', label: 'SPL per band' },
            { key: 'ir', label: 'IR', tooltip: 'Impulse Response' },
          ]}
          selectedValue={selectedPlotType}
          onChange={handlePlotTypeChange}
        />
      </span>
      {selectedPlotType === 'spl' ? (
        <TrblSelect
          menuItems={SPL_TYPE_OPTIONS}
          value={selectedSplType}
          setValue={(value) => onSplTypeChange(value as SPLType)}
          minimal
          className={styles['header-dropdown']}
        />
      ) : (
        <TrblSelect
          menuItems={IR_TYPE_OPTIONS}
          value={selectedIrType}
          setValue={(value) => onIrTypeChange(value as IRType)}
          minimal
          className={styles['header-dropdown']}
        />
      )}
    </div>
  );
};
