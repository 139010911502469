import { FC } from 'react';

import './styles.scss';

type RadioButtonProps = {
  disabled?: boolean;
  readonly?: boolean;
  selected?: boolean;
  label: string;
  onClick: React.MouseEventHandler;
};
export const RadioButton: FC<RadioButtonProps> = ({ label, selected, disabled = false, readonly = false, onClick }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      onClick(event);
    }
  };
  return (
    <span
      tabIndex={0}
      className={`radio-button ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}  ${
        readonly ? 'readonly' : ''
      }`}
      onClick={onClick}
      onKeyUp={handleKeyUp}>
      {label}
    </span>
  );
};
