import { useEffect, useRef, useState } from 'react';

import { usePresetContext } from '@/components/Auralizer/PresetContext';
import { LibraryHeader, LibraryList } from '@/components/LibraryPanel';
import { LibrarySearch } from '@/components/LibraryPanel/components/LibrarySearch';
import { useHandleKeyDown } from '@/components/LibraryPanel/hooks/useHandleKeyDown';
import { useSearchForString } from '@/components/LibraryPanel/hooks/useSearchForString';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { PresetEmpty } from './PresetEmpty';
import { PresetRow } from './PresetRow';

import { AuralizationPresetDto } from '../types';

import styles from './styles.module.scss';

export const LoadPresetsLibrary = ({
  availablePresets,
  sharedPresets,
  setShowPopup,
}: {
  availablePresets: AuralizationPresetDto[];
  sharedPresets: string[];
  setShowPopup: (show: boolean) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const libraryPanelRef = useRef<HTMLDivElement>(null);
  const { selectedPreset } = usePresetContext();

  const { highlightedItemId } = useLibraryPanelContext();

  const [filteredPresets, setFilteredPresets] = useState<AuralizationPresetDto[]>([]);

  useHandleKeyDown(inputRef, libraryPanelRef);
  const searchForString = useSearchForString(availablePresets, ['name']);

  useEffect(() => {
    if (availablePresets) {
      setFilteredPresets(availablePresets);
    }
  }, [availablePresets]);

  // TODO: move into a shared function because this also lives in the library panel
  const searchForPreset = (key: string, inputValue: string) => {
    const filteredPresets: AuralizationPresetDto[] = searchForString(key, inputValue) as AuralizationPresetDto[];
    setFilteredPresets(filteredPresets);
  };

  return (
    <div className={styles.library_content_container}>
      <LibrarySearch
        inputRef={inputRef}
        label="presets"
        searchLibrary={searchForPreset}
        autofocus={!highlightedItemId && !selectedPreset}
      />
      <div ref={libraryPanelRef} className={styles.library_content}>
        <LibraryHeader>
          <p className={styles.name_column}>Name</p>
          <p className={styles.shared_column}>Shared</p>
          <p className={styles.created_column}>Created</p>
        </LibraryHeader>
        <div className={styles.library_content_list}>
          <LibraryList>
            {filteredPresets.length === 0 ? (
              <PresetEmpty />
            ) : (
              filteredPresets.map((preset) => (
                <PresetRow key={preset.id} preset={preset} sharedPresets={sharedPresets} setShowPopup={setShowPopup} />
              ))
            )}
          </LibraryList>
        </div>
      </div>
    </div>
  );
};
