import { useSimulationContext } from '@/context/SimulationContext';

import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';

import { GRID_RECEIVER_SAVE_TEXT } from '../constants';

import { GridReceiver as EditorGridReceiver } from '@/context/EditorContext/types';
import { GridReceiver } from '@/types';
import { Simulation } from '@/types';

export const useSaveGridReceivers = () => {
  const saveSimulation = useSaveUpdatedSimulation();
  const confirmEdit = useConfirmEdit();

  // Since a change to a source might also change the grid receivers (valid points) we need to
  // get the latest sources from the editor context since the selected simulation might not have
  // the latest changes
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();

  const saveGridReceivers = async (gridReceivers: EditorGridReceiver[]) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Simulation | null>(async (resolve) => {
      const canContinue = await confirmEdit();

      const newGridReceivers: GridReceiver[] = gridReceivers.map((gr, index) => ({
        ...gr,
        orderNumber: index,
        geometryInfo: {},
      }));

      if (selectedSimulation) {
        const updatedSimulation = {
          ...selectedSimulation,
          sources: [...selectedSimulation.sources],
          gridReceivers: [...newGridReceivers],
        };

        const saveText = GRID_RECEIVER_SAVE_TEXT;
        if (canContinue) {
          saveSimulation(updatedSimulation, saveText);
          resolve(null);
        } else {
          resolve(updatedSimulation);
        }
      }
    });
  };

  return { saveGridReceivers };
};
