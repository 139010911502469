import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MeshTaskDto } from '@/components/SolverSettings/types';

import axios from '@/axios';

const getActiveMeshTasks = async (modelId: string): Promise<MeshTaskDto[]> => {
  const { data } = await axios.get(`/api/Mesh/GetActiveMeshTasksForModel?modelId=${modelId}`);

  return data;
};

export const useGetActiveMeshTasks = (modelId: string) =>
  useQuery<MeshTaskDto[], AxiosError>(['active-mesh-tasks', modelId], () => getActiveMeshTasks(modelId), {
    enabled: !!modelId,
    refetchOnWindowFocus: false,
    onError: () => {
      toast.error(`Could not get active mesh tasks for model with id ${modelId}`, {
        className: 'editor-toast',
        toastId: `active-mesh-tasks-${modelId}`,
      });
    },
  });
