import { toast } from 'react-toastify';

import { ResultComparison } from '../Results/types';

export const checkProjectsForSavingResults = (availableComparisons: ResultComparison[]) => {
  if (availableComparisons.length > 0) {
    const projectId = availableComparisons[0].formState?.projectId;
    let hasMultipleProjects = false;
    for (const comparison of availableComparisons) {
      if (projectId !== comparison.formState?.projectId) {
        hasMultipleProjects = true;
        break;
      }
    }
    if (hasMultipleProjects) {
      toast.warning('Results can only be saved with simulations that belong to the same project');
      return false;
    }
  } else {
    toast.warning('No simulation selected. Add simulations to save your results.');
    return false;
  }
  return true;
};
