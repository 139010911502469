import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblHeatmapIcon: FC<IconProps> = ({ fill }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9585 4.95821V2.04155C13.9585 1.39721 13.4362 0.874878 12.7918 0.874878L2.87516 0.874879C2.23083 0.874879 1.7085 1.39721 1.7085 2.04155L1.7085 5.24988C1.7085 5.57204 1.96966 5.83321 2.29183 5.83321C2.614 5.83321 2.87516 5.57204 2.87516 5.24988L2.87516 4.22905C2.87501 4.19032 2.89032 4.15314 2.91771 4.12575C2.94509 4.09837 2.98227 4.08306 3.021 4.08321H4.3335C4.65566 4.08321 4.91683 3.82204 4.91683 3.49988C4.91683 3.17771 4.65566 2.91654 4.3335 2.91654H3.021C2.98227 2.9167 2.94509 2.90139 2.91771 2.874C2.89032 2.84662 2.87501 2.80944 2.87516 2.77071V2.18738C2.87501 2.14865 2.89032 2.11147 2.91771 2.08409C2.94509 2.0567 2.98227 2.04139 3.021 2.04154L8.56266 2.04154C8.64321 2.04154 8.7085 2.10684 8.7085 2.18738V2.77071C8.7085 2.85125 8.64321 2.91654 8.56266 2.91654H6.37516C6.053 2.91654 5.79183 3.17771 5.79183 3.49988C5.79183 3.82204 6.053 4.08321 6.37516 4.08321L9.29183 4.08321C9.614 4.08321 9.87516 3.82204 9.87516 3.49988C9.87516 3.17771 9.87516 2.18738 9.87516 2.18738C9.87501 2.14865 9.89032 2.11147 9.91771 2.08409C9.94509 2.0567 9.98227 2.04139 10.021 2.04155H12.646C12.7265 2.04155 12.7918 2.10684 12.7918 2.18738V3.93738C12.7918 4.01792 12.7265 4.08321 12.646 4.08321C12.6073 4.08337 12.5701 4.06805 12.5427 4.04067C12.5153 4.01329 12.5 3.9761 12.5002 3.93738V3.49988C12.5002 3.17771 12.239 2.91655 11.9168 2.91655C11.5947 2.91655 11.3335 3.17771 11.3335 3.49988V3.79155C11.3335 4.11371 11.5947 4.37488 11.9168 4.37488H12.646C12.7265 4.37488 12.7918 4.44017 12.7918 4.52071V4.95821C12.7918 5.28038 13.053 5.54155 13.3752 5.54154C13.6973 5.54154 13.9585 5.28038 13.9585 4.95821Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M12.7918 13.1249C13.4362 13.1249 13.9585 12.6025 13.9585 11.9582L13.9585 4.37488C13.9585 4.05271 13.6973 3.79155 13.3752 3.79155C13.053 3.79155 12.7918 4.05271 12.7918 4.37488L12.7918 11.8124C12.7918 11.8929 12.7265 11.9582 12.646 11.9582L11.771 11.9582C11.7323 11.9584 11.6951 11.9431 11.6677 11.9157C11.6403 11.8883 11.625 11.8511 11.6252 11.8124L11.6252 10.4999C11.6252 10.1777 11.364 9.91654 11.0418 9.91654C10.7197 9.91654 10.4585 10.1777 10.4585 10.4999L10.4585 11.8124C10.4585 11.8929 10.3932 11.9582 10.3127 11.9582H9.43767C9.39894 11.9584 9.36176 11.9431 9.33437 11.9157C9.30699 11.8883 9.29168 11.8511 9.29183 11.8124V8.60404C9.29183 8.28188 9.03067 8.02071 8.7085 8.02071C7.9326 8.02071 8.36526 8.02071 7.97933 8.02071H7.10433C7.06561 8.02087 7.02843 8.00555 7.00104 7.97817C6.97366 7.95079 6.95834 7.9136 6.9585 7.87488V7.43738C6.9585 7.11521 6.69733 6.85404 6.37517 6.85404C6.053 6.85405 5.79183 7.11521 5.79183 7.43738V8.60404C5.79183 8.92621 6.053 9.18738 6.37517 9.18738H7.97933C8.05987 9.18738 8.12517 9.25267 8.12517 9.33321V11.8124C8.12517 11.8929 8.05987 11.9582 7.97933 11.9582H3.021C2.98227 11.9584 2.94509 11.9431 2.91771 11.9157C2.89032 11.8883 2.87501 11.8511 2.87516 11.8124V11.229C2.87501 11.1903 2.89032 11.1531 2.91771 11.1258C2.94509 11.0984 2.98227 11.0831 3.021 11.0832H5.35433C5.6765 11.0832 5.93767 10.822 5.93767 10.4999C5.93767 10.1777 5.6765 9.91654 5.35433 9.91654H3.021C2.98227 9.9167 2.94509 9.90139 2.91771 9.874C2.89032 9.84662 2.87501 9.80944 2.87516 9.77071L2.87516 4.66654C2.87516 4.34438 2.614 4.08321 2.29183 4.08321C1.96967 4.08321 1.7085 4.34438 1.7085 4.66654L1.7085 11.9582C1.7085 12.6025 2.23083 13.1249 2.87516 13.1249L12.7918 13.1249Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M11.0418 5.54154V7.14571C11.0417 7.18444 11.057 7.22162 11.0844 7.249C11.1118 7.27639 11.1489 7.2917 11.1877 7.29154H13.3752C13.6973 7.29154 13.9585 7.55271 13.9585 7.87488C13.9585 8.19704 13.6973 8.45821 13.3752 8.45821H10.4585C10.1363 8.45821 9.87517 8.19704 9.87517 7.87488V6.27071C9.87517 6.19017 9.80988 6.12488 9.72934 6.12488L5.35433 6.12488C5.3156 6.12472 5.27842 6.14004 5.25104 6.16742C5.22366 6.1948 5.20834 6.23199 5.2085 6.27071V7.29154C5.2085 7.61371 4.94733 7.87488 4.62516 7.87488H2.29183C1.96967 7.87488 1.7085 7.61371 1.7085 7.29154C1.7085 6.96938 1.96967 6.70821 2.29183 6.70821H3.896C3.97654 6.70821 4.04183 6.64292 4.04183 6.56238L4.04183 5.54154C4.04183 5.21938 4.303 4.95821 4.62516 4.95821L10.4585 4.95821C10.7807 4.95821 11.0418 5.21938 11.0418 5.54154Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
