import { Material, ModelInformationDto, RunStatus, Simulation } from '@/types';

export const sortMaterials = (materials: Material[]) => {
  const sortedMaterialsByName = materials.sort((a: Material, b: Material) => {
    const aName = a.name.trim().toLowerCase();
    const bName = b.name.trim().toLowerCase();
    const aCategory = a.category.trim().toLowerCase();
    const bCategory = b.category.trim().toLowerCase();
    if (aCategory < bCategory) {
      return -1;
    }
    if (aCategory > bCategory) {
      return 1;
    }
    if (aCategory === bCategory) {
      return aName > bName ? 1 : -1;
    }
    return 0;
  });

  return sortedMaterialsByName;
};

export const setSimulationStatus = (simulation: Simulation) => {
  let returnStatus = 0;

  if (simulation && simulation.lastSimulationRun !== null && simulation.lastSimulationRun !== undefined) {
    const status = simulation.lastSimulationRun.status;

    if (status) {
      if ([RunStatus.Queued, RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults].includes(status)) {
        returnStatus = 1;
      }
      if (status === RunStatus.Completed) {
        returnStatus = 2;
      }
      if (status === RunStatus.Cancelled) {
        returnStatus = 3;
      }
      if ([RunStatus.Error, RunStatus.TaskError].includes(status)) {
        returnStatus = 4;
      }
      if (status === RunStatus.InsufficientTokens) {
        returnStatus = 5;
      }
    }
  }

  simulation.extra.status = returnStatus;
};

export const isExampleModelCheck = (modelInformation: ModelInformationDto | null) => {
  // For now we can only see if it's an example model based on the
  // spaceTag, spaceName and modelBaseName
  // TODO: Ask backend to mark the example models?
  if (modelInformation?.spaceTag == 'Example project') {
    const spaceName = modelInformation.spaceName;
    const modelName = modelInformation.modelBaseName;

    if (
      spaceName == 'Classroom' ||
      spaceName == 'Home theatre' ||
      spaceName == 'Open office' ||
      spaceName == 'Lecture hall'
    ) {
      if (modelName == 'Initial design' || modelName === spaceName + ' example') {
        return true;
      }
    }
  }

  return false;
};
