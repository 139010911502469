import { Box, CircularProgress } from '@mui/material';

import { TrblRecentSimulations } from '../Icons';
import { Text } from '../Shared/Text';
import { RecentSimulationStatus } from './RecentSimulationStatus';

import { useGetSimulationRunStatusesByUser } from '@/hooks';

import { SimulationRunStatusDto } from '@/types';

import classes from './styles.module.scss';

let hasLoadedOnce = false;

export const RecentSimulationsHeader = ({ showBorder = true }: { showBorder?: boolean }) => (
  <h4 className={`${classes.recent_simulations_header} ${showBorder ? classes.border_bottom : ''}`}>
    <TrblRecentSimulations></TrblRecentSimulations> Recent simulations
  </h4>
);

export const RecentSimulationsContent = ({ showHeader = true }: { showHeader: boolean }) => {
  const { data: recentSimulations, isFetching: isFetchingTaskStatuses } = useGetSimulationRunStatusesByUser(6);

  if (!isFetchingTaskStatuses && recentSimulations) {
    hasLoadedOnce = true;
  }

  return (
    <div className={classes.recent_simulations_container}>
      {isFetchingTaskStatuses && !hasLoadedOnce ? (
        <div className={classes.loading_container}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {showHeader && <RecentSimulationsHeader />}
          <div className={classes.recent_simulations_list}>
            {recentSimulations && hasLoadedOnce && (
              <>
                {recentSimulations.length > 0 ? (
                  recentSimulations.map((simulationRunStatus: SimulationRunStatusDto) => (
                    <RecentSimulationStatus key={simulationRunStatus.id} simulationRunStatus={simulationRunStatus} />
                  ))
                ) : (
                  <Box component="div" padding={'16px 20px 20px 20px'}>
                    <Text type="regular-10px" color={'#999'}>
                      There are no recent simulations
                    </Text>
                  </Box>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
