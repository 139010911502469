import { Fragment } from 'react';

import { Material } from '@/types';

import './style.scss';

export const AbsorptionPlotPreview = ({
  material,
  isHighlighted,
  setShowMaterialPopup,
}: {
  material: Material;
  isHighlighted: boolean;
  setShowMaterialPopup: (show: boolean) => void;
}) => {
  const abs = material.absorptionCoefficients ?? [];

  return (
    <div
      className="ab-sticks"
      title="View material details"
      onClick={(e) => {
        if (isHighlighted) e.stopPropagation();
        setShowMaterialPopup(true);
      }}>
      <span className="grid">
        <div className="line hor one"></div>
        <div className="line hor two"></div>
        <div className="line ver one"></div>
        <div className="line ver two"></div>
        <div className="line ver three"></div>
        <div className="line ver four"></div>
        <div className="line ver five"></div>
        <div className="line ver six"></div>
      </span>
      {abs.map((singleAbs: number, index: number) => {
        const ab = Math.floor(singleAbs * 16) - 1;
        const ab2 = Math.floor(abs[index + 1] * 16) + 0.5;
        return (
          <Fragment key={index}>
            <svg height="20" className="circle">
              <circle r="1.25" stroke="#0ead87" fill="none" cx="1.6" cy={20 - (ab + 1.5)} strokeWidth="0.75"></circle>
            </svg>
            {index < abs.length - 1 ? (
              <svg width="4" className="line" height="20">
                <line x1="-1" y1={20 - (ab + 1.5)} x2="5" y2={20 - ab2} stroke="#0ead87" strokeWidth="0.75"></line>
              </svg>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};
