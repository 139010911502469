import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

const { mode } = config;

export type ServiceMessage = {
  status: ServiceStatus;
  message: string;
  id: string;
};

// Statuses and environments are documented in this Confluence page:
// https://treble-tech.atlassian.net/wiki/spaces/PM/pages/523403490/Service+down
export enum ServiceStatus {
  warning = 'warning',
  offline = 'offline',
  operational = 'operational',
}

enum ServiceEnvMap {
  dev = 'dev', // workaround because we have a custom env variable for dev build which we should fix for the whole project later to be the same as running the project locally
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  development = 'dev',
  staging = 'staging',
  production = 'prod',
}

export const getServiceMessage = async (): Promise<ServiceMessage> => {
  const env = mode as 'development' | 'staging' | 'production' | 'dev';
  const response = await fetch(`https://ts.eu2.treble.tech/system?id=tass-${ServiceEnvMap[env]}`);
  const data = await response.json();
  return data;
};

export const useGetServiceMessage = () => {
  return useQuery<ServiceMessage>(['service-message'], () => getServiceMessage(), {
    refetchOnWindowFocus: true,
    refetchInterval: 5 * 60 * 1000, // every 5 minutes
  });
};
