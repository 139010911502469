import { FC } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblCancelledIcon, TrblCompletedIcon, TrblEditIcon } from '@/components/Icons';

type ExpiryDateControlsProps = {
  isInEditMode: boolean;
  setIsInEditMode: () => void;
  editTitle: string;
  disabled: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const EditControls: FC<ExpiryDateControlsProps> = ({
  isInEditMode = false,
  setIsInEditMode,
  editTitle,
  disabled,
  onSave,
  onCancel,
}) => {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {!isInEditMode && (
        <TrblTooltip title={editTitle}>
          <span>
            <TrblIconButton
              size="small"
              icon={<TrblEditIcon fill="#cccccc" height="10px" width="10px" />}
              onClick={setIsInEditMode}
            />
          </span>
        </TrblTooltip>
      )}
      {isInEditMode && (
        <>
          <TrblTooltip title={disabled ? 'No changes' : 'Confirm'}>
            <span>
              <TrblIconButton
                size="small"
                icon={<TrblCompletedIcon fill={disabled ? '#616161' : '#00F5BA'} height="14px" width="14px" />}
                disabled={disabled}
                onClick={onSave}
              />
            </span>
          </TrblTooltip>
          <TrblTooltip title="Cancel">
            <span>
              <TrblIconButton
                size="small"
                icon={<TrblCancelledIcon fill="#fd5b5b" height="14px" width="14px" />}
                onClick={onCancel}
              />
            </span>
          </TrblTooltip>
        </>
      )}
    </div>
  );
};
