import { useMutation } from '@tanstack/react-query';
import { generateUUID } from 'three/src/math/MathUtils';

import axios from '@/axios';

export type StartSourceSummingTaskRequest = {
  simulationId: string;
  sources: string[];
  name: string;
  resultType: string;
};

const startSourceSummingTask = async (request: StartSourceSummingTaskRequest): Promise<string> => {
  const { data } = await axios.post<string>(`/api/SourceSumming/StartSourceSummingTask`, {
    ...request,
    description: '',
    id: generateUUID(), // To be removed and set in the backend
    status: 'Created', // To be removed and set in the backend
  });
  return data;
};

export const useStartSourceSummingTask = () => {
  return useMutation(async (request: StartSourceSummingTaskRequest) => await startSourceSummingTask(request));
};
