import { Text } from '@/components/Shared/Text';

import classes from './styles.module.scss';

export const DisclaimerText = () => {
  return (
    <div className={classes['disclaimer']}>
      <Text type="regular-10px" color="#999">
        Shared links can be opened by anyone with the link
      </Text>
    </div>
  );
};
