import { Text } from '@react-three/drei';
import { BoxGeometry, Euler, Vector3 } from 'three';

import { MeasurementLines } from './MeasurementLines';
import { MeshWithColor } from './MeshWithColor';
import { TiltedLineLeft } from './TiltedLineLeft';

import { BoxProps, getAdjustedXPos, scaledBoxWidth, TEXT_CONFIG } from '../constants';

export const AirCavityBox = ({ box, index }: { box: BoxProps; index: number }) => {
  const { width, height, depth, color, xPos } = box;
  const needsExtraSpace = width <= 8.9;

  const textConfig = {
    ...TEXT_CONFIG,
    rotation: new Euler(89.6, 0, 0),
    position: needsExtraSpace ? new Vector3(-0.7, -0.8, 2.5) : new Vector3(0, -0.8, 2.3),
  };

  return (
    <>
      {width > 0 ? (
        <group>
          <mesh position={new Vector3(getAdjustedXPos(xPos, index) || 0, 0, 0)}>
            <Text {...textConfig} anchorX="center" anchorY="top" textAlign="center" maxWidth={10}>
              {`${(width * 10).toFixed()} mm`}
            </Text>
            {needsExtraSpace ? <TiltedLineLeft /> : null}
            <MeasurementLines width={width} />
            <boxGeometry args={[scaledBoxWidth(width), height, depth]} />
            <MeshWithColor index={0} />
            <MeshWithColor index={1} />
            <MeshWithColor index={2} />
            <MeshWithColor index={3} />
            <MeshWithColor index={4} />
            <MeshWithColor index={5} />
            <lineSegments>
              <edgesGeometry args={[new BoxGeometry(scaledBoxWidth(width), height, depth), 1]} />
              <lineBasicMaterial color={color} />
            </lineSegments>
          </mesh>
        </group>
      ) : null}
    </>
  );
};
