import { ActionType, useAppContext } from '@/context/AppContext';

import { useShareSourceDefinition } from '@/hooks';

import { SourceDefinition } from '@/types';

export const useShareAndUpdateSourceDefinition = () => {
  const {
    appState: { filteredSourceDefinitions },
    dispatch,
  } = useAppContext();

  const { mutate: shareSourceDefinition } = useShareSourceDefinition();

  const shareAndUpdateSourceDefinition = (sourceDefinition: SourceDefinition) => {
    shareSourceDefinition(sourceDefinition.id);
    const updatedSourceDefinition = { ...sourceDefinition, isSharedWithOrganization: true };

    const sourceDefinitionIndex = filteredSourceDefinitions.findIndex(
      (filteredSourceDefinition) => filteredSourceDefinition.id === updatedSourceDefinition.id
    );
    const newSourceDefinitions = [...filteredSourceDefinitions];
    newSourceDefinitions[sourceDefinitionIndex] = updatedSourceDefinition;
    dispatch({
      type: ActionType.SET_SOURCE_DEFINITIONS,
      payload: newSourceDefinitions,
    });
  };

  return shareAndUpdateSourceDefinition;
};
