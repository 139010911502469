import { Line } from '@react-three/drei';

import { LIGHT_GRAY_COLOR } from '../constants';

export const TiltedLineRight = () => {
  return (
    <>
      {/* Tilted line starting from the center of the horizontal line */}
      <Line
        points={[
          [0, -0.8, 2], // Start point at the center of the horizontal line
          [0.3, -0.8, 2.2], // End point tilted to the right and upwards
        ]}
        color={LIGHT_GRAY_COLOR}
        lineWidth={1}
      />

      {/* Horizontal line from the endpoint of the tilted line */}
      <Line
        points={[
          [0.3, -0.8, 2.2], // Start point (endpoint of the tilted line)
          [1.2, -0.8, 2.2], // End point of the new horizontal line
        ]}
        color={LIGHT_GRAY_COLOR}
        lineWidth={1}
      />
    </>
  );
};
