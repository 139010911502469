import { useQuery } from '@tanstack/react-query';

import { SimulationRunStatusDto } from '@/types';

import axios from '@/axios';

const getSimulationRunStatusById = async (simulationRunId: string) => {
  const { data } = await axios.get<SimulationRunStatusDto>(`/api/SimulationRun/GetSimulationRunStatusById`, {
    params: {
      simulationRunId,
    },
  });

  return data;
};

export const useGetSimulationRunStatusById = (
  simulationRunId: string,
  enabled = true,
  refetchInterval: number | false = false,
  staleTime?: number | undefined
) => {
  return useQuery(['simulationRunStatus', simulationRunId], () => getSimulationRunStatusById(simulationRunId), {
    refetchOnWindowFocus: false,
    refetchInterval: enabled ? refetchInterval : false,
    enabled,
    staleTime,
  });
};
