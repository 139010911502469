import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

interface UseMutationVariables {
  resultId: string;
  resultName: string;
}

const deleteResultPreset = async (resultId: string, resultName: string) => {
  const { data } = await axios.delete(`/api/ResultPreset/DeleteResultPreset/${resultId}`);
  return { data, resultName };
};

export const useDeleteResultPreset = () => {
  return useMutation(
    async ({ resultId, resultName }: UseMutationVariables) => await deleteResultPreset(resultId, resultName),
    {
      onError: () => {
        toast.error('An error occurred while deleting the saved results. Please refresh the browser.');
      },
      onSuccess: ({ resultName }) => {
        toast.info(`"${resultName}" deleted`);
      },
    }
  );
};
