import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MeshTaskFrequencyDto } from '../types';

import axios from '@/axios';

const getCompletedMeshTasks = async (modelId: string): Promise<MeshTaskFrequencyDto[]> => {
  const { data } = await axios.get(`/api/Mesh/GetCompletedMeshTasksForModel?modelId=${modelId}`);

  return data;
};

export const useGetCompletedMeshTasks = (modelId: string) =>
  useQuery<MeshTaskFrequencyDto[], AxiosError>(
    ['completed-mesh-tasks', modelId],
    () => getCompletedMeshTasks(modelId),
    {
      enabled: !!modelId,
      refetchOnWindowFocus: false,
      onError: () => {
        toast.error(`Could not get completed mesh tasks for model with id ${modelId}`, {
          className: 'editor-toast',
          toastId: `completed-mesh-tasks-${modelId}`,
        });
      },
    }
  );
