import { FC, useState } from 'react';
import dayjs from 'dayjs';

import { Box, Collapse, Stack } from '@mui/material';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { ProgressBar } from '@/components/Shared/ProgressBar';
import { Text } from '@/components/Shared/Text';
import { TrblChevronDownIcon, TrblChevronUpIcon } from '../Icons';

import { SimulationRunSourceDetails } from './types';

type SimulationRunDetailsProps = {
  progressBarColor: 'primary' | 'secondary' | 'warning' | 'error';
  statusText: string;
  showEllipsis: boolean;
  percentage: number | null;
  timeRemainingText: string | null;
  showRemaining: boolean;
  createdAt: string | null;
  completedAt: string | null;
  sourceDetails: Array<SimulationRunSourceDetails>;
};

export const SimulationRunDetails: FC<SimulationRunDetailsProps> = ({
  progressBarColor,
  statusText,
  showEllipsis,
  percentage,
  timeRemainingText,
  showRemaining,
  createdAt,
  completedAt,
  sourceDetails,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandStatus = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Box
        component="div"
        margin="15px -19px 5px"
        padding="10px 20px 10px"
        bgcolor={isExpanded ? 'rgba(33, 33, 33, 0.98)' : undefined}>
        <Stack>
          <Text type="medium-11px" ellipsis={showEllipsis}>{`${statusText} ${
            showRemaining ? `${percentage || 0}%  ${timeRemainingText ? `· ${timeRemainingText} remaining` : ''}` : ''
          }`}</Text>
          <Box component="div" display="flex" gap="6px" alignItems={'center'}>
            <ProgressBar sx={{ flexGrow: 1 }} color={progressBarColor} variant="determinate" value={percentage || 0} />
            <TrblIconButton
              label={isExpanded ? 'Collapse' : 'Expand'}
              size="small"
              icon={isExpanded ? <TrblChevronUpIcon /> : <TrblChevronDownIcon />}
              onClick={handleExpandStatus}
              sx={{
                visibility: sourceDetails.length == 0 ? 'hidden' : undefined,
              }}
            />
          </Box>
        </Stack>
        <Collapse in={isExpanded} timeout="auto">
          <Box component="div" pl="44px" pr="22px">
            {sourceDetails.map((source, index) => (
              <Box component="div" key={`source_${index}`}>
                <Text type="semibold-10px">{source.label}</Text>
                <Text type="regular-10px">
                  {source.inProgress ? ` · Running ${source.percentage || 0}% ` : ` - ${source.statusText}`}
                </Text>
                <ProgressBar color={source.progressBarColor} variant="determinate" value={source.percentage || 0} />
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Stack sx={{ margin: '0' }}>
        <span>
          <Text type="bold-11px">Started at: </Text>
          <Text type="regular-11px">{createdAt ? dayjs(createdAt).format('MMM DD, HH:mm') : '--'}</Text>
        </span>
        <span style={{ display: completedAt ? 'inline-block' : 'none' }}>
          <Text type="bold-11px">Completed at: </Text>
          <Text type="regular-11px">
            {completedAt && statusText == 'Completed' ? dayjs(completedAt).format('MMM DD, HH:mm') : '--'}
          </Text>
        </span>
      </Stack>
    </>
  );
};
