import { SelectOption } from '@/components/Shared/TrblSelect';

export const SPL_TYPE_OPTIONS: SelectOption[] = [
  { id: 'actual', name: 'Actual' },
  { id: 'relative', name: 'Rel. direct' },
];

export const IR_TYPE_OPTIONS: SelectOption[] = [
  { id: 'db', name: 'DB' },
  { id: 'pressure', name: 'Pressure' },
];
