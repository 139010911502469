import { Box3, Vector3 } from 'three';

import { DEFAULT_CAMERA_POSITION, VIEWPORT_CENTER, X_ANGLE, Y_ANGLE, Z_ANGLE } from './constants';

import { Material } from '@/types';

export enum MaterialCategoryColors {
  Carpets = '#E3E2E3',
  Curtains = '#5E7582',
  Furnishing = '#848484',
  Gypsum = '#D8D8D8',
  NaturalMaterials = '#E8DBE8',
  Other = '#BDBDBD',
  PerforatedPanels = '#E6E4D9',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Porous = '#848484',
  Rigid = '#918891',
  Windows = '#BFE9F9',
  Wood = '#DDCAB8',
  Default = '#FFFFFF',
}

const getColorByMaterialCategory = (category: string) => {
  switch (category) {
    case 'Carpets':
      return MaterialCategoryColors.Carpets;

    case 'Curtains':
      return MaterialCategoryColors.Curtains;

    case 'Furnishing':
      return MaterialCategoryColors.Furnishing;

    case 'Gypsum':
      return MaterialCategoryColors.Gypsum;

    case 'Natural materials':
      return MaterialCategoryColors.Gypsum;

    case 'Other':
      return MaterialCategoryColors.Other;

    case 'Perforated panels':
      return MaterialCategoryColors.PerforatedPanels;

    case 'Porous':
      return MaterialCategoryColors.Porous;

    case 'Rigid':
      return MaterialCategoryColors.Rigid;

    case 'Windows':
      return MaterialCategoryColors.Windows;

    case 'Wood':
      return MaterialCategoryColors.Wood;

    default:
      return MaterialCategoryColors.Default;
  }
};

export const getLayerColors = (
  materialIdByObjectId:
    | {
        [key: string]: string;
      }
    | undefined,
  materials: Material[]
) => {
  const layerColors: Record<string, string> = {};
  if (materialIdByObjectId) {
    Object.keys(materialIdByObjectId).forEach((objectId) => {
      const materialId = materialIdByObjectId[objectId];
      const material = materials.find((material) => material.id === materialId);
      if (material) {
        layerColors[objectId] = getColorByMaterialCategory(material.category);
      }
    });
  }
  return layerColors;
};

export const getCameraLookAtFromModel = (modelCenter: Vector3) => {
  // if model center is more than 6m away from viewport center, then return model center for camera to rotate towards
  if (VIEWPORT_CENTER.distanceTo(modelCenter) > 6) {
    return modelCenter;
    //else return the default viewport center
  } else {
    return VIEWPORT_CENTER;
  }
};

export const getCameraPositionFromModel = (modelCenter: Vector3, modelBoundingBox: Box3) => {
  const modelAreaLength = modelBoundingBox.min.distanceTo(modelBoundingBox.max);
  let offset = 1;
  let moveCameraPosition = false;

  // if model area is longer than 40m, then move camera position further back in relation to the model area
  // offset is formula made by Úlfur, no math, just what looked good
  if (modelAreaLength > 40) {
    offset = modelAreaLength / 35;
    if (offset > 3) offset = 3;
    moveCameraPosition = true;

    // if model area is less than 10m, then move camera position closer in relation to the model area
    // offset is formula made by Úlfur, no math, just what looked good
  } else if (modelAreaLength < 10) {
    offset = modelAreaLength / 11;
    if (offset < 0.5) offset = 0.5;
    moveCameraPosition = true;

    // if model center is more than 15m away from viewport center, then move camera position to model center
  } else if (VIEWPORT_CENTER.distanceTo(modelCenter) > 15) {
    moveCameraPosition = true;
  }

  if (moveCameraPosition) {
    return new Vector3(
      modelCenter.x + X_ANGLE * offset,
      modelCenter.y + Y_ANGLE * offset,
      modelCenter.z + Z_ANGLE * offset
    );
    //if camera position was not moved, then set camera position to default
  } else {
    return DEFAULT_CAMERA_POSITION;
  }
};
