import { useEffect, useState } from 'react';

import { IconButton } from '@mui/material';

import { TrblCloseIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const WarningMessage = ({
  text,
  background = '#cdcdcd',
  textColor = '#272727',
  isClosed = false,
}: {
  text: string;
  background?: string;
  textColor?: string;
  isClosed?: boolean;
}) => {
  const [active, setActive] = useState(false);
  const [closed, setClosed] = useState(isClosed);

  useEffect(() => {
    if (!isClosed) setActive(true);
  }, []);

  useEffect(() => {
    if (isClosed) closeWarning();
  }, [isClosed]);

  const closeWarning = () => {
    setActive(false);
    setTimeout(() => {
      setClosed(true);
    }, 1000);
  };

  return !closed ? (
    <div className={`${styles['warning-container']} `}>
      <div
        className={`${styles['warning-box']} ${active ? styles['active'] : ''}`}
        style={{ background, color: textColor }}>
        <div className={`${styles['warning-text']} `}>{text}</div>
        <IconButton aria-label="close warning" className={styles['warning-close-btn']} onClick={closeWarning}>
          <TrblCloseIcon width="11" height="11" stroke="#272727" />
        </IconButton>
      </div>
    </div>
  ) : (
    <></>
  );
};
