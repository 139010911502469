import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, CircularProgress, Stack } from '@mui/material';

import { FileUpload } from '@/components/Shared/FileUpload';
import { Text } from '@/components/Shared/Text';
import { TrblGeometryFolderIcon, TrblModelsIcon } from '@/components/Icons';
import { TrblTruncate } from '../SoundLibrary/TrblTruncate';
import darkgridImageUrl from './images/darkgrid.png';

import {
  uploadAndZipFileToTreble,
  useGetGeometryCheckResult,
  useGetGeometryCheckTask,
  useStartGeometryCheckTask,
} from '@/hooks';

import { SpaceExtractionResults, SpaceResults } from './types';

import styles from './styles.module.scss';

export const MultiSpaceImportStep1 = ({
  file,
  setFile,
  importStarted,
  setImportStarted,
  setMultiSpaceResults,
  setImportError,
}: {
  file: File | null;
  setFile: (file: File | null) => void;
  importStarted: boolean;
  setImportStarted: (value: boolean) => void;
  setMultiSpaceResults: (value: SpaceExtractionResults | null) => void;
  setImportError: (value: string | null) => void;
}) => {
  const [ongoingGeometryCheckId, setOngoingGeometryCheckId] = useState<string | null>(null);
  const [geometryCheckTaskId, setGeometryCheckTaskId] = useState<string | null>(null);

  const { mutate: startGeometryCheckTask } = useStartGeometryCheckTask();
  const { data: geometryCheckTaskData } = useGetGeometryCheckTask(ongoingGeometryCheckId, true);
  const { data: geometryCheckResultsData } = useGetGeometryCheckResult(geometryCheckTaskId);

  useEffect(() => {
    if (importStarted) handleStartImport();
  }, [importStarted]);

  // step 1 create uploadSlot
  const handleStartImport = async () => {
    if (file) {
      setOngoingGeometryCheckId(null);
      setMultiSpaceResults(null);
      setImportError(null);
      toast.info('File upload started');

      const uploadId = await uploadAndZipFileToTreble(file);

      if (uploadId) handleStartGeometryCheckTask(uploadId);
    }
  };

  // step 2 start GeometryCheck
  const handleStartGeometryCheckTask = (uploadId: string) => {
    startGeometryCheckTask(
      {
        inputFileUploadId: uploadId,
        body: {
          crossoverFrequency: 710,
          elementsPerWavelength: 1.585,
          basisOrder: 4,
        },
      },
      {
        onSuccess: (response: string) => {
          setOngoingGeometryCheckId(response);
        },
        onError: () => {
          onGeometryCheckError('Error occurred while starting file upload');
        },
      }
    );
  };

  // step 3 poll getGeometryCheckTask until Completed or Error
  useEffect(() => {
    if (ongoingGeometryCheckId && geometryCheckTaskData) {
      if (geometryCheckTaskData.task.status === 'Completed') {
        setGeometryCheckTaskId(geometryCheckTaskData.task.id);
      } else if (geometryCheckTaskData.task.status === 'Error') {
        let userStatusMsg = geometryCheckTaskData.task.userStatusMsg;

        if (userStatusMsg) {
          // Replace the support sentences to align with the web app, because the userStatusMsg is written for SkeptchUp
          userStatusMsg = userStatusMsg.includes('Contact us through the Support button if the issue persists.')
            ? userStatusMsg.replace(
                'Contact us through the Support button if the issue persists.',
                'Please contact support.'
              )
            : userStatusMsg;
        }

        const errorMessage = userStatusMsg || 'Error occurred while uploading file. Please contact support.';
        onGeometryCheckError(errorMessage, 'Error occurred while uploading file');
      } else if (geometryCheckTaskData.task.status === 'TimedOut') {
        onGeometryCheckError('Uploading file timed out');
      }
    }
  }, [geometryCheckTaskData]);

  // step 4 geometry object ready
  useEffect(() => {
    if (geometryCheckResultsData) {
      // put geometryCheckResultsData into MultiSpace data format
      const space: SpaceResults = {
        id: geometryCheckResultsData.id,
        meshUploadId: geometryCheckResultsData.meshUploadId,
        sourceModelUploadId: geometryCheckResultsData.meshUploadId,
        feedbackDownloadUrl: geometryCheckResultsData.feedbackDownloadUrl,
        spaceName: '',
      };

      const data: SpaceExtractionResults = {
        spaceExtractionTaskId: geometryCheckResultsData.id,
        taskId: geometryCheckResultsData.id,
        hasImportedModel: false,
        spaces: [space],
      };

      setMultiSpaceResults(data);

      setImportStarted(false);
      setOngoingGeometryCheckId(null);

      toast.info('File succesfully uploaded!');
    }
  }, [geometryCheckResultsData]);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      setFile(file);
      setImportError(null);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      setFile(file);
      setImportError(null);
    }
  };

  const onGeometryCheckError = (errorMessage: string, toastErrorMessage?: string) => {
    setImportStarted(false);
    setOngoingGeometryCheckId(null);
    setImportError(errorMessage);
    toast.error(toastErrorMessage || errorMessage);
  };

  return (
    <Stack position={'relative'} height={'100%'} width={'100%'}>
      {importStarted && (
        <Box
          component="div"
          display={'flex'}
          position="absolute"
          height="calc(100% - 24px)"
          width="calc(100% - 24px)"
          top="12px"
          left="12px"
          justifyContent={'center'}
          alignItems="center"
          flexDirection={'column'}
          textAlign="center"
          overflow={'hidden'}
          gap={2}
          className={styles['fade-in']}
          style={{ backdropFilter: 'blur(8px)', zIndex: 2 }}>
          <CircularProgress />
          <Text type="semibold-18px" style={{ lineHeight: 1.5 }}>
            Uploading
            <br />
            <Text type="medium-14px" title={file?.name}>
              <TrblTruncate text={file?.name ?? ''} strLen={50} />
            </Text>
          </Text>

          <Text type="regular-12px" color="#adadad" style={{ lineHeight: 2 }}>
            This can take up to a minute
          </Text>
          <img src={darkgridImageUrl} className={styles['back-img']} alt="Loading image" />
        </Box>
      )}

      <FileUpload
        disabled={importStarted}
        accept={'.dxf, .3dm, .obj'}
        acceptText="DXF, OBJ or 3DM files accepted"
        label="Drop file here to upload"
        onChange={onChangeFileUpload}
        onDropFile={onDropFileUpload}
        idleIcon={<TrblGeometryFolderIcon width="40" height="42" fill={'#3a3a3a'} />}
        successIcon={<TrblGeometryFolderIcon width="40" height="42" />}
        acceptIcon={<TrblModelsIcon width="18" height="18" />}
        backgroundImage={darkgridImageUrl}
        newFilename={file?.name}
        maxSizeInMB={80}
      />
    </Stack>
  );
};
