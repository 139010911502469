import { useBaseContext } from '@/context/BaseContext';

import { FeatureFlags } from '../types';

/* 
    1. keep a record of all features under a feature flag
    2. used in components like this:
    const { testFeature } = useFeatureFlags();
    if (testFeature) {
        return <div> New amazing Test feature </div>;
    }
*/

export const useFeatureFlags = (): FeatureFlags => {
  const {
    state: { featureFlags },
  } = useBaseContext();

  return featureFlags;
};
