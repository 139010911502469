import { SimSrcRecHash } from '../types';

export const getSourceHashById = (simId: string, sourceId: string, simSrcRecHash: SimSrcRecHash | null) => {
  try {
    const map = simSrcRecHash?.[simId]?.srcHashes;
    return Object.keys(map).find((key) => map[key].id === sourceId);
  } catch (error) {
    console.error(error);
    return null;
  }
};
