import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { FitMaterial } from '@/types';

import axios from '@/axios';

const MAX_POLL = 60;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFitMaterial = async (materialFitId: string | null, shouldPoll: any): Promise<FitMaterial> => {
  if (shouldPoll && materialFitId) {
    const { data } = await axios.get(`/api/MaterialFit/GetMaterialFit?materialFitId=${materialFitId}`);

    const parsedData: FitMaterial = {
      material_fit_id: data.material_fit_id,
      status: data.status,
      material_id: data.material_id,
      material_json: data.material_json,
      material: data.material_json ? JSON.parse(data.material_json) : null,
      material_metadata_json: data.material_metadata_json,
      material_metadata: data.material_metadata_json ? JSON.parse(data.material_metadata_json) : null,
      input_parameters_json: data.input_parameters_json,
      input_parameters: data.input_parameters_json ? JSON.parse(data.input_parameters_json) : null,
    };

    return parsedData;
  } else {
    throw new Error('this is error');
  }
};

export const useGetFitMaterial = (materialFitId: string | null, shouldRefetch: boolean) => {
  return useQuery<FitMaterial, boolean>(
    ['material-fit-id', materialFitId],
    () => getFitMaterial(materialFitId, shouldRefetch),
    {
      enabled: !!materialFitId && shouldRefetch,
      refetchOnWindowFocus: false,
      // Refetch the data every second
      refetchInterval: (data, query) => {
        if (data?.status !== 40 && query.state.dataUpdateCount > MAX_POLL) {
          toast.error('An error occurred while fitting the material. Please try again.');
          return false;
        } else {
          return 1000;
        }
      },
      onError: () => {
        toast.error('An error occurred while fitting the material. Please try again.');
      },
    }
  );
};
