import { FC, useState } from 'react';

import {
  Trbl3DBoxIcon,
  TrblAddIcon,
  TrblAnalyticsIcon,
  TrblArrowLeft,
  TrblArrowThinRight,
  TrblCardsViewIcon,
  TrblClipboardIcon,
  TrblCloseEyeIcon,
  TrblCrankWheelIcon,
  TrblDeleteIcon,
  TrblDotIcon,
  TrblEditIcon,
  TrblEyeIcon,
  TrblFileIcon,
  TrblFileUploadIcon,
  TrblHeatmapIcon,
  TrblInfoIcon,
  TrblLinkIcon,
  TrblListViewIcon,
  TrblMenuHorizontalIcon,
  TrblOctaveBandIcon,
  TrblPlayAlternativeIcon,
  TrblPlayIcon,
  TrblQuickGuideIcon,
  TrblReflectionIcon,
  TrblSettingsSliderIcon,
  TrblStopIcon,
  TrblTimeComputerIcon,
  TrblTimewatchIcon,
  TrblTimewatchUnfilledIcon,
} from '.';

export type IconTypes =
  | 'add'
  | 'analytics'
  | 'arrowThinRight'
  | 'arrowLeft'
  | 'box3D'
  | 'cardsView'
  | 'listView'
  | 'clipboard'
  | 'closeEye'
  | 'crankWheel'
  | 'delete'
  | 'dot'
  | 'edit'
  | 'file'
  | 'fileUpload'
  | 'heatmap'
  | 'info'
  | 'link'
  | 'menuHorizontal'
  | 'octaveBand'
  | 'openEye'
  | 'play'
  | 'playAlternative'
  | 'reflection'
  | 'stop'
  | 'timeComputer'
  | 'timewatch'
  | 'timewatchUnfilled'
  | 'slider'
  | 'quickGuide';

type TrblIconProps = {
  icon: IconTypes;
  color?: string;
  hoverColor?: string;
};

const renderIcon = (color?: string): { [key in IconTypes]: React.ReactElement } => ({
  add: <TrblAddIcon fill={color} />,
  analytics: <TrblAnalyticsIcon fill={color} />,
  arrowLeft: <TrblArrowLeft fill={color} />,
  arrowThinRight: <TrblArrowThinRight fill={color} />,
  box3D: <Trbl3DBoxIcon fill={color} />,
  cardsView: <TrblCardsViewIcon fill={color} />,
  listView: <TrblListViewIcon fill={color} />,
  clipboard: <TrblClipboardIcon fill={color} />,
  closeEye: <TrblCloseEyeIcon fill={color} />,
  crankWheel: <TrblCrankWheelIcon fill={color} />,
  delete: <TrblDeleteIcon fill={color} />,
  dot: <TrblDotIcon fill={color} />,
  edit: <TrblEditIcon fill={color} />,
  file: <TrblFileIcon fill={color} />,
  fileUpload: <TrblFileUploadIcon fill={color} />,
  heatmap: <TrblHeatmapIcon fill={color} />,
  info: <TrblInfoIcon fill={color} />,
  link: <TrblLinkIcon fill={color} />,
  menuHorizontal: <TrblMenuHorizontalIcon fill={color} />,
  octaveBand: <TrblOctaveBandIcon fill={color} />,
  openEye: <TrblEyeIcon fill={color} />,
  play: <TrblPlayIcon fill={color} />,
  playAlternative: <TrblPlayAlternativeIcon fill={color} />,
  reflection: <TrblReflectionIcon fill={color} />,
  stop: <TrblStopIcon fill={color} />,
  timeComputer: <TrblTimeComputerIcon fill={color} />,
  timewatch: <TrblTimewatchIcon fill={color} />,
  timewatchUnfilled: <TrblTimewatchUnfilledIcon fill={color} />,
  slider: <TrblSettingsSliderIcon />,
  quickGuide: <TrblQuickGuideIcon fill={color} />,
});

export const TrblIcon: FC<TrblIconProps> = ({ icon, color, hoverColor }) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <span
      style={{ display: 'flex', alignItems: 'center' }}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}>
      {renderIcon(mouseOver && hoverColor ? hoverColor : color)[icon]}
    </span>
  );
};
