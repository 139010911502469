import { Outlet } from 'react-router-dom';

import { AppProvider } from '@/context/AppContext';

export const MainWrapper = () => {
  return (
    <AppProvider>
      <Outlet />
    </AppProvider>
  );
};
