import { KeyboardEvent, RefObject, useEffect } from 'react';

import { TrblSearchIcon } from '@/components/Icons';

import './styles.scss';

export const LibrarySearch = ({
  inputRef,
  label,
  searchLibrary,
  autofocus = false,
}: {
  inputRef: RefObject<HTMLInputElement>;
  label: string;
  autofocus?: boolean;
  searchLibrary: (key: string, inputValue: string) => void;
}) => {
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('cut', onCut);
      inputRef.current.addEventListener('paste', onPaste);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('cut', onCut);
        inputRef.current.removeEventListener('paste', onPaste);
      }
    };
  }, [inputRef]);

  const onCut = () => {
    searchLibrary('Enter', '');
  };

  // @ts-expect-error Type 'ClipboardEvent' is not generic.
  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    // @ts-expect-error Property 'clipboardData' does not exist on type 'Window & typeof globalThis'.
    const pasteText = (event.clipboardData || window.clipboardData).getData('text');
    searchLibrary('Enter', pasteText);
  };

  const keyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    searchLibrary(event.key, event.target.value);
  };

  return (
    <div className="search-library-container">
      <span className="search-icon">
        <TrblSearchIcon />
      </span>
      <input
        autoFocus={autofocus}
        ref={inputRef}
        onKeyUp={keyUp}
        className="input-text search-library"
        type="text"
        placeholder={`Search ${label}`}></input>
    </div>
  );
};
