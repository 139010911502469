export const TrblMagnifyZoomInIcon = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1495 10.7958C10.6903 9.71599 11.8746 6.78093 10.7948 4.24015C9.71502 1.69937 6.77995 0.515022 4.23918 1.59483C1.6984 2.67463 0.514046 5.60969 1.59385 8.15047C2.67366 10.6912 5.60872 11.8756 8.1495 10.7958Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.1943 10.1953L12.8048 12.8063" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.69434 6.19531C8.69434 5.91917 8.47048 5.69531 8.19434 5.69531H6.81934C6.7503 5.69531 6.69434 5.63935 6.69434 5.57031V4.19531C6.69434 3.91917 6.47048 3.69531 6.19434 3.69531C5.91819 3.69531 5.69434 3.91917 5.69434 4.19531V5.57031C5.69434 5.63935 5.63837 5.69531 5.56934 5.69531H4.19434C3.91819 5.69531 3.69434 5.91917 3.69434 6.19531C3.69434 6.47145 3.91819 6.69531 4.19434 6.69531H5.56934C5.63837 6.69531 5.69434 6.75128 5.69434 6.82031V8.19531C5.69434 8.47146 5.91819 8.69531 6.19434 8.69531C6.47048 8.69531 6.69434 8.47146 6.69434 8.19531V6.82031C6.69434 6.75128 6.7503 6.69531 6.81934 6.69531H8.19434C8.47048 6.69531 8.69434 6.47145 8.69434 6.19531Z"
      fill={fill}
    />
  </svg>
);
