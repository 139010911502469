import { toast } from 'react-toastify';

import { ActionType as AppActionType, useAppContext } from '@/context/AppContext';

import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

import { useDeleteSourceDefinition } from '@/hooks';

export const useOnDeleteSourceDefinition = () => {
  const { mutate: deleteSourceDefinition } = useDeleteSourceDefinition();
  const {
    appState: { filteredSourceDefinitions },
    dispatch: appDispatch,
  } = useAppContext();
  const { dispatch: libraryDispatch } = useLibraryPanelContext();

  const onDeleteSourceDefinition = async (sourceDefinitionId: string, sourceDefinitionName: string) => {
    if (!sourceDefinitionId) return;

    // We need to return a promise so we can await for this to finish before e.g. popups close
    // because otherwise the re-rendering won't happen (the source definition list won't update)
    return new Promise((resolve) => {
      deleteSourceDefinition(sourceDefinitionId, {
        onSuccess: () => {
          const newSourceDefinitions = filteredSourceDefinitions.filter(
            (filteredSourceDefinition) => filteredSourceDefinition.id !== sourceDefinitionId
          );
          appDispatch({
            type: AppActionType.SET_SOURCE_DEFINITIONS,
            payload: newSourceDefinitions,
          });
          libraryDispatch({
            type: LibraryActionType.SET_HIGHLIGHTED_ITEM,
            highlightedItemId: null,
          });

          toast.info("'" + sourceDefinitionName + "' deleted");
          resolve(true);
        },
        onError: () => {
          toast.error('An error occurred while deleting source definition');
        },
      });
    });
  };

  return onDeleteSourceDefinition;
};
