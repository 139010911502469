import { useQuery } from '@tanstack/react-query';

import { SharedAuralizationInfoDto } from '../types';

import axios from '@/axios';

export const getSharedAuralizationByPresetId = async (presetId: string): Promise<SharedAuralizationInfoDto | null> => {
  const response = await axios.get(`/api/SharedAuralization/GetSharedAuralizationByPresetId`, {
    params: {
      auralizationPresetId: presetId,
    },
  });

  if (response.status !== 204) {
    return response.data;
  } else {
    // 204 will return us an empty string (no content). We return null instead
    return null;
  }
};

export const useGetSharedAuralizationByPresetId = (
  presetId: string,
  onSuccess?: (data: SharedAuralizationInfoDto | null) => void
) => {
  return useQuery<SharedAuralizationInfoDto | null>(
    ['shared-auralization', presetId],
    () => getSharedAuralizationByPresetId(presetId),
    {
      refetchOnWindowFocus: false,
      onSuccess,
    }
  );
};
