import { TreeStructure } from '@/components/Shared/TreeView/types';
import { TrblFolderIcon, TrblModelsIcon, TrblSimMaterialIcon } from '@/components/Icons';

import { SimulationLightDto, SpaceSimulationsDto } from '../../types';
import { StatusType } from '@/types';

export const useCreateFileStructure = (allSpacesWithSims: SpaceSimulationsDto[]) => {
  if (allSpacesWithSims.length > 0) {
    const treeStructure: TreeStructure = allSpacesWithSims.reduce(
      (treeStructure: TreeStructure, spaceWithSim: SpaceSimulationsDto): TreeStructure => {
        const simulationNames = spaceWithSim.simulations.map((sim) => sim.name?.toLowerCase());
        const simulationIds = spaceWithSim.simulations.map((sim) => sim.id);

        if (simulationNames.length > 0) {
          // simulations
          const simulationChildren = spaceWithSim.simulations.reduce<TreeStructure>(
            (accSims, currSim: SimulationLightDto) => {
              if (currSim?.lastSimulationRun?.status === StatusType.Completed) {
                return {
                  ...accSims,
                  [currSim.id]: {
                    name: `${currSim.name}${currSim.hasBeenEdited ? ' [editing]' : ''}`,
                    id: currSim.id,
                    isOpen: null,
                    meta: {
                      modelName: spaceWithSim.modelBaseName,
                      spaceName: spaceWithSim.spaceName,
                      simulation: currSim,
                    },
                    icon: <TrblSimMaterialIcon fill="#999999" />,
                    isSelectable: true,
                    children: {} as TreeStructure,
                  },
                };
              } else {
                return accSims;
              }
            },
            {} as TreeStructure
          );

          if (Object.keys(simulationChildren).length > 0) {
            // modelBase
            const modelBase: TreeStructure = {
              [spaceWithSim.modelId]: {
                isOpen: false,
                id: spaceWithSim.modelId,
                name: spaceWithSim.modelBaseName,
                icon: <TrblModelsIcon fill="#999999" />,
                children: simulationChildren,
                meta: { simulationNames, simulationIds },
              },
            };

            const existingModelBases =
              treeStructure?.[spaceWithSim.projectId]?.children?.[spaceWithSim.spaceId]?.children ?? {};

            const existingSimNames: string[] =
              treeStructure?.[spaceWithSim.projectId]?.children?.[spaceWithSim.spaceId]?.meta?.simulationNames ?? [];

            const existingSimIds: string[] =
              treeStructure?.[spaceWithSim.projectId]?.children?.[spaceWithSim.spaceId]?.meta?.simulationIds ?? [];

            // space
            const space: TreeStructure = {
              [spaceWithSim.spaceId]: {
                isOpen: false,
                id: spaceWithSim.spaceId,
                name: spaceWithSim.spaceName,
                children: {
                  ...existingModelBases,
                  ...modelBase,
                },
                meta: {
                  simulationNames: [...existingSimNames, ...modelBase[spaceWithSim.modelId].meta.simulationNames],
                  simulationIds: [...existingSimIds, ...modelBase[spaceWithSim.modelId].meta.simulationIds],
                },
              },
            };

            const existingSimNamesForTags = treeStructure[spaceWithSim.projectId]?.meta?.simulationNames ?? [];
            const existingSimIdsForTags = treeStructure[spaceWithSim.projectId]?.meta?.simulationIds ?? [];

            return {
              ...treeStructure,
              // projects
              [spaceWithSim.projectId]: {
                id: '',
                isOpen: false,
                name: spaceWithSim.projectName,
                icon: <TrblFolderIcon fill="#999999" />,
                children: { ...treeStructure[spaceWithSim.projectId]?.children, ...space },
                meta: {
                  simulationNames: [...existingSimNamesForTags, ...space[spaceWithSim.spaceId].meta.simulationNames],
                  simulationIds: [...existingSimIdsForTags, ...space[spaceWithSim.spaceId].meta.simulationIds],
                },
              },
            };
          } else {
            return treeStructure;
          }
        } else {
          return treeStructure;
        }
      },
      {}
    );
    return treeStructure;
  } else return {};
};
