import { useQuery } from '@tanstack/react-query';

import { ResultPresetDto } from '../types';

import axios from '@/axios';

export const getResultPreset = async (id: string): Promise<ResultPresetDto> => {
  const { data } = await axios.get(`/api/ResultPreset/GetResultPreset/${id}`);
  return data;
};

export const useGetResultPreset = (id: string) => {
  return useQuery<ResultPresetDto>(['result-preset-by-id', id], () => getResultPreset(id), {
    enabled: id.length > 0,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};
