import { toast } from 'react-toastify';

import { useEditorContext } from '@/context/EditorContext';
import { ActionType, useMeshContext } from '@/context/MeshContext';

import { getMeshResult } from './useGetMeshResult';
import { getMeshTask } from './useGetMeshTask';

import {
  maxMeshElements,
  maxMeshElementsId,
  maxMeshElementsMessage,
  meshElementSizeFactor,
  smallMeshElements,
  smallMeshElementsId,
  smallMeshElementsMessage,
} from '@/utils/constants';

import { calculateSimulationTimeEstimate } from '../utils';

import { CompletedMeshTask } from '@/context/MeshContext/types';
import { Simulation } from '@/types';

export const useGetEstimatedSimulationTime = () => {
  const { dispatch, completedMeshResults, completedMeshTasks, meshWarnings } = useMeshContext();
  const { isInResultsMode } = useEditorContext();

  const getEstimatedSimulationTime = async (
    transitionFrequency: number,
    impulseResponseLength: number,
    rayCount: number,
    selectedSimulation: Simulation,
    taskType: string | null
  ) => {
    // if GA only, don't send mesh results to calculation
    if (taskType == 'GA') {
      const estimate = calculateSimulationTimeEstimate(
        null,
        impulseResponseLength,
        rayCount,
        selectedSimulation.receivers,
        selectedSimulation.gridReceivers,
        taskType
      );
      if (estimate) {
        dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate });
        dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: null });
        return { estimate, meshTaskId: null };
      }
    }

    // if DG only or Hybrid, then look for mesh results and send to calculation
    if (completedMeshTasks && completedMeshTasks.length) {
      let meshResult = null;

      const meshTask = completedMeshTasks.find(
        (completedMeshTask: CompletedMeshTask) => completedMeshTask.crossoverFrequency === transitionFrequency
      );

      if (meshTask) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        meshResult = completedMeshResults.find((x: any) => x.meshTaskId === meshTask.meshTaskId);
        if (!meshResult) {
          meshResult = await getNewMeshResults(meshTask.meshTaskId, !isInResultsMode);
        }
      }
      if (meshResult && meshTask) {
        const newCurrentMeshResult = {
          ...meshResult,
          meshTaskId: meshTask.meshTaskId, // yes this is supposed to be here
        };
        const newCompletedMeshResults = [...completedMeshResults, newCurrentMeshResult];
        dispatch({ type: ActionType.SET_COMPLETED_MESH_RESULTS, completedMeshResults: newCompletedMeshResults });
        dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: newCurrentMeshResult });
        dispatch({ type: ActionType.SET_MESH_ERROR, meshHasError: false });

        // a catch for edge cases where the mesh results are returned as completed, but have no elements.
        if (!meshResult.elementCount) {
          toast.error('The geometry for this model appears to be invalid. Meshing failed!');
        } else {
          // if status 1 or 2 (in progress or completed) then don't show mesh warnings
          // if status 2 (completed) then only show mesh warning if simulation has been edited
          if (
            (selectedSimulation.extra?.status !== 1 && selectedSimulation.extra?.status !== 2) ||
            (selectedSimulation.extra?.status == 2 && selectedSimulation.hasBeenEdited === true)
          ) {
            // Warning for too many elements in mesh
            if (meshResult.elementCount > maxMeshElements) {
              const maxMeshWarningId = maxMeshElementsId + transitionFrequency.toString();
              if (meshWarnings.indexOf(maxMeshWarningId) === -1) {
                toast.warning(maxMeshElementsMessage, { toastId: maxMeshWarningId });
                dispatch({
                  type: ActionType.ADD_MESH_WARNING,
                  meshWarning: maxMeshWarningId,
                });
              }
            }

            // Warning for too small elements in the mesh
            if (meshResult.elementMinLength * meshElementSizeFactor < smallMeshElements) {
              const smallMeshWarningId = smallMeshElementsId + transitionFrequency.toString();
              if (meshWarnings.indexOf(smallMeshWarningId) === -1) {
                toast.warning(smallMeshElementsMessage, { toastId: smallMeshWarningId });
                dispatch({
                  type: ActionType.ADD_MESH_WARNING,
                  meshWarning: smallMeshWarningId,
                });
              }
            }
          }

          const estimate = calculateSimulationTimeEstimate(
            newCurrentMeshResult,
            impulseResponseLength,
            rayCount,
            selectedSimulation.receivers,
            selectedSimulation.gridReceivers,
            taskType
          );
          if (estimate) {
            dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate });
            return { estimate, meshTaskId: newCurrentMeshResult.meshTaskId };
          }
        }
      } else {
        const filteredCompletedMeshTasks = completedMeshTasks.filter((completedMeshTask: CompletedMeshTask) => {
          return completedMeshTask.meshTaskId !== meshTask?.meshTaskId;
        });
        dispatch({ type: ActionType.SET_COMPLETED_MESH_TASKS, completedMeshTasks: filteredCompletedMeshTasks });
        dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: null });
      }
    }
  };

  return getEstimatedSimulationTime;
};

const getNewMeshResults = async (meshTaskId: string, showMessage: boolean = true) => {
  const meshTask = await getMeshTask(meshTaskId);
  const meshMade = new Date(meshTask.task.completedAt);
  const newMesherMade = new Date('2023-04-17');

  if (meshMade < newMesherMade) {
    if (showMessage) {
      toast.warning(
        'We have improved our geometry preprocessing so your previous estimate at ' +
          meshTask.crossoverFrequency +
          ' Hz is no longer available, please run the estimation again to proceed',
        { className: 'editor-toast', autoClose: false, toastId: 'mesh-' + meshTask.crossoverFrequency }
      );
    }

    return null;
  } else {
    const meshResult = await getMeshResult(meshTask.task.id);
    // TODO: fix this
    // result.meshTaskId = meshTaskId;
    // editor.completedMeshResults.push(result);

    return meshResult;
  }
};
