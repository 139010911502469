import { useThree } from '@react-three/fiber';
import { Box3, BufferGeometry, Material, Mesh, Vector3 } from 'three';

export const useAdjustCameraToFitMesh = () => {
  const { camera } = useThree();

  const adjustCameraToFitMesh = (meshRef: React.RefObject<Mesh<BufferGeometry, Material | Material[]>>) => {
    if (meshRef.current) {
      const boundingBox = new Box3().setFromObject(meshRef.current);
      const boxSize = boundingBox.getSize(new Vector3());
      const boxCenter = boundingBox.getCenter(new Vector3());

      // Get the distance from the camera to the center of the mesh
      const distance = camera.position.distanceTo(boxCenter);

      // Calculate the half size of the mesh's diagonal
      const maxBoxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);
      const halfDiagonal = (maxBoxDimension * Math.sqrt(3)) / 2;

      // Calculate the required distance to fit the mesh into the view
      // @ts-expect-error fov does exist
      const adjustedFov = camera.fov / 1.4;
      const requiredDistance = halfDiagonal / Math.sin(adjustedFov * (Math.PI / 180));

      // Move the camera accordingly
      const moveDistance = requiredDistance - distance;
      const cameraDirection = camera.getWorldDirection(new Vector3()).normalize();
      camera.position.add(cameraDirection.multiplyScalar(-moveDistance));

      camera.updateProjectionMatrix();
    }
  };
  return adjustCameraToFitMesh;
};
