import { ReactNode, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // load on demand

import { TrblCancelledIcon, TrblCompletedIcon, TrblErrorIcon, TrblInprogressIcon, TrblWarningIcon } from '../Icons';
import { TrblTooltip } from '../Shared';
import yellowCircleImageUrl from './images/yellowCircle.png';

import { getTimeStringByDuration } from '@/utils/trebleFunctions';

export const OptionContentWithStatus = ({
  name,
  status,
  createdAt,
  simulationRunCreatedAt,
  simulationRunCompletedAt,
  hasBeenEdited,
}: {
  name: string;
  id: string;
  status: number;
  createdAt: string;
  simulationRunCreatedAt?: string;
  simulationRunCompletedAt?: string | null;
  hasBeenEdited: boolean | null;
}) => {
  const [statusIcon, setStatusIcon] = useState<ReactNode | null>(<></>);
  const [text, setText] = useState('');

  useEffect(() => {
    switch (status) {
      case 0:
        setStatusIcon(null);
        setText('');
        break;
      case 1:
        // running
        setStatusIcon(<TrblInprogressIcon />);
        setText('In progress');
        break;
      case 2:
        // completed
        setStatusIcon(<TrblCompletedIcon />);
        if (hasBeenEdited) {
          setText('Completed [editing]');
        } else {
          setText('Completed');
        }
        break;
      case 3:
        // cancelled
        setStatusIcon(<TrblCancelledIcon />);
        setText('Cancelled');
        break;
      case 4:
        // error
        setStatusIcon(<TrblErrorIcon />);
        setText('Error');
        break;
      case 5:
        // error
        setStatusIcon(<TrblWarningIcon />);
        setText('Insufficient tokens');
        break;
    }
  }, [status, hasBeenEdited]);

  const getSimulationRuntime = (started: string, completed: string) => {
    dayjs.extend(duration);
    const difference = dayjs(completed).diff(dayjs(started), 'ms');
    const differenceDuration = dayjs.duration(difference);

    return getTimeStringByDuration(differenceDuration);
  };

  return (
    <>
      <p className="simulation_name" title={name.length > 30 ? name : ''}>
        {name}
      </p>
      <div className="status">
        {statusIcon && (
          <span className="status_icon">
            {statusIcon}
            {hasBeenEdited ? (
              <img src={yellowCircleImageUrl} alt="Settings changed from last simulation run" className="changed-img" />
            ) : null}
          </span>
        )}
        {status == 2 ? (
          <p className="status_text">
            {text.length > 0 && `${text} `}
            {simulationRunCreatedAt && simulationRunCompletedAt && (
              <>
                <TrblTooltip title={'Completed ' + dayjs(simulationRunCompletedAt).format('MMM DD YYYY, HH:mm')}>
                  <span>{dayjs(simulationRunCompletedAt).format('MMM DD')}</span>
                </TrblTooltip>

                <span title="Runtime">
                  {' · ' + getSimulationRuntime(simulationRunCreatedAt, simulationRunCompletedAt)}
                </span>
              </>
            )}
          </p>
        ) : (
          <p className="status_text">
            <span title={'Created ' + dayjs(createdAt).format('MMM DD YYYY, HH:mm')}>
              {text.length > 0 && text + ' · '}Created {dayjs(createdAt).format('MMM DD')}
            </span>
          </p>
        )}
      </div>
    </>
  );
};
