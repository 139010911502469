import { TextArea } from '@/components/Shared/TextArea';

import styles from '../styles.module.scss';

type MaterialTextInput = {
  textInput: string;
  placeholder: string;
  changeInput: ((value: string) => void) | undefined;
  validateInput?: (value: string) => void;
  disabled?: boolean;
  warning?: string;
};

export const MaterialTextInput: React.FC<MaterialTextInput> = ({
  textInput,
  placeholder,
  changeInput,
  validateInput,
  disabled = false,
  warning,
}) => {
  return (
    <div style={{ margin: '8px 0' }}>
      <TextArea
        value={textInput}
        onChange={changeInput}
        onBlur={validateInput}
        placeholder={placeholder}
        disabled={disabled}
        style={{ height: 88 }}
      />
      {warning && warning.length > 0 && <p className={styles['warning-text']}> {warning}</p>}
    </div>
  );
};
