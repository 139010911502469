import { ComplexData } from '../types';

export const parseObjectToStringComplex = (obj: ComplexData) => {
  let result = '';

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      result += `${key}       ${obj[key].real}       ${obj[key].imag}\n`;
    }
  }

  // Remove the trailing newline character
  return result.trim();
};
