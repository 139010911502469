import styles from './styles.module.scss';

type TrblToggleProps = {
  id?: string;
  checked: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  dark?: boolean;
  onChangeToggle: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const TrblToggle = ({ id, checked, ariaLabel, disabled, dark, onChangeToggle }: TrblToggleProps) => {
  return (
    <label className={`${styles['switch']} ${dark ? styles['dark'] : ''}`} htmlFor={id ?? 'toggle'}>
      <input
        aria-label={ariaLabel}
        id={id ?? 'toggle'}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChangeToggle}></input>
      <span className={`${styles['slider']} ${styles['round']}`} />
    </label>
  );
};
