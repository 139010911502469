import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OptionUnstyled, SelectUnstyled } from '@mui/base';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { OptionContentWithStatus } from './OptionContentWithStatus';

import { setSimulationStatus } from './utils';

import { Simulation } from '@/types';

type SimulationDropdown = {
  simulations: Simulation[];
  defaultSimulation?: Simulation | null;
};

export const SimulationDropdown = ({ simulations, defaultSimulation }: SimulationDropdown) => {
  const [selectedSim, setSelectedSim] = useState<Simulation>(defaultSimulation as Simulation);
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isAuralizerOpen } = useEditorContext();

  useEffect(() => {
    if (simulations) {
      for (let i = 0; i < simulations.length; i++) {
        setSimulationStatus(simulations[i]);
      }
    }
  }, [simulations]);

  useEffect(() => {
    // This is for when the global state changes like
    // when a new simulation is created or deleted
    if (selectedSimulation) {
      setSelectedSim(selectedSimulation);
    }
  }, [selectedSimulation]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeSelectedSim = (_: any, thisSimId: string | null) => {
    if (simulations) {
      const selectedOption = simulations.filter((sim: Simulation) => sim.id === thisSimId);
      if (selectedOption[0]) {
        if (searchParams.get('mid')) {
          // Backwards compatible for SketchUp
          setSearchParams({ mid: selectedOption[0].modelId, sid: selectedOption[0].id }, { replace: true });
        } else {
          setSearchParams({ sid: selectedOption[0].id }, { replace: true });
        }
        setSelectedSim(selectedOption[0]);
      }
    }
  };

  return (
    <div className="sim-selector-container">
      {isAuralizerOpen ? (
        <p className="sim-name-aur"> {selectedSimulation?.name} </p>
      ) : (
        <SelectUnstyled onChange={changeSelectedSim} className="sim-selector" value={selectedSim ? selectedSim.id : ''}>
          {simulations &&
            simulations.length > 0 &&
            [...simulations].reverse().map((simulation: Simulation, index: number) => (
              <OptionUnstyled value={simulation?.id} key={simulation?.id || index} style={{ zIndex: 3 }}>
                <OptionContentWithStatus
                  name={simulation.name}
                  id={simulation?.id}
                  status={simulation.extra.status}
                  createdAt={simulation.createdAt}
                  simulationRunCreatedAt={simulation.lastSimulationRun?.createdAt}
                  simulationRunCompletedAt={simulation.lastSimulationRun?.completedAt}
                  hasBeenEdited={simulation.hasBeenEdited}
                />
              </OptionUnstyled>
            ))}
        </SelectUnstyled>
      )}
    </div>
  );
};
