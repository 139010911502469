import { useQuery } from '@tanstack/react-query';

import { GridReceiverResultsDto, Point, ResultType } from '@/types';

import axios from '@/axios';

export type SourceSummingResultForGridReceiverDto = {
  id: string;
  label: string | null;
  createdAt: string;
  resultType: ResultType;
  taskId: string;
  sources: Point[];
  frequencies: number[];
  gridReceiverResults: GridReceiverResultsDto[];
};

const getLastGridReceiverSourceSummingResults = async (
  simulationId: string
): Promise<SourceSummingResultForGridReceiverDto[]> => {
  const { data } = await axios.get(`/api/SourceSumming/GetLastGridReceiverSourceSummingResults`, {
    params: {
      simulationId,
    },
  });

  return data;
};

export const useGetLastGridReceiverSourceSummingResultsBySimulationId = (
  simulationId: string,
  refetchInterval?: number
) => {
  return useQuery<SourceSummingResultForGridReceiverDto[], boolean>(
    ['lastGridReceiverSourceSummingResultsBySimulationId', simulationId],
    () => getLastGridReceiverSourceSummingResults(simulationId),
    {
      refetchInterval,
      refetchOnWindowFocus: false,
      enabled: !!simulationId.length,
      staleTime: 60000, // stale time is set to 1 minute, after that time it re-fetches
    }
  );
};
