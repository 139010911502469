import { Fragment, ReactElement, ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { TrblChevronLeft } from '../Icons';

import './styles.scss';

type Breadcrumb = {
  text: string | ReactNode;
  to?: string;
  icon?: ReactElement;
  hidden?: boolean;
};

export const Breadcrumbs = ({ items }: { items: Breadcrumb[] }) => {
  const navigate = useNavigate();

  return (
    <div className="breadcrumbs-div">
      <IconButton className="back-btn" onClick={() => navigate(-1)}>
        <TrblChevronLeft />
      </IconButton>
      <div className="top-info-text">
        {items.map((item: Breadcrumb, index: number) => (
          <Fragment key={index}>
            {!item.hidden && (
              <>
                {item.icon && <div className="breadcrumbs-icon">{item.icon}</div>}
                {item.to ? (
                  <Link to={item.to}>{item.text}</Link>
                ) : (
                  <span className={index == items.length - 1 ? 'active' : ''}>{item.text}</span>
                )}
                {index < items.length - 1 && !items[index + 1].hidden && <span className="arrow"> &rsaquo; </span>}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
