import { useEffect, useRef, useState } from 'react';

import { useSimulationContext } from '@/context/SimulationContext';

import { TrblCaretDownIcon, TrblCaretRightIcon } from '../Icons';
import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { HideLayerCell } from './HideLayerCell';
import { LayerNameCell } from './LayerNameCell';
import { MaterialNameCell } from './MaterialNameCell';
import { ScatterCell } from './ScatterCell/ScatterCell';

import { useHandleRowClick } from './hooks/useHandleRowClick';
import { useScrollIntoView } from './hooks/useScrollIntoView';

import { MaterialLayer } from '@/types';

export const LayerRowContent = ({
  layer,
  textName,
  layerChildren,
  updateLayerChildren,
  toggleParentLayer,
  isChildOpen,
}: {
  layer: MaterialLayer;
  textName: string;
  layerChildren?: MaterialLayer[];
  updateLayerChildren: (layer: MaterialLayer) => void;
  toggleParentLayer: (event: React.MouseEvent<HTMLElement>) => void;
  isChildOpen: boolean;
}) => {
  const layerItem = useRef(null);

  const {
    simulationState: { hiddenLayers },
  } = useSimulationContext();
  const { selectedLayer, multiSelectedItemIds } = useLibraryPanelContext();
  const [isSelected, setIsSelected] = useState(false);
  const handleRowClick = useHandleRowClick();
  useScrollIntoView(layer, layerItem);

  useEffect(() => {
    setIsSelected(selectedLayer?.id === layer.id || multiSelectedItemIds?.includes(layer.id));
  }, [multiSelectedItemIds, selectedLayer]);

  useEffect(() => {
    if (layerItem.current && isSelected) {
      (layerItem.current as HTMLElement)?.focus();
    }
  }, [layerItem, isSelected]);

  return (
    <div
      ref={layerItem}
      className={`treble-layer-row ${isSelected ? 'selected' : ''}`}
      onClick={(e) => handleRowClick(e, layer, layer.layerIndex)}>
      <div className="treble-layers-cell first-cell">
        {layerChildren && layerChildren.length > 1 && (
          <button onClick={toggleParentLayer} tabIndex={-1} className="toggle-button">
            {isChildOpen ? <TrblCaretDownIcon fill="#616161" /> : <TrblCaretRightIcon fill="#616161" />}
          </button>
        )}
      </div>
      <LayerNameCell layer={layer} />
      <MaterialNameCell layer={layer} isSelected={isSelected} index={layer.layerIndex} />
      <ScatterCell
        layerName={textName}
        layer={layer}
        layerChildren={layerChildren}
        updateLayerChildren={updateLayerChildren}
      />
      <HideLayerCell
        isSelected={isSelected}
        layer={layer}
        isHidden={hiddenLayers.findIndex((x) => x.id === layer.id) > -1}
      />
    </div>
  );
};
