import { useState } from 'react';

import { useModelContext } from '@/context/ModelContext';

import { EditSimulation } from '@/components/EditSimulation';
import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useShareAndUpdateSourceDefinition } from './useShareAndUpdateSourceDefinition';

import { SOURCE_DEFINITION_SAVE_TEXT } from '../constants';

import { Source } from '@/context/EditorContext/types';
import { Simulation, SourceDefinition } from '@/types';

export const useEditSourceDefinitionPopup = (): [
  () => JSX.Element | null,
  (updatedSimulation: Simulation, newSourceDefinition: SourceDefinition, userId?: string) => void,
  boolean
] => {
  const { dispatch: libraryDispatch, selectedSource } = useLibraryPanelContext();
  const { modelInformation } = useModelContext();
  const shareAndUpdateSourceDefinition = useShareAndUpdateSourceDefinition();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [simulation, setSimulation] = useState<Simulation | null>();
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition | null>();
  const [userId, setUserId] = useState<string>();

  const openPopup = (updatedSimulation: Simulation, newSourceDefinition: SourceDefinition, userId?: string) => {
    setSimulation(updatedSimulation);
    setSourceDefinition(newSourceDefinition);
    setIsPopupOpen(true);
    setUserId(userId);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const onConfirm = (updatedSimulation: Simulation, sourceDefinition: SourceDefinition) => {
    if (selectedSource) {
      const updatedParams = updatedSimulation.sourceParameters.find((param) => param.sourceId === selectedSource.id);
      const updatedSource: Source = {
        ...selectedSource,
        // @ts-expect-error Type 'string | undefined' is not assignable to type 'string'.
        params: {
          ...updatedParams,
        },
      };
      libraryDispatch({
        type: LibraryActionType.SET_SELECTED_SOURCE,
        source: updatedSource,
        sourceDefinition: sourceDefinition,
      });

      // if the model is in a shared project and source definition is created by the user and not shared with the organization
      // then share the source definition
      if (
        modelInformation?.isProjectShared &&
        sourceDefinition.userId == userId &&
        !sourceDefinition.isSharedWithOrganization
      ) {
        shareAndUpdateSourceDefinition(sourceDefinition);
      }
    }
    closePopup();
  };

  const EditSourceDefinitionPopup = () => {
    if (isPopupOpen && simulation && sourceDefinition) {
      return (
        <EditSimulation
          showPopup={isPopupOpen}
          closePopup={closePopup}
          updatedSimulation={simulation}
          onConfirm={() => onConfirm(simulation, sourceDefinition)}
          saveText={SOURCE_DEFINITION_SAVE_TEXT}
        />
      );
    } else {
      return null;
    }
  };

  return [EditSourceDefinitionPopup, openPopup, isPopupOpen];
};
