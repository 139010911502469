import { IconProps } from '@/types';

export const TrblUserCreatedIcon = ({ width = '10', height = '10', fill = '#999999' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1429 11.1426C9.82872 9.23255 7.9602 8.14258 6.00004 8.14258C4.03987 8.14258 2.17135 9.23255 0.857178 11.1426"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00002 6.00028C7.42018 6.00028 8.57145 4.84901 8.57145 3.42885C8.57145 2.00869 7.42018 0.857422 6.00002 0.857422C4.57986 0.857422 3.42859 2.00869 3.42859 3.42885C3.42859 4.84901 4.57986 6.00028 6.00002 6.00028Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
