import { useRouteError } from 'react-router-dom';

import { ErrorPage } from './Error';

export const UnexpectedErrorPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  if (error) {
    console.error(error);
  }

  return <ErrorPage />;
};
