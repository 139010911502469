import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { SxProps, Theme } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { RowActions } from './RowActions';
import { SelectCell } from './SelectCell';

import { UserAccess } from '../types';

import styles from './styles.module.scss';

export const gridStyleOverrides: SxProps<Theme> = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
};

export const getColumns = (
  currentUserId: string,
  onEditUser: (userId: string) => void,
  onDeleteUser: (userId: string) => void,
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void,
  onToggleIsEnabled: (userId: string, isEnabled: boolean) => void,
  onToggleHasSDKAccess: (userId: string, isEnabled: boolean) => void,
  hasAvailableSeats: boolean,
  automaticBillingFeature: boolean,
  isUsingSso: boolean,
  hasSDK: boolean
): GridColDef<UserAccess>[] => {
  const columns: GridColDef<UserAccess>[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'date',
      width: 150,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'hasSDKAccess',
      headerName: 'SDK access',
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => (
        <TrblTooltip
          title={
            !params.value && !hasAvailableSeats
              ? 'You can not enable this user - please add more seats'
              : params.value && currentUserId === params.row.id
              ? 'You can not disable your own account'
              : ''
          }>
          <span>
            <SelectCell
              menuItems={[
                { name: 'Active', id: 'active' },
                { name: 'Disabled', id: 'disabled' },
              ]}
              value={params.value ? 'active' : 'disabled'}
              onSelect={(value) => {
                onToggleHasSDKAccess(params.row.id, value == 'active');
              }}
              confirmationText={
                !params.value
                  ? `Are you sure you want to grant SDK access to this user?`
                  : `Are you sure you want to revoke SDK access for this user?`
              }
              // If the user is not enabled and there are no available seats, the switch should be disabled
              // Also you should not be able to disable yourself (the logged in user)
              disabled={!params.value && !hasAvailableSeats}
            />
          </span>
        </TrblTooltip>
      ),
      editable: false,
      headerClassName: styles['data-grid-header'],
      hide: !hasSDK,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => (
        <RowActions params={params} onEditUser={onEditUser} onDeleteUser={onDeleteUser} isUsingSso={isUsingSso} />
      ),
      headerClassName: styles['data-grid-header'],
    },
  ];

  if (automaticBillingFeature) {
    columns.splice(4, 0, {
      field: 'isEnabled',
      headerName: hasSDK ? 'Web app access' : 'Status',
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => (
        <TrblTooltip
          title={
            !params.value && !hasAvailableSeats
              ? 'You can not enable this user - please add more seats'
              : params.value && currentUserId === params.row.id
              ? 'You can not disable your own account'
              : ''
          }>
          <span>
            <SelectCell
              menuItems={[
                { name: 'Active', id: 'active' },
                { name: 'Disabled', id: 'disabled' },
              ]}
              value={params.value ? 'active' : 'disabled'}
              onSelect={(value) => {
                onToggleIsEnabled(params.row.id, value == 'active');
              }}
              confirmationText={
                !params.value
                  ? hasSDK
                    ? 'Are you sure you want to grant web app access to this user?'
                    : 'Are you sure you want to activate this user?'
                  : hasSDK
                  ? 'Are you sure you want to revoke web app access for this user?'
                  : 'Are you sure you want to disable this user?'
              }
              // If the user is not enabled and there are no available seats, the switch should be disabled
              // Also you should not be able to disable yourself (the logged in user)
              disabled={(!params.value && !hasAvailableSeats) || (params.value && currentUserId === params.row.id)}
            />
          </span>
        </TrblTooltip>
      ),
      headerClassName: styles['data-grid-header'],
    });
  }

  columns.splice(3, 0, {
    field: 'isAdmin',
    headerName: 'Role',
    width: 100,
    disableColumnMenu: true,
    renderCell: (params) => (
      <TrblTooltip
        title={params.value && currentUserId === params.row.id ? 'You can not revoke your own admin permissions' : ''}>
        <span>
          <SelectCell
            menuItems={[
              { name: 'User', id: 'user' },
              { name: 'Admin', id: 'admin' },
            ]}
            value={params.value ? 'admin' : 'user'}
            onSelect={(value) => {
              onToggleIsAdmin(params.row.id, value == 'admin');
            }}
            confirmationText={
              !params.value
                ? `Are you sure you want to grant Admin permissions to this user?`
                : `Are you sure you want to revoke Admin permissions for this user?`
            }
            // You should not be able to revoke you own admin rights (the logged in user)
            disabled={params.value && currentUserId === params.row.id}
          />
        </span>
      </TrblTooltip>
    ),
    headerClassName: styles['data-grid-header'],
  });

  return columns;
};
