import { useState } from 'react';

import { useResultsContext } from '../../context/ResultsContext';

import { TrblAddIconCircle } from '@/components/Icons';
import { AddComparisonPopup } from '../AddComparisonPopup/AddComparisonPopup';

import classes from './styles.module.scss';

export const AddComparisonButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { allSpacesWithSims } = useResultsContext();

  return (
    <>
      <button
        disabled={!allSpacesWithSims || Object.keys(allSpacesWithSims).length === 0}
        onClick={() => setShowPopup(true)}
        className={classes.add_comparison_button}>
        <span className={classes.add_comparison_button__text}>Add simulation</span>
        <TrblAddIconCircle />
      </button>
      <AddComparisonPopup showPopup={showPopup} setShowPopup={setShowPopup} />
    </>
  );
};
