import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblMenuVerticalIcon: FC<IconProps> = ({ width = '14', height = '14', fill = '#DADADA' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83203 7.00033C5.83203 6.35599 6.35437 5.83366 6.9987 5.83366C7.64303 5.83366 8.16536 6.35599 8.16536 7.00033C8.16536 7.64466 7.64303 8.16699 6.9987 8.16699C6.35437 8.16699 5.83203 7.64466 5.83203 7.00033ZM6.99878 10.0417C7.64311 10.0417 8.16545 10.564 8.16545 11.2083C8.16545 11.8527 7.64311 12.375 6.99878 12.375C6.35445 12.375 5.83211 11.8527 5.83211 11.2083C5.83211 10.564 6.35445 10.0417 6.99878 10.0417ZM6.9987 3.95801C7.64303 3.95801 8.16536 3.43567 8.16536 2.79134C8.16536 2.14701 7.64303 1.62467 6.9987 1.62467C6.35437 1.62467 5.83203 2.14701 5.83203 2.79134C5.83203 3.43567 6.35437 3.95801 6.9987 3.95801Z"
      fill={fill}
    />
  </svg>
);
