import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { ActionType } from '@/components/Results/context/ReflectogramResultsContext/types';
import { useArrowKeyPress } from './';

export const useReflectogramKeyPressHandlers = (selectedReflectionIndex: number | null) => {
  const {
    dispatch,
    state: { relativeAndFilteredData },
  } = useReflectogramResultsContext();

  const handleSelectPreviousIndex = () => {
    let newIndex: number | null;
    if (selectedReflectionIndex === null) {
      newIndex = relativeAndFilteredData.length - 1;
    } else if (selectedReflectionIndex === 0) {
      newIndex = null;
    } else {
      newIndex = selectedReflectionIndex - 1;
    }

    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: newIndex,
    });
  };

  const handleSelectNextIndex = () => {
    let newIndex: number | null;
    if (selectedReflectionIndex === null) {
      newIndex = 0;
    } else if (selectedReflectionIndex === relativeAndFilteredData.length - 1) {
      newIndex = null;
    } else {
      newIndex = selectedReflectionIndex + 1;
    }

    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: newIndex,
    });
  };

  useArrowKeyPress(
    relativeAndFilteredData.length ? handleSelectPreviousIndex : null,
    relativeAndFilteredData.length ? handleSelectNextIndex : null
  );

  return {
    handleSelectPreviousIndex,
    handleSelectNextIndex,
  };
};
