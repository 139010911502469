import { FREQUENCIES } from '../constants';

import styles from '../styles.module.scss';

export const FrequencyTable = ({
  data,
  showData,
  fontSize,
}: {
  data: number[];
  showData: boolean;
  fontSize?: string;
}) => {
  return (
    <table className={styles['freq-table']} style={{ fontSize: fontSize }}>
      <tbody>
        <tr>
          {FREQUENCIES.map((freq: string) => (
            <th key={freq}>{freq}</th>
          ))}
        </tr>
        <tr>
          {showData
            ? data.map((num: number, index: number) => <td key={index}>{num}</td>)
            : FREQUENCIES.map((_, index: number) => <td key={index}>-</td>)}
        </tr>
      </tbody>
    </table>
  );
};
