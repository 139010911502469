import colors from '@/theme/colors.module.scss';

export const comparisonsColors: string[] = [
  colors.plotBlue,
  colors.plotGreen,
  colors.plotPurple,
  colors.plotYellow,
  colors.plotPink,
  colors.plotGray,
];

export const gradientEndColors = {
  [colors.plotBlue]: [colors.plotBlueLighter, colors.plotBlueDarker],
  [colors.plotYellow]: [colors.plotYellowLighter, colors.plotYellowDarker],
  [colors.plotPink]: [colors.plotPinkLighter, colors.plotPinkDarker],
  [colors.plotPurple]: [colors.plotPurpleLighter, colors.plotPurpleDarker],
  [colors.plotGreen]: [colors.plotGreen, colors.plotGreenDarker],
  [colors.plotGray]: [colors.plotGrayLighter, colors.plotGrayDarker],
};

export const MAX_COMPARISONS = 6;
