import { useEffect, useState } from 'react';

import { SimulationsInSpace } from '@/context/AppContext';

import { SelectOptionWithGrouping } from '@/components/Shared/TrblSelect';
import { createSelectMenu } from '@/components/CreateNewSimulationPopup/utils';
import { useAuralizerContext } from '../AuralizerContext';
import { SimulationComparison } from './SimulationComparison';

import { addDisabledToMenuItems, filterUndefinedAndNotMatchingLastRun } from '../utils';

import { ModelSimulationsDto, Simulation } from '@/types';

import '../style.scss';

export const SimulationsColumn = ({ originalSim }: { originalSim: Simulation }) => {
  const { simsToCompare, simSrcRecHash, availableSimsToCompare } = useAuralizerContext();

  const [validSimsByModels, setValidSimsByModels] = useState<SimulationsInSpace[]>([]);
  const [menuItems, setMenuItems] = useState<SelectOptionWithGrouping[]>([] as SelectOptionWithGrouping[]);

  useEffect(() => {
    // Filter out simulations that can not be compared and parse into Menu items
    if (availableSimsToCompare && originalSim && simSrcRecHash) {
      const tempValidSimsByModels = availableSimsToCompare.reduce(
        (acc: SimulationsInSpace[], simByModel: ModelSimulationsDto) => {
          const validSims = simByModel.simulations.filter((simulation) =>
            filterUndefinedAndNotMatchingLastRun(simulation, originalSim, simSrcRecHash)
          );
          if (validSims.length > 0) {
            acc.push({
              name: simByModel.modelBaseName,
              id: simByModel.modelId,
              createdAt: simByModel.modelCreatedAt,
              simulations: validSims,
            });
          }
          return acc;
        },
        [] as SimulationsInSpace[]
      );

      setValidSimsByModels(tempValidSimsByModels);
    }
  }, [availableSimsToCompare, originalSim, simSrcRecHash]);

  useEffect(() => {
    if (validSimsByModels.length > 0) {
      createMenuItems(validSimsByModels, simsToCompare);
    }
  }, [validSimsByModels, simsToCompare]);

  const createMenuItems = (spaceSimulationsBySpace: SimulationsInSpace[], simsToCompare: Simulation[]) => {
    const sortedMenuItems = createSelectMenu(spaceSimulationsBySpace, originalSim.modelId);
    const parsedMenuItems = addDisabledToMenuItems(sortedMenuItems, simsToCompare);
    setMenuItems(parsedMenuItems);
  };

  return (
    <div className="simulations-column">
      {originalSim && <SimulationComparison originalSim={originalSim} menuItems={menuItems} />}
    </div>
  );
};
