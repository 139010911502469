import { useMemo } from 'react';
import { Data } from 'plotly.js';

import { getTimeOfArrivalColor } from '@/components/Editor/components/Viewport/ReflectogramResults/utils';
import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { TimeOfArrivalGroup } from '../../../SubHeaders/ReflectogramResultsHeader/constants';

import { getSPLUnit, getSplValue } from '../../utils';
import { roundFloat } from '@/utils/trebleFunctions';

import { AxisType } from '../types';

type UsePlotDataProps = {
  data: ReflectionDetails[];
  selectedReflectionIndex: number | null;
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedFrequencyBandIndex: number | null;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
  selectedXAxisType: AxisType;
};

export const useReflectionsPlotData = ({
  data,
  selectedReflectionIndex,
  selectedFrequencyWeighting,
  selectedFrequencyBandIndex,
  timeOfArrivalGroups,
  selectedXAxisType,
}: UsePlotDataProps): Data[] => {
  return useMemo(() => {
    const baseDataPoints = data.map(
      (d, index): Data => ({
        x: [
          selectedXAxisType === AxisType.Linear ? d.timeOfArrivalRelative * 1000 : index + 1,
          selectedXAxisType === AxisType.Linear ? d.timeOfArrivalRelative * 1000 : index + 1,
        ], // Two x-values to make a vertical line
        y: [0, getSplValue(d, selectedFrequencyWeighting, selectedFrequencyBandIndex)], // Starting at 0 for the bottom of the line
        type: 'scatter',
        mode: 'lines',
        line: {
          width: 2, // Consistent line width
          color: getTimeOfArrivalColor(d.timeOfArrivalRelative, timeOfArrivalGroups, selectedReflectionIndex === index),
        },
        text: `Reflection: ${index + 1} of ${data.length}<br>SPL: ${roundFloat(
          getSplValue(d, selectedFrequencyWeighting, selectedFrequencyBandIndex),
          2
        )} ${getSPLUnit(selectedFrequencyWeighting)}<br>Time (Rel. direct): ${roundFloat(
          d.timeOfArrivalRelative * 1000,
          2
        )} ms`,
        hoverinfo: `text`,
      })
    );

    // Adding an additional data point for the selected reflection to make it appear in front of other lines
    if (
      selectedXAxisType === AxisType.Linear &&
      selectedReflectionIndex !== null &&
      selectedReflectionIndex >= 0 &&
      selectedReflectionIndex < data.length
    ) {
      const selectedData = data[selectedReflectionIndex];
      const selectedDataPoint: Data = {
        x: [selectedData.timeOfArrivalRelative * 1000, selectedData.timeOfArrivalRelative * 1000], // Two x-values for vertical line
        y: [0, getSplValue(selectedData, selectedFrequencyWeighting, selectedFrequencyBandIndex)], // Line from 0 to SPL
        type: 'scatter',
        mode: 'lines',
        line: {
          width: 3, // Slightly thicker for the selected line
          color: getTimeOfArrivalColor(selectedData.timeOfArrivalRelative, timeOfArrivalGroups, true),
        },
        text: `Reflection: ${selectedReflectionIndex + 1} of ${data.length}, SPL: ${roundFloat(
          getSplValue(selectedData, selectedFrequencyWeighting, selectedFrequencyBandIndex),
          2
        )} ${getSPLUnit(selectedFrequencyWeighting)}, Time (Rel. direct): ${roundFloat(
          selectedData.timeOfArrivalRelative * 1000,
          2
        )} ms`,
        hoverinfo: 'text',
      };

      baseDataPoints.push(selectedDataPoint);
    }

    return baseDataPoints;
  }, [
    data,
    selectedXAxisType,
    selectedFrequencyWeighting,
    selectedFrequencyBandIndex,
    selectedReflectionIndex,
    timeOfArrivalGroups,
  ]);
};
