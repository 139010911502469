import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblMenuHorizontalIcon: FC<IconProps> = ({ fill }) => (
  <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833008 6.00033C0.833008 5.35599 1.35534 4.83366 1.99967 4.83366C2.64401 4.83366 3.16634 5.35599 3.16634 6.00033C3.16634 6.64466 2.64401 7.16699 1.99967 7.16699C1.35534 7.16699 0.833008 6.64466 0.833008 6.00033ZM1.99976 9.04167C2.64409 9.04167 3.16642 9.564 3.16642 10.2083C3.16642 10.8527 2.64409 11.375 1.99976 11.375C1.35542 11.375 0.833089 10.8527 0.833089 10.2083C0.833089 9.564 1.35542 9.04167 1.99976 9.04167ZM1.99967 2.95801C2.64401 2.95801 3.16634 2.43567 3.16634 1.79134C3.16634 1.14701 2.64401 0.624675 1.99967 0.624675C1.35534 0.624675 0.833008 1.14701 0.833008 1.79134C0.833008 2.43567 1.35534 2.95801 1.99967 2.95801Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
