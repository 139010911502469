import { HeaderActionsLeft } from './HeaderActionsLeft';
import { HeaderActionsRight } from './HeaderActionsRight';
import { PresetInfo } from './PresetInfo';
import { ReceiverControls } from './ReceiverControls';

import { Receiver, Simulation, Source } from '@/types';

import '../../style.scss';

export const AuralizerPanelHeader = ({
  originalSim,
  sources,
  isMinimized = false,
  setIsMinimized,
  availableReceivers,
}: {
  originalSim: Simulation;
  sources: Source[];
  isMinimized: boolean;
  setIsMinimized: (value: boolean) => void;
  availableReceivers: Receiver[];
}) => {
  return (
    <div className="auralizer-header">
      <HeaderActionsLeft originalSim={originalSim} sources={sources} isMinimized={isMinimized} />
      <div className="center-content">
        <PresetInfo />
        <ReceiverControls isMinimized={isMinimized} availableReceivers={availableReceivers} />
      </div>
      <HeaderActionsRight isMinimized={isMinimized} setIsMinimized={setIsMinimized} />
    </div>
  );
};
