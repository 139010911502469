import { useState } from 'react';

import { useUpdateSurfaceLayers } from '@/context/hooks/useUpdateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { EditSimulation } from '@/components/EditSimulation';
import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useShareAndUpdateMaterial } from './useShareAndUpdateMaterial';

import { MATERIAL_SAVE_TEXT } from '../constants';

import { Material, Simulation } from '@/types';

export const useEditMaterialPopup = (): [
  () => JSX.Element | null,
  (updatedSimulation: Simulation, material: Material, layerIds: string[], userId?: string) => void,
  boolean
] => {
  const { dispatch } = useSimulationContext();
  const { modelInformation } = useModelContext();
  const { dispatch: libraryDispatch, isMaterialsLibraryOpen } = useLibraryPanelContext();
  const shareAndUpdateMaterial = useShareAndUpdateMaterial();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [simulation, setSimulation] = useState<Simulation | null>();
  const [material, setMaterial] = useState<Material | null>();
  const [layerIds, setLayerIds] = useState<string[]>();
  const [userId, setUserId] = useState<string>();

  const updateSurfaceLayers = useUpdateSurfaceLayers();

  const openPopup = (updatedSimulation: Simulation, material: Material, layerIds: string[], userId?: string) => {
    setSimulation(updatedSimulation);
    setMaterial(material);
    setPopupOpen(true);
    setLayerIds(layerIds);
    setUserId(userId);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const onConfirm = (updatedSimulation: Simulation, material: Material) => {
    if (updatedSimulation && layerIds) {
      const newSurfaceLayers = updateSurfaceLayers(layerIds, material);
      dispatch({
        type: ActionType.UPDATE_SURFACE_LAYERS,
        newSurfaceLayers,
      });

      if (!isMaterialsLibraryOpen) {
        libraryDispatch({
          type: LibraryActionType.SET_MATERIALS_PANEL_OPEN,
          isOpen: true,
        });
      }

      libraryDispatch({
        type: LibraryActionType.SET_SELECTED_MATERIAL,
        material: material,
        highlightedItemId: material.id,
      });

      // if the model is in a shared project and material is created by the user and not shared with the organization
      // then share the material
      if (modelInformation?.isProjectShared && material.userId == userId && !material.isSharedWithOrganization) {
        shareAndUpdateMaterial(material);
      }
    }
    closePopup();
  };

  const EditMaterialPopup = () => {
    if (isPopupOpen && simulation && material) {
      return (
        <EditSimulation
          showPopup={isPopupOpen}
          closePopup={closePopup}
          updatedSimulation={simulation}
          onConfirm={() => onConfirm(simulation, material)}
          saveText={MATERIAL_SAVE_TEXT}
        />
      );
    } else {
      return null;
    }
  };

  return [EditMaterialPopup, openPopup, isPopupOpen];
};
