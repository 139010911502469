import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblIfcIcon: FC<IconProps> = ({ fill = '#DADADA', width = '29', height = '28' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8288 3.32967L10.6706 0.170917C10.5612 0.0615129 10.4129 3.30385e-05 10.2582 0H3.49967C2.85534 0 2.33301 0.522334 2.33301 1.16667V4.52083C2.33301 4.60137 2.3983 4.66667 2.47884 4.66667H3.35384C3.43438 4.66667 3.49967 4.60137 3.49967 4.52083V1.45833C3.49967 1.29725 3.63026 1.16667 3.79134 1.16667H9.77051C9.85105 1.16667 9.91634 1.23196 9.91634 1.3125V2.91667C9.91634 3.561 10.4387 4.08333 11.083 4.08333H12.6872C12.7677 4.08333 12.833 4.14862 12.833 4.22917V12.5417C12.833 12.7027 12.7024 12.8333 12.5413 12.8333H3.64551C3.56497 12.8333 3.49967 12.768 3.49967 12.6875V12.3958C3.49967 12.3153 3.43438 12.25 3.35384 12.25H2.47884C2.3983 12.25 2.33301 12.3153 2.33301 12.3958V12.8333C2.33301 13.4777 2.85534 14 3.49967 14H12.833C13.4773 14 13.9997 13.4777 13.9997 12.8333V3.7415C13.9995 3.58701 13.938 3.43889 13.8288 3.32967Z"
      fill="#DADADA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91667 5.25C10.2388 5.25 10.5 5.51117 10.5 5.83333V11.0833C10.5 11.4055 10.2388 11.6667 9.91667 11.6667H0.583333C0.261167 11.6667 0 11.4055 0 11.0833V5.83333C0 5.51117 0.261167 5.25 0.583333 5.25H9.91667ZM4.94792 7.07292H6.11458C6.31594 7.07292 6.47917 6.90969 6.47917 6.70833C6.47917 6.50698 6.31594 6.34375 6.11458 6.34375H4.94792C4.42453 6.34407 4.00032 6.76828 4 7.29167V10.2083C4 10.4097 4.16323 10.5729 4.36458 10.5729C4.56594 10.5729 4.72917 10.4097 4.72917 10.2083V8.96875C4.72917 8.88821 4.79446 8.82292 4.875 8.82292H5.53125C5.7326 8.82292 5.89583 8.65969 5.89583 8.45833C5.89583 8.25698 5.7326 8.09375 5.53125 8.09375H4.875C4.79446 8.09375 4.72917 8.02846 4.72917 7.94792V7.29167C4.72917 7.17085 4.8271 7.07292 4.94792 7.07292ZM9.04134 9.84375C9.24269 9.84375 9.40592 10.007 9.40592 10.2083C9.40592 10.4097 9.24269 10.5729 9.04134 10.5729C7.87349 10.5729 6.92676 9.62619 6.92676 8.45833C6.92676 7.29048 7.87349 6.34375 9.04134 6.34375C9.24269 6.34375 9.40592 6.50698 9.40592 6.70833C9.40592 6.90969 9.24269 7.07292 9.04134 7.07292C8.54638 7.07292 8.08902 7.33698 7.84154 7.76562C7.59405 8.19427 7.59405 8.72239 7.84154 9.15104C8.08902 9.57969 8.54638 9.84375 9.04134 9.84375ZM2.83301 9.84424H2.76126C2.72227 9.84424 2.68488 9.82875 2.65731 9.80118C2.62975 9.77361 2.61426 9.73623 2.61426 9.69724V7.21865C2.61426 7.18013 2.62956 7.14319 2.6568 7.11595C2.68404 7.08871 2.72098 7.0734 2.75951 7.0734H2.83301C3.03436 7.0734 3.19759 6.91018 3.19759 6.70882C3.19759 6.50747 3.03436 6.34424 2.83301 6.34424H1.66634C1.46499 6.34424 1.30176 6.50747 1.30176 6.70882C1.30176 6.91018 1.46499 7.0734 1.66634 7.0734H1.73809C1.81928 7.0734 1.88509 7.13922 1.88509 7.2204V9.69899C1.88509 9.73751 1.86979 9.77446 1.84255 9.8017C1.81531 9.82894 1.77836 9.84424 1.73984 9.84424H1.66634C1.46499 9.84424 1.30176 10.0075 1.30176 10.2088C1.30176 10.4102 1.46499 10.5734 1.66634 10.5734H2.83301C3.03436 10.5734 3.19759 10.4102 3.19759 10.2088C3.19759 10.0075 3.03436 9.84424 2.83301 9.84424Z"
      fill={fill}
    />
  </svg>
);
