import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { ALPHABET_OPTIONS } from '@/components/Shared/Auralizer';
import { SelectOptionWithGrouping } from '@/components/Shared/TrblSelect';
import { TrblAddIcon } from '@/components/Icons';

import '../style.scss';

export const AddSimulation = ({
  comparisons,
  setComparisons,
  menuItems,
}: {
  comparisons: string[];
  setComparisons: React.Dispatch<React.SetStateAction<string[]>>;
  menuItems: SelectOptionWithGrouping[];
}) => {
  const [maxComparisons, setMaxComparisons] = useState<number>(ALPHABET_OPTIONS.length);

  useEffect(() => {
    if (menuItems) {
      const maxNumberOfMenuItems = menuItems.flatMap((spaces) => spaces.options).length;
      if (maxNumberOfMenuItems < ALPHABET_OPTIONS.length) {
        setMaxComparisons(maxNumberOfMenuItems);
      } else {
        setMaxComparisons(ALPHABET_OPTIONS.length);
      }
    }
  }, [menuItems]);

  const addSimulation = () => {
    const randomId = Math.floor(Math.random() * 100) + Date.now().toString();
    const newComparisons = [...comparisons, randomId];
    setComparisons(newComparisons);
  };

  return (
    <TrblTooltip
      title={
        maxComparisons === 1
          ? 'You do not have any comparable simulations'
          : comparisons.length >= ALPHABET_OPTIONS.length
          ? `You can not add more than ${ALPHABET_OPTIONS.length} simulations to compare`
          : maxComparisons === 0
          ? `You have no simulations to compare`
          : comparisons.length === maxComparisons
          ? `You have no more comparable simulations`
          : ''
      }>
      <span>
        <button
          disabled={comparisons.length >= maxComparisons}
          className={`add-sim-compare-button ${comparisons.length >= 4 ? 'fade-out' : ''}`}
          onClick={addSimulation}>
          Add simulation
          <span className="add-sim-icon">
            <TrblAddIcon />
          </span>
        </button>
      </span>
    </TrblTooltip>
  );
};
