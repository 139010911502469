import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { MaterialDto } from '@/types';

import axios from '@/axios';

type PerformLambdaMaterialBuilder = {
  rigidBackingLayer: unknown;
  airCavityLayer?: {
    thicknessInMm: number | undefined;
  };
  porousAbsorberLayer: {
    thicknessInMm: number | undefined;
    flowResistivity?: number;
    density?: number;
    woolType?: string | null;
  };
};

const performLambdaMaterialBuilder = async (materialFitting: PerformLambdaMaterialBuilder): Promise<MaterialDto> => {
  const { data } = await axios.post(`/api/MaterialFit/PerformLambdaMaterialBuilder`, materialFitting);
  return data;
};

export const usePerformLambdaMaterialBuilder = () => {
  return useMutation(
    async (materialFitting: PerformLambdaMaterialBuilder) => await performLambdaMaterialBuilder(materialFitting),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data.message) toast.error(error.response?.data.message);
      },
    }
  );
};
