import { useQuery } from '@tanstack/react-query';

import { parseJsonWithSafeIntegers } from '@/utils/trebleFunctions';

import { SpaceDto } from '@/types';

import axios from '@/axios';

const getSpaceById = async (spaceId: string) => {
  const { data } = await axios.get(`/api/Space/GetSpaceById`, {
    params: { spaceId },
    transformResponse: [(data) => data],
  });

  // Handles large numbers in the response, such as 64-bit integers (e.g., SourceModelUploadId),
  // which exceed the JavaScript safe integer range.
  return parseJsonWithSafeIntegers(data);
};

export const useGetSpaceById = (spaceId: string) =>
  useQuery<SpaceDto, boolean>(['spaceById', spaceId], () => getSpaceById(spaceId), {
    enabled: !!spaceId,
    refetchOnWindowFocus: false,
  });
