import { TimeOfArrivalGroup } from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/constants';

export const getTimeOfArrivalColor = (
  timeOfArrival: number,
  timeOfArrivalGroups: TimeOfArrivalGroup[],
  isSelected = false
): string => {
  const group = timeOfArrivalGroups.find((group) => group.range[0] <= timeOfArrival && timeOfArrival < group.range[1]);
  if (!group) return '#000000';

  return isSelected ? group.selectedColor : group.color;
};
