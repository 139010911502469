import { useNavigate } from 'react-router-dom';

import { useSimulationContext } from '@/context/SimulationContext';

import { ActionType, useAuralizerContext } from '@/components/Auralizer/AuralizerContext';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useSetPresetRoute } from './useSetPresetRoute';

import { AuralizationPresetDto } from '../types';

export const useLoadPreset = () => {
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const navigate = useNavigate();

  const { dispatch: libDispatch } = useLibraryPanelContext();
  const { dispatch } = useAuralizerContext();
  const setPresetRoute = useSetPresetRoute();

  const loadPreset = (newPreset: AuralizationPresetDto) => {
    libDispatch({ type: LibActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: newPreset.id });

    const firstSimId = newPreset.simulations?.[0];
    if (selectedSimulation && selectedSimulation?.id === firstSimId) {
      // if the selected simulation is the same as the selected preset
      navigate(`/auralizer?presetId=${newPreset.id}&mid=${selectedSimulation.modelId}&sid=${selectedSimulation.id}`);
    } else if (firstSimId) {
      dispatch({
        type: ActionType.SET_FETCHING,
        isFetching: true,
        simId: firstSimId,
      });
      setPresetRoute(newPreset.id, firstSimId);
    }
  };

  return loadPreset;
};
