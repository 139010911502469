import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

interface UseMutationVariables {
  modelBaseId: string | undefined;
}

export const useDeleteModelBase = () =>
  useMutation(async ({ modelBaseId }: UseMutationVariables) => {
    const response = axios.delete(`/api/Model/DeleteModelBase?modelBaseId=${modelBaseId}`);
    return response;
  });
