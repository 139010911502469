import { useEffect, useRef, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { AssignButton, LibraryRow, UserOwnedIconIndicator } from '@/components/LibraryPanel';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { setElementFocus } from '@/components/LibraryPanel/utils';
import { MaterialDetailsPopup } from '@/components/MaterialDetailsPopup';
import { AbsorptionPlotPreview } from './AbsorptionPlotPreview';

import { useAssignNewMaterial } from '../hooks/useAssignNewMaterial';
import { useEditMaterialPopup } from '../hooks/useEditMaterialPopup';

import { Material } from '@/types';

import './style.scss';

export const MaterialRow = ({
  material,
  index,
  parentIndex,
}: {
  material: Material;
  index: number;
  parentIndex: number;
}) => {
  const listItemRef = useRef<HTMLLIElement>(null);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const { selectedMaterial, selectedLayer, multiSelectedItemIds, highlightedItemId, previouslyAssignedMaterial } =
    useLibraryPanelContext();
  const {
    state: { userInfo },
  } = useBaseContext();
  const { dispatch } = useEditorContext();
  const [EditMaterialPopup, openPopup, isPopupOpen] = useEditMaterialPopup();
  const [showAssignButton, setShowAssignButton] = useState(false);
  const [showMaterialPopup, setShowMaterialPopup] = useState<undefined | boolean>(undefined);

  const assignNewMaterial = useAssignNewMaterial();

  useEffect(() => {
    if (highlightedItemId === material.id) {
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else if (highlightedItemId === null || highlightedItemId === EMPTY_GUID) {
      // if the layer has unassigned material
      // check if there is a previously assigned material
      if (previouslyAssignedMaterial?.id === material.id) {
        setIsHighlighted(true);
        setElementFocus(listItemRef);
        // if not pick the topmost material
      } else if (!previouslyAssignedMaterial && index === 0 && parentIndex === 0) {
        setIsHighlighted(true);
        setElementFocus(listItemRef);
      } else {
        setIsHighlighted(false);
      }
    } else {
      setIsHighlighted(false);
    }
  }, [highlightedItemId, listItemRef, previouslyAssignedMaterial, selectedLayer]);

  useEffect(() => {
    if (selectedMaterial && selectedMaterial.id === material.id) {
      setIsAssigned(true);
      setElementFocus(listItemRef);
    } else {
      setIsAssigned(false);
    }
  }, [selectedMaterial, listItemRef, selectedLayer]);

  const assignMaterial = async () => {
    const selectedLayers = multiSelectedItemIds.length > 0 ? multiSelectedItemIds : selectedLayer && [selectedLayer.id];

    if (selectedLayers) {
      const updatedSimulation = await assignNewMaterial(selectedSimulation, material, selectedLayers, userInfo?.id);
      if (updatedSimulation) {
        openPopup(updatedSimulation, material, selectedLayers, userInfo?.id);
      }
    }
  };

  useEffect(() => {
    if (showMaterialPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showMaterialPopup,
      });
    }
  }, [showMaterialPopup]);

  return (
    <>
      <LibraryRow
        listItemRef={listItemRef}
        itemId={material.id}
        isHighlighted={isHighlighted}
        isAssigned={isAssigned}
        onMouseEnter={() => setShowAssignButton(true)}
        onMouseLeave={() => setShowAssignButton(false)}
        onAssign={assignMaterial}>
        <div className="material-row-content">
          <AbsorptionPlotPreview
            material={material}
            setShowMaterialPopup={setShowMaterialPopup}
            isHighlighted={isHighlighted}
          />
          <p className="library-item-name" title={material.name}>
            {material.name}
          </p>
        </div>
        <div className="library-item-last">
          <div style={{ marginRight: '12px' }}>
            <UserOwnedIconIndicator
              showUserIcon={material.isUserCreated && material.userId === userInfo?.id}
              showOrganization={material.isSharedWithOrganization}
              shouldOverlap={true}
            />
          </div>
          <div className="assign-button-container">
            <AssignButton
              label="Assign Material"
              isSelected={isAssigned}
              show={showAssignButton}
              isHighlighted={isHighlighted}
              onAssign={assignMaterial}
            />
          </div>
        </div>
      </LibraryRow>
      {isPopupOpen && <EditMaterialPopup />}
      {showMaterialPopup && material && (
        <MaterialDetailsPopup material={material} showPopup={showMaterialPopup} setShowPopup={setShowMaterialPopup} />
      )}
    </>
  );
};
