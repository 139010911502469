import { useAppContext } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';

import { getEstimatedTransitionFrequency } from '@/components/SolverSettings/utils';

import { useCreateSimulation } from '@/hooks';

import { NewSimulationTypes } from '../constants';

import { createFromScratch } from '../utils';
import { createFromSimulation } from '../utils';

import { Simulation } from '@/types';

export const useCreateNewSimulation = () => {
  const { mutate: createSimulation } = useCreateSimulation();
  const {
    appState: { simulationsInSpace },
  } = useAppContext();
  const { modelVolume, modelInformation } = useModelContext();

  const createNewSimulation = async (
    selectedRadio: NewSimulationTypes,
    name: string,
    description: string,
    simulationToCopyId: string
  ): Promise<Simulation | undefined> => {
    const newSimulationObject: Simulation =
      selectedRadio === NewSimulationTypes.Copy
        ? await createFromSimulation(simulationsInSpace, modelInformation!.id, simulationToCopyId)
        : createFromScratch(modelInformation?.nonWatertight);
    if (newSimulationObject) {
      if (selectedRadio === NewSimulationTypes.New) {
        const transitionFrequency = getEstimatedTransitionFrequency(modelVolume || 0);
        newSimulationObject.solverSettings.gaSettings.crossoverFrequency = transitionFrequency;
        newSimulationObject.solverSettings.dgSettings.crossoverFrequency = transitionFrequency;
      }

      newSimulationObject.modelId = modelInformation?.id || '';
      newSimulationObject.name = name;
      newSimulationObject.description = description || '';

      return new Promise((resolve) => {
        createSimulation(newSimulationObject, {
          onSuccess: (data) => {
            const savedSim: Simulation = data;
            if (savedSim === null) {
              throw new Error();
            }
            // used for storing temporary data like meshTaskId and run status
            savedSim.extra = {};

            resolve(savedSim);
          },
        });
      });
    }
  };
  return createNewSimulation;
};
