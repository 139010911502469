import { useQuery } from '@tanstack/react-query';

import { SourceDefinition } from '@/types';

import axios from '@/axios';

export const getSourceDefinitionById = async (sourceDefinitionId: string | null): Promise<SourceDefinition> => {
  if (sourceDefinitionId) {
    const { data } = await axios.get<SourceDefinition>(`/api/SourceDefinition/GetSourceDefinitionById`, {
      params: {
        sourceDefinitionId: sourceDefinitionId,
      },
    });

    return data;
  } else {
    throw new Error('No Source definition id');
  }
};

export const useGetSourceDefinitionById = (sourceDefinitionId: string | null) => {
  return useQuery<SourceDefinition>(
    ['sourceDefinition', sourceDefinitionId],
    () => getSourceDefinitionById(sourceDefinitionId),
    {
      enabled: !!sourceDefinitionId,
      refetchOnWindowFocus: false,
    }
  );
};
