import { useSimulationContext } from '@/context/SimulationContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

import { useRevertSimulation } from '../EditSimulation/hooks/useRevertSimulation';

type RevertSimulation = {
  showRevertConfirmation: boolean;
  setShowRevertConfirmation: (show: boolean) => void;
};

export const RevertSimulationPopup = ({ showRevertConfirmation, setShowRevertConfirmation }: RevertSimulation) => {
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const revertSimulation = useRevertSimulation();

  return (
    <>
      {showRevertConfirmation && (
        <ConfirmationDialog
          title="Revert simulation"
          message={() => (
            <>
              <span>
                Are you sure you want to exit edit mode and revert <br></br>
                <b>{selectedSimulation?.name}</b> to it's last run?
              </span>
              <br></br> <br></br>
              <span>
                This will allow you to open the Results and the Auralizer, but you will loose any changes you have made.
              </span>
            </>
          )}
          onConfirm={() => {
            revertSimulation(selectedSimulation);
            setShowRevertConfirmation(false);
          }}
          onCancel={() => setShowRevertConfirmation(false)}
        />
      )}
    </>
  );
};
