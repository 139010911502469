import { Mesh, Vector3 } from 'three';

import { useGetGridPointValidity } from '@/components/SourceRecieverSettings/hooks/useGetGridPointValidity';

import { GridReceiverPoint, GridReceiverUserInput } from '@/types';

export const useCreateGridPoints = () => {
  const getGridPointValidity = useGetGridPointValidity();

  const createGridPoints = async (plane: Mesh, grUserInput: GridReceiverUserInput) => {
    // @ts-expect-error Property 'array' does not exist on type 'BufferAttribute | InterleavedBufferAttribute | GLBufferAttribute'.
    const verts = plane.geometry.attributes.position.array;

    const coarsenessW = grUserInput.width / grUserInput.coarseness;
    const pointsForObject: GridReceiverPoint[] = [];
    const invalidPointsForObject: GridReceiverPoint[] = [];
    let i = 0;

    // loop through the vertices of the plane
    for (let k = 0; k < verts.length; k += 3) {
      // get average of quad next to point to put it in the middle of the queue
      const k0 = 0;
      const k1 = 1;
      const k2 = coarsenessW + 1;
      const k3 = coarsenessW + 2;
      const pointX =
        (verts[3 * k0 + 0 + k] + verts[3 * k1 + 0 + k] + verts[3 * k2 + 0 + k] + verts[3 * k3 + 0 + k]) / 4;
      const pointY =
        (verts[3 * k0 + 1 + k] + verts[3 * k1 + 1 + k] + verts[3 * k2 + 1 + k] + verts[3 * k3 + 1 + k]) / 4;
      const pointZ =
        (verts[3 * k0 + 2 + k] + verts[3 * k1 + 2 + k] + verts[3 * k2 + 2 + k] + verts[3 * k3 + 2 + k]) / 4;

      // if average of the quad includes NaN values, then the point is at an edge of the plane and should'nt be included
      if (!isNaN(pointX) && !isNaN(pointY) && !isNaN(pointZ)) {
        // only include points that are not at the edge of the plane
        if ((k / 3) % (coarsenessW + 1) !== coarsenessW) {
          const point = new Vector3(pointX, pointY, pointZ);

          // give the points the (x, y, z) in relation where the Plane is located in the Viewport
          plane.localToWorld(point);

          let isValid = false;
          // check if point is in a valid position
          const validity = await getGridPointValidity(point.x, point.y, point.z);
          isValid = validity === null ? true : false;

          if (isValid) {
            // round the (x, y z) to 3 decimals
            point.x = Math.round(point.x * 1000) / 1000;
            point.y = Math.round(point.y * 1000) / 1000;
            point.z = Math.round(point.z * 1000) / 1000;

            pointsForObject.push({ x: point.x, y: point.y, z: point.z, gridIndex: i });
          } else {
            invalidPointsForObject.push({ x: point.x, y: point.y, z: point.z, gridIndex: i });
          }

          i++;
        }
      }
    }

    return [pointsForObject, invalidPointsForObject];
  };
  return createGridPoints;
};
