import { createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';

import { useResultsContext } from '../ResultsContext';
import { initialState, reflectogramResultsReducer } from './reducer';

import { useFilteredReflections, useReceiverDirection } from './hooks';

import { calculateRelativeAngles } from './utils';

import {
  ActionType,
  ReceiverDirections,
  ReceiverDirectionType,
  ReflectogramResultsContextAction,
  State,
} from './types';

interface ReflectogramResultsProviderProps {
  children: ReactNode;
}

const ReflectogramResultsContext = createContext<
  | {
      state: State;
      dispatch: Dispatch<ReflectogramResultsContextAction>;
    }
  | undefined
>(undefined);

const ReflectogramResultsProvider = ({ children }: ReflectogramResultsProviderProps) => {
  const [state, dispatch] = useReducer(reflectogramResultsReducer, initialState);
  const { selectedComparisonIndex, availableComparisons } = useResultsContext();

  const selectedReceiverObject = useMemo(
    () => availableComparisons[selectedComparisonIndex]?.formState?.simulationData?.selectedReceiverObjects?.[0],
    [availableComparisons, selectedComparisonIndex]
  );

  const selectedReceiverDirection = useReceiverDirection(
    state.receiverDirections,
    availableComparisons,
    selectedComparisonIndex,
    selectedReceiverObject
  );

  // Calculate relative angles based on the receiver direction settings
  const relativeData = useMemo(() => {
    if (!state.reflectionsData || state.reflectionsData.length === 0 || !selectedReceiverDirection) return [];
    return calculateRelativeAngles(state.reflectionsData, selectedReceiverDirection);
  }, [state.reflectionsData, selectedReceiverDirection]);

  // Filter reflections based on the selected scale and time of arrival group indexes
  const relativeAndFilteredData = useFilteredReflections(
    relativeData,
    state.selectedScale,
    state.selectedTimeOfArrivalGroupIndexes
  );

  useEffect(() => {
    const newDirectionsState: ReceiverDirections = {};
    availableComparisons.forEach((comparison) => {
      if (comparison.formState) {
        const simulationId = comparison.formState.simulationId;

        // Check if we have already stored the receiver directions for this simulation.
        // If so we keep that state. Otherwise we default to "Along X axis" direction
        const existingDirectionState = state.receiverDirections[simulationId] ?? null;
        if (!existingDirectionState) {
          comparison.formState.availableReceivers?.forEach((receiver) => {
            newDirectionsState[simulationId] = {
              ...newDirectionsState[simulationId],
              [receiver.id]: { type: ReceiverDirectionType.AlongX },
            };
          });
        } else {
          newDirectionsState[simulationId] = existingDirectionState;
        }
      }
    });

    dispatch({ type: ActionType.SET_ALL_RECEIVER_DIRECTIONS, receiverDirections: newDirectionsState });
  }, [availableComparisons]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_SELECTED_REFLECTION_INDEX, reflectionIndex: null });

    if (selectedReceiverObject) {
      const reflectionResults = selectedReceiverObject.receiverResults?.filter(
        (receiver) => receiver.resultType === 'reflection'
      );

      if (reflectionResults && reflectionResults.length > 0) {
        dispatch({
          type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO,
          downloadInfo: {
            id: reflectionResults[0].uploadId,
            downloadUrl: reflectionResults[0].uploadUrl,
          },
        });
      } else {
        dispatch({ type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO, downloadInfo: null });
        dispatch({ type: ActionType.SET_REFLECTIONS_DATA, reflectionsData: [] });
      }
    } else {
      dispatch({
        type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO,
        downloadInfo: null,
      });
      dispatch({
        type: ActionType.SET_REFLECTIONS_DATA,
        reflectionsData: [],
      });
    }
  }, [selectedReceiverObject]);

  const contextValue = useMemo(
    () => ({
      state: {
        ...state,
        relativeAndFilteredData,
        selectedReceiverDirection,
        // Make sure the filtered data contains the selected reflection index (there might be a temporary mismatch when data is being filtered)
        selectedReflectionIndex:
          state.selectedReflectionIndex !== null && relativeAndFilteredData.length > state.selectedReflectionIndex
            ? state.selectedReflectionIndex
            : null,
      },
      dispatch,
    }),
    [state, relativeAndFilteredData, selectedReceiverDirection]
  );

  return <ReflectogramResultsContext.Provider value={contextValue}>{children}</ReflectogramResultsContext.Provider>;
};

const useReflectogramResultsContext = () => {
  const context = useContext(ReflectogramResultsContext);
  if (!context) {
    throw new Error('useReflectogramResultsContext must be used within a ReflectogramResultsProvider');
  }
  return context;
};

export { ReflectogramResultsProvider, useReflectogramResultsContext };
