import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { ActionType, useAuralizerContext } from '../AuralizerContext';

export const useSelectSimulationAsAurSim = () => {
  const { dispatch: modelDispatch } = useModelContext();
  const { dispatch: simDispatch } = useSimulationContext();

  const { simsToCompare, selectedAurSim, dispatch } = useAuralizerContext();

  const selectSimulationAsAurSim = (selectedSimId: string) => {
    const newAurSimulation = simsToCompare.find((sim) => sim.id === selectedSimId);
    if (newAurSimulation) {
      dispatch({
        type: ActionType.SET_SELECTED_AUR_SIM,
        simulation: newAurSimulation,
      });

      // if the selected simulation has a different modelId
      // we need to change the model in the viewport
      if (newAurSimulation?.modelId !== selectedAurSim?.modelId) {
        modelDispatch({
          type: ModelActionType.SET_CURRENT_MODEL_ID,
          modelId: newAurSimulation.modelId,
        });
      }

      // select simulation in the Sim context as well
      simDispatch({
        type: SimActionType.SET_SELECTED_SIMULATION,
        simulation: newAurSimulation,
      });
    }
  };

  return selectSimulationAsAurSim;
};
