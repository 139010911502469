import { useQuery } from '@tanstack/react-query';

import { UserDto } from '@/components/ManagementPortal/hooks';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

export const getUserMine = async (): Promise<UserDto> => {
  const { data } = await axios.get(`/api/User/mine`, { baseURL: adminApiUrl });

  return data;
};

export const useGetUserMine = (enabled = true) => {
  return useQuery<UserDto>(['user_mine'], getUserMine, {
    refetchOnWindowFocus: false,
    enabled,
  });
};
