import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblRevertSimIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.0422 12.8613H6.85316C6.38924 12.8613 6.01316 12.4696 6.01316 11.9863C6.01316 11.5031 6.38924 11.1113 6.85316 11.1113H9.0422C10.0311 11.1409 10.9573 10.6082 11.4602 9.72072C11.963 8.8332 11.963 7.73112 11.4602 6.8436C10.9573 5.95608 10.0311 5.42341 9.0422 5.453H3.9966C3.91928 5.453 3.8566 5.51829 3.8566 5.59883V7.42583C3.85655 7.66173 3.72011 7.87437 3.51089 7.96464C3.30166 8.05491 3.06084 8.00502 2.90068 7.83824L0.16396 4.98808C-0.0546535 4.76029 -0.0546535 4.39104 0.16396 4.16325L2.90068 1.3125C3.06084 1.14572 3.30166 1.09583 3.51089 1.1861C3.72011 1.27637 3.85655 1.48901 3.8566 1.72491V3.55483C3.8566 3.63537 3.91928 3.70066 3.9966 3.70066H9.0422C10.6364 3.66317 12.1251 4.52767 12.9328 5.95988C13.7405 7.3921 13.7405 9.16756 12.9328 10.5998C12.1251 12.032 10.6364 12.8965 9.0422 12.859V12.8613Z"
      fill={fill}
    />
  </svg>
);
