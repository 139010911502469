import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';

import { Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';

import { TrblCrankWheelIcon, TrblLockIcon, TrblUserIcon } from '@/components/Icons';

import { menuPaperStyleOverrides } from './utils';

import { config } from '@/__config__/config';

import styles from './styles.module.scss';

const { viteAuth0LogoutUri, appVersion } = config;

export const AccountProfile: FC = () => {
  const { user, logout } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleUserProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOutClick = () => {
    logout({ returnTo: viteAuth0LogoutUri });
  };

  const handleManagementPortalClick = () => {
    navigate('/organizations');
  };

  const handleAdministrationClick = () => {
    navigate('/administration');
  };

  const gotoUrl = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <IconButton className={styles['user-profile-icon']} aria-label="Account profile" onClick={handleUserProfileClick}>
        <TrblUserIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuPaperStyleOverrides}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem className={`${styles['menu-item']} ${styles['info']}`}>
          <ListItemIcon className={styles['menu-icon']}>
            <TrblUserIcon />
          </ListItemIcon>
          {user?.email}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => gotoUrl('https://docs.treble.tech/user-guide')} className={styles['menu-item']}>
          <ListItemIcon className={styles['menu-icon']}></ListItemIcon>
          Documentation
        </MenuItem>
        <MenuItem onClick={() => gotoUrl('https://docs.treble.tech/release-notes')} className={styles['menu-item']}>
          <ListItemIcon className={styles['menu-icon']}></ListItemIcon>
          Release notes
        </MenuItem>
        <Divider />
        {userRoles.includes('Superuser') && (
          <MenuItem onClick={handleManagementPortalClick} className={styles['menu-item']}>
            <ListItemIcon className={styles['menu-icon']}>
              <TrblLockIcon />
            </ListItemIcon>
            Management portal
          </MenuItem>
        )}
        {userRoles.includes('Admin') && (
          <MenuItem onClick={handleAdministrationClick} className={styles['menu-item']}>
            <ListItemIcon className={styles['menu-icon']}>
              <TrblCrankWheelIcon />
            </ListItemIcon>
            Administration
          </MenuItem>
        )}
        <MenuItem onClick={handleLogOutClick} className={styles['menu-item']}>
          <ListItemIcon className={styles['menu-icon']}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        {userRoles.includes('Superuser') && (
          <span
            onClick={(e) => e.stopPropagation()}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '12px',
              fontSize: '8px',
            }}>
            v. {appVersion || '0.0.0'}
          </span>
        )}
      </Menu>
    </>
  );
};
