import { useEffect, useRef, useState } from 'react';

import { TrblTreeBranchName } from './TrblTreeBranchName';

import { TreeBranch } from './types';

export const TrblTreeBranch = ({
  structure,
  level = 0,
  onSelectItem,
  filter,
}: {
  structure: TreeBranch;
  level?: number;
  onSelectItem: (structure: TreeBranch) => void;
  filter: string;
}) => {
  const newLevel = level + 1;
  const [isOpen, setIsOpen] = useState(structure.isOpen);
  const listItem = useRef<null | HTMLLIElement>(null);

  useEffect(() => {
    if (structure.meta?.scroll && listItem.current) {
      listItem.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [structure]);

  const clickListItem = (event: React.MouseEvent<HTMLElement>, structure: TreeBranch) => {
    event.stopPropagation();
    setIsOpen((isOpen) => !isOpen);
    // only fire the select event if the item
    // that is clicked has isSelectable prop
    if (structure.isSelectable && isOpen === null) {
      onSelectItem(structure);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, structure: TreeBranch) => {
    if (event.key === 'Enter') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clickListItem(event as any, structure);
    }
  };

  return (
    <>
      {structure.isHidden ? null : (
        <ul key={`${structure.id}-${structure.isOpen}`} role="tree">
          <li
            ref={listItem}
            onClick={(event) => clickListItem(event, structure)}
            onKeyDown={(event) => handleKeyDown(event, structure)}
            role="treeitem"
            tabIndex={0}>
            <TrblTreeBranchName structure={structure} isOpen={isOpen} level={newLevel} filter={filter} />
            {isOpen &&
              structure.children &&
              Object.keys(structure.children).map(
                (child) =>
                  structure.children &&
                  structure.children[child] && (
                    <TrblTreeBranch
                      key={`${structure.children[child].id}-${structure.children[child].isOpen}`}
                      filter={filter}
                      structure={structure.children[child]}
                      level={newLevel}
                      onSelectItem={onSelectItem}
                    />
                  )
              )}
          </li>
        </ul>
      )}
    </>
  );
};
