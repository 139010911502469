import { cloneDeep } from 'lodash';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';

import { Simulation } from '@/types';

export const useUpdateSolverSettings = () => {
  const saveSimulation = useSaveUpdatedSimulation();
  const confirmEdit = useConfirmEdit();
  const { dispatch } = useEditorContext();

  const updateSolverSettings = async (simulation: Simulation | null) => {
    const canContinue = await confirmEdit();
    if (simulation) {
      const updatedSimulation: Simulation = cloneDeep(simulation);

      const saveText = 'Solver settings saved ✓';
      if (canContinue) {
        saveSimulation(updatedSimulation, saveText);
      } else {
        dispatch({
          type: ActionType.SHOW_EDIT_MODAL,
          editSimulation: { showModal: true, updatedSimulation, saveText },
        });
      }
    }
  };

  return updateSolverSettings;
};
