import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblOctaveBandIcon: FC<IconProps> = ({ fill }) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1974 2.2002C11.1974 2.89055 11.757 3.4502 12.4474 3.4502C13.1377 3.4502 13.6974 2.89055 13.6974 2.2002C13.6974 1.50984 13.1377 0.950195 12.4474 0.950195C11.757 0.950195 11.1974 1.50984 11.1974 2.2002Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M11.1974 5.79966C11.1974 6.49002 11.757 7.04966 12.4474 7.04966C13.1377 7.04966 13.6974 6.49002 13.6974 5.79966C13.6974 5.1093 13.1377 4.54966 12.4474 4.54966C11.757 4.54966 11.1974 5.1093 11.1974 5.79966Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M3.93564 2.2002C3.93564 2.89055 4.49528 3.4502 5.18564 3.4502C5.87599 3.4502 6.43564 2.89055 6.43564 2.2002C6.43564 1.50984 5.87599 0.950195 5.18564 0.950195C4.49528 0.950195 3.93564 1.50984 3.93564 2.2002Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M3.93564 5.79956C3.93564 6.48992 4.49528 7.04956 5.18564 7.04956C5.87599 7.04956 6.43564 6.48992 6.43564 5.79956C6.43564 5.1092 5.87599 4.54956 5.18564 4.54956C4.49528 4.54956 3.93564 5.1092 3.93564 5.79956Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M7.56454 2.2002C7.56454 2.89055 8.12419 3.4502 8.81454 3.4502C9.5049 3.4502 10.0645 2.89055 10.0645 2.2002C10.0645 1.50984 9.5049 0.950195 8.81454 0.950195C8.12419 0.950195 7.56454 1.50984 7.56454 2.2002Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M7.56454 5.79956C7.56454 6.48992 8.12419 7.04956 8.81454 7.04956C9.5049 7.04956 10.0645 6.48992 10.0645 5.79956C10.0645 5.1092 9.5049 4.54956 8.81454 4.54956C8.12419 4.54956 7.56454 5.1092 7.56454 5.79956Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M0.302734 2.2002C0.302734 2.89055 0.862378 3.4502 1.55273 3.4502C2.24309 3.4502 2.80273 2.89055 2.80273 2.2002C2.80273 1.50984 2.24309 0.950195 1.55273 0.950195C0.862378 0.950195 0.302734 1.50984 0.302734 2.2002Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M0.302734 5.79966C0.302734 6.49002 0.862378 7.04966 1.55273 7.04966C2.24309 7.04966 2.80273 6.49002 2.80273 5.79966C2.80273 5.1093 2.24309 4.54966 1.55273 4.54966C0.862378 4.54966 0.302734 5.1093 0.302734 5.79966Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
