import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSoundSourceIcon: FC<IconProps> = ({ fill }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33366 0.25C7.96547 0.25 7.66699 0.548477 7.66699 0.916667V15.5833C7.66699 15.9515 7.96547 16.25 8.33366 16.25C8.70185 16.25 9.00033 15.9515 9.00033 15.5833V0.916667C9.00033 0.548477 8.70185 0.25 8.33366 0.25Z"
      fill={fill || '#F5F5F5'}
    />
    <path
      d="M5.66569 1.58337C5.2975 1.58337 4.99902 1.88185 4.99902 2.25004V14.25C4.99902 14.6182 5.2975 14.9167 5.66569 14.9167C6.03388 14.9167 6.33236 14.6182 6.33236 14.25V2.25004C6.33236 1.88185 6.03388 1.58337 5.66569 1.58337Z"
      fill="#F5F5F5"
    />
    <path
      d="M2.99967 4.25C2.63148 4.25 2.33301 4.54848 2.33301 4.91667V11.5833C2.33301 11.9515 2.63148 12.25 2.99967 12.25C3.36786 12.25 3.66634 11.9515 3.66634 11.5833V4.91667C3.66634 4.54848 3.36786 4.25 2.99967 4.25Z"
      fill="#F5F5F5"
    />
    <path
      d="M0.999674 6.91663C0.631485 6.91663 0.333008 7.2151 0.333008 7.58329V8.91663C0.333008 9.28482 0.631485 9.58329 0.999674 9.58329C1.36786 9.58329 1.66634 9.28482 1.66634 8.91663V7.58329C1.66634 7.2151 1.36786 6.91663 0.999674 6.91663Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.9997 1.58337C10.6315 1.58337 10.333 1.88185 10.333 2.25004V14.25C10.333 14.6182 10.6315 14.9167 10.9997 14.9167C11.3679 14.9167 11.6663 14.6182 11.6663 14.25V2.25004C11.6663 1.88185 11.3679 1.58337 10.9997 1.58337Z"
      fill="#F5F5F5"
    />
    <path
      d="M13.6657 4.25C13.2975 4.25 12.999 4.54848 12.999 4.91667V11.5833C12.999 11.9515 13.2975 12.25 13.6657 12.25C14.0339 12.25 14.3324 11.9515 14.3324 11.5833V4.91667C14.3324 4.54848 14.0339 4.25 13.6657 4.25Z"
      fill="#F5F5F5"
    />
    <path
      d="M15.6657 6.91663C15.2975 6.91663 14.999 7.2151 14.999 7.58329V8.91663C14.999 9.28482 15.2975 9.58329 15.6657 9.58329C16.0339 9.58329 16.3324 9.28482 16.3324 8.91663V7.58329C16.3324 7.2151 16.0339 6.91663 15.6657 6.91663Z"
      fill="#F5F5F5"
    />
  </svg>
);
