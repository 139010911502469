import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

const cancelSimulationRun = async (simulationRunId: string) => {
  const { data } = await axios.patch(`/api/SolveTask/CancelSimulationRun`, null, {
    params: {
      simulationRunId,
    },
  });
  return data;
};

export const useCancelSimulationRun = () => {
  return useMutation(async (simulationRunId: string) => await cancelSimulationRun(simulationRunId));
};
