import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  GridReceiver,
  ModelSettings,
  Receiver,
  SimulationRunDto,
  SolverSettings,
  Source,
  SourceParameter,
  TaskType,
} from '@/types';

import axios from '@/axios';

export type StartSolveTask = {
  simulationId: string;
  taskType: TaskType;
  sources: Array<Source>;
  sourceParameters?: Array<SourceParameter>;
  receivers: Array<Receiver>;
  gridReceivers: Array<GridReceiver>;
  modelSettings: ModelSettings;
  solverSettings: SolverSettings;
  settingsPreset: number | null;

  meshTaskId?: string;
};

const startSolveTask = async (request: StartSolveTask) => {
  let endpoint = '/api/SolveTask/StartSolveTaskHybrid';

  if (request.taskType === 'DG') {
    endpoint = '/api/SolveTask/StartSolveTaskDg';
  } else if (request.taskType === 'GA') {
    endpoint = '/api/SolveTask/StartSolveTaskGa';
  }

  const { data } = await axios.post<StartSolveTask, AxiosResponse<SimulationRunDto>>(endpoint, request, {
    params:
      (request.taskType === 'DG' || request.taskType === 'Hybrid') && request.meshTaskId
        ? {
            meshTaskId: request.meshTaskId,
          }
        : undefined,
  });
  return data;
};

export const useStartSolveTask = () => {
  return useMutation(async (request: StartSolveTask) => await startSolveTask(request));
};
