import { useMemo } from 'react';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { getWeightedRelativeSplPerBand, getWeightedSplPerBand } from '../utils';

import { SPLType } from '../types';

export const useGetYAxisRange = (
  data: ReflectionDetails | null,
  selectedSplType: SPLType,
  selectedFrequencyWeighting: FrequencyWeightingType
) => {
  return useMemo(() => {
    let splPerBand: number[] = [];

    if (data) {
      if (selectedSplType === 'actual') {
        splPerBand = getWeightedSplPerBand(data, selectedFrequencyWeighting);
      } else {
        splPerBand = getWeightedRelativeSplPerBand(data, selectedFrequencyWeighting);
      }
    }

    const splMin = Math.min(...splPerBand);
    const splMax = Math.max(...splPerBand);
    const yaxisRange: [number, number] = [Math.max(splMin - 3, -150), Math.max(splMax + 3, 0)];

    return yaxisRange;
  }, [data, selectedSplType, selectedFrequencyWeighting]);
};
