import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Drawer, Stack, TextField } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';

import { Organization } from '../../hooks';

import classes from './styles.module.scss';

type OrganizaitonSidepanel = {
  organization?: Organization;
  onCancel: () => void;
  onSubmit: (values: OrganizationFormState) => void;
};

export type OrganizationFormState = {
  id?: string;
  name: string;
  description: string;
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

export const OrganizationSidepanel: FC<OrganizaitonSidepanel> = ({ organization, onCancel, onSubmit }) => {
  const { handleSubmit, control, register } = useForm<OrganizationFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: organization?.id,
      name: organization?.name || '',
      description: organization?.description || '',
    },
  });

  useEffect(() => {
    if (organization?.id) {
      register('id', { value: organization.id });
    }
  }, [organization]);

  return (
    <Drawer open={true} anchor={'right'}>
      <div className={classes.sidepanel_layout}>
        <Stack flex={'auto 1 1'} spacing="20px">
          <p style={{ fontWeight: '600', margin: '8px 0 12px' }}>
            {organization ? 'Update organization' : 'New organization'}
          </p>

          <Controller
            name={'name'}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <TextField
                required
                onChange={onChange}
                label="Name"
                defaultValue={organization?.name}
                error={error !== undefined}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name={'description'}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <TextField
                required
                multiline
                minRows={4}
                onChange={onChange}
                label="Description"
                defaultValue={organization?.description}
                error={error !== undefined}
                helperText={error?.message}
                sx={{ textarea: { boxShadow: 'none' } }}
              />
            )}
          />
        </Stack>
        <div className={classes.sidepanel_actions}>
          <Box component="div" width="150px">
            <SecondaryButton label="Cancel" onClick={onCancel} />
          </Box>
          <Box component="div" width="150px">
            <PrimaryButton label={organization ? 'Update' : 'Create'} onClick={handleSubmit(onSubmit)} />
          </Box>
        </div>
      </div>
    </Drawer>
  );
};
