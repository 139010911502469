export const getIconSize = (menuSize: 'small' | 'medium' | 'large' | 'x-large') => {
  if (menuSize === 'medium') {
    return '14';
  } else if (menuSize === 'large') {
    return '16';
  } else if (menuSize === 'x-large') {
    return '18';
  } else {
    return '12';
  }
};
