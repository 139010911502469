import { FC } from 'react';

export const ToastWarningIcon: FC = () => (
  <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4862 11.6666L8.0338 1.25405C7.8324 0.869715 7.43434 0.628906 7.00043 0.628906C6.56652 0.628906 6.16846 0.869715 5.96705 1.25405L0.514052 11.6666C0.324605 12.0282 0.338015 12.4626 0.549411 12.8119C0.760808 13.1612 1.13943 13.3746 1.54772 13.3746H12.4526C12.8608 13.3746 13.2395 13.1612 13.4509 12.8119C13.6623 12.4626 13.6757 12.0282 13.4862 11.6666ZM6.2505 10.765C6.24322 10.567 6.31587 10.3743 6.4521 10.2304C6.58832 10.0865 6.77667 10.0035 6.97475 10H6.98824C7.39811 10.0008 7.73418 10.3253 7.74948 10.735C7.7569 10.9331 7.6843 11.1258 7.54804 11.2697C7.41179 11.4137 7.22336 11.4966 7.02523 11.5H7.01174C6.60208 11.4987 6.2663 11.1745 6.2505 10.765ZM6.41797 4.79193V8.29181C6.41797 8.61397 6.67913 8.87513 7.00128 8.87513C7.32344 8.87513 7.5846 8.61397 7.5846 8.29181V4.79193C7.5846 4.46978 7.32344 4.20862 7.00128 4.20862C6.67913 4.20862 6.41797 4.46978 6.41797 4.79193Z"
      fill="#272727"
    />
  </svg>
);
