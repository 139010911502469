import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useResultComparisonContext } from '../context/ResultComparisonContext';

import { SourceSummingTask } from '@/components/Results/types';
import {
  TaskGroupSourceSummingResultsDto,
  useCancelSourceSummingTask,
  useGetLastGridReceiverSourceSummingResultsBySimulationId,
  useGetLastSourceSummingResultsBySimulationId,
  useStartSourceSummingTask,
  useUpdateSourceSummingTask,
} from './';

import { ActionType, SOURCE_SUMMING_REFETCH_INTERVAL } from '../constants';

import { StatusType } from '@/types';

// Helper function to get completed source summing tasks
const getCompletedOrFailedSourceSummingTasks = (
  lastSolveResults: TaskGroupSourceSummingResultsDto | undefined,
  sourceSummingInProgress: SourceSummingTask[]
) => {
  const completedSourceSummingTasks = lastSolveResults?.sourceResults?.filter((result) =>
    sourceSummingInProgress.some((task) => task.taskId === result.taskId)
  );

  const failedSourceSummingTasks = lastSolveResults?.incompleteTasks?.filter(
    (task) => task.status === StatusType.Error && sourceSummingInProgress.some((x) => x.taskId === task.id)
  );

  return {
    completedSourceSummingTasks,
    failedSourceSummingTasks,
  };
};

export const useSourceSumming = () => {
  const { dispatch, state } = useResultComparisonContext();
  const queryClient = useQueryClient();

  const { data: lastSourceSummingResults, isFetched: hasFetchedSourceSummingResults } =
    useGetLastSourceSummingResultsBySimulationId(
      state.simulationId,
      state.availableSummedSources?.some((s) => s.inProgressTasks.length) ? SOURCE_SUMMING_REFETCH_INTERVAL : undefined
    );
  const { data: lastGridReceiverSourceSummingResults } = useGetLastGridReceiverSourceSummingResultsBySimulationId(
    state.simulationId,
    state.availableSummedSources?.some((s) => s.inProgressTasks.length) ? SOURCE_SUMMING_REFETCH_INTERVAL : undefined
  );

  useEffect(() => {
    if (lastSourceSummingResults?.sourceResults.length) {
      dispatch({
        type: ActionType.UPDATE_SOURCE_SUMMING_RESULTS,
        payload: lastSourceSummingResults,
      });
    }
  }, [lastSourceSummingResults]);

  useEffect(() => {
    if (lastGridReceiverSourceSummingResults?.length) {
      dispatch({
        type: ActionType.UPDATE_GRID_RECEIVER_SOURCE_SUMMING_RESULTS,
        payload: lastGridReceiverSourceSummingResults,
      });
    }
  }, [lastGridReceiverSourceSummingResults]);

  const { mutate: startSourceSummingTask } = useStartSourceSummingTask();
  const { mutate: cancelSourceSummingTask } = useCancelSourceSummingTask();
  const { mutate: updateSourceSummingTask } = useUpdateSourceSummingTask();

  // Handle starting the source summing process
  const handleStartSummingProcess = (name: string, sources: string[]) => {
    if (sources.length && name) {
      startSourceSummingTask(
        {
          simulationId: state.simulationId,
          name,
          resultType: state.resultType,
          sources,
        },
        {
          onSuccess: (taskId, variables) => {
            dispatch({ type: ActionType.SET_SOURCE_SUMMING_SELECTION_ENABLED, payload: false });
            toast.info('Summing process started. Please wait for the results to be available.');
            queryClient.invalidateQueries(['lastSourceSummingResultsBySimulationId', state.simulationId]);

            // Create a unique ID by joining sorted sourceIds
            const sortedSourceIds = [...variables.sources].sort((a, b) => a.localeCompare(b));
            const summedSourceId = sortedSourceIds.join(';');

            dispatch({
              type: ActionType.ADD_IN_PROGRESS_SOURCE_SUMMING,
              payload: {
                summedSourceId,
                taskId,
                label: variables.name,
                resultType: variables.resultType,
                sourceIds: variables.sources,
              },
            });
          },
        }
      );
    }
  };

  const handleCancelSummingProcess = (summedSourceId: string, taskId: string) => {
    cancelSourceSummingTask(
      {
        summedSourceId,
        taskId,
      },
      {
        onSuccess: (_, variables) => {
          queryClient.invalidateQueries(['lastSourceSummingResultsBySimulationId', state.simulationId]);
          toast.info('Summing process cancelled');

          dispatch({
            type: ActionType.REMOVE_IN_PROGRESS_SOURCE_SUMMING,
            payload: {
              summedSourceId: variables.summedSourceId,
              taskId: variables.taskId,
            },
          });
        },
      }
    );
  };

  const handleUpdateSourceSummingLabel = (summedSourceId: string, label: string) => {
    // Update the label in state before we update the tasks
    dispatch({
      type: ActionType.UPDATE_SUMMED_SOURCE_LABEL,
      payload: {
        summedSourceId,
        label,
      },
    });

    const completedTasks = state.availableSummedSources?.find((x) => x.id === summedSourceId)?.completedTasks ?? [];
    // Find all completed tasks for the summed source and update the label for each. Display a single toaster once all tasks are updated
    state.availableSummedSources
      ?.find((x) => x.id === summedSourceId)
      ?.completedTasks.forEach((task, i) => {
        updateSourceSummingTask(
          {
            id: task.taskId,
            name: label,
            description: '',
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['lastSourceSummingResultsBySimulationId', state.simulationId]);
              if (i === completedTasks.length - 1) {
                toast.info('Label updated');
              }
            },
          }
        );
      });
  };

  // When we get new solve results (possibly from a manual cache refresh) we want to check if we have any new incomplete tasks (source summing tasks)
  // or if any of the existing tasks have completed
  useEffect(() => {
    const { completedSourceSummingTasks, failedSourceSummingTasks } = getCompletedOrFailedSourceSummingTasks(
      lastSourceSummingResults,
      state.availableSummedSources?.flatMap((x) => x.inProgressTasks) ?? []
    );

    if (completedSourceSummingTasks?.length) {
      toast.success('Source summing completed');
    }

    if (failedSourceSummingTasks?.length) {
      toast.error('Source summing failed. Please try again or contact support');
    }
  }, [lastSourceSummingResults]);

  return {
    handleStartSummingProcess,
    handleCancelSummingProcess,
    handleUpdateSourceSummingLabel,
    hasFetchedSourceSummingResults,
  };
};
