import { useEffect, useRef, useState } from 'react';

import { useAuralizerContext } from '@/components/Auralizer/AuralizerContext';
import { LibraryHeader, LibraryList } from '@/components/LibraryPanel';
import { LibrarySearch } from '@/components/LibraryPanel/components/LibrarySearch';
import { useHandleKeyDown } from '@/components/LibraryPanel/hooks/useHandleKeyDown';
import { usePresetContext } from '../Auralizer/PresetContext';
import { SoundLibraryFilters } from './SoundLibraryFilters';
import { SoundRow } from './SoundRow';
import { UploadSound } from './UploadSound';

import { LibrarySound } from '../Auralizer/constants/audioSounds';

import styles from './styles.module.scss';

export const LoadSoundLibrary = ({
  setShowPopup,
  sourceIndex,
}: {
  setShowPopup: (show: boolean) => void;
  sourceIndex: number;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const libraryPanelRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState<string>('');

  const [soundCategories, setSoundCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { auralizerSounds } = useAuralizerContext();

  const { selectedSounds } = usePresetContext();

  const [librarySounds, setLibrarySounds] = useState<LibrarySound[]>([]);
  const [filteredSounds, setFilteredSounds] = useState<LibrarySound[]>([]);

  useHandleKeyDown(inputRef, libraryPanelRef);

  useEffect(() => {
    if (auralizerSounds) {
      const activeSounds = auralizerSounds.filter((sound) => sound.active);
      const soundCategories = [...new Set(activeSounds.map((sound) => sound.category.toLowerCase()))];
      setSoundCategories(soundCategories);
      setLibrarySounds(activeSounds);
      setFilteredSounds(activeSounds);
    }
  }, [auralizerSounds]);

  // filter sounds anytime any of these change
  useEffect(() => {
    filterSoundList(searchValue, selectedCategories, librarySounds);
  }, [searchValue, selectedCategories, librarySounds]);

  const filterSoundList = (searchValue: string, selectedCategories: string[], librarySounds: LibrarySound[]) => {
    let filterdSoundsList = librarySounds;
    // filter list
    if (selectedCategories.length > 0) {
      filterdSoundsList = librarySounds.filter((sound) => {
        if (selectedCategories.map((s) => s.toLowerCase()).includes(sound.category.toLowerCase())) {
          return sound;
        }
      });
    }

    // search list
    if (searchValue && searchValue.length > 1) {
      const searchedList = filterdSoundsList.filter((sound) => {
        const parsedName = sound.name.toLowerCase();
        const parsedCategory = sound.category.toLowerCase();
        return parsedName.includes(searchValue) || parsedCategory.includes(searchValue);
      });
      setFilteredSounds(searchedList);
    } else {
      setFilteredSounds(filterdSoundsList);
    }
  };

  const searchForSound = (key: string, inputValue: string) => {
    if (/^[ -~]+$/i.test(key)) {
      const parsedVal = inputValue.toLowerCase();
      setSearchValue(parsedVal);
    }
  };

  const selectCategory = (category: string, event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'click') event.stopPropagation();

    const index = selectedCategories.findIndex((selected) => selected === category);
    if (index === -1) {
      const newArray = [...selectedCategories, category];

      setSelectedCategories(newArray);
    } else {
      const newArray = [...selectedCategories];
      newArray.splice(index, 1);

      setSelectedCategories(newArray);
    }
  };

  return (
    <div className={styles.library_content_container}>
      <div className={styles.top_row}>
        <LibrarySearch
          inputRef={inputRef}
          label="Sounds"
          searchLibrary={searchForSound}
          autofocus={selectedSounds?.[sourceIndex] === undefined}
        />
        <UploadSound sourceIndex={sourceIndex} />
      </div>
      <SoundLibraryFilters
        categories={soundCategories}
        selectCategory={selectCategory}
        selectedCategories={selectedCategories}
      />
      <div ref={libraryPanelRef} className={styles.library_content}>
        <LibraryHeader>
          <p className={styles.name_column}>Name</p>
          <p className={styles.category_column}>Category</p>
        </LibraryHeader>
        <div className={styles.library_content_list}>
          <LibraryList>
            {filteredSounds.map((sound) => (
              <SoundRow key={sound.id} sound={sound} setShowPopup={setShowPopup} sourceIndex={sourceIndex} />
            ))}
          </LibraryList>
        </div>
      </div>
    </div>
  );
};
