import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box } from '@mui/material';

import { SecondaryButton } from '../Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '../Shared/Popup';
import { PopupContent } from './PopupContent';

import { useCreateNewSimulation } from './hooks/useCreateNewSimulation';
import { useSubmitEvent } from '@/hooks';

import { NewSimulationTypes } from './constants';

const POPUP_TITLE = 'Create new simulation';

export const CreateNewSimulationPopup = ({ setShowPopup }: { setShowPopup: (show: boolean) => void }) => {
  const {
    simulationState: { availableSimulations },
    dispatch,
  } = useSimulationContext();

  const [isFormValid, setIsFormValid] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Form values
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedRadio, setSelectedRadio] = useState(NewSimulationTypes.New);
  const [selectedSimulationToCopyId, setSelectedSimulationToCopyId] = useState('');

  const createNewSimulation = useCreateNewSimulation();
  useSubmitEvent(isFormValid, [name, description, selectedRadio, selectedSimulationToCopyId]);

  useEffect(() => {
    // reset the simulation select when toggling away from "copy"
    if (selectedRadio === NewSimulationTypes.New) {
      setSelectedSimulationToCopyId('');
    }
  }, [selectedRadio]);

  // Form validation
  useEffect(() => {
    if (selectedRadio === NewSimulationTypes.Copy) {
      setIsFormValid(false);
      if (selectedSimulationToCopyId.length > 0 && name.length > 0) {
        setIsFormValid(true);
      }
    } else if (name.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, selectedRadio, selectedSimulationToCopyId]);

  const createSimulation = async () => {
    const newSim = await createNewSimulation(selectedRadio, name, description, selectedSimulationToCopyId);
    if (newSim) {
      if (availableSimulations) {
        // Add the new simulation to the current model
        // simulations array to update the dropdown etc.
        const newSimulations = [...availableSimulations, newSim];

        // Add the new simulation to the model's list of simulations
        dispatch({
          type: ActionType.SET_MODEL_SIMULATIONS,
          simulations: newSimulations,
        });

        // the dispatch above does not fire "quickly enough", so as an hack we set the change
        // param functionality into a timeout so that when a new simulation is created, the
        // newSimulations (availableSimulations) array will be part of the next "React state update"
        // otherwise the route changes but availableSimulations do not update in time
        setTimeout(() => {
          if (searchParams.get('mid')) {
            // Backwards compatible for SketchUp
            setSearchParams({ mid: newSim.modelId, sid: newSim.id }, { replace: true });
          } else {
            setSearchParams({ sid: newSim.id }, { replace: true });
          }
        });

        toast.success(`New simulation '${newSim.name}' created!`, { className: 'editor-toast' });
      }

      setShowPopup(false);
    } else {
      toast.error('Creating a new simulation failed. Please refresh the browser.', { className: 'editor-toast' });
    }
  };

  return (
    <TrblPopup width="400px" hideBackdrop={false} aria-labelledby={POPUP_TITLE} sx={{ fontSize: '12px' }} open={true}>
      <form>
        <TrblPopupTitle onClose={() => setShowPopup(false)}>{POPUP_TITLE}</TrblPopupTitle>
        <TrblPopupContent>
          <PopupContent
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            selectedSimulationToCopyId={selectedSimulationToCopyId}
            setSelectedSimulationToCopyId={setSelectedSimulationToCopyId}
          />
        </TrblPopupContent>
        <TrblPopupActions>
          <Box component={'div'}>
            <SecondaryButton
              type="submit"
              disabled={!isFormValid}
              width={'fit-content'}
              label="Add"
              onClick={createSimulation}
            />
          </Box>
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
