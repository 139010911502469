import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Stack } from '@mui/material';

import { TabButtons } from '@/components/Shared/TabButtons';
import { REF_RANGE } from '@/components/MaterialDetailsPopup/constants';
import { ComplexInput } from './ComplexInput';
import { SimpleInput } from './SimpleInput';

import { MaterialInputType } from '../constants';

export const MaterialInput = ({ plotHeight }: { plotHeight: number }) => {
  const {
    formDisabled,
    inputType,
    materialFit,
    impedanceFittedData,
    reflectionFittedData,
    impedanceData,
    reflectionData,
    dispatch,
  } = useCreateMaterialContext();

  const changeMaterialType = (value: string) => {
    dispatch({
      type: ActionType.SET_MATERIAL_INPUT_TYPE,
      inputType: value as MaterialInputType,
    });
  };

  return (
    <>
      <Stack margin={'0px 0 20px'}>
        <TabButtons
          options={[
            {
              key: MaterialInputType.FullOctaveAbsorption,
              label: 'Full octave absorption',
              disabled: formDisabled || impedanceFittedData !== null || reflectionFittedData !== null,
            },
            {
              key: MaterialInputType.SurfaceImpedance,
              label: 'Surface impedance',
              disabled: formDisabled || materialFit !== null || reflectionFittedData !== null,
            },
            {
              key: MaterialInputType.ReflectionCoefficient,
              label: 'Reflection coefficient',
              disabled: formDisabled || materialFit !== null || impedanceFittedData !== null,
            },
          ]}
          selectedValue={inputType}
          onChange={changeMaterialType}
        />
      </Stack>
      <>
        {inputType === MaterialInputType.FullOctaveAbsorption && <SimpleInput plotHeight={plotHeight} />}
        {inputType === MaterialInputType.SurfaceImpedance && (
          <ComplexInput
            data={impedanceData}
            label="surface impedance"
            rowLabels={['Real (Z)', 'Imag (Z)']}
            specificRowLabels={['Real (ζ)', 'Imag (ζ)']}
            plotHeight={plotHeight}
          />
        )}
        {inputType === MaterialInputType.ReflectionCoefficient && (
          <ComplexInput
            data={reflectionData}
            label="reflection coefficient"
            rowLabels={['Real (R)', 'Imag (R)']}
            plotHeight={plotHeight}
            range={REF_RANGE}
          />
        )}
      </>
    </>
  );
};
