import { FC } from 'react';

import { TrblLinkIcon } from '@/components/Icons';
import { TrblTooltip } from '../TrblTooltip';

import classes from './styles.module.scss';

type ShareRowProps = {
  content: string;
  isAnonymous?: boolean;
  size?: 'large' | 'medium' | 'small';
  lineStyle?: 'solid' | 'dotted';
  tooltip?: string;
  color?: string;
  background?: string;
  overlapping?: boolean;
};

export const NameMarker: FC<ShareRowProps> = ({
  isAnonymous = false,
  content,
  tooltip,
  color,
  size = 'small',
  lineStyle = 'dotted',
  background,
  overlapping,
}) => {
  return (
    <TrblTooltip title={tooltip && <div style={{ whiteSpace: 'pre-line' }}>{tooltip}</div>}>
      <div
        className={`${classes['bubble']} ${classes[size]} ${classes[lineStyle]} ${
          isAnonymous ? classes['anonymous'] : ''
        } ${overlapping ? classes['overlapping'] : ''}`}
        style={{ borderColor: color, background }}>
        {isAnonymous ? (
          <span className={classes['icon']}>
            <TrblLinkIcon fill="#272727" />
          </span>
        ) : (
          <span className={classes['label']} style={{ color }}>
            {content}
          </span>
        )}
      </div>
    </TrblTooltip>
  );
};
