import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useUpdateObjScats } from '@/context/hooks/useUpdateObjScats';

import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';

import { Simulation } from '@/types';

// We need a "global" state variable so if the user changes the scatter
// really quickly the newest one doesn't overwrite the other ones
const newScatteringByObjectId: { [key: string]: { [key: string]: number[] } } = {};

export const useUpdateScatter = () => {
  const saveSimulation = useSaveUpdatedSimulation();
  const confirmEdit = useConfirmEdit();
  const { dispatch } = useEditorContext();
  const updateObjScats = useUpdateObjScats();

  const updateScatter = async (simulation: Simulation | null, selectedLayerId: string | null, newScatter: number[]) => {
    if (simulation?.modelSettings && selectedLayerId) {
      const canContinue = await confirmEdit();

      newScatteringByObjectId[simulation.id] = {
        ...simulation.modelSettings?.scatteringByObjectId,
        ...newScatteringByObjectId[simulation.id],
      };

      const newObjScats = updateObjScats(newScatteringByObjectId[simulation.id], [selectedLayerId], newScatter);

      newScatteringByObjectId[simulation.id] = {
        ...newObjScats,
      };

      const updatedSimulation: Simulation = {
        ...simulation,
        modelSettings: {
          ...simulation.modelSettings,
          scatteringByObjectId: newScatteringByObjectId[simulation.id],
        },
      };

      if (canContinue) {
        saveSimulation(updatedSimulation);
      } else {
        dispatch({
          type: ActionType.SHOW_EDIT_MODAL,
          editSimulation: { showModal: true, updatedSimulation },
        });
      }
    }
  };

  return updateScatter;
};
