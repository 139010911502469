import { useEffect, useState } from 'react';

import { UserDto } from '@/components/ManagementPortal/hooks/types';

import { useGetSpaces, useGetUserMine } from '@/hooks';

import { SpaceDto } from '@/types';

const checkHasOwnModels = (user?: UserDto, spaces?: SpaceDto[]) => {
  if (!user?.createdAt || !spaces) {
    // Handle loading or data not yet available
    return undefined;
  }

  const userCreatedAt = new Date(user.createdAt);

  // Iterate through projects and their modelBases
  for (const space of spaces) {
    for (const modelBase of space.modelBases) {
      const modelCreatedAt = new Date(modelBase.createdAt);

      // Calculate the time difference in milliseconds
      const timeDifference = modelCreatedAt.getTime() - userCreatedAt.getTime();

      // Check if the model was created 5 minutes or more after the user was created. If so we assume it was a user created model
      if (timeDifference >= 5 * 60 * 1000) {
        return true; // Return true if any model meets the condition
      }
    }
  }

  return false; // Return false if no model meets the condition
};

export const useHasOwnModels = () => {
  const { data: user } = useGetUserMine();
  const { data: spaces } = useGetSpaces();

  const [hasOwnModels, setHasOwnModels] = useState<boolean | undefined>();

  useEffect(() => {
    const hasOwnModels = checkHasOwnModels(user, spaces);
    setHasOwnModels(hasOwnModels);
  }, [user, spaces]);

  return hasOwnModels;
};
