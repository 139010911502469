import { TrblCaretDownIcon, TrblCaretRightIcon } from '@/components/Icons';

import { TreeBranch } from './types';

import styles from './styles.module.scss';

export const TrblTreeBranchName = ({
  structure,
  isOpen,
  level,
  filter,
}: {
  structure: TreeBranch;
  isOpen: boolean | null;
  level: number;
  filter: string;
}) => {
  const HighlightedName = ({ name, highlight }: { name: string; highlight: string }) => {
    if (structure.isSelectable) {
      // if the user searches for the id of a simulation
      if (structure.id === highlight) {
        return (
          <span className={styles.tree_branch__name_highlight}>
            <b>{name} </b>
          </span>
        );
      }
      // if the user searches for a simulation name or part of name
      const parts = name.split(new RegExp(`(${highlight})`, 'i'));
      if (parts && parts.length > 1) {
        return (
          <span>
            {parts.map((part, index) =>
              part.toLowerCase() === highlight.toLowerCase() ? (
                <b key={`${part}-${index}`} className={styles.tree_branch__name_highlight}>
                  {part}
                </b>
              ) : (
                part
              )
            )}
          </span>
        );
      } else {
        return <span>{name} </span>;
      }
    } else {
      return <span>{name} </span>;
    }
  };

  return (
    <span
      className={`${styles.tree_branch} ${styles[`level_${level}`]} ${
        structure.meta?.scroll && styles.tree_branch__hover
      }`}>
      <span className={styles.tree_branch__icon}>
        {structure.children &&
          Object.keys(structure.children).length > 0 &&
          (isOpen ? <TrblCaretDownIcon /> : <TrblCaretRightIcon />)}
      </span>
      {structure.icon ? <span className={styles.tree_branch__custom_icon}> {structure.icon} </span> : null}
      <span className={styles.tree_branch__name}>
        <HighlightedName name={structure.name} highlight={filter} />
      </span>
    </span>
  );
};
