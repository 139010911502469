import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSolverSettingsIcon: FC<IconProps> = ({ fill }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86816 8.50049C3.86816 5.73906 6.0172 3.50049 8.66816 3.50049C9.9412 3.50049 11.1621 4.02727 12.0623 4.96495C12.9625 5.90264 13.4682 7.17441 13.4682 8.50049C13.4682 11.2619 11.3191 13.5005 8.66816 13.5005C6.0172 13.5005 3.86816 11.2619 3.86816 8.50049ZM8.50767 6.00071C8.50779 6.55291 8.93756 7.00049 9.46767 7.00049V6.99982C9.99787 6.99982 10.4277 6.55211 10.4277 5.99982C10.4273 5.44762 9.99736 5.00024 9.46725 5.00049C8.93714 5.00073 8.50756 5.44851 8.50767 6.00071Z"
      fill="#F5F5F5"
    />
    <path
      d="M16.0274 9.16727C16.3808 9.16727 16.6674 8.8688 16.6674 8.50061C16.6706 8.168 16.4343 7.88551 16.1176 7.84327C16.0457 7.83186 15.9902 7.77132 15.9826 7.69594C15.6535 4.35318 13.2731 1.62319 10.1104 0.961439C6.94767 0.299685 3.73986 1.86041 2.19714 4.81153C0.654427 7.76265 1.13639 11.4163 3.38608 13.8246C3.44064 13.8833 3.44607 13.9747 3.39888 14.0399L3.35536 14.1006C3.21817 14.2911 3.18898 14.5435 3.2788 14.7625C3.36862 14.9815 3.56379 15.134 3.7908 15.1625C4.01781 15.191 4.24217 15.0911 4.37936 14.9006L5.33936 13.5673C5.55143 13.2727 5.49413 12.8549 5.21136 12.6339C4.92859 12.413 4.52743 12.4727 4.31536 12.7673C4.30066 12.7882 4.27771 12.801 4.25288 12.8023C4.22805 12.8036 4.20398 12.7932 4.18736 12.7739C2.4125 10.7609 2.07763 7.78888 3.35677 5.40244C4.63591 3.01601 7.24046 1.75358 9.81686 2.27122C12.3933 2.78887 14.3602 4.9698 14.6955 7.68061C14.7057 7.7634 14.6557 7.84133 14.5784 7.86327C14.2785 7.95867 14.0891 8.26635 14.1311 8.59004C14.173 8.91373 14.4339 9.1581 14.7474 9.16727H16.0274Z"
      fill={fill || '#F5F5F5'}
    />
  </svg>
);
