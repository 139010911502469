import { ClickAwayListener, Popper } from '@mui/material';

import { SourceDefinitionLibrary } from '@/components/SourceDefinitionLibrary';

import styles from './styles.module.scss';

export const SourceDefinitionLibraryPopup = ({
  showPopup,
  setShowPopup,
  anchorEl,
}: {
  showPopup: boolean;
  setShowPopup: (shouldShowPopup: boolean) => void;
  anchorEl: HTMLElement;
}) => {
  const onClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={showPopup}
      anchorEl={anchorEl}
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}>
      <ClickAwayListener onClickAway={onClosePopup}>
        <div className={styles['dropdown-content']}>
          <SourceDefinitionLibrary inPopup={true} />
        </div>
      </ClickAwayListener>
    </Popper>
  );
};
