import { useEffect, useState } from 'react';

import { QuickLinks } from '../RecentSimulations/QuickLinks';
import { ModelSimulationStatusDisplay } from './ModelSimulationStatusDisplay';

import { RunStatus, Simulation, SimulationRunDto } from '@/types';

import classes from '@/components/RecentSimulations/styles.module.scss';

interface ModelSimulationStatusProps {
  simulation: Simulation;
  simulationRun?: SimulationRunDto | null;
  modelId: string;
  modelBaseId: string;
}

export const ModelSimulationStatus = ({ simulation, simulationRun, modelId }: ModelSimulationStatusProps) => {
  const [status, setStatus] = useState<RunStatus | null>(null);

  const setStatusFunc = (sim: SimulationRunDto) => {
    setStatus(sim.status);
  };

  useEffect(() => {
    if (simulationRun) {
      setStatusFunc(simulationRun);
    }
  }, [simulationRun]);

  return (
    <div className={classes.recent_simulations_status}>
      <a
        className={classes.simulation_name}
        href={`/editor?mid=${modelId}&sid=${simulation.id}`}
        title={`Open simulation ${simulation.name}`}>
        {simulation.name}
      </a>
      <div className={classes.row}>
        {status ? (
          <>
            <ModelSimulationStatusDisplay simulationStatus={status} created={simulation.createdAt} />
            {status == RunStatus.Completed && <QuickLinks simulationId={simulation.id} modelId={simulation.modelId} />}
          </>
        ) : (
          <ModelSimulationStatusDisplay created={simulation.createdAt} />
        )}
      </div>
    </div>
  );
};
