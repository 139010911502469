import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import { TabButtons } from '@/components/Shared/TabButtons';
import { DarkBox } from '../Shared/Popup';
import { SPLPlot } from './SPLPlot';

import { TOGGLE_2D_PLOT_HOVER_TEXT } from './constants';

import { getPlotData, getSourceDefinitionPlotLayoutConfig, getSPLOnAxisValues } from './utils';

type SourceDefinitionPlots = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceDefinitionJson: any;
  eqData?: number[] | null;
};

export const SourceDefinitionPlots = ({ sourceDefinitionJson, eqData }: SourceDefinitionPlots) => {
  const [graphSelected, setGraphSelected] = useState('');
  const [plotSelected, setPlotSelected] = useState('');
  const [plotData, setPlotData] = useState<Data[] | null>(null);
  const [splData, setSPLData] = useState<number[] | null>(null);

  const toggleGraph = (value: string) => {
    setGraphSelected(value);
  };

  const togglePlot = (value: string) => {
    if (value === 'Top') setPlotData(getPlotData(sourceDefinitionJson.angles, sourceDefinitionJson.directivity));
    else setPlotData(getPlotData(sourceDefinitionJson.angles, sourceDefinitionJson.directivity_XZ));
    setPlotSelected(value);
  };

  useEffect(() => {
    setGraphSelected('Directivity pattern');
    togglePlot('Top');
  }, []);

  useEffect(() => {
    if (plotSelected) togglePlot(plotSelected);
    // eslint-disable-next-line no-prototype-builtins
    if (sourceDefinitionJson.hasOwnProperty('SplOnAxis1mByFrequency')) {
      setSPLData(getSPLOnAxisValues(sourceDefinitionJson) as number[]);
    } else {
      setSPLData(null);
    }
  }, [sourceDefinitionJson]);

  return (
    <DarkBox minHeight={358} height={'100%'} mt={-17} mb={-16} ml={0}>
      <div style={{ margin: '2px auto', width: '320px' }}>
        <TabButtons
          options={[
            { key: 'Directivity pattern', label: 'Directivity pattern' },
            { key: 'SPL', label: 'SPL on axis' },
          ]}
          selectedValue={graphSelected}
          onChange={toggleGraph}
          height={30}
        />
      </div>

      <div style={graphSelected !== 'Directivity pattern' ? { display: 'none' } : {}}>
        {plotData && (
          <Plot
            style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
            data={plotData ?? []}
            config={{ displaylogo: false }}
            layout={getSourceDefinitionPlotLayoutConfig(plotData, plotSelected)}
          />
        )}
        <div style={{ position: 'absolute', right: 20, bottom: 20, zIndex: '1', width: '54px' }}>
          <TabButtons
            options={[
              { key: 'Top', label: 'T', tooltip: TOGGLE_2D_PLOT_HOVER_TEXT[0] },
              { key: 'Side', label: 'S', tooltip: TOGGLE_2D_PLOT_HOVER_TEXT[1] },
            ]}
            selectedValue={plotSelected}
            onChange={togglePlot}
            small
            dark
          />
        </div>
      </div>

      <div style={graphSelected !== 'SPL' ? { display: 'none' } : { paddingTop: '16px', textAlign: 'center' }}>
        {splData ? <SPLPlot splData={splData} eqData={eqData} /> : <p>No SPL data available.</p>}
      </div>
    </DarkBox>
  );
};
