import { useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblCancelledIcon, TrblCompletedIcon, TrblErrorIcon, TrblInprogressIcon, TrblWarningIcon } from '../Icons';
/** Images */
import yellowCircleImageUrl from './images/yellowCircle.png';

export const RunSimulationBadge = ({ status }: { status: number | null }) => {
  const [title, setTitle] = useState('');
  const [statusIcon, setStatusIcon] = useState(<></>);
  const { isAuralizerOpen } = useEditorContext();
  const {
    simulationState: { userTouched },
  } = useSimulationContext();

  useEffect(() => {
    switch (status) {
      case 0:
      case 1:
        // running
        setStatusIcon(<TrblInprogressIcon />);
        setTitle('In progress');
        break;
      case 2:
        // completed
        setStatusIcon(<TrblCompletedIcon />);
        if (userTouched) {
          setTitle('Completed [editing]');
        } else {
          setTitle('Completed');
        }
        break;
      case 3:
        // cancelled
        setStatusIcon(<TrblCancelledIcon />);
        setTitle('Cancelled');

        break;
      case 4:
        // error
        setStatusIcon(<TrblErrorIcon />);
        setTitle('Error');

        break;
      case 5:
        // error
        setStatusIcon(<TrblWarningIcon />);
        setTitle('Insufficient tokens');

        break;
    }
  }, [status, userTouched]);

  return (
    <>
      {status === 0 ? null : (
        <TrblTooltip title={title}>
          <div className={`sim-folder-statusicon ${isAuralizerOpen ? 'hidden' : ''}`}>
            {statusIcon}
            {userTouched && status !== 1 ? (
              <img src={yellowCircleImageUrl} alt="Settings changed from last simulation run" className="changed-img" />
            ) : null}
          </div>
        </TrblTooltip>
      )}
    </>
  );
};
