import { SyntheticEvent, useEffect, useState } from 'react';

import { GeometryImportActionType, useGeometryImportContext } from '@/context/GeometryImportContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import {
  TrblCaretDownIcon,
  TrblCaretRightIcon,
  TrblChevronLeft,
  TrblMagnifyZoomInIcon,
  TrblMagnifyZoomOutIcon,
} from '@/components/Icons';

import { WarningTableGeometryInfo } from '../types';

import styles from './styles.module.scss';

const WarningTable = ({ data, modelId }: { data: WarningTableGeometryInfo[]; modelId: string }) => {
  const [collapsedRows, setCollapsedRows] = useState<Set<string>>(new Set());
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const { dispatch: modelDispatch, warningSelected } = useGeometryImportContext();

  const [warningsLength, setWarningsLength] = useState(0);
  const [warningTypeSelected, setWarningsTypeSelected] = useState('');
  const [warningIndex, setWarningIndex] = useState<number | null>(null);

  useEffect(() => {
    // if another model is selected, then make sure that we zoom out
    handleSetWarning(warningTypeSelected, null);
  }, [modelId]);

  const handleZoomClick = (e: SyntheticEvent, id: string, length: number) => {
    e.stopPropagation();

    if (selectedRow !== id) {
      handleFirstClick(id, 0, length);
      return;
    }

    if (warningSelected == null) {
      nextWarning(e, id, length);
    } else {
      handleSetWarning(id, null);
    }
  };

  const nextWarning = (e: SyntheticEvent, id: string, length: number) => {
    e.stopPropagation();
    if (selectedRow !== id) {
      handleFirstClick(id, 0, length);
      return;
    }

    let nextIndex;
    if (warningSelected !== null) {
      nextIndex = warningSelected == warningsLength - 1 ? 0 : warningSelected + 1;
    } else {
      nextIndex = 0;
      setWarningsLength(length);
    }

    handleSetWarning(id, nextIndex);
  };

  const previousWarning = (e: SyntheticEvent, id: string, length: number) => {
    e.stopPropagation();
    if (selectedRow !== id) {
      handleFirstClick(id, length - 1, length);
      return;
    }

    let previousIndex;

    if (warningSelected !== null) {
      previousIndex = warningSelected == 0 ? warningsLength - 1 : warningSelected - 1;
    } else {
      previousIndex = length - 1;
      setWarningsLength(length);
    }

    handleSetWarning(id, previousIndex);
  };

  const handleSetWarning = (warningType: string, index: number | null) => {
    modelDispatch({
      type: GeometryImportActionType.SET_GEOMETRY_WARNINGS_SELECTED,
      index,
      warningType,
    });
    setWarningIndex(index);
    setWarningsTypeSelected(warningType);
  };

  const toggleRow = (id: string) => {
    const newCollapsedRows = new Set(collapsedRows);
    if (newCollapsedRows.has(id)) {
      newCollapsedRows.delete(id);
    } else {
      newCollapsedRows.add(id);
    }
    setCollapsedRows(newCollapsedRows);
  };

  const handleFirstClick = (id: string, index: number, length: number) => {
    setSelectedRow(id);
    toggleRow(id);
    setWarningsLength(length);
    handleSetWarning(id, index);
  };

  const handleClickRow = (id: string) => {
    if (selectedRow == id) {
      setSelectedRow(null);
      handleSetWarning('', null);
    } else {
      setSelectedRow(id);
      handleSetWarning(id, null);
    }

    toggleRow(id);
  };

  const getWarningLength = ({ length, actualLength, name }: WarningTableGeometryInfo) => {
    const tooltipTitle =
      length >= actualLength
        ? `${length} ${name.toLocaleLowerCase()} found`
        : `${actualLength} ${name.toLocaleLowerCase()} found, showing first ${length}`;

    const displayLength = length >= actualLength ? length : `${length}+`;

    return (
      <TrblTooltip title={tooltipTitle}>
        <span>{displayLength}</span>
      </TrblTooltip>
    );
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>, id: string) {
    if (event.key === 'Enter' && event.target.tagName == 'DIV') {
      event.preventDefault();
      handleClickRow(id);
    }
  }

  return (
    <div>
      {data.map((warningInfo: WarningTableGeometryInfo) => {
        return (
          <div
            title={warningInfo.id == selectedRow ? '' : 'View warning'}
            key={warningInfo.id}
            className={`${styles['warning-row']} ${warningInfo.id === selectedRow ? styles['selected'] : ''}`}
            onClick={() => handleClickRow(warningInfo.id)}
            onKeyDown={(e) => handleKeyDown(e, warningInfo.id)}
            tabIndex={0}>
            <div className={styles['warning-row-cell']}>
              <div className={styles['expand-collapse-cell']}>
                {selectedRow == warningInfo.id ? <TrblCaretDownIcon /> : <TrblCaretRightIcon />}
              </div>
              <div>
                {warningInfo.id === selectedRow && warningSelected !== null ? (
                  <>
                    {warningInfo.name}{' '}
                    <span style={{ color: '#adadad' }} title="">
                      - <div className={styles['counter-number']}>{warningSelected + 1}</div>/
                      {getWarningLength(warningInfo)}
                    </span>
                  </>
                ) : (
                  <>
                    {warningInfo.name}{' '}
                    <span style={{ color: '#adadad' }} title="">
                      - {getWarningLength(warningInfo)}
                    </span>
                  </>
                )}
              </div>
              <div
                className={` ${styles['warning-row-extra']} ${
                  warningInfo.id === selectedRow ? styles['selected'] : ''
                }`}>
                <div className={styles['action-box']}>
                  <TrblIconButton
                    onClick={(e) => previousWarning(e, warningInfo.id, warningInfo.length)}
                    title="Go to previous warning"
                    sx={{ width: '22px', height: '22px', padding: '7.5px' }}
                    icon={<TrblChevronLeft />}
                    tabIndex={warningInfo.id == selectedRow ? 0 : -1}></TrblIconButton>
                  <TrblIconButton
                    onClick={(e) => handleZoomClick(e, warningInfo.id, warningInfo.length)}
                    title={
                      warningIndex !== null && selectedRow == warningInfo.id
                        ? 'Zoom out to previous position'
                        : 'Zoom into first warning'
                    }
                    sx={{ width: '22px', height: '22px', padding: '0' }}
                    icon={
                      warningIndex !== null && selectedRow == warningInfo.id ? (
                        <TrblMagnifyZoomOutIcon width="16" height="16" />
                      ) : (
                        <TrblMagnifyZoomInIcon width="16" height="16" />
                      )
                    }
                    tabIndex={warningInfo.id == selectedRow ? 0 : -1}></TrblIconButton>

                  <TrblIconButton
                    onClick={(e) => nextWarning(e, warningInfo.id, warningInfo.length)}
                    title="Go to next warning"
                    sx={{ width: '22px', height: '22px', padding: '7.5px', transform: 'rotate(180deg)' }}
                    icon={<TrblChevronLeft />}
                    tabIndex={warningInfo.id == selectedRow ? 0 : -1}></TrblIconButton>
                </div>
              </div>
            </div>
            <div className={styles['child-row']} onClick={() => handleClickRow(warningInfo.id)}>
              {warningInfo.info}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WarningTable;
