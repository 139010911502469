import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

type ConfirmNewRunPopup = {
  showRunConfirmation: boolean;
  setShowRunConfirmation: (show: boolean) => void;
  runSimulation: () => void;
};

export const ConfirmNewRunPopup = ({
  showRunConfirmation,
  setShowRunConfirmation,
  runSimulation,
}: ConfirmNewRunPopup) => {
  return (
    <>
      {showRunConfirmation && (
        <ConfirmationDialog
          title="Confirm overwrite"
          message={() => (
            <>
              <span> Are you sure you want to run this simulation and overwrite previous results? </span>
            </>
          )}
          onConfirm={() => {
            runSimulation();
            setShowRunConfirmation(false);
          }}
          onCancel={() => setShowRunConfirmation(false)}
        />
      )}
    </>
  );
};
