import { useState } from 'react';

import { ActionType as AppAction, SimulationsInSpace, useAppContext } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';

import { Box, FormControl } from '@mui/material';

import { RadioButton } from '../Shared/RadioButton';
import { TextArea } from '../Shared/TextArea';
import { TextField } from '../Shared/TextField';
import { SelectOptionWithGrouping, TrblSelect } from '../Shared/TrblSelect';

import { NewSimulationTypes } from './constants';

import { createSelectMenu } from './utils';

import { ModelSimulationsDto } from '@/types';

import axios from '@/axios';

type PopupContent = {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  selectedRadio: string;
  setSelectedRadio: (option: NewSimulationTypes) => void;
  selectedSimulationToCopyId: string;
  setSelectedSimulationToCopyId: (prevSimId: string) => void;
};

export const PopupContent = ({
  name,
  setName,
  description,
  setDescription,
  selectedRadio,
  setSelectedRadio,
  selectedSimulationToCopyId,
  setSelectedSimulationToCopyId,
}: PopupContent) => {
  const { modelInformation } = useModelContext();
  const { dispatch: appDispatch } = useAppContext();
  const [menuItems, setMenuItems] = useState<SelectOptionWithGrouping[]>([] as SelectOptionWithGrouping[]);
  const [hasSims, setHasSims] = useState<boolean>(true);

  const getSimulationsForModels = async () => {
    try {
      const { data } = await axios.get(`/api/Simulation/GetBySpaceId?spaceId=${modelInformation?.spaceId}`);
      setHasSims(data.length === 0 ? false : true);
      const spaceSimulationsBySpace = data.map((space: ModelSimulationsDto) => {
        return {
          name: space.modelBaseName,
          id: space.modelId,
          createdAt: space.modelCreatedAt,
          simulations: space.simulations,
        };
      });
      createMenuItems(spaceSimulationsBySpace);
      appDispatch({ type: AppAction.SET_SIMULATIONS_IN_SPACE, payload: spaceSimulationsBySpace });
    } catch (error) {
      // TODO: handle error
    }
  };

  const createMenuItems = (spaceSimulationsBySpace: SimulationsInSpace[]) => {
    const sortedMenuItems = createSelectMenu(spaceSimulationsBySpace, modelInformation?.id);
    setMenuItems(sortedMenuItems);
  };

  return (
    <>
      <FormControl>
        <Box component="div" paddingTop={1}>
          <RadioButton
            label="New simulation"
            onClick={() => setSelectedRadio(NewSimulationTypes.New)}
            selected={selectedRadio === NewSimulationTypes.New}
          />
        </Box>
        <Box component="div" paddingTop={1}>
          <RadioButton
            label="Duplicate simulation settings"
            onClick={() => {
              setSelectedRadio(NewSimulationTypes.Copy);
              getSimulationsForModels();
            }}
            selected={selectedRadio === NewSimulationTypes.Copy}
          />
        </Box>
      </FormControl>
      <FormControl
        disabled={menuItems[0]?.options.length === 0}
        // Style to show or hide dropdown depending on selected radio button
        style={
          selectedRadio === NewSimulationTypes.Copy
            ? { maxHeight: '50px', transition: '0.2s max-height, 0.05s margin', overflow: 'hidden', margin: '16px 0' }
            : { maxHeight: 0, transition: '0.2s max-height, 0.05s margin', overflow: 'hidden', margin: '0' }
        }>
        <div>
          <TrblSelect
            value={selectedSimulationToCopyId}
            setValue={setSelectedSimulationToCopyId}
            menuItems={menuItems}
            hasCategories={true}
            disabled={selectedRadio !== NewSimulationTypes.Copy || !hasSims}
            placeholder={hasSims ? 'Choose simulation to duplicate' : 'No other simulations available'}
          />
        </div>
      </FormControl>
      <FormControl>
        <div>
          <TextField autoFocus placeholder="Simulation name (required)" value={name} onChange={setName} />
        </div>
      </FormControl>
      <FormControl>
        <Box component="div" paddingTop={2} height={'100%'}>
          <TextArea
            placeholder="Simulation description"
            value={description}
            onChange={setDescription}
            style={{ height: '100%' }}
          />
        </Box>
      </FormControl>
    </>
  );
};
