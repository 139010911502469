import { useEffect, useState } from 'react';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { Box } from '@mui/material';

import { SecondaryButton } from '../Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '../Shared/Popup';
import { EditSimAttrPopupContent } from './EditSimAttrPopupContent';

import { useSaveUpdatedSimulation } from '../EditSimulation/hooks/useSaveUpdatedSimulation';
import { useSubmitEvent } from '@/hooks';

const POPUP_TITLE = 'Edit simulation details';

export const EditSimAttrPopup = ({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (setShow: boolean) => void;
}) => {
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const { dispatch: editorDispatch } = useEditorContext();

  const [isFormValid, setIsFormValid] = useState(true);

  // Form values
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const saveSimulation = useSaveUpdatedSimulation();
  useSubmitEvent(isFormValid, [name, description]);

  useEffect(() => {
    editorDispatch({
      type: EditorActionType.SET_IS_POPUP_OPEN,
      isOpen: showPopup,
    });
  }, [showPopup]);

  useEffect(() => {
    if (selectedSimulation) {
      setName(selectedSimulation.name);
      setDescription(selectedSimulation?.description);
    }
  }, [selectedSimulation]);

  // Form validation
  useEffect(() => {
    if (name.length > 0 && (selectedSimulation?.name !== name || selectedSimulation?.description !== description)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, selectedSimulation, description]);

  const saveCurrentSimulation = () => {
    if (selectedSimulation) {
      const updatedSimulation = { ...selectedSimulation, name, description };
      saveSimulation(updatedSimulation, 'Simulation updated ✓');
      setShowPopup(false);
    }
  };

  return (
    <>
      {showPopup ? (
        <TrblPopup
          width="400px"
          hideBackdrop={false}
          aria-labelledby={POPUP_TITLE}
          sx={{ fontSize: '12px' }}
          open={showPopup}>
          <form>
            <TrblPopupTitle onClose={() => setShowPopup(false)}>{POPUP_TITLE}</TrblPopupTitle>
            <TrblPopupContent>
              <EditSimAttrPopupContent
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
              />
            </TrblPopupContent>
            <TrblPopupActions>
              <Box component={'div'} display={'flex'} justifyContent={'end'} width={'100%'} alignItems={'center'}>
                <SecondaryButton
                  type="submit"
                  disabled={!isFormValid}
                  width={'fit-content'}
                  label="Update"
                  onClick={saveCurrentSimulation}
                />
              </Box>
            </TrblPopupActions>
          </form>
        </TrblPopup>
      ) : null}
    </>
  );
};
