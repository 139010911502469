import { useRef, useState } from 'react';

import { useResultsContext } from '../Results/context/ResultsContext';

import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { DeleteItemPopup } from '../LibraryPanel/components/DeleteItemPopup';
import { EditResultPresetPopup } from './EditResultPresetPopup';
import { InfoResultPresetPopup } from './InfoResultPresetPopup';

import { useResultPresetActions } from './hooks/useResultPresetActions';

import { checkProjectsForSavingResults } from './utils';

import { IActions } from '@/types';

import './styles.scss';

export const SavedResultsMenu = () => {
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  const { availableComparisons, selectedPreset, selectedPresetEdited } = useResultsContext();

  const { onUpdateResultPreset, onConfirmDelete, onUpdateResultPresetDetails } = useResultPresetActions();

  const [showEditPresetPopup, setShowEditPresetPopup] = useState(false);
  const [showInfoPresetPopup, setShowInfoPresetPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleEditResultPreset = async (name: string, description: string): Promise<boolean> => {
    const success = await onUpdateResultPresetDetails(name, description);
    if (!success) {
      return false;
    }

    setShowEditPresetPopup(false);
    return true;
  };

  const handleSaveChanges = () => {
    if (checkProjectsForSavingResults(availableComparisons)) {
      onUpdateResultPreset();
    }
  };

  const getResultsActions = (): IActions[] => {
    const actions: IActions[] = [
      {
        key: 'save-changes',
        value: 'Save changes',
        disabled: !selectedPresetEdited,
        customClassName: 'result-preset-menu-item',
        onClick: handleSaveChanges,
      },
      {
        key: 'edit',
        value: 'Edit',
        customClassName: 'result-preset-menu-item',
        onClick: () => setShowEditPresetPopup(true),
      },
      {
        key: 'details',
        value: 'Details',
        customClassName: 'result-preset-menu-item',
        onClick: () => setShowInfoPresetPopup(true),
      },
      {
        key: 'delete',
        value: 'Delete',
        customClassName: 'result-preset-menu-item',
        onClick: () => setShowDeleteConfirmation(true),
      },
    ];

    return actions;
  };

  return (
    selectedPreset && (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          <TrblActionsMenu
            id="saved-resultd-actions"
            triggerRef={actionsButtonRef}
            aria-haspopup="true"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            actions={getResultsActions()}
            title="View actions"
            size="small"
          />
        </div>

        {showInfoPresetPopup && (
          <InfoResultPresetPopup resultPreset={selectedPreset} onClose={() => setShowInfoPresetPopup(false)} />
        )}
        {showEditPresetPopup && (
          <EditResultPresetPopup
            resultPreset={selectedPreset}
            onClose={() => setShowEditPresetPopup(false)}
            onSubmit={handleEditResultPreset}
          />
        )}
        {showDeleteConfirmation && (
          <DeleteItemPopup
            onCancelDelete={() => setShowDeleteConfirmation(false)}
            onConfirmDelete={() => {
              setShowDeleteConfirmation(false);
              onConfirmDelete(selectedPreset);
            }}
            type="saved results"
            showWarning={false}
            name={selectedPreset.name}
          />
        )}
      </>
    )
  );
};
