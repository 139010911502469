import { FC } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { Text } from '@/components/Shared/Text';
import { TrblChevronLeft } from '@/components/Icons';

import styles from './styles.module.scss';

type ReflectionSelectionProps = {
  selectedReflectionIndex: number | null;
  totalCount: number;
  onSelectPreviousReflection: () => void;
  onSelectNextReflection: () => void;
};

export const ReflectionSelector: FC<ReflectionSelectionProps> = ({
  selectedReflectionIndex,
  totalCount,
  onSelectPreviousReflection,
  onSelectNextReflection,
}) => (
  <div className={styles['info-item']} style={{ gap: '3px' }}>
    <div className={styles['label']}>Reflection</div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}>
      <TrblTooltip title="Go to previous reflection">
        <span>
          <TrblIconButton
            onClick={onSelectPreviousReflection}
            sx={{ width: '22px', height: '22px' }}
            icon={<TrblChevronLeft />}
          />
        </span>
      </TrblTooltip>
      <Text
        type="regular-12px"
        style={{ minWidth: '75px', textAlign: 'center', paddingRight: '10px', paddingTop: '2px' }}>
        {`${selectedReflectionIndex === null ? 'All  ' : selectedReflectionIndex + 1} of ${totalCount}`}
      </Text>
      <TrblTooltip title="Go to next reflection">
        <span>
          <TrblIconButton
            onClick={onSelectNextReflection}
            sx={{ width: '22px', height: '22px', transform: 'rotate(180deg)' }}
            icon={<TrblChevronLeft />}
          />
        </span>
      </TrblTooltip>
    </div>
  </div>
);
