import { ReactNode, useEffect } from 'react';

import { ActionType, useAppContext } from '@/context/AppContext';

import { RecentSimulationsContent } from '@/components/RecentSimulations';
import { ModelDetails } from '../ModelDetails';
import { Divider } from '../Shared/Divider';
import { Welcome } from '../Welcome';

import { useHasOwnModels } from './hooks/useHasOwnModels';

import classes from './styles.module.scss';

export const ModelDetailsHeader = ({ children }: { children: ReactNode }) => (
  <h4 className={classes.model_details_header}>{children}</h4>
);

export const RightSidebar = () => {
  const {
    dispatch,
    appState: { selectedModel },
  } = useAppContext();

  const userHasOwnModels = useHasOwnModels();

  const onOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;

    //don't de-select model if clicked target is Sidepanel, modelCard, MuiMenuItem, or MuiModal
    if (
      document.querySelector('.page-layout-sidepanel')?.contains(target) ||
      target.classList.contains('MuiMenuItem-root') ||
      document.querySelector('.MuiModal-root')?.contains(target) ||
      target.closest('.base-model-card')
    ) {
      return;
    }

    dispatch({
      type: ActionType.SET_SELECTED_MODEL,
      payload: null,
    });
  };

  useEffect(() => {
    if (selectedModel) {
      document.addEventListener('mousedown', onOutsideClick);
    }
    return () => document.removeEventListener('mousedown', onOutsideClick);
  }, [selectedModel]);

  return (
    <div className={classes.sidebar_right}>
      {selectedModel && (
        <div className={classes.model_details_container}>
          <ModelDetails model={selectedModel} />
        </div>
      )}

      <RecentSimulationsContent showHeader={selectedModel !== null} />
      {!selectedModel && (
        <>
          <Divider />
          <Welcome isNewUser={userHasOwnModels === false} />
        </>
      )}
    </div>
  );
};
