import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

export type GetTokenStatusResponse = {
  reservableTokens: number;
  reservedTokens: number;
  tokensLeftInPackages: number;
};

const MAX_RETRIES = 10;

const getTokenStatus = async (organizationId: string) => {
  const { data } = await axios.post<GetTokenStatusResponse>(
    `/api/Token/GetTokenStatus`,
    {
      organizationId,
    },
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

/** Check if there is a difference between previous data and newly fetched data (in case of ongoing refetch interval)  */
const compareData = (previousData: GetTokenStatusResponse, newData: GetTokenStatusResponse) => {
  return previousData.tokensLeftInPackages === newData.tokensLeftInPackages;
};

export const useGetTokenStatus = (
  organizationId?: string,
  enabled = true,
  hasPendingChanges = false,
  onTokenChangeRegistered?: () => void
) => {
  const [previousData, setPreviousData] = useState<GetTokenStatusResponse | null>(null);
  const retryCountRef = useRef(0);

  return useQuery(['token-status', organizationId], () => getTokenStatus(organizationId!), {
    onSuccess: (data) => {
      setPreviousData(data);
    },
    enabled: !!organizationId && enabled,
    refetchOnWindowFocus: false,
    onError: () => toast.error('An error occurred while retrieving token status!'),
    refetchInterval:
      hasPendingChanges && onTokenChangeRegistered
        ? (data) => {
            // If the cached data is different from the new data or we have already waited for 20 sec then we stop polling
            if ((previousData && data && !compareData(previousData, data)) || retryCountRef.current >= MAX_RETRIES) {
              onTokenChangeRegistered();
              retryCountRef.current = 0;

              return false;
            }
            // We wait for 2 sec before polling again
            else {
              retryCountRef.current += 1;
              return 2000;
            }
          }
        : false,
  });
};
