import { useEffect } from 'react';

import { useModelContext } from '@/context/ModelContext';

import { useAuralizerContext } from '@/components/Auralizer/AuralizerContext';
import { ActionType, usePresetContext } from '@/components/Auralizer/PresetContext';
import { useGetAuralizationPresetsForSpace } from './useGetAuralizationPresetsForSpace';

import { toSorted } from '@/utils/trebleFunctions';

import { AuralizationPresetDto } from '../types';

export const useGetAuralizationPresets = () => {
  const { modelInformation } = useModelContext();
  const { hasInitialized } = useAuralizerContext();
  const { dispatch } = usePresetContext();

  const { data: presets } = useGetAuralizationPresetsForSpace(modelInformation?.spaceId || '');

  useEffect(() => {
    if (presets && hasInitialized) {
      const orderedPresets = toSorted(presets, (presetA: AuralizationPresetDto, presetB: AuralizationPresetDto) => {
        return new Date(presetA.createdAt) > new Date(presetB.createdAt) ? -1 : 1;
      });
      dispatch({ type: ActionType.SET_AVAILABLE_PRESETS, presets: orderedPresets });
    }
  }, [presets, hasInitialized]);
};
