import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

const cancelSpaceExtractionTask = async (spaceExtractionTaskId: string) => {
  const { data } = await axios.patch(`/api/SpaceExtraction/CancelSpaceExtractionTask`, null, {
    params: {
      spaceExtractionTaskId,
    },
  });
  return data;
};

export const useCancelSpaceExtractionTask = () => {
  return useMutation(async (spaceExtractionTaskId: string) => await cancelSpaceExtractionTask(spaceExtractionTaskId));
};
