import { FC } from 'react';

import { Marker } from '@/components/SourceRecieverSettings/Marker';
import { SelectItem } from './SelectItem';
import { SumMarker } from './SumMarker';

import { SummedSourceOption } from '../../types';
import { BaseType } from '@/types';

type RenderedValueProps = {
  values: string[];
  availableSources: BaseType[];
  availableSummedSources: SummedSourceOption[];
};

export const RenderedValue: FC<RenderedValueProps> = ({ values, availableSources, availableSummedSources }) => {
  if (!values.length) {
    return <em>Select source</em>;
  } else {
    const isSummedSource = values.length > 1; // In case of summed sources, values is an array of source ids

    let selectedOptionIndex: number;
    let selectedOption: BaseType | SummedSourceOption | undefined;

    if (isSummedSource) {
      selectedOptionIndex = availableSummedSources.findIndex(
        (summedSource) =>
          summedSource.sourceIds.length === values.length &&
          summedSource.sourceIds.every((sourceId) => values.includes(sourceId))
      );
      selectedOption = selectedOptionIndex > -1 ? availableSummedSources[selectedOptionIndex] : undefined;
    } else {
      selectedOptionIndex = availableSources.findIndex((source) => source.id === values[0]);
      selectedOption = selectedOptionIndex > -1 ? availableSources[selectedOptionIndex] : undefined;
    }

    if (selectedOption === undefined) {
      return <em>Select source</em>;
    }

    return (
      <SelectItem
        label={selectedOption?.name ?? ''}
        icon={
          isSummedSource ? (
            <SumMarker order={selectedOptionIndex + 1} />
          ) : (
            <Marker color={'green'} label={(selectedOptionIndex + 1).toString()} small />
          )
        }
      />
    );
  }
};
