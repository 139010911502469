import { CircularProgress } from '@mui/material';

import styles from './styles.module.scss';

export const LoadingSpinner = () => {
  return (
    <div className={styles['spinner-container']}>
      <CircularProgress />
    </div>
  );
};
