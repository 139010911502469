import { useMutation } from '@tanstack/react-query';

import { Simulation } from '@/types';

import axios from '@/axios';

const createSimulation = async (simulation: Simulation): Promise<Simulation> => {
  const { data } = await axios.post<Simulation>(`/api/Simulation/Create`, simulation);
  return data;
};

export const useCreateSimulation = () => {
  return useMutation(async (simulation: Simulation) => await createSimulation(simulation), {
    onSuccess: (data) => data,
  });
};
