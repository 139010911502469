import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

export type UpdateUserProductAccessRequest = {
  userId: string;
  organizationId: string;
  isEnabled: boolean;
};

const updateUserProductAccess = async (userId: string, isEnabled: boolean) => {
  const { data } = await axios.post(`/api/Subscription/UpdateUserProductAccess`, null, {
    params: {
      targetUserId: userId,
      product: 'TASS',
      enabled: isEnabled,
    },
    baseURL: adminApiUrl,
  });

  return data;
};

export const useUpdateUserProductAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (request: UpdateUserProductAccessRequest) => await updateUserProductAccess(request.userId, request.isEnabled),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['product-accesses', variables.organizationId]);
        queryClient.invalidateQueries(['subscription', variables.organizationId]);
      },
      onError: () => toast.error(`An error occurred while updating user's product access`),
    }
  );
};
