// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSearchForString = (originalItems: any[], attrToFilter: string[]) => {
  const searchForString = (key: string, inputValue: string) => {
    if (/^[ -~]+$/i.test(key) && inputValue.length > 1 && originalItems) {
      const parsedVal = inputValue.toLowerCase();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filteredItems = originalItems.filter((item: any) => {
        const parsedArray: string[] = attrToFilter.reduce((acc: string[], curr: string | string[]) => {
          if (Array.isArray(item[curr as string])) {
            return [...acc, ...item[curr as string].flat().map((item: string) => item.toLowerCase())];
          } else {
            return [...acc, item[curr as string].toLowerCase()];
          }
        }, []);
        return parsedArray.some((element) => element.includes(parsedVal));
      });
      return filteredItems;
    } else {
      return originalItems;
    }
  };

  return searchForString;
};
