import dayjs from 'dayjs';

import {
  TrblArrowThinRight,
  TrblCancelledIcon,
  TrblCompletedDottedIcon,
  TrblCompletedIcon,
  TrblErrorIcon,
  TrblInprogressIcon,
} from '../Icons';
import { TrblTooltip } from '../Shared';

import { Task } from '@/types';

import classes from './styles.module.scss';

interface RecentUploadStatusDisplayProps {
  task: Task;
  uploadStatus?: string;
  hasBeenImported?: boolean;
  goNext: () => void;
}

export const RecentUploadStatusDisplay = ({
  task,
  uploadStatus,
  hasBeenImported,
  goNext,
}: RecentUploadStatusDisplayProps) => {
  return (
    <div className={classes.row}>
      <div className={classes.status}>
        {(!uploadStatus || uploadStatus === 'Created') && (
          <>
            <TrblInprogressIcon />
            <p className={classes.status_text}>
              Created
              {` `}
              {dayjs(task.createdAt).format('MMM DD HH:mm')}
            </p>
          </>
        )}

        {(uploadStatus === 'InProgress' || uploadStatus === 'Pending' || uploadStatus === 'ProcessingResults') && (
          <>
            <TrblInprogressIcon />
            <p className={classes.status_text}>
              In progress
              <span title="Started at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(task.createdAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
        {uploadStatus === 'Completed' && (
          <>
            {hasBeenImported ? <TrblCompletedIcon /> : <TrblCompletedDottedIcon />}

            <p className={classes.status_text}>
              {hasBeenImported ? 'Import completed' : 'Ready for import'}
              <span title="Finished at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(task.completedAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
        {uploadStatus === 'Cancelled' && (
          <>
            <TrblCancelledIcon />
            <p className={classes.status_text}>
              Cancelled
              <span title="Started at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(task.createdAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
        {(uploadStatus === 'Error' || uploadStatus === 'TaskError') && (
          <>
            <TrblErrorIcon />
            <p className={classes.status_text}>
              Error
              <span title="Errored at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(task.completedAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
      </div>
      {uploadStatus === 'Completed' && !hasBeenImported && (
        <div className={classes.button_container}>
          <TrblTooltip title="Import model">
            <span style={{ float: 'right' }}>
              <button onClick={goNext} className={`${classes.quick_btn} ${classes.stroke_hover}`}>
                <TrblArrowThinRight fill="#999999" />
              </button>
            </span>
          </TrblTooltip>
        </div>
      )}
    </div>
  );
};
