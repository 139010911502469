import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { ActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

import { SelectedDetails } from '@/context/EditorContext/types';

export const useMultiSelectLayers = () => {
  const { dispatch, multiSelectedItemIds } = useLibraryPanelContext();
  const {
    simulationState: { surfaceLayers },
  } = useSimulationContext();
  const { dispatch: edDispatch, multiSelected } = useEditorContext();

  const multiSelectLayers = (layerIds: string[]) => {
    const [allLayerIds, selectedDetails]: [string[], SelectedDetails[]] = layerIds.reduce(
      (acc: [string[], SelectedDetails[]], layerId: string): [string[], SelectedDetails[]] => {
        const currentLayer = surfaceLayers.find((layer) => layer.id === layerId);
        const layerChildrenIds: string[] =
          currentLayer && currentLayer?.children.length > 1 ? currentLayer?.children.map((child) => child.id) : [];

        const newLayers = [];
        if (currentLayer?.type === 'LayerGroup') {
          newLayers.push({ type: 'LayerGroup', id: currentLayer.id });
          if (currentLayer.children.length > 1) {
            newLayers.push(
              ...currentLayer.children.map((layer) => ({
                type: 'Layer',
                id: layer.id,
              }))
            );
          }
        } else {
          newLayers.push({ type: 'Layer', id: layerId });
        }
        return [[...acc[0], ...layerChildrenIds] as string[], [...acc[1], ...newLayers] as SelectedDetails[]];
      },
      [[], []]
    );

    const newMultiSelectedItems = new Set([...allLayerIds, ...multiSelectedItemIds, ...layerIds]);
    dispatch({
      type: ActionType.SET_MULTI_SELECT_ITEMS,
      multiSelectedItemIds: Array.from(newMultiSelectedItems),
    });
    edDispatch({
      type: EdActionType.SET_MULTI_SELECTED,
      multiSelected: [...multiSelected, ...selectedDetails],
    });
    edDispatch({
      type: EdActionType.SET_FOCUS_ITEM,
      focusItem: layerIds[0],
    });
  };

  return multiSelectLayers;
};
