import { RunStatus } from '@/types';

export const getProgressMessages = (simulationStatus?: RunStatus | string) => {
  switch (simulationStatus) {
    case RunStatus.InProgress:
      return 'In progress';
    case RunStatus.ProcessingResults:
      return 'processing results';
    case RunStatus.Queued:
      return 'queued';
    // "Pending" status is not part of RunStatus, not sure why we are checking for it?
    case 'Pending':
      return 'Pending';
    default:
      return '';
  }
};
