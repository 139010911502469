import { datadogRum } from '@datadog/browser-rum';

import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';

import { pauseAudioElements } from '../utils';

export const useCreateGainNodes = () => {
  const { audioElementDictionary, dispatch } = useAuralizerContext();

  const createGainNodes = (sourceIdList: string[]) => {
    const audioEngine = AudioEngine.getInstance();

    pauseAudioElements(audioElementDictionary);

    const newAudioElementDictionary: { [key: string]: HTMLAudioElement } = {};
    try {
      sourceIdList.forEach((sourceId) => {
        const newAudioElement = createAudioElement(sourceId);
        newAudioElementDictionary[sourceId] = newAudioElement;

        const { sourceGain, bypassInputGain } = setupAudioNodes(audioEngine, newAudioElement);

        audioEngine.bypassInputGainDict[sourceId] = bypassInputGain;
        audioEngine.sourceGainDictionary[sourceId] = sourceGain;
        audioEngine.storedReceiverGain[sourceId] = bypassInputGain;
      });

      dispatch({ type: ActionType.SET_AUDIO_ELEMENT_DICT, audioElementDictionary: newAudioElementDictionary });
    } catch (error) {
      datadogRum.addError(`An error occurred while creating gain: ${error}`);
    }
  };

  return createGainNodes;
};

// Function to create and configure a new audio element
const createAudioElement = (sourceId: string) => {
  const audioElement = document.createElement('audio');
  audioElement.id = `audio-${sourceId}`;
  audioElement.loop = true;
  audioElement.crossOrigin = 'anonymous';
  return audioElement;
};

// Function to configure audio gain nodes
const configureAudioGain = (audioContext: AudioContext, gainValue: number, channelCount?: number) => {
  const gainNode = audioContext.createGain();
  gainNode.gain.value = gainValue;
  if (channelCount) {
    gainNode.channelCount = channelCount;
  }
  return gainNode;
};

// Function to set up audio nodes for a given source ID
const setupAudioNodes = (audioEngine: AudioEngine, audioElement: HTMLAudioElement) => {
  const audioContext = audioEngine.audioContext;
  const bypassInputGain = configureAudioGain(audioContext, 0.25);
  const sourceGain = configureAudioGain(audioContext, 1, 1);

  const audioElementSource = audioContext.createMediaElementSource(audioElement);

  audioElementSource.connect(sourceGain);
  sourceGain.connect(bypassInputGain);
  bypassInputGain.connect(audioEngine.soaRenderer.input);

  return { audioElementSource, sourceGain, bypassInputGain };
};
