import { SyntheticEvent, useEffect, useState } from 'react';

import { ProjectGroupHeader } from './ProjectGroupHeader';
import { SpaceCard } from './SpaceCard';

import { useGetSpacesByProjectId } from '@/hooks';

import { IActions, ProjectAndUsersDto, SpaceDetailsDto } from '@/types';

import classes from '../styles.module.scss';

interface ProjectGroupProps {
  project: ProjectAndUsersDto;
  viewType?: string;
  loggedInUserId: string;
  getSpaceActions: (space: SpaceDetailsDto) => IActions[];
  getProjectActions: (project: ProjectAndUsersDto) => IActions[];
  onSpacesLoaded: (spaces: SpaceDetailsDto[]) => void;
  isMinimized: boolean;
  projectSearchString: string;
}

export const ProjectGroup = ({
  project,
  viewType = 'cards',
  loggedInUserId,
  getSpaceActions,
  getProjectActions,
  onSpacesLoaded,
  isMinimized,
  projectSearchString,
}: ProjectGroupProps) => {
  const { data: spaces } = useGetSpacesByProjectId(project.id);
  const [minimized, setMinimized] = useState(isMinimized);

  useEffect(() => {
    if (spaces !== undefined) {
      onSpacesLoaded(spaces);
    }
  }, [spaces]);

  useEffect(() => {
    setMinimized(isMinimized);
  }, [isMinimized]);

  const handleSetMinimized = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    // hack to not minimize the Project if the action menu has been opened, checking if the
    // element clicked has the css style of Mui backdrop for menus
    if (!target.classList.contains('MuiBackdrop-root')) {
      setMinimized(!minimized);
    }
  };

  return (
    <div id={project.id} className={`${classes.project_group} ${minimized ? classes.minimized : ''}`}>
      <ProjectGroupHeader
        project={project}
        loggedInUserId={loggedInUserId}
        getProjectActions={getProjectActions}
        handleSetMinimized={handleSetMinimized}
        projectSearchString={projectSearchString}
      />
      <div className={classes.project_spaces}>
        {spaces?.length
          ? spaces.map((space) => (
              <SpaceCard
                viewType={viewType}
                key={space.id}
                id={space.id}
                modelCount={space.modelCount}
                title={space.name}
                spaceActions={getSpaceActions(space)}
                // set tabIndex as -1 for SpaceCard if project is minimized, so it doesn't selected when tabbing
                tabIndex={minimized ? -1 : 0}
                projectSearchString={projectSearchString}
              />
            ))
          : null}
      </div>
    </div>
  );
};
