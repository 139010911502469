import React, { useCallback, useRef, useState } from 'react';
import { Figure } from 'react-plotly.js';
import { Layout } from 'plotly.js';

import { useMediaQuery } from '@mui/material';

import { TabButtons } from '@/components/Shared/TabButtons';
import {
  FrequencyWeightingType,
  ReflectionDetailsWithRelativeAngles,
} from '@/components/Results/context/ReflectogramResultsContext/types';
import { PolarPlot } from './PolarPlot';

import { useDbAxisRange, usePolarPlotClickHandler, usePolarPlotData } from './hooks';

import { TimeOfArrivalGroup } from '../../SubHeaders/ReflectogramResultsHeader/constants';

import { shouldUpdateLayout } from './utils';

import classes from './styles.module.scss';

type ReflectionAnglesProps = {
  data: ReflectionDetailsWithRelativeAngles[];
  selectedReflectionIndex: number | null;
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedFrequencyBandIndex: number | null;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
  onSelectReflection: (index: number) => void;
};

export const ReflectionAnglePlots: React.FC<ReflectionAnglesProps> = ({
  data,
  selectedReflectionIndex,
  selectedFrequencyWeighting,
  selectedFrequencyBandIndex,
  timeOfArrivalGroups,
  onSelectReflection,
}) => {
  const plotContainerRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<Partial<Layout>>({});
  const isSmallHeightScreen = useMediaQuery('(max-height: 1000px)');
  const [selectedAngleType, setSelectedAngleType] = useState<string>('azimuth');

  const dbAxisRange = useDbAxisRange(data, selectedFrequencyWeighting, selectedFrequencyBandIndex);
  const polarPlotDataPoints = usePolarPlotData(
    data,
    selectedReflectionIndex,
    selectedFrequencyWeighting,
    selectedFrequencyBandIndex,
    selectedAngleType,
    timeOfArrivalGroups,
    dbAxisRange
  );

  const handlePlotClick = usePolarPlotClickHandler(
    data,
    (layout.polar?.radialaxis?.range as [number, number]) ?? null,
    selectedFrequencyWeighting,
    selectedFrequencyBandIndex,
    selectedAngleType,
    plotContainerRef,
    onSelectReflection
  );

  const handleInitializePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout(figure.layout);
  }, []);

  const handleUpdatePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout((prevLayout) => (shouldUpdateLayout(prevLayout, figure.layout) ? figure.layout : prevLayout));
  }, []);

  return (
    <div className={classes['plot-container']}>
      <div className={classes['plot-header']}>
        <div className={classes['tabs-container']}>
          <TabButtons
            small={isSmallHeightScreen}
            options={[
              { key: 'azimuth', label: 'Azimuth' },
              { key: 'elevation', label: 'Elevation' },
            ]}
            selectedValue={selectedAngleType}
            onChange={setSelectedAngleType}
          />
        </div>
      </div>
      <div className={classes['plot']} onClick={handlePlotClick} ref={plotContainerRef}>
        <PolarPlot
          plotData={polarPlotDataPoints}
          dbAxisRange={dbAxisRange}
          selectedFrequencyWeighting={selectedFrequencyWeighting}
          isSmallHeightScreen={isSmallHeightScreen}
          onInitialized={handleInitializePlot}
          onUpdate={handleUpdatePlot}
        />
      </div>
    </div>
  );
};
