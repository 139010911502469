import { useEffect, useState } from 'react';

import { TextArea } from '@/components/Shared/TextArea';
import { SpaceInfoParams } from '../../MultiSpaceImportStep2';

import styles from '../../styles.module.scss';

export const Description = ({
  isExpanded,
  index,
  description,
  existingSpace,
  updateAllSpacesInfo,
}: {
  isExpanded: boolean;
  index: number;
  description?: string;
  existingSpace: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAllSpacesInfo: (value: any, param: SpaceInfoParams, index: number) => void;
}) => {
  const [thisDescription, setThisDescription] = useState(description);

  useEffect(() => {
    setThisDescription(description);
  }, [description]);

  const handleUpdateDescription = (value: string) => {
    if (value && value !== description) {
      updateAllSpacesInfo(value, 'description', index);
    }
  };

  return (
    <div className={styles['space-card-extra-content']}>
      <TextArea
        label="Space description"
        value={thisDescription}
        onChange={setThisDescription}
        onBlur={handleUpdateDescription}
        disabled={existingSpace}
        title={existingSpace ? 'Cannot edit description of existing space' : ''}
        style={{ height: '100%' }}
        tabIndex={isExpanded ? 0 : -1}
      />
    </div>
  );
};
