import { FC, useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { ArrowHelper, Color, Euler, Material, MathUtils, Vector3 } from 'three';

const ARROW_LENGTH_SCALE = 0.075;

type ArrowProps = {
  azimuth: number;
  elevation: number;
  color: string;
};
export const Arrow: FC<ArrowProps> = ({ azimuth, elevation, color }) => {
  const arrowHelperRef = useRef<ArrowHelper>(null!);
  const [rotation, setRotation] = useState(new Euler());

  useEffect(() => {
    const aroundZ = MathUtils.degToRad(azimuth - 90);
    const aroundX = MathUtils.degToRad(elevation);
    const eulerRot = new Euler(aroundX, 0, aroundZ, 'ZXY');
    setRotation(eulerRot);
  }, [azimuth, elevation]);

  useFrame(({ camera }) => {
    const currentDistance = camera.position.distanceTo(arrowHelperRef.current.position);
    const arrowLength = currentDistance * ARROW_LENGTH_SCALE;
    arrowHelperRef.current.setLength(arrowLength, undefined, arrowLength * 0.1);
    arrowHelperRef.current.setRotationFromEuler(rotation);
    (arrowHelperRef.current.line.material as Material).depthTest = false;
  });

  return <arrowHelper ref={arrowHelperRef} args={[new Vector3(0, 1, 0), undefined, 0, new Color(color)]} />;
};
