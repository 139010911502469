import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

export type MissingSourceDefinitionInfo = {
  sourceDefinitionId: string;
  sourceDefinitionName: string;
  isSharedWithOrganization: boolean;
  isDeleted: boolean;
};

const getMissingSourceDefinitionInfo = async (
  sourceDefinitionIds: string[]
): Promise<MissingSourceDefinitionInfo[]> => {
  const { data } = await axios.post(`/api/SourceDefinition/GetMissingSourceDefinitionInfo`, {
    missingSourceDefinitionIds: sourceDefinitionIds,
  });

  return data;
};

export const useGetMissingSourceDefinitionInfo = (sourceDefinitionIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any>(
    ['missing-source-definition-info', sourceDefinitionIds],
    () => getMissingSourceDefinitionInfo(sourceDefinitionIds),
    { enabled: sourceDefinitionIds.length > 0, staleTime: 1000 * 60 * 5 }
  );
};
