import { useSimulationContext } from '@/context/SimulationContext';

import { useSabineEstimate } from '@/components/Editor/hooks/useSabineEstimate';

import { defaultEnergyDecayThreshold } from '@/utils/constants';

const ESTIMATION_FACTORS = [1.1, 1.1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0];
const MIN_LENGTH = 0.4;
const BUFFER_TIME = 0.15;

export const useEstimatedImpulseLength = () => {
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();

  const calculateSabineEstimate = useSabineEstimate();

  // This is a formula from Jesper Pedersen to calculate the estimated Impulse response length
  // based on Materials on each layer (sabineEstimate) and the Energy decay threshold and some constants he has put in
  const estimateImpulseLength = (
    materialIdByObjectId: { [key: string]: string },
    newEnergyDecayThreshold?: number | null
  ) => {
    const sabineEstimate = calculateSabineEstimate(materialIdByObjectId);
    window.sabineEstimate = sabineEstimate;

    if (sabineEstimate) {
      const estimates = sabineEstimate.map((Sabine, i) => +(Sabine * ESTIMATION_FACTORS[i]).toFixed(2));

      let energy_decay_threshold = newEnergyDecayThreshold;

      // if newEnergyDecayThreshold was not set, then use EDT from selected simulation, or default
      if (energy_decay_threshold === undefined) {
        energy_decay_threshold =
          selectedSimulation!.solverSettings.dgSettings.energyDecayThreshold ?? defaultEnergyDecayThreshold;
      }
      // if newEnergyDecayThreshold was set, but is null, then use default
      else if (energy_decay_threshold === null) {
        energy_decay_threshold = defaultEnergyDecayThreshold;
      }

      const IR = Math.min(Math.max(Math.max(...estimates), MIN_LENGTH), 10);

      let new_estimate: number | undefined =
        (IR - BUFFER_TIME) * (energy_decay_threshold / defaultEnergyDecayThreshold) + BUFFER_TIME;

      new_estimate = Number(new_estimate.toFixed(3));

      // catch for if materials are not loaded, this value can become NaN, and NaN is not handled, but undefined is a handled
      if (isNaN(new_estimate)) new_estimate = undefined;

      return new_estimate;
    }
  };
  return estimateImpulseLength;
};
