import { TrblTreeBranch } from './TrblTreeBranch';

import { TreeBranch, TreeStructure } from './types';

import styles from './styles.module.scss';

export const TrblTreeView = ({
  treeStructure,
  onSelectItem,
  filter,
}: {
  treeStructure: TreeStructure;
  onSelectItem: (structure: TreeBranch) => void;
  filter: string;
}) => {
  return (
    <ul className={styles.tree_structure}>
      <li>
        {Object.keys(treeStructure).map((key) => (
          <TrblTreeBranch
            key={`${key}-${treeStructure[key].isOpen}`}
            filter={filter}
            structure={treeStructure[key]}
            onSelectItem={onSelectItem}
          />
        ))}
      </li>
    </ul>
  );
};
