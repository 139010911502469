import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType as MeshActionType, useMeshContext } from '@/context/MeshContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box, CircularProgress } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { TrblIcon, TrblMinimizeIcon } from '@/components/Icons';
import { TrblTooltip } from '../Shared';
import { ConfirmNewRunPopup } from './ConfirmNewRunPopup';
import { SimulationRunDetails } from './SimulationRunDetails';

import { useUpdateSimulationAndSettings } from '../EditSimulation/hooks/useUpdatedSimulationAndSettings';
import { startMeshTask } from '../SolverSettings/hooks/useStartMeshTask';
import {
  useCancelSimulationRun,
  useGetSimulationById,
  useGetSimulationRunStatusById,
  useStartSolveTask,
} from '@/hooks';

import { maxMeshElements, maxMeshElementsMessage } from '@/utils/constants';

import { mapSimulationRunStatusToSimulationRunDetails, mapSimulationRunToSimulationRunDetails } from './utils';
import { getSimStatus } from '@/utils/getSimStatus';
import { roundFloat } from '@/utils/trebleFunctions';

import { SimulationRunDetails as SimulationRunDetailsType } from './types';
import { ActiveMeshTask, SimulationTimeEstimate } from '@/context/MeshContext/types';
import { RunStatus, Simulation, SimulationRunDto, TaskType } from '@/types';

type RunSimulationPopupProps = {
  selectedSimulation: Simulation;
  activeSimulationRun: SimulationRunDto | null;
  onClose: () => void;
};

export const RunSimulationPopup: FC<RunSimulationPopupProps> = ({
  selectedSimulation,
  activeSimulationRun,
  onClose,
}) => {
  const [simulationRunDetails, setSimulationRunDetails] = useState<SimulationRunDetailsType | null>(
    activeSimulationRun != null ? mapSimulationRunToSimulationRunDetails(activeSimulationRun) : null
  );
  const { activeMeshTasks, currentSimTimeEstimate, currentMeshResult, meshHasError } = useMeshContext();
  const { dispatch } = useSimulationContext();
  const { dispatch: meshDispatch } = useMeshContext();
  const { mutate: updateSimulationAndSettings } = useUpdateSimulationAndSettings();

  const isGAOnly = selectedSimulation.taskType == 'GA';
  const [estimatedSimulationTime, setEstimatedSimulationTime] = useState<string | null>(
    currentSimTimeEstimate?.simulationTimeString || null
  );
  const [estimatedCPUHours, setEstimatedCPUHours] = useState<number | null>(currentSimTimeEstimate?.cpuHours || null);

  const { data: newSimulationObject, refetch: refetchSimulation } = useGetSimulationById(selectedSimulation.id, false);

  const [meshTaskId, setMeshTaskId] = useState<string | null>(selectedSimulation.extra?.meshTaskId || null);
  const [showRunConfirmation, setShowRunConfirmation] = useState(false);
  const [needConfirm, setNeedConfirm] = useState(false);
  const [tooManyMeshElements, setTooManyMeshElements] = useState(false);
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const [waitingToStart, setWaitingToStart] = useState(false);
  const [minimizePopup, setMinimizePopup] = useState(false);

  const { data: simulationRunStatus } = useGetSimulationRunStatusById(
    simulationRunDetails?.id || '',
    simulationRunDetails?.inProgress || false,
    3000
  );

  useEffect(() => {
    if (selectedSimulation?.lastSimulationRun?.status === RunStatus.Completed) {
      setNeedConfirm(true);
    } else {
      setNeedConfirm(false);
    }
  }, [selectedSimulation]);

  useEffect(() => {
    if (selectedSimulation.extra?.meshTaskId && currentSimTimeEstimate) {
      handleEstimationCompleted(
        selectedSimulation.extra?.meshTaskId,
        currentSimTimeEstimate,
        selectedSimulation.solverSettings.dgSettings.crossoverFrequency
      );
    }
  }, [
    selectedSimulation.extra.meshTaskId,
    currentSimTimeEstimate,
    selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
  ]);

  useEffect(() => {
    if (simulationRunStatus) {
      const runDetails = mapSimulationRunStatusToSimulationRunDetails(simulationRunStatus, selectedSimulation.taskType);
      setSimulationRunDetails(runDetails);
      if (!runDetails.inProgress) {
        refetchSimulation();
      }
    }
  }, [simulationRunStatus]);

  const { mutate: startSolveTask } = useStartSolveTask();
  const { mutate: cancelSimulationRun } = useCancelSimulationRun();

  useEffect(() => {
    if (newSimulationObject?.lastSimulationRun) {
      dispatch({
        type: ActionType.SET_LAST_SIMULATION_RUN,
        simulationRun: newSimulationObject.lastSimulationRun,
      });
    }
  }, [newSimulationObject]);

  const handleStartSimulation = () => {
    // Update hasBeenEdited flag so the user gets prompted again
    // if they want to edit their simulation or not
    updateSimulationAndSettings(
      { ...selectedSimulation, hasBeenEdited: false },
      {
        onSuccess: () => {
          dispatch({
            type: ActionType.UPDATE_SELECTED_SIMULATION,
            simulation: { ...selectedSimulation, hasBeenEdited: false },
          });
          setSimulationRunDetails({
            id: null,
            inProgress: false,
            progressBarColor: 'primary',
            statusText: 'Starting',
            showEllipsis: true,
            percentage: null,
            createdAt: null,
            completedAt: null,
            timeRemainingText: null,
            showRemaining: false,
            sourceDetails: [],
          });
          getSimStatus(selectedSimulation, 1);
          setWaitingToStart(true);
          startSolveTask(
            {
              simulationId: selectedSimulation.id,
              taskType: selectedSimulation.taskType as TaskType,
              sources: selectedSimulation.sources,
              sourceParameters: selectedSimulation.sourceParameters,
              receivers: selectedSimulation.receivers,
              gridReceivers: selectedSimulation.gridReceivers,
              modelSettings: selectedSimulation.modelSettings!,
              solverSettings: selectedSimulation.solverSettings!,
              settingsPreset: selectedSimulation.settingsPreset,
              meshTaskId: meshTaskId || undefined,
            },
            {
              onSuccess: (simulationRun) => {
                setWaitingToStart(false);
                if (simulationRun) {
                  if (simulationRun?.status !== RunStatus.InsufficientTokens) {
                    toast.info('Simulation run started', { className: 'editor-toast', autoClose: 3000 });
                  }

                  setSimulationRunDetails(mapSimulationRunToSimulationRunDetails(simulationRun));
                  dispatch({
                    type: ActionType.SET_LAST_SIMULATION_RUN,
                    simulationRun: simulationRun,
                  });
                } else {
                  toast.error('An error occurred while starting the simulation');
                  throw 'Error';
                }
              },
              onError: () => {
                updateSimulationAndSettings({ ...selectedSimulation, hasBeenEdited: true });
                dispatch({
                  type: ActionType.UPDATE_SELECTED_SIMULATION,
                  simulation: { ...selectedSimulation, hasBeenEdited: true },
                });
                setWaitingToStart(false);
                toast.error('An error occurred while starting the simulation');
              },
            }
          );
        },
      }
    );
  };

  const handleCancelSimulation = () => {
    if (simulationRunDetails?.id) {
      setCancelInProgress(true);
      cancelSimulationRun(simulationRunDetails.id);
    }
  };

  const startNewEstimation = async (modelId: string) => {
    const meshTask = await startMeshTask(selectedSimulation.solverSettings.dgSettings.crossoverFrequency, modelId);
    const newActiveMeshTasks = [
      ...activeMeshTasks,
      {
        id: meshTask.id,
        transitionFrequency: meshTask.crossoverFrequency,
      },
    ];
    meshDispatch({
      type: MeshActionType.SET_ACTIVE_MESH_TASKS,
      activeMeshTasks: newActiveMeshTasks,
    });
  };

  // If we don't have the estimation we trigger a new mesh task when we open the popup, only for DG and Hybrid jobs
  useEffect(() => {
    if (!isGAOnly) {
      if (
        !meshHasError &&
        estimatedSimulationTime === null &&
        activeMeshTasks.findIndex(
          (x: ActiveMeshTask) =>
            x.transitionFrequency === selectedSimulation.solverSettings.dgSettings.crossoverFrequency
        ) === -1
      ) {
        startNewEstimation(selectedSimulation?.modelId);
      } else if (meshHasError) {
        setEstimatedSimulationTime(null);
      }
    }
  }, []);

  const handleEstimationCompleted = (
    meshTaskId: string,
    estimatedSimulationTime: SimulationTimeEstimate,
    transitionFrequency: number
  ) => {
    if (
      estimatedSimulationTime &&
      transitionFrequency === selectedSimulation.solverSettings.dgSettings.crossoverFrequency
    ) {
      setEstimatedSimulationTime(estimatedSimulationTime.simulationTimeString);
      setEstimatedCPUHours(estimatedSimulationTime.cpuHours);
      setMeshTaskId(meshTaskId);
      if (currentMeshResult && currentMeshResult.elementCount > maxMeshElements) {
        setTooManyMeshElements(true);
        toast.warning(maxMeshElementsMessage);
      }
    } else if (estimatedSimulationTime === null) {
      setEstimatedSimulationTime(null);
    }
  };

  const onClosePopup = () => {
    setMinimizePopup(true);
    onClose();
  };

  return (
    <>
      <TrblPopup
        className={minimizePopup ? 'minimize-popup' : ''}
        transitionDuration={{ exit: 800 }}
        width={420}
        maxheight={600}
        aria-labelledby={'Simulation run'}
        onClose={!waitingToStart ? onClosePopup : undefined}
        open={!minimizePopup}>
        <TrblPopupTitle onClose={onClosePopup} disabled={waitingToStart} closeIcon={<TrblMinimizeIcon />}>
          Simulation run
        </TrblPopupTitle>
        <TrblPopupContent>
          <Box component="div" mb="20px" mt="-4px">
            <Text type="bold-12px">{selectedSimulation.name}</Text>
          </Box>
          {!simulationRunDetails ? (
            <>
              <Box component="div" display="flex">
                {!isGAOnly && (
                  <Box component="div" display="flex" flexDirection="column" gap="7px" width="100%">
                    <Text type="semibold-11px">Transition frequency: </Text>
                    <Text type="regular-11px" numberFontStyleEnabled>
                      {selectedSimulation.solverSettings.dgSettings.crossoverFrequency} Hz
                    </Text>
                  </Box>
                )}
                <Box component="div" display="flex" flexDirection={!isGAOnly ? 'column' : 'row'} gap="7px" width="100%">
                  <Text type="semibold-11px">Impulse response length:</Text>
                  <Text type="regular-11px" numberFontStyleEnabled>
                    {selectedSimulation.solverSettings.dgSettings.impulseLengthSeconds} s
                    {selectedSimulation.solverSettings.dgSettings.energyDecayThreshold !== null && (
                      <span style={{ color: '#999999' }}> (Estimated)</span>
                    )}
                  </Text>
                </Box>
              </Box>

              <Box component="div" mt="20px" mb="-12px" display="flex" gap="12px" flexDirection="column">
                <Box component="div" mb="-4px">
                  <Text type="bold-11px">Estimated: </Text>
                </Box>
                {!estimatedSimulationTime ? (
                  meshHasError && !isGAOnly ? (
                    <>
                      <Box component="div" display="flex" gap="5px" alignItems="self-end">
                        <TrblIcon icon="timewatchUnfilled" />
                        <Text type="medium-11px">Error preparing geometry</Text>
                      </Box>
                      <p style={{ marginTop: '0px', color: '#ffbd59', fontSize: '11px' }}>
                        Failed to prepare geometry for the wave solver. Select 'Geometrical acoustics solver only' in
                        Advanced settings to run a simulation, or contact support.
                      </p>
                    </>
                  ) : (
                    <Box component="div" display="flex" gap="5px" alignItems="self-end">
                      <CircularProgress size={15} />
                      <Text type="medium-11px">Estimating...</Text>
                    </Box>
                  )
                ) : (
                  <>
                    <Box component="div" display="flex" gap="5px" alignItems="self-end">
                      <TrblTooltip title="Runtime">
                        <span>
                          <TrblIcon icon="timewatchUnfilled" />
                        </span>
                      </TrblTooltip>
                      <Text type="medium-11px">{estimatedSimulationTime}</Text>
                    </Box>
                    {estimatedCPUHours && (
                      <Box component="div" display="flex" gap="5px" alignItems="self-end">
                        <TrblTooltip title="Tokens">
                          <span>
                            <TrblIcon icon="timeComputer" />
                          </span>
                        </TrblTooltip>
                        <Text type="medium-11px">
                          {roundFloat(estimatedCPUHours * selectedSimulation.sources.length, 3)} tokens
                        </Text>

                        <TrblTooltip
                          title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {`${estimatedCPUHours} per source (${selectedSimulation.sources.length}) =  ${roundFloat(
                                estimatedCPUHours * selectedSimulation.sources.length,
                                3
                              )} tokens
                              
                              The estimated runtime and required tokens is an approximation of how much computational effort is needed to run the simulation. 
                              
                              Simulation tokens serve as a digital currency in Treble. The estimated token cost indicates the amount deducted from the organization's token pool upon the successful completion of a simulation run.
                              
                              This is only an estimate and the actual token cost of the simulation is what will be registered to the account.
                              
                              Note that since a separate simulation is run for each source, the number of sources affects the token cost.
                              `}
                            </div>
                          }>
                          <span style={{ cursor: 'pointer', width: '11px', margin: '-1px 0 1px -1px' }}>
                            <TrblIcon icon="info" />
                          </span>
                        </TrblTooltip>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <SimulationRunDetails
              statusText={simulationRunDetails.statusText}
              showEllipsis={simulationRunDetails.showEllipsis}
              timeRemainingText={simulationRunDetails.timeRemainingText}
              percentage={simulationRunDetails.percentage}
              progressBarColor={simulationRunDetails.progressBarColor}
              showRemaining={simulationRunDetails.showRemaining}
              createdAt={simulationRunDetails.createdAt}
              completedAt={simulationRunDetails.completedAt}
              sourceDetails={simulationRunDetails.sourceDetails}
            />
          )}
        </TrblPopupContent>
        <TrblPopupActions>
          <Box component="div" display="flex" width="100%" justifyContent={'space-between'}>
            <TrblTooltip title="Cancel simulation run">
              <span>
                <SecondaryButton
                  disabled={cancelInProgress}
                  width={'fit-content'}
                  label="Cancel"
                  sx={{ visibility: !simulationRunDetails?.inProgress ? 'hidden' : undefined }}
                  onClick={handleCancelSimulation}
                />
              </span>
            </TrblTooltip>
            <PrimaryButton
              disabled={(!isGAOnly && !estimatedSimulationTime) || tooManyMeshElements}
              width={'fit-content'}
              label="Start"
              onClick={() => (needConfirm ? setShowRunConfirmation(true) : handleStartSimulation())}
              sx={{ visibility: simulationRunDetails ? 'hidden' : undefined }}
            />
            {simulationRunDetails?.completedAt && !simulationRunDetails?.inProgress && (
              <PrimaryButton width={'fit-content'} label="Ok" onClick={onClosePopup} />
            )}
          </Box>
        </TrblPopupActions>
      </TrblPopup>
      <ConfirmNewRunPopup
        showRunConfirmation={showRunConfirmation}
        setShowRunConfirmation={setShowRunConfirmation}
        runSimulation={handleStartSimulation}
      />
    </>
  );
};
