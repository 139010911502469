import React from 'react';
import { Line } from '@react-three/drei';

type ReflectionPathProps = {
  lineWidth: number;
  points: [number, number, number][];
  color: string;
};

export const ReflectionPath: React.FC<ReflectionPathProps> = ({ lineWidth, points, color }) => {
  return <Line points={points} color={color} lineWidth={lineWidth} />;
};
