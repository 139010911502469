import { useQuery } from '@tanstack/react-query';

import { RunStatus, SimulationRunStatusDto } from '@/types';

import axios from '@/axios';

let refetchInterval = 0;

const isInProgress = (status: RunStatus | null) => {
  if (
    status &&
    [RunStatus.Queued, RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults].includes(status)
  ) {
    return true;
  }
  return false;
};

const getSimulationRunStatuses = async (maxNumberOfResults = 6) => {
  const { data } = await axios.get<SimulationRunStatusDto[]>(`/api/SimulationRun/GetSimulationRunStatusesByUser`, {
    params: {
      maxNumberOfResults,
    },
  });

  return data;
};

export const useGetSimulationRunStatusesByUser = (maxNumberOfResults?: number, enabled = true) =>
  useQuery([], () => getSimulationRunStatuses(maxNumberOfResults), {
    enabled,
    refetchOnWindowFocus: false,
    refetchInterval,
    onSuccess: (data) => {
      let inProgress = false;

      for (const sim of data) {
        inProgress = isInProgress(sim.status);
        if (inProgress) break;
      }

      if (inProgress) {
        refetchInterval = 10000;
      } else {
        refetchInterval = 0;
      }
    },
  });
