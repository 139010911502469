import { useEffect } from 'react';

// hook that registers submit event and removes it when component
// unmounts. 2 props are required; a flag to state if the event
// should register and props for what variables it needs to put into scope
export const useSubmitEvent = (shouldRegisterEvent: boolean, props: string[]) => {
  useEffect(() => {
    if (shouldRegisterEvent) {
      window.addEventListener('submit', handleSubmit);

      return () => {
        window.removeEventListener('submit', handleSubmit);
      };
    }
  }, [shouldRegisterEvent, ...props]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };
};
