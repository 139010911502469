import { useEffect, useState } from 'react';

import { IconButton } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblCloseIcon, TrblUploadIcon, TrblUploadInProgressIcon } from '../../../Icons';

import { SpaceExtractionTask } from '../../types';
import { TaskStatus } from '@/types';

import styles from '../../styles.module.scss';

export const StatusNav = ({
  isSidebarActive,
  setIsSidebarActive,
  spaceExtractionTasks,
}: {
  isSidebarActive: boolean;
  setIsSidebarActive: (value: boolean) => void;
  spaceExtractionTasks?: SpaceExtractionTask[];
}) => {
  const [activeTask, setActiveTask] = useState<TaskStatus | null>(null);

  useEffect(() => {
    if (spaceExtractionTasks && spaceExtractionTasks.length > 0) {
      for (const spaceExtractionTask of spaceExtractionTasks) {
        const status = spaceExtractionTask.task.status;
        if (status === 'InProgress' || status === 'Queued' || status === 'Pending' || status === 'Created') {
          setActiveTask(spaceExtractionTask.task);
          break;
        }
        if (status === 'Completed' && !spaceExtractionTask.hasImportedModel) {
          setActiveTask(spaceExtractionTask.task);
        }
      }
    }
  }, [spaceExtractionTasks]);

  return (
    <div className={`${styles['recent-uploads-nav']} ${isSidebarActive ? styles['active'] : ''} `}>
      <Text type="regular-11px" className={styles['status-text']}>
        <button className={styles['status-text-btn']} onClick={() => setIsSidebarActive(!isSidebarActive)}>
          {activeTask &&
          (activeTask.status === 'InProgress' || activeTask.status === 'Queued' || activeTask.status === 'Pending') ? (
            <>
              <span title="Upload in progress">
                <TrblUploadInProgressIcon />
              </span>
              <span>Recent uploads</span>
            </>
          ) : (
            <>
              <TrblUploadIcon />
              <span>Recent uploads</span>
            </>
          )}
        </button>
      </Text>
      <IconButton
        aria-label="close"
        onClick={() => {
          setIsSidebarActive(false);
        }}>
        <TrblCloseIcon width="10" height="10" stroke="#c0c0c0" />
      </IconButton>
    </div>
  );
};
