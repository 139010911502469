import { FC } from 'react';

import styles from './styles.module.scss';

type TimeScaleGroupProps = {
  color: string;
  onClick: () => void;
  onDoubleClick: () => void;
  isSelected: boolean;
  disabled: boolean;
  lowerLimit: number;
  upperLimit: number;
};

export const TimeScaleGroup: FC<TimeScaleGroupProps> = ({
  color,
  onClick,
  onDoubleClick,
  isSelected,
  disabled,
  lowerLimit,
  upperLimit,
}) => {
  return (
    <div className={styles['time-scale-group']}>
      <div
        className={styles['label']}
        style={{
          opacity: isSelected && !disabled ? 1 : 0.5,
        }}>
        {upperLimit !== Infinity ? `${lowerLimit * 1000}-${upperLimit * 1000} ms` : `>${lowerLimit * 1000} ms`}
      </div>
      <button
        type="button"
        disabled={disabled}
        style={{ cursor: 'pointer', display: 'flex', background: 'none', border: 'none', padding: 0 }}
        onClick={!disabled ? onClick : undefined}
        onDoubleClick={!disabled ? onDoubleClick : undefined}
        aria-pressed={isSelected}
        aria-label={`Toggle time scale group ${lowerLimit * 1000} to ${upperLimit * 1000} ms`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <rect
            x="0.5"
            y="19.5"
            width="19"
            height="19"
            rx="1.5"
            transform="rotate(-90 0.5 19.5)"
            fill={isSelected && !disabled ? color : 'transparent'}
            stroke={!disabled ? '#DADADA' : '#555657'}
          />
        </svg>
      </button>
    </div>
  );
};
