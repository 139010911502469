import { ReactNode } from 'react';

import { LayersListHeader } from './LayersListHeader';

import { DisplayLayer, ListColumnProps } from './types';

import styles from './styles.module.scss';

interface ListProps {
  columns: ListColumnProps[];
  data: DisplayLayer[];
  children: ReactNode;
}

export const LayersList: React.FC<ListProps> = ({ columns, data, children }) => {
  const listHasChildren = data.some((row) => 'children' in row && row.children?.length && row.children.length > 1);

  return (
    <div className={styles['list-container']}>
      <LayersListHeader listHasChildren={listHasChildren} columns={columns} />
      <ul className={styles['list-body']}>{children}</ul>
    </div>
  );
};
