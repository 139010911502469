import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditorContext } from '@/context/EditorContext';
import { ModelActionType, useModelContext } from '@/context/ModelContext/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Button } from '@mui/material';

import { TrblAuralizeIcon } from '../Icons';
import { TrblTooltip } from '../Shared';

import { useCloseAuralizer } from '../Auralizer/hooks/useCloseAuralizer';

import { Simulation } from '@/types';

import './styles.scss';

export const AuralizerButton = ({
  status,
  selectedSimulation,
  originalSimulation,
  userTouched,
}: {
  status: number | null;
  selectedSimulation: Simulation | null;
  originalSimulation: Simulation | null;
  userTouched: boolean;
}) => {
  const navigate = useNavigate();
  const { isAuralizerOpen, isInResultsMode } = useEditorContext();
  const { dispatch } = useSimulationContext();
  const { dispatch: modelDispatch } = useModelContext();

  const [showAurButton, setShowAurButton] = useState(false);

  const closeAuralizer = useCloseAuralizer();

  useEffect(() => {
    if (isAuralizerOpen) {
      setShowAurButton(true);
    } else if (selectedSimulation) {
      switch (status) {
        case 1:
          // running
          setShowAurButton(false);
          break;
        case 2:
          // completed
          if (selectedSimulation?.lastSimulationRun?.taskType !== 'DG') {
            setShowAurButton(true);
          } else {
            setShowAurButton(false);
          }
          break;
        case 3:
          // cancelled
          setShowAurButton(false);
          break;
        case 4:
          // error
          setShowAurButton(false);
          break;
        default:
          setShowAurButton(false);
      }
    }
  }, [status, selectedSimulation, userTouched, isAuralizerOpen]);

  const toggleAuralizer = () => {
    const currentSim = isInResultsMode ? originalSimulation : selectedSimulation;
    if (currentSim) {
      modelDispatch({
        type: ModelActionType.SET_CURRENT_MODEL_ID,
        modelId: currentSim.modelId,
      });
      dispatch({
        type: ActionType.SET_SELECTED_SIMULATION,
        simulation: { ...currentSim },
      });
    }
    if (!isAuralizerOpen && currentSim) {
      navigate(`/auralizer?mid=${currentSim.modelId}&sid=${currentSim.id}`);
    } else {
      closeAuralizer();
    }
  };

  const inEditMode = userTouched && status === 2;
  const disabled = inEditMode && !isAuralizerOpen;

  return (
    <TrblTooltip
      title={`${disabled ? `Auralizer can not be used while in ${inEditMode ? 'edit' : 'results'} mode` : ''}`}>
      <span>
        <Button
          disabled={disabled}
          className={`aur-button ${showAurButton ? '' : 'hidden'} ${disabled ? 'disabled' : ''}`}
          variant="text"
          startIcon={<TrblAuralizeIcon />}
          onClick={toggleAuralizer}>
          {isAuralizerOpen ? 'Exit Auralizer' : 'Auralizer'}
        </Button>
      </span>
    </TrblTooltip>
  );
};
