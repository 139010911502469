import { useEffect, useRef } from 'react';

import { useAppContext } from '@/context/AppContext';
import { useEditorContext } from '@/context/EditorContext';
import { useCreateSurfaceLayers } from '@/context/hooks/useCreateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { LayerRow } from './LayerRow';
import { TableHeader } from './TableHeader';

import { useTabDownList } from './hooks/useTabDownList';
import { useTabUpList } from './hooks/useTabUpList';
import { useGetMissingMaterialInfo } from '@/hooks';

import { MaterialLayer } from '@/types';

import './styles.scss';

export const LayersTable = () => {
  const layersContainer = useRef<HTMLDivElement>(null);
  const { isPopupOpen } = useEditorContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const {
    simulationState: { surfaceLayers, selectedSimulation },
    dispatch,
  } = useSimulationContext();
  const { selectedLayer } = useLibraryPanelContext();
  const { currentModel3dLayerGroups } = useModelContext();
  const { mutate: getMissingMaterialInfo } = useGetMissingMaterialInfo();
  const createSurfaceLayers = useCreateSurfaceLayers();
  const tabDownList = useTabDownList();
  const tabUpList = useTabUpList();

  useEffect(() => {
    if (
      currentModel3dLayerGroups?.length &&
      surfaceLayers &&
      surfaceLayers.length === 0 &&
      selectedSimulation?.modelSettings &&
      filteredMaterials.length > 0
    ) {
      const missingMaterialIds = Object.values(selectedSimulation?.modelSettings.materialIdByObjectId || {}).filter(
        (materialId) => !filteredMaterials.some((m) => m.id === materialId)
      );

      const missingMaterialIdsWithoutDuplicates = [...new Set(missingMaterialIds)];
      if (missingMaterialIdsWithoutDuplicates.length > 0) {
        getMissingMaterialInfo(missingMaterialIdsWithoutDuplicates, {
          onSuccess: (data) => {
            const { newLayers, newSimulation } = createSurfaceLayers(
              currentModel3dLayerGroups,
              selectedSimulation,
              filteredMaterials,
              data
            );

            dispatch({
              type: ActionType.SET_SURFACE_LAYERS,
              simulation: newSimulation,
              surfaceLayers: newLayers,
            });
            dispatch({
              type: ActionType.SET_MISSING_MATERIALS,
              missingMaterials: data,
            });
          },
        });
      } else {
        const { newLayers, newSimulation } = createSurfaceLayers(
          currentModel3dLayerGroups,
          selectedSimulation,
          filteredMaterials,
          []
        );

        dispatch({
          type: ActionType.SET_SURFACE_LAYERS,
          simulation: newSimulation,
          surfaceLayers: newLayers,
        });
        dispatch({
          type: ActionType.SET_MISSING_MATERIALS,
          missingMaterials: [],
        });
      }
    }
  }, [surfaceLayers, selectedSimulation?.id, currentModel3dLayerGroups, filteredMaterials]);

  useEffect(() => {
    // keyboard events for the editor
    // if there is a selected layer we have custom keyboard shortcuts
    if (selectedLayer && !isPopupOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedLayer, isPopupOpen]);

  // Keyboard events
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'Tab') {
      tabUpList(event, layersContainer);
    } else if (event.key === 'Tab') {
      tabDownList(event, layersContainer);
    }
  };

  return (
    <div ref={layersContainer} className="treble-layers-material">
      <TableHeader />
      <ul>
        {surfaceLayers?.map((layer: MaterialLayer) => (
          <LayerRow key={layer.id} layer={layer} />
        ))}
      </ul>
    </div>
  );
};
