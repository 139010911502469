import { useQuery } from '@tanstack/react-query';

import { ResultPresetDto } from '../types';

import axios from '@/axios';

export const getResultPresets = async (): Promise<ResultPresetDto[]> => {
  const { data } = await axios.get(`/api/ResultPreset/GetResultPresets`);
  return data;
};

export const useGetResultPresets = () => {
  return useQuery<ResultPresetDto[]>(['all-result-presets'], () => getResultPresets(), {
    refetchOnWindowFocus: false,
  });
};
