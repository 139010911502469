import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';

import { MeshTaskDto } from '@/components/SolverSettings/types';
import { useSimulationContext } from '../SimulationContext';

import { useGetActiveMeshTasks } from './hooks/useGetActiveMeshTasks';
import { useGetCompletedMeshTasks } from './hooks/useGetCompletedMeshTasks';

import {
  ActiveMeshTask,
  CompletedMeshResult,
  CompletedMeshTask,
  CurrentMeshResult,
  MeshTaskFrequencyDto,
  SimulationTimeEstimate,
} from './types';

type MeshProviderProps = { children: ReactNode };

enum ActionType {
  SET_ACTIVE_MESH_TASKS = 'SET_ACTIVE_MESH_TASKS',
  SET_CURRENT_SIM_ESTIMATE = 'SET_CURRENT_SIM_ESTIMATE',
  SET_COMPLETED_MESH_RESULTS = 'SET_COMPLETED_MESH_RESULTS',
  SET_CURRENT_MESH_RESULT = 'SET_CURRENT_MESH_RESULT',
  SET_COMPLETED_MESH_TASKS_FOR_MODEL = 'SET_COMPLETED_MESH_TASKS_FOR_MODEL',
  SET_COMPLETED_MESH_TASKS = 'SET_COMPLETED_MESH_TASKS',
  SET_ACTIVE_MESH_TASKS_FOR_MODEL = 'SET_ACTIVE_MESH_TASKS_FOR_MODEL',
  SET_MESH_ERROR = 'SET_MESH_ERROR',
  ADD_MESH_WARNING = 'ADD_MESH_WARNING',
}

type MeshContextAction =
  | { type: ActionType.SET_ACTIVE_MESH_TASKS; activeMeshTasks: ActiveMeshTask[] }
  | { type: ActionType.SET_ACTIVE_MESH_TASKS_FOR_MODEL; activeMeshTasksForModel: MeshTaskDto[] }
  | { type: ActionType.SET_COMPLETED_MESH_RESULTS; completedMeshResults: CompletedMeshResult[] }
  | { type: ActionType.SET_CURRENT_MESH_RESULT; currentMeshResult: CurrentMeshResult | null }
  | { type: ActionType.SET_COMPLETED_MESH_TASKS; completedMeshTasks: CompletedMeshTask[] }
  | { type: ActionType.SET_COMPLETED_MESH_TASKS_FOR_MODEL; completedMeshTasksForModel: MeshTaskFrequencyDto[] }
  | { type: ActionType.SET_MESH_ERROR; meshHasError: boolean }
  | { type: ActionType.ADD_MESH_WARNING; meshWarning: string }
  | {
      type: ActionType.SET_CURRENT_SIM_ESTIMATE;
      estimate: SimulationTimeEstimate | null;
    };

type State = {
  activeMeshTasks: ActiveMeshTask[]; // used in our components with extra prop
  completedMeshTasks: CompletedMeshTask[];
  currentMeshResult: CurrentMeshResult | null;
  completedMeshResults: CompletedMeshResult[]; // used in our components with extra prop
  completedMeshTasksForModel: MeshTaskFrequencyDto[]; // from endpoint
  currentSimTimeEstimate: SimulationTimeEstimate | null;
  meshHasError: boolean;
  meshWarnings: string[];
  dispatch: (action: MeshContextAction) => void;
};

const initialState: State = {
  activeMeshTasks: [],
  completedMeshTasks: [],
  currentMeshResult: null,
  completedMeshResults: [],
  completedMeshTasksForModel: [],
  currentSimTimeEstimate: null,
  meshHasError: false,
  meshWarnings: [],
  dispatch: () => {},
};

const MeshContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: MeshContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const meshReducer = (state: State, action: MeshContextAction): State => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_MESH_TASKS_FOR_MODEL: {
      const newAtiveMeshTasks: ActiveMeshTask[] = action.activeMeshTasksForModel.map((activeMeshTask) => {
        return { id: activeMeshTask.id, transitionFrequency: activeMeshTask.crossoverFrequency };
      });
      return {
        ...state,
        activeMeshTasks: [...newAtiveMeshTasks],
      };
    }

    case ActionType.SET_ACTIVE_MESH_TASKS: {
      return {
        ...state,
        activeMeshTasks: [...action.activeMeshTasks],
      };
    }

    case ActionType.SET_COMPLETED_MESH_RESULTS: {
      return {
        ...state,
        completedMeshResults: [...action.completedMeshResults],
      };
    }

    case ActionType.SET_CURRENT_MESH_RESULT: {
      return {
        ...state,
        currentMeshResult: action.currentMeshResult,
      };
    }

    case ActionType.SET_CURRENT_SIM_ESTIMATE: {
      return {
        ...state,
        currentSimTimeEstimate: action.estimate,
      };
    }

    case ActionType.SET_COMPLETED_MESH_TASKS_FOR_MODEL: {
      return {
        ...state,
        completedMeshTasksForModel: [...action.completedMeshTasksForModel],
        completedMeshTasks: [...action.completedMeshTasksForModel],
      };
    }

    case ActionType.SET_COMPLETED_MESH_TASKS: {
      return {
        ...state,
        completedMeshTasks: [...action.completedMeshTasks],
      };
    }

    case ActionType.SET_MESH_ERROR: {
      return {
        ...state,
        meshHasError: action.meshHasError,
      };
    }

    case ActionType.ADD_MESH_WARNING: {
      return {
        ...state,
        meshWarnings: [...state.meshWarnings, action.meshWarning],
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const MeshProvider = ({ children }: MeshProviderProps) => {
  const [meshState, dispatch] = useReducer(meshReducer, initialState);
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const { data: completedMeshTasksForModel } = useGetCompletedMeshTasks(selectedSimulation?.modelId || '');
  const { data: activeMeshTasksForModel } = useGetActiveMeshTasks(selectedSimulation?.modelId || '');

  useEffect(() => {
    if (completedMeshTasksForModel) {
      dispatch({
        type: ActionType.SET_COMPLETED_MESH_TASKS_FOR_MODEL,
        completedMeshTasksForModel,
      });
    }
  }, [completedMeshTasksForModel]);

  useEffect(() => {
    if (activeMeshTasksForModel) {
      dispatch({
        type: ActionType.SET_ACTIVE_MESH_TASKS_FOR_MODEL,
        activeMeshTasksForModel,
      });
    }
  }, [activeMeshTasksForModel]);

  const value = { ...meshState, dispatch };

  return <MeshContext.Provider value={value}>{children}</MeshContext.Provider>;
};

const useMeshContext = () => {
  const context = useContext(MeshContext);
  if (context === undefined) {
    throw new Error('useMeshContext must be used within MeshProvider');
  }
  return context;
};

export { ActionType, MeshProvider, useMeshContext };
