import { Fragment, SyntheticEvent, useEffect, useState } from 'react';

import { LayersListRow } from './LayersListRow';
import { ListColumn } from './ListColumn';

import { DisplayLayer, LayerListClassName, ListColumnProps } from './types';

interface ListProps {
  columns: ListColumnProps[];
  data: DisplayLayer[];
  rowData: DisplayLayer;
  hiddenLayers: Set<string>;
  toggleVisibility: (event: SyntheticEvent, id: string, parentId: string) => void;
  classNames?: LayerListClassName;
  selectedRow: string | null;
  setSelectedRow: (id: string) => void;
}

export const LayersListContent: React.FC<ListProps> = ({
  columns,
  data,
  rowData,
  hiddenLayers,
  toggleVisibility,
  selectedRow,
  setSelectedRow,
  classNames,
}) => {
  const [collapsedRows, setCollapsedRows] = useState<Set<string>>(new Set());

  useEffect(() => {
    // if the selectedRow is a child we want to toggle that list open
    if (selectedRow !== null && rowData.id !== selectedRow) {
      const childIsSelectedRow = rowData.children.find((child) => child.id === selectedRow);
      if (childIsSelectedRow) {
        const newCollapsedRows = new Set(collapsedRows);
        newCollapsedRows.add(rowData.id);
        setCollapsedRows(newCollapsedRows);
      } else {
        setCollapsedRows(new Set());
      }
    }
  }, [selectedRow, rowData]);

  const toggleRow = (e: SyntheticEvent, id: string) => {
    e?.stopPropagation();
    const newCollapsedRows = new Set(collapsedRows);
    if (newCollapsedRows.has(id)) {
      newCollapsedRows.delete(id);
    } else {
      newCollapsedRows.add(id);
    }
    setCollapsedRows(newCollapsedRows);
  };

  const listHasChildren = data.some((row) => 'children' in row && row.children?.length && row.children.length > 1);

  return (
    <Fragment>
      <LayersListRow
        rowData={rowData}
        listHasChildren={listHasChildren}
        collapsedRows={collapsedRows}
        selectedRow={selectedRow}
        toggleRow={toggleRow}
        setSelectedRow={setSelectedRow}
        classNames={classNames}>
        {columns.map((column) => (
          <ListColumn
            key={column.fieldName}
            isSelected={selectedRow === rowData.id}
            isHidden={hiddenLayers.has(rowData.id)}
            column={column}
            rowData={rowData}
            toggleVisibility={toggleVisibility}
          />
        ))}
      </LayersListRow>
      {rowData.children.length > 1 &&
        collapsedRows.has(rowData.id) &&
        rowData.children.map((childRow) => (
          <LayersListRow
            key={childRow.id}
            rowData={childRow}
            listHasChildren={false}
            collapsedRows={collapsedRows}
            selectedRow={selectedRow}
            toggleRow={toggleRow}
            setSelectedRow={setSelectedRow}
            isChild={true}
            classNames={classNames}>
            {columns.map((column) => (
              <ListColumn
                key={column.fieldName}
                isSelected={selectedRow === childRow.id}
                isHidden={hiddenLayers.has(childRow.id)}
                column={column}
                rowData={childRow}
                toggleVisibility={toggleVisibility}
              />
            ))}
          </LayersListRow>
        ))}
    </Fragment>
  );
};
