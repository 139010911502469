import { FC, useState } from 'react';

import { Text } from '../Text';

import styles from './styles.module.scss';

type TabsProps = {
  options: { key: string; label?: string }[];
  selectedValue: string | null;
  onChange: (selectedValue: string) => void;
};

export const Tabs: FC<TabsProps> = ({ options, selectedValue, onChange }) => {
  const [tabHovered, setTabHovered] = useState<string | null>(null);

  return (
    <div className={styles['tabs-container']}>
      {options.map((option) => (
        <div
          key={option.key}
          className={`${styles['tab']} ${selectedValue === option.key ? styles['selected'] : ''}`}
          onClick={() => {
            if (selectedValue !== option.key) {
              onChange(option.key);
            }
          }}
          onMouseOver={() => setTabHovered(option.key)}
          onMouseLeave={() => setTabHovered(null)}>
          {option.label && (
            <Text
              type="regular-12px"
              color={tabHovered === option.key || selectedValue === option.key ? '#F5F5F5' : undefined}>
              {option.label}
            </Text>
          )}
        </div>
      ))}
    </div>
  );
};
