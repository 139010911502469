import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { QuickLinks } from './QuickLinks';
import { RecentSimulationStatusDisplay } from './RecentSimulationStatusDisplay';

import { RunStatus, SimulationRunStatusDto, SourceStatus, TaskStatus } from '@/types';

import classes from './styles.module.scss';

interface RecentSimulationStatusProps {
  simulationRunStatus: SimulationRunStatusDto;
}
export const RecentSimulationStatus = ({ simulationRunStatus }: RecentSimulationStatusProps) => {
  const [latestTaskRun, setLatestTaskRun] = useState<TaskStatus | null>(null);
  const [status, setStatus] = useState<RunStatus>(RunStatus.Completed);
  const [percentage, setPercentage] = useState<number>(0);

  const setStatusFunc = (sim: SimulationRunStatusDto) => {
    if (sim.status) {
      setStatus(sim.status);
    }

    if (
      sim.status &&
      sim.progressPercentage &&
      [RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults, RunStatus.Queued].includes(sim.status)
    ) {
      setPercentage(sim.progressPercentage);
      // show 99% done before showing 100% processing results
      if (sim.status !== RunStatus.ProcessingResults && sim.progressPercentage == 100) setPercentage(99);
    }
  };

  const getLatestTaskRun = (sources: SourceStatus[]) => {
    let latestTaskStatus: TaskStatus | null = null;

    // Iterate through sources to sort the taskStatuses
    sources.forEach((item) => {
      if (item.taskStatuses && item.taskStatuses.length > 0) {
        item.taskStatuses.sort(
          (taskA: TaskStatus, taskB: TaskStatus) => dayjs(taskB.completedAt).unix() - dayjs(taskA.completedAt).unix()
        );
      }
    });
    // Find the latest task status across all sources
    sources.forEach((source) => {
      if (source.taskStatuses && source.taskStatuses.length > 0) {
        const currentTaskStatus = source.taskStatuses[0];
        if (
          latestTaskStatus === null ||
          dayjs(currentTaskStatus.completedAt).unix() > dayjs(latestTaskStatus.completedAt).unix()
        ) {
          latestTaskStatus = currentTaskStatus;
        }
      }
    });

    return latestTaskStatus;
  };

  useEffect(() => {
    if (simulationRunStatus) {
      setStatusFunc(simulationRunStatus);
      const latestTaskRun = getLatestTaskRun([...(simulationRunStatus.sources || [])]);
      setLatestTaskRun(latestTaskRun);
    }
  }, [simulationRunStatus]);

  return (
    <div className={classes.recent_simulations_status}>
      <Link
        to={`/editor?mid=${simulationRunStatus.modelId}&sid=${simulationRunStatus.simulationId}`}
        title={
          simulationRunStatus.spaceName +
          ' › ' +
          simulationRunStatus.modelBaseName +
          ' › ' +
          simulationRunStatus.modelBaseName
        }>
        <p className={classes.simulation_name}>
          <span>{simulationRunStatus.modelBaseName} › </span>
          {simulationRunStatus.simulationName}
        </p>
      </Link>

      {(latestTaskRun || status === RunStatus.Created) && (
        <div className={classes.row}>
          <RecentSimulationStatusDisplay
            simulationStatus={status}
            taskStatus={latestTaskRun}
            percentage={percentage}
            timeEstimate={simulationRunStatus.timeEstimate}
            startedAt={simulationRunStatus.createdAt}
            completedAt={simulationRunStatus.completedAt}
            parent="Sidebar"
          />
          {status === RunStatus.Completed && (
            <QuickLinks simulationId={simulationRunStatus.simulationId} modelId={simulationRunStatus.modelId} />
          )}
        </div>
      )}
      {!latestTaskRun && status === RunStatus.InsufficientTokens && (
        <RecentSimulationStatusDisplay
          simulationStatus={status}
          taskStatus={simulationRunStatus}
          timeEstimate={null}
          parent="Sidebar"
        />
      )}
    </div>
  );
};
