import { FC, useMemo } from 'react';
import { BufferAttribute, BufferGeometry, DoubleSide } from 'three';

import { Triangle as TriangleType } from '../../types';

type TriangleProps = {
  triangle: TriangleType;
  color?: string;
};

export const Triangle: FC<TriangleProps> = ({ triangle, color = 'orange' }) => {
  const geometry = useMemo(() => {
    const geom = new BufferGeometry();
    // Flatten the array of vertices for BufferGeometry
    const flatVertices = triangle.flat();
    const vertices = new Float32Array(flatVertices);

    geom.setAttribute('position', new BufferAttribute(vertices, 3));

    return geom;
  }, [triangle]);

  return (
    <mesh geometry={geometry}>
      <meshBasicMaterial color={color} depthWrite={true} side={DoubleSide} transparent={true} opacity={0.8} />
    </mesh>
  );
};
