import { CSSProperties, FC, useEffect, useState } from 'react';

import { Box, MenuItem, Select, SelectProps } from '@mui/material';

import { TrblChevronDownIcon } from '@/components/Icons';
import { Checkbox } from '../Checkbox';
import { Text } from '../Text';

import styles from './styles.module.scss';

type Option = {
  label: string;
  value: string;
};

type MultiSelectProps = {
  options: Option[];
  selected: string[];
  onChange: (selected: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  minimal?: boolean;
  menuOpen?: boolean;
  renderMenuItem?: (menuItem: Option, index: number) => React.ReactNode;
  handleThisClose?: () => void;
};

/**
 * Icon component for the select dropdown.
 */
const ChevronDownIcon: FC<SelectProps> = (props) => <TrblChevronDownIcon {...props} fill="#ADADAD" />;

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  selected,
  onChange,
  disabled = false,
  placeholder = '',
  style,
  minimal = true,
  className,
  menuOpen = false,
  renderMenuItem,
  handleThisClose,
}) => {
  const [open, setOpen] = useState(false);
  const handleOptionSelect = (value: string, isChecked: boolean) => {
    if (isChecked) {
      onChange([...selected, value]);
    } else {
      onChange(selected.filter((s) => s !== value));
    }
  };

  useEffect(() => {
    setOpen(menuOpen);
  }, [menuOpen]);

  const handleClose = () => {
    if (handleThisClose) handleThisClose();
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Select
      multiple={true}
      style={{ ...style }}
      IconComponent={ChevronDownIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: {
          paddingTop: '0px',
          '&& .Mui-selected[aria-selected="false"]': {
            background: 'none',
          },
        },
      }}
      disabled={disabled}
      value={selected}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      displayEmpty={true} // this needs to be here so we can show a placeholder
      className={`${styles['custom-select']} ${className ?? ''} ${disabled ? 'disabled' : ''} ${
        minimal ? styles['minimal'] : ''
      }`}
      renderValue={(selected) => {
        const value = selected.length
          ? options
              .filter((o) => selected.includes(o.value))
              .map((o) => o.label)
              .join(', ')
          : placeholder;

        return <em className={selected.length ? '' : styles['placeholder']}>{value}</em>;
      }}>
      <MenuItem
        key={'all'}
        value={'all'}
        onClick={() => {
          if (selected.length === options.length) {
            onChange([]);
          } else {
            onChange([...options.map((o) => o.value)]);
          }
        }}
        className={styles['custom-select-item']}>
        <Box component="div" display="flex" width="100%" justifyContent={'space-between'} alignItems="center">
          <Text type="medium-10px" color="#999999" uppercase>
            Select all
          </Text>
          <Checkbox id={'all'} isChecked={selected.length === options.length} />
        </Box>
      </MenuItem>
      {options.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => {
            handleOptionSelect(option.value, !selected.includes(option.value));
          }}
          className={`${styles['custom-select-item']} ${selected.includes(option.value) ? styles['selected'] : ''}`}>
          {renderMenuItem ? (
            renderMenuItem(option, index)
          ) : (
            <Checkbox
              id={option.value}
              label={option.label}
              spaceBetween={true}
              labelAlignment={'left'}
              isChecked={selected.includes(option.value)}
            />
          )}
        </MenuItem>
      ))}
    </Select>
  );
};
