import { datadogRum } from '@datadog/browser-rum';

import { useSimulationContext } from '@/context/SimulationContext';

import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { useCreateAudioObjectForPair } from './useCreateAudioObjectForPair';

import { getHashes, getNewIdsFromRecHashes, getOriginalIds } from '../utils';

import { RecUrl } from '../types';
import { Simulation, Source } from '@/types';

export const useSetupAllAudioNodesForSim = () => {
  const { simSrcRecHash, dispatch } = useAuralizerContext();
  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const createAudioObjectForPair = useCreateAudioObjectForPair();

  const setupAllAudioNodesForSim = (simulation: Simulation, sources: Source[], recUrl: RecUrl) => {
    console.log('[Auralization] loading all');
    const currentReceiverIdList = getNewIdsFromRecHashes(simulation, simSrcRecHash) ?? [];

    sources.forEach((source) => {
      currentReceiverIdList.forEach((receiverId: string) => {
        try {
          const { srcHash, recHash } = getHashes(source.id, receiverId, simulation, simSrcRecHash);
          if (srcHash && recHash) {
            const originalIds = getOriginalIds(srcHash, recHash, simSrcRecHash, originalSim);

            dispatch({
              type: ActionType.SET_FETCHING,
              isFetching: true,
              simId: simulation?.id ?? '',
            });
            const url = recUrl[source.id][receiverId].url;
            createAudioObjectForPair(url, originalIds.sourceId, originalIds.receiverId, simulation?.id ?? '');
          } else {
            datadogRum.addError('[Auralization] Hashes not found');
          }
        } catch (e) {
          console.log('[Auralization] error setting up audio nodes', e);
        }
      });
    });
  };

  return setupAllAudioNodesForSim;
};
