import { useAppContext } from '@/context/AppContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { ActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useSelectLayerInViewport } from './useSelectLayerInViewport';

import { MaterialLayer } from '@/types';

export const useSelectLayer = () => {
  const {
    appState: { filteredMaterials },
  } = useAppContext();

  const selectLayerInViewport = useSelectLayerInViewport();

  const { selectedLayer, selectedMaterial, dispatch } = useLibraryPanelContext();
  const selectLayer = (layer: MaterialLayer) => {
    const newLayer = layer ? layer : selectedLayer;
    if (newLayer) {
      selectLayerInViewport(newLayer as MaterialLayer);
    }

    // Just select the row, not opening the material panel unless the row has already been clicked
    let newMaterial = null;
    if (layer?.materialId !== EMPTY_GUID && !layer?.isMissingMaterial) {
      newMaterial = filteredMaterials.find((material) => material.id === layer?.materialId) || selectedMaterial;
    }

    dispatch({
      type: ActionType.SET_SELECTED_MATERIAL,
      material: newMaterial,
      highlightedItemId: newMaterial?.id ?? null,
    });
  };

  return selectLayer;
};
