/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueries } from '@tanstack/react-query';

import { GeometryImportActionType, useGeometryImportContext } from '@/context/GeometryImportContext';

import { getFeedbackByUrl } from './useGetGeometryFeedbackByUrl';

import { Warnings } from '../constants';

import { GeometryInfo, SpaceResults } from '../types';

export const useLoadGeometryInfo = (spaceResults: SpaceResults[]) => {
  const { dispatch } = useGeometryImportContext();

  const results = useQueries({
    queries: spaceResults.map((space) => ({
      queryKey: ['feedback-url', space.feedbackDownloadUrl],
      queryFn: () => getFeedbackByUrl(space.feedbackDownloadUrl),
      onSuccess: (data: any) => {
        if (data) {
          const watertight = data.errors.length ? !data.errors.includes('not_watertight') : true;

          const boundingBoxVol = data.volumes.bounding_box;
          const outerShellVol = data.volumes.outer_shell;
          let boundaryMismatch = false;

          // If the volume of the model's bounding box is 4 times bigger than the volume of the outer shell, trigger a boundaryMismatch Mismatch warning
          if (watertight && outerShellVol / boundingBoxVol < 0.25) {
            boundaryMismatch = true;
          }

          const geometryInfo: GeometryInfo = {
            objectCount: data.geometry_object_count,
            layerCount: Object.keys(data.layer_name_by_layer_index).length,
            watertight: watertight,
            errors: data.errors.length ? data.errors : null,
            warnings: data.warnings.length ? data.warnings : null,
            isolated_edges: Warnings.ISOLATED_EDGES in data ? data[Warnings.ISOLATED_EDGES] : [],
            naked_edges: Warnings.NAKED_EDGES in data ? data[Warnings.NAKED_EDGES] : [],
            invalid_faces: Warnings.INVALID_FACES in data ? data[Warnings.INVALID_FACES] : [],
            small_edges: Warnings.SMALL_EDGES in data ? data[Warnings.SMALL_EDGES] : [],
            excluded_faces: Warnings.EXCLUDED_FACES in data ? data[Warnings.EXCLUDED_FACES] : [],
            problem_areas:
              Warnings.PROBLEM_AREAS in data
                ? data[Warnings.PROBLEM_AREAS].map((x: any) => ({ center: [x[0], x[1], x[2]], radius: x[3] }))
                : [],
            boundaryMismatch: boundaryMismatch,
            modelVolume: outerShellVol,
            cappedElementLists: data.capped_element_lists,
            //small_tetrahedrons: Warnings.SMALL_TETRAS in data ? data[Warnings.SMALL_TETRAS] : [],
          };

          dispatch({
            type: GeometryImportActionType.ADD_GEOMETRY_INFO,
            modelId: `model_${space.meshUploadId}`,
            geometryInfo,
          });
        }
      },
      refetchOnWindowFocus: false,
    })),
  });

  return {
    isLoading: results.some((r) => r.isFetching),
  };
};
