import { useEffect, useState } from 'react';

import { useSimulationContext } from '@/context/SimulationContext';

import { TrblTooltip } from '@/components/Shared';
import { FrequencyTable } from './components/FrequencyTable';
import { TrblCaretDownIcon, TrblCaretRightIcon, TrblInfoIcon } from '../Icons';

import { EMPTY_GUID } from '../Shared/constants';
import { infoText } from './constants';

import styles from './styles.module.scss';

export const StatisticalEstimates = () => {
  const [isOpen, setIsOpen] = useState(true);

  const {
    simulationState: { sabineEstimate, selectedSimulation },
  } = useSimulationContext();

  const [showSabineEstimate, setShowSabineEstimate] = useState(false);

  useEffect(() => {
    if (
      selectedSimulation?.modelSettings &&
      Object.values(selectedSimulation.modelSettings.materialIdByObjectId).indexOf(EMPTY_GUID) > -1
    ) {
      setShowSabineEstimate(false);
    } else {
      setShowSabineEstimate(true);
    }
  }, [selectedSimulation?.modelSettings?.materialIdByObjectId]);

  return (
    <div className={`${styles['room-settings-container']} ${styles[isOpen ? 'show' : '']}`}>
      <button className={styles['room-settings-button']} onClick={() => setIsOpen(!isOpen)}>
        <div>
          <span className={styles['room-settings-toggle']}>
            {isOpen ? <TrblCaretDownIcon /> : <TrblCaretRightIcon />}
          </span>
          <span className={styles['room-settings-header']}> RT estimates </span>
        </div>

        <TrblTooltip title={infoText} placement="left">
          <span>
            <TrblInfoIcon />
          </span>
        </TrblTooltip>
      </button>
      <div>
        <div className={`${styles['room-settings-content']}  ${styles[isOpen ? 'show' : '']}`}>
          {isOpen && (
            <FrequencyTable data={sabineEstimate} showData={showSabineEstimate && sabineEstimate.length > 0} />
          )}
        </div>
      </div>
    </div>
  );
};
