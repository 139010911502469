import { useEffect, useState } from 'react';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';
import { useCreateSurfaceLayers } from '@/context/hooks/useCreateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box, Grid, Stack } from '@mui/material';

import { ActionType as LibActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { TrblEditableLabel } from '../Shared/TrblEditableLabel';

import { useUpdateMaterial } from '@/hooks';

import { Material } from '@/types';

import styles from './styles.module.scss';

export const MaterialInfo = ({ material }: { material: Material }) => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const {
    appState: { filteredMaterials },
    dispatch,
  } = useAppContext();
  const { currentModel3dLayerGroups } = useModelContext();
  const {
    dispatch: simulationDispatch,
    simulationState: { selectedSimulation, missingMaterials },
  } = useSimulationContext();
  const createSurfaceLayers = useCreateSurfaceLayers();

  const { highlightedItemId, dispatch: libDispatch } = useLibraryPanelContext();

  const { mutate: updateMaterial } = useUpdateMaterial();
  const materialDescription = material.description.split(/\r?\n/);
  const [absorptionClass, setAbsorptionClass] = useState<string | null>(null);

  useEffect(() => {
    if (material) {
      const parsedMaterialMetadata = JSON.parse(material?.materialMetadataJson);
      setAbsorptionClass(parsedMaterialMetadata.AbsorptionClass);
    }
  }, [material]);

  const updateMaterialName = (value: string) => {
    if (material.name !== value && value.length > 0) {
      updateMaterial(
        {
          materialId: material.id,
          updatedMaterial: {
            name: value,
            description: material.description,
            category: material.category,
          },
        },
        {
          onSuccess: () => {
            updateMaterials({ ...material, name: value });
          },
        }
      );
    }
  };

  const updateMaterials = (updatedMaterial: Material) => {
    const materialIndex = filteredMaterials.findIndex((filteredMaterial) => filteredMaterial.id === material.id);
    const newMaterials = [...filteredMaterials];
    newMaterials[materialIndex] = updatedMaterial;

    dispatch({
      type: ActionType.SET_MATERIALS,
      payload: newMaterials,
    });

    const { newLayers, newSimulation } = createSurfaceLayers(
      currentModel3dLayerGroups,
      selectedSimulation,
      newMaterials,
      missingMaterials
    );

    simulationDispatch({
      type: SimActionType.SET_SURFACE_LAYERS,
      surfaceLayers: newLayers,
      simulation: newSimulation,
    });

    if (updatedMaterial.id === highlightedItemId) {
      libDispatch({
        type: LibActionType.SET_HIGHLIGHTED_ITEM,
        highlightedItemId: updatedMaterial.id,
      });
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Stack>
          <Box component="div" mb={'5px'} display={'flex'} width={'100%'}>
            <span className={styles['material-label']}>Name: </span>
            {material?.isUserCreated && material?.userId === userInfo?.id ? (
              <div style={{ maxWidth: '240px', width: '100%', display: 'flex', alignItems: 'center' }}>
                <TrblEditableLabel
                  label={material.name || '-'}
                  placeholder={material.name ? undefined : `Material name`}
                  onBlur={updateMaterialName}
                  fontSize="12px"
                  showPencil
                  noEmpty
                />
              </div>
            ) : (
              <span> {material.name || '-'} </span>
            )}
          </Box>
          <Box component="div" mb={'5px'}>
            <span className={styles['material-label']}>Category: </span>
            <span> {material.category || '-'} </span>
          </Box>
          <Box component="div" mb={'5px'}>
            <span className={styles['material-label']}>Default scattering: </span>
            <span> {material.defaultScattering ?? '-'} </span>
          </Box>
          {absorptionClass && absorptionClass !== 'unclassified' && (
            <Box component="div" mb={'5px'}>
              <span className={styles['material-label']}>Absorption class: </span>
              <span> {absorptionClass} </span>
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Box component="div" mb={'5px'}>
          <span className={styles['material-label']}>Description: </span>
          <Box component="div" className={styles['material-description']}>
            {materialDescription.map((description, index: number) => (
              <p key={index}> {description ? description : '-'} </p>
            ))}
          </Box>
        </Box>
        {material.isUserCreated && (
          <Box component="div" mb={'5px'}>
            <span className={styles['material-label']}>Created by: </span>
            <span> {material.userEmail ?? '-'} </span>
          </Box>
        )}
      </Grid>
    </>
  );
};
