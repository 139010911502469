import { Stack } from '@mui/material';

import { TrblWarningIcon } from '../Icons';

import { SOURCE_DEFINITION_WARNING_TEXT } from './constants';

export const SourceDefinitionWarning = () => {
  return (
    <Stack flexDirection={'row'} gap={1} fontSize={11} color={'#999999'}>
      <TrblWarningIcon fill="#999999" width="14" height="14" />
      {SOURCE_DEFINITION_WARNING_TEXT}
    </Stack>
  );
};
