import React from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { PlotMouseEvent } from 'plotly.js';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { useGetYAxisRange, useSplPerBandPlotData } from './hooks';

import { AVAILABLE_FREQUENCY_BANDS_LABELS } from '../constants';

import { getLayoutConfig } from './utils';

import { SPLType } from './types';

type ReflectionsPlotProps = {
  selectedSplType: SPLType;
  data: ReflectionDetails | null;
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedFrequencyIndex: number | null;
  onFrequencySelected: (index: number) => void;
};

export const SPLPerBandPlot: React.FC<ReflectionsPlotProps> = ({
  selectedSplType,
  data,
  selectedFrequencyWeighting,
  selectedFrequencyIndex,
  onFrequencySelected,
}) => {
  const plotData = useSplPerBandPlotData({
    data,
    selectedFrequencyIndex,
    selectedFrequencyWeighting,
    selectedSplType,
  });
  const yAxisRange = useGetYAxisRange(data, selectedSplType, selectedFrequencyWeighting);

  const handleClick = (event: Readonly<PlotMouseEvent>) => {
    const frequencyBand = event.points[0].data.x[0] as string;
    const frequencyIndex = AVAILABLE_FREQUENCY_BANDS_LABELS.indexOf(frequencyBand);

    if (frequencyIndex !== undefined) {
      onFrequencySelected(frequencyIndex);
    }
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Plot
          data={plotData}
          layout={getLayoutConfig(yAxisRange, selectedFrequencyWeighting)}
          style={{ width, height }}
          config={{ displaylogo: false, responsive: true, displayModeBar: false }}
          useResizeHandler={true}
          onClick={handleClick}
        />
      )}
    </AutoSizer>
  );
};
