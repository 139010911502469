import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Organization } from './types';

import axios from '@/axios';

export type CreateOrganizationRequest = {
  name: string;
  description: string;
};

type CreateOrganizationResponse = Organization;

const createOrganization = async (organization: CreateOrganizationRequest) => {
  const { data } = await axios.post<CreateOrganizationResponse>(`/api/Organization/Create`, organization);
  return data;
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CreateOrganizationRequest) => await createOrganization(data), {
    onSuccess: () => queryClient.invalidateQueries(['organizations']),
    onError: () => toast.error('An error occurred while creating the organization!'),
  });
};
