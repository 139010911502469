import React, { FC, useState } from 'react';

import { ClickAwayListener, Popper } from '@mui/material';

import { TrblNumberInput } from '@/components/Shared/NumberInput';

import { Source } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

type SourceDelayPopupProps = {
  parentRef: React.RefObject<HTMLDivElement>;
  source: Source;
  onIrTimeDelayChange: (value: number) => void;
  onClose: () => void;
};

export const SourceDelayPopup: FC<SourceDelayPopupProps> = ({ parentRef, source, onIrTimeDelayChange, onClose }) => {
  const [irTimeDelay, setIrTimeDelay] = useState<number | undefined>(source.params.irTimeDelay ?? 0);

  const handleClickAway = () => {
    if (irTimeDelay !== undefined) {
      onIrTimeDelayChange(irTimeDelay);
    }
    onClose();
  };

  const handleContainerKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      handleClickAway();
    }
  };

  return (
    <ClickAwayListener disableReactTree onClickAway={handleClickAway}>
      <Popper
        id="source-delay-popover"
        placement="right"
        open={true}
        anchorEl={parentRef.current}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}>
        <div className={styles['controls-container']} onKeyDown={handleContainerKeyDown}>
          <div>
            <TrblNumberInput
              value={irTimeDelay}
              step={0.1}
              min={0}
              max={1}
              endAdornment="s"
              decimals={4}
              onChange={setIrTimeDelay}
              onBlur={(value) => {
                if (value !== undefined && source?.params.irTimeDelay !== value) {
                  onIrTimeDelayChange(value);
                }
              }}
              autofocus={true}
              alignment="right"
              blurOnStep={false}
            />
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};
