import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';
import { ActionType as AppActionType, useAppContext } from '@/context/AppContext';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';

import { useCreateMaterial } from '@/hooks';

import { MaterialInputType } from '../constants';

import { MaterialDto } from '@/types';

export const CreateMaterialButton = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const {
    isFormValid,
    materialFit,
    builderFittedData,
    impedanceFittedData,
    reflectionFittedData,
    inputType,
    formValues,
    shouldShare,
    dispatch,
  } = useCreateMaterialContext();

  const {
    dispatch: appDispatch,
    appState: { filteredMaterials },
  } = useAppContext();

  const { mutate: createMaterial, isLoading: isCreatingMaterial } = useCreateMaterial();

  const [fittedData, setFittedData] = useState<MaterialDto | null>(null);

  useEffect(() => {
    if (inputType === MaterialInputType.SurfaceImpedance) {
      setFittedData(impedanceFittedData);
    } else if (inputType === MaterialInputType.ReflectionCoefficient) {
      setFittedData(reflectionFittedData);
    } else if (inputType === MaterialInputType.PorousAbsorberBuilder) {
      setFittedData(builderFittedData);
    }
  }, [impedanceFittedData, reflectionFittedData]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_DISABLED_FORM,
      formDisabled: isCreatingMaterial,
    });
  }, [isCreatingMaterial]);

  const handleCreateMaterial = () => {
    if ((materialFit?.material_json && materialFit.material_metadata_json) || fittedData !== undefined) {
      const materialProps =
        materialFit?.material_json && materialFit.material_metadata_json
          ? {
              materialJson: materialFit.material_json,
              materialMetaDataJson: materialFit.material_metadata_json,
              materialFitId: materialFit.material_fit_id,
            }
          : {
              materialJson: fittedData!.materialJson,
              materialMetaDataJson: fittedData!.materialMetadataJson,
              materialFitId: fittedData!.id,
            };
      createMaterial(
        {
          ...formValues,
          description: formValues.description.trim() === '' ? 'none' : formValues.description,
          isSharedWithOrganization: shouldShare,
          ...materialProps,
        },
        {
          onSuccess: (data) => {
            const newMaterials = [...filteredMaterials, data];
            appDispatch({
              type: AppActionType.SET_MATERIALS,
              payload: newMaterials,
            });
            setShowPopup(false);
          },
          onError: () => {
            dispatch({
              type: ActionType.SET_DISABLED_FORM,
              formDisabled: false,
            });
          },
        }
      );
    }
  };

  return (
    <TrblTooltip title={!isFormValid ? 'Name and category are required' : ''}>
      <span>
        <PrimaryButton
          disabled={!isFormValid}
          width="fit-content"
          label="Create material"
          onClick={handleCreateMaterial}
        />
      </span>
    </TrblTooltip>
  );
};
