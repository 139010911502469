import { toast } from 'react-toastify';

import { useSimulationContext } from '@/context/SimulationContext';

import { AudioEngine } from '../AudioEngine';

import { AudioNodesDict } from '../types';

export const useUpdatedReverb = () => {
  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const audioEngine = AudioEngine.getInstance();

  const updateReverb = async (
    currentRecId: string | undefined,
    selAurSimId: string,
    audioNodesDict: { [sourceId: string]: AudioNodesDict } | null,
    audioElementDictionary: { [sourceId: string]: HTMLAudioElement } | null
  ) => {
    if (originalSim && currentRecId && audioNodesDict && audioElementDictionary) {
      const audioElementsSourceIds = Object.keys(audioElementDictionary).filter(
        (key) => audioElementDictionary[key].src !== ''
      );
      audioElementsSourceIds.forEach((sourceId) => {
        updateConvolversBySource(sourceId, currentRecId, selAurSimId, audioNodesDict);
      });
    }
  };

  const updateConvolversBySource = (
    sourceId: string,
    receiverId: string,
    selAurSimId: string,
    audioNodesDict: { [sourceId: string]: AudioNodesDict }
  ) => {
    const simulationAudioNodes = audioNodesDict[selAurSimId];
    const convolverIds = simulationAudioNodes?.convolverIds?.[sourceId];
    const receiverGainId = convolverIds?.[receiverId];

    if (convolverIds === undefined || receiverGainId === undefined) {
      // audioNodesDict is still being updated
      return;
    }

    // Ensure receiver and source exist and are initialized
    if (!audioEngine.storedReceiverGain[sourceId]) {
      audioEngine.storedReceiverGain[sourceId] = audioEngine.bypassInputGainDict[sourceId];
    }

    const storedConvolverNode = audioEngine.storedConvolverNode[sourceId];
    if (storedConvolverNode) {
      try {
        storedConvolverNode.disconnect(audioEngine.soaRenderer.input);
      } catch (e) {
        toast.error('Error disconnecting source gain from receiver gain. Please refresh the browser.', {
          toastId: selAurSimId,
        });
      }
    }

    const storedReceiverGain = audioEngine.storedReceiverGain[sourceId];
    if (storedReceiverGain) {
      try {
        audioEngine.sourceGainDictionary[sourceId].disconnect(storedReceiverGain);
      } catch (e) {
        toast.error('Error disconnecting source gain from receiver gain. Please refresh the browser.', {
          toastId: selAurSimId,
        });
      }
    } else {
      try {
        audioEngine.sourceGainDictionary[sourceId].disconnect(audioEngine.bypassInputGainDict[sourceId]);
      } catch (e) {
        toast.error('Error disconnecting source gain from receiver gain. Please refresh the browser.', {
          toastId: selAurSimId,
        });
      }
    }

    const receiverGains = simulationAudioNodes?.['receiverGains'][sourceId][receiverId];

    if (receiverGains) {
      // Connecting the source gain dictionary to the receiver gain
      audioEngine.sourceGainDictionary[sourceId].connect(receiverGains);

      // Connecting the receiver gain node to the respective merger node, assumed to be already created
      const mergerNode = simulationAudioNodes?.['mergerNodes'][sourceId][receiverId];

      if (mergerNode) {
        mergerNode.connect(audioEngine.soaRenderer.input);

        // Update stored nodes for quick access later
        audioEngine.storedConvolverNode[sourceId] = mergerNode;
        audioEngine.storedReceiverGain[sourceId] = receiverGains;
      }
    }
  };

  return updateReverb;
};
