import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSimMaterialIcon: FC<IconProps> = ({ fill = '#F5F5F5' }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.62696 7.87329L8.3183 5.15396C8.36537 5.11892 8.39066 5.06178 8.38492 5.00338C8.37919 4.94497 8.34329 4.89384 8.2903 4.86862L4.98696 3.31662C4.93814 3.29367 4.88123 3.29576 4.83423 3.32224C4.78723 3.34872 4.75596 3.39631 4.7503 3.44996L4.3623 7.72129C4.35519 7.78704 4.38764 7.85078 4.445 7.88371C4.50235 7.91665 4.57375 7.91256 4.62696 7.87329Z"
      fill={fill}
    />
    <path
      d="M10.4014 4.66511L15.9667 6.51644C16.1127 6.56378 16.0174 6.35978 16.0174 6.35978L12.0907 1.38578C12.0554 1.34096 11.9996 1.31732 11.9428 1.32313C11.8861 1.32895 11.8362 1.3634 11.8107 1.41444L10.3027 4.43244C10.2809 4.47593 10.2793 4.52678 10.2983 4.57156C10.3173 4.61633 10.355 4.65053 10.4014 4.66511Z"
      fill={fill}
    />
    <path
      d="M9.56018 12.377L4.86085 10.223C4.80125 10.1959 4.73125 10.2061 4.68195 10.2492C4.63264 10.2923 4.61317 10.3604 4.63218 10.423L5.80685 14.339C5.82096 14.3861 5.85515 14.4246 5.90027 14.4442C5.94539 14.4637 5.99684 14.4623 6.04085 14.4404L9.56551 12.6777C9.62292 12.649 9.65873 12.5899 9.65759 12.5257C9.65645 12.4615 9.61856 12.4037 9.56018 12.377Z"
      fill={fill}
    />
    <path
      d="M8.96656 5.91653L4.93856 8.88653C4.89125 8.92172 4.86595 8.97921 4.87197 9.03786C4.87798 9.09652 4.91442 9.14767 4.96789 9.17253L10.0559 11.5059C10.1128 11.5324 10.1797 11.5245 10.2289 11.4856C10.2782 11.4467 10.3012 11.3833 10.2886 11.3219L9.22922 6.02186C9.21885 5.96538 9.18023 5.91816 9.12693 5.89679C9.07362 5.87541 9.01308 5.88287 8.96656 5.91653Z"
      fill={fill || '#F5F5F5'}
    />
    <path
      d="M11.5493 11.1699L14.858 7.44991C14.8942 7.40803 14.9072 7.3509 14.8928 7.29745C14.8784 7.244 14.8383 7.2012 14.786 7.18324L10.444 5.73657C10.3875 5.71777 10.3253 5.73048 10.2808 5.76994C10.2362 5.80939 10.2161 5.8696 10.228 5.92791L11.262 11.0966C11.2754 11.1578 11.3223 11.2063 11.383 11.2218C11.4438 11.2373 11.5081 11.2172 11.5493 11.1699Z"
      fill={fill}
    />
    <path
      d="M13.4964 14.7453L11.0798 13.1339C11.03 13.1003 10.966 13.0962 10.9124 13.1233L6.10708 15.5233C6.10708 15.5233 5.93308 15.6746 6.20775 15.6459C7.67042 15.4939 12.4331 14.9759 13.4398 14.8666C13.5611 14.8553 13.5678 14.7926 13.4964 14.7453Z"
      fill={fill}
    />
    <path
      d="M16.1884 7.45658L11.9171 12.2626C11.8851 12.2986 11.8702 12.3467 11.8764 12.3945C11.8825 12.4423 11.909 12.4851 11.9491 12.5119L14.0504 13.9119C14.1114 13.9577 14.1623 14.0155 14.1998 14.0819C14.2664 14.1899 14.3331 14.1853 14.3818 14.0293C14.7311 12.8293 16.2544 7.59392 16.2744 7.53792C16.2944 7.48192 16.2291 7.41192 16.1884 7.45658Z"
      fill={fill}
    />
    <path
      d="M9.1344 4.16455C9.21621 4.2031 9.31383 4.16937 9.3544 4.08855L10.8524 1.08855C10.8807 1.03322 10.8756 0.966683 10.8392 0.916328C10.8027 0.865972 10.7411 0.840301 10.6797 0.84988C9.52773 1.05988 5.65506 1.76655 4.7464 1.95788C4.61306 1.98521 4.66706 2.05855 4.66706 2.05855L9.1344 4.16455Z"
      fill={fill}
    />
    <path
      d="M3.12108 8.65523C3.2063 8.65739 3.27872 8.5934 3.28708 8.50857C3.34775 7.82257 3.60841 4.90457 3.74308 3.3699C3.74988 3.28931 3.69783 3.21548 3.61965 3.19478C3.54147 3.17408 3.45971 3.2125 3.42575 3.2859L1.07175 8.41923C1.04816 8.47079 1.0524 8.53081 1.08301 8.57853C1.11361 8.62626 1.16638 8.65516 1.22308 8.65523H3.12108Z"
      fill={fill}
    />
    <path
      d="M3.39213 9.77039C3.37098 9.7008 3.30619 9.6537 3.23346 9.65506H1.34346C1.28175 9.65613 1.22568 9.69122 1.19774 9.74626C1.1698 9.8013 1.17457 9.86727 1.21013 9.91772L4.83546 15.0631C4.83546 15.0631 5.00413 15.2257 4.9388 15.0044C4.61413 13.8917 3.59146 10.4444 3.39213 9.77039Z"
      fill={fill}
    />
  </svg>
);
