import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblClfIcon: FC<IconProps> = ({ fill = '#DADADA', width = '29', height = '28' }) => (
  <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1582 6.65933L21.8418 0.341833C21.6231 0.123026 21.3264 6.6077e-05 21.017 0H7.5C6.21133 0 5.16666 1.04467 5.16666 2.33333V9.04167C5.16666 9.20275 5.29725 9.33333 5.45833 9.33333H7.20833C7.36941 9.33333 7.5 9.20275 7.5 9.04167V2.91667C7.5 2.5945 7.76116 2.33333 8.08333 2.33333H20.0417C20.2027 2.33333 20.3333 2.46392 20.3333 2.625V5.83333C20.3333 7.122 21.378 8.16667 22.6667 8.16667H25.875C26.0361 8.16667 26.1667 8.29725 26.1667 8.45833V25.0833C26.1667 25.4055 25.9055 25.6667 25.5833 25.6667H7.79166C7.63058 25.6667 7.5 25.5361 7.5 25.375V24.7917C7.5 24.6306 7.36941 24.5 7.20833 24.5H5.45833C5.29725 24.5 5.16666 24.6306 5.16666 24.7917V25.6667C5.16666 26.9553 6.21133 28 7.5 28H26.1667C27.4553 28 28.5 26.9553 28.5 25.6667V7.483C28.4996 7.17401 28.3767 6.87779 28.1582 6.65933Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3333 10.5C20.9777 10.5 21.5 11.0223 21.5 11.6667V22.1667C21.5 22.811 20.9777 23.3333 20.3333 23.3333H1.66667C1.02233 23.3333 0.5 22.811 0.5 22.1667V11.6667C0.5 11.0223 1.02233 10.5 1.66667 10.5H20.3333ZM16.25 14.1458H18.5833C18.986 14.1458 19.3125 13.8194 19.3125 13.4167C19.3125 13.014 18.986 12.6875 18.5833 12.6875H16.25C15.2032 12.6881 14.3548 13.5366 14.3542 14.5833V20.4167C14.3542 20.8194 14.6806 21.1458 15.0833 21.1458C15.486 21.1458 15.8125 20.8194 15.8125 20.4167V17.9375C15.8125 17.7764 15.9431 17.6458 16.1042 17.6458H17.4167C17.8194 17.6458 18.1458 17.3194 18.1458 16.9167C18.1458 16.514 17.8194 16.1875 17.4167 16.1875H16.1042C15.9431 16.1875 15.8125 16.0569 15.8125 15.8958V14.5833C15.8125 14.3417 16.0084 14.1458 16.25 14.1458ZM10.3958 19.6877H12.7292C13.1319 19.6877 13.4583 20.0141 13.4583 20.4168C13.4583 20.8195 13.1319 21.146 12.7292 21.146H10.3958C9.34906 21.1454 8.50064 20.2969 8.5 19.2502V13.4168C8.5 13.0141 8.82646 12.6877 9.22917 12.6877C9.63187 12.6877 9.95833 13.0141 9.95833 13.4168V19.2502C9.95833 19.4918 10.1542 19.6877 10.3958 19.6877ZM7.31185 20.4167C7.31185 20.014 6.98539 19.6875 6.58268 19.6875C5.59276 19.6875 4.67803 19.1594 4.18307 18.3021C3.68811 17.4448 3.68811 16.3885 4.18307 15.5312C4.67803 14.674 5.59276 14.1458 6.58268 14.1458C6.98539 14.1458 7.31185 13.8194 7.31185 13.4167C7.31185 13.014 6.98539 12.6875 6.58268 12.6875C4.24698 12.6875 2.35352 14.581 2.35352 16.9167C2.35352 19.2524 4.24698 21.1458 6.58268 21.1458C6.98539 21.1458 7.31185 20.8194 7.31185 20.4167Z"
      fill={fill}
    />
  </svg>
);
