import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCreateIcon: FC<IconProps> = ({ fill = '#00F5BA' }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99902 0C4.33039 0 4.59902 0.268629 4.59902 0.6V3.4H7.39902C7.73039 3.4 7.99902 3.66863 7.99902 4C7.99902 4.33137 7.73039 4.6 7.39902 4.6H4.59902V7.4C4.59902 7.73137 4.33039 8 3.99902 8C3.66765 8 3.39902 7.73137 3.39902 7.4V4.6H0.599024C0.267653 4.6 -0.00097661 4.33137 -0.000976562 4C-0.000976515 3.66863 0.267653 3.4 0.599024 3.4L3.39902 3.4V0.6C3.39902 0.268629 3.66765 0 3.99902 0Z"
      fill={fill}
    />
  </svg>
);
