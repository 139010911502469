import { useQuery } from '@tanstack/react-query';

import { MeshResultDto } from '../types';

import axios from '@/axios';

export const getMeshResult = async (meshTaskId: string): Promise<MeshResultDto> => {
  const { data } = await axios.get<MeshResultDto>(`/api/Mesh/GetLatestMeshResult?taskId=${meshTaskId}`);

  return data;
};

export const useGetMeshResult = (meshTaskId: string) => {
  return useQuery<MeshResultDto>(['meshTaskId', meshTaskId], () => getMeshResult(meshTaskId), {
    enabled: !!meshTaskId,
    refetchOnWindowFocus: false,
  });
};
