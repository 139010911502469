import { int16ToFloat32 } from './int16ToFloat32';

export const createConvolver = (buffer: Buffer, dataType: number, sampleRate: number, audioContext: AudioContext) => {
  let floatBuffer = null;

  if (dataType === 1) {
    floatBuffer = int16ToFloat32(buffer, 0, buffer.length);
  } else if (dataType === 3) {
    floatBuffer = buffer;
  }

  const convolver = audioContext.createConvolver();
  convolver.normalize = false;
  convolver.channelCount = 1;

  if (floatBuffer) {
    const audioBuffer = audioContext.createBuffer(1, floatBuffer?.length, sampleRate);
    audioBuffer.getChannelData(0).set(floatBuffer);
    convolver.buffer = audioBuffer;
  }
  return convolver;
};
