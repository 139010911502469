import { FC, useEffect, useMemo } from 'react';
import { Material, Mesh } from 'three';

import { createInterpolatedHeatmap, createSimpleHeatmap } from './utils';
import { roundFloat } from '@/utils/trebleFunctions';

import { GridReceiverResultsDto } from '@/types';

type HeatmapProps = {
  result: GridReceiverResultsDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorBar: any;
  isExporting: boolean;
  valuesChosen: number[];
  isVisible: boolean;
  isInterpolated: boolean;
  decimals: number;
  targetValue: boolean;
};

export const Heatmap: FC<HeatmapProps> = ({
  result,
  isExporting,
  colorBar,
  valuesChosen,
  isVisible,
  isInterpolated,
  decimals,
  targetValue = false,
}) => {
  const roundedValuesChosen = useMemo(() => {
    return valuesChosen.map((value) => roundFloat(value, decimals));
  }, [valuesChosen]);

  const interpolatedHeatmap = useMemo(() => {
    return createInterpolatedHeatmap(colorBar, result, roundedValuesChosen, isVisible, decimals, targetValue);
  }, [roundedValuesChosen, colorBar]);

  const simpleHeatmap = useMemo(() => {
    return createSimpleHeatmap(colorBar, result, roundedValuesChosen, isVisible, targetValue);
  }, [roundedValuesChosen, colorBar]);

  useEffect(() => {
    if (!isInterpolated) {
      simpleHeatmap?.traverse(function (object) {
        if (object instanceof Mesh) {
          object.material.visible = isVisible;
        }
      });
    }
    if (isInterpolated && interpolatedHeatmap) {
      const heatmapMaterial = interpolatedHeatmap.material as Material;
      heatmapMaterial.visible = isVisible;
    }
  }, [isVisible]);

  const handlePointerOver = () => {
    document.body.style.cursor = 'crosshair';
  };

  const handlePointerOut = () => {
    document.body.style.removeProperty('cursor');
  };

  return interpolatedHeatmap !== null && simpleHeatmap !== null ? (
    <>
      {isExporting ? (
        <>
          {/* when we export an image we only render either the simple heatmap, or the interpolated heatmap */}
          {!isInterpolated && <primitive object={simpleHeatmap} />}
          {isInterpolated && <primitive object={interpolatedHeatmap} />}
        </>
      ) : (
        <>
          {/* we always create the simple heatmap, for assigning pressure text
        but don't show the simple heatmap if interpolated is selected  */}
          <primitive
            object={simpleHeatmap}
            onPointerEnter={!isInterpolated ? handlePointerOver : null}
            onPointerLeave={!isInterpolated ? handlePointerOut : null}
            visible={!isInterpolated}
          />
          {isInterpolated && (
            <primitive
              onPointerEnter={handlePointerOver}
              onPointerLeave={handlePointerOut}
              object={interpolatedHeatmap}
            />
          )}
        </>
      )}
    </>
  ) : null;
};
