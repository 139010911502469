import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Backdrop } from '@mui/material';

import { PeriodToggle } from './components/PeriodToggle';
import { SubscriptionOption } from './components/SubscriptionOption';
import { LoadingSpinner } from '../Shared/LoadingSpinner';

import { useWindowSize } from '@/hooks';

import { useChargebee } from './utils/useChargebee';

import { SubscriptionLength, SubscriptionType } from './types';

import styles from './styles.module.scss';

export const Subscriptions = ({ subscriptionSelected }: { subscriptionSelected: SubscriptionType | null }) => {
  const windowSize = useWindowSize();
  const [searchParams] = useSearchParams();
  const childContainer = useRef<HTMLDivElement | null>(null);
  const [stubHeight, setStubHeight] = useState(0);
  const [monthlyPeriod, setMonthlyPeriod] = useState(false);

  const onChargebeeLoaded = () => {
    const subscriptionType = searchParams.get('type');
    const subscriptionPeriod = searchParams.get('period');

    // Check if the user has not yet subscribed (trial or earlyBird)
    // and then check if there are query parameters that should trigger the subscription popup
    if (
      subscriptionSelected &&
      ['Trial', 'EarlyBird'].includes(subscriptionSelected) &&
      subscriptionType &&
      subscriptionPeriod &&
      ['flexible', 'unlimited'].includes(subscriptionType) &&
      ['yearly', 'monthly'].includes(subscriptionPeriod)
    ) {
      setMonthlyPeriod(subscriptionPeriod === 'monthly');
      subscribe(subscriptionType as SubscriptionType, subscriptionPeriod as SubscriptionLength);
    }
  };

  const { subscribe, isSubscriptionPending } = useChargebee(onChargebeeLoaded);

  useEffect(() => {
    if (childContainer.current) {
      const height = childContainer.current.clientHeight;
      setStubHeight(height);
    }
  }, [childContainer, windowSize]);

  return (
    <>
      {/* We need to make the parent element take the height of the child element to enable scroll on the page for small screens */}
      <div style={{ height: stubHeight }} className={styles['subscription-container-stub']}></div>
      <div ref={childContainer} className={styles['subscription-container']}>
        <div className={styles['toggle-container']}>
          <PeriodToggle
            checked={monthlyPeriod}
            label={'Subscription period toggle'}
            onChangeToggle={() => setMonthlyPeriod(!monthlyPeriod)}
          />
        </div>
        <div className={styles['subscription-items']}>
          <SubscriptionOption
            type="flexible"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
          <SubscriptionOption
            type="unlimited"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
          <SubscriptionOption
            type="enterprise"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
        </div>
      </div>
      <Backdrop sx={{ backdropFilter: 'blur(3px)', zIndex: 100 }} open={isSubscriptionPending}>
        <LoadingSpinner />
      </Backdrop>
    </>
  );
};
