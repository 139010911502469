import { useEffect, useState } from 'react';

export const TrblTruncate = ({ text, strLen = 20 }: { text: string; strLen?: number }) => {
  const [truncatedText, setTruncatedText] = useState('');
  const separator = '...';

  useEffect(() => {
    if (text.length <= strLen) {
      setTruncatedText(text);
    } else {
      const sepLen = separator.length;
      const charsToShow = strLen - sepLen;
      const frontChars = Math.ceil(charsToShow / 2);
      const backChars = Math.floor(charsToShow / 2);
      const newText = `${text.substring(0, frontChars)}${separator}${text.substring(text.length - backChars)}`;
      setTruncatedText(newText);
    }
  }, [text]);

  return <span>{truncatedText}</span>;
};
