import { FC, useEffect, useState } from 'react';

import { useSimulationContext } from '@/context/SimulationContext';

import { AuralizerButton } from './AuralizerButton';
import { ResultsButton } from './ResultsButton';
import { RunButton } from './RunButton';
import { SimulationDates } from './SimulationDates';

import './styles.scss';

type SidePanelBottomRowProps = {
  sidepanelCollapsed: boolean;
};

export const SidePanelBottomRow: FC<SidePanelBottomRowProps> = ({ sidepanelCollapsed }) => {
  const {
    simulationState: { selectedSimulation, lastSimRunDate, userTouched, originalSim },
  } = useSimulationContext();
  const [simStatus, setSimStatus] = useState<number | null>(null);

  useEffect(() => {
    setSimStatus(selectedSimulation?.extra.status);
  }, [selectedSimulation?.extra.status]);

  return (
    <div className={`bottom-container ${sidepanelCollapsed ? 'collapsed' : ''}`}>
      <SimulationDates simulation={selectedSimulation} lastSimRunDate={lastSimRunDate} />
      <div className="button-container">
        <div>
          <ResultsButton
            key={selectedSimulation?.id}
            status={simStatus}
            selectedSimulation={selectedSimulation}
            originalSimulation={originalSim}
            userTouched={userTouched}
          />
          <AuralizerButton
            status={simStatus}
            selectedSimulation={selectedSimulation}
            originalSimulation={originalSim}
            userTouched={userTouched}
          />
        </div>
        <RunButton status={simStatus} simulation={selectedSimulation} />
      </div>
    </div>
  );
};
