import { ErrorPage } from './Error';

export const ErrorUnauhorizedPage = () => {
  return (
    <ErrorPage
      title=""
      header="Unauthorized"
      text="We're sorry but it looks like you don't have access to this page"
      color="red"
    />
  );
};
