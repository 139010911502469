import { FC } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { Text } from '@/components/Shared/Text';

import { ServiceStatus } from '@/hooks';

import './styles.scss';

export const ServiceBanner: FC = () => {
  const {
    state: { serviceMessage },
  } = useBaseContext();

  return (
    <>
      {serviceMessage?.status === ServiceStatus.warning ? (
        <div className={'warning-banner'}>
          <div className={'warning-banner__content'}>
            <Text color="#171717" type="semibold-12px">
              {serviceMessage?.message}
            </Text>
          </div>
        </div>
      ) : null}
    </>
  );
};
