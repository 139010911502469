import { useEffect, useState } from 'react';

import { Box, FormControl } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';
import { TrblIcon } from '@/components/Icons';

const POPUP_TITLE_NEW = 'Create new auralization preset';
const POPUP_TITLE_EDIT = 'Edit preset details';

type SavePresetPopupProps = {
  onSubmit: (name: string, description: string) => Promise<boolean>;
  onClose: () => void;
  defaultName?: string;
  defaultDescription?: string;
  isEditing?: boolean;
  saveButtonLabel?: string;
};

export const SavePresetPopup: React.FC<SavePresetPopupProps> = ({
  onClose,
  onSubmit,
  defaultName = '',
  defaultDescription = '',
  isEditing = false,
  saveButtonLabel = 'Save',
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  // Form values
  const [name, setName] = useState(defaultName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState(defaultDescription);

  // Form validation
  useEffect(() => {
    if (name.length > 0 && (defaultName !== name || defaultDescription !== description)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, description, defaultName, defaultDescription]);

  const handleSavePreset = async (event: React.MouseEvent<Element, MouseEvent>) => {
    setIsSubmitting(true);
    event.preventDefault();
    await onSubmit(name, description);
    setIsSubmitting(false);
  };

  return (
    <TrblPopup
      width="400px"
      hideBackdrop={false}
      aria-labelledby={isEditing ? POPUP_TITLE_EDIT : POPUP_TITLE_NEW}
      sx={{ fontSize: '12px' }}
      open={true}>
      <form>
        <TrblPopupTitle onClose={onClose}>{isEditing ? POPUP_TITLE_EDIT : POPUP_TITLE_NEW}</TrblPopupTitle>
        <TrblPopupContent>
          <FormControl>
            <Box component={'div'} paddingTop={2}>
              <TextField autoFocus placeholder="Preset name (required)" value={name} onChange={setName} />
            </Box>
          </FormControl>
          <FormControl>
            <Box component="div" paddingTop={2}>
              <TextArea placeholder="Preset description" value={description} onChange={setDescription} />
              <TrblTooltip
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {`Please note that the preset description will be visible to external parties once the Auralization preset is shared.
                    \n While the description is optional, it is recommended to provide a short description of the simulation(s) and their setup.
                    `}
                  </div>
                }>
                <span style={{ position: 'absolute', cursor: 'pointer', width: '11px', top: '20px', right: '6px' }}>
                  <TrblIcon icon="info" />
                </span>
              </TrblTooltip>
            </Box>
          </FormControl>
        </TrblPopupContent>
        <TrblPopupActions>
          <SecondaryButton
            type="submit"
            disabled={!isFormValid || isSubmitting}
            width={'fit-content'}
            label={saveButtonLabel}
            onClick={handleSavePreset}
          />
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
