import { useEffect, useState } from 'react';

import { TrblSkeleton } from '@/components/Shared/TrblSkeleton';
import { Marker } from '@/components/SourceRecieverSettings/Marker';
import { useAuralizerContext } from '../AuralizerContext';

import { useClickReceiver } from '../hooks/useClickReceiver';
import { useUpdatedReverb } from '../hooks/useUpdateReverb';

import { Receiver } from '@/types';

import '../style.scss';

type ReceiverPoint = {
  receiver: Receiver;
  index: number;
  loading: boolean;
};

export const ReceiverPoint = ({ receiver, index, loading }: ReceiverPoint) => {
  const { selectedReceiver, selectedAurSim, audioNodesDict, audioElementDictionary } = useAuralizerContext();

  const [receiverLabel, setReceiverLabel] = useState('');

  const clickReceiver = useClickReceiver();
  const updateReverb = useUpdatedReverb();

  useEffect(() => {
    if (selectedReceiver?.receiver.id === receiver.id && selectedAurSim) {
      updateReverb(selectedReceiver.receiver.id, selectedAurSim.id, audioNodesDict, audioElementDictionary);
    }
  }, [selectedReceiver?.receiver.id, selectedAurSim, audioNodesDict, audioElementDictionary]);

  useEffect(() => {
    if (selectedAurSim) {
      selectedAurSim.receivers.forEach((aurReceiver) => {
        if (aurReceiver.id === receiver.id) {
          setReceiverLabel(aurReceiver.label);
        }
      });
    }
  }, [selectedAurSim]);

  return (
    <li key={receiver.id} className="receiver-list-item">
      {loading ? (
        <TrblSkeleton
          style={{
            height: 22,
            margin: '18px',
          }}
        />
      ) : (
        <button
          className={`shared-receiver ${selectedReceiver?.index === index ? 'selected' : ''}`}
          onClick={() => clickReceiver(receiver, index)}>
          <div>
            <Marker color="blue" label={(index + 1).toString()} small isSelected={selectedReceiver?.index === index} />
            <span className="receiver-text">{receiverLabel || `Receiver ${index + 1}`}</span>
          </div>
        </button>
      )}
    </li>
  );
};
