import { stringify as jsonBigIntStringify } from 'json-bigint';
import JSZip from 'jszip';

import axios from '@/axios';

type UploadSlot = {
  url: string;
  id: string;
};

const getUploadSlot = async () => {
  const response = await axios.get(`/api/Upload/GetUploadSlot`);

  return response.status == 200 ? { url: response.data.uploadUrl, id: response.data.id } : { url: '', id: '' };
};

const postFileAndGetUploadId = async (file: File | Blob, filename: string, uploadSlot: UploadSlot) => {
  // we make the File/Blob into a new Blob and put it into FormData and a RequestInit to be able to send it correctly
  const fileBlob = new Blob([file]);
  const fileFormData = new FormData();
  fileFormData.append('file', fileBlob, filename);

  const fileRequest: RequestInit = {
    method: 'POST',
    mode: 'no-cors',
    body: fileFormData,
  };

  // with the uploadSlot we can upload the fileRequest to the URL of the uploadSlot
  await fetch(uploadSlot.url, fileRequest);

  // with the ID of the uploadSlot we can consume (delete) the uploadSlot and in return we get a fileUploadId which is
  // what our system needs to be able to download the file in a secure manner.
  const fileUploadId = await consumeSlot(uploadSlot.id);

  return fileUploadId;
};

const uploadFile = async (file: File) => {
  // to be able to upload a file to our S3 bucket we need to obtain a uploadSlot (because security)
  const uploadSlot = await getUploadSlot();

  return postFileAndGetUploadId(file, file.name, uploadSlot);
};

const uploadAndZipFile = async (file: File) => {
  // to be able to upload a file to our S3 bucket we need to obtain a uploadSlot (because security)
  const uploadSlot = await getUploadSlot();

  const zip = new JSZip();
  zip.file(file.name, file);

  // Generate the complete zip file
  const zipData = await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 6,
    },
  });

  // remove the file extention from file name and replace with .zip
  const zipFileName = file.name.replace(/\.[^/.]+$/, '.zip');

  return postFileAndGetUploadId(zipData, zipFileName, uploadSlot);
};

export const uploadFileToTreble = (file: File) => {
  return uploadFile(file);
};

export const uploadAndZipFileToTreble = (file: File) => {
  return uploadAndZipFile(file);
};

// when a uploadSlot has been created we need to consume (delete) the uploadSlot and in return we get a fileUploadId which is
// what our system needs to be able to download the file in a secure manner.
const consumeSlot = async (uploadSlotId: string) => {
  const fileUploadId = await axios
    .delete<string>(`/api/Upload/ConsumeUploadSlot?uploadSlotId=${uploadSlotId}`, {
      // the fileUploadId is a bigInt, this stringifies the response so it comes out correctly
      transformResponse: function (response) {
        return jsonBigIntStringify(response);
      },
    })
    .then((response) => {
      // the fileUploadId is a bigInt and has been stringified in the response
      // this cleans and pulls out the ID from the response object with RegExp
      const cleanResponse = JSON.stringify(response.data).replaceAll('\\', '');
      const regExpForId = /"id":(\d+)/g;
      const fileUploadId = getObjectWithRegExp(regExpForId, cleanResponse)[0];

      return fileUploadId;
    });

  return fileUploadId;
};

// helper function that applies RegExp to a string and returns it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getObjectWithRegExp(regexp: any, str: string) {
  const array = [...str.matchAll(regexp)];
  return array.map((m) => m[1]);
}
