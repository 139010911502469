import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { FitMaterial } from '@/types';

import axios from '@/axios';

const fitMaterial = async (absorptionTarget: number[]): Promise<FitMaterial> => {
  const materialFitData = {
    Absorption_target: absorptionTarget,
    Membrane: false,
    quick: false,
    material_type: 4,
  };
  const { data } = await axios.post(`/api/MaterialFit/StartMaterialFitJob`, materialFitData);
  return data;
};

export const useFitMaterial = () => {
  return useMutation(async (absorptionTarget: number[]) => await fitMaterial(absorptionTarget), {
    onError: () => {
      toast.error('An error occurred while fitting the material. Please refresh the browser.');
    },
  });
};
