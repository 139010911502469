import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useBaseContext } from '@/context/BaseContext';

import { useFeatureFlags } from '@/components/FeatureToggles';
import { Subscriptions } from '@/components/Subscriptions';
import { SubscriptionType } from '@/components/Subscriptions/types';

import { USER_CONTENT } from './constants';

import { config } from '@/__config__/config';

const { viteAuth0LogoutUri } = config;

import styles from './styles.module.scss';

export const InformationPage = ({ accessDisabled = false }: { accessDisabled?: boolean }) => {
  const [subscriptionOffer, setSubscriptionOffer] = useState<string>();
  const [userType, setUserType] = useState<string>();
  const {
    state: { subscriptionInfo, userInfo },
  } = useBaseContext();
  const { logout } = useAuth0();

  const { automaticBillingFeature } = useFeatureFlags();

  useEffect(() => {
    if (userInfo?.roles.includes('Admin')) {
      setUserType('admin');
    } else {
      setUserType('regular');
    }
  }, [userInfo]);

  useEffect(() => {
    if (accessDisabled) {
      setSubscriptionOffer('accessDisabled');
    } else if (subscriptionInfo?.type === 'Trial') {
      if (subscriptionInfo.daysUntilExpired && subscriptionInfo.daysUntilExpired <= 0) {
        setSubscriptionOffer('trialExpired');
      } else {
        setSubscriptionOffer('trial');
      }
    } else if (subscriptionInfo?.type === 'EarlyBird') {
      if (subscriptionInfo.daysUntilExpired && subscriptionInfo.daysUntilExpired <= 0) {
        setSubscriptionOffer('earlyBirdExpired');
      } else {
        setSubscriptionOffer('earlyBird');
      }
    } else {
      setSubscriptionOffer('paid');
    }
  }, [subscriptionInfo, accessDisabled]);

  return (
    <div className={styles['information-page']}>
      <button className={styles['logout-button']} onClick={() => logout({ returnTo: viteAuth0LogoutUri })}>
        Logout
      </button>
      <div
        className={`${styles['hero-image']} ${
          userType !== 'admin' || accessDisabled || !automaticBillingFeature ? styles['hero-image-large'] : ''
        }`}>
        {subscriptionOffer && userType && (
          <div
            className={`${styles['information-page-header']} ${
              userType !== 'admin' || accessDisabled ? styles['no-subscriptions'] : ''
            }`}>
            <h1> {USER_CONTENT[subscriptionOffer][userType].header} </h1>
            <p> {USER_CONTENT[subscriptionOffer][userType].subheader} </p>
          </div>
        )}
      </div>
      {userType === 'admin' && !accessDisabled && automaticBillingFeature && (
        <Subscriptions subscriptionSelected={(subscriptionInfo?.type as SubscriptionType) ?? null} />
      )}
    </div>
  );
};
