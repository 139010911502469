import { useEffect } from 'react';

export const useArrowKeyPress = (onLeftPress: (() => void) | null, onRightPress: (() => void) | null) => {
  useEffect(() => {
    const handleKeyDown =
      onLeftPress && onRightPress
        ? (event: KeyboardEvent) => {
            switch (event.key) {
              case 'ArrowLeft':
                onLeftPress();
                break;
              case 'ArrowRight':
                onRightPress();
                break;
              default:
                break;
            }
          }
        : null;

    if (handleKeyDown) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (handleKeyDown) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [onLeftPress, onRightPress]);
};
