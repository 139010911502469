import { FC } from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { PageLayout } from '@/components';
import { TrblChevronLeft } from '@/components/Icons';
import { Organizations } from '@/components/ManagementPortal';

export const OrganizationsPage: FC = () => {
  return (
    <PageLayout
      extraHeader={
        <div className="breadcrumbs-div">
          <Link to="/">
            <IconButton className="back-btn">
              <TrblChevronLeft />
            </IconButton>
          </Link>
          <div className="top-info-text">
            <span className="active">{'Organizations'}</span>
          </div>
        </div>
      }>
      <Organizations />
    </PageLayout>
  );
};
