import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { ActionType as MeshActionType, useMeshContext } from '@/context/MeshContext';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box, CircularProgress, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { Divider } from '@/components/Shared/Divider';
import { Label } from '@/components/Shared/Label';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { RadioButtonGroup } from '@/components/Shared/RadioButton';
import { useFeatureFlags } from '@/components/FeatureToggles/hooks/useFeatureFlags';
import { TrblAlertIcon, TrblTimewatchIcon } from '@/components/Icons';
import { FrequencySlider } from './FrequencySlider';

import { useSolverSettings } from './hooks';
import { useEstimatedImpulseLength } from './hooks/useEstimatedImpulseLength';
import { useGetEstimatedSimulationTime } from './hooks/useGetEstimatedSimulationTime';
import { useUpdateSolverSettings } from './hooks/useUpdateSolverSettings';

import { defaultEnergyDecayThreshold, nonWatertightMessage } from '@/utils/constants';

import { getEstimatedTransitionFrequency } from './utils';

import { PresetTypes } from './types';
import { Simulation } from '@/types';

enum SolverTypes {
  GA = 'GA',
  DG = 'DG',
  Hybrid = 'Hybrid',
}

const minImpulseResponse = 0.005;
const maxImpulseResponse = 20;

type SolverSettingsFormProps = {
  presetType: PresetTypes;
  selectedSimulation: Simulation;
  readonly: boolean;
};

export const SolverSettingsForm: FC<SolverSettingsFormProps> = ({ presetType, selectedSimulation, readonly }) => {
  const { modelVolume, modelInformation } = useModelContext();
  const { dispatch } = useSimulationContext();
  const { dispatch: editorDispatch } = useEditorContext();

  const { imageSourceOrderFeature } = useFeatureFlags();

  const { dgSettings, gaSettings } = selectedSimulation.solverSettings;

  const [transitionFrequency, setTransitionFrequency] = useState<number | undefined>(dgSettings?.crossoverFrequency);
  const [impulseResponseLength, setImpulseResponseLength] = useState<number | undefined>();
  const [taskType, setTaskType] = useState(selectedSimulation.taskType);
  const [numberOfRays, setNumberOfRays] = useState<number | undefined>(gaSettings?.rayCount);
  const [imageSourceOrder, setImageSourceOrder] = useState<number | undefined>(gaSettings?.reflectionOrder);
  const [energyDecayThreshold, setEnergyDecayThreshold] = useState<number | null>(dgSettings?.energyDecayThreshold);
  const [autoStop, setAutoStop] = useState(dgSettings.energyDecayThreshold ? true : false);
  const [isEstimating, setIsEstimating] = useState(false);
  const [estimatedSimulationTime, setEstimatedSimulationTime] = useState<string>();
  const getEstimatedSimulationTime = useGetEstimatedSimulationTime();
  const { meshHasError, completedMeshTasks, dispatch: meshDispatch } = useMeshContext();

  const updateSolverSettings = useUpdateSolverSettings();
  const {
    saveImpulseResponseLength,
    saveEnergyDecayThreshold,
    saveTaskType,
    saveTransitionFrequency,
    saveImageSourceOrder,
    saveNumberOfRays,
    startNewEstimation,
    activeMeshTasks,
  } = useSolverSettings();

  const [prevPresetType, setPrevPresetType] = useState(presetType);
  const estimateImpulseLength = useEstimatedImpulseLength();

  // run this once when the simulation is loaded in the Editor
  useEffect(() => {
    if (!readonly) {
      let impulseLength = dgSettings.impulseLengthSeconds;

      // if autostop is selected (edt has value) then calculate default impulse response length
      if (dgSettings.energyDecayThreshold) {
        const estImpulseLength = estimateImpulseLength(selectedSimulation?.modelSettings?.materialIdByObjectId || {});

        if (estImpulseLength !== undefined) {
          impulseLength = estImpulseLength;
        }
      }
      // a catch for if Default or Survey selected and not set to autostop (edt is null), then set to autostop
      // (unless simulation is Complete or InProgress and not being edited)
      else if (!dgSettings.energyDecayThreshold && presetType !== PresetTypes.Advanced) {
        if (
          (selectedSimulation.extra?.status !== 2 && selectedSimulation.extra?.status !== 1) ||
          (selectedSimulation.extra?.status == 2 && selectedSimulation.hasBeenEdited)
        ) {
          handleAutoStopChange('edt');
        }
      }

      setImpulseResponseLength(impulseLength);
    }
  }, []);

  useEffect(() => {
    if (presetType !== prevPresetType) {
      saveAndUpdate();
      // the default of all presets should be Autostop is ON
      setAutoStop(true);
    }
  }, [presetType]);

  const saveAndUpdate = async () => {
    let newTransitionFrequency = null;

    // If we select survey we don't care what the value of the transition frequency is (and api does not allow null)
    // If we are on default (and coming from advanced) we need to recalculate the default transition frequency
    if (presetType === PresetTypes.Default && prevPresetType === PresetTypes.Advanced) {
      newTransitionFrequency = getEstimatedTransitionFrequency(modelVolume || 0);
      setTransitionFrequency(newTransitionFrequency);
    }

    let taskType = presetType === PresetTypes.Survey ? SolverTypes.GA : SolverTypes.Hybrid;
    const numberOfRays = presetType === PresetTypes.Survey ? 5000 : 10000;
    const imageSourceOrder = presetType === PresetTypes.Survey ? 1 : 2;
    const energyDecayThreshold =
      presetType !== PresetTypes.Advanced ? defaultEnergyDecayThreshold : dgSettings.energyDecayThreshold;
    const estImpulseLength =
      estimateImpulseLength(selectedSimulation.modelSettings?.materialIdByObjectId || {}, energyDecayThreshold) ??
      dgSettings.impulseLengthSeconds;
    dgSettings.impulseLengthSeconds;
    // Update the local state
    if (modelInformation?.nonWatertight) {
      taskType = SolverTypes.GA;
    }

    setTaskType(taskType);
    setNumberOfRays(numberOfRays);
    setImageSourceOrder(imageSourceOrder);
    setPrevPresetType(presetType);
    setEnergyDecayThreshold(energyDecayThreshold);
    setImpulseResponseLength(estImpulseLength);
    // Save new settings
    const updatedSimulation = {
      ...selectedSimulation,
      taskType,
      settingsPreset: presetType,
      solverSettings: {
        ...selectedSimulation.solverSettings,
        gaSettings: {
          ...selectedSimulation.solverSettings.gaSettings,
          reflectionOrder: imageSourceOrder,
          rayCount: numberOfRays,
          energyDecayThreshold: energyDecayThreshold,
          impulseLengthSeconds: estImpulseLength,
          crossoverFrequency: newTransitionFrequency ?? selectedSimulation.solverSettings.gaSettings.crossoverFrequency,
        },
        dgSettings: {
          ...selectedSimulation.solverSettings.dgSettings,
          energyDecayThreshold: energyDecayThreshold,
          impulseLengthSeconds: estImpulseLength,
          crossoverFrequency: newTransitionFrequency ?? selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
        },
      },
    };

    await updateSolverSettings(updatedSimulation);
  };

  const handleTaskTypeChange = (taskType: string) => {
    setTaskType(taskType);
    saveTaskType(taskType);
    editorDispatch({
      type: EditorActionType.SET_TASK_TYPE,
      taskType,
    });
  };

  const handleAutoStopChange = (type: string) => {
    // set the Impulse response to default
    setEstimatedImpulseResponseLength(defaultEnergyDecayThreshold);

    if (type === 'edt') {
      setAutoStop(true);
      setEnergyDecayThreshold(defaultEnergyDecayThreshold);
      saveEnergyDecayThreshold(defaultEnergyDecayThreshold);
    } else if (type === 'irl') {
      setAutoStop(false);
      setEnergyDecayThreshold(null);
      saveEnergyDecayThreshold(null);
    }
  };

  const setEstimatedImpulseResponseLength = (edt: number | null) => {
    const irl =
      estimateImpulseLength(selectedSimulation.modelSettings!.materialIdByObjectId, edt) ??
      dgSettings.impulseLengthSeconds;
    setImpulseResponseLength(irl);
  };

  // estimating sim time formula depends on receivers and grid receivers length
  // so every time we change those props we need to recalculate
  useEffect(() => {
    if (impulseResponseLength !== undefined && numberOfRays !== undefined && !readonly) {
      // If DG only or Hybrid
      if (taskType !== 'GA' && transitionFrequency) {
        meshDispatch({ type: MeshActionType.SET_CURRENT_SIM_ESTIMATE, estimate: null });
        getEstSimTime(impulseResponseLength, transitionFrequency, numberOfRays);
        // If GA only
      } else if (taskType == 'GA') {
        getEstSimTime(impulseResponseLength, 0, numberOfRays);
      }
    }
  }, [
    transitionFrequency,
    impulseResponseLength,
    numberOfRays,
    activeMeshTasks.length,
    completedMeshTasks.length,
    taskType,
    selectedSimulation.gridReceivers,
    selectedSimulation.receivers,
  ]);

  useEffect(() => {
    if (meshHasError) {
      toast.warning(
        "Failed to prepare geometry for the wave solver. Select 'Geometrical acoustics solver only' in Advanced settings to run a simulation, or contact support.",
        { autoClose: false }
      );
    }
  }, [meshHasError]);

  const getEstSimTime = async (impulseResponseLength: number, transitionFrequency: number, rayCount: number) => {
    let irl = impulseResponseLength;
    if (impulseResponseLength < minImpulseResponse) {
      irl = minImpulseResponse;
    } else if (impulseResponseLength > maxImpulseResponse) {
      irl = maxImpulseResponse;
    }

    // if GA only then don't look at activeMesh tasks, and set transitionFrequency as 0
    if (taskType == 'GA') {
      const data = await getEstimatedSimulationTime(0, impulseResponseLength, rayCount, selectedSimulation, 'GA');
      if (data) {
        setEstimatedSimulationTime(data.estimate.simulationTimeString);
      } else {
        setEstimatedSimulationTime(undefined);
      }
      return;
    }

    if (
      activeMeshTasks.length === 0 ||
      activeMeshTasks.findIndex((x) => x.transitionFrequency === transitionFrequency) === -1
    ) {
      const data = await getEstimatedSimulationTime(transitionFrequency, irl, rayCount, selectedSimulation, taskType);
      if (data) {
        setEstimatedSimulationTime(data.estimate.simulationTimeString);
        dispatch({
          type: ActionType.UPDATE_MESH_TASK_ID,
          meshTaskId: data.meshTaskId,
        });
      } else {
        setEstimatedSimulationTime(undefined);
      }
      setIsEstimating(false);
    } else {
      setIsEstimating(true);
    }
  };

  return (
    <Stack spacing={'20px'} flex="1 1 0">
      {presetType === PresetTypes.Survey && (
        <Stack spacing={'8px'} mt={'8px'}>
          <TrblAlertIcon />
          <Box component={'div'} fontWeight={400} fontSize="12px" lineHeight={'20px'} color={'#FFFFFF'}>
            Survey settings will generate a quick simulation using only the geometrical acoustics solver.
          </Box>
        </Stack>
      )}
      <Stack spacing={'15px'} mt={'4px'}>
        {presetType == PresetTypes.Default && (
          <>
            <Label sx={{ marginTop: '4px' }}>Transition frequency</Label>
            <FrequencySlider
              value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
              disabled={true}
              readOnly={readonly}
              onChange={setTransitionFrequency}
              onChangeCommitted={saveTransitionFrequency}
              singleColorStyling={taskType === SolverTypes.DG}
            />
          </>
        )}
        {presetType === PresetTypes.Advanced && (
          <>
            <Label sx={{ marginTop: '4px' }}>Transition frequency</Label>
            <FrequencySlider
              value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
              disabled={taskType === SolverTypes.GA}
              readOnly={readonly}
              onChange={setTransitionFrequency}
              onChangeCommitted={saveTransitionFrequency}
              singleColorStyling={taskType === SolverTypes.DG}
            />
            <Divider marginOffset={20} marginTopOffset={20} />
            <Box component="p" fontWeight={700}>
              Termination criterion
            </Box>
            <Box component="div" display="flex" flexDirection={'row'}>
              <Stack width={'172px'} padding={'2px 0 0'}>
                <RadioButtonGroup
                  options={[
                    {
                      text: 'Energy decay threshold',
                      value: 'edt',
                      readonly: readonly,
                    },
                    {
                      text: 'Impulse response length',
                      value: 'irl',
                      readonly: readonly,
                    },
                  ]}
                  selectedValue={autoStop ? 'edt' : 'irl'}
                  onChange={handleAutoStopChange}
                />
              </Stack>

              <Stack spacing={'8px'} width={'50%'} alignItems={'self-end'}>
                <TrblNumberInput
                  label={''}
                  value={energyDecayThreshold ?? undefined}
                  onChange={(value) => {
                    if (value === null || value == undefined) setEnergyDecayThreshold(null);
                    else setEnergyDecayThreshold(value);
                  }}
                  onBlur={(value) => {
                    if (value !== undefined) {
                      saveEnergyDecayThreshold(value);
                      setEstimatedImpulseResponseLength(value);
                    }
                  }}
                  decimals={0}
                  min={10}
                  max={60}
                  endAdornment={'dB'}
                  step={1}
                  disabled={!autoStop}
                  blurOnStep={false}
                  readOnly={readonly}
                />
                <TrblNumberInput
                  label={autoStop ? <span style={{ color: '#999999' }}></span> : ''}
                  value={autoStop ? undefined : impulseResponseLength}
                  onChange={setImpulseResponseLength}
                  onBlur={(value) => {
                    if (value !== undefined && value < minImpulseResponse) {
                      value = minImpulseResponse;
                      setImpulseResponseLength(minImpulseResponse);
                    }

                    saveImpulseResponseLength(value);
                  }}
                  decimals={3}
                  min={0} // Needs to be set to 0 so that the step doesn't react weird. Actually this min is 0.005
                  max={maxImpulseResponse}
                  endAdornment={'s'}
                  step={0.1}
                  disabled={autoStop}
                  readOnly={readonly}
                  blurOnStep={false}
                />
              </Stack>
            </Box>
          </>
        )}
      </Stack>
      {presetType !== PresetTypes.Survey && !readonly && (
        <SecondaryButton
          label={
            isEstimating
              ? 'Estimating...'
              : estimatedSimulationTime
              ? `${estimatedSimulationTime} estimated`
              : meshHasError
              ? 'Error preparing geometry'
              : 'Estimate simulation'
          }
          icon={isEstimating ? <CircularProgress size={15} /> : <TrblTimewatchIcon />}
          sx={
            estimatedSimulationTime
              ? {
                  ':hover': {
                    backgroundColor: '#3c3c3c',
                    cursor: 'default',
                  },
                  margin: '25px 0 5px !important',
                }
              : { margin: '25px 0 5px !important' }
          }
          disabled={isEstimating}
          onClick={() => {
            if (
              !estimatedSimulationTime &&
              transitionFrequency &&
              impulseResponseLength !== undefined &&
              impulseResponseLength >= minImpulseResponse &&
              impulseResponseLength <= maxImpulseResponse
            ) {
              setIsEstimating(true);
              startNewEstimation(transitionFrequency, selectedSimulation.modelId);
            }
          }}
        />
      )}

      {presetType === PresetTypes.Advanced && (
        <>
          <RadioButtonGroup
            options={[
              {
                text: 'Both solvers',
                value: SolverTypes.Hybrid.toString(),
                disabled: !!modelInformation?.nonWatertight,
                readonly: readonly,
                tooltip: modelInformation?.nonWatertight ? nonWatertightMessage : '',
              },
              {
                text: 'Wave solver only',
                value: SolverTypes.DG.toString(),
                disabled: !!modelInformation?.nonWatertight,
                readonly: readonly,
                tooltip: modelInformation?.nonWatertight ? nonWatertightMessage : '',
              },
              {
                text: 'Geometrical acoustics solver only',
                value: SolverTypes.GA.toString(),
                readonly: readonly,
              },
            ]}
            selectedValue={taskType}
            onChange={handleTaskTypeChange}
          />
          <Divider marginOffset={20} marginTopOffset={25} />
          <Box
            component="div"
            sx={{
              transition: '0.2s opacity',
              opacity: taskType === SolverTypes.DG ? '0.5 !important' : '1',
            }}
            fontWeight={700}>
            Geometrical acoustics
          </Box>
          <Stack spacing="10px" paddingBottom="20px">
            <TrblNumberInput
              label={'Number of radiosity rays'}
              value={taskType !== SolverTypes.DG ? numberOfRays : undefined}
              onChange={setNumberOfRays}
              onBlur={saveNumberOfRays}
              step={100}
              min={1000}
              max={100000}
              disabled={taskType === SolverTypes.DG}
              readOnly={readonly}
              blurOnStep={false}
            />

            <TrblNumberInput
              label={'Image source order'}
              value={taskType !== SolverTypes.DG ? imageSourceOrder : undefined}
              onChange={setImageSourceOrder}
              onBlur={saveImageSourceOrder}
              min={0}
              // If user has imageSourceOrder featureFlag then max is 20, default max is 10
              max={imageSourceOrderFeature ? 20 : 10}
              disabled={taskType === SolverTypes.DG}
              readOnly={readonly}
              blurOnStep={false}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
