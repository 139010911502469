import { RefObject, useEffect } from 'react';

import { ActionType, useLibraryPanelContext } from '../LibraryPanelContext';

export const useHandleKeyDown = (inputRef: RefObject<HTMLInputElement>, libraryPanelRef: RefObject<HTMLDivElement>) => {
  const { dispatch } = useLibraryPanelContext();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    if (
      // mac search
      (event.metaKey && key === 'f') ||
      (event.metaKey && key === 'F') ||
      // windows search
      (event.ctrlKey && key === 'f') ||
      (event.ctrlKey && key === 'F')
    ) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({
        type: ActionType.SET_HIGHLIGHTED_ITEM,
        highlightedItemId: null,
      });
      inputRef?.current?.focus();
      inputRef?.current?.select();
    } else if (key === 'ArrowDown') {
      if (document.activeElement === inputRef.current) {
        let selectedPresetRow = libraryPanelRef?.current?.querySelector('.library-row.assigned');
        if (selectedPresetRow) {
          (selectedPresetRow as HTMLElement)?.focus();
        } else {
          selectedPresetRow = libraryPanelRef?.current?.querySelector('.library-row');
          if (selectedPresetRow) {
            (selectedPresetRow as HTMLElement)?.focus();
          }
        }
      }
    }
  };

  return handleKeyDown;
};
