import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { MaterialDto } from '@/types';

import axios from '@/axios';

type PerformLambdaMaterialFitting = {
  inputType: string;
  coefficientsReal: number[];
  coefficientsImaginary: number[];
};

const performLambdaMaterialFitting = async (materialFitting: PerformLambdaMaterialFitting): Promise<MaterialDto> => {
  const { data } = await axios.post(`/api/MaterialFit/PerformLambdaMaterialFitting`, materialFitting);
  return data;
};

export const usePerformLambdaMaterialFitting = () => {
  return useMutation(
    async (materialFitting: PerformLambdaMaterialFitting) => await performLambdaMaterialFitting(materialFitting),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data.message) toast.error(error.response?.data.message);
      },
    }
  );
};
