import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';

import { usePerformLambdaMaterialBuilder } from '@/hooks/Material/usePerformLambdaMaterialBuilder';

import { PorousAbsorberInputType } from '../types';

export const BuilderMaterialFit = () => {
  const { formDisabled, airCavityFormValues, porousAbsorberFormValues, dispatch } = useCreateMaterialContext();

  const { mutate: fitMaterial } = usePerformLambdaMaterialBuilder();

  const calculateAbsorption = () => {
    dispatch({
      type: ActionType.SET_DISABLED_FORM,
      formDisabled: true,
    });

    fitMaterial(
      {
        rigidBackingLayer: {},
        ...(airCavityFormValues?.thickness &&
          airCavityFormValues.thickness > 0 && {
            airCavityLayer: {
              thicknessInMm: airCavityFormValues.thickness,
            },
          }),
        porousAbsorberLayer: {
          thicknessInMm: porousAbsorberFormValues.thickness,
          ...(porousAbsorberFormValues.inputType === PorousAbsorberInputType.FlowResistivity && {
            flowResistivity: porousAbsorberFormValues.flowResistivity,
          }),
          ...(porousAbsorberFormValues.inputType === PorousAbsorberInputType.Density && {
            density: porousAbsorberFormValues.density,
          }),
          ...(porousAbsorberFormValues.inputType === PorousAbsorberInputType.Density && {
            woolType: porousAbsorberFormValues.woolType,
          }),
        },
      },
      {
        onSuccess: (fitResponse) => {
          dispatch({
            type: ActionType.SET_BUILDER_FITTED_DATA,
            builderFittedData: fitResponse,
          });
        },
        onError: () => {
          dispatch({
            type: ActionType.SET_DISABLED_FORM,
            formDisabled: false,
          });
        },
      }
    );
  };

  return (
    <TrblTooltip title={formDisabled ? 'Building material' : ''}>
      <span>
        <PrimaryButton
          width="fit-content"
          label="Start material fit"
          disabled={formDisabled}
          onClick={calculateAbsorption}
        />
      </span>
    </TrblTooltip>
  );
};
