import { Layout } from 'plotly.js';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { getSPLUnit } from '../utils';

export const getLayoutConfig = (
  yAxisRange: [number, number],
  selectedFrequencyWeighting: FrequencyWeightingType
): Partial<Layout> => ({
  barmode: 'group',
  xaxis: {
    type: 'category',
    title: {
      text: 'FREQUENCY [Hz]',
      standoff: 15,
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
    gridcolor: '#555555',
  },
  yaxis: {
    title: {
      text: `SPL [${getSPLUnit(selectedFrequencyWeighting)}] `,
      standoff: 20,
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
    gridcolor: '#555555',
    range: yAxisRange,
  },
  showlegend: false,
  title: '',
  plot_bgcolor: 'transparent',
  paper_bgcolor: '#272727',
  font: {
    family: 'Inter, Helvetica, Arial, sans-serif',
    color: '#f5f5f5',
    size: 10,
  },

  margin: {
    l: 40,
    r: 5,
    b: 50,
    t: 15,
    pad: 5,
  },
  autosize: true,
});

export const getWeightedSplPerBand = (data: ReflectionDetails, selectedFrequencyWeighting: FrequencyWeightingType) => {
  switch (selectedFrequencyWeighting) {
    case FrequencyWeightingType.AWeighting:
      return data.splAWeightedPerBand ?? [];
    case FrequencyWeightingType.CWeighting:
      return data.splCWeightedPerBand ?? [];
    default:
      return data.splPerBand ?? [];
  }
};

export const getWeightedRelativeSplPerBand = (
  data: ReflectionDetails,
  selectedFrequencyWeighting: FrequencyWeightingType
) => {
  switch (selectedFrequencyWeighting) {
    case FrequencyWeightingType.AWeighting:
      return data.splAWeightedPerBandRelative ?? [];
    case FrequencyWeightingType.CWeighting:
      return data.splCWeightedPerBandRelative ?? [];
    default:
      return data.splPerBandRelative ?? [];
  }
};
