import { ReactNode, SyntheticEvent, useEffect, useRef } from 'react';

import { TrblCaretDownIcon, TrblCaretRightIcon } from '@/components/Icons';

import { DisplayChildLayer, DisplayLayer, LayerListClassName } from './types';

import styles from './styles.module.scss';

export const LayersListRow = ({
  rowData,
  children,
  listHasChildren,
  collapsedRows,
  toggleRow,
  selectedRow,
  setSelectedRow,
  isChild = false,
  classNames,
}: {
  rowData: DisplayLayer | DisplayChildLayer;
  children: ReactNode;
  listHasChildren: boolean;
  collapsedRows: Set<string>;
  toggleRow: (e: SyntheticEvent, id: string) => void;
  selectedRow: string | null;
  setSelectedRow: (id: string) => void;
  isChild?: boolean;
  classNames?: LayerListClassName;
}) => {
  const listItemRef = useRef(null);

  useEffect(() => {
    if (listItemRef.current && selectedRow === rowData.id) {
      // @ts-expect-error Property 'scrollIntoView' does not exist on type 'never'.ts(2339)
      listItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [listItemRef, selectedRow]);

  const handleClickRow = (id: string) => {
    if (selectedRow === id) setSelectedRow('');
    else setSelectedRow(id);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyUp = (event: any, id: string) => {
    if (event.key === 'Enter') {
      setSelectedRow(id);
    }
  };

  const rowClass = `${styles['list-row']} ${classNames?.rowClassName ?? ''} ${
    isChild ? `${styles['child-row']} ${classNames?.childRowClassName ?? ''}` : ''
  } ${isChild && collapsedRows.has(rowData.id) ? styles['expanded'] : ''} ${
    rowData.id === selectedRow ? `${styles['selected']} ${classNames?.selectedClassName ?? ''}` : ''
  }`;

  const isParentRow = 'children' in rowData && rowData.children && rowData.children.length > 1;

  return (
    <li
      ref={listItemRef}
      key={rowData.id}
      tabIndex={0}
      className={rowClass}
      onClick={() => handleClickRow(rowData.id)}
      onKeyUp={(e) => handleKeyUp(e, rowData.id)}>
      {listHasChildren && (
        <button
          className={styles['expand-collapse-cell']}
          onClick={isParentRow ? (e) => toggleRow(e, rowData.id) : undefined}>
          {isParentRow ? collapsedRows.has(rowData.id) ? <TrblCaretDownIcon /> : <TrblCaretRightIcon /> : ''}
        </button>
      )}
      {children}
    </li>
  );
};
