import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSmallCoarseness: FC<IconProps> = ({ height = '10', width = '10' }) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.143" cy="2.14287" r="0.714286" fill="#D9D9D9" />
    <ellipse cx="2.143" cy="5.00005" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="2.143" cy="7.85711" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="5.00042" cy="2.14287" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <circle cx="5.00042" cy="5.00005" r="0.714286" fill="#D9D9D9" />
    <ellipse cx="5.00042" cy="7.85711" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="7.85686" cy="2.14287" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="7.85686" cy="5.00005" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <circle cx="7.85686" cy="7.85711" r="0.714286" fill="#D9D9D9" />
  </svg>
);
