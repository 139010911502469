import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAmbisonicsIcon: FC<IconProps> = ({ fillExtra = '#ADADAD' }) => (
  <svg
    width="237pt"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    height="237pt"
    viewBox="0 0 237 237"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs />
    <g id="Background">
      <path
        fill={fillExtra}
        fillRule="evenodd"
        d="M233.196 116.5 C233.196 52.1588 180.993 0 116.598 0 C52.2028 0 1.86805e-14 52.1588 0 116.5 C1.86805e-14 180.841 52.2028 233 116.598 233 C180.993 233 233.196 180.841 233.196 116.5 Z M116.27 116.5 C116.27 84.4198 90.242 58.4142 58.1348 58.4142 C26.0278 58.4142 0 84.4198 0 116.5 C0 148.58 26.0278 174.586 58.1348 174.586 C90.242 174.586 116.27 148.58 116.27 116.5 L116.27 116.5 Z M233.196 116.5 C233.196 84.4198 207.169 58.4142 175.062 58.4142 C142.954 58.4142 116.927 84.4198 116.927 116.5 C116.927 148.671 143.028 174.75 175.226 174.75 C207.424 174.75 233.525 148.671 233.525 116.5 L233.196 116.5 Z M174.733 174.914 C174.733 142.834 148.705 116.828 116.598 116.828 C84.491 116.828 58.4634 142.834 58.4634 174.914 C58.4634 206.994 84.491 233 116.598 233 C148.705 233 174.733 206.994 174.733 174.914 L174.733 174.914 Z M174.733 58.0858 C174.733 26.0059 148.705 0 116.598 0 C84.491 0 58.4634 26.0059 58.4634 58.0858 C58.4634 90.166 84.491 116.172 116.598 116.172 C148.705 116.172 174.733 90.166 174.733 58.0858 L174.733 58.0858 Z M174.897 116.5 C174.897 84.3292 148.796 58.25 116.598 58.25 C84.4003 58.25 58.2991 84.3292 58.2991 116.5 C58.2991 148.671 84.4003 174.75 116.598 174.75 C148.796 174.75 174.897 148.671 174.897 116.5 Z"
        transform="translate(1.6, 2.1)"
      />
    </g>
  </svg>
);
