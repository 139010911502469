import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblExpandIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="16" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.90018 3.1001L6.80019 1.00011L4.7002 3.1001"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.79999 1.0003V6.60028" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.79999 9.00006V14.6" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.7002 12.5L6.80019 14.6L8.90018 12.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
