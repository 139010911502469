import { FC } from 'react';

import { IconProps } from '@/types';

// old fill color '#DADADA'
export const ChatWidgetIcon: FC<IconProps> = ({ fill = '#dadada' }) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.91667 10.4166H7.5C7.25951 10.4166 7.03074 10.5205 6.8725 10.7016L5.41667 12.3683V11.25C5.41667 10.7897 5.04357 10.4166 4.58333 10.4166H4.16667C2.78595 10.4166 1.66667 9.29734 1.66667 7.91663V4.58329C1.66667 3.20258 2.78595 2.08329 4.16667 2.08329H12.0833C13.464 2.08329 14.5833 3.20258 14.5833 4.58329V5.49996C14.5833 5.9602 14.9564 6.33329 15.4167 6.33329C15.8769 6.33329 16.25 5.9602 16.25 5.49996V4.58329C16.2472 2.28325 14.3834 0.419381 12.0833 0.416626H4.16667C1.86662 0.419381 0.0027551 2.28325 0 4.58329V7.91663C0.00263469 10.0556 1.62205 11.8463 3.75 12.0633V14.5833C3.74966 14.9307 3.96489 15.2419 4.2901 15.3641C4.6153 15.4864 4.98222 15.3941 5.21083 15.1325L7.8775 12.0833H7.91667C8.3769 12.0833 8.75 11.7102 8.75 11.25C8.75 10.7897 8.3769 10.4166 7.91667 10.4166Z"
      fill="#dadada"
    />
    <path
      d="M17.5007 6.99988H12.084C10.7033 6.99988 9.58398 8.11917 9.58398 9.49988V11.9999C9.58398 13.3806 10.7033 14.4999 12.084 14.4999H13.4057L15.6615 16.7565C15.8999 16.9949 16.2585 17.0661 16.5699 16.937C16.8813 16.8078 17.0842 16.5037 17.084 16.1665V14.4999H17.5007C18.8814 14.4999 20.0007 13.3806 20.0007 11.9999V9.49988C20.0007 8.11917 18.8814 6.99988 17.5007 6.99988Z"
      fill={fill}
    />
  </svg>
);
