import { FC } from 'react';

import { ChatWidgetIcon, TrblUserIcon } from '../Icons';
import { Text } from '../Shared/Text';
import { LinkButton } from './LinkButton';

import styles from './styles.module.scss';

type WelcomeProps = {
  isNewUser: boolean;
  isExampleModel?: boolean;
};

export const Welcome: FC<WelcomeProps> = ({ isNewUser, isExampleModel }) => {
  const openQuickStartGuideWindow = (url: string) => {
    window.open(url, 'mywindow', 'width=600,height=' + window.screen.height);
  };
  return (
    <div className={styles['container']}>
      {isNewUser ? (
        <div className={styles['welcome-message']}>
          {isExampleModel ? (
            <>
              <h2 className={styles['title']} style={{ fontSize: 16 }}>
                Let's get you started!
              </h2>
              <span className={styles['message']}>
                Create your own simulations using this example model and get familiar with Treble and the features we
                have to offer.
              </span>
            </>
          ) : (
            <>
              <h2 className={styles['title']}>Welcome to Treble!</h2>
              <span className={styles['message']}>
                Choose one of our pre-made spaces on the left to start simulating.
              </span>
            </>
          )}

          <span className={styles['message']}>
            To import your own models you can use <span style={{ color: '#00f5ba' }}>Import geometry (beta)</span> on
            the home page, or import your models from SketchUp using our{' '}
            <a
              className={styles['link']}
              target="_blank"
              href={'https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/treble-extension'}>
              SketchUp plugin
            </a>
            <span style={{ color: '#dadada' }}>*</span>
            <div className={styles['note']}>
              <span style={{ color: '#adadad' }}>*</span> Note that you will need a{' '}
              <a
                target="_blank"
                className={styles['underline']}
                href={'https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/sketchup'}>
                SketchUp Pro
              </a>{' '}
              license
            </div>
          </span>
        </div>
      ) : (
        <Text type="semibold-14px">Resources</Text>
      )}
      <div className={styles['links']}>
        <LinkButton
          text="Quick start guide"
          icon={'quickGuide'}
          url={'https://docs.treble.tech/user-guide/getting-started/quick_start_guide'}
          onClick={openQuickStartGuideWindow}
        />
        <LinkButton text="Documentation" icon={'file'} url={'https://docs.treble.tech/user-guide'} />
        <LinkButton
          text="Video guides"
          icon={'playAlternative'}
          url={'https://www.youtube.com/playlist?list=PL_OrBfOSE-nAZR2aRNFcNkjApX23wm6lo'}
        />
      </div>
      <div className={styles['more-info']}>
        <div className={styles['info-column']}>
          <ChatWidgetIcon />
          <span className={styles['text']}>
            Our <b>live support chat</b> is located on the left side of the screen
          </span>
        </div>
        <div className={styles['info-column']}>
          <TrblUserIcon />
          <span className={styles['text']}>
            Find the <span style={{ fontWeight: 600 }}>documentation</span> under the user menu in the top right corner
          </span>
        </div>
      </div>
    </div>
  );
};
