import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblTimewatchIcon: FC<IconProps> = ({ fill }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6768 4.62314L12.579 3.75648C12.7306 3.61588 12.7914 3.40767 12.738 3.21188C12.6846 3.01608 12.5254 2.86318 12.3214 2.81193C12.1175 2.76068 11.9006 2.81906 11.7541 2.96464L10.8208 3.86064C10.0223 3.2855 9.0761 2.92936 8.08321 2.83024V1.12H9.24987C9.57204 1.12 9.83321 0.869279 9.83321 0.56C9.83321 0.250721 9.57204 0 9.24987 0H5.74987C5.42771 0 5.16654 0.250721 5.16654 0.56C5.16654 0.869279 5.42771 1.12 5.74987 1.12H6.91654V2.83024C4.377 3.08879 2.35034 4.9799 2.00901 7.40953C1.66768 9.83916 3.10009 12.1781 5.47755 13.0733C7.85501 13.9684 10.5618 13.188 12.0321 11.1834C13.5021 9.17914 13.3553 6.47013 11.6768 4.62314ZM2.83301 8.11989C2.83301 10.5941 4.92235 12.5999 7.49967 12.5999C8.73735 12.5999 9.92434 12.1279 10.7995 11.2877C11.6747 10.4476 12.1663 9.30806 12.1663 8.11989C12.1663 5.64566 10.077 3.63989 7.49967 3.63989C4.92235 3.63989 2.83301 5.64566 2.83301 8.11989Z"
      fill={fill || '#DADADA'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.70703 8.11998C3.70703 6.10966 5.40462 4.47998 7.4987 4.47998C8.50431 4.47998 9.46874 4.86348 10.1798 5.54611C10.8909 6.22874 11.2904 7.15459 11.2904 8.11998C11.2904 10.1303 9.59278 11.76 7.4987 11.76C5.40462 11.76 3.70703 10.1303 3.70703 8.11998ZM7.18947 8.30029C7.36144 8.46134 7.63583 8.46134 7.8078 8.30029V8.30085C7.88987 8.22217 7.93599 8.11539 7.93599 8.00405C7.93599 7.8927 7.88987 7.78593 7.8078 7.70725L6.61722 6.56373C6.44631 6.39966 6.16921 6.39966 5.9983 6.56373C5.82739 6.7278 5.82739 6.99382 5.9983 7.15789L7.18947 8.30029Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
