import { TrblTooltip } from '@/components/Shared';

import { MaterialLayer } from '@/types';

const maxTextLength = 14;

export const LayerNameCell = ({ layer }: { layer: MaterialLayer }) => {
  return (
    <div className="treble-layers-cell layer-cell" role="gridcell" aria-label="layer name">
      {layer.textName.length >= maxTextLength ? (
        <TrblTooltip disableInteractive title={layer.textName}>
          <p>{layer.textName}</p>
        </TrblTooltip>
      ) : (
        <p>{layer.textName}</p>
      )}
    </div>
  );
};
