import { DoubleSide, Texture } from 'three';

export const MeshWithTexture = ({
  index,
  texture,
  alphaTest,
  doubleSided,
}: {
  index: number;
  texture: Texture;
  alphaTest?: number;
  doubleSided?: boolean;
}) => (
  <meshBasicMaterial
    attach={`material-${index}`}
    map={texture}
    alphaTest={alphaTest}
    side={doubleSided ? DoubleSide : undefined}
  />
);
