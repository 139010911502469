import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { GetSubscriptionResponse } from './useGetSubscription';

import { config } from '@/__config__/config';

import { PendingSubscriptionInfo } from '../Organization/types';

import axios from '@/axios';

const { adminApiUrl } = config;

const getPendingSubscriptionChanges = async (organizationId: string) => {
  const { data } = await axios.get<GetSubscriptionResponse>(`/api/Subscription/GetSubscriptionWithScheduledChanges`, {
    params: {
      organizationId,
      product: 'TASS',
    },
    baseURL: adminApiUrl,
  });

  return data
    ? {
        subscriptionType: data.products[0].billingType === 'PrepaidOnly' ? 'Flexible' : data.products[0].billingType,
        paymentFrequency: data.products[0].paymentFrequency,
        maxSeats: data.products[0].maxSeats,
        scheduledDate: data.products[0].expiresAt || '',
      }
    : null;
};

export const useGetPendingSubscriptionChanges = (organizationId: string, enabled: boolean) => {
  return useQuery<PendingSubscriptionInfo | null>(
    ['pendingSubscription', organizationId],
    () => getPendingSubscriptionChanges(organizationId),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: () => toast.error('An error occurred while loading pending subscription details!'),
    }
  );
};
