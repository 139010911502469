import { useEffect, useState } from 'react';
import { Vector3 } from 'three';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblSkeleton } from '@/components/Shared/TrblSkeleton';
import { SourceSettings } from '@/components/AuralizerPresets/types';
import { SoundLibrary } from '@/components/SoundLibrary/SoundLibrary';
import { Marker } from '@/components/SourceRecieverSettings/Marker';
import { AudioEngine } from '../AudioEngine';
import { useAuralizerContext } from '../AuralizerContext';

import { Source } from '@/types';

import '../style.scss';

export const SourceSound = ({
  source,
  sourceIndex,
  sourceSettings,
}: {
  source: Source | null;
  sourceIndex: number;
  sourceSettings?: SourceSettings;
}) => {
  const [sourceLabel, setSourceLabel] = useState('');

  const { selectedAurSim, selectedReceiver } = useAuralizerContext();

  const { dispatch: editorDispatch, selectedAurSource, isCameraInsideModel } = useEditorContext();

  useEffect(() => {
    if (selectedAurSim) {
      selectedAurSim.sources.forEach((aurSource) => {
        if (aurSource.id === source?.id) {
          setSourceLabel(aurSource.label);
        }
      });
    }
  }, [selectedAurSim, source?.id]);

  useEffect(() => {
    if (sourceSettings?.volume !== undefined && sourceSettings.isMuted === false && source?.id) {
      AudioEngine.getInstance().updateSingleGain(sourceSettings.volume, source.id);
    }
  }, [sourceSettings]);

  const pointCameraToSource = () => {
    if (source && selectedReceiver) {
      editorDispatch({
        type: EditorActionType.UPDATE_COORDINATES,
        coordinates: [
          new Vector3(selectedReceiver.receiver.x, selectedReceiver.receiver.y, selectedReceiver.receiver.z),
          new Vector3(source.x, source.y, source.z),
        ],
      });
    }
  };

  const pointAndSetCameraToSource = () => {
    if (source) {
      editorDispatch({
        type: EditorActionType.SET_SELECTED_AUR_SOURCE,
        source: source,
        index: sourceIndex,
      });
      pointCameraToSource();
    }
  };

  return (
    <div>
      <TrblTooltip title={isCameraInsideModel ? 'Look at source' : ''}>
        <p className="column-title clickable" onClick={pointAndSetCameraToSource}>
          <button>
            <Marker
              color="green"
              label={(sourceIndex + 1).toString()}
              small
              isSelected={selectedAurSource?.index === sourceIndex}
            />
          </button>

          <span className="label">{sourceLabel || `Source ${sourceIndex + 1}`}</span>
        </p>
      </TrblTooltip>
      {source ? (
        <SoundLibrary sourceId={source.id} sourceSettings={sourceSettings} sourceIndex={sourceIndex} />
      ) : (
        <TrblSkeleton
          style={{
            height: 24,
          }}
        />
      )}
    </div>
  );
};
