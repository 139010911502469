import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type UpdateProjectDto = {
  id: string;
  name: string;
  description: string;
};

const updateProject = async (request: UpdateProjectDto) => {
  const { data } = await axios.patch(`/api/v2/Project/UpdateProject`, request);
  return data;
};

export const useUpdateProject = () => {
  return useMutation(async (data: UpdateProjectDto) => await updateProject(data));
};
