import { LibraryList, LibraryListItem } from '@/components/LibraryPanel';
import { GroupMaterialAfterCategory } from '@/components/LibraryPanel/types';
import { MaterialRow } from './MaterialRow';

import { Material } from '@/types';

export const MaterialsList = ({
  availableMaterialsByGrouping,
}: {
  availableMaterialsByGrouping: GroupMaterialAfterCategory;
}) => {
  return (
    <LibraryList>
      {Object.keys(availableMaterialsByGrouping).map((keyName: string, parentIndex: number) => (
        <LibraryListItem key={keyName} categoryTitle={keyName}>
          {availableMaterialsByGrouping[keyName].map((material: Material, index: number) => (
            <MaterialRow key={material.id} material={material} index={index} parentIndex={parentIndex} />
          ))}
        </LibraryListItem>
      ))}
    </LibraryList>
  );
};
