import { IconProps } from '@/types';

export const TrblUploadIcon = ({ fill = '#DADADA' }: IconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.283 9.19532C12.8886 9.19532 12.569 9.48987 12.569 9.85323V10.8602C12.5683 11.3308 12.1542 11.7121 11.6434 11.7124H2.35366C1.84285 11.7121 1.42875 11.3308 1.4281 10.8602V9.85323C1.4281 9.48987 1.10841 9.19532 0.714051 9.19532C0.319692 9.19532 0 9.48987 0 9.85323V10.8602C-1.01591e-06 12.0583 1.05329 13.03 2.35366 13.0315H11.6434C12.9449 13.0315 14 12.0594 14 10.8602V9.85323C14 9.67827 13.9244 9.51051 13.7898 9.38705C13.6553 9.26359 13.4729 9.1946 13.283 9.19532Z"
      fill={fill}
    />
    <path
      d="M6.99881 10.1895C7.62972 10.1895 8.14118 9.71821 8.14118 9.13691V5.18496C8.14118 5.11001 8.20712 5.04926 8.28846 5.04926H9.85502C10.0788 5.04917 10.282 4.92873 10.3748 4.74109C10.4676 4.55346 10.433 4.33317 10.2863 4.17747L7.43007 1.15066C7.32164 1.03548 7.1643 0.969318 6.99881 0.969318C6.83333 0.969318 6.67599 1.03548 6.56755 1.15066L3.71135 4.17747C3.56461 4.33317 3.53001 4.55346 3.62283 4.74109C3.71565 4.92873 3.9188 5.04917 4.14261 5.04926H5.70916C5.79051 5.04926 5.85645 5.11001 5.85645 5.18496L5.85645 9.13691C5.85645 9.71821 6.3679 10.1895 6.99881 10.1895Z"
      fill={fill}
    />
  </svg>
);
