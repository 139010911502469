import { ReactNode } from 'react';

import { TrblCrankWheelIcon } from '@/components/Icons';
import { TrblIconButton } from '../../Shared/Buttons';

import './styles.scss';

export const LibraryItemPreview = ({
  title,
  type,
  setShowDetailsPopup,
  children,
  large,
}: {
  title: string | React.ReactNode;
  type: string;
  setShowDetailsPopup: (show: boolean) => void;
  children: ReactNode;
  large?: boolean;
}) => {
  return (
    <div className={`library-info-box ${large ? 'extra-height' : ''}`}>
      <div className="library-info-box-header">
        <p> {title} </p>
        <TrblIconButton
          className="info-library-item-button"
          icon={<TrblCrankWheelIcon />}
          label={`${type} details`}
          title={`View ${type} details`}
          edge="start"
          onClick={() => setShowDetailsPopup(true)}></TrblIconButton>
      </div>
      {children}
    </div>
  );
};
