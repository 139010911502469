import { useMutation } from '@tanstack/react-query';

import { ModelBaseDto } from '@/types';

import axios from '@/axios';

type CreateModelBaseRequest = {
  spaceId: string;
  modelName: string;
};

const createModelBase = async (request: CreateModelBaseRequest) => {
  const { data } = await axios.post<ModelBaseDto>(`/api/Model/CreateModelBase`, null, {
    params: { ...request },
  });
  return data;
};

export const useCreateModelBase = () => {
  return useMutation(async (modelBase: CreateModelBaseRequest) => await createModelBase(modelBase));
};
