import { FC } from 'react';

import { getColorByIndex } from '@/components/Shared/NameMarker/utils';
import { ShareRow } from './ShareRow';

import classes from './styles.module.scss';

type ShareListProps = {
  hasAnonymousLink: boolean;
  emails: string[];
  disabled: boolean;
  onDeleteShare: (email?: string) => void;
  onResendEmail: (email: string) => void;
  onCopyAnonymousLink: () => void;
};

export const ShareList: FC<ShareListProps> = ({
  hasAnonymousLink,
  emails,
  disabled = false,
  onDeleteShare,
  onResendEmail,
  onCopyAnonymousLink,
}) => {
  return hasAnonymousLink || emails.length ? (
    <div className={`${classes['share-list']} ${disabled ? classes['disabled'] : ''}`}>
      {hasAnonymousLink ? (
        <ShareRow
          isAnonymous
          showMenu={!disabled}
          onDeleteShare={onDeleteShare}
          onCopyAnonymousLink={onCopyAnonymousLink}
        />
      ) : null}
      {emails.map((email, i) => (
        <ShareRow
          key={email}
          email={email}
          color={getColorByIndex(i)}
          showMenu={!disabled}
          onDeleteShare={onDeleteShare}
          onResendShare={onResendEmail}
        />
      ))}
    </div>
  ) : null;
};
