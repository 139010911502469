/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-react';
import { AxiosError } from 'axios';

import { UserDto } from '@/components/ManagementPortal/hooks';
import client from '../../axios';

import { config } from '@/__config__/config';

const { viteAuth0LogoutUri, mode, dev } = config;

const handleNavigation = (to: string) => {
  if (!window.location.href.includes(to)) {
    window.location.replace(`${window.location.origin}${to}`);
  }
};

export const configureAxiosResponseInterceptor = (
  logout: (options?: LogoutOptions | undefined) => void,
  userInfo: UserDto
) => {
  const automaticBillingFeature =
    userInfo.featureFlags?.some((x) => x.toLowerCase() === 'automaticbillingfeature') || false;

  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Covers both when running locally and when building for DEV environment
      const isDev = mode === 'dev' || dev;
      // Remove the hacky feature flag check when the automaticBillingFeature flag is removed
      if (error.response && error.response.status === 403 && (isDev || automaticBillingFeature)) {
        const errorMessage = (error.response.data as any)?.message;

        if (errorMessage === 'Trial expired') {
          handleNavigation('/subscription');
        } else if (errorMessage === 'Subscription expired') {
          handleNavigation('/subscription-expired');
        } else if (errorMessage === 'Product access disabled' || errorMessage === 'User has no TASS subscription') {
          handleNavigation('/access-disabled');
        }
      } else if (
        error.response &&
        error.response.status === 403 &&
        (error.response.data as any)?.message === 'Trial expired'
      ) {
        // Remove this else when billing feature goes live!!
        logout({ returnTo: 'https://www.treble.tech/trial-ended' });
      }

      return Promise.reject(error);
    }
  );
};

export const configureAxiosRequestInterceptor = (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  logout: (options?: LogoutOptions | undefined) => void
) => {
  client.interceptors.request.use(async (config) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (config.headers && accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    } catch (e: any) {
      if (e.error === 'login_required' || e.error === 'invalid_grant' || e.error === 'missing_refresh_token') {
        logout({ returnTo: viteAuth0LogoutUri });
      }
    }
    return config;
  });
};
