import { useEffect } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import { ResultsView, View3DType } from '@/context/EditorContext/types';

export const useChooseResultsView = (userSelectedView3D: View3DType, showResults: boolean) => {
  const { resultsView, view3D, dispatch } = useEditorContext();

  useEffect(() => {
    // change the model-3D-view to wireframe when opening the
    // GridReceiversView or ReflectogramView so the heatmap / reflections are properly shown
    if (resultsView == ResultsView.ResultsGridReceiversView || resultsView == ResultsView.ResultsReflectogramView) {
      if (view3D !== 'wireframe') {
        dispatch({
          type: ActionType.SET_3D_VIEW,
          view3D: 'wireframe',
        });
      }
      // change the model-3D-view to whatever the user had previously selected, if not already selected
    } else if (view3D !== userSelectedView3D) {
      dispatch({
        type: ActionType.SET_3D_VIEW,
        view3D: userSelectedView3D,
      });
    }
  }, [resultsView]);

  return (
    showResults &&
    (resultsView === ResultsView.ResultsReflectogramView || resultsView === ResultsView.ResultsGridReceiversView)
  );
};
