import { useQuery } from '@tanstack/react-query';

import { SpaceExtractionResults } from '@/components/MultiSpaceImport/types';

import axios from '@/axios';

export const getSpaceExtractionResult = async (taskId: string | null): Promise<SpaceExtractionResults | undefined> => {
  if (taskId) {
    const { data } = await axios.get(
      `/api/SpaceExtraction/GetSpaceExtractionTaskResults?spaceExtractionTaskId=${taskId}`
    );

    return data;
  }
};

export const useGetSpaceExtractionResult = (taskId: string | null) => {
  return useQuery(['SpaceExtractionResult', taskId], () => getSpaceExtractionResult(taskId), {
    enabled: !!taskId,
    refetchOnWindowFocus: false,
  });
};
