import { FC, useEffect, useRef, useState } from 'react';
import { GizmoHelper, OrbitControls, Segment, Segments, Sphere } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Vector3 } from 'three';

import { useGeometryImportContext } from '@/context/GeometryImportContext';

import { AxisHelper } from '@/components/Shared/AxisHelper';
import { getCameraLookAtFromModel, getCameraPositionFromModel } from '@/components/Editor/components/Viewport/utils';
import { useObjectClickHandler } from '@/components/Editor/hooks';
import { Model3D } from '../../../Editor/components/Viewport/Model3d';
import { Triangle } from './Triangle';

import { Warnings } from '../../constants';

import { GeometryInfo } from '../../types';
import { SelectedDetails } from '@/context/EditorContext/types';
import { ModelLayerGroup } from '@/context/ModelContext/types';
import { HiddenLayer } from '@/types';

let originalCameraPosition: [Vector3, Vector3] | null = null;

type ViewportContentProps = {
  layerGroups: ModelLayerGroup[];
  geometryInfo: GeometryInfo | null;
  hiddenLayers: Set<string>;
  selectedRow: string | null;
  setSelectedRow: (id: string) => void;
};

export type GeometryLineWarningTypes = Warnings.NAKED_EDGES | Warnings.SMALL_EDGES | Warnings.EXCLUDED_FACES;

export const ViewportContent: FC<ViewportContentProps> = ({
  layerGroups,
  geometryInfo,
  hiddenLayers,
  selectedRow,
  setSelectedRow,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const controlRef = useRef<any>();
  const [hiddenViewportLayers, setHiddenViewportLayers] = useState<HiddenLayer[]>([]);
  const [selectedDetails, setSelectedDetails] = useState<SelectedDetails | null>(null);

  const { camera, invalidate } = useThree();
  const { modelsGeometryWarningsSelected, modelCenter, modelBoundingBox } = useGeometryImportContext();

  const [warningsTypeSelected, setWarningsTypeSelected] = useState('');
  const [warningIndex, setWarningIndex] = useState<number | null>(null);
  const warningColor = '#ff6c00';
  const selectedColor = '#e65000';

  useEffect(() => {
    if (modelCenter && modelBoundingBox) {
      const cameraLookat = getCameraLookAtFromModel(modelCenter);
      const cameraPosition = getCameraPositionFromModel(modelCenter, modelBoundingBox);

      controlRef.current.target = cameraLookat;
      camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.y);
    }
  }, []);

  useEffect(() => {
    const setToArray = Array.from(hiddenLayers);
    const newHiddenViewportLayers = setToArray.map((item) => ({
      type: layerGroups.find((group) => group.id === item) ? 'LayerGroup' : ('Layer' as 'Layer' | 'LayerGroup'),
      id: item,
    }));
    setHiddenViewportLayers(newHiddenViewportLayers);
  }, [hiddenLayers, layerGroups]);

  useEffect(() => {
    if (selectedRow) {
      const type = layerGroups.find((group) => group.id === selectedRow)
        ? 'LayerGroup'
        : ('Layer' as 'Layer' | 'LayerGroup');
      setSelectedDetails({
        type,
        id: selectedRow,
      });
    }
  }, [selectedRow, layerGroups]);

  useEffect(() => {
    if (modelsGeometryWarningsSelected) {
      setWarningsTypeSelected(modelsGeometryWarningsSelected.warningType);
      setWarningIndex(modelsGeometryWarningsSelected.index);
    }
  }, [modelsGeometryWarningsSelected]);

  useEffect(() => {
    if (geometryInfo && warningIndex !== null) {
      if (originalCameraPosition == null) {
        originalCameraPosition = [camera.position.clone(), controlRef.current.target];
      }

      if (warningsTypeSelected == Warnings.PROBLEM_AREAS) {
        const x = geometryInfo[Warnings.PROBLEM_AREAS][warningIndex];
        const midpoint = new Vector3(x.center[0], x.center[1], x.center[2]);

        const offset = x.radius * 2;

        const position = new Vector3(midpoint.x + offset, midpoint.y + offset, midpoint.z + offset);

        updateCamera(position, midpoint);
      } else {
        const x = geometryInfo[warningsTypeSelected as GeometryLineWarningTypes][warningIndex];
        const point1 = new Vector3(x[0][0], x[0][1], x[0][2]);
        const point2 = new Vector3(x[1][0], x[1][1], x[1][2]);

        const midpoint = new Vector3().addVectors(point1, point2).divideScalar(2);
        const distance = point1.distanceTo(point2);
        const offset = distance > 0.05 ? distance * 1.2 : 0.05;

        const position = new Vector3(midpoint.x + offset, midpoint.y + offset, midpoint.z + offset);

        updateCamera(position, midpoint);
      }
    } else if (warningIndex == null) {
      if (originalCameraPosition !== null) {
        updateCamera(originalCameraPosition[0], originalCameraPosition[1]);
        originalCameraPosition = null;
      }
    }
  }, [warningIndex, warningsTypeSelected]);

  const updateCamera = (position: Vector3, lookAt: Vector3) => {
    camera.position.set(position.x, position.y, position.z);
    controlRef.current.target = lookAt;
    invalidate();
  };

  const handleOutsideClick = () => {
    if (selectedRow) {
      setSelectedRow('');
      setSelectedDetails(null);
    }
  };

  const clickMeshHandler = (object: THREE.Object3D<THREE.Event>) => {
    if (object && object.parent) {
      const isLayerGroup = object.parent.children.length > 1 ? false : true;
      const selectedLayerId = isLayerGroup ? object.parent.uuid : object.userData.attributes?.id;
      setSelectedRow(selectedLayerId);
    }
  };

  const clickHandlerProps = useObjectClickHandler((event) => {
    if (event.object.type === 'Mesh') {
      clickMeshHandler(event.object);
    }
  });

  return (
    geometryInfo && (
      <>
        <group onPointerMissed={handleOutsideClick} {...clickHandlerProps}>
          <Model3D
            view3D={'ghosted'}
            layerGroups={layerGroups}
            selectedDetails={[selectedDetails]}
            hiddenLayers={hiddenViewportLayers}
          />
        </group>
        <OrbitControls
          ref={controlRef}
          makeDefault={true}
          minPolarAngle={0}
          maxPolarAngle={Math.PI}
          dampingFactor={0.25}
        />
        {warningsTypeSelected == Warnings.SMALL_EDGES && (
          <Segments lineWidth={2.5}>
            {geometryInfo[Warnings.SMALL_EDGES].map((x, i) => (
              <Segment
                key={`small_edge_${i}`}
                start={[x[0][0], x[0][1], x[0][2]]}
                end={[x[1][0], x[1][1], x[1][2]]}
                color={i == warningIndex ? selectedColor : warningColor}
              />
            ))}
          </Segments>
        )}

        {warningsTypeSelected == Warnings.NAKED_EDGES && (
          <Segments lineWidth={2.5}>
            {geometryInfo[Warnings.NAKED_EDGES].map((x, i) => (
              <Segment
                key={`naked_edge_${i}`}
                start={[x[0][0], x[0][1], x[0][2]]}
                end={[x[1][0], x[1][1], x[1][2]]}
                color={i == warningIndex ? selectedColor : warningColor}
              />
            ))}
          </Segments>
        )}

        {warningsTypeSelected == Warnings.ISOLATED_EDGES && (
          <Segments lineWidth={2.5}>
            {geometryInfo[Warnings.ISOLATED_EDGES].map((x, i) => (
              <Segment
                key={`isoalted_edge_${i}`}
                start={[x[0][0], x[0][1], x[0][2]]}
                end={[x[1][0], x[1][1], x[1][2]]}
                color={i == warningIndex ? selectedColor : warningColor}
              />
            ))}
          </Segments>
        )}

        {warningsTypeSelected == Warnings.EXCLUDED_FACES &&
          geometryInfo[Warnings.EXCLUDED_FACES].map((x, i) => (
            <Triangle
              key={`excluded_face_${i}`}
              triangle={x}
              color={i == warningIndex ? selectedColor : warningColor}
            />
          ))}

        {warningsTypeSelected == Warnings.PROBLEM_AREAS &&
          geometryInfo[Warnings.PROBLEM_AREAS].map((x, i) => (
            <Sphere
              renderOrder={i == warningIndex ? 10 : 9}
              key={`problem_area_${i}`}
              position={[x.center[0], x.center[1], x.center[2]]}
              args={[x.radius]}>
              <meshBasicMaterial
                transparent={true}
                opacity={0.5}
                color={i == warningIndex ? selectedColor : warningColor}
                depthWrite={true}
                depthTest={false}
              />
            </Sphere>
          ))}

        <GizmoHelper alignment="bottom-right" onTarget={() => controlRef.current.target}>
          <AxisHelper position={new Vector3(0, 22, -22)} />
        </GizmoHelper>
      </>
    )
  );
};
