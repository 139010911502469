import * as React from 'react';
import { useGizmoContext } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { CanvasTexture, Vector3 } from 'three';

type AxisHeadProps = JSX.IntrinsicElements['sprite'] & {
  label?: string;
  color: string;
  onClick?: (e: ThreeEvent<MouseEvent>) => null;
};

type AxisHelperArrowsProps = JSX.IntrinsicElements['group'] & {
  axisColors?: [string, string, string];
  labels?: [string, string, string];
  font?: string;
  offset?: Vector3;
  onClick?: (e: ThreeEvent<MouseEvent>) => null;
};

function AxisLine({ color, rotation }: { color: string; rotation: [number, number, number] }) {
  return (
    <group rotation={rotation}>
      <mesh position={[0.45, 0, 0]} scale={new Vector3(0.9, 0.034, 0.034)}>
        <boxGeometry />
        <meshBasicMaterial color={color} toneMapped={false} />
      </mesh>
    </group>
  );
}

function AxisLabel({ label, color, ...props }: AxisHeadProps) {
  const texture = React.useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const context = canvas.getContext('2d')!;

    if (label) {
      context.font = 'Bold 26px "Inter", Arial, sans-serif';
      context.textAlign = 'center';
      context.fillStyle = color;
      context.fillText(label, 32, 40);
    }
    return new CanvasTexture(canvas);
  }, [label, color]);

  return (
    <sprite scale={0.75} {...props}>
      <spriteMaterial map={texture} alphaTest={0.3} toneMapped={false} />
    </sprite>
  );
}

export const AxisHelperArrows = ({
  axisColors = ['#ff6f61', '#6fe14e', '#00ace5'],
  labels = ['X', 'Y', 'Z'],
  offset = new Vector3(0, 0, 0),
  onClick,
  ...props
}: AxisHelperArrowsProps) => {
  const [colorX, colorY, colorZ] = axisColors;

  const { tweenCamera } = useGizmoContext();
  const axisHeadProps = {
    onClick,
    onPointerDown: (e: ThreeEvent<PointerEvent>) => {
      tweenCamera(e.object.position);
      e.stopPropagation();
    },
  };
  return (
    <group position={offset} scale={42} {...props}>
      <arrowHelper args={[new Vector3(1, 0, 0), new Vector3(0, 0, 0), 1, colorX, 0.15, 0.14]} />
      <arrowHelper args={[new Vector3(0, 1, 0), new Vector3(0, 0, 0), 1, colorY, 0.15, 0.14]} />
      <arrowHelper args={[new Vector3(0, 0, 1), new Vector3(0, 0, 0), 1, colorZ, 0.15, 0.14]} />
      <AxisLine color={colorX} rotation={[0, 0, 0]} />
      <AxisLine color={colorY} rotation={[0, 0, Math.PI / 2]} />
      <AxisLine color={colorZ} rotation={[0, -Math.PI / 2, 0]} />
      <AxisLabel color={colorX} position={[1.2, 0, 0]} label={labels[0]} {...axisHeadProps} />
      <AxisLabel color={colorY} position={[0, 1.2, 0]} label={labels[1]} {...axisHeadProps} />
      <AxisLabel color={colorZ} position={[0, 0, 1.2]} label={labels[2]} {...axisHeadProps} />
    </group>
  );
};
