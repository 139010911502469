import { FC } from 'react';

type SumMarkerProps = {
  order: number;
  disabled?: boolean;
};

export const SumMarker: FC<SumMarkerProps> = ({ order, disabled = false }) => {
  const color = disabled ? '#009973' : '#00F5BA';

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8104 10.7314C17.1989 8.97907 16.9867 7.14698 16.2081 5.52974C15.4295 3.9125 14.1297 2.60408 12.5176 1.81486C10.9056 1.02563 9.0749 0.80146 7.32002 1.17838C5.56515 1.55531 3.988 2.51143 2.84219 3.89301C1.69637 5.27459 1.04845 7.00137 1.00261 8.79568C0.956768 10.59 1.51567 12.3476 2.58944 13.7859C3.66322 15.2242 5.18948 16.2596 6.92282 16.7256C8.65616 17.1917 10.4959 17.0613 12.1461 16.3554"
        stroke={color}
      />
      <text
        x="8.8" // Adjust positioning based on your design
        y="9.5"
        textAnchor="middle"
        dominantBaseline="middle"
        fontFamily="Inter"
        fontSize="9px"
        fontWeight="900"
        fontStyle="normal"
        letterSpacing="-0.09px"
        fill={color}
        style={{
          textAlign: 'center',
          fontFeatureSettings: "'ss01' on",
        }}>
        {order}
      </text>
      <path
        d="M14.157 15.7503L14.157 11.7276H15.174L15.1741 15.7503H14.157ZM12.6542 14.2475L12.6542 13.2304H16.6769L16.6769 14.2475H12.6542Z"
        fill={color}
      />
    </svg>
  );
};
