import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { AuralizationPresetDto, CreateAuralizationPresetDto } from '../types';

import axios from '@/axios';

const createPreset = async (preset: CreateAuralizationPresetDto): Promise<AuralizationPresetDto> => {
  const { data } = await axios.post(`/api/AuralizationPreset/CreateAuralizationPreset`, preset);
  return data;
};

export const useCreateAuralizationPreset = () => {
  return useMutation(async (preset: CreateAuralizationPresetDto) => await createPreset(preset), {
    onError: () => {
      toast.error('An error occurred while saving the auralization preset. Please refresh the browser.');
    },
    onSuccess: (data) => {
      toast.success(`Preset "${data.name}" created!`, { className: 'editor-toast' });
    },
  });
};
