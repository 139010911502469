import { FC } from 'react';

import { ListSubheader } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { Checkbox } from '@/components/Shared/Checkbox';
import { Text } from '@/components/Shared/Text';

import classes from './styles.module.scss';

type MenuSubHeaderProps = {
  title: string;
  showSelectAll?: boolean;
  onSelectAll?: () => void;
  allSourcesSelected?: boolean;
};

export const MenuSubHeader: FC<MenuSubHeaderProps> = ({
  title,
  showSelectAll = false,
  onSelectAll,
  allSourcesSelected = false,
}) => {
  return (
    <ListSubheader className={classes['menu-sub-header']}>
      {showSelectAll ? (
        <TrblTooltip title="Select all sources">
          <span style={{ width: '100%' }} onClick={onSelectAll}>
            <Checkbox
              id="select-all-sources"
              label={
                <Text type="medium-8px" uppercase color="#ADADAD">
                  {title}
                </Text>
              }
              isChecked={allSourcesSelected}
              spaceBetween={true}
              labelAlignment={'left'}
            />
          </span>
        </TrblTooltip>
      ) : (
        <Text type="medium-8px" uppercase color="#ADADAD">
          {title}
        </Text>
      )}
    </ListSubheader>
  );
};
