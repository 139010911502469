import { FC, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';

import { useAppContext } from '@/context/AppContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { ExportHeatmapButton } from './ExportHeatmapButton';
import { Grid } from './Grid';
import { GridReceiverResults } from './GridReceiverResults/GridReceiverResults';
import { GridReceivers } from './GridReceivers';
import { Model3D } from './Model3d';
import { Points } from './Points';
import { PointsAuralizer } from './PointsAuralizer';
import { ReflectogramResults } from './ReflectogramResults';
import { ViewportControls } from './ViewportControls';

import { useClickMeshHandler, useObjectClickHandler } from '../../hooks';

import {
  DEFAULT_CAMERA_SETTINGS,
  DEFAULT_GL_SETTINGS,
  DEFAULT_RAYCASTER_SETTINGS,
  DEFAULT_RESIZE_SETTINGS,
  DEFAULT_SCENE_SETTINGS,
} from './constants';

import { getLayerColors } from './utils';

import { ResultsView } from '@/context/EditorContext/types';

export const Viewport: FC = () => {
  const {
    dispatch,
    selected,
    multiSelected,
    isInResultsMode,
    isAuralizerOpen,
    resultsView,
    isCameraInsideModel,
    view3D,
  } = useEditorContext();
  const { isModelLoaded, currentModel3dLayerGroups, currentModelId } = useModelContext();
  const {
    simulationState: { hiddenLayers, selectedSimulation },
  } = useSimulationContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const { dispatch: libDispatch, multiSelectedItemIds } = useLibraryPanelContext();

  const clickMeshHandler = useClickMeshHandler();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [hasLoadingScreen, setHasLoadingScreen] = useState(true);

  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (!currentModelId) {
      setHasLoadingScreen(false);
      // if model is ready then remove the loading screen with a slight delay for effect
    } else if (isModelLoaded) {
      setTimeout(() => {
        setHasLoadingScreen(false);
      }, 300);
    } else {
      setHasLoadingScreen(true);
    }
  }, [isModelLoaded]);

  const handleOutsideClick = (event: MouseEvent) => {
    if ((selected || multiSelectedItemIds.length > 0) && event.type === 'click') {
      dispatch({
        type: ActionType.CLEAR_SELECTED,
      });
      libDispatch({
        type: LibActionType.SET_MULTI_SELECT_ITEMS,
        multiSelectedItemIds: [],
      });
    }
  };

  const clickHandlerProps = useObjectClickHandler(
    (event) => {
      if (event.object.type === 'Mesh') {
        clickMeshHandler(event.object, event);
      } else if (event.object.type === 'LineSegments' && event.object.parent) {
        clickMeshHandler(event.object.parent, event);
      }
    },
    resultsView !== ResultsView.ResultsGridReceiversView,
    view3D
  );

  return (
    <>
      <Canvas
        frameloop="demand"
        resize={DEFAULT_RESIZE_SETTINGS}
        id="viewport"
        ref={canvasRef}
        scene={DEFAULT_SCENE_SETTINGS}
        raycaster={{
          ...DEFAULT_RAYCASTER_SETTINGS,
          params: {
            ...DEFAULT_RAYCASTER_SETTINGS.params,
            Points: { threshold: selected?.type === 'ReceiverPoint' || selected?.type === 'SourcePoint' ? 0.8 : 0.2 },
          },
        }}
        camera={DEFAULT_CAMERA_SETTINGS}
        gl={{ ...DEFAULT_GL_SETTINGS, preserveDrawingBuffer: true }}>
        <hemisphereLight name="Default light" args={[0xffffff, 0xaaaaaa, 2.25]} position={[50, 100, 0]} />
        <Grid showAxes={!isCameraInsideModel && !hasLoadingScreen} />
        {isModelLoaded && (
          <>
            <group onPointerMissed={handleOutsideClick} {...clickHandlerProps}>
              <Model3D
                layerGroups={currentModel3dLayerGroups ?? []}
                view3D={view3D}
                selectedDetails={[selected, ...multiSelected]}
                hiddenLayers={hiddenLayers}
                layerColors={getLayerColors(selectedSimulation?.modelSettings?.materialIdByObjectId, filteredMaterials)}
              />
              {!isCameraInsideModel ? <Points /> : <PointsAuralizer />}

              {isAuralizerOpen || (isInResultsMode && resultsView !== ResultsView.ResultsGridReceiversView) ? null : (
                <GridReceivers />
              )}
            </group>
            {isInResultsMode && resultsView === ResultsView.ResultsGridReceiversView && (
              <GridReceiverResults isExporting={isExporting} setIsExporting={setIsExporting} />
            )}
            {isInResultsMode && resultsView === ResultsView.ResultsReflectogramView && (
              <ReflectogramResults view3D={view3D} />
            )}
            <ViewportControls canvasRef={canvasRef} />
          </>
        )}
      </Canvas>
      {isInResultsMode && resultsView === ResultsView.ResultsGridReceiversView && (
        <ExportHeatmapButton setIsExporting={setIsExporting} isExporting={isExporting} />
      )}
      {hasLoadingScreen && <BufferLoadingScreen />}
    </>
  );
};
