import { useEffect, useState } from 'react';

import { TrblSkeleton } from '@/components/Shared/TrblSkeleton';
import { useAuralizerContext } from '../AuralizerContext';
import { ReceiverPoint } from './ReceiverPoint';

import { Receiver } from '@/types';

import '../style.scss';

export const SharedReceiversColumn = ({ availableReceivers }: { availableReceivers: Receiver[] }) => {
  const { selectedAurSim, fetching, error } = useAuralizerContext();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAurSim && fetching?.[selectedAurSim?.id]?.isFetching) {
      setIsLoading(fetching?.[selectedAurSim?.id].isFetching);
    } else {
      setIsLoading(false);
    }
  }, [fetching, selectedAurSim]);

  useEffect(() => {
    if (selectedAurSim && error && error[selectedAurSim.id]) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error, selectedAurSim]);

  return (
    <div className={`shared-receivers ${hasError ? 'has-error' : ''}`}>
      <ul className="scrollable-list">
        {isLoading ? (
          <TrblSkeleton
            numberOfWaves={3}
            width=""
            style={{
              height: 22,
              margin: '18px',
            }}
          />
        ) : (
          availableReceivers.length > 0 &&
          availableReceivers.map((receiver: Receiver, index: number) => (
            <ReceiverPoint key={receiver.id} receiver={receiver} index={index} loading={isLoading} />
          ))
        )}
      </ul>
    </div>
  );
};
