import { Box, Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';

import { SourceDefinition } from '@/types';

export const SourceDefinitionDetails = ({ sd }: { sd: SourceDefinition }) => {
  return (
    <Box component={'div'} display="flex" flexGrow={1} gap="20px" mb="20px">
      <Stack gap={1} flex={'1 1 0'}>
        {sd.manufacturer && (
          <Box component={'div'}>
            <Text type="semibold-12px">Manufacturer: </Text>
            <Text type="regular-12px">{sd.manufacturer}</Text>
          </Box>
        )}
        <Box component={'div'}>
          <Text type="semibold-12px">Name: </Text>
          <Text type="regular-12px">{sd.name}</Text>
        </Box>
        <Box component={'div'}>
          <Text type="semibold-12px">Category: </Text>
          <Text type="regular-12px">{sd.subCategory}</Text>
        </Box>
      </Stack>
      <Stack gap={1} flex={'1 1 0'}>
        <Box component={'div'}>
          <Text type="semibold-12px">Type: </Text>
          <Text type="regular-12px">{sd.category}</Text>
        </Box>

        <Box component={'div'} maxHeight="56px" overflow="auto">
          <Text type="semibold-12px">Description: </Text>
          <Text type="regular-12px">{sd.description}</Text>
        </Box>
        {sd.isUserCreated && (
          <Box component={'div'} maxHeight="56px" overflow="auto">
            <Text type="semibold-12px">Created by: </Text>
            <Text type="regular-12px">{sd.userEmail ?? '-'}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
