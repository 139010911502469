import { useAuralizerContext } from '../Auralizer/AuralizerContext';
import { ActionType, usePresetContext } from '../Auralizer/PresetContext';
import { ActionType as LibActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { PrimaryButton } from '../Shared/Buttons';

export const LoadSoundButton = ({
  sourceIndex,
  setShowPopup,
}: {
  sourceIndex: number;
  setShowPopup: (shouldShowPopup: boolean) => void;
}) => {
  const { auralizerSounds } = useAuralizerContext();
  const { dispatch } = usePresetContext();
  const { highlightedItemId, dispatch: libDispatch } = useLibraryPanelContext();

  const onLoadSound = () => {
    if (highlightedItemId) {
      const newSound = auralizerSounds.find((sound) => sound.id === highlightedItemId);

      if (newSound) {
        dispatch({
          type: ActionType.SET_SELECTED_SOUND,
          sourceIndex,
          sound: {
            id: newSound.id,
            name: newSound.name,
            soundPath: newSound.soundPath,
            urlObject: null,
          },
        });
      }

      libDispatch({ type: LibActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: null });

      setShowPopup(false);
    }
  };

  return <PrimaryButton disabled={!highlightedItemId} width={'fit-content'} label="Load" onClick={onLoadSound} />;
};
