/* eslint-disable @typescript-eslint/no-explicit-any */
export const USER_CONTENT: { [key: string]: any } = {
  paid: {
    admin: {
      header: 'You already have a subscription',
      subheader: `Please go to the administration page to change your subscription.`,
    },
    regular: {
      header: 'You already have a subscription',
      subheader: `Thank you for using Treble! If you'd like to change your subscription, please contact the admin of your organization.`,
    },
  },
  earlyBird: {
    admin: {
      header: 'Early bird ends soon',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please select one of the subscription plans below.`,
    },
    regular: {
      header: 'Early bird ends soon',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please contact the admin of your organization.`,
    },
  },
  earlyBirdExpired: {
    admin: {
      header: 'Early bird offer has ended',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please select one of the subscription plans below.`,
    },
    regular: {
      header: 'Early bird offer has ended',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please contact the admin of your organization.`,
    },
  },
  trial: {
    admin: {
      header: 'Your trial ends soon',
      subheader: `If you'd like to keep using your Treble account after your trial, please select one of the subscription plans below.`,
    },
    regular: {
      header: 'Your trial ends soon',
      subheader: `If you'd like to keep using your Treble account after your trial, please contact the admin of your organization.`,
    },
  },
  trialExpired: {
    admin: {
      header: 'Your trial has ended',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please select one of the subscription plans below.`,
    },
    regular: {
      header: 'Your trial has ended',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please contact the admin of your organization.`,
    },
  },
  accessDisabled: {
    admin: {
      header: 'You no longer have access to Treble',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please contact support@treble.tech or reach out to us in the support chat in the lower left corner.`,
    },
    regular: {
      header: 'You no longer have access to Treble',
      subheader: `Thank you for using Treble! If you'd like to keep using your Treble account, please contact the admin of your organization.`,
    },
  },
};
