import { FrequencyWeightingType } from '@/components/Results/context/ReflectogramResultsContext/types';

import colors from '@/theme/colors.module.scss';

export const FREQUENCY_WEIGHTING_OPTIONS = [
  { id: FrequencyWeightingType.None, name: 'Unweighted' },
  { id: FrequencyWeightingType.AWeighting, name: 'A-weighting' },
  { id: FrequencyWeightingType.CWeighting, name: 'C-weighting' },
];

export const FREQUENCY_OPTIONS = [
  { id: 'Total', name: 'Total' },
  { id: '125', name: '125 Hz' },
  { id: '250', name: '250 Hz' },
  { id: '500', name: '500 Hz' },
  { id: '1000', name: '1k Hz' },
  { id: '2000', name: '2k Hz' },
  { id: '4000', name: '4k Hz' },
  { id: '8000', name: '8k Hz' },
];

export type TimeOfArrivalScale = {
  id: string;
  name: string;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
};

export type TimeOfArrivalGroup = {
  range: [number, number];
  color: string;
  selectedColor: string;
};

export const TIME_OF_ARRIVAL_SCALES: TimeOfArrivalScale[] = [
  {
    id: 'music',
    name: 'Music',
    timeOfArrivalGroups: [
      {
        range: [0, 0.002],
        color: '#FD6A6A',
        selectedColor: '#FEC5C5',
      },
      {
        range: [0.002, 0.02],
        color: colors.plotYellow,
        selectedColor: '#FDF8E8',
      },
      {
        range: [0.02, 0.08],
        color: colors.trebleNeonGreen,
        selectedColor: '#DBFFF7',
      },
      {
        range: [0.08, 0.2],
        color: colors.trebleLightBlue,
        selectedColor: '#99E6FF',
      },
      {
        range: [0.2, Infinity],
        color: colors.trebleBlue,
        selectedColor: '#99CAFF',
      },
    ],
  },
  {
    id: 'speech',
    name: 'Speech',
    timeOfArrivalGroups: [
      {
        range: [0, 0.002],
        color: '#FD6A6A',
        selectedColor: '#FEC5C5',
      },
      {
        range: [0.002, 0.05],
        color: colors.plotYellow,
        selectedColor: '#FDF8E8',
      },
      {
        range: [0.05, 0.08],
        color: colors.trebleNeonGreen,
        selectedColor: '#DBFFF7',
      },
      {
        range: [0.08, Infinity],
        color: colors.trebleLightBlue,
        selectedColor: '#99E6FF',
      },
    ],
  },
  {
    id: 'studio',
    name: 'Studio',
    timeOfArrivalGroups: [
      {
        range: [0, 0.002],
        color: '#FD6A6A',
        selectedColor: '#FEC5C5',
      },
      {
        range: [0.002, 0.015],
        color: colors.plotYellow,
        selectedColor: '#FDF8E8',
      },
      {
        range: [0.015, 0.05],
        color: colors.trebleNeonGreen,
        selectedColor: '#DBFFF7',
      },
      {
        range: [0.05, Infinity],
        color: colors.trebleLightBlue,
        selectedColor: '#99E6FF',
      },
    ],
  },
];

export const SCALE_OPTIONS = TIME_OF_ARRIVAL_SCALES.map((x) => ({ id: x.id, name: x.name }));
