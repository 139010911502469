import { useSimulationContext } from '@/context/SimulationContext';

import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';

import { createAudioConvolverChain } from '../utils';

import { AudioObject } from '../types';

export const useCreateAudioObjectForPair = () => {
  const { simSrcRecHash, dispatch } = useAuralizerContext();

  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const audioEngine = AudioEngine.getInstance();

  const createAudioObjectForPair = async (
    url: string,
    originalSourceId: string,
    originalReceiverId: string,
    simulationId: string
  ) => {
    if (simSrcRecHash && originalSim) {
      const originalMax = audioEngine.audioSettings.firstSimNormMax?.relMax;
      const normFactor = audioEngine.audioSettings[simulationId][originalSourceId].normFactor;

      const newAudioObject: AudioObject = await createAudioConvolverChain(
        url,
        originalMax,
        normFactor,
        simulationId,
        originalSourceId
      );

      dispatch({
        type: ActionType.SET_AUDIO_NODE_DICT,
        audioObject: newAudioObject,
        simId: simulationId,
        receiverId: originalReceiverId,
        sourceId: originalSourceId,
      });

      dispatch({
        type: ActionType.SET_FETCHING,
        simId: simulationId,
        isFetching: false,
      });
    }
  };

  return createAudioObjectForPair;
};
