import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useAuralizerContext } from '../AuralizerContext';
import { AuralizerPanelHeader } from './AuralizerPanelHeader';
import { MasterColumn } from './MasterColumn';
import { SharedReceiversColumn } from './SharedReceiversColumn';
import { SimulationsColumn } from './SimulationsColumn';
import { SourcePoints } from './SourcePoints';

import { useAuralizerPreset } from '../hooks/useAuralizerPreset';
import { useInitializeAuralizer } from '../hooks/useInitializeAuralizer';

import { soundOff } from '../utils';

import { Receiver } from '@/types';

import '../style.scss';

export const AuralizerPanel = () => {
  const {
    simulationState: { originalSim },
  } = useSimulationContext();
  const { selectedAurSim, error, simsAurObject, hasInitialized } = useAuralizerContext();

  const { dispatch: editorDispatch, isAuralizerOpen } = useEditorContext();
  const { isSourceDefinitionLibraryOpen, isMaterialsLibraryOpen, dispatch: libDispatch } = useLibraryPanelContext();

  const [isMinimized, setIsMinimized] = useState(false);
  const [openDelay, setOpenDelay] = useState(false);
  const [availableReceivers, setAvailableReceivers] = useState<Receiver[]>([]);

  useInitializeAuralizer();
  useAuralizerPreset();

  useEffect(() => {
    // close the library panel if opening the auralizer
    if (isSourceDefinitionLibraryOpen || isMaterialsLibraryOpen) {
      libDispatch({
        type: LibActionType.CLOSE_LIBRARY_PANEL,
      });
    }
  }, []);

  useEffect(() => {
    setOpenDelay(hasInitialized);
  }, [hasInitialized]);

  useEffect(() => {
    if (originalSim && Object.keys(simsAurObject).length > 0) {
      const tempAvailableReceivers: Receiver[] = simsAurObject[originalSim.id]?.receivers;
      if (tempAvailableReceivers?.length > 0) {
        setAvailableReceivers(tempAvailableReceivers);
      }
      editorDispatch({
        type: EditorActionType.SET_SELECTED_AUR_SOURCE,
        source: originalSim.sources[0],
        index: 0,
      });
    }
  }, [originalSim, simsAurObject]);

  useEffect(() => {
    return () => {
      // close AURAL and reset when isAuralizerOpen is true
      if (isAuralizerOpen) {
        console.log('[Auralization] CLOSE AURAL');

        editorDispatch({
          type: EditorActionType.UPDATE_COORDINATES,
          coordinates: null,
        });
        editorDispatch({
          type: EditorActionType.SET_IS_CAMERA_INSIDE_MODEL,
          payload: false,
        });

        soundOff();
      }
    };
  }, [isAuralizerOpen]);

  useEffect(() => {
    if (selectedAurSim && error && error[selectedAurSim.id]) {
      toast.warning(`${error[selectedAurSim.id].message} for ${selectedAurSim.name}`);
    }
  }, [error, selectedAurSim]);

  return (
    <div
      key={originalSim?.id}
      className={`auralizer-container ${isMinimized ? 'minimized' : openDelay && !isMinimized ? 'expanded' : ''}`}>
      {selectedAurSim && originalSim ? (
        <>
          <AuralizerPanelHeader
            originalSim={originalSim}
            sources={selectedAurSim.sources}
            isMinimized={isMinimized}
            setIsMinimized={setIsMinimized}
            availableReceivers={availableReceivers}
          />
          <div className="auralizer-body">
            <SimulationsColumn originalSim={originalSim} />
            <SharedReceiversColumn availableReceivers={availableReceivers} />
            <SourcePoints />
            <MasterColumn />
          </div>
        </>
      ) : null}
    </div>
  );
};
