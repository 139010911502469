import { FC, ReactNode, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { IconTypes, TrblIcon } from '@/components/Icons';
import { Text } from '../Text';

import styles from './styles.module.scss';

export type TabButton = {
  key: string;
  label?: string | ReactNode;
  icon?: IconTypes;
  tooltip?: string;
  disabled?: boolean;
};

type TabButtonsProps = {
  options: TabButton[];
  selectedValue: string | null;
  onChange: (selectedValue: string) => void;
  small?: boolean;
  dark?: boolean;
  height?: number;
};

export const TabButtons: FC<TabButtonsProps> = ({ options, selectedValue, onChange, small, dark, height }) => {
  const [tabHovered, setTabHovered] = useState<string | null>(null);

  return (
    <div className={`${styles['tabs-container']} ${small ? styles['small'] : ''} ${dark ? styles['dark'] : ''}`}>
      {options.map(({ key, label, icon, tooltip, disabled = false }) => (
        <TrblTooltip title={tooltip} key={key}>
          <button
            type="button"
            tabIndex={0}
            className={`${styles['tab']} ${selectedValue === key ? styles['selected'] : ''} ${
              disabled ? styles['disabled'] : ''
            }`}
            onClick={() => {
              if (!disabled) {
                onChange(key);
              }
            }}
            style={{ height }}
            onMouseOver={() => setTabHovered(key)}
            onMouseLeave={() => {
              setTabHovered(null);
            }}>
            {icon && (
              <TrblIcon
                icon={icon}
                color={
                  selectedValue === key
                    ? dark
                      ? '#39bd9d'
                      : '#00F5BA'
                    : disabled
                    ? '#F5F5F5'
                    : tabHovered === key
                    ? '#F5F5F5'
                    : undefined
                }
              />
            )}
            {label && (
              <Text
                type="regular-11px"
                style={{ whiteSpace: 'nowrap' }}
                color={(tabHovered === key && !disabled) || selectedValue === key ? '#F5F5F5' : undefined}>
                {label}
              </Text>
            )}
          </button>
        </TrblTooltip>
      ))}
    </div>
  );
};
