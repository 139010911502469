import { Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblChevronRight, TrblSimMaterialIcon } from '@/components/Icons';

import { Simulation } from '@/types';

import classes from './styles.module.scss';

type SelectedSimulationField = {
  spaceName: string;
  modelName: string;
  selectedSimulation: Simulation | null;
};
export const SelectedSimulationField = ({ spaceName, modelName, selectedSimulation }: SelectedSimulationField) => {
  return (
    <div className={`${classes.comparison_selection}`}>
      <Stack gap="4px">
        <Text className={classes.simulation_name} type="regular-10px">
          <TrblSimMaterialIcon fill="#dadada" />
          {selectedSimulation?.name}
        </Text>
        {spaceName && (
          <div className={classes.comparison_data}>
            <span>{spaceName}</span>
            {modelName && (
              <span>
                {' '}
                <TrblChevronRight /> {modelName}
              </span>
            )}
          </div>
        )}
      </Stack>
    </div>
  );
};
