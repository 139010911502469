import { FC } from 'react';

import { IconProps } from '@/types';

export const ToastCheckmarkIcon: FC<IconProps> = ({ fill = '#272727' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 3.13401 3.13401 0 7 0C10.8641 0.00450028 13.9955 3.13587 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7.048 10.2019L11.0409 4.78331C11.1759 4.61624 11.2081 4.3885 11.1249 4.19051C11.0416 3.99253 10.8562 3.85633 10.6424 3.836C10.4286 3.81567 10.2209 3.91449 10.1017 4.09323L6.46933 9.02298L4.09166 7.12073C3.84005 6.91937 3.47285 6.96012 3.2715 7.21173C3.07014 7.46334 3.11089 7.83054 3.3625 8.0319L6.21383 10.3116C6.33848 10.409 6.49705 10.4523 6.65391 10.4316C6.81077 10.411 6.95277 10.3282 7.048 10.2019Z"
      fill={fill}
    />
  </svg>
);
