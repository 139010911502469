import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

export type UpdateSourceSummingTaskRequest = {
  id: string;
  name: string;
  description: string;
};

const updateSourceSummingTask = async (request: UpdateSourceSummingTaskRequest): Promise<void> => {
  await axios.patch<void>(`/api/SourceSumming/UpdateSourceSummingTask`, request);
};

export const useUpdateSourceSummingTask = () => {
  return useMutation(async (request: UpdateSourceSummingTaskRequest) => await updateSourceSummingTask(request));
};
