import dayjs from 'dayjs';

import { Simulation } from '@/types';

import './styles.scss';

export const SimulationDates = ({
  simulation,
  lastSimRunDate,
}: {
  simulation: Simulation | null;
  lastSimRunDate: string | null;
}) => {
  return (
    <div className="dates-container">
      {simulation?.createdAt ? <p>Created: {dayjs(simulation?.createdAt).format('MMM DD YYYY, HH:mm')}</p> : null}

      {lastSimRunDate ? <p>Last run: {dayjs(lastSimRunDate).format('MMM DD YYYY, HH:mm')}</p> : null}
    </div>
  );
};
