import { ActionType, useAppContext } from '@/context/AppContext';

import { useShareMaterial } from '@/hooks';

import { Material } from '@/types';

export const useShareAndUpdateMaterial = () => {
  const {
    appState: { filteredMaterials },
    dispatch,
  } = useAppContext();

  const { mutate: shareMaterial } = useShareMaterial();

  const shareAndUpdateMaterial = (material: Material) => {
    shareMaterial(material.id);
    const updatedMaterial = { ...material, isSharedWithOrganization: true };

    const materialIndex = filteredMaterials.findIndex((filteredMaterial) => filteredMaterial.id === updatedMaterial.id);
    const newMaterials = [...filteredMaterials];
    newMaterials[materialIndex] = updatedMaterial;
    dispatch({
      type: ActionType.SET_MATERIALS,
      payload: newMaterials,
    });
  };

  return shareAndUpdateMaterial;
};
