export const config = {
  apiUrl: import.meta.env.VITE_API_URL,
  adminApiUrl: import.meta.env.VITE_ADMIN_API_URL,
  chargebeeApiKeyPublishable: import.meta.env.VITE_CHARGEBEE_API_KEY_PUBLISHABLE,
  viteChargebeeSiteName: import.meta.env.VITE_CHARGEBEE_SITE_NAME,
  viteAuth0LogoutUri: import.meta.env.VITE_AUTH0_LOGOUT_URI,
  mode: import.meta.env.MODE,
  appVersion: import.meta.env.VITE_APP_VERSION,
  cdnUrl: import.meta.env.VITE_CDN_URL,
  dev: import.meta.env.DEV,
};
