import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteSourceDefinition = async (sourceId: string): Promise<any> => {
  const { data } = await axios.delete(`/api/SourceDefinition/DeleteSourceDefinition?sourceId=${sourceId}`);
  return data;
};

export const useDeleteSourceDefinition = () => {
  return useMutation(async (sourceId: string) => await deleteSourceDefinition(sourceId));
};
