import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import { ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from '@mui/material';

import { CreateMaterial } from '../CreateMaterial';
import { CreateNewSimulationPopup } from '../CreateNewSimulationPopup';
import { CreateSourceDefinition } from '../CreateSourceDefinition';
import { TrblCreateIcon, TrblMaterialIcon, TrblSimulationIcon, TrblSourceDefinitionIcon } from '../Icons';
import { TertiaryButton } from '../Shared/Buttons';

import './styles.scss';

export const CreateButton = ({ hidden }: { hidden?: boolean }) => {
  const [open, setOpen] = useState(false);
  const [showSimulationPopup, setShowSimulationPopup] = useState<undefined | boolean>(undefined);
  const [showMaterialPopup, setShowMaterialPopup] = useState<undefined | boolean>(undefined);
  const [showSourceDefinitionPopup, setShowSourceDefinitionPopup] = useState<undefined | boolean>(undefined);
  const { dispatch } = useEditorContext();

  const anchorRef = useRef<HTMLButtonElement>(null);

  // For keyboard navigation we need to keep track globally when popups
  // are open that should have their own keyboard navigation or shortcuts
  useEffect(() => {
    if (showSimulationPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showSimulationPopup,
      });
    }
  }, [showSimulationPopup]);

  useEffect(() => {
    if (showMaterialPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showMaterialPopup,
      });
    }
  }, [showMaterialPopup]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_IS_CREATE_MENU_OPEN,
      isOpen: open,
    });
  }, [open]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const createNewSimulation = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowSimulationPopup(true);
    handleClose(event);
  };

  const createNewMaterial = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowMaterialPopup(true);
    handleClose(event);
  };

  const createNewSourceDefinition = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowSourceDefinitionPopup(true);
    handleClose(event);
  };
  return (
    <div>
      <TertiaryButton
        className={`create-button ${hidden ? 'hide-up' : ''}`}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        label="New"
        data-dd-action-name="New button"
        icon={<TrblCreateIcon />}
        onClick={handleToggle}></TertiaryButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}>
            <Paper className="create-button-paper">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  className="create-button-list">
                  <MenuItem onClick={createNewSimulation} data-dd-action-name="Create simulation option">
                    <ListItemIcon>
                      <TrblSimulationIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Simulation</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={createNewMaterial} data-dd-action-name="Create material option">
                    <ListItemIcon>
                      <TrblMaterialIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Material</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={createNewSourceDefinition} data-dd-action-name="Create Source Definition option">
                    <ListItemIcon>
                      <TrblSourceDefinitionIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Source definition</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showSimulationPopup && <CreateNewSimulationPopup setShowPopup={setShowSimulationPopup} />}
      {showMaterialPopup && <CreateMaterial setShowPopup={setShowMaterialPopup} />}
      {showSourceDefinitionPopup && <CreateSourceDefinition setShowPopup={setShowSourceDefinitionPopup} />}
    </div>
  );
};
