import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import { ORANGE_PLOT_COLOR } from '../CreateMaterial/constants';

type MaterialDetailsPlot = {
  ticktext: string[];
  tickvals: number[];
  xData: number[] | string[];
  plots: (number[] | undefined)[];
  plotTitles: string[];
  plotColors?: string[];
  plotMarker?: string[];
  plotDashed?: boolean[];
  yAxesTitle: string;
  range?: number[];
  plotHeight?: number;
  plotWidth?: number;
};

export const MaterialDetailsPlot = ({
  range,
  ticktext,
  tickvals,
  xData,
  plots,
  plotTitles,
  plotColors,
  plotMarker,
  plotDashed,
  yAxesTitle,
  plotHeight,
  plotWidth,
}: MaterialDetailsPlot) => {
  const [plotlyData, setPlotlyData] = useState<Data[]>([]);

  useEffect(() => {
    if (xData && plots.length > 0) {
      const plotData: Data[] = plots.map((plot, index) => {
        return createPlotObject(
          plotTitles[index] ?? '',
          xData,
          plot,
          plotColors?.[index] ?? ORANGE_PLOT_COLOR,
          plotDashed?.[index],
          plotMarker?.[index]
        );
      });

      setPlotlyData(plotData);
    }
  }, [xData, plots, plotTitles, plotColors, plotDashed, plotMarker]);

  const createPlotObject = (
    name: string,
    x: number[] | string[],
    y: number[] | undefined,
    color: string,
    dashed?: boolean,
    marker?: string
  ): Data => {
    return {
      name,
      x,
      y,
      type: 'scatter',
      mode: 'lines+markers',
      line: {
        dash: dashed ? 'dot' : 'solid',
        color,
      },
      marker: {
        symbol: marker ?? 'circle',
      },
    };
  };

  return (
    <Plot
      data={plotlyData}
      config={{ displaylogo: false }}
      layout={{
        width: plotWidth || 400,
        height: plotHeight,
        autosize: true,
        legend: {
          y: 1.15,
          orientation: 'h',
          font: {
            color: '#DADADA',
            size: 10,
          },
        },
        xaxis: {
          title: {
            text: 'FREQUENCY [Hz]',
            font: {
              size: 10,
              color: '#ADADAD',
            },
          },
          tickmode: 'array',
          range: [Math.log10(50), Math.log10(12000)],
          type: 'log',
          ticktext,
          tickvals,
          gridcolor: '#3c3c3c',
          griddash: 'dot',
        },
        yaxis: {
          range: range,
          title: {
            text: yAxesTitle,
            font: {
              size: 10,
              color: '#ADADAD',
            },
            standoff: 10,
          },

          gridcolor: '#3c3c3c',
          griddash: 'dot',
          zeroline: false,
        },
        margin: {
          l: 54,
          r: 10,
          b: 40,
          t: 2,
          pad: 5,
        },

        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        font: {
          family: 'Inter, Helvetica, Arial, sans-serif',
          color: '#DADADA',
          size: 10,
        },
      }}
    />
  );
};
