import { useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { Divider } from '@/components/Shared/Divider';
import { ReceiversTabs } from './ReceiverTabs';
import { Sources } from './Sources';

import styles from './styles.module.scss';

export const SourceRecieverSettings = ({
  tabValue,
  setTabValue,
}: {
  tabValue: number;
  setTabValue: (newValue: number) => void;
}) => {
  const { sources, receivers, gridReceivers, readonly } = useEditorContext();
  const [selectedSourceId, setSelectedSourceId] = useState<string | undefined>();
  const selectedSourceIndex = selectedSourceId ? sources.findIndex((s) => s.id === selectedSourceId) : -1;
  const selectedSourceSettings = selectedSourceIndex > -1 ? sources[selectedSourceIndex] : undefined;

  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();

  return (
    <div className={styles.sources_and_receivers_container}>
      <Sources
        sources={sources}
        selectedSourceSettings={selectedSourceSettings}
        setSelectedSourceId={setSelectedSourceId}
        sourceIndex={selectedSourceIndex}
        isDisabled={selectedSimulation?.extra.status === 1}
        readonly={readonly}
      />

      <Divider />
      <ReceiversTabs
        tabValue={tabValue}
        setTabValue={setTabValue}
        receivers={receivers}
        gridReceivers={gridReceivers}
        isDisabled={selectedSimulation?.extra.status === 1}
        readonly={readonly}
      />
    </div>
  );
};
