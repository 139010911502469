import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, Stack } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent } from '@/components/Shared/Popup';
import { TextField } from '@/components/Shared/TextField';

interface AddNewProjectProps {
  addNewProject: (name: string) => void;
  onClose: () => void;
  projects: { id: string; name: string }[];
}
export const AddNewProjectPopup = ({ addNewProject, onClose, projects }: AddNewProjectProps) => {
  const [name, setName] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState(true);

  // Form validation
  useEffect(() => {
    if (name?.length) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name]);

  const doesProjectNameExist = (name: string) => {
    return projects?.length > 0 && projects.some((p) => p.name.toLowerCase() === name.toLowerCase());
  };

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault();

    if (doesProjectNameExist(name)) {
      toast.warning('Project with this name already exists. Please choose another one.');
      return;
    }

    addNewProject(name);
    onClose();
  };

  return (
    <TrblPopup hideBackdrop={false} aria-labelledby={'New project'} open={true}>
      <form>
        <TrblPopupContent>
          <Stack gap={1} margin={'4px 0 16px'}>
            <TextField autoFocus label="Project" placeholder="Name of project" value={name} onChange={setName} />
          </Stack>
        </TrblPopupContent>
        <TrblPopupActions>
          <Box component="div" display="flex" justifyContent="space-between" width="100%">
            <SecondaryButton width={'auto'} label="Cancel" onClick={onClose} />

            <PrimaryButton type="submit" disabled={!isFormValid} width={'auto'} label="Add" onClick={handleSubmit} />
          </Box>
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
