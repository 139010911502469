import { toast } from 'react-toastify';

import { Source } from '@/context/EditorContext/types';

export const showInvalidSourcesInfo = (sources: Source[]) => {
  for (const source of sources) {
    if (source.validationError == 'CloseToSurface') {
      toast.warning(
        <p>
          Some sources are too close to a surface for using the wave solver. See{' '}
          <a
            style={{ textDecoration: 'underline' }}
            target="_blank"
            href="https://docs.treble.tech/user-guide/simulations/sources_and_receivers#sources">
            documentation
          </a>
        </p>,
        {
          className: 'editor-toast',
          toastId: 'invalid-sources',
        }
      );
      break;
    }
  }
};
