import { FC, useEffect, useState } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';

import { Box, useMediaQuery } from '@mui/material';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { Tabs } from '@/components/Shared/Tabs';
import { ParameterResults, ResponsePlot } from '@/components';
import { ParsedParameterData } from '@/components/Results/components/ParameterResults/types';
import { useParameterData } from '@/components/Results/components/ParameterResults/useParameterData';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

type PointReceiverResultsProps = {
  view: ResultsView;
};

export const PointReceiverResults: FC<PointReceiverResultsProps> = ({ view }) => {
  const { availableComparisons, emptyResults } = useResultsContext();
  const parameterData: ParsedParameterData[] = useParameterData(availableComparisons);

  const [selectedResultTab, setSelectedResultTab] = useState<string>('parameters');

  const isSmallHeightScreen = useMediaQuery('(max-height: 1000px)');
  const isSmallWidthScreen = useMediaQuery('(max-width: 1400px)');

  const showResultTabs = (view !== ResultsView.ResultsReportView && isSmallHeightScreen) || isSmallWidthScreen;

  useEffect(() => {
    // Always reset to the first tab
    setSelectedResultTab('parameters');
  }, [showResultTabs]);

  return (
    <Box
      component="div"
      className={styles['point-receiver-plots']}
      flexDirection={view === ResultsView.ResultsReportView && !showResultTabs ? 'row' : 'column'}
      gap={showResultTabs ? '0px' : '8px'}>
      {showResultTabs && (
        <Tabs
          selectedValue={selectedResultTab}
          options={[
            { key: 'parameters', label: 'Parameters' },
            { key: 'plots', label: 'Plots' },
          ]}
          onChange={setSelectedResultTab}
        />
      )}
      <div
        className={`${styles['plot-container']} ${isSmallWidthScreen ? styles['full-width'] : ''} ${
          showResultTabs && selectedResultTab !== 'parameters' ? styles['tab-not-active'] : ''
        } `}>
        <ParameterResults parameterData={parameterData} inTabs={showResultTabs} />
      </div>
      <div
        className={`${styles['plot-container']} ${isSmallWidthScreen ? styles['full-width'] : ''} ${
          showResultTabs && selectedResultTab !== 'plots' ? styles['tab-not-active'] : ''
        } `}>
        <ResponsePlot parameterData={parameterData} comparisons={availableComparisons} inTabs={showResultTabs} />
      </div>
      {emptyResults && (
        <BufferLoadingScreen
          message="Add a simulation to view results"
          sx={{ backdropFilter: 'blur(4px) !important' }}
          showAnimation={false}
        />
      )}
    </Box>
  );
};
