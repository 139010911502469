import { useState } from 'react';

import { PageLayout, ProjectsView, RecentSimulationsHeader, RightSidebar } from '@/components';
import { DashboardHeader } from '@/components/DashboardHeader/DashboardHeader';

import { useGetProjects } from '@/hooks';

export const Dashboard = () => {
  const { data: availableProjects, isLoading } = useGetProjects();

  const [projectFilter, setProjectFilter] = useState<string>('all');
  const [projectSearchString, setProjectSearchString] = useState('');
  const [viewType, setViewType] = useState('cards');
  const [minimized, setMinimized] = useState(false);

  return (
    <PageLayout
      isFetching={isLoading}
      extraHeader={
        <DashboardHeader
          projectFilter={projectFilter}
          setProjectFilter={setProjectFilter}
          setProjectSearchString={setProjectSearchString}
          viewType={viewType}
          setViewType={setViewType}
          minimized={minimized}
          setMinimized={setMinimized}
        />
      }
      mainContentMinWidth={'700px'}
      sidepanel={<RightSidebar />}
      sidepanelExtraHeader={<RecentSimulationsHeader showBorder={false} />}>
      <div>
        <ProjectsView
          availableProjects={availableProjects ?? []}
          projectFilter={projectFilter}
          projectSearchString={projectSearchString}
          viewType={viewType}
          minimized={minimized}
        />
      </div>
    </PageLayout>
  );
};
