import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type UpdateModelBaseRequest = {
  id: string;
  name: string;
};

const updateModelBase = async (request: UpdateModelBaseRequest) => {
  const { data } = await axios.patch(`/api/Model/UpdateModelBase`, null, {
    params: {
      modelBaseId: request.id,
      modelName: request.name,
    },
  });
  return data;
};

export const useUpdateModelBase = () => {
  return useMutation(async (data: UpdateModelBaseRequest) => await updateModelBase(data));
};
