import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

const getDownloadUrl = async (fileUploadId?: string) => {
  if (fileUploadId) {
    const { data } = await axios.get(`/api/Upload/GetDownloadUrl?fileUploadId=${fileUploadId}`);
    return data;
  }
};

export const useGetDownloadUrl = (fileUploadId?: string, staleTime?: number) =>
  useQuery<string, boolean>(['download-url', fileUploadId], () => getDownloadUrl(fileUploadId), {
    enabled: !!fileUploadId,
    refetchOnWindowFocus: false,
    staleTime,
  });
