export enum Warnings {
  ISOLATED_EDGES = 'isolated_edges',
  NAKED_EDGES = 'naked_edges',
  EXCLUDED_FACES = 'excluded_faces',
  INVALID_FACES = 'invalid_faces',
  SMALL_EDGES = 'small_edges',
  PROBLEM_AREAS = 'problem_areas',
}

export const WarningsInfo: Record<Warnings, string[]> = {
  isolated_edges: [
    'Isolated edges',
    'This warning highlights isolated open edges, which may display a hole for instance.',
  ],
  naked_edges: [
    'Open edges',
    'This warning highlights open surfaces edges which surround an open object. Open edges cause the model to not be watertight.',
  ],
  excluded_faces: [
    'Excluded faces',
    'This warning highlights the faces that are not included in the largest manifold. Those faces will be excluded from the imported model.',
  ],
  invalid_faces: ['Invalid faces', 'Warnings found with invalid faces.'],
  small_edges: [
    'Short edges',
    'This warning highlights short edges. Short edges may affect the runtime of your simulation. Consider simplifying your geometry by removing unnecessary details.',
  ],

  problem_areas: [
    'Problem areas',
    'This warning highlights the areas that contain small gaps between two elements that will impact the speed of the wave solver.',
  ],
};
