import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { BillingPeriod, Organization, Subscription, UserDto } from './types';

import axios from '@/axios';

export type OrganizationResponse = {
  organization: Organization;
  users: UserDto[];
  activeSubscription?: Subscription;
  activeBillingPeriod?: BillingPeriod;
};

const getOrganizationById = async (organizationId: string): Promise<OrganizationResponse> => {
  const { data } = await axios.get(`/api/Organization/GetById`, {
    params: {
      organizationId,
    },
  });

  return data;
};

export const useGetOrganizationById = (organizationId: string) => {
  return useQuery<OrganizationResponse>(['organization', organizationId], () => getOrganizationById(organizationId), {
    refetchOnWindowFocus: false,
    onError: () => toast.error('An error occurred while loading the organization!'),
  });
};
