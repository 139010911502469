import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { SharedAuralizationInfoDto } from '../types';

import axios from '@/axios';

type CreateSharedAuralizationDto = {
  auralizationPresetId: string;
  emails: string[];
  expiresAt: string;
  createAnonymousUrl: boolean;
};

const createSharedAuralization = async (
  sharedAuralization: CreateSharedAuralizationDto
): Promise<SharedAuralizationInfoDto> => {
  const { data } = await axios.post(`/api/SharedAuralization/CreateSharedAuralization`, sharedAuralization);
  return data;
};

export const useCreateSharedAuralization = () => {
  return useMutation(
    async (sharedAuralization: CreateSharedAuralizationDto) => await createSharedAuralization(sharedAuralization),
    {
      onError: () => {
        toast.error('An error occurred while sharing the auralization. Please refresh the browser.');
      },
    }
  );
};
