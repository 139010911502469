import { useCallback, useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Camera, Quaternion, Vector3 } from 'three';

const quaternion = new Quaternion();
const quaternionAxis = new Vector3(0, 0, 1);
const worldDirection = new Vector3();

export const useFirstPersonCamera = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  syncAudioWithCamera: (camera: Camera) => void,
  isEnabled: boolean = true,
  syncAudio: boolean = false
) => {
  const { camera, invalidate } = useThree();

  const isMouseDown = useRef<boolean>(false);
  const lastMousePosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const handleMouseDown = useCallback((event: MouseEvent) => {
    const { clientX, clientY } = event;
    lastMousePosition.current = { x: clientX, y: clientY };
    isMouseDown.current = true;
  }, []);

  const handleMouseUp = useCallback(() => {
    isMouseDown.current = false;
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (isMouseDown.current) {
        const { x: lastX, y: lastY } = lastMousePosition.current;
        const { clientX, clientY } = event;

        const deltaX = clientX - lastX;
        const deltaY = clientY - lastY;

        // Calculate the rotation speed based on mouse movement
        const rotationSpeed = 0.005;

        // Update the camera rotation based on mouse movement
        if (camera) {
          // Calculate the current pitch
          camera.getWorldDirection(worldDirection).normalize();
          const currentPitch = Math.asin(worldDirection.z);

          // Calculate the new pitch
          const newPitch = currentPitch - deltaY * rotationSpeed;

          // avoid glitch when passing over the zero, rad
          const distanceToZero = 0.01;
          // Clamp the pitch to the range [-π/2, π/2], (ceiling and floor)
          const maxPitch = Math.PI / 2 - distanceToZero;
          const minPitch = -Math.PI / 2 + distanceToZero;
          const clampedPitch = Math.max(minPitch, Math.min(maxPitch, newPitch));

          // Rotate the camera by the difference in pitch
          camera.rotateX(clampedPitch - currentPitch);

          // this is now z
          quaternion.setFromAxisAngle(quaternionAxis, -deltaX * rotationSpeed);
          camera.applyQuaternion(quaternion);

          // Request a new frame to render
          invalidate();

          if (syncAudio) {
            syncAudioWithCamera(camera);
          }
        }

        lastMousePosition.current = { x: clientX, y: clientY };
      }
    },
    [syncAudio]
  );

  const handleZoom = useCallback((event: Event) => event.preventDefault(), []);

  // Controls
  useEffect(() => {
    if (isEnabled) {
      // Add the mouse event listeners to the canvas
      canvasRef.current!.addEventListener('scroll', handleZoom);
      canvasRef.current!.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      canvasRef.current!.removeEventListener('scroll', handleZoom);
      canvasRef.current!.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    }
  }, [isEnabled]);

  const updateCamera = useCallback(
    (position: Vector3, lookAt?: Vector3) => {
      if (camera) {
        camera.position.set(position.x, position.y, position.z);
        if (lookAt) {
          camera.lookAt(lookAt);
        }
        if (syncAudio) {
          syncAudioWithCamera(camera);
        }
        invalidate();
      }
    },
    [syncAudio]
  );

  return {
    updateCamera,
  };
};
