import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblReferenceCurveIcon: FC<IconProps> = ({ fill = '#ADADAD', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.45833 11.6667C8.17567 11.6665 7.9338 11.4638 7.88433 11.1855L7.1435 7.03854C7.13094 6.96915 7.07052 6.91869 7 6.91869C6.92948 6.91869 6.86906 6.96915 6.8565 7.03854L6.11567 11.1855C6.06541 11.463 5.82374 11.6649 5.54167 11.6649C5.25959 11.6649 5.01792 11.463 4.96767 11.1855L4.20933 6.94171C4.19677 6.87232 4.13635 6.82186 4.06583 6.82186C3.99531 6.82186 3.9349 6.87232 3.92233 6.94171L3.78117 7.69246C3.7288 7.96755 3.48836 8.16661 3.20833 8.16671L0.583333 8.17371C0.261167 8.17371 0 7.91254 0 7.59038C0 7.26821 0.261167 7.00704 0.583333 7.00704L2.60342 7.00004C2.67273 7.00006 2.73247 6.95129 2.74633 6.88338L3.5105 2.80996C3.56188 2.53395 3.80258 2.33369 4.08333 2.33338C4.36493 2.33461 4.60544 2.53684 4.655 2.81404L5.39583 6.96096C5.4084 7.03035 5.46881 7.08081 5.53933 7.08081C5.60985 7.08081 5.67027 7.03035 5.68283 6.96096L6.42367 2.81404C6.47392 2.53648 6.71559 2.33464 6.99767 2.33464C7.27974 2.33464 7.52141 2.53648 7.57167 2.81404L8.3125 6.96096C8.32506 7.03035 8.38548 7.08081 8.456 7.08081C8.52652 7.08081 8.58694 7.03035 8.5995 6.96096L9.34033 2.81404C9.3902 2.53511 9.63331 2.33236 9.91667 2.33338C10.1971 2.33358 10.4376 2.53325 10.4895 2.80879L11.2537 6.88221C11.2675 6.95012 11.3273 6.99889 11.3966 6.99888L13.4167 7.00704C13.7388 7.00704 14 7.26821 14 7.59038C14 7.91254 13.7388 8.17371 13.4167 8.17371L10.7917 8.16671C10.5118 8.16561 10.2721 7.96584 10.2206 7.69071L10.0777 6.94171C10.0651 6.87232 10.0047 6.82186 9.93417 6.82186C9.86365 6.82186 9.80323 6.87232 9.79067 6.94171L9.03233 11.186C8.98262 11.4641 8.74081 11.6666 8.45833 11.6667Z"
      fill={fill}
    />
  </svg>
);
