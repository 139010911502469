import { FC } from 'react';

import { ButtonProps, IconButton } from '@mui/material';

interface TrblIconButtonProps extends ButtonProps {
  icon: React.ReactElement;
  edge?: false | 'start' | 'end';
  label?: string;
  className?: string;
}

export const TrblIconButton: FC<TrblIconButtonProps> = ({
  icon,
  edge = 'start',
  label,
  className,
  ...IconButtonProps
}) => (
  <IconButton edge={edge} name={label} {...IconButtonProps} className={className}>
    {icon}
  </IconButton>
);
