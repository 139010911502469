import { useEffect, useState } from 'react';

import { ControlButton, ReceiverSelector } from '@/components/Shared/Auralizer';
import { TrblArrowLeft, TrblArrowRight } from '../../../Icons';
import { SelectOption } from '../../../Shared/TrblSelect';
import { useAuralizerContext } from '../../AuralizerContext';

import { useClickReceiver } from '../../hooks/useClickReceiver';

import { Receiver } from '@/types';

import '../../style.scss';

export const ReceiverControls = ({
  isMinimized,
  availableReceivers,
}: {
  isMinimized: boolean;
  availableReceivers: Receiver[];
}) => {
  const { selectedReceiver } = useAuralizerContext();

  const [menuItems, setMenuItems] = useState<SelectOption[]>([]);

  useEffect(() => {
    // initial load
    const newMenuItems = availableReceivers.map((receiver, index) => {
      return {
        id: index.toString(),
        name: receiver.label ? receiver.label : 'Receiver ' + (index + 1),
      };
    });
    setMenuItems(newMenuItems);
  }, [availableReceivers]);

  const clickReceiver = useClickReceiver();

  const clickPreviousReceiver = () => {
    if (selectedReceiver === null) selectReceiver(availableReceivers.length - 1);
    else if (selectedReceiver.index > 0) {
      selectReceiver(selectedReceiver.index - 1);
    } else {
      selectReceiver(availableReceivers.length - 1);
    }
  };
  const clickNextReceiver = () => {
    if (selectedReceiver === null) selectReceiver(0);
    else if (selectedReceiver.index < availableReceivers.length - 1) {
      selectReceiver(selectedReceiver.index + 1);
    } else {
      selectReceiver(0);
    }
  };
  const selectReceiver = (index: number) => {
    const receiver = availableReceivers[index];
    clickReceiver(receiver, index);
  };

  return (
    <div className={`receiver-controls-header ${isMinimized ? 'active' : ''} `}>
      <ControlButton
        title="Previous receiver"
        onClick={clickPreviousReceiver}
        style={{ margin: '0 4px' }}
        icon={<TrblArrowLeft width="12" height="12" />}
      />
      <ControlButton
        title="Next receiver"
        onClick={clickNextReceiver}
        style={{ margin: '0 12px 0 4px' }}
        icon={<TrblArrowRight width="12" height="12" />}
      />
      {isMinimized && (
        <ReceiverSelector
          value={selectedReceiver ? selectedReceiver.index.toString() : ''}
          setValue={(value) => selectReceiver(Number(value))}
          menuItems={menuItems}
        />
      )}
    </div>
  );
};
