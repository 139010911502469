import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCloseEyeIcon: FC<IconProps> = ({ fill }) => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60344 2.87764C10.4032 3.3822 11.1316 3.99197 11.7689 4.69058C12.1421 5.10496 12.1436 5.73382 11.7724 6.15C10.4822 7.56812 8.3505 9.07084 6.2072 9.07084H6.05613C5.30853 9.05786 4.57088 8.89715 3.88564 8.59797L1.79773 10.6859C1.62789 10.8551 1.36227 10.8812 1.1628 10.7481C0.963328 10.615 0.885338 10.3598 0.976373 10.138C0.978541 10.1358 0.981001 10.1345 0.983439 10.1332C0.985531 10.1321 0.987606 10.131 0.989466 10.1294L10.8096 0.309312C10.8496 0.267886 10.8794 0.217713 10.8967 0.162766L10.9057 0.153702C11.0322 0.022789 11.2194 -0.0297137 11.3955 0.0163729C11.5716 0.0624595 11.7091 0.199963 11.7551 0.376035C11.8012 0.552106 11.7487 0.739344 11.6178 0.865784L9.60344 2.87764ZM6.78986 7.58574C7.51367 7.35696 8.0786 6.78676 8.30064 6.06086C8.45757 5.55206 8.42153 5.00327 8.19942 4.51936C8.18148 4.48211 8.14651 4.45594 8.10571 4.44922C8.06491 4.44251 8.0234 4.45609 7.99446 4.48562L5.19448 7.2851C5.16521 7.31421 5.15186 7.3557 5.15866 7.39642C5.16547 7.43714 5.19158 7.47204 5.22872 7.49006C5.71901 7.71533 6.27574 7.74946 6.78986 7.58574Z"
      fill={fill ? fill : '#616161'}
    />
    <path
      d="M3.92038 6.12633C3.95183 6.09461 3.96359 6.04834 3.9511 6.00546C3.8951 5.81568 3.86528 5.61915 3.86247 5.4213C3.86247 4.16972 4.87707 3.15512 6.12864 3.15512C6.32644 3.15719 6.52302 3.18651 6.71281 3.24224C6.75578 3.2546 6.80207 3.24264 6.83368 3.21102L7.81115 2.23304C7.8426 2.20165 7.8555 2.15619 7.84523 2.11296C7.83496 2.06973 7.80299 2.03493 7.76079 2.02103C7.2334 1.85176 6.68252 1.76711 6.12864 1.77024C3.96319 1.74103 1.79774 3.25584 0.494937 4.69058C0.116209 5.1032 0.11468 5.73656 0.491412 6.151C1.01173 6.72067 1.59223 7.23229 2.22277 7.67689C2.27386 7.71221 2.34288 7.70607 2.38694 7.66229L3.92038 6.12633Z"
      fill={fill ? fill : '#616161'}
    />
  </svg>
);
