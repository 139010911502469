import { useEffect } from 'react';

import { Box } from '@mui/material';

import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { useAuralizerContext } from '../Auralizer/AuralizerContext';
import { usePresetContext } from '../Auralizer/PresetContext';
import { ActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { LoadSoundButton } from './LoadSoundButton';
import { LoadSoundLibrary } from './LoadSoundLibrary';

import { LibrarySound } from '../Auralizer/constants/audioSounds';

import { SourceSettings } from '../AuralizerPresets/types';

const POPUP_TITLE = 'Sound library';

export const SoundLibraryPopup = ({
  showPopup,
  setShowPopup,
  sourceSettings,
  sourceIndex,
}: {
  showPopup: boolean;
  sourceSettings?: SourceSettings;
  setShowPopup: (shouldShowPopup: boolean) => void;
  sourceIndex: number;
}) => {
  const { hasInitialized, auralizerSounds } = useAuralizerContext();
  const { selectedPresetEdited } = usePresetContext();

  const { dispatch } = useLibraryPanelContext();

  useEffect(() => {
    if (auralizerSounds.length > 0 && sourceSettings && !selectedPresetEdited && hasInitialized) {
      const newSound = sourceSettings.soundPath ?? '';
      const soundItem = auralizerSounds.find((item: LibrarySound) => item.id === newSound);
      if (soundItem) {
        dispatch({ type: ActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: soundItem.id });
      }
    } else if (sourceSettings === undefined && !selectedPresetEdited) {
      dispatch({ type: ActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: null });
    }
  }, [sourceSettings?.soundPath, selectedPresetEdited, auralizerSounds]);

  const onClosePopup = () => {
    dispatch({ type: ActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: null });
    setShowPopup(false);
  };

  return (
    <TrblPopup
      width={800}
      hideBackdrop={false}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px' }}
      open={showPopup}
      onClose={onClosePopup}>
      <TrblPopupTitle onClose={onClosePopup}>{POPUP_TITLE}</TrblPopupTitle>
      <TrblPopupContent>
        <LoadSoundLibrary setShowPopup={setShowPopup} sourceIndex={sourceIndex} />
      </TrblPopupContent>
      <TrblPopupActions>
        <Box component="div" display="flex" width="100%" justifyContent={'flex-end'}>
          <LoadSoundButton setShowPopup={setShowPopup} sourceIndex={sourceIndex} />
        </Box>
      </TrblPopupActions>
    </TrblPopup>
  );
};
