import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ModelInformationDto } from '@/types';

import axios from '@/axios';

const getModelInformation = async (modelId: string) => {
  const { data } = await axios.get(`/api/Model/GetModelInformation?modelId=${modelId}`);

  return data;
};

export const useGetModelInformation = (modelId: string | null) => {
  const query = useQuery<ModelInformationDto, AxiosError>(
    ['model-information', modelId],
    () => getModelInformation(modelId!),
    {
      enabled: !!modelId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => {
        toast.error(`Could not get information for model with id ${modelId}`, {
          className: 'editor-toast',
          toastId: `model-information-${modelId}`,
        });
      },
    }
  );

  return query;
};
