/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { DeleteSharp, EditSharp, LockReset } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { useBaseContext } from '@/context/BaseContext';

import { Switch } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

import { UserDto, useResetPassword } from '../../hooks';

import { gridStyleOverrides } from './utils';

import styles from './styles.module.scss';

const renderRowActions = (
  params: GridRenderCellParams<any, UserDto, any>,
  onEditUser: (userId: string) => void,
  onDeleteUser: (userId: string) => void,
  isUsingSso: boolean
) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResendConfirmation, setShowResendConfirmation] = useState(false);

  const { mutate: resetPassword } = useResetPassword();

  return (
    <>
      <div className={styles['row-actions-cell']}>
        <GridActionsCellItem onClick={() => onEditUser(params.row.id)} icon={<EditSharp />} label="Edit" title="Edit" />
        {!isUsingSso && (
          <GridActionsCellItem
            onClick={() => setShowResendConfirmation(true)}
            icon={<LockReset />}
            label="Send set password email"
            title="Send set password email"
          />
        )}
        <GridActionsCellItem
          onClick={() => setShowDeleteConfirmation(true)}
          icon={<DeleteSharp />}
          label="Delete"
          title="Delete"
        />
        {showDeleteConfirmation && (
          <ConfirmationDialog
            title="Confirm delete"
            message={`Are you sure you want to delete the user with email ${params.row.email}?`}
            onConfirm={() => {
              onDeleteUser(params.row.id);
              setShowDeleteConfirmation(false);
            }}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
        {showResendConfirmation && (
          <ConfirmationDialog
            title="Confirm send"
            message={`Are you sure you want to send a set password email to ${params.row.email}?`}
            onConfirm={() => {
              resetPassword(params.row.id);
              setShowResendConfirmation(false);
            }}
            onCancel={() => setShowResendConfirmation(false)}
          />
        )}
      </div>
    </>
  );
};

const renderAdminCell = (
  params: GridRenderCellParams<any, UserDto, any>,
  currentUserId: string,
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void
) => {
  const isAdmin = params.row.roles?.includes('Admin') || false;
  const isDisabled = isAdmin && currentUserId === params.row.id;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleToggle = () => {
    setShowConfirmation(true);
  };

  return (
    <>
      <TrblTooltip title={isDisabled ? 'You can not revoke your own admin permissions' : ''}>
        <span>
          <Switch
            disabled={isDisabled}
            checked={isAdmin}
            onChange={handleToggle}
            inputProps={{ 'aria-label': 'Is admin' }}
          />
        </span>
      </TrblTooltip>
      {showConfirmation && (
        <ConfirmationDialog
          title="Confirm admin change"
          message={
            !isAdmin
              ? `Are you sure you want to grant Admin permissions to this user?`
              : `Are you sure you want to revoke Admin permissions for this user?`
          }
          onConfirm={() => {
            onToggleIsAdmin(params.row.id, !isAdmin);
            setShowConfirmation(false);
          }}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

const getColumns = (
  onEditUser: (userId: string) => void,
  onDeleteUser: (userId: string) => void,
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void,
  currentUserId: string,
  isSuperUser: boolean,
  isUsingSso: boolean
): GridColDef<UserDto>[] => {
  const columns: GridColDef<UserDto>[] = [
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 200,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 300,
      editable: false,
    },
    {
      field: 'isAdmin',
      headerName: 'Is admin?',
      width: 100,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => renderAdminCell(params, currentUserId, onToggleIsAdmin),
      valueGetter: (params) => params.row.roles?.includes('Admin'),
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'date',
      width: 200,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => renderRowActions(params, onEditUser, onDeleteUser, isUsingSso),
    },
  ];

  if (isSuperUser) {
    columns.splice(5, 0, {
      field: 'trialExpiresAt',
      headerName: 'Trial expires at',
      type: 'date',
      width: 150,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
    });
  }

  return columns;
};

export type UsersGridProps = {
  organizationId: string;
  isSuperUser: boolean;
  isLoading: boolean;
  isUsingSso: boolean;
  users: UserDto[];
  onEditUser: (userId: string) => void;
  onDeleteUser: (userId: string) => void;
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void;
};

export const UsersGridOld: FC<UsersGridProps> = ({
  users,
  isLoading,
  isUsingSso,
  onEditUser,
  onDeleteUser,
  onToggleIsAdmin,
  isSuperUser,
}) => {
  const {
    state: { userInfo },
  } = useBaseContext();

  const columns = getColumns(onEditUser, onDeleteUser, onToggleIsAdmin, userInfo?.id ?? '', isSuperUser, isUsingSso);

  return (
    <DataGrid
      sx={gridStyleOverrides}
      onRowDoubleClick={(params) => onEditUser(params.row.id)}
      rows={users}
      loading={isLoading}
      componentsProps={{
        row: { className: styles['data-grid-row'] },
        filterPanel: {
          filterFormProps: {
            deleteIconProps: {
              sx: {
                width: 'initial', // Weird fix needed for the filter panel
              },
            },
          },
        },
      }}
      columns={columns}
      autoPageSize={true}
      disableSelectionOnClick
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: 'email', sort: 'asc' }],
        },
      }}
    />
  );
};
