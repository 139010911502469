import { useQuery } from '@tanstack/react-query';

import { SourceResultForGridReceiverDto } from '@/types';

import axios from '@/axios';

const getLastGridReceiverResultsBySimulationId = async (simulationId: string) => {
  const { data } = await axios.get(
    `/api/SolveTask/GetLastGridReceiverResultsBySimulationId?simulationId=${simulationId}`
  );

  return await data;
};

export const useGetLastGridReceiverResultsBySimulationId = (simulationId: string, enabled = true) => {
  return useQuery<SourceResultForGridReceiverDto[], boolean>(
    ['lastGridReceiverResultsBySimulationId', simulationId],
    () => getLastGridReceiverResultsBySimulationId(simulationId),
    {
      refetchOnWindowFocus: false,
      enabled: !!simulationId && enabled,
    }
  );
};
