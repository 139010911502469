import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { usePresetContext } from '@/components/Auralizer/PresetContext';
import { LibraryRow } from '@/components/LibraryPanel';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { setElementFocus } from '@/components/LibraryPanel/utils';

import { useLoadPreset } from '../hooks/useLoadPreset';

import { AuralizationPresetDto } from '../types';

import styles from './styles.module.scss';

export const PresetRow = ({
  preset,
  sharedPresets,
  setShowPopup,
}: {
  preset: AuralizationPresetDto;
  sharedPresets: string[];
  setShowPopup: (show: boolean) => void;
}) => {
  const listItemRef = useRef<HTMLLIElement>(null);

  const [isAssigned, setIsAssigned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const { highlightedItemId } = useLibraryPanelContext();
  const { selectedPreset } = usePresetContext();

  const loadPreset = useLoadPreset();

  useEffect(() => {
    if (highlightedItemId === preset.id) {
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else {
      setIsHighlighted(false);
    }
  }, [highlightedItemId, listItemRef, selectedPreset]);

  useEffect(() => {
    if (selectedPreset && selectedPreset.id === preset.id) {
      setIsAssigned(true);
      setElementFocus(listItemRef);
      if (selectedPreset && selectedPreset.id === preset.id && highlightedItemId === null) {
        setIsHighlighted(true);
      }
    } else {
      setIsAssigned(false);
    }
  }, [selectedPreset, listItemRef]);

  const assignPreset = async (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();
    loadPreset(preset);
    setShowPopup(false);
  };

  return (
    <LibraryRow
      listItemRef={listItemRef}
      itemId={preset.id}
      isHighlighted={isHighlighted}
      isAssigned={isAssigned}
      onAssign={assignPreset}>
      <div className={styles.row_content}>
        <p className={styles.name_column} title={preset.name || ''}>
          {preset.name}
        </p>
        <p className={styles.shared_column}>{sharedPresets.includes(preset.id) ? 'Yes' : 'No'}</p>
        <p className={styles.created_column}> {dayjs(preset.createdAt).format('YYYY-MMM-DD, HH:mm')} </p>
      </div>
    </LibraryRow>
  );
};
