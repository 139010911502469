import { Layout } from 'plotly.js';

import { FrequencyWeightingType } from '@/components/Results/context/ReflectogramResultsContext/types';

import { AVAILABLE_FREQUENCY_BANDS } from '../constants';

import { getSPLUnit } from '../utils';

import { AxisType } from './types';

export const getLayoutConfig = (
  axisType: AxisType,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null,
  yAxisRange: [number, number]
) => {
  const config: Partial<Layout> = {
    uirevision: 'true', // Needed so that the zoom persists after a re-render (changing bar selection)
    barmode: 'group',
    bargap: axisType === AxisType.Linear ? 1 : undefined, // Needed so that overlaying selected bar is correctly aligned with other bars
    xaxis: {
      title: {
        text: axisType === AxisType.Linear ? 'ARRIVAL TIME [ms]' : 'REFLECTION NR.',
        standoff: 15,
        font: {
          size: 10,
          color: '#ADADAD',
        },
      },
      gridcolor: '#555555',
    },
    yaxis: {
      title: {
        text: `SPL ${
          selectedFrequencyBandIndex !== null ? ` ${AVAILABLE_FREQUENCY_BANDS[selectedFrequencyBandIndex]} Hz` : ''
        } [${getSPLUnit(selectedFrequencyWeighting)}] `,
        standoff: 20,
        font: {
          size: 10,
          color: '#ADADAD',
        },
      },
      range: yAxisRange,
      gridcolor: '#555555',
    },
    showlegend: false,
    title: '',
    plot_bgcolor: 'transparent',
    paper_bgcolor: '#272727',
    font: {
      family: 'Inter, Helvetica, Arial, sans-serif',
      color: '#f5f5f5',
      size: 10,
    },
    margin: {
      l: 40,
      r: 5,
      b: 50,
      t: 20,
      pad: 5,
    },
    autosize: true,
  };

  return config;
};

export const convertToDataCoordinates = (
  clickX: number,
  clickY: number,
  boundingRect: DOMRect,
  xaxisRange: [number, number],
  yaxisRange: [number, number]
) => {
  const clickedX = xaxisRange[0] + (clickX / boundingRect.width) * (xaxisRange[1] - xaxisRange[0]);
  const clickedY =
    yaxisRange[0] + ((boundingRect.height - clickY) / boundingRect.height) * (yaxisRange[1] - yaxisRange[0]);
  return { clickedX, clickedY };
};
