import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CordImage } from './CordImage';

import './style.scss';

/** Images */
import trebleLogoUrl from '@/images/treble-logo.png';

type ErrorPageProps = {
  title?: string;
  header?: string;
  text?: string;
  linkOptions?: {
    href: string;
    text: string;
  } | null;
  logoHref?: string;
  color?: 'red' | 'orange' | 'yellow';
};

export const ErrorPage: FC<ErrorPageProps> = ({
  title = 'Unexpected error',
  header = 'Holy moly!',
  text = "We're sorry but something went wrong.",
  linkOptions = { href: '/', text: 'Go home' },
  logoHref = '/',
  color = 'orange',
}) => {
  const getColor = (color: string) => {
    switch (color) {
      case 'red': {
        return '#FD5B5B';
      }
      case 'orange': {
        return '#FFBB6F';
      }
      case 'yellow': {
        return '#F2D367';
      }
    }
  };

  const illustrationColor = getColor(color);

  return (
    <div className="error-page-container">
      <div className="error-page-image">
        <CordImage fill={illustrationColor} />
      </div>
      <div className="error-page-content">
        <div>
          <p className="eyebrow-text">{title}</p>
        </div>
        <div>
          <h1>{header}</h1>
        </div>
        <div>
          <p className="small-text">{text}</p>
        </div>
        {linkOptions ? (
          <Link className="error-page-button" to={linkOptions.href}>
            {linkOptions.text}
          </Link>
        ) : null}
      </div>
      <div className="treble-logo">
        <a href={logoHref}>
          <img src={trebleLogoUrl} alt="Logo" height={52}></img>
        </a>
      </div>
    </div>
  );
};
