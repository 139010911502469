import { useRouteError } from 'react-router-dom';

import { useBaseContext } from '@/context/BaseContext';

import { ErrorPage } from './Error';

export const ServiceError = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();
  const {
    state: { serviceMessage },
  } = useBaseContext();

  if (error) {
    console.error(error);
  }

  return (
    <ErrorPage
      color="yellow"
      header="We’re oiling gears"
      title="System maintenance"
      linkOptions={null}
      text={serviceMessage?.message}
    />
  );
};
