import { Dispatch, useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { Stack } from '@mui/material';

import { SelectOptionWithGrouping, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblSkeleton } from '@/components/Shared/TrblSkeleton/TrblSkeleton';
import { getAudioSettings } from '@/components/AuralizerStandalone/utils/getAudioSettings';
import { getFirstSimNormMax } from '@/components/AuralizerStandalone/utils/getFirstSimNormMax';
import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { ActionType as PreActionType, usePresetContext } from '../PresetContext';

import { useGetURLsFromSimID } from '../hooks/useGetURLsFromSimId';
import { useSelectSimulationAsAurSim } from '../hooks/useSelectSimulationAsAurSim';
import { useSetupAllAudioNodesForSim } from '../hooks/useSetupAllAudioNodesForSim';
import { useGetModelInformation, useLoadAndExtractFileFromUrl } from '@/hooks';

import { AudioSettings } from '../types';
import { Simulation } from '@/types';

import '../style.scss';

export const SimulationPicker = ({
  originalSim,
  menuItems,
  selectedSim,
  setSelectedSimId,
  taskType,
}: {
  originalSim: Simulation;
  menuItems: SelectOptionWithGrouping[];
  selectedSim: Simulation | undefined;
  setSelectedSimId: Dispatch<string>;
  taskType: string | undefined;
}) => {
  const { selectedAurSim, simsToCompare, availableSimsToCompare, audioNodesDict, simSrcRecHash, recUrls, dispatch } =
    useAuralizerContext();
  const { dispatch: preDispatch } = usePresetContext();

  const { models3d, addModelFromFile } = useModelContext();
  const { performanceMode } = useEditorContext();
  const [newModelId, setNewModelId] = useState<string | null>(null);
  const [availableSimulations, setAvailableSimulations] = useState<Simulation[]>([]);

  const { data: modelInformationData = null } = useGetModelInformation(newModelId);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(modelInformationData?.modelUrl || null, newModelId);
  const { data: urlsForSimId } = useGetURLsFromSimID(selectedSim?.id ?? '', selectedSim?.taskType ?? '');

  const selectSimulationAsAurSim = useSelectSimulationAsAurSim();
  const setupAllAudioNodesForSim = useSetupAllAudioNodesForSim();

  const audioEngine = AudioEngine.getInstance();

  useEffect(() => {
    if (availableSimsToCompare.length > 0) {
      const availableSimulations = availableSimsToCompare.flatMap((simsByModels) => simsByModels.simulations);
      setAvailableSimulations(availableSimulations);
    }
  }, [availableSimsToCompare]);

  // Whenever urlsForSimId have been fetched we can generate the audio settings
  useEffect(() => {
    if (
      urlsForSimId &&
      selectedSim &&
      simSrcRecHash &&
      Object.keys(simSrcRecHash).length > 0 &&
      // make sure that recUrls have not already been set
      // eslint-disable-next-line no-prototype-builtins
      !recUrls?.hasOwnProperty(selectedSim.id)
    ) {
      let newAudioSettings: AudioSettings = {
        ...audioEngine.audioSettings,
        [selectedSim.id]: getAudioSettings(urlsForSimId, simSrcRecHash, selectedSim.id, originalSim.id),
      };

      newAudioSettings = getFirstSimNormMax(newAudioSettings, originalSim.id);
      audioEngine.audioSettings = newAudioSettings;
      dispatch({ type: ActionType.SET_REC_URLS, recUrls: { [selectedSim.id]: urlsForSimId } });
    }
  }, [urlsForSimId, selectedSim]);

  useEffect(() => {
    if (
      !performanceMode &&
      urlsForSimId &&
      selectedSim &&
      simSrcRecHash &&
      // make sure that recUrls have not already been set
      // eslint-disable-next-line no-prototype-builtins
      !recUrls?.hasOwnProperty(selectedSim.id)
    ) {
      setupAllAudioNodesForSim(selectedSim, selectedSim.sources, urlsForSimId);
    }
  }, [urlsForSimId, selectedSim]);

  useEffect(() => {
    if (selectedSim?.id === selectedAurSim?.id) {
      const newSimulation = availableSimulations.find((sim) => sim.id === selectedSim?.id);

      if (newSimulation && !models3d[newSimulation.modelId]) {
        setNewModelId(newSimulation.modelId);
      }
    }
  }, [selectedSim, selectedAurSim]);

  useEffect(() => {
    if (modelFile && newModelId) {
      addModelFromFile(newModelId, modelFile);
    }
  }, [modelFile, newModelId]);

  const selectSimToCompare = async (newValue: string) => {
    const newSimulation = availableSimulations.find((sim) => sim.id === newValue);
    if (newSimulation && originalSim && audioNodesDict) {
      if (audioNodesDict[newSimulation.id] !== undefined) {
        // this simulation has already been connected earlier
        dispatch({
          type: ActionType.SET_FETCHING,
          simId: newSimulation.id,
          isFetching: false,
        });
      }
    }

    let newSimulationsToCompare: Simulation[] = [];
    if (selectedSim?.id !== '' && newSimulation) {
      // if the user is replacing a simulation from the picker
      newSimulationsToCompare = [
        ...simsToCompare.filter((simulation) => simulation.id !== selectedSim?.id),
        newSimulation,
      ];

      // if the old value is the same as the selected auralization simulation
      if (selectedAurSim && selectedSim?.id === selectedAurSim.id) {
        selectSimulationAsAurSim(newSimulation.id);
      }
    } else if (newSimulation) {
      // if it is a new picker value
      newSimulationsToCompare = [...simsToCompare, newSimulation];
    }

    dispatch({
      type: ActionType.SET_SIMS_TO_COMPARE,
      simulations: newSimulationsToCompare,
    });
    preDispatch({
      type: PreActionType.SET_PRESET_EDITED,
      presetEdited: true,
    });

    setSelectedSimId(newValue);
  };

  return (
    <>
      {menuItems.length > 0 ? (
        <Stack flexDirection="row" alignItems="center" gap="8px">
          <TrblSelect
            placeholder="Select simulation"
            value={selectedSim?.id ?? ''}
            setValue={selectSimToCompare}
            menuItems={menuItems}
            hasCategories={true}
          />
          {taskType === 'GA' && <span className="task-type-label">GA</span>}
        </Stack>
      ) : (
        <TrblSkeleton height={32} />
      )}
    </>
  );
};
