import { IconProps } from '@/types';

export const TrblArrowRight = ({ fill = '#DADADA', width = '16', height = '16' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1017 6.88999L8.62826 0.329993C8.38608 0.117218 8.03876 0.065086 7.74293 0.197108C7.4471 0.329129 7.25865 0.620358 7.26256 0.939464V5.2873H2.09999C1.28537 5.2873 0.624988 5.94769 0.624988 6.76231V8.23731C0.624988 9.05193 1.28537 9.71231 2.09999 9.71231H7.26256V14.0605C7.25865 14.3796 7.4471 14.6709 7.74293 14.8029C8.03876 14.9349 8.38608 14.8828 8.62826 14.67L15.1017 8.10947C15.2758 7.95286 15.375 7.73163 15.375 7.49973C15.375 7.26784 15.2758 7.0466 15.1017 6.88999Z"
        fill={fill}
      />
    </g>
  </svg>
);
