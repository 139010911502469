import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { Organization } from './types';

import axios from '@/axios';

const getOrganizations = async () => {
  const { data } = await axios.get<Organization[]>(`/api/Organization/GetAll`);
  return data;
};

export const useGetOrganizations = () => {
  return useQuery<Organization[]>(['organizations'], getOrganizations, {
    refetchOnWindowFocus: false,
    onError: () => toast.error('An error occurred while loading organizations!'),
  });
};
