import { useMemo } from 'react';

import { TIME_OF_ARRIVAL_SCALES } from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/constants';

import { ReflectionDetailsWithRelativeAngles } from '../types';

export const useFilteredReflections = (
  reflectionsData: ReflectionDetailsWithRelativeAngles[],
  selectedScale: string,
  selectedTimeOfArrivalGroupIndexes: number[]
): ReflectionDetailsWithRelativeAngles[] => {
  return useMemo(() => {
    if (reflectionsData.length > 0 && selectedScale) {
      const scale = TIME_OF_ARRIVAL_SCALES.find((scale) => scale.id === selectedScale);
      if (scale && selectedTimeOfArrivalGroupIndexes.length < scale.timeOfArrivalGroups.length) {
        return reflectionsData.filter((reflection) =>
          selectedTimeOfArrivalGroupIndexes.some((index) => {
            const { range } = scale.timeOfArrivalGroups[index];
            return range[0] <= reflection.timeOfArrivalRelative && reflection.timeOfArrivalRelative < range[1];
          })
        );
      }
    }
    return reflectionsData;
  }, [reflectionsData, selectedScale, selectedTimeOfArrivalGroupIndexes]);
};
