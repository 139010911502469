import { FC } from 'react';

import styles from '../styles.module.scss';

type TextAreaProps = {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  label?: string;
  placeholder?: string;
  title?: string;
  value?: string;
  disabled?: boolean;
  tabIndex?: number;
  className?: string | '';
  style?: React.CSSProperties;
};

export const TextArea: FC<TextAreaProps> = ({
  placeholder,
  value,
  label,
  onChange,
  onBlur,
  className,
  autoFocus,
  disabled,
  title,
  tabIndex,
  style,
}) => {
  return (
    <div className={styles['input-div']}>
      {label && <span className={styles['label-top']}>{label}</span>}
      <textarea
        autoFocus={autoFocus}
        className={`${styles['text-area']}  ${label ? styles['has-label-top'] : ''} ${className}`}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange ? (event) => onChange(event.target.value) : undefined}
        onBlur={onBlur ? (event) => onBlur(event.target.value) : undefined}
        disabled={disabled}
        title={title}
        style={style}
        tabIndex={tabIndex}
      />
    </div>
  );
};
