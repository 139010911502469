import { Vector3 } from 'three';

import { useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import {
  isPointCloseToSource,
  isPointInsideInternalVolume,
  isPointInsideModel,
  isPointInValidProximityGridReceiver,
} from '../utils';

import { ValidationError } from '@/context/EditorContext/types';

// setting the point outside of the function and resuing the variable, for speedup
const point3D = new Vector3(0, 0, 0);

export const useGetGridPointValidity = () => {
  const { meshes, outerMeshes, innerMeshes } = useModelContext();
  const { sources } = useEditorContext();

  const getGridPointValidity = async (x: number, y: number, z: number) => {
    point3D.set(x, y, z);
    if (outerMeshes?.length && !isPointInsideModel(point3D, outerMeshes)) {
      return ValidationError.NotInsideModel;
    } else if (isPointCloseToSource(point3D, sources)) {
      return ValidationError.CloseToSource;
    } else if (innerMeshes?.length && isPointInsideInternalVolume(point3D, innerMeshes)) {
      return ValidationError.InsideInnerVolume;
    } else if (meshes?.length) {
      const isPointInValidProximity = await isPointInValidProximityGridReceiver(point3D, meshes);
      if (!isPointInValidProximity) {
        return ValidationError.CloseToSurface;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return getGridPointValidity;
};
