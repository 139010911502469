import { FC } from 'react';

import { Text } from '@/components/Shared/Text';

import classes from './styles.module.scss';

type EmailTagProps = {
  index: number;
  email: string;
  removeEmail: (index: number) => void;
};

export const EmailTag: FC<EmailTagProps> = ({ index, email, removeEmail }) => {
  return (
    <div className={classes['email-tag']} title={email}>
      <Text type="medium-10px" color="#171717" className={classes['email']}>
        {email}
      </Text>
      <button type="button" className={classes['remove-button']} onClick={() => removeEmail(index)}>
        ×
      </button>
    </div>
  );
};
