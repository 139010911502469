import { useQuery } from '@tanstack/react-query';

import { SpaceSimulationsDto } from '../types';

import axios from '@/axios';

const getAllSimulationsForSpaces = async (): Promise<SpaceSimulationsDto[]> => {
  const { data } = await axios.get(`/api/Simulation/GetAllSimulationsForSpaces`);

  return data;
};

export const useGetAllSimulationsForSpaces = (enabled = true) => {
  const query = useQuery<SpaceSimulationsDto[]>(['all-simulations-for-space'], () => getAllSimulationsForSpaces(), {
    refetchOnWindowFocus: false,
    enabled,
  });

  return query;
};
