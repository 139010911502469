import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { Box3, Object3D, Vector3 } from 'three';

import { GeometryInfo, GeometryWarningsSelected } from '@/components/MultiSpaceImport/types';

import { createLayerGroupsFromModel, getModelBoundingBox, parseModelAsObject3D } from '../ModelContext/utils';

import { ModelFile, ModelLayerGroup } from '../ModelContext/types';

enum GeometryImportActionType {
  ADD_MODEL = 'ADD_MODEL',
  SET_CURRENT_MODEL_ID = 'SET_CURRENT_MODEL_ID',
  ADD_GEOMETRY_INFO = 'ADD_GEOMETRY_INFO',

  SET_GEOMETRY_WARNINGS_SELECTED = 'SET_GEOMETRY_WARNINGS_SELECTED',
}

type GeoometryImportAction =
  | { type: GeometryImportActionType.ADD_MODEL; modelId: string; model: Object3D }
  | { type: GeometryImportActionType.ADD_GEOMETRY_INFO; modelId: string; geometryInfo: GeometryInfo }
  | {
      type: GeometryImportActionType.SET_GEOMETRY_WARNINGS_SELECTED;
      index: number | null;
      warningType: string;
    }
  | { type: GeometryImportActionType.SET_CURRENT_MODEL_ID; modelId: string };

type GeometryImportState = {
  currentModelId: string | null;
  modelsLayerGroups: Record<string, Array<ModelLayerGroup>>;
  modelsGeometryInfo: Record<string, GeometryInfo>;
  modelsGeometryWarningsSelected: GeometryWarningsSelected | null;
  modelCenter: Vector3 | null;
  modelBoundingBox: Box3 | null;
  // Derived properties
  currentModelLayerGroups: Array<ModelLayerGroup> | null;
  currentModelGeometryInfo: GeometryInfo | null;
  warningSelected: number | null;
  isGeometryInfoLoaded: boolean;
  isModelLoaded: boolean;

  dispatch: React.Dispatch<GeoometryImportAction>;
  addModelFromFile: (modelId: string, modelFile: ModelFile, onSuccess?: () => void) => Promise<void>;
};

const initialState: GeometryImportState = {
  currentModelId: null,
  modelsLayerGroups: {},
  modelsGeometryInfo: {},
  modelsGeometryWarningsSelected: null,
  modelCenter: null,
  modelBoundingBox: null,
  // Derived properties
  currentModelLayerGroups: null,
  currentModelGeometryInfo: null,
  warningSelected: null,
  isGeometryInfoLoaded: false,
  isModelLoaded: false,

  dispatch: () => null,
  addModelFromFile: async () => undefined,
};

const GeometryImportContext = createContext<GeometryImportState | undefined>(undefined);

const reducer = (state: GeometryImportState, action: GeoometryImportAction): GeometryImportState => {
  switch (action.type) {
    case GeometryImportActionType.ADD_MODEL: {
      const layerGroups = createLayerGroupsFromModel(action.model);

      return {
        ...state,
        modelsLayerGroups: {
          ...state.modelsLayerGroups,
          [action.modelId]: layerGroups,
        },
      };
    }
    case GeometryImportActionType.ADD_GEOMETRY_INFO: {
      return {
        ...state,
        modelsGeometryInfo: {
          ...state.modelsGeometryInfo,
          [action.modelId]: action.geometryInfo,
        },
      };
    }

    case GeometryImportActionType.SET_CURRENT_MODEL_ID: {
      return {
        ...state,
        currentModelId: action.modelId,
      };
    }
    case GeometryImportActionType.SET_GEOMETRY_WARNINGS_SELECTED: {
      return {
        ...state,
        modelsGeometryWarningsSelected: {
          index: action.index,
          warningType: action.warningType,
        },
      };
    }
  }
};

type GeometryImportProviderProps = {
  children: ReactNode;
};

const GeometryImportProvider: React.FC<GeometryImportProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addModelFromFile = async (modelId: string, modelFile: ModelFile, onSuccess?: () => void) => {
    if (modelFile.fileData.byteLength !== 0) {
      try {
        const model = await parseModelAsObject3D(modelFile);

        dispatch({
          type: GeometryImportActionType.ADD_MODEL,
          modelId: modelId,
          model,
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        toast.error('Failed to parse model');
      }
    }
  };

  // Memoize the value object
  const value = useMemo(() => {
    const currentModelLayerGroups =
      state.currentModelId && state.currentModelId in state.modelsLayerGroups
        ? state.modelsLayerGroups[state.currentModelId]
        : null;
    const { modelBoundingBox, modelCenter } = currentModelLayerGroups
      ? getModelBoundingBox(currentModelLayerGroups[0].children)
      : { modelBoundingBox: null, modelCenter: null };

    return {
      ...state,
      currentModelLayerGroups: currentModelLayerGroups,
      modelBoundingBox: modelBoundingBox,
      modelCenter: modelCenter,
      currentModelGeometryInfo:
        state.currentModelId && state.currentModelId in state.modelsGeometryInfo
          ? state.modelsGeometryInfo[state.currentModelId]
          : null,
      warningSelected:
        state.currentModelId && state.modelsGeometryWarningsSelected
          ? state.modelsGeometryWarningsSelected.index
          : null,
      isModelLoaded: !!(state.currentModelId && state.currentModelId in state.modelsLayerGroups),
      isGeometryInfoLoaded: !!(state.currentModelId && state.currentModelId in state.modelsGeometryInfo),
      dispatch,
      addModelFromFile,
    };
  }, [state]);

  return <GeometryImportContext.Provider value={value}>{children}</GeometryImportContext.Provider>;
};

const useGeometryImportContext = () => {
  const context = useContext(GeometryImportContext);
  if (!context) {
    throw new Error('useGeometryImportContext must be used within GeometryImportProvider');
  }
  return context;
};

export { GeometryImportActionType, GeometryImportProvider, useGeometryImportContext };
