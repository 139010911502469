import { MouseEventHandler, useEffect } from 'react';

import { ActionType as ResultsActionType, useResultsContext } from '../../../context/ResultsContext';

import { ResultComparisonState } from '../../../types';

type UseUpdateResultContextProps = {
  index: number;
  color: string;
  state: ResultComparisonState;
  onFormChange: () => void;
};

export const useUpdateResultContext = ({ index, color, state, onFormChange }: UseUpdateResultContextProps) => {
  const { dispatch: resultsDispatch } = useResultsContext();

  // Handle removing the comparison
  const handleRemoveComparison: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    resultsDispatch({ type: ResultsActionType.REMOVE_COMPARISON, color });
    onFormChange();
  };

  // Handle selecting the comparison
  const handleSelectComparison = () => {
    resultsDispatch({ type: ResultsActionType.SET_SELECTED_COMPARISON_INDEX, selectedComparisonIndex: index });
  };

  useEffect(() => {
    // When the simulation data gets updated we want to let the parent know so the next child can
    // copy the same state. Also we use this to let the plots know if the title changed.
    if (state.simulationData !== null) {
      resultsDispatch({
        type: ResultsActionType.UPDATE_COMPARISON,
        update: { color: state.color ?? color, formState: state },
      });
    }
  }, [state.simulationData, state.sourceSummingSelectionEnabled, state.selectedSourcesForSumming, state.title]);

  return { handleRemoveComparison, handleSelectComparison };
};
