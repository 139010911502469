import { GroupMaterialAfterCategory } from '../LibraryPanel/types';
import { Material } from '@/types';

export const getGroupsByCategories = (
  materials: Material[],
  materialGroupsToUse: string[],
  userId: string | undefined,
  categories?: string[]
) => {
  const newfilteredMaterials = materials.filter((material) => material.name !== 'unassigned');
  const materialGroups: string[] = categories ?? materialGroupsToUse;
  const defaultFilter = materialGroups.includes('Default');
  const userCreatedFilter = materialGroups.includes('Created by me');
  const sharedWithOrganizationFilter = materialGroups.includes('Organization');

  const shouldBeAdded = (category: string) =>
    (restOfFilters.length > 0 && restOfFilters.includes(category)) || restOfFilters.length === 0;

  const restOfFilters = materialGroups.filter(
    (group) => group !== 'Default' && group !== 'Created by me' && group !== 'Organization'
  );

  const groupAfterCategory = newfilteredMaterials.reduce((groups: GroupMaterialAfterCategory, curr) => {
    // a flag to prevent us adding the material twice to the grouping
    let hasBeenAdded = false;

    // 1. check if Default filter is selected - default is neither created by the user or shared with the organization
    if (defaultFilter && !curr.isUserCreated && !curr.isSharedWithOrganization && shouldBeAdded(curr.category)) {
      groups[curr.category] = addToGroup(groups, curr);
      hasBeenAdded = true;
    }

    // 2. check if Created by me filter is selected
    if (
      !hasBeenAdded &&
      userCreatedFilter &&
      curr.isUserCreated &&
      userId === curr.userId &&
      shouldBeAdded(curr.category)
    ) {
      groups[curr.category] = addToGroup(groups, curr);
      hasBeenAdded = true;
    }

    // 3. check if Organization filter is selected
    if (
      !hasBeenAdded &&
      sharedWithOrganizationFilter &&
      curr.isSharedWithOrganization &&
      shouldBeAdded(curr.category)
    ) {
      groups[curr.category] = addToGroup(groups, curr);
      hasBeenAdded = true;
    }

    // 4. check the other filters
    if (
      !defaultFilter &&
      !userCreatedFilter &&
      !sharedWithOrganizationFilter &&
      !hasBeenAdded &&
      restOfFilters.length > 0 &&
      restOfFilters.includes(curr.category)
    ) {
      groups[curr.category] = addToGroup(groups, curr);
    }

    // 5. if no filters are selected add all materials to the list
    if (!defaultFilter && !userCreatedFilter && !sharedWithOrganizationFilter && restOfFilters.length === 0) {
      groups[curr.category] = addToGroup(groups, curr);
    }

    return groups;
  }, {});

  return groupAfterCategory;
};

const addToGroup = (groups: GroupMaterialAfterCategory, curr: Material) => {
  const group: Material[] = groups[curr.category] || [];
  group.push(curr);
  return group;
};
