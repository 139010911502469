import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblFilterIcon: FC<IconProps> = ({ fill = '#999999' }) => (
  <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.9735 1.08868C5.93112 1.0041 5.84461 0.950687 5.75 0.950684H0.250001C0.155308 0.950684 0.0687426 1.00418 0.0263946 1.08888C-0.0159534 1.17358 -0.00681437 1.27493 0.0500014 1.35068L2.25 4.30643V6.45068C2.25204 6.72598 2.47471 6.94864 2.75 6.95068C2.85821 6.95081 2.96351 6.91571 3.05 6.85068L3.55 6.47568C3.67569 6.38108 3.74973 6.233 3.75 6.07568V4.30643L5.95 1.35068C6.00686 1.27486 6.01596 1.17341 5.9735 1.08868Z"
      fill={fill}
    />
  </svg>
);
