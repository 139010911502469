import { TrblTooltip } from '@/components/Shared';
import { PresetsActionMenu } from '@/components/AuralizerPresets/PresetsActionMenu/PresetsActionMenu';
import { TrblChevronDownIcon, TrblCloseIcon } from '../../../Icons';
import { TrblIconButton } from '../../../Shared/Buttons';

import { useCloseAuralizer } from '../../hooks/useCloseAuralizer';

import '../../style.scss';

export const HeaderActionsRight = ({
  isMinimized,
  setIsMinimized,
}: {
  isMinimized: boolean;
  setIsMinimized: (value: boolean) => void;
}) => {
  const closeAuralizer = useCloseAuralizer();

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className="right-content">
      <PresetsActionMenu />
      <TrblIconButton
        className="minimize"
        icon={<TrblChevronDownIcon fill="#DADADA" />}
        label="Minimize"
        edge="start"
        onClick={toggleMinimize}></TrblIconButton>

      <TrblTooltip title="Exit Auralizer">
        <span>
          <TrblIconButton
            className="close-auralizer"
            icon={<TrblCloseIcon />}
            label="Exit Auralizer"
            edge="start"
            onClick={closeAuralizer}></TrblIconButton>
        </span>
      </TrblTooltip>
    </div>
  );
};
