import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import { BaseProvider } from '@/context/BaseContext';

import { TrblToast } from '@/components/Shared';
import { Support } from '@/components/Support';
import { ServiceError } from '../Error';
import { LoginPage } from '../LoginPage';

import { ServiceStatus, useGetServiceMessage, useGetUserMine } from '@/hooks';

import { configureAxiosRequestInterceptor, configureAxiosResponseInterceptor } from './utils';

export const BaseWrapper = () => {
  const [isAxiosConfigured, setIsAxiosConfigured] = useState(false);

  const { isAuthenticated, isLoading: isLoadingAuth, getAccessTokenSilently, logout } = useAuth0();

  const { data: serviceMessage } = useGetServiceMessage();

  // Do not call user mine endpoint if the service is down
  const { data: user } = useGetUserMine(
    isAuthenticated && serviceMessage !== undefined && serviceMessage?.status !== ServiceStatus.offline
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    configureAxiosRequestInterceptor(getAccessTokenSilently, logout);
  }, []);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
      });

      configureAxiosResponseInterceptor(logout, user);
      setIsAxiosConfigured(true);
    }
  }, [user]);

  useEffect(() => {
    if (serviceMessage) {
      if (serviceMessage?.status === ServiceStatus.offline) {
        navigate('/offline');
      } else if (location.pathname === '/offline') {
        navigate('/');
      }
    }
  }, [serviceMessage]);

  // We wait for the user to be authenticated and if he is, also wait for the user data to be fetched and axios configured
  const isLoading = isLoadingAuth || (!isAxiosConfigured && isAuthenticated);

  return (
    <main>
      {!isLoading ? (
        <>
          {isAuthenticated ? (
            <BaseProvider serviceMessage={serviceMessage}>
              <Outlet />
              <Support />
              <TrblToast />
            </BaseProvider>
          ) : (
            <LoginPage />
          )}
        </>
      ) : serviceMessage?.status === ServiceStatus.offline ? (
        <BaseProvider serviceMessage={serviceMessage}>
          <ServiceError />
        </BaseProvider>
      ) : (
        <div></div>
      )}
    </main>
  );
};
