import { FC, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { useAppContext } from '@/context/AppContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { ScatterControls } from '../ScatterControls';

import { useUpdateScatter } from '../hooks/useUpdateScatter';

import { getScatterText } from './utils';

import { MaterialLayer } from '@/types';

export const ScatterCell = ({
  layerName,
  layer,
  layerChildren,
  updateLayerChildren,
}: {
  layerName: string;
  layer: MaterialLayer;
  layerChildren?: MaterialLayer[];
  updateLayerChildren: (layer: MaterialLayer) => void;
}) => {
  const scatterContainerRef = useRef<HTMLDivElement>(null);
  const [scatterValues, setScatterValues] = useState<number[] | null>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const isLayerAssigned = layer.materialId !== EMPTY_GUID;
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();

  const { dispatch: editorDispatch, readonly } = useEditorContext();

  useEffect(() => {
    setScatterValues(layer.scatter);
  }, [layer]);

  const updateScatter = useUpdateScatter();

  useEffect(() => {
    if (layerChildren && layerChildren.length > 1) {
      let areSame = true;
      layerChildren.reduce((childA: MaterialLayer, childB: MaterialLayer) => {
        const sameScatter = isEqual(childA.scatter, childB.scatter);
        if (areSame && !sameScatter) {
          areSame = false;
        }
        return childB;
      });
      // if all child layers are the same then the parent
      // layer should have the same scatter value
      if (areSame) {
        setScatterValues(layerChildren[0].scatter);
      } else {
        setScatterValues(null);
      }
    }
  }, [layerChildren]);

  const handleUpdateScatterValues = (values: number[]) => {
    // If no changes were made then we don't need to do anything
    if (!isEqual(values, scatterValues)) {
      const newLayer = { ...layer };
      if (selectedSimulation) {
        updateScatter(selectedSimulation, layer.id, values);

        // if is layer then also update the child rows in the table
        if (layer.children.length) {
          for (const child of newLayer.children) {
            child.scatter = values;
          }
        } else {
          newLayer.scatter = values;
        }
        updateLayerChildren(newLayer);
        setScatterValues(values);
      }
    }
  };

  const handleInputOnClick = () => {
    // To prevent a race condition with the "handleScatterControlClosed" where you have one input active and the click another one before the first one has closed
    setTimeout(() => {
      setIsActive(true);
    }, 0);
  };

  const handleScatterControlClosed = () => {
    setIsActive(false);
  };

  useEffect(() => {
    editorDispatch({
      type: ActionType.SET_IS_POPUP_OPEN,
      isOpen: isActive,
    });
  }, [isActive]);

  const material = filteredMaterials.find((material) => material.id === layer.materialId);

  const scatterValueText = getScatterText(scatterValues);

  return (
    <div
      onClick={isLayerAssigned && !readonly ? handleInputOnClick : undefined}
      className={`treble-layers-cell scatter-cell`}
      role="gridcell"
      aria-label="scatter"
      ref={scatterContainerRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      {/* Only render scatter input if layer has a material assigned */}
      {isLayerAssigned &&
        (!isActive ? (
          <ScatterValue isHovering={isHovering} defaultValue={scatterValueText} readonly={readonly} />
        ) : (
          <ScatterControls
            parentRef={scatterContainerRef}
            scatterValues={scatterValues}
            surfaceName={layerChildren?.length ? layerName : `${layerName} ${layer.textName}`}
            materialName={layer.materialName ?? ''}
            defaultScattering={material?.defaultScattering ?? 0.1}
            hasChildLayers={layerChildren ? layerChildren.length > 1 : false}
            onUpdate={handleUpdateScatterValues}
            onClose={handleScatterControlClosed}
          />
        ))}
    </div>
  );
};

type HoverInputProps = {
  isHovering: boolean;
  defaultValue: string;
  readonly: boolean;
};

const ScatterValue: FC<HoverInputProps> = ({ defaultValue, isHovering, readonly }) => {
  return isHovering && !readonly ? (
    <input defaultValue={defaultValue} className="scatter-input scatter-text" />
  ) : (
    <span className="scatter-text">{defaultValue}</span>
  );
};
