import { useMutation } from '@tanstack/react-query';

import { Model } from '@/types';

import axios from '@/axios';

type CreateModelRequest = {
  modelBaseId: string;
  modelName: string;
  externalId: string;
  fileUploadId: number | string;
  sourceModelSha256: string;
  application: string;
  applicationVersion: string;
  applicationPluginVersion?: string;
  thumbnailUploadId?: string;
  sourceModelUploadId?: string;
  nonWaterTight?: boolean;
  fileType?: string;
};

const createModel = async (request: CreateModelRequest) => {
  const { data } = await axios.post<Model>(`/api/Model/CreateModel`, null, {
    params: {
      ...request,
    },
  });
  return data;
};

export const useCreateModel = () => {
  return useMutation(async (model: CreateModelRequest) => await createModel(model));
};
