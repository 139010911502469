import { ReactNode, useEffect, useRef, useState } from 'react';

import { Popper } from '@mui/material';

import { TrblNotebookIcon } from '@/components/Icons/TrblNotebookIcon';

import styles from './styles.module.scss';

type CollapsibleInfoProps = {
  children: ReactNode;
  closeCollapsibleLabel?: string;
  isOpen?: boolean;
  small?: boolean;
  top?: boolean;
};

export const CollapsibleInfo = ({
  children,
  closeCollapsibleLabel = 'Hide description',
  small,
  top,
  isOpen = true,
}: CollapsibleInfoProps) => {
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openForTheFirstTime, setOpenForTheFirstTime] = useState(isOpen);

  useEffect(() => {
    if (buttonRef && openForTheFirstTime) {
      setAnchorEl(buttonRef.current);
      setOpenForTheFirstTime(false);
    }
  }, [buttonRef]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl) || openForTheFirstTime;
  const id = open ? 'description-popover' : undefined;

  return (
    <>
      <button
        ref={buttonRef}
        data-dd-action-name="Open preset description"
        className={`${styles.collapsible_button} ${open ? styles.open : ''} ${small ? styles.small : ''} `}
        onClick={handleClick}
        aria-describedby={id}>
        <TrblNotebookIcon />
      </button>
      <Popper
        id={id}
        open={open}
        placement={top ? 'top-start' : 'bottom'}
        anchorEl={anchorEl}
        nonce={undefined}
        onResize={undefined}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              padding: 2,
            },
          },
        ]}
        onResizeCapture={undefined}>
        <div className={`${styles.collapsible_info} ${top ? styles.top : styles.bottom}`}>
          <div className={styles.close_collapsible}>
            <button
              onClick={handleClick}
              data-dd-action-name="Close preset description"
              aria-label={closeCollapsibleLabel}>
              {closeCollapsibleLabel}
            </button>
          </div>
          <div className={`${styles.collapsible_content} `}>{children}</div>
        </div>
      </Popper>
    </>
  );
};
