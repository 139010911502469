export const TrblMagnifyZoomOutIcon = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1495 10.7958C10.6903 9.71599 11.8746 6.78093 10.7948 4.24015C9.71502 1.69937 6.77995 0.515022 4.23918 1.59483C1.6984 2.67463 0.514046 5.60969 1.59385 8.15047C2.67366 10.6912 5.60872 11.8756 8.1495 10.7958Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.1943 10.1953L12.8048 12.8063" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.69434 6.19531C8.69434 5.91917 8.47048 5.69531 8.19434 5.69531C5.11017 5.69531 7.2785 5.69531 4.19434 5.69531C3.91819 5.69531 3.69434 5.91917 3.69434 6.19531C3.69434 6.47145 3.91819 6.69531 4.19434 6.69531C5.52767 6.69531 6.861 6.69531 8.19434 6.69531C8.47048 6.69531 8.69434 6.47145 8.69434 6.19531Z"
      fill={fill}
    />
  </svg>
);
