import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

import { ReceiverReflections } from '../types';

const getReflectionsJson = async (url: string): Promise<ReceiverReflections> => {
  const response = await axios.get<ReceiverReflections>(url);
  return response.data;
};

export const useGetReflectionDataByDownloadUrl = (
  downloadUrl: string | null,
  uploadId: number | null
): UseQueryResult<ReceiverReflections, Error> => {
  return useQuery<ReceiverReflections, Error>(['reflectionsData', uploadId], () => getReflectionsJson(downloadUrl!), {
    enabled: !!(downloadUrl && uploadId),
  });
};
