import { useEffect, useState } from 'react';

import { LibraryFilter, LibraryHeader, LibraryList } from '@/components/LibraryPanel';
import { SourceDefinitionRow } from './SourceDefinitionRow';

import { SourceDefinition } from '@/types';

export const SourceDefinitionList = ({
  availableSourceDefinitionList,
  sourceDefinitionManufacturers,
  selectedSourceDefinitionManufacturers,
  onSelectSourceDefinitionManufacturers,
  sourceDefinitionSubCategories,
  selectedSourceDefinitionSubCategories,
  onSelectSourceDefinitionSubCategories,
  inPopup,
}: {
  availableSourceDefinitionList: SourceDefinition[];
  sourceDefinitionManufacturers: string[];
  selectedSourceDefinitionManufacturers: string[];
  onSelectSourceDefinitionManufacturers: (values: string[]) => void;
  sourceDefinitionSubCategories: string[];
  selectedSourceDefinitionSubCategories: string[];
  onSelectSourceDefinitionSubCategories: (values: string[]) => void;
  inPopup?: boolean;
}) => {
  const [manufacturersOptions, setManufacturersOptions] = useState<{ label: string; value: string }[]>([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const manufacturerOptions = sourceDefinitionManufacturers.map((ele) => ({ label: ele, value: ele }));
    setManufacturersOptions(manufacturerOptions);
  }, [sourceDefinitionManufacturers]);

  useEffect(() => {
    const subCategoriesOptions = sourceDefinitionSubCategories.map((ele) => ({ label: ele, value: ele }));
    setSubCategoriesOptions(subCategoriesOptions);
  }, [sourceDefinitionSubCategories]);

  return (
    <>
      <LibraryHeader>
        <div style={{ width: '129px', display: 'flex' }}>
          <p>Manufacturer</p>
          <LibraryFilter
            title="Filter by manufacturer"
            selectItems={onSelectSourceDefinitionManufacturers}
            selectedItems={selectedSourceDefinitionManufacturers}
            itemOptions={manufacturersOptions}></LibraryFilter>
        </div>
        <p style={{ width: '114px' }}>Name</p>
        <div style={{ width: '120px', display: 'flex' }}>
          <p>Category</p>
          <LibraryFilter
            title="Filter by category"
            selectItems={onSelectSourceDefinitionSubCategories}
            selectedItems={selectedSourceDefinitionSubCategories}
            itemOptions={subCategoriesOptions}></LibraryFilter>
        </div>
      </LibraryHeader>
      <LibraryList>
        {availableSourceDefinitionList.map((sourceDefinition, index: number) => (
          <SourceDefinitionRow
            key={sourceDefinition.id}
            sourceDefinition={sourceDefinition}
            index={index}
            inPopup={inPopup}
          />
        ))}
      </LibraryList>
    </>
  );
};
