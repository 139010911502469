import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblTimeDelayIcon: FC<IconProps> = ({ fill = '#DADADA', fillExtra = 'none' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76367 4.01232C9.7191 4.05504 9.7146 4.12292 9.75317 4.17072C11.0743 5.8072 11.0831 8.09221 9.77475 9.73808C8.46635 11.384 6.16818 11.9788 4.1753 11.1873C2.18242 10.3959 0.993628 8.41625 1.27883 6.36402C1.56404 4.3118 3.25185 2.70061 5.39116 2.4384C5.45362 2.43088 5.5004 2.37979 5.50016 2.31936V1.08C5.50016 1.01373 5.4442 0.96 5.37516 0.96H4.50016C4.22402 0.96 4.00016 0.745097 4.00016 0.48C4.00016 0.214903 4.22402 0 4.50016 0H7.50017C7.77631 0 8.00017 0.214903 8.00017 0.48C8.00017 0.745097 7.77631 0.96 7.50017 0.96H6.62516C6.59193 0.96 6.56006 0.972709 6.5366 0.995317C6.51314 1.01793 6.50003 1.04857 6.50016 1.08048V2.31936C6.50015 2.37971 6.54682 2.43068 6.60916 2.4384C7.43971 2.54153 8.22676 2.85479 8.88967 3.34608C8.93937 3.38282 9.00973 3.37851 9.05417 3.336L9.88216 2.54112C10.0077 2.41634 10.1936 2.3663 10.3684 2.41023C10.5432 2.45415 10.6798 2.58521 10.7255 2.75304C10.7713 2.92086 10.7191 3.09932 10.5892 3.21984L9.76367 4.01232ZM2.25016 6.96C2.25016 8.94823 3.9291 10.56 6.00016 10.56C8.07032 10.5579 9.74796 8.94735 9.75016 6.96C9.75016 4.97177 8.07123 3.36 6.00016 3.36C3.9291 3.36 2.25016 4.97177 2.25016 6.96Z"
      fill={fill}
    />
    <path
      d="M5.99995 9.39173C4.60093 9.39173 3.4668 8.30297 3.4668 6.9599C3.4668 5.61684 4.60093 4.52808 5.99995 4.52808C7.39897 4.52808 8.53311 5.61684 8.53311 6.9599C8.53162 8.30237 7.39836 9.3903 5.99995 9.39173Z"
      fill={fillExtra}
    />
  </svg>
);
