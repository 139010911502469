import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { KeyboardArrowRightSharp } from '@mui/icons-material';

import { useBaseContext } from '@/context/BaseContext';

import { config } from '@/__config__/config';

const { viteAuth0LogoutUri } = config;

import styles from './styles.module.scss';

export const SubscriptionExpiredPage = () => {
  const [userType, setUserType] = useState<string>('regular');
  const {
    state: { userInfo },
  } = useBaseContext();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.roles.includes('Admin')) {
      setUserType('admin');
    }
  }, [userInfo]);

  return (
    <div className={styles['information-page']}>
      <button className={styles['logout-button']} onClick={() => logout({ returnTo: viteAuth0LogoutUri })}>
        Logout
      </button>
      <div className={`${styles['hero-image']} ${styles['hero-image-large']}`}>
        <div className={`${styles['information-page-header']} ${styles['no-subscriptions']}`}>
          <h1> Your subscription has expired! </h1>
          <p>
            {`It looks like your subscription has expired. ${
              userType === 'regular'
                ? `If you'd like to keep using your Treble account, please contact the admin of your organization.`
                : `To manage your subscription, please go to the administration
            page.`
            }`}
          </p>
          {userType === 'admin' && (
            <button className={styles['administration-button']} onClick={() => navigate('/administration')}>
              Go to administration <KeyboardArrowRightSharp />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
