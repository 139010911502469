import { IconProps } from '@/types';

export const TrblSharedProjectIcon = ({ width = '16', height = '16', fill = '#ADADAD' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8776 6.02066C13.2518 5.08513 12.3621 4.55127 11.4287 4.55127C10.4952 4.55127 9.60547 5.08513 8.97968 6.02066"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4288 3.57154C12.1051 3.57154 12.6533 3.02332 12.6533 2.34705C12.6533 1.67078 12.1051 1.12256 11.4288 1.12256C10.7526 1.12256 10.2043 1.67078 10.2043 2.34705C10.2043 3.02332 10.7526 3.57154 11.4288 3.57154Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8776 14.0207C13.2518 13.0851 12.3621 12.5513 11.4287 12.5513C10.4952 12.5513 9.60547 13.0851 8.97968 14.0207"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4288 11.5715C12.1051 11.5715 12.6533 11.0233 12.6533 10.347C12.6533 9.67078 12.1051 9.12256 11.4288 9.12256C10.7526 9.12256 10.2043 9.67078 10.2043 10.347C10.2043 11.0233 10.7526 11.5715 11.4288 11.5715Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.77146 10.1027C6.93706 8.85537 5.7507 8.14355 4.50615 8.14355C3.2616 8.14355 2.07524 8.85537 1.24084 10.1027"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50631 6.83708C5.408 6.83708 6.13896 6.10612 6.13896 5.20443C6.13896 4.30274 5.408 3.57178 4.50631 3.57178C3.60462 3.57178 2.87366 4.30274 2.87366 5.20443C2.87366 6.10612 3.60462 6.83708 4.50631 6.83708Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
