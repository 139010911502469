import React, { FC, useEffect, useRef, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';

import { ClickAwayListener, Popper } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '@/components/Shared/Text';
import { TrblIcon } from '@/components/Icons';
import { frequencyBands } from '@/components/SourceRecieverSettings/SourceSettings/utils';

import styles from './styles.module.scss';

type ScatterControlsProps = {
  parentRef: React.RefObject<HTMLDivElement>;
  scatterValues: number[] | null;
  hasChildLayers: boolean;
  surfaceName: string;
  materialName: string;
  defaultScattering: number;
  onUpdate: (values: number[]) => void;
  onClose: () => void;
};

export const ScatterControls: FC<ScatterControlsProps> = ({
  parentRef,
  scatterValues,
  surfaceName,
  materialName,
  hasChildLayers,
  defaultScattering,
  onUpdate,
  onClose,
}) => {
  const { readonly } = useEditorContext();
  const [isMultipleScatter, setIsMultipleScatter] = useState<boolean>(
    scatterValues !== null && scatterValues !== undefined && scatterValues.length > 1
  );

  // For storing the single value while making changes (before closing the control again)
  const [singleScatterValue, setSingleScatterValue] = useState<number | undefined>(
    !isMultipleScatter ? scatterValues?.[0] : undefined
  );

  // For storing the multiple values while making changes (before closing the control again)
  const [multipleScatterValues, setMultipleScatterValues] = useState<(number | undefined)[] | undefined>(
    isMultipleScatter && scatterValues && scatterValues.length === 8 ? scatterValues : undefined
  );

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClickAway = () => {
    saveValues(singleScatterValue, multipleScatterValues);
    onClose();
  };

  const numberOfInputs = isMultipleScatter ? 8 : 1;

  // Holds the reference to the first input, for setting the focus when opening the controls or switching between scattering per octave band or single value
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [isMultipleScatter]);

  const handleInputChange = (value: number | undefined, index?: number) => {
    if (isMultipleScatter && index !== undefined) {
      const newValues = [...(multipleScatterValues || [])];

      newValues[index] = value;

      setMultipleScatterValues(newValues);
    } else {
      setSingleScatterValue(value);
    }
  };

  // Only allow empty value if the layer was already empty, it has child layers, and it's not multiple scatter
  const allowEmpty = hasChildLayers && !isMultipleScatter && scatterValues === null;

  const handleInputBlur = (value: number | undefined, index?: number) => {
    if (isMultipleScatter && index !== undefined) {
      const newValues = [...(multipleScatterValues || [])];

      newValues[index] = value ?? 0;

      setMultipleScatterValues(newValues);
    } else if (singleScatterValue === undefined && !allowEmpty) {
      setSingleScatterValue(value ?? 0);
    }
  };

  const saveValues = (
    singleScatterValue: number | undefined,
    multipleScatterValues: (number | undefined)[] | undefined
  ) => {
    // If we are allowing empty values to be saved we know that it was empty before as well, so no need to trigger the save
    if (isMultipleScatter && multipleScatterValues) {
      const newValues: number[] = multipleScatterValues.map((value, i) => {
        if (value === undefined) {
          return scatterValues?.[i] ?? 0;
        } else {
          return value;
        }
      });

      onUpdate(newValues);
    } else if (!(allowEmpty && singleScatterValue === undefined)) {
      onUpdate([singleScatterValue ?? 0]);
    }
  };

  const handleControlClicks = (event: React.MouseEvent<HTMLElement>) => {
    // Avoid clicks to propagate outside the controls container
    event.stopPropagation();
  };

  /** If switching from a multiple scatter to a single value then we simply ignore the previous values
   * and assign the default scattering of the material to the input
   */
  const handleSingleScatterClick = () => {
    if (singleScatterValue === undefined) {
      setSingleScatterValue(defaultScattering);
    }
    setIsMultipleScatter(false);
  };

  /** If switching from a single scatter to a multiple scatter value then we take the previous value
   * (or the default scattering of the material if there is no value) and assign that value to every octave band input
   */
  const handleMultipleScatterClick = () => {
    if (!multipleScatterValues) {
      setMultipleScatterValues([...Array(8)].map(() => singleScatterValue ?? defaultScattering));
    }

    setIsMultipleScatter(true);
  };

  const handleContainerKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      onClose();
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, value?: number, index?: number) => {
    if (event.key === 'Enter') {
      event.stopPropagation();

      // If user presses enter we want to save the current (validated) value and close the controls
      let newMultipleScatterValues = multipleScatterValues;
      let newSingleScatterValue = singleScatterValue;
      if (isMultipleScatter && index !== undefined) {
        const newValues = [...(multipleScatterValues || [])];

        newValues[index] = value;

        newMultipleScatterValues = newValues;
      } else {
        newSingleScatterValue = value;
      }

      saveValues(newSingleScatterValue, newMultipleScatterValues);
      onClose();
    }
  };

  return (
    <ClickAwayListener disableReactTree onClickAway={handleClickAway}>
      <Popper
        id="scatter-popover"
        open={true}
        anchorEl={parentRef.current}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}>
        <div
          className={`${styles['controls-container']} ${isMultipleScatter ? styles['multiple-scatter'] : ''}`}
          onKeyDown={handleContainerKeyDown}
          onClick={handleControlClicks}>
          <div className={styles['header']}>
            <div className={styles['header-left']}>
              {isMultipleScatter && (
                <>
                  <Text type="bold-11px">{surfaceName}</Text>
                  <Text type="medium-11px" color="#DADADA" style={{ opacity: 0.8 }}>
                    {materialName}
                  </Text>
                </>
              )}
            </div>

            <div className={styles['header-right']}>
              <div className={styles['buttons-container']}>
                <ScatterModeButton
                  data-dd-action-name="Change scatter mode to single value"
                  title="Single value scattering"
                  iconName="dot"
                  selected={!isMultipleScatter}
                  onClick={handleSingleScatterClick}
                />
                <ScatterModeButton
                  data-dd-action-name="Change scatter mode to multiple values"
                  title="Scattering per octave band"
                  iconName="octaveBand"
                  selected={isMultipleScatter}
                  onClick={handleMultipleScatterClick}
                />
              </div>
              <div className={styles['info']}>
                <TrblTooltip
                  placement="left"
                  title={
                    <div>
                      <p>
                        Single value and octave band scattering are using two different methods of scattering, see our{' '}
                        <a
                          target="_blank"
                          style={{ textDecoration: 'underline' }}
                          href="https://docs.treble.tech/user-guide/simulations/assigning_materials#assigning-a-scattering-coefficient">
                          <b>documentation</b>
                        </a>{' '}
                        for more info.
                      </p>
                      <br />
                      <p>
                        <i>Please note that scattering is only applied in the Geometrical Acoustics solver.</i>
                      </p>
                    </div>
                  }>
                  <span className={styles['info-icon']}>
                    <TrblIcon icon="info" />
                  </span>
                </TrblTooltip>
              </div>
            </div>
          </div>
          <div className={`${styles['scatter-inputs']} ${isMultipleScatter ? styles['multiple-scatter'] : ''}`}>
            {[...Array(numberOfInputs)].map((_, i) => {
              const scatterValue =
                isMultipleScatter && multipleScatterValues ? multipleScatterValues[i] : singleScatterValue;

              return (
                <TrblNumberInput
                  key={`input_${i}`}
                  allowEmpty={allowEmpty}
                  ref={i === 0 ? firstInputRef : undefined}
                  value={scatterValue}
                  alignment={'left'}
                  step={0.01}
                  decimals={2}
                  min={0}
                  max={1}
                  onChange={(value) => handleInputChange(value, i)}
                  onBlur={(value) => handleInputBlur(value, i)}
                  onDoubleClick={handleDoubleClick}
                  onKeyDown={(event, value) => handleInputKeyDown(event, value, i)}
                  blurOnStep={false}
                  autofocus={i == 0}
                  readOnly={readonly}
                />
              );
            })}
          </div>
          {isMultipleScatter && (
            <div className={`${styles['octave-bands']} ${isMultipleScatter ? styles['multiple-scatter'] : ''}`}>
              {[...Array(numberOfInputs)].map((_, i) => (
                <span key={`band_${frequencyBands[i]}`} className={styles['band']}>
                  <Text type="regular-10px" color="#999">
                    {frequencyBands[i]}
                  </Text>
                </span>
              ))}
            </div>
          )}
        </div>
      </Popper>
    </ClickAwayListener>
  );
};

type ScatterModeButtonProps = {
  title: string;
  selected: boolean;
  onClick: () => void;
  iconName: 'dot' | 'octaveBand';
};

const ScatterModeButton: FC<ScatterModeButtonProps> = ({ title, selected, onClick, iconName }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <span
      title={title}
      className={`${styles['button']} ${selected ? styles['selected'] : ''}`}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={!selected ? onClick : undefined}>
      <TrblIcon icon={iconName} color={!selected && !isHovering ? '#616161' : undefined} />
    </span>
  );
};
