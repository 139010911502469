import { ActionType, FrequencyWeightingType, ReflectogramResultsContextAction, State } from '../types';

export const initialState: State = {
  reflectionsDataDownloadInfo: null,
  reflectionsData: [],
  selectedFrequencyWeighting: FrequencyWeightingType.None,
  selectedFrequencyBandIndex: 3, // Default to 1K Hz band
  selectedScale: 'speech',
  selectedTimeOfArrivalGroupIndexes: [0, 1, 2, 3],
  selectedReflectionIndex: null,
  receiverDirections: {},
  selectedReceiverDirection: null,
  relativeAndFilteredData: [],
};

export const reflectogramResultsReducer = (state: State, action: ReflectogramResultsContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE:
      return initialState;

    case ActionType.RESET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFrequencyBandIndex: initialState.selectedFrequencyBandIndex,
        selectedScale: initialState.selectedScale,
        selectedTimeOfArrivalGroupIndexes: initialState.selectedTimeOfArrivalGroupIndexes,
        selectedReflectionIndex: initialState.selectedReflectionIndex,
      };

    case ActionType.SET_REFLECTIONS_DOWNLOAD_INFO:
      return {
        ...state,
        reflectionsDataDownloadInfo: action.downloadInfo,
      };

    case ActionType.SET_REFLECTIONS_DATA:
      return {
        ...state,
        reflectionsData: action.reflectionsData,
      };

    case ActionType.SET_SELECTED_REFLECTION_INDEX:
      return {
        ...state,
        selectedReflectionIndex: action.reflectionIndex,
      };

    case ActionType.SET_SELECTED_FREQUENCY_WEIGHTING:
      return {
        ...state,
        selectedFrequencyWeighting: action.frequencyWeighting,
      };

    case ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX:
      return {
        ...state,
        selectedFrequencyBandIndex: action.frequencyBandIndex,
      };

    case ActionType.SET_SELECTED_SCALE:
      return {
        ...state,
        selectedScale: action.scale,
      };

    case ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES:
      return {
        ...state,
        selectedTimeOfArrivalGroupIndexes: action.indexes,
        selectedReflectionIndex: null,
      };

    case ActionType.SET_ALL_RECEIVER_DIRECTIONS:
      return {
        ...state,
        receiverDirections: action.receiverDirections,
      };

    case ActionType.SET_RECEIVER_DIRECTION: {
      let simulationReceiverDirections = state.receiverDirections[action.simulationId] || {};

      // If no receiverId is provided, we update all receivers in the simulation
      if (action.receiverId) {
        simulationReceiverDirections = {
          ...state.receiverDirections[action.simulationId],
          [action.receiverId]: action.direction,
        };
      } else {
        simulationReceiverDirections = Object.keys(simulationReceiverDirections).reduce(
          (acc, receiverId) => ({
            ...acc,
            [receiverId]: action.direction,
          }),
          {}
        );
      }

      return {
        ...state,
        receiverDirections: {
          ...state.receiverDirections,
          [action.simulationId]: simulationReceiverDirections,
        },
      };
    }
    default:
      return state;
  }
};
