import styles from './styles.module.scss';

export const TableDivider = ({
  double = false,
  small = false,
  xSmall = false,
}: {
  double?: boolean;
  small?: boolean;
  xSmall?: boolean;
}) => {
  return (
    <>
      <div
        className={styles['table-divider']}
        style={double ? { marginBottom: '2px' } : small ? { margin: '1px 0' } : xSmall ? { margin: '0' } : {}}></div>
      {double && <div className={styles['table-divider']} style={double ? { marginTop: '0' } : {}}></div>}
    </>
  );
};
