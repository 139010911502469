import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSourceDefinitionIcon: FC<IconProps> = ({ fill = '#DADADA', width = '16', height = '16' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4C2 4.36819 2.29848 4.66667 2.66667 4.66667C3.03486 4.66667 3.33333 4.36819 3.33333 4C3.33333 3.63181 3.63181 3.33333 4 3.33333C4.36819 3.33333 4.66667 3.03486 4.66667 2.66667C4.66667 2.29848 4.36819 2 4 2C2.89543 2 2 2.89543 2 4Z"
      fill={fill}
    />
    <path
      d="M0.666666 4C1.03486 4 1.33333 3.70152 1.33333 3.33333C1.33333 2.22876 2.22876 1.33333 3.33333 1.33333C3.70152 1.33333 4 1.03486 4 0.666667C4 0.298477 3.70152 7.75902e-09 3.33333 0C1.4933 0.00220408 0.00220399 1.4933 -2.05906e-07 3.33333C-2.16828e-07 3.51014 0.0702377 3.67971 0.195262 3.80474C0.320286 3.92976 0.489855 4 0.666666 4Z"
      fill={fill}
    />
    <path
      d="M12.0007 3.33333C12.3688 3.33333 12.6673 3.63181 12.6673 4C12.6673 4.36819 12.9658 4.66667 13.334 4.66667C13.7022 4.66667 14.0007 4.36819 14.0007 4C14.0007 2.89543 13.1052 2 12.0007 2C11.6325 2 11.334 2.29848 11.334 2.66667C11.334 3.03486 11.6325 3.33333 12.0007 3.33333Z"
      fill={fill}
    />
    <path
      d="M12.6667 1.33333C13.7712 1.33333 14.6667 2.22876 14.6667 3.33333C14.6667 3.70152 14.9651 4 15.3333 4C15.7015 4 16 3.70152 16 3.33333C15.9978 1.4933 14.5067 0.00220408 12.6667 0C12.2985 0 12 0.298477 12 0.666667C12 1.03486 12.2985 1.33333 12.6667 1.33333Z"
      fill={fill}
    />
    <path
      d="M2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333C3.03486 11.3333 3.33333 11.6318 3.33333 12C3.33333 12.3682 3.63181 12.6667 4 12.6667C4.36819 12.6667 4.66667 12.9651 4.66667 13.3333C4.66667 13.7015 4.36819 14 4 14C2.89543 14 2 13.1046 2 12Z"
      fill={fill}
    />
    <path
      d="M0.666667 12C1.03486 12 1.33333 12.2985 1.33333 12.6667C1.33333 13.7712 2.22876 14.6667 3.33333 14.6667C3.70152 14.6667 4 14.9651 4 15.3333C4 15.7015 3.70152 16 3.33333 16C1.4933 15.9978 0.00220417 14.5067 2.05906e-07 12.6667C2.16828e-07 12.4899 0.0702381 12.3203 0.195262 12.1953C0.320287 12.0702 0.489856 12 0.666667 12Z"
      fill={fill}
    />
    <path
      d="M12.0007 12.6667C12.3688 12.6667 12.6673 12.3682 12.6673 12C12.6673 11.6318 12.9658 11.3333 13.334 11.3333C13.7022 11.3333 14.0007 11.6318 14.0007 12C14.0007 13.1046 13.1052 14 12.0007 14C11.6325 14 11.334 13.7015 11.334 13.3333C11.334 12.9651 11.6325 12.6667 12.0007 12.6667Z"
      fill={fill}
    />
    <path
      d="M12.6667 14.6667C13.7712 14.6667 14.6667 13.7712 14.6667 12.6667C14.6667 12.2985 14.9651 12 15.3333 12C15.7015 12 16 12.2985 16 12.6667C15.9978 14.5067 14.5067 15.9978 12.6667 16C12.2985 16 12 15.7015 12 15.3333C12 14.9651 12.2985 14.6667 12.6667 14.6667Z"
      fill={fill}
    />
    <circle cx="8.00042" cy="8.00005" r="1.71429" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10.8571C9.57796 10.8571 10.8571 9.57796 10.8571 8C10.8571 6.42204 9.57796 5.14286 8 5.14286C6.42204 5.14286 5.14286 6.42204 5.14286 8C5.14286 9.57796 6.42204 10.8571 8 10.8571ZM8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
      fill={fill}
    />
  </svg>
);
