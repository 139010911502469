import { cloneDeep } from 'lodash';

import { SimulationsInSpace } from '@/context/AppContext';

import { SelectOptionWithGrouping } from '../Shared/TrblSelect';

import { getModelSourceIds, getSimulationById } from '@/hooks';

import { EMPTY_SIM } from './constants';

import { ModelSettings, Simulation } from '@/types';

export const parseSimulation = async (templateSimId: string, modelId: string): Promise<ModelSettings> => {
  const templateSim = await getSimulationById(templateSimId);

  const templateSourceIdByObjectId = await getModelSourceIds(templateSim.modelId);

  const targetSourceIdByObjectId = await getModelSourceIds(modelId);

  const newSimulation = {} as Simulation;
  newSimulation.modelSettings = {} as ModelSettings;
  newSimulation.modelSettings.materialIdByObjectId = {};
  newSimulation.modelSettings.scatteringByObjectId = {};

  if (templateSim.modelSettings !== null) {
    for (const templateObjectId in templateSim.modelSettings.materialIdByObjectId) {
      const materialId = templateSim.modelSettings.materialIdByObjectId[templateObjectId];
      const templateSourceId = templateSourceIdByObjectId[templateObjectId];

      const scattering = templateSim.modelSettings.scatteringByObjectId[templateObjectId];

      let hit = null;
      for (const objectId in targetSourceIdByObjectId) {
        if (targetSourceIdByObjectId[objectId] == templateSourceId) {
          hit = objectId;
          break;
        }
      }
      if (hit != null) {
        newSimulation.modelSettings.materialIdByObjectId[hit] = materialId;
        newSimulation.modelSettings.scatteringByObjectId[hit] = scattering;
      }
    }
  }

  return newSimulation.modelSettings;
};

export const createFromSimulation = async (
  simulationsInSpace: SimulationsInSpace[],
  modelId: string,
  simulationToCopyId: string
) => {
  const simulations = simulationsInSpace
    .map((modelsWithSimulations: SimulationsInSpace) => modelsWithSimulations.simulations)
    .flat();

  const selSim = simulations.find((simulation: Simulation) => simulation.id === simulationToCopyId);
  const simToCopy = cloneDeep(selSim);

  if (simToCopy) {
    // if simulation is from another model then try to parse materials to the surfaces of this model
    if (simToCopy.modelId !== modelId) {
      simToCopy.modelSettings = await parseSimulation(simToCopy.id, modelId);
    }

    //@ts-expect-error parameters needed to be removed from the object so the Create simulation API call can run correctly
    delete simToCopy.id;
    delete simToCopy.extra;
    delete simToCopy.lastSimulationRun;

    return simToCopy;
  }
};

export const createFromScratch = (nonWatertight: boolean | null | undefined) => {
  const newSim = cloneDeep(EMPTY_SIM);

  if (nonWatertight) {
    newSim.taskType = 'GA';
    newSim.settingsPreset = 1;
  }

  return newSim;
};

// TODO: move to shared utils
export const createSelectMenu = (simulationsInSpace: SimulationsInSpace[], currentModelId: string = '') => {
  let newSimOptions = simulationsInSpace;
  let currentModelWithSims: SimulationsInSpace[] = [];
  if (currentModelId.length > 0) {
    // find the current model
    currentModelWithSims = newSimOptions.filter((model) => model.id === currentModelId);
    if (currentModelWithSims.length > 0) {
      // filter out the rest so we can make the current
      // model the first option in the dropdown
      newSimOptions = newSimOptions.filter((model) => model.id !== currentModelId);
    }
  }
  let sortedMenuItems = sortHierarchy(newSimOptions);
  if (currentModelWithSims) {
    const sortedFirstItem = sortHierarchy(currentModelWithSims);
    sortedMenuItems = [...sortedFirstItem, ...sortedMenuItems];
  }
  const parsedMenuItems: SelectOptionWithGrouping[] = sortedMenuItems.map((item) => {
    return {
      name: item.name,
      id: item.id,
      options: item.simulations.map((sim: Simulation) => {
        return {
          name: `${sim.name}${sim.hasBeenEdited ? ' [editing]' : ''}`,
          id: sim.id,
        };
      }),
    };
  });
  return parsedMenuItems;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortHierarchy = (nodes: any[] = []) => {
  const toSort = nodes;
  const sorted = toSort.sort(
    (a: SimulationsInSpace, b: SimulationsInSpace) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  );
  sorted.forEach((option: SimulationsInSpace) => sortHierarchy(option.simulations ?? []));
  return sorted;
};
