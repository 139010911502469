import { IconProps } from '@/types';

export const TrblDraggableIcon = ({ fill = '#999999' }: IconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.16667 2.60629C4.52234 2.60629 4 2.1033 4 1.48283C4 0.86236 4.52234 0.359375 5.16667 0.359375C5.811 0.359375 6.33334 0.86236 6.33334 1.48283C6.33334 2.1033 5.811 2.60629 5.16667 2.60629Z"
      fill={fill}
    />
    <path
      d="M6.33333 5.12346C6.33333 5.74393 5.811 6.24693 5.16667 6.24693C4.52233 6.24693 4 5.74393 4 5.12346C4 4.50299 4.52233 4 5.16667 4C5.811 4 6.33333 4.50299 6.33333 5.12346Z"
      fill={fill}
    />
    <path
      d="M4.00306 8.62346C4.00306 8.00299 4.52539 7.5 5.16972 7.5C5.81405 7.5 6.33639 8.00299 6.33639 8.62346C6.33639 9.24393 5.81405 9.74693 5.16972 9.74693C4.52539 9.74693 4.00306 9.24393 4.00306 8.62346Z"
      fill={fill}
    />
    <path
      d="M4.00305 12.3765C4.00305 11.7561 4.52539 11.2531 5.16972 11.2531C5.81405 11.2531 6.33639 11.7561 6.33639 12.3765C6.33639 12.997 5.81405 13.5 5.16972 13.5C4.52539 13.5 4.00305 12.997 4.00305 12.3765Z"
      fill={fill}
    />
    <path
      d="M8.91667 2.60629C8.27234 2.60629 7.75 2.1033 7.75 1.48283C7.75 0.86236 8.27234 0.359375 8.91667 0.359375C9.561 0.359375 10.0833 0.86236 10.0833 1.48283C10.0833 2.1033 9.561 2.60629 8.91667 2.60629Z"
      fill={fill}
    />
    <path
      d="M10.0833 5.12346C10.0833 5.74393 9.561 6.24693 8.91667 6.24693C8.27233 6.24693 7.75 5.74393 7.75 5.12346C7.75 4.50299 8.27233 4 8.91667 4C9.561 4 10.0833 4.50299 10.0833 5.12346Z"
      fill={fill}
    />
    <path
      d="M7.75306 8.62346C7.75306 8.00299 8.27539 7.5 8.91972 7.5C9.56405 7.5 10.0864 8.00299 10.0864 8.62346C10.0864 9.24393 9.56405 9.74693 8.91972 9.74693C8.27539 9.74693 7.75306 9.24393 7.75306 8.62346Z"
      fill={fill}
    />
    <path
      d="M7.75305 12.3765C7.75305 11.7561 8.27539 11.2531 8.91972 11.2531C9.56405 11.2531 10.0864 11.7561 10.0864 12.3765C10.0864 12.997 9.56405 13.5 8.91972 13.5C8.27539 13.5 7.75305 12.997 7.75305 12.3765Z"
      fill={fill}
    />
  </svg>
);
