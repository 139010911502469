import { SourceSettings } from '@/components/AuralizerPresets/types';
import { AuralizerSound } from '@/components/SoundLibrary/types';

type NewSourceVolumes = { [key: number]: number };
type NewSourceSounds = {
  [key: number]: AuralizerSound;
};
type NewSourceMuteSettings = {
  [key: number]: boolean;
};
export const createInitialPresetSettingsObjects = (sourceSettings: SourceSettings[] | null) => {
  let newSourceVolumes: NewSourceVolumes = {};
  let newSourceSounds: NewSourceSounds = {};
  let newSourceMuteSettings: NewSourceMuteSettings = {};

  if (sourceSettings) {
    newSourceVolumes = sourceSettings.reduce((acc: NewSourceVolumes, curr: SourceSettings, index: number) => {
      return {
        ...acc,
        [index]: curr.volume,
      };
    }, {} as NewSourceVolumes);

    newSourceSounds = sourceSettings.reduce((acc: NewSourceSounds, curr: SourceSettings, index: number) => {
      return {
        ...acc,
        [index]: {
          // we are treating soundPath as an id until we have the backend in place
          id: curr.soundPath ?? '',
          soundPath: '',
          name: '',
          urlObject: null,
        },
      };
    }, {} as NewSourceSounds);

    newSourceMuteSettings = sourceSettings.reduce((acc: NewSourceMuteSettings, curr: SourceSettings, index: number) => {
      return {
        ...acc,
        [index]: curr.isMuted ?? false,
      };
    }, {} as NewSourceMuteSettings);
  }

  return { newSourceVolumes, newSourceSounds, newSourceMuteSettings };
};
