import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TertiaryButton } from '@/components/Shared/Buttons';
import { TrblLoadIcon } from '@/components/Icons';
import { ActionType, usePresetContext } from '../Auralizer/PresetContext';

import styles from './styles.module.scss';

const UPLOAD_LABEL = 'Load local sound (.wav)';
const UPLOADED_LABEL = 'Replace local sound (.wav)';

export const UploadSound = ({ sourceIndex }: { sourceIndex: number }) => {
  const { dispatch, selectedSounds } = usePresetContext();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  useEffect(() => {
    if (selectedSounds[sourceIndex]?.urlObject) {
      setUploadedFileName(selectedSounds[sourceIndex]?.name);
    } else {
      setUploadedFileName(null);
    }
  }, [selectedSounds]);

  const uploadSound = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.wav';
    input.onchange = () => {
      if (input.files) {
        const files = Array.from(input.files);
        const urlObject = URL.createObjectURL(files[0]);
        dispatch({
          type: ActionType.SET_SELECTED_SOUND,
          sourceIndex,
          sound: { id: '', name: files[0].name, soundPath: '', urlObject },
        });
      }
    };
    input.click();
  };

  return (
    <TrblTooltip title={uploadedFileName ?? ''}>
      <TertiaryButton
        className={`${styles.upload_sound_button} ${uploadedFileName ? styles.file_loaded : ''}`}
        label={uploadedFileName ? UPLOADED_LABEL : UPLOAD_LABEL}
        onClick={uploadSound}
        icon={<TrblLoadIcon fill="#00F5BA" />}
      />
    </TrblTooltip>
  );
};
