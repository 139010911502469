import { ChangeEventHandler, useRef } from 'react';

import { FormControl, InputAdornment, TextField } from '@mui/material';

import classes from './styles.module.scss';

interface SimulationFormTextField {
  label: string;
  value: string;
  color?: string;
  formControlChangeEvent: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}
export const SimulationFormTextField = (formData: SimulationFormTextField) => {
  const { label, value, color, formControlChangeEvent, disabled = false } = formData;

  const inpRef = useRef<HTMLInputElement>(null);

  return (
    <FormControl fullWidth disabled={disabled} className={classes.simulation_form_control__container}>
      <TextField
        inputRef={inpRef}
        name={label}
        className={classes.treble_textfield}
        label={label}
        value={value}
        onChange={formControlChangeEvent}
        InputProps={
          color
            ? {
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: 0 }}>
                    <div className={classes.comparison_color} style={{ backgroundColor: color }}></div>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
    </FormControl>
  );
};
