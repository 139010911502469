import { SyntheticEvent, useRef } from 'react';
import { Link } from 'react-router-dom';

import { HighlightedText } from '@/components/Shared/HighlightedText';
import { TrblModelsIcon } from '@/components/Icons';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import { IActions } from '@/types';

import classes from './styles.module.scss';

interface SpaceCardProps {
  viewType?: string;
  id: string;
  title?: string;
  modelCount?: number;
  spaceActions: IActions[];
  tabIndex?: number;
  projectSearchString?: string;
}

export const SpaceCard = ({
  viewType = 'cards',
  id,
  title,
  modelCount = 0,
  spaceActions,
  tabIndex = 0,
  projectSearchString = '',
}: SpaceCardProps) => {
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const cardRef = useRef<HTMLAnchorElement>(null);

  const handleClickSpace = (e: SyntheticEvent) => {
    // prevent space link to open if action menu is clicked
    if (!cardRef.current?.contains(e.target as Node)) {
      e.preventDefault();
    }
  };

  const cardStyle = viewType === 'cards' ? classes.card_container : classes.list_container;
  const isCardSearched =
    projectSearchString.length > 0 && title?.toLowerCase()?.includes(projectSearchString.toLowerCase());

  return (
    <Link
      ref={cardRef}
      className={`${cardStyle} ${isCardSearched ? classes.searched : ''}`}
      to={`/space/${id}`}
      onClick={handleClickSpace}
      tabIndex={tabIndex}>
      <div className={classes.card_rows}>
        <p className={classes.card_title}>
          <HighlightedText text={title ?? ''} highlight={projectSearchString} highlightColor="#00f5ba" />
        </p>
        {viewType === 'cards' && (
          <TrblActionsMenu
            triggerRef={actionsButtonRef}
            actions={spaceActions}
            id={id}
            title="View Space actions"
            classNames={classes.info_top}
            tabIndex={tabIndex}
          />
        )}
      </div>
      <div className={`${classes.card_rows} ${classes.info_row}`}>
        <div className={classes.models_tag}>
          <TrblModelsIcon fill="#999999" />
          <p className={classes.modified_tag}>
            {modelCount} {modelCount === 1 ? 'model' : 'models'}
          </p>
        </div>
        {viewType !== 'cards' && (
          <TrblActionsMenu
            triggerRef={actionsButtonRef}
            actions={spaceActions}
            id={id}
            classNames={classes.info_top}
            size="large"
            tabIndex={tabIndex}
          />
        )}
      </div>
    </Link>
  );
};
