import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCoarseness: FC<IconProps> = ({ height = '10', width = '10' }) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="0.714286" cy="0.714286" r="0.714286" fill="#D9D9D9" />
    <ellipse cx="0.714286" cy="5.00005" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="0.714286" cy="9.2857" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <ellipse cx="5.00042" cy="0.714286" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <circle cx="5.00042" cy="5.00005" r="0.714286" fill="#D9D9D9" />
    <circle cx="5.00042" cy="9.2857" r="0.714286" fill="#D9D9D9" />
    <ellipse cx="9.28557" cy="0.714286" rx="0.714286" ry="0.714286" fill="#D9D9D9" />
    <circle cx="9.28557" cy="5.00005" r="0.714286" fill="#D9D9D9" />
    <circle cx="9.28557" cy="9.2857" r="0.714286" fill="#D9D9D9" />
  </svg>
);
