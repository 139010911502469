import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';

import { useSubmitEvent, useUpdateProject } from '@/hooks';

interface PopupProjectCardProps {
  showPopup: boolean;
  onClose?: () => void;
  onUpdate?: (response: string) => void;
  id: string;
  name: string;
  description: string;
}
export const PopupProjectCard = ({
  showPopup = false,
  onClose,
  onUpdate,
  id,
  name,
  description,
}: PopupProjectCardProps) => {
  const [newName, setNewName] = useState<string>(name);
  const [newDescription, setNewDescription] = useState<string>(description);
  const [isFormValid, setIsFormValid] = useState(true);

  const { mutate: updateProject } = useUpdateProject();

  useSubmitEvent(isFormValid, [name]);

  useEffect(() => {
    if (showPopup) {
      setNewName(name);
      setNewDescription(description);
    }
  }, [showPopup]);

  // Form validation
  useEffect(() => {
    if (newName && newName.length > 0 && (newName !== name || newDescription !== description)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [newName, name, newDescription, description]);

  const handleSubmitUpdate = () => {
    updateProject(
      {
        id: id,
        name: newName,
        description: newDescription,
      },
      {
        onSuccess: () => {
          if (onUpdate) onUpdate(newName);
          toast.info("'" + newName + "' updated");
        },
        onError: () => {
          toast.error('An error occurred while updating Project');
        },
      }
    );
  };

  return (
    <>
      {showPopup && newName != undefined && (
        <TrblPopup aria-labelledby={'Edit project'} sx={{ fontSize: '12px' }} open={true}>
          <form>
            <TrblPopupTitle onClose={onClose}>{'Edit project'}</TrblPopupTitle>
            <TrblPopupContent>
              <Stack gap={1}>
                <TextField autoFocus maxLength={128} placeholder="Name" value={newName} onChange={setNewName} />
                <TextArea placeholder="Description" value={newDescription} onChange={setNewDescription} />
              </Stack>
            </TrblPopupContent>
            <TrblPopupActions>
              <Box component={'div'} display="flex" justifyContent="flex-end" width={'100%'}>
                <SecondaryButton
                  type="submit"
                  disabled={!isFormValid}
                  width={'auto'}
                  label="Update"
                  onClick={handleSubmitUpdate}
                />
              </Box>
            </TrblPopupActions>
          </form>
        </TrblPopup>
      )}
    </>
  );
};
