import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblMaterialIcon: FC<IconProps> = ({
  fill = '#F5F5F5',
  fillExtra = '#F5F5F5',
  width = '16',
  height = '16',
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.76998 4.9335L7.20265 7.7335C7.71135 7.95394 8.28861 7.95394 8.79731 7.7335L15.23 4.9335C15.595 4.77472 15.8311 4.41456 15.8311 4.0165C15.8311 3.61844 15.595 3.25828 15.23 3.0995L8.79998 0.301498C8.29126 0.0811412 7.71403 0.0811412 7.20531 0.301498L0.771314 3.0975C0.405879 3.2561 0.169434 3.61647 0.169434 4.01483C0.169434 4.4132 0.405879 4.77357 0.771314 4.93217L0.76998 4.9335Z"
      fill={fill}
    />
    <path
      d="M15.2294 7.09877L14.4 6.73744C14.0624 6.59016 13.6693 6.74448 13.522 7.08211C13.3748 7.41974 13.5291 7.81283 13.8667 7.96011L13.994 8.01544L8.2667 10.5061C8.09686 10.5812 7.90321 10.5812 7.73337 10.5061L2.00537 8.01544L2.13337 7.96011C2.35178 7.86484 2.50277 7.66028 2.52947 7.4235C2.55616 7.18672 2.45451 6.95368 2.2628 6.81217C2.07109 6.67065 1.81845 6.64217 1.60004 6.73744L0.770703 7.09811C0.405268 7.2567 0.168823 7.61707 0.168823 8.01544C0.168823 8.41381 0.405268 8.77417 0.770703 8.93277L7.20004 11.7288C7.70874 11.9492 8.286 11.9492 8.7947 11.7288L15.2274 8.93344C15.5928 8.77484 15.8293 8.41447 15.8293 8.01611C15.8293 7.61774 15.5928 7.25737 15.2274 7.09877H15.2294Z"
      fill={fillExtra}
    />
    <path
      d="M15.2294 11.067L14.4 10.7077C14.0624 10.5604 13.6693 10.7147 13.522 11.0523C13.3748 11.39 13.5291 11.783 13.8667 11.9303L13.994 11.9857L8.2667 14.4763C8.09667 14.5505 7.9034 14.5505 7.73337 14.4763L2.00537 11.9857L2.13337 11.9337C2.35178 11.8384 2.50277 11.6338 2.52947 11.397C2.55616 11.1603 2.45451 10.9272 2.2628 10.7857C2.07109 10.6442 1.81844 10.6157 1.60004 10.711L0.770703 11.071C0.405268 11.2296 0.168823 11.59 0.168823 11.9883C0.168823 12.3867 0.405268 12.7471 0.770703 12.9057L7.20004 15.699C7.70874 15.9194 8.286 15.9194 8.7947 15.699L15.2247 12.9037C15.5905 12.7452 15.8274 12.3847 15.8274 11.986C15.8274 11.5873 15.5905 11.2267 15.2247 11.0683L15.2294 11.067Z"
      fill={fill}
    />
  </svg>
);
