import { SxProps, Theme } from '@mui/material';

export const gridStyleOverrides: SxProps<Theme> = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
};
