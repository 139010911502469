import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { UserDto } from '@/components/ManagementPortal/hooks';
import { getCheckoutOptions } from './getCheckoutOptions';
import { getPortalOptions } from './getPortalOptions';
import { getTokenParams } from './getTokenParams';

import { getUserMine } from '@/hooks';

import { PLANS } from '../constants';

import { config } from '@/__config__/config';

import { SubscriptionLength, SubscriptionType } from '../types';
import type { Chargebee, ChargebeeInstance } from '@chargebee/chargebee-js-types';

import axios from '@/axios';

const { adminApiUrl, chargebeeApiKeyPublishable, viteChargebeeSiteName } = config;

declare global {
  interface Window {
    Chargebee: typeof Chargebee;
  }
}

export const useChargebee = (onChargebeeLoaded?: () => void) => {
  const cbInstanceRef = useRef<ChargebeeInstance | undefined>(undefined);
  const navigate = useNavigate();

  const [pendingBillingType, setPendingBillingType] = useState<null | string>(null);
  const isSubscriptionPending = useMemo(() => !!pendingBillingType, [pendingBillingType]);

  // If we are subscribing we poll the admin api until the subscription type has been registered in
  // the backend before we route to the frontpage.
  useQuery<UserDto>(['user_mine'], getUserMine, {
    enabled: !!pendingBillingType,
    refetchInterval: (data) => {
      if (data) {
        if (data?.billingType.toLowerCase() === pendingBillingType?.toLowerCase()) {
          toast.success('Your subscription has been processed. Thank you for using Treble!');
          setPendingBillingType(null);
          navigate('/');
          return false;
        } else {
          return 1000;
        }
      } else {
        return false;
      }
    },
  });

  useEffect(() => {
    if (!window.Chargebee) {
      const script = document.createElement('script');
      script.onload = function () {
        window.Chargebee.init({
          site: viteChargebeeSiteName,
          publishableKey: chargebeeApiKeyPublishable,
        });
        cbInstanceRef.current = window.Chargebee.getInstance();

        if (onChargebeeLoaded && cbInstanceRef.current) {
          onChargebeeLoaded();
        }
      };
      script.src = 'https://js.chargebee.com/v2/chargebee.js';
      document.head.appendChild(script);
    } else {
      cbInstanceRef.current = window.Chargebee.getInstance();
    }
  }, []);

  // subscribe for the first time
  const subscribe = async (
    subscriptionType: SubscriptionType,
    subscriptionLength: SubscriptionLength,
    additionalSeats: number = 5
  ) => {
    if (cbInstanceRef.current) {
      cbInstanceRef.current.openCheckout(
        getCheckoutOptions(
          'Subscribe',
          [
            {
              product: 'TASS',
              subscriptionType: subscriptionType,
              paymentFrequency: subscriptionLength,
              additionalSeats,
            },
          ],
          () => {
            cbInstanceRef.current?.closeAll();
            toast.info('Your subscription is being processed. Please wait...');
            setPendingBillingType(subscriptionType === 'flexible' ? 'PrepaidOnly' : subscriptionType);
          }
        )
      );
    }
  };

  const openPortal = async (onSubscriptionChanged: () => void) => {
    if (cbInstanceRef.current) {
      await cbInstanceRef.current.setPortalSession(async () => {
        const { data } = await axios.post(`/api/Portal/Session/`, null, {
          baseURL: adminApiUrl,
        });
        return data.portal_session;
      });
      const portal = cbInstanceRef.current.createChargebeePortal();

      // @ts-expect-error Expected 2 arguments, but got 1.
      portal.open(getPortalOptions(cbInstanceRef.current, onSubscriptionChanged));
    }
  };

  const addTokens = (onTokensChanged: () => void) => {
    if (cbInstanceRef.current) {
      const tokenType = PLANS.tokens.volume;

      cbInstanceRef.current.openCheckout(
        getCheckoutOptions('TokensVolume', getTokenParams(tokenType), () => {
          cbInstanceRef.current?.closeAll();
          toast.info('Your purchase is being processed. Please wait...');
          onTokensChanged();
        })
      );
    }
  };

  return {
    isSubscriptionPending,
    subscribe,
    addTokens,
    openPortal,
  };
};
