export type ReflectionPath = [number, number, number][];

export type ReflectionDetails = {
  order: number;
  path: ReflectionPath;
  timeOfArrival: number;
  timeOfArrivalRelative: number;
  azimuth: number;
  elevation: number;
  spl: number;
  splRelative: number;
  splAWeighted?: number;
  splAWeightedRelative?: number;
  splCWeighted?: number;
  splCWeightedRelative?: number;
  splPerBand: number[];
  splPerBandRelative: number[];
  splAWeightedPerBand?: number[];
  splAWeightedPerBandRelative?: number[];
  splCWeightedPerBand?: number[];
  splCWeightedPerBandRelative?: number[];
  distance: number;
};

export type ReflectionDetailsWithRelativeAngles = ReflectionDetails & {
  azimuthRelative: number;
  elevationRelative: number;
};

export enum FrequencyWeightingType {
  None = 'None',
  AWeighting = 'A',
  CWeighting = 'C',
}

export enum ReceiverDirectionType {
  AlongX = 'alongX',
  AlongY = 'alongY',
  TowardsSource = 'towardsSource',
  Custom = 'custom',
}

export type ReceiverDirections = Record<string, Record<string, ReceiverDirection>>;

export type ReceiverDirection =
  | { type: ReceiverDirectionType.Custom; azimuth: number; elevation: number }
  | { type: Exclude<ReceiverDirectionType, ReceiverDirectionType.Custom> };

export type SelectedReceiverDirection = {
  receiverId: string;
  simulationId: string;
  type: ReceiverDirectionType;
  azimuth: number;
  elevation: number;
};

export enum ActionType {
  RESET_STATE = 'RESET_STATE',
  RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS',
  SET_REFLECTIONS_DOWNLOAD_INFO = 'SET_REFLECTIONS_DOWNLOAD_INFO',
  SET_REFLECTIONS_DATA = 'SET_REFLECTIONS_DATA',
  SET_SELECTED_REFLECTION_INDEX = 'SET_SELECTED_REFLECTION_INDEX',
  SET_SELECTED_FREQUENCY_WEIGHTING = 'SET_SELECTED_FREQUENCY_WEIGHTING',
  SET_SELECTED_FREQUENCY_BAND_INDEX = 'SET_SELECTED_FREQUENCY_BAND_INDEX',
  SET_SELECTED_SCALE = 'SET_SELECTED_SCALE',
  SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES = 'SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES',
  SET_ALL_RECEIVER_DIRECTIONS = 'SET_ALL_RECEIVER_DIRECTIONS',
  SET_RECEIVER_DIRECTION = 'SET_RECEIVER_DIRECTION',
}

export type ReflectogramResultsContextAction =
  | { type: ActionType.RESET_STATE }
  | { type: ActionType.RESET_SELECTED_FILTERS }
  | { type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO; downloadInfo: { id: number; downloadUrl: string } | null }
  | { type: ActionType.SET_REFLECTIONS_DATA; reflectionsData: ReflectionDetails[] }
  | { type: ActionType.SET_SELECTED_REFLECTION_INDEX; reflectionIndex: number | null }
  | { type: ActionType.SET_SELECTED_FREQUENCY_WEIGHTING; frequencyWeighting: FrequencyWeightingType }
  | { type: ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX; frequencyBandIndex: number | null }
  | { type: ActionType.SET_SELECTED_SCALE; scale: string }
  | {
      type: ActionType.SET_ALL_RECEIVER_DIRECTIONS;
      receiverDirections: ReceiverDirections;
    }
  | {
      type: ActionType.SET_RECEIVER_DIRECTION;
      simulationId: string;
      receiverId?: string;
      direction: ReceiverDirection;
    }
  | { type: ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES; indexes: number[] };

export type State = {
  reflectionsDataDownloadInfo: { id: number; downloadUrl: string } | null;
  reflectionsData: ReflectionDetails[];
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedFrequencyBandIndex: number | null;
  selectedScale: string;
  selectedTimeOfArrivalGroupIndexes: number[];
  selectedReflectionIndex: number | null;
  receiverDirections: ReceiverDirections;
  selectedReceiverDirection: SelectedReceiverDirection | null;
  relativeAndFilteredData: ReflectionDetailsWithRelativeAngles[];
};
