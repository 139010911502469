import { MouseEventHandler, useCallback } from 'react';

import {
  FrequencyWeightingType,
  ReflectionDetailsWithRelativeAngles,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { getSplValue } from '../../utils';

/**
 * Finds the closest reflection to the clicked point on the polar plot and selects it
 */
export const usePolarPlotClickHandler = (
  data: ReflectionDetailsWithRelativeAngles[],
  radialAxisRange: [number, number] | null,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null,
  selectedAngleType: string,
  plotContainerRef: React.RefObject<HTMLDivElement>,
  onSelectReflection: (index: number) => void
): MouseEventHandler<HTMLDivElement> => {
  return useCallback(
    (event) => {
      const plotContainerElement = plotContainerRef.current;
      const plotAreaElement = plotContainerElement?.querySelector('.polar .maindrag') as HTMLElement;
      if (!plotAreaElement || !radialAxisRange) return;

      const { left, top, width, height } = plotAreaElement.getBoundingClientRect();
      const [clickX, clickY] = [event.clientX - left, event.clientY - top];
      const [centerX, centerY] = [width / 2, height / 2];
      const [dx, dy] = [clickX - centerX, clickY - centerY];

      let radius = Math.sqrt(dx * dx + dy * dy);
      let angle = (Math.atan2(dy, dx) * 180) / Math.PI + 180;

      // Make sure that the angle is between -180 and 180
      angle = ((angle + 180) % 360) - 180;

      // Adjust the radius to match the radial axis range instead of the plot area size in pixels
      const [radialMin, radialMax] = radialAxisRange;
      radius = (radius / (width / 2)) * (radialMax - radialMin) + radialMin;

      let closestIndex = -1;
      let minDistance = Infinity;

      data.forEach((d, index) => {
        const theta = selectedAngleType === 'elevation' ? d.elevationRelative : d.azimuthRelative;
        const r = getSplValue(d, selectedFrequencyWeighting, selectedFrequencyBandIndex);

        const distance = Math.sqrt((r - radius) ** 2 + (theta - angle) ** 2);

        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      });

      if (closestIndex !== -1) {
        onSelectReflection(closestIndex);
      }
    },
    [
      data,
      radialAxisRange,
      selectedFrequencyWeighting,
      selectedFrequencyBandIndex,
      selectedAngleType,
      plotContainerRef,
      onSelectReflection,
    ]
  );
};
