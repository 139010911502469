import { SyntheticEvent, useEffect, useState } from 'react';

import { Visibility } from '@/components/LayersTable/Visibility';
import { LayersList } from './LayersList';
import { LayersListContent } from './LayersList/LayersListContent';

import { DisplayLayer } from './LayersList/types';
import { ModelLayerGroup } from '@/context/ModelContext/types';

export const MultiSpaceLayerBox = ({
  layers,
  hiddenLayers,
  toggleVisibility,
  selectedRow,
  setSelectedRow,
}: {
  layers: ModelLayerGroup[];
  hiddenLayers: Set<string>;
  toggleVisibility: (event: SyntheticEvent, id: string, parentId: string) => void;
  selectedRow: string | null;
  setSelectedRow: (id: string) => void;
}) => {
  const [listData, setListData] = useState<DisplayLayer[]>([]);

  useEffect(() => {
    if (layers.length) {
      const displayLayers: DisplayLayer[] = [];
      layers.forEach((layer) => {
        displayLayers.push({
          id: layer.id,
          parentId: null,
          data: { name: layer.name },
          children: layer.children.map((child, i) => ({
            id: child.userData.attributes.id,
            parentId: layer.id,
            data: { name: '— surface (' + (i + 1) + ')' },
          })),
        });
      });

      setListData(displayLayers);
    }
  }, [layers]);

  const listColumns = [
    { fieldName: 'name', headerLabel: 'Layers' },
    {
      fieldName: 'actions',
      style: { justifyContent: 'flex-end', flexGrow: 0 },
      onRenderCell: ({
        rowId,
        parentId,
        isSelected,
        isHidden,
        toggleVisibility,
      }: {
        rowId: string;
        parentId: string;
        isSelected: boolean;
        isHidden: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toggleVisibility: (e: any, rowId: string, parentId: string) => void;
      }) => (
        <Visibility
          isHidden={isHidden}
          isHiddenTitle="Unhide"
          isShownTitle="Hide"
          isSelected={isSelected}
          toggleVisibility={(e) => toggleVisibility(e, rowId, parentId)}
        />
      ),
    },
  ];

  return (
    <LayersList columns={listColumns} data={listData}>
      {listData.map((rowData) => (
        <LayersListContent
          key={rowData.id}
          columns={listColumns}
          data={listData}
          rowData={rowData}
          hiddenLayers={hiddenLayers}
          toggleVisibility={toggleVisibility}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      ))}
    </LayersList>
  );
};
