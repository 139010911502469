import { FC } from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { PageLayout } from '@/components';
import { TrblChevronLeft } from '@/components/Icons';
import { Organization } from '@/components/ManagementPortal';

import { useGetOrganizationMine } from '@/hooks';

export const AdministrationPage: FC = () => {
  const { data: myOrganization } = useGetOrganizationMine();

  return (
    <PageLayout
      extraHeader={
        <div className="breadcrumbs-div">
          <Link to="/">
            <IconButton className="back-btn">
              <TrblChevronLeft />
            </IconButton>
          </Link>
          <div className="top-info-text">
            <span className="active">{'Administration'}</span>
          </div>
        </div>
      }>
      {myOrganization && <Organization organizationId={myOrganization.id} isMyOrganization={true} />}
    </PageLayout>
  );
};
