import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

interface UseMutationVariables {
  presetId: string;
  presetName: string;
}

const deleteAuralizationPreset = async (presetId: string, presetName: string) => {
  const { data } = await axios.delete(`/api/AuralizationPreset/DeleteAuralizationPreset/${presetId}`);
  return { data, presetName };
};

export const useDeleteAuralizationPreset = () => {
  return useMutation(
    async ({ presetId, presetName }: UseMutationVariables) => await deleteAuralizationPreset(presetId, presetName),
    {
      onError: () => {
        toast.error('An error occurred while deleting the auralization preset. Please refresh the browser.');
      },
      onSuccess: ({ presetName }) => {
        toast.info(`Preset "${presetName}" deleted!`);
      },
    }
  );
};
