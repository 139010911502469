import { FC, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import {
  DEFAULT_CAMERA_SETTINGS,
  DEFAULT_GL_SETTINGS,
  DEFAULT_RAYCASTER_SETTINGS,
  DEFAULT_RESIZE_SETTINGS,
  DEFAULT_SCENE_SETTINGS,
} from '@/components/Editor/components/Viewport/constants';
import { Grid } from '@/components/Editor/components/Viewport/Grid';
import { ViewportContent } from '../Viewport/ViewportContent';

import { GeometryInfo } from '../../types';
import { ModelLayerGroup } from '@/context/ModelContext/types';

type ViewportProps = {
  layerGroups: ModelLayerGroup[];
  geometryInfo: GeometryInfo | null;
  hiddenLayers: Set<string>;
  selectedRow: string | null;
  setSelectedRow: (id: string) => void;
  modelId: string;
};

export const Viewport: FC<ViewportProps> = ({
  layerGroups,
  geometryInfo,
  hiddenLayers,
  selectedRow,
  setSelectedRow,
  modelId,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [hasLoadingScreen, setHasLoadingScreen] = useState(true);

  useEffect(() => {
    // if layergroups are ready then remove the loading screen with a slight delay for effect
    if (layerGroups.length > 0) {
      setTimeout(() => {
        setHasLoadingScreen(false);
      }, 300);
    } else {
      setHasLoadingScreen(true);
    }
  }, [layerGroups]);

  return (
    <>
      <Canvas
        ref={canvasRef}
        frameloop="demand"
        resize={DEFAULT_RESIZE_SETTINGS}
        id="multispace_import_viewport"
        scene={DEFAULT_SCENE_SETTINGS}
        raycaster={DEFAULT_RAYCASTER_SETTINGS}
        camera={DEFAULT_CAMERA_SETTINGS}
        gl={DEFAULT_GL_SETTINGS}>
        <hemisphereLight name="Default light" args={[0xffffff, 0xaaaaaa, 2.25]} position={[0, 0, 0]} />
        <Grid showAxes={!hasLoadingScreen} />
        {layerGroups.length > 0 && (
          <ViewportContent
            key={modelId}
            layerGroups={layerGroups}
            geometryInfo={geometryInfo}
            hiddenLayers={hiddenLayers}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        )}
      </Canvas>
      {hasLoadingScreen && <BufferLoadingScreen />}
    </>
  );
};
