import './styles.scss';

export const AssignButton = ({
  isSelected,
  show,
  isHighlighted,
  label,
  onAssign,
}: {
  isSelected: boolean;
  show: boolean;
  isHighlighted: boolean;
  label: string;
  onAssign: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <button
      className={`assign-button ${show || isHighlighted ? '' : 'hide'} ${isSelected ? 'selected' : ''} ${
        isHighlighted ? 'highlighted' : ''
      }`}
      name={label}
      aria-label={label}
      onClick={onAssign}
      disabled={isSelected}>
      {isSelected ? <span className="checkmark-icon"></span> : 'Assign'}
    </button>
  );
};
