import 'react-multi-email/dist/style.css';

import { TrblTooltip } from '@/components/Shared';
import { LinkButton, PrimaryButton } from '@/components/Shared/Buttons';
import { LoadingSpinner } from '@/components/Shared/LoadingSpinner';
import { MultiEmailInput } from '@/components/Shared/MultiEmailInput';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TrblWarningIcon } from '@/components/Icons';
import { DisclaimerText } from './DisclaimerText';
import { ExpiryDateControls } from './ExpiryDateControls';
import { ShareList } from './ShareList';

import { useShareAuralizationPreset } from '../hooks';

import classes from './styles.module.scss';

const POPUP_TITLE = 'Share auralization';

type SavePresetPopupProps = {
  presetId: string;
  onClose: () => void;
};

export const SharePresetPopup: React.FC<SavePresetPopupProps> = ({ presetId, onClose }) => {
  const {
    shareDetails,
    isLoading,
    isMutating,
    newEmails,
    dateToday,
    expiresAt,
    expiresAtError,
    expiresAtEditMode,
    setNewEmails,
    setExpiresAt,
    setExpiresAtEditMode,
    setExpiresAtError,
    handleSend,
    handleCopyLink,
    handleSaveNewExpiresAt,
    handleCancelNewExpiresAt,
    handleDeleteShare,
    handleResendEmail,
    handleStopSharing,
  } = useShareAuralizationPreset(presetId);

  return (
    <TrblPopup
      width="480px"
      hideBackdrop={false}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px' }}
      onClose={onClose}
      open={true}>
      <TrblPopupTitle onClose={onClose}>{POPUP_TITLE}</TrblPopupTitle>
      <TrblPopupContent>
        {isMutating || isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={classes['email-share']}>
              <MultiEmailInput disabled={expiresAtEditMode} emails={newEmails} onChange={setNewEmails} />
              <PrimaryButton
                label={'Send'}
                disabled={!(newEmails.length > 0 && !expiresAtError && !expiresAtEditMode)}
                onClick={handleSend}
                style={{ width: 'fit-content' }}
              />
            </div>
            <DisclaimerText />
            <ShareList
              emails={shareDetails?.emails ?? []}
              hasAnonymousLink={!!shareDetails?.anonymousUrl}
              disabled={expiresAtEditMode}
              onDeleteShare={handleDeleteShare}
              onResendEmail={handleResendEmail}
              onCopyAnonymousLink={handleCopyLink}
            />
          </>
        )}
      </TrblPopupContent>
      {!isLoading ? (
        <TrblPopupActions>
          <div className={classes['popup-footer']}>
            <ExpiryDateControls
              shareId={shareDetails?.id}
              date={expiresAt}
              setDate={setExpiresAt}
              hasError={expiresAtError}
              setHasError={setExpiresAtError}
              isInEditMode={expiresAtEditMode}
              setIsInEditMode={setExpiresAtEditMode}
              onSave={handleSaveNewExpiresAt}
              onCancel={handleCancelNewExpiresAt}
            />
            {expiresAt !== null && dateToday.isBefore(expiresAt) && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                {shareDetails?.id && (
                  <LinkButton
                    icon={'stop'}
                    disabled={expiresAtEditMode}
                    label="Stop sharing"
                    onClick={handleStopSharing}
                  />
                )}
                <LinkButton
                  icon={'link'}
                  disabled={expiresAtEditMode}
                  label={shareDetails?.anonymousUrl ? 'Copy link' : 'Generate link'}
                  color="#00F5BA"
                  hoverColor="#02cc9b"
                  onClick={handleCopyLink}
                />
              </div>
            )}
            {expiresAt !== null && dateToday.isAfter(expiresAt) && (
              <TrblTooltip title="Set a new expiry date if you want to activate this auralization again">
                <div className={classes['popup-footer-warning']}>
                  <TrblWarningIcon />
                  <p> Shared auralization has expired </p>
                </div>
              </TrblTooltip>
            )}
          </div>
        </TrblPopupActions>
      ) : null}
    </TrblPopup>
  );
};
