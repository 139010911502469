import { useMemo } from 'react';
import { Data } from 'plotly.js';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { AVAILABLE_FREQUENCY_BANDS_LABELS } from '../../constants';

import { getWeightedRelativeSplPerBand, getWeightedSplPerBand } from '../utils';
import { roundFloat } from '@/utils/trebleFunctions';

import { SPLType } from '../types';

import colors from '@/theme/colors.module.scss';

type UseSplPerBandPlotDataProps = {
  data: ReflectionDetails | null;
  selectedFrequencyIndex: number | null;
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedSplType: SPLType;
};

export const useSplPerBandPlotData = ({
  data,
  selectedFrequencyIndex,
  selectedFrequencyWeighting,
  selectedSplType,
}: UseSplPerBandPlotDataProps): Data[] => {
  return useMemo(() => {
    if (!data) {
      return AVAILABLE_FREQUENCY_BANDS_LABELS.map((frequency) => ({
        x: [frequency],
        y: [0],
        type: 'bar',

        text: '',
        hoverinfo: 'text',
        textposition: 'none',
      }));
    }

    let splPerBand: number[] = [];

    if (selectedSplType === 'actual') {
      splPerBand = getWeightedSplPerBand(data, selectedFrequencyWeighting);
    } else {
      splPerBand = getWeightedRelativeSplPerBand(data, selectedFrequencyWeighting);
    }

    return AVAILABLE_FREQUENCY_BANDS_LABELS.map((frequency, index) => {
      const yValue = [splPerBand[index]];
      const color = selectedFrequencyIndex === index ? '#B8C7FF' : colors.plotBlue;
      const roundedSPL = roundFloat(splPerBand[index], 2);

      return {
        x: [frequency],
        y: yValue,
        type: 'bar',
        marker: {
          color,
          line: {
            color,
          },
        },
        text: `SPL at ${frequency} Hz: ${roundedSPL} db`,
        hoverinfo: 'text',
        textposition: 'none',
      };
    });
  }, [data, selectedFrequencyIndex, selectedFrequencyWeighting, selectedSplType]);
};
