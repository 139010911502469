import { useMutation } from '@tanstack/react-query';

import { SourceDefinition } from '@/types';

import axios from '@/axios';

const revokeShareSourceDefinition = async (sourceDefinitionId: string): Promise<SourceDefinition> => {
  const { data } = await axios.patch(
    `/api/SourceDefinition/RevokeShareSourceDefinition?sourceDefinitionId=${sourceDefinitionId}`
  );
  return data;
};

export const useRevokeShareSourceDefinition = () => {
  return useMutation(async (sourceDefinitionId: string) => await revokeShareSourceDefinition(sourceDefinitionId));
};
