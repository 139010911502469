/* eslint-disable no-constant-condition */
import { FC, useMemo } from 'react';
import { RestartAlt } from '@mui/icons-material';

import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { ActionType, FrequencyWeightingType } from '@/components/Results/context/ReflectogramResultsContext/types';
import { FilterItem } from './FilterItem';
import { ReflectionInfo } from './ReflectionInfo';
import { ReflectionSelector } from './ReflectionSelector';
import { TimeScaleGroup } from './TimeScaleGroup';

import { useReflectionData, useReflectogramKeyPressHandlers } from './hooks';

import { AVAILABLE_FREQUENCY_BANDS } from '../../Reflectogram/constants';
import { FREQUENCY_OPTIONS, FREQUENCY_WEIGHTING_OPTIONS, SCALE_OPTIONS, TIME_OF_ARRIVAL_SCALES } from './constants';

import { getRelativeSplValue, getSPLUnit, getSplValue } from '../../Reflectogram/utils';
import { roundFloat } from '@/utils/trebleFunctions';

import styles from './styles.module.scss';

export const ReflectogramResultsHeader: FC = () => {
  const {
    state: {
      reflectionsDataDownloadInfo,
      relativeAndFilteredData,
      selectedFrequencyBandIndex,
      selectedFrequencyWeighting,
      selectedScale,
      selectedTimeOfArrivalGroupIndexes,
      selectedReflectionIndex,
    },
    dispatch,
  } = useReflectogramResultsContext();

  useReflectionData(reflectionsDataDownloadInfo?.downloadUrl ?? null, reflectionsDataDownloadInfo?.id ?? null);
  const { handleSelectNextIndex, handleSelectPreviousIndex } = useReflectogramKeyPressHandlers(selectedReflectionIndex);

  const handleFrequencyWeightingSelected = (weighting: FrequencyWeightingType) => {
    dispatch({
      type: ActionType.SET_SELECTED_FREQUENCY_WEIGHTING,
      frequencyWeighting: weighting,
    });
  };

  const handleFrequencySelected = (frequency: string) => {
    let updatedFrequencyBandIndex: number | null = null;
    if (frequency !== 'Total') {
      updatedFrequencyBandIndex = AVAILABLE_FREQUENCY_BANDS.indexOf(Number(frequency));
    }

    dispatch({
      type: ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX,
      frequencyBandIndex: updatedFrequencyBandIndex === -1 ? null : updatedFrequencyBandIndex,
    });
  };

  const handleScaleSelected = (scale: string) => {
    dispatch({
      type: ActionType.SET_SELECTED_SCALE,
      scale,
    });

    const timeOfArrivalScale = TIME_OF_ARRIVAL_SCALES.find((x) => x.id === scale);

    dispatch({
      type: ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES,
      indexes: timeOfArrivalScale?.timeOfArrivalGroups.map((_, index) => index) ?? [],
    });
  };

  const handleTimeOfArrivalGroupToggle = (index: number, isDoubleClick: boolean = false) => {
    let updatedIndexes: number[] = [];

    if (isDoubleClick) {
      updatedIndexes = [index];
    } else {
      const isEnabled = selectedTimeOfArrivalGroupIndexes.includes(index);
      updatedIndexes = isEnabled
        ? selectedTimeOfArrivalGroupIndexes.filter((x) => x !== index)
        : [...selectedTimeOfArrivalGroupIndexes, index];
    }

    dispatch({
      type: ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES,
      indexes: updatedIndexes,
    });
  };

  const handleResetFilters = () => {
    dispatch({
      type: ActionType.RESET_SELECTED_FILTERS,
    });
  };

  const timeOfArrivalGroups = useMemo(
    () => TIME_OF_ARRIVAL_SCALES.find((x) => x.id === selectedScale)?.timeOfArrivalGroups ?? [],
    [selectedScale]
  );

  const getReflectionOrder = () =>
    selectedReflectionIndex !== null ? relativeAndFilteredData[selectedReflectionIndex].order : '-';

  const getReflectionSPL = () => {
    if (selectedReflectionIndex !== null) {
      const reflection = relativeAndFilteredData[selectedReflectionIndex];
      const splValue = getSplValue(reflection, selectedFrequencyWeighting, selectedFrequencyBandIndex);
      return `${roundFloat(splValue, 2)} ${getSPLUnit(selectedFrequencyWeighting)}`;
    }
    return '-';
  };

  const getReflectionSPLRelative = () => {
    if (selectedReflectionIndex !== null) {
      const reflection = relativeAndFilteredData[selectedReflectionIndex];

      const splValue = getRelativeSplValue(reflection, selectedFrequencyWeighting, selectedFrequencyBandIndex);

      return `${roundFloat(splValue, 2)} ${getSPLUnit(selectedFrequencyWeighting)}`;
    }
    return '-';
  };

  const getReflectionTime = () =>
    selectedReflectionIndex != null
      ? `${roundFloat(relativeAndFilteredData[selectedReflectionIndex].timeOfArrival * 1000, 2)} ms`
      : '-';

  const getReflectionTimeRelative = () =>
    selectedReflectionIndex != null
      ? `${roundFloat(relativeAndFilteredData[selectedReflectionIndex].timeOfArrivalRelative * 1000, 2)} ms`
      : '-';

  const getReflectionAzimuthAngle = () =>
    selectedReflectionIndex != null
      ? `${roundFloat(relativeAndFilteredData[selectedReflectionIndex].azimuthRelative, 2)}°`
      : '-';

  const getReflectionElevationAngle = () =>
    selectedReflectionIndex != null
      ? `${roundFloat(relativeAndFilteredData[selectedReflectionIndex].elevationRelative, 2)}°`
      : '-';

  const getReflectionDistance = () =>
    selectedReflectionIndex != null
      ? `${roundFloat(relativeAndFilteredData[selectedReflectionIndex].distance, 2)} m`
      : '-';

  const controlsDisabled = reflectionsDataDownloadInfo === null;

  return (
    <div className={styles['reflectogram-results-header']}>
      <div className={styles['left-container']}>
        <div className={styles['filters']}>
          <FilterItem
            label="Freq. weighting"
            disabled={controlsDisabled || relativeAndFilteredData?.[0]?.splAWeighted === undefined}
            menuItems={FREQUENCY_WEIGHTING_OPTIONS}
            value={selectedFrequencyWeighting}
            setValue={(value) => handleFrequencyWeightingSelected(value as FrequencyWeightingType)}
            minWidth={'120px'}
          />
          <FilterItem
            label="Frequency"
            disabled={controlsDisabled}
            menuItems={FREQUENCY_OPTIONS}
            value={
              selectedFrequencyBandIndex !== null
                ? AVAILABLE_FREQUENCY_BANDS[selectedFrequencyBandIndex]?.toString()
                : 'Total'
            }
            setValue={handleFrequencySelected}
          />
          <FilterItem
            label="Scale"
            disabled={controlsDisabled}
            menuItems={SCALE_OPTIONS}
            value={selectedScale}
            setValue={handleScaleSelected}
          />
          <div className={styles['arrival-time-groups']}>
            <div className={styles['label']}>Arrival time groups</div>
            <div className={styles['time-scale-groups-container']}>
              <div className={styles['time-scale-groups']}>
                {timeOfArrivalGroups.map((group, index) => (
                  <TimeScaleGroup
                    key={`${selectedScale}_${group.color}`}
                    color={group.color}
                    isSelected={selectedTimeOfArrivalGroupIndexes.includes(index)}
                    disabled={controlsDisabled}
                    lowerLimit={group.range[0]}
                    upperLimit={group.range[1]}
                    onClick={() => handleTimeOfArrivalGroupToggle(index)}
                    onDoubleClick={() => handleTimeOfArrivalGroupToggle(index, true)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['right-container']}>
        {!controlsDisabled ? (
          <ReflectionSelector
            selectedReflectionIndex={selectedReflectionIndex}
            totalCount={relativeAndFilteredData.length}
            onSelectNextReflection={handleSelectNextIndex}
            onSelectPreviousReflection={handleSelectPreviousIndex}
          />
        ) : (
          <ReflectionInfo label="Reflection" value={'-'} minWidth="95px" />
        )}
        <div className={styles['reflection-info']}>
          <ReflectionInfo label="Order" value={getReflectionOrder()} />
          <ReflectionInfo label="SPL" value={getReflectionSPL()} minWidth="60px" />
          <ReflectionInfo label="SPL (Rel. direct)" value={getReflectionSPLRelative()} />
          <ReflectionInfo label="Time" value={getReflectionTime()} minWidth="60px" />
          <ReflectionInfo label="Time (Rel. direct)" value={getReflectionTimeRelative()} />
          <ReflectionInfo label="Azimuth" value={getReflectionAzimuthAngle()} minWidth="50px" />
          <ReflectionInfo label="Elevation" value={getReflectionElevationAngle()} minWidth="50px" />
          <ReflectionInfo label="Distance" value={getReflectionDistance()} />
        </div>
        <div className={styles['reset-button']}>
          <TrblTooltip title="Reset all filters">
            <span>
              <TrblIconButton
                size="small"
                disabled={controlsDisabled}
                onClick={handleResetFilters}
                icon={<RestartAlt fontSize="small" />}
              />
            </span>
          </TrblTooltip>
        </div>
      </div>
    </div>
  );
};
