import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Material } from '@/types';

import axios from '@/axios';

type UpdatedMaterial = {
  name: string;
  description: string;
  category: string;
};

type UpdateMaterialRequest = {
  materialId: string;
  updatedMaterial: UpdatedMaterial;
};

const updateMaterial = async (materialId: string, updatedMaterial: UpdatedMaterial): Promise<Material> => {
  const { data } = await axios.patch(`/api/Material/UpdateMaterial?materialId=${materialId}`, {
    ...updatedMaterial,
  });
  return data;
};

export const useUpdateMaterial = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UpdateMaterialRequest) => await updateMaterial(data.materialId, data.updatedMaterial),
    {
      onSuccess: (_, variables) => queryClient.invalidateQueries(['material', variables.materialId]),
      onError: () => {
        toast.error('An error occurred while updating the material.');
      },
    }
  );
};
