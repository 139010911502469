import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteMaterial = async (materialId: string): Promise<any> => {
  const { data } = await axios.patch(`/api/Material/DeleteMaterial?materialId=${materialId}`);
  return data;
};

export const useDeleteMaterial = () => {
  return useMutation(async (materialId: string) => await deleteMaterial(materialId));
};
