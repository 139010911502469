import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

const MAX_POLL = 500;

let timedOut = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSpaceExtractionTask = async (taskId: string | null, shouldPoll: boolean): Promise<any> => {
  if (!timedOut && shouldPoll && taskId) {
    const { data } = await axios.get(`/api/SpaceExtraction/GetSpaceExtractionTask?spaceExtractionTaskId=${taskId}`);

    return data;
  } else {
    if (timedOut) {
      const data = { task: { status: 'TimedOut' } };
      return data;
    } else {
      return { task: { status: 'WeirdError' } };
    }
  }
};

export const useGetSpaceExtractionTask = (taskId: string | null, shouldRefetch: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, boolean>(
    ['space-extraction-task-id', taskId],
    () => getSpaceExtractionTask(taskId, shouldRefetch),
    {
      enabled: !!taskId && shouldRefetch,
      refetchOnWindowFocus: false,
      // Refetch the data every 2 second
      refetchInterval: (data, query) => {
        if (query.state.dataUpdateCount > MAX_POLL) {
          timedOut = true;
        } else if (
          data?.task.status === 'Completed' ||
          data?.task.status === 'Error' ||
          data?.task.status === 'TimedOut'
        ) {
          return false;
        }

        return 4000;
      },
      onError: () => {
        toast.error('Error occurred while fetching geometry task');
      },
    }
  );
};
