import { TokenParams } from '../types';

export const getTokenParams = (tokenType: string): TokenParams => {
  return {
    item_prices: [
      {
        item_price_id: tokenType,
        quantity: 10,
        item_type: 'addon',
      },
    ],
  };
};
