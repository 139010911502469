import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAddMemberIcon: FC<IconProps> = ({ fill = '#39BD9D', width = '24', height = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible">
    <rect width="24" height="24" rx="12" fill={fill} />
    <path
      d="M11.1539 10.6154C12.4284 10.6154 13.4616 9.58219 13.4616 8.30769C13.4616 7.03319 12.4284 6 11.1539 6C9.87938 6 8.84619 7.03319 8.84619 8.30769C8.84619 9.58219 9.87938 10.6154 11.1539 10.6154Z"
      stroke="#313131"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5385 17.077H7V16.5766C7.00735 15.8731 7.1929 15.1828 7.53933 14.5704C7.88576 13.958 8.38175 13.4434 8.98099 13.0746C9.58023 12.7058 10.2632 12.495 10.966 12.4617C11.6688 12.4284 12.3686 12.5738 13 12.8843"
      stroke="#313131"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.231 12.4619V18.0004" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.4619 15.2305H19.0004" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
