import { useQuery } from '@tanstack/react-query';

import { SpaceDto } from '@/types';

import axios from '@/axios';

const getSpaces = async () => {
  const { data } = await axios.get(`/api/Space/GetSpaces`);

  return data;
};

export const useGetSpaces = (enabled = true) => {
  const query = useQuery<SpaceDto[], boolean>(['spaces'], () => getSpaces(), {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

  return query;
};
