import { FC, useState } from 'react';

import { Text } from '@/components/Shared/Text';
import { IconTypes, TrblIcon } from '@/components/Icons';

import styles from './linkButton.module.scss';

type LinkButtonProps = {
  label: string;
  icon?: IconTypes;
  disabled?: boolean;
  onClick: () => void;
  color?: string;
  hoverColor?: string;
  iconColor?: string;
  iconHoverColor?: string;
  style?: React.CSSProperties;
};

export const LinkButton: FC<LinkButtonProps> = ({
  label,
  icon,
  onClick,
  disabled = false,
  color = '#DADADA',
  hoverColor = '#DADADACC',
  iconColor,
  iconHoverColor,
  style,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`${styles['link-button']} ${disabled ? styles['disabled'] : ''}`}
      onMouseEnter={!disabled ? () => setIsHovered(true) : undefined}
      onMouseLeave={!disabled ? () => setIsHovered(false) : undefined}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      style={style}>
      {icon && <TrblIcon icon={icon} color={!isHovered ? iconColor ?? color : iconHoverColor ?? hoverColor} />}
      <Text type="medium-11px" color={!isHovered ? color : hoverColor}>
        {label}
      </Text>
    </button>
  );
};
