import { FREQUENCY_RANGE_THIRD_OCTAVE_NUM, FULL_OCTAVES } from '../constants';

import { ComplexData, Frequency } from '../types';

export const parseCSV = (input: string): ComplexData => {
  const lines = input.split(/\r?\n/); // Split the input into lines
  const result: ComplexData = {}; // Initialize an empty object to hold the result

  lines.forEach((line) => {
    const [freq, real, imag] = line.trim().split(';');
    const newFreq = freq ? parseFloat(freq.replace(',', '.')) : null;
    const newReal = real ? parseFloat(real.replace(',', '.')) : 0;
    const newImag = imag ? parseFloat(imag.replace(',', '.')) : 0;
    if (
      newFreq &&
      (FULL_OCTAVES.includes(newFreq as Frequency) || FREQUENCY_RANGE_THIRD_OCTAVE_NUM.includes(newFreq))
    ) {
      result[newFreq] = { real: newReal, imag: newImag }; // Parse the key and values, then add to the result object
    }
  });

  return result; // Return the resulting object
};
