import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextField } from '@/components/Shared/TextField';

import { useSubmitEvent, useUpdateModelBase } from '@/hooks';

import { ModelBaseDto } from '@/types';

interface PopupModelCardProps {
  showPopup: boolean;
  onClose?: () => void;
  onUpdate?: (response: ModelBaseDto) => void;
  name: string;
  id: string;
}
export const PopupModelCard = ({ showPopup = false, onClose, onUpdate, name, id }: PopupModelCardProps) => {
  const [nameNew, setName] = useState<string>(name);
  const [isFormValid, setIsFormValid] = useState(true);

  const { mutate: updateModelBase } = useUpdateModelBase();

  useSubmitEvent(isFormValid, [name]);

  useEffect(() => {
    if (showPopup) {
      setName(name);
    }
  }, [showPopup]);

  // Form validation
  useEffect(() => {
    if (nameNew && nameNew.length > 0 && nameNew !== name) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [nameNew, name]);

  const handleSubmitUpdate = () => {
    updateModelBase(
      {
        id: id,
        name: nameNew,
      },
      {
        onSuccess: (response: ModelBaseDto) => {
          if (onUpdate) onUpdate(response);
          toast.info("'" + response.name + "' updated");
        },
        onError: () => {
          toast.error('An error occurred while updating Model');
        },
      }
    );
  };

  return (
    <>
      {showPopup && nameNew != undefined && (
        <TrblPopup hideBackdrop={false} aria-labelledby={'Edit model'} sx={{ fontSize: '12px' }} open={true}>
          <form>
            <TrblPopupTitle onClose={onClose}>{'Edit model'}</TrblPopupTitle>
            <TrblPopupContent>
              <Stack gap={1}>
                <TextField autoFocus maxLength={128} placeholder="Name" value={nameNew} onChange={setName} />
              </Stack>
            </TrblPopupContent>
            <TrblPopupActions>
              <Box component={'div'} display="flex" justifyContent="flex-end" width={'100%'}>
                <SecondaryButton
                  type="submit"
                  disabled={!isFormValid}
                  width={'auto'}
                  label="Update"
                  onClick={handleSubmitUpdate}
                />
              </Box>
            </TrblPopupActions>
          </form>
        </TrblPopup>
      )}
    </>
  );
};
