import { SourceDefinition } from '@/types';

export const filterByCategories = (
  sourceDefinitions: SourceDefinition[],
  categoryFilters: string[],
  manufacturersFilter: string[],
  subCategoriesFilter: string[],
  userId: string | undefined
) => {
  const defaultFilter = categoryFilters.includes('Default');
  const userCreatedFilter = categoryFilters.includes('Created by me');
  const sharedWithOrganizationFilter = categoryFilters.includes('Organization');

  const restOfFilters = categoryFilters.filter(
    (group) => group !== 'Default' && group !== 'Created by me' && group !== 'Organization'
  );
  return sourceDefinitions.filter((sourceDefinition: SourceDefinition) => {
    const isManufacturerMatch =
      manufacturersFilter.length === 0 || manufacturersFilter.includes(sourceDefinition.manufacturer);
    const isSubCategoryMatch =
      subCategoriesFilter.length === 0 ||
      subCategoriesFilter.map((s) => s.toLowerCase()).includes(sourceDefinition.subCategory.toLowerCase());
    const isCategoryMatch =
      categoryFilters.length === 0 ||
      restOfFilters.map((s) => s.toLowerCase()).includes(sourceDefinition.category.toLowerCase());

    if (isManufacturerMatch && isSubCategoryMatch && isCategoryMatch) {
      if (
        (userCreatedFilter && sourceDefinition.isUserCreated && userId === sourceDefinition.userId) ||
        (sharedWithOrganizationFilter && sourceDefinition.isSharedWithOrganization) ||
        (defaultFilter && !sourceDefinition.isUserCreated && !sourceDefinition.isSharedWithOrganization)
      ) {
        return true;
      } else if (!userCreatedFilter && !sharedWithOrganizationFilter && !defaultFilter) {
        return true;
      }
    } else if (
      restOfFilters.length === 0 &&
      ((userCreatedFilter && sourceDefinition.isUserCreated && userId === sourceDefinition.userId) ||
        (sharedWithOrganizationFilter && sourceDefinition.isSharedWithOrganization) ||
        (defaultFilter && !sourceDefinition.isUserCreated && !sourceDefinition.isSharedWithOrganization))
    ) {
      if (isManufacturerMatch && isSubCategoryMatch) {
        return true;
      }
    }

    return false;
  });
};
