import { FC, useState } from 'react';

import { TrblCollapseIcon, TrblExpandIcon } from '../Icons';
import { TabButtons, TrblTooltip } from '../Shared';

import styles from './styles.module.scss';

type ViewTypeSwitchProps = {
  viewType: string;
  setViewType: (viewType: string) => void;
  minimized?: boolean;
  setMinimized?: (value: boolean) => void;
};

export const ViewTypeSwitch: FC<ViewTypeSwitchProps> = ({ viewType, setViewType, minimized, setMinimized }) => {
  const [showTooltip, setShowTooltip] = useState(true);

  return (
    <div className={styles['view-type-switch']}>
      {setMinimized && (
        <TrblTooltip title={minimized ? 'Expand all projects' : 'Collapse all projects'} hidden={!showTooltip}>
          <button
            onClick={() => setMinimized(!minimized)}
            onMouseDown={() => setShowTooltip(false)}
            onMouseLeave={() => setTimeout(() => setShowTooltip(true), 100)}>
            {minimized ? <TrblCollapseIcon /> : <TrblExpandIcon />}
          </button>
        </TrblTooltip>
      )}

      <div style={{ width: '56px' }}>
        <TabButtons
          options={[
            { key: 'list', icon: 'listView', tooltip: 'Switch to list view' },
            { key: 'cards', icon: 'cardsView', tooltip: 'Switch to card view' },
          ]}
          selectedValue={viewType}
          onChange={setViewType}
          height={26}
          small
          dark
        />
      </div>
    </div>
  );
};
