import { useQuery } from '@tanstack/react-query';

import { UserBasicDto } from '@/types';

import axios from '@/axios';

const getMyOrganizationUsers = async () => {
  const { data } = await axios.get(`/api/Organization/GetMyOrganizationUsers`);

  return data;
};

export const useGetMyOrganizationUsers = (enabled = true) => {
  const query = useQuery<UserBasicDto[], boolean>(['my-org-users'], () => getMyOrganizationUsers(), {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

  return query;
};
