import { useThree } from '@react-three/fiber';

import { useEditorContext } from '@/context/EditorContext';

import { PointAuralizer } from './PointAuralizer';

export const PointsAuralizer = () => {
  const { receivers, sources, coordinates } = useEditorContext();
  const { size } = useThree();

  return (
    coordinates && (
      <>
        {sources.map((s, index) =>
          s.x !== undefined && s.y !== undefined && s.z !== undefined ? (
            <PointAuralizer
              key={s.id}
              id={s.id}
              index={index}
              type="SourcePoint"
              x={s.x}
              y={s.y}
              z={s.z}
              originPoint={coordinates[0]}
              canvasSize={size}
            />
          ) : null
        )}
        {receivers.map((r, index) =>
          r.x !== undefined && r.y !== undefined && r.z !== undefined ? (
            <PointAuralizer
              key={r.id}
              id={r.id}
              index={index}
              type="ReceiverPoint"
              x={r.x}
              y={r.y}
              z={r.z}
              originPoint={coordinates[0]}
              canvasSize={size}
            />
          ) : null
        )}
      </>
    )
  );
};
