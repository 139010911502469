import { ReactNode } from 'react';

import { TrblSearchIcon } from '@/components/Icons';

import style from '../styles.module.scss';

export const SearchInputWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className={style.search_input_wrapper}>
      <span className={style.search_icon}>
        <TrblSearchIcon />
      </span>
      {children}
    </div>
  );
};
