import { useQuery } from '@tanstack/react-query';

import { SimulationRunDto } from '@/types';

import axios from '@/axios';

export const getSimulationRunById = async (simulationRunId: string): Promise<SimulationRunDto> => {
  const { data } = await axios.get<SimulationRunDto>(`/api/SimulationRun/GetSimulationRunById`, {
    params: {
      simulationRunId,
    },
  });

  return data;
};

export const useGetSimulationRunById = (simulationRunId: string, enabled: boolean = true) => {
  return useQuery<SimulationRunDto>(['simulationRun', simulationRunId], () => getSimulationRunById(simulationRunId), {
    enabled,
    refetchOnWindowFocus: false,
  });
};
