import { IconProps } from '@/types';

export const TrblLoadIcon = ({ fill = '#DADADA' }: IconProps) => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2221 8.48536C12.8317 8.48536 12.5151 8.78923 12.5151 9.16408V10.2029C12.5145 10.6884 12.1045 11.0818 11.5987 11.0821H2.40073C1.89497 11.0818 1.48496 10.6884 1.48431 10.2029V9.16408C1.48431 8.78923 1.16778 8.48536 0.777313 8.48536C0.386847 8.48536 0.0703125 8.78923 0.0703125 9.16408V10.2029C0.0703115 11.4389 1.1132 12.4413 2.40073 12.4429H11.5987C12.8874 12.4429 13.9321 11.44 13.9321 10.2029V9.16408C13.9321 8.98358 13.8572 8.81052 13.724 8.68316C13.5907 8.55579 13.4102 8.48461 13.2221 8.48536Z"
      fill={fill}
    />
    <path
      d="M6.99994 9.51172C7.62462 9.51172 8.13103 9.02557 8.13103 8.42588V4.34892C8.13103 4.2716 8.19632 4.20892 8.27686 4.20892H9.82794C10.0495 4.20883 10.2507 4.08458 10.3426 3.89101C10.4345 3.69744 10.4002 3.47018 10.2549 3.30956L7.42694 0.186999C7.31958 0.0681753 7.16379 -8.01086e-05 6.99994 -8.01086e-05C6.83609 -8.01086e-05 6.68031 0.0681753 6.57294 0.186999L3.74494 3.30956C3.59965 3.47018 3.5654 3.69744 3.6573 3.89101C3.7492 4.08458 3.95035 4.20883 4.17194 4.20892H5.72303C5.80357 4.20892 5.86886 4.2716 5.86886 4.34892L5.86886 8.42588C5.86886 9.02557 6.37526 9.51172 6.99994 9.51172Z"
      fill={fill}
    />
  </svg>
);
