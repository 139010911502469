import { roundFloat } from '@/utils/trebleFunctions';

import { ReflectionDetails, ReflectionDetailsWithRelativeAngles, SelectedReceiverDirection } from './types';

/**
 * Normalizes an azimuth difference to the range [-180, 180).
 */
export const normalizeAzimuthDifference = (azimuthDifference: number): number => {
  // Normalize azimuth difference to the range [-180, 180).
  // If the difference is greater than 180, adjust it to a negative equivalent.
  // If the difference is less than -180, adjust it to a positive equivalent.

  if (azimuthDifference > 180) azimuthDifference -= 360;
  else if (azimuthDifference < -180) azimuthDifference += 360;
  return azimuthDifference;
};

/**
 * Calculates the elevation relative to the base elevation, considering the azimuth.
 */
export const calculateElevationRelative = (
  baseElevation: number,
  reflectionElevation: number,
  azimuthRelative: number
): number => {
  // Calculate the elevation difference between the base and reflection. A positive value means the reflection is above the base.
  const elevationDifference = reflectionElevation - baseElevation;

  // Constants for azimuth threshold and elevation correction.
  const AZIMUTH_THRESHOLD = 90;
  const ELEVATION_CORRECTION = 180;

  // If the relative azimuth is beyond the front-facing range, invert the elevation difference to simulate the reflection's relative elevation.
  if (azimuthRelative > AZIMUTH_THRESHOLD || azimuthRelative < -AZIMUTH_THRESHOLD) {
    return elevationDifference >= 0
      ? ELEVATION_CORRECTION - elevationDifference
      : -ELEVATION_CORRECTION - elevationDifference;
  }

  // Return the elevation difference if within the front-facing range.
  return elevationDifference;
};

export const calculateRelativeAngles = (
  data: ReflectionDetails[],
  selectedReceiverDirection: SelectedReceiverDirection
): ReflectionDetailsWithRelativeAngles[] => {
  return data.map((d) => {
    // Calculate azimuth difference and normalize it.
    const azimuthDifference = selectedReceiverDirection.azimuth - roundFloat(d.azimuth, 3);
    const azimuthRelative = normalizeAzimuthDifference(azimuthDifference);

    // Calculate relative elevation considering the azimuth difference.
    const elevationRelative = calculateElevationRelative(
      selectedReceiverDirection.elevation,
      roundFloat(d.elevation, 3),
      azimuthRelative
    );
    return { ...d, azimuthRelative, elevationRelative };
  });
};
