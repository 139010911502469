import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type NewSourceDefinition = {
  description: string;
  category: string | null;
  subCategory: string | null;
  fileUploadId: string;
  correctIr: boolean;
  isSharedWithOrganization: boolean;
};

const startSourceDefinitionImport = async (newSourceDefinition: NewSourceDefinition): Promise<string> => {
  const createSourceDefinitionData = {
    description: newSourceDefinition.description || '',
    category: newSourceDefinition.category,
    subCategory: newSourceDefinition.subCategory,
    inputFileUploadId: newSourceDefinition.fileUploadId,
    correctIrByOnAxisSplDefault: newSourceDefinition.correctIr,
    isSharedWithOrganization: newSourceDefinition.isSharedWithOrganization,
  };

  const { data } = await axios.post(`/api/SourceDefinition/StartSourceDefinitionImport`, createSourceDefinitionData);
  return data.id;
};

export const useStartSourceDefinitionImport = () => {
  return useMutation(
    async (newSourceDefinition: NewSourceDefinition) => await startSourceDefinitionImport(newSourceDefinition),
    {
      onError: () => {
        toast.error('An error occurred while importing the source definition. Please refresh the browser.');
      },
    }
  );
};
