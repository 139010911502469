import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { useFeatureFlags } from '../FeatureToggles';
import { TrblToggle, TrblTooltip } from '../Shared';
import { TrblNumberInput } from '../Shared/NumberInput';

import { CORRECT_IR_SPL_TEXT, USE_SPL_AS_STI_TEXT } from './constants';

import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';

type SourceDefinitionFooterProps = {
  source?: Source;
  sourceDefinition?: SourceDefinition;
  onCorrectIrChange?: (value: boolean) => void;
  onUseSplForStiChange?: (value: boolean) => void;
  onIrTimeDelayChange?: (value: number) => void;
  readonly?: boolean;
};

export const SourceDefinitionFooter = ({
  source,
  sourceDefinition,
  onCorrectIrChange,
  onUseSplForStiChange,
  onIrTimeDelayChange,
  readonly = false,
}: SourceDefinitionFooterProps) => {
  const [correctIr, setCorrectIr] = useState(
    source?.params.correctIrByOnAxisSpl ?? sourceDefinition?.correctIrByOnAxisSplDefault ?? false
  );
  const [irTimeDelay, setIrTimeDelay] = useState<number | undefined>(source?.params.irTimeDelay ?? 0);
  const useSplForSti = false;

  const { sourceSumming } = useFeatureFlags();

  const showIrTimeDelay = sourceSumming && source; // Only show the IR input if we are in the source settings and source summing is enabled

  useEffect(() => {
    setCorrectIr(source?.params.correctIrByOnAxisSpl ?? sourceDefinition?.correctIrByOnAxisSplDefault ?? false);
  }, [source, sourceDefinition]);

  useEffect(() => {
    if (correctIr !== source?.params.correctIrByOnAxisSpl && onCorrectIrChange) {
      onCorrectIrChange(correctIr);
    }
  }, [correctIr]);

  useEffect(() => {
    if (useSplForSti !== source?.params.useSplForSti && onUseSplForStiChange) {
      onUseSplForStiChange(useSplForSti);
    }
  }, [useSplForSti]);

  return (
    <Stack
      gap="30px"
      alignItems={'center'}
      justifyContent={showIrTimeDelay ? 'space-between' : 'flex-end'}
      direction="row"
      margin={'16px 0 0'}>
      {showIrTimeDelay && (
        <TrblNumberInput
          label={<Text type="regular-11px">Source delay</Text>}
          value={irTimeDelay}
          step={0.1}
          min={0}
          max={1}
          endAdornment="s"
          decimals={4}
          onChange={setIrTimeDelay}
          onBlur={(value) => {
            if (onIrTimeDelayChange && value !== undefined && source?.params.irTimeDelay !== value) {
              onIrTimeDelayChange(value);
            }
          }}
          alignment="center"
          readOnly={readonly}
        />
      )}
      <TrblTooltip title={<div style={{ whiteSpace: 'pre-line' }}>{CORRECT_IR_SPL_TEXT}</div>}>
        <Stack gap="8px" alignItems={'center'} direction="row">
          <Text type="regular-11px">Correct IR with on-axis SPL</Text>
          <TrblToggle
            id="irToggle"
            ariaLabel="Set correct IR with on-axis SPL toggle"
            checked={correctIr}
            disabled={!source || readonly}
            onChangeToggle={() => setCorrectIr(!correctIr)}
          />
        </Stack>
      </TrblTooltip>
      <TrblTooltip title={USE_SPL_AS_STI_TEXT}>
        <Stack gap="8px" alignItems={'center'} direction="row">
          <Text type="regular-11px">Use SPL as STI speech level</Text>
          <TrblToggle
            id="splToggle"
            ariaLabel="Set use SPL as STI speech level toggle"
            checked={false}
            disabled
            onChangeToggle={() => {}}
          />
        </Stack>
      </TrblTooltip>
    </Stack>
  );
};
