import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type ResendEmailInviteRequest = {
  sharedAuralizationId: string;
  email: string;
};

const resendEmailInvite = async (sharedAuralizationId: string, email: string): Promise<void> => {
  await axios.post(`/api/SharedAuralization/ResendEmailInvite`, null, {
    params: {
      sharedAuralizationId,
      email,
    },
  });
};

export const useResendEmailInvite = () => {
  return useMutation(
    async (request: ResendEmailInviteRequest) => await resendEmailInvite(request.sharedAuralizationId, request.email),
    {
      onError: () => {
        toast.error('An error occurred while sharing the auralization. Please refresh the browser.');
      },
    }
  );
};
