import { FC, useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { Box, Stack } from '@mui/material';

import { RadioButtonGroup } from '../Shared/RadioButton';
import { SolverSettingsForm } from './SolverSettingsForm';

import { nonWatertightMessage } from '@/utils/constants';

import { PresetTypes } from './types';
import { Simulation } from '@/types';

type SolverSettingsProps = {
  selectedSimulation: Simulation;
};

export type SolverSettingsFormState = {
  transitionFrequency?: number;
  impulseResponseLength: number;
  type: number;
  numberOfRays: number;
  imageSourceOrder: number;
};

export const SolverSettings: FC<SolverSettingsProps> = ({ selectedSimulation }) => {
  const { modelInformation } = useModelContext();
  const { readonly } = useEditorContext();
  const [selectedPreset, setSelectedPreset] = useState(
    selectedSimulation.settingsPreset === null ? PresetTypes.Default : selectedSimulation.settingsPreset
  );

  useEffect(() => {
    if (modelInformation?.nonWatertight) {
      setSelectedPreset(
        selectedSimulation.settingsPreset === null || selectedSimulation.settingsPreset === PresetTypes.Default
          ? PresetTypes.Advanced
          : selectedSimulation.settingsPreset
      );
    } else {
      setSelectedPreset(
        selectedSimulation.settingsPreset === null ? PresetTypes.Default : selectedSimulation.settingsPreset
      );
    }
  }, []);

  return (
    <Box component="div" padding="15px 20px" height={'100%'}>
      <Stack spacing="15px" height="100%">
        <RadioButtonGroup
          options={[
            {
              text: 'Survey',
              value: PresetTypes.Survey.toString(),
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Survey.toString(),
            },
            {
              text: 'Default',
              value: PresetTypes.Default.toString(),
              disabled: !!modelInformation?.nonWatertight,
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Default.toString(),
              tooltip: modelInformation?.nonWatertight ? nonWatertightMessage : '',
            },
            {
              text: 'Advanced',
              value: PresetTypes.Advanced.toString(),
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Advanced.toString(),
            },
          ]}
          selectedValue={selectedPreset.toString()}
          onChange={(value) => setSelectedPreset(Number(value))}
          horizontal={true}
        />

        <SolverSettingsForm presetType={selectedPreset} selectedSimulation={selectedSimulation} readonly={readonly} />
      </Stack>
    </Box>
  );
};
