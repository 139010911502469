import { Line } from '@react-three/drei';

import { LIGHT_GRAY_COLOR, scaledBoxWidth } from '../constants';

export const MeasurementLines = ({ width }: { width: number }) => {
  return (
    <>
      {/* Horizontal line */}
      <Line
        points={[
          [-scaledBoxWidth(width) / 2, -0.8, 2], // Start point
          [scaledBoxWidth(width) / 2, -0.8, 2], // End point
        ]}
        color={LIGHT_GRAY_COLOR}
        lineWidth={2}
      />
      {/* Vertical line at the start of the horizontal line */}
      <Line
        points={[
          [-scaledBoxWidth(width) / 2, -0.8, 1.93],
          [-scaledBoxWidth(width) / 2, -0.8, 2.06],
        ]}
        color={LIGHT_GRAY_COLOR}
        lineWidth={2}
      />
      {/* Vertical line at the end of the horizontal line */}
      <Line
        points={[
          [scaledBoxWidth(width) / 2, -0.8, 1.93],
          [scaledBoxWidth(width) / 2, -0.8, 2.06],
        ]}
        color={LIGHT_GRAY_COLOR}
        lineWidth={2}
      />
    </>
  );
};
