import { useEffect, useState } from 'react';
import { Data } from 'plotly.js';

import { convertPressureBasedImpulseResponseToDb, createPlotlyObject } from '../../../ResponsePlot/utils';

import { ParsedResponseData } from '../../../ResponsePlot/types';
import { IRType } from '../../SingleReflectionPlots/types';
import { ReceiverResults } from '@/types';

import colors from '@/theme/colors.module.scss';

type UseImpulseResponsePlotDataProps = {
  selectedIrType: IRType;
  receiverIrResult: ReceiverResults | null;
  irNormalization: number | null;
};

export const useImpulseResponsePlotData = ({
  selectedIrType,
  receiverIrResult,
  irNormalization,
}: UseImpulseResponsePlotDataProps) => {
  const [plotlyData, setPlotlyData] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<ParsedResponseData | null>(null);

  useEffect(() => {
    if (receiverIrResult !== null && irNormalization !== null) {
      createPlotlyObject(receiverIrResult, irNormalization, colors.plotBlue, '').then((plotlyObject) => {
        setResponseData(plotlyObject);
      });
    } else {
      setResponseData(null);
    }
  }, [receiverIrResult, irNormalization]);

  // If the parsed data changes or the selected ir type  (db or pressure)
  useEffect(() => {
    if (responseData !== null) {
      let yData = responseData.y;
      if (selectedIrType === 'db') {
        yData = convertPressureBasedImpulseResponseToDb(yData);
      }

      const newPlotlyData: Data = {
        marker: responseData.marker,
        y: yData,
        x: responseData.x,
        type: responseData.type,
        mode: responseData.mode,
        name: responseData.name,
      };

      setPlotlyData([newPlotlyData]);
    } else {
      setPlotlyData([]);
    }
  }, [responseData, selectedIrType]);

  return plotlyData;
};
