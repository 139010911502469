import { Text } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { BoxGeometry, Euler, RepeatWrapping, TextureLoader, Vector3 } from 'three';

import { MeasurementLines } from './MeasurementLines';
import { MeshWithColor } from './MeshWithColor';
import { MeshWithTexture } from './MeshWithTexture';
import { TiltedLineRight } from './TiltedLineRight';

import { BoxProps, getAdjustedXPos, GRAY_COLOR, scaledBoxWidth, TEXT_CONFIG } from '../constants';

export const PorousMaterialBox = ({ box, index }: { box: BoxProps; index: number }) => {
  const { color, textureUrl, width, height, depth, xPos } = box;
  const texture = useLoader(TextureLoader, textureUrl!);

  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.needsUpdate = true;
  texture.repeat.set(scaledBoxWidth(width), 1);

  const needsExtraSpace = width <= 8.9;

  const textConfig = {
    ...TEXT_CONFIG,
    rotation: new Euler(89.6, 0, 0),
    position: needsExtraSpace ? new Vector3(0.7, -0.8, 2.5) : new Vector3(0, -0.8, 2.3),
  };

  return (
    <group>
      <mesh position={new Vector3(getAdjustedXPos(xPos, index) || 0, 0, 0)}>
        <Text {...textConfig} anchorX="center" anchorY="top" textAlign="center" maxWidth={10}>
          {`${(width * 10).toFixed()} mm`}
        </Text>
        {needsExtraSpace ? <TiltedLineRight /> : null}
        <MeasurementLines width={width} />
        <boxGeometry args={[scaledBoxWidth(width), height, depth]} />
        <MeshWithColor color={GRAY_COLOR} index={0} />
        <MeshWithColor color={GRAY_COLOR} index={1} />
        <MeshWithTexture index={2} texture={texture} />
        <MeshWithTexture index={3} texture={texture} />

        <MeshWithColor color={GRAY_COLOR} index={4} />
        <MeshWithColor color={GRAY_COLOR} index={5} />

        <lineSegments>
          <edgesGeometry args={[new BoxGeometry(scaledBoxWidth(width), height, depth), 1]} />
          <lineBasicMaterial color={color} />
        </lineSegments>
      </mesh>
    </group>
  );
};
