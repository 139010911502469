import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '@/axios';

type UpdateSDKAccessRequest = {
  userId: string;
  organizationId: string;
};

const removeSDKProductAccess = async (userId: string) => {
  const response = await axios.patch(`/api/ApiUser/RemoveSDKProductAccess`, null, {
    params: {
      userId,
    },
  });

  return response;
};

export const useRemoveSDKProductAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(async (variables: UpdateSDKAccessRequest) => await removeSDKProductAccess(variables.userId), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['subscription', variables.organizationId]);
      queryClient.invalidateQueries(['product-accesses', variables.organizationId]);
    },
    onError: () => {
      toast.error('An error occurred while removing SDK access for user');
    },
  });
};
