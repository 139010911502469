import { useEffect, useState } from 'react';
import Slider, { sliderUnstyledClasses } from '@mui/base/SliderUnstyled';
import { alpha, styled } from '@mui/system';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblCoarseness, TrblSmallCoarseness } from '@/components/Icons';

import styles from './styles.module.scss';

type SpaceSlider = {
  onSelectingValue: (value: number) => void;
  min: number;
  max: number;
  value: number | undefined;
  isDisabled?: boolean;
};

export const SpaceSlider = ({ onSelectingValue, min, max, value, isDisabled }: SpaceSlider) => {
  const [sliderValue, setSliderValue] = useState(value);
  const [availableMarks, setAvailableMarks] = useState<{ value: number }[]>([]);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  useEffect(() => {
    const availableMarks = [];

    for (let i = min; i <= max; i += 0.5) {
      availableMarks.push({ value: i });
    }
    setAvailableMarks(availableMarks);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (event: any) => {
    const newValue = event?.target?.value;
    setSliderValue(newValue);
  };

  const onChangeCommitted = () => {
    onSelectingValue(sliderValue as number);
  };

  const decreaseSpace = () => {
    if (value && value >= min + min) {
      const newValue = value - min;
      setSliderValue(newValue);
      onSelectingValue(newValue);
    }
  };

  const increaseSpace = () => {
    if (value && value <= 5 - min) {
      const newValue = value + min;
      setSliderValue(newValue);
      onSelectingValue(newValue);
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <TrblIconButton
        disabled={isDisabled}
        className={styles.sliderIconLeft}
        onClick={decreaseSpace}
        label="Decrease coarseness"
        icon={<TrblSmallCoarseness />}></TrblIconButton>
      <StyledSlider
        aria-label="Step"
        defaultValue={1}
        value={sliderValue}
        getAriaValueText={valuetext}
        step={null}
        marks={availableMarks}
        min={min}
        max={max}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        disabled={isDisabled}
      />
      <TrblIconButton
        disabled={isDisabled}
        className={styles.sliderIconRight}
        onClick={increaseSpace}
        label="Increase coarseness"
        icon={<TrblCoarseness />}></TrblIconButton>
      <TrblTooltip title="Spacing">
        <span className={styles.sliderValue}> {sliderValue ? sliderValue : ''} m </span>
      </TrblTooltip>
    </div>
  );
};

const valuetext = (value: number) => {
  return `${value} m`;
};

const StyledSlider = styled(Slider)(
  () => `
  color: orange;
  height: 10px;
  width: 100%;
  padding: 14px 0;
  margin-right: 16px;
  margin-left: 6px;
  margin-top: -4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 1;
  }

  &.${sliderUnstyledClasses.disabled} { 
    pointer-events: none;
    cursor: default;
    color: #616161;
    opacity: 0.5;
  }

  & .${sliderUnstyledClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #202020;
  }

  & .${sliderUnstyledClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 2px;
    background-color: #00f5ba;
  }

  & .${sliderUnstyledClasses.thumb} {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -10px;
    margin-top: -5px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: #f5f5f5;
    box-shadow: 0 0 1px 3px ${alpha('#171717', 0.15)};

    :hover,
    &.${sliderUnstyledClasses.focusVisible} {
      box-shadow: 0 0 1px 3px ${alpha('#616161', 0.15)};
    }

    &.${sliderUnstyledClasses.active} {
      box-shadow: 0 0 1px 3px ${alpha('#616161', 0.3)};
    }
  }
`
);
