import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useBaseContext } from '@/context/BaseContext';

import { Text } from '@/components/Shared/Text';

import './styles.scss';

export const EarlyBirdExpiringBanner: FC = () => {
  const {
    state: { userInfo },
  } = useBaseContext();

  const isAdmin = userInfo?.roles.includes('Admin');

  return (
    <div className={'warning-banner'}>
      <span>
        <Text color="#171717" type="semibold-12px">
          Your Early Bird subscription has expired.
        </Text>
        {!isAdmin ? (
          <Text color="#171717" type="semibold-12px">
            {' Please contact your admin to update your subscription. '}
          </Text>
        ) : (
          <Text color="#171717" type="semibold-12px">
            {' Please update your subscription. '}
          </Text>
        )}
      </span>
      {isAdmin && (
        <Link className="link-button" to="/subscription">
          Choose subscription
        </Link>
      )}
    </div>
  );
};
