import { Box, FormControl, Stack } from '@mui/material';

import { TextArea } from '../Shared/TextArea';
import { TextField } from '../Shared/TextField';

type PopupContent = {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
};

export const EditSimAttrPopupContent = ({ name, setName, description, setDescription }: PopupContent) => {
  return (
    <Stack>
      <FormControl>
        <Box component={'div'} paddingBottom={1}>
          <TextField autoFocus placeholder="Simulation name (required)" value={name} onChange={setName} />
        </Box>
      </FormControl>
      <FormControl>
        <Box component="div" paddingBottom={1}>
          <TextArea placeholder="Simulation description" value={description} onChange={setDescription} />
        </Box>
      </FormControl>
    </Stack>
  );
};
