import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';

import { useCreateSharedAuralization } from './useCreateSharedAuralization';
import { useDeleteSharedAuralization } from './useDeleteSharedAuralization';
import { useGetSharedAuralizationByPresetId } from './useGetSharedAuralizationByPresetId';
import { useResendEmailInvite } from './useResendEmailInvite';
import { useUpdateSharedAuralization } from './useUpdateSharedAuralization';

import { SharedAuralizationInfoDto } from '../types';

export const useShareAuralizationPreset = (presetId: string) => {
  const [shareDetails, setShareDetails] = useState<SharedAuralizationInfoDto | null>(null);

  // Load shared details for a preset
  const { isLoading } = useGetSharedAuralizationByPresetId(presetId, (data) => {
    setShareDetails(data);
  });

  // Shared auralization preset actions
  const { mutate: createSharedAuralization, isLoading: isCreating } = useCreateSharedAuralization();
  const { mutate: updateSharedAuralization, isLoading: isUpdating } = useUpdateSharedAuralization();
  const { mutate: deleteSharedAuralization, isLoading: isDeleting } = useDeleteSharedAuralization();
  const { mutate: resendEmailInvite } = useResendEmailInvite();

  const isMutating = isCreating || isUpdating || isDeleting;
  const dateToday = dayjs();

  // Form values
  const [newEmails, setNewEmails] = useState<string[]>([]);
  const [expiresAt, setExpiresAt] = useState<Dayjs | null>(null);
  const [expiresAtEditMode, setExpiresAtEditMode] = useState<boolean>(false);
  const [expiresAtError, setExpiresAtError] = useState<boolean>(true);

  useEffect(() => {
    if (shareDetails?.expiresAt) {
      setExpiresAt(dayjs(shareDetails.expiresAt));
    } else {
      setExpiresAt(dayjs().add(1, 'month'));
    }
    setExpiresAtError(false);
  }, [shareDetails]);

  const handleSend = () => {
    if (expiresAt && !expiresAtError) {
      if (shareDetails?.id) {
        updateSharedAuralization(
          {
            id: shareDetails.id,
            auralizationPresetId: shareDetails.auralizationPresetId,
            emails: [...shareDetails.emails, ...newEmails.filter((email) => !shareDetails.emails.includes(email))],
            expiresAt: shareDetails.expiresAt,
            createAnonymousUrl: !!shareDetails.anonymousUrl,
          },
          {
            onSuccess: (data) => {
              setShareDetails(data);
              setNewEmails([]);
              toast.success(
                `Auralization shared! Email sent to ${
                  newEmails.length == 1 ? newEmails[0] : newEmails.length + ' recipients'
                }`,
                { className: 'editor-toast' }
              );
            },
          }
        );
      } else {
        createSharedAuralization(
          {
            auralizationPresetId: presetId,
            emails: newEmails,
            expiresAt: expiresAt.toISOString(),
            createAnonymousUrl: false,
          },
          {
            onSuccess: (data) => {
              setShareDetails(data);
              setNewEmails([]);
              toast.success(
                `Auralization shared! Email sent to ${
                  newEmails.length == 1 ? newEmails[0] : newEmails.length + ' recipients'
                }`,
                { className: 'editor-toast' }
              );
            },
          }
        );
      }
    }
  };

  const handleCopyLink = async () => {
    if (shareDetails?.anonymousUrl) {
      // Copy link (already started sharing and generated the anonymous link)
      writeToClipboard(shareDetails.anonymousUrl, 'Link copied to clipboard!');
    } else if (shareDetails?.id) {
      // Create the anonymous link (already started sharing)
      updateSharedAuralization(
        {
          id: shareDetails.id,
          auralizationPresetId: shareDetails.auralizationPresetId,
          emails: shareDetails?.emails ?? [],
          expiresAt: shareDetails.expiresAt,
          createAnonymousUrl: true,
        },
        {
          onSuccess: (data) => {
            if (data.anonymousUrl) {
              // Copy link
              writeToClipboard(data.anonymousUrl, 'Link ready & copied to clipboard!');
            }

            setShareDetails(data);
          },
        }
      );
    } else if (expiresAt && !expiresAtError) {
      // Create the anonymous link (no sharing created yet)
      createSharedAuralization(
        {
          auralizationPresetId: presetId,
          emails: shareDetails?.emails ?? [],
          expiresAt: expiresAt.toISOString(),
          createAnonymousUrl: true,
        },
        {
          onSuccess: (data) => {
            if (data.anonymousUrl) {
              // Copy link
              writeToClipboard(data.anonymousUrl, 'Link ready & copied to clipboard!');
            }

            setShareDetails(data);
          },
        }
      );
    }
  };

  const writeToClipboard = async (url: string, toasterText: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast.info(toasterText, { className: 'editor-toast' });
    } catch (error) {
      toast.error('Error in copying text to clipboard, please try again', { className: 'editor-toast' });
    }
  };

  const handleSaveNewExpiresAt = () => {
    if (shareDetails?.id && expiresAt && !expiresAtError) {
      updateSharedAuralization(
        {
          id: shareDetails.id,
          auralizationPresetId: shareDetails.auralizationPresetId,
          emails: shareDetails.emails,
          expiresAt: expiresAt.toISOString(),
          createAnonymousUrl: !!shareDetails.anonymousUrl,
        },
        {
          onSuccess: (data) => {
            setShareDetails(data);
            setExpiresAtEditMode(false);
            toast.info('Expiry date updated', { className: 'editor-toast' });
          },
        }
      );
    }
  };

  const handleCancelNewExpiresAt = () => {
    setExpiresAt(shareDetails?.expiresAt ? dayjs(shareDetails.expiresAt) : null);
    setExpiresAtEditMode(false);
    setExpiresAtError(false);
  };

  const handleDeleteShare = (email?: string) => {
    if (shareDetails) {
      // If we are deleting the last shared link we delete the sharing entirely
      if (
        shareDetails.emails.length === 0 ||
        (email && shareDetails.emails.length === 1 && !shareDetails.anonymousUrl)
      ) {
        deleteSharedAuralization(shareDetails.id, {
          onSuccess: () => {
            setShareDetails(null);
            toast.info(
              email
                ? `Access revoked. The sharing link sent to ${email} can no longer be used to access this Auralization.`
                : `Anonymous link deleted successfully. It can no longer be used to access this Auralization.`
            );
          },
        });
      } else {
        updateSharedAuralization(
          {
            id: shareDetails.id,
            auralizationPresetId: shareDetails.auralizationPresetId,
            emails: email ? shareDetails.emails.filter((e) => e !== email) : shareDetails.emails,
            expiresAt: shareDetails.expiresAt,
            createAnonymousUrl: !!(email && shareDetails.anonymousUrl),
          },
          {
            onSuccess: (data) => {
              setShareDetails(data);
              toast.info(
                email
                  ? `Access revoked. The sharing link sent to ${email} can no longer be used to access this Auralization.`
                  : `Anonymous link deleted successfully. It can no longer be used to access this Auralization.`
              );
            },
          }
        );
      }
    }
  };

  const handleResendEmail = (email: string) => {
    if (shareDetails) {
      resendEmailInvite(
        {
          sharedAuralizationId: shareDetails.id,
          email,
        },
        {
          onSuccess: () => toast.info(`Email sent to ${email}!`, { className: 'editor-toast' }),
        }
      );
    }
  };

  const handleStopSharing = () => {
    if (shareDetails) {
      deleteSharedAuralization(shareDetails.id, {
        onSuccess: () => {
          setShareDetails(null);
          toast.info('Auralization sharing stopped', { className: 'editor-toast' });
        },
      });
    }
  };

  return {
    shareDetails,
    isLoading,
    isMutating,
    newEmails,
    dateToday,
    expiresAt,
    expiresAtEditMode,
    expiresAtError,
    setNewEmails,
    setExpiresAt,
    setExpiresAtEditMode,
    setExpiresAtError,
    handleSend,
    handleCopyLink,
    handleSaveNewExpiresAt,
    handleCancelNewExpiresAt,
    handleDeleteShare,
    handleResendEmail,
    handleStopSharing,
  };
};
