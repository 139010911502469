import { useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Mesh } from 'three';

import { useCreateMaterialContext } from '../context/CreateMaterialContext';

import { AirCavityBox } from './AirCavityBox';
import { PorousMaterialBox } from './PorousMaterialBox';
import { RigidBackingBox } from './RigidBackingBox';

import { useAdjustCameraToFitMesh } from '../hooks/useAdjustCameraToFitMesh';

import { BoxProps, DEFAULT_BOX_VALUES, INITIAL_XPOS, MAX_NUMBER_INPUT, scaledBoxWidth } from '../constants';

export const BuilderBoxes = () => {
  const { camera } = useThree();

  const meshRef = useRef<Mesh>(null);
  const { airCavityFormValues, porousAbsorberFormValues } = useCreateMaterialContext();
  const [boxes, setBoxes] = useState<BoxProps[]>([...DEFAULT_BOX_VALUES]);

  const adjustCameraToFitMesh = useAdjustCameraToFitMesh();

  useEffect(() => {
    if (
      porousAbsorberFormValues.thickness !== undefined &&
      porousAbsorberFormValues.thickness >= 0 &&
      porousAbsorberFormValues.thickness < MAX_NUMBER_INPUT
    ) {
      updateBoxWidth(2, porousAbsorberFormValues.thickness);
    }
  }, [porousAbsorberFormValues.thickness]);

  useEffect(() => {
    if (
      airCavityFormValues.thickness !== undefined &&
      airCavityFormValues.thickness >= 0 &&
      airCavityFormValues.thickness < MAX_NUMBER_INPUT
    ) {
      updateBoxWidth(1, airCavityFormValues.thickness);
    }
  }, [airCavityFormValues.thickness]);

  // Update a box's width
  const updateBoxWidth = (index: number, newWidth: number) => {
    setBoxes((currentBoxes) => {
      const updatedBoxes = [...currentBoxes];
      updatedBoxes[index].width = newWidth > 0 ? scaledBoxWidth(newWidth) : 0;
      return updatePositions(updatedBoxes);
    });
  };

  // Recompute positions based on new box widths
  const updatePositions = (boxes: BoxProps[]): BoxProps[] => {
    let currentPosition = INITIAL_XPOS; // Start position for the first box center
    return boxes.map((box, index) => {
      if (index === 0) {
        // The first box is always at position 0
        box.xPos = currentPosition;
      } else {
        // Subsequent boxes are positioned based on the previous boxes
        currentPosition += scaledBoxWidth(boxes[index - 1].width) / 2 + scaledBoxWidth(box.width) / 2;
        box.xPos = currentPosition;
      }
      return box;
    });
  };

  useEffect(() => {
    adjustCameraToFitMesh(meshRef);
  }, [airCavityFormValues, porousAbsorberFormValues, meshRef, camera]);

  return (
    <mesh ref={meshRef}>
      <RigidBackingBox box={boxes[0]} index={0} />
      <AirCavityBox box={boxes[1]} index={1} />
      <PorousMaterialBox box={boxes[2]} index={2} />
    </mesh>
  );
};
