import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { AddComparisonContent } from './AddComparisonContent';

const POPUP_TITLE = 'Add simulation';

export const AddComparisonPopup = ({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
}) => {
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <TrblPopup
      width="400px"
      height="472px"
      hideBackdrop={false}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px', minHeight: '400px' }}
      onClose={handleClose}
      open={showPopup}>
      <TrblPopupTitle onClose={handleClose}>{POPUP_TITLE}</TrblPopupTitle>
      <TrblPopupContent>
        <AddComparisonContent setShowPopup={setShowPopup} />
      </TrblPopupContent>
    </TrblPopup>
  );
};
