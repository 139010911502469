import React, { ForwardedRef, useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';

import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TrblAzimuthIcon, TrblElevationIcon, TrblIcon, TrblRotationIcon } from '@/components/Icons';
import { Visibility } from '@/components/LayersTable/Visibility';
import { TrblEditableLabel } from '../../Shared/TrblEditableLabel';
import { Marker } from '../Marker';
import { SpaceSlider } from '../SpaceSlider/SpaceSlider';

import { GridReceiver, GridReceiverUserInput } from '@/types';

import styles from '../styles.module.scss';

type GridReceiverRowProps = {
  label: string;
  userInput: GridReceiverUserInput;
  index: number;
  isSelected: boolean;
  isDisabled: boolean;
  readonly: boolean;
  isNewPoint: boolean;
  onSelect: () => void;
  onDelete: () => void;
  onChangeLabel: (label: string) => void;
  onChangeAxis: (axis: 'x' | 'y' | 'z', value?: number) => void;
  onChangeParam: (param: 'pitch' | 'yaw' | 'roll', value?: number) => void;
  onChangeSize: (value: number[]) => void;
  onChangingGridReceiverVisibility: (gridReceiver: GridReceiver) => void;
};

const minCoarseness = 0.5;
const maxCoarseness = 5;

export const GridReceiverRow = React.forwardRef(
  (
    {
      label,
      userInput,
      index,
      isSelected,
      isDisabled,
      readonly,
      isNewPoint,
      onSelect,
      onDelete,
      onChangeAxis,
      onChangeLabel,
      onChangeParam,
      onChangeSize,
      onChangingGridReceiverVisibility,
    }: GridReceiverRowProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { gridReceivers, hiddenSurfaceReceivers, isInResultsMode } = useEditorContext();
    const [posX, setPosX] = useState<number | undefined>(userInput.centerPoint.x);
    const [posY, setPosY] = useState<number | undefined>(userInput.centerPoint.y);
    const [posZ, setPosZ] = useState<number | undefined>(userInput.centerPoint.z);
    const [gridPitch, setGridPitch] = useState<number | undefined>(userInput.pitch);
    const [gridYaw, setGridYaw] = useState<number | undefined>(userInput.yaw);
    const [gridRoll, setGridRoll] = useState<number | undefined>(userInput.roll);
    const [gridWidth, setGridWidth] = useState<number | undefined>(userInput.width);
    const [gridLength, setGridLength] = useState<number | undefined>(userInput.length);
    const [coarseness, setCoarseness] = useState<number | undefined>(userInput.coarseness);
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
      if (hiddenSurfaceReceivers && gridReceivers) {
        setIsHidden(!!hiddenSurfaceReceivers.find((surface) => surface.id === gridReceivers[index].id));
      }
    }, [hiddenSurfaceReceivers, gridReceivers]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleVisibility = (event: any) => {
      event.stopPropagation();
      onChangingGridReceiverVisibility(gridReceivers[index]);
    };

    // to handle when the position is changed with the viewportControls
    useEffect(() => {
      if (userInput.centerPoint.x !== posX && !isInResultsMode) setPosX(userInput.centerPoint.x);
    }, [userInput.centerPoint.x]);

    useEffect(() => {
      if (userInput.centerPoint.y !== posY && !isInResultsMode) setPosY(userInput.centerPoint.y);
    }, [userInput.centerPoint.y]);

    useEffect(() => {
      if (userInput.centerPoint.z !== posZ && !isInResultsMode) setPosZ(userInput.centerPoint.z);
    }, [userInput.centerPoint.z]);

    const handlePosXBlur = (value: number | undefined) => {
      if (userInput.centerPoint.x !== value) {
        onChangeAxis('x', value);
      }
    };
    const handlePosYBlur = (value: number | undefined) => {
      if (userInput.centerPoint.y !== value) {
        onChangeAxis('y', value);
      }
    };
    const handlePosZBlur = (value: number | undefined) => {
      if (userInput.centerPoint.z !== value) {
        onChangeAxis('z', value);
      }
    };

    const handlePitchBlur = (value: number | undefined) => {
      if (userInput.pitch !== value) {
        onChangeParam('pitch', value);
      }
    };
    const handleYawBlur = (value: number | undefined) => {
      if (userInput.yaw !== value) {
        onChangeParam('yaw', value);
      }
    };
    const handleRollBlur = (value: number | undefined) => {
      if (userInput.roll !== value) {
        onChangeParam('roll', value);
      }
    };

    const handleWidthBlur = (value: number | undefined) => {
      if (userInput.width !== value) {
        if (value !== undefined) {
          onChangeSize(getGridSize(value, userInput.length, userInput.coarseness));
        } else {
          setGridWidth(userInput.coarseness);
          onChangeSize(getGridSize(userInput.coarseness, userInput.length, userInput.coarseness));
        }
      }
    };

    const handleLengthBlur = (value: number | undefined) => {
      if (userInput.length !== value) {
        if (value !== undefined) {
          onChangeSize(getGridSize(userInput.width, value, userInput.coarseness));
        } else {
          setGridLength(userInput.coarseness);
          onChangeSize(getGridSize(userInput.width, userInput.coarseness, userInput.coarseness));
        }
      }
    };

    const handleResolutionChange = (value: number | undefined) => {
      if (coarseness !== value) {
        if (value !== undefined) {
          setCoarseness(value);
          onChangeSize(getGridSize(userInput.width, userInput.length, value));
        }
      }
    };

    // calculates what the length and width need to be in relation to the coarseness
    function getGridSize(w: number, l: number, c: number) {
      let width = w;
      let length = l;
      const coarseness = c;
      if (width < coarseness) width = coarseness;
      if (length < coarseness) length = coarseness;
      if (width % coarseness !== 0) width = closestNumber(width, coarseness);
      if (length % coarseness !== 0) length = closestNumber(length, coarseness);
      setGridWidth(width);
      setGridLength(length);
      return [width, length, coarseness];
    }
    function closestNumber(number: number, multiple: number) {
      return Math.round(number / multiple) * multiple;
    }

    const handleLabelChange = (value: string) => {
      if (label !== value) {
        onChangeLabel(value);
      }
    };

    const handleContainerClick = () => {
      onSelect();
    };

    return (
      <div
        ref={ref}
        onClick={handleContainerClick}
        className={`${styles['row-container']} ${isSelected ? styles['selected'] : ''} ${
          isDisabled || readonly ? styles['disabled'] : ''
        }`}>
        <div style={{ padding: '2px 0', width: 180 }}>
          <TrblEditableLabel
            label={label}
            placeholder={label ? undefined : `Surface receiver ${index + 1}`}
            editableOnRender={isNewPoint}
            onBlur={handleLabelChange}
            isDisabled={isDisabled}
          />
        </div>

        <div className={styles['settings-row']}>
          <Marker isSelected={isSelected} color="white" label={(index + 1).toString()} />
          <div className={styles['coordinate-inputs-wrapper']}>
            <TrblNumberInput
              value={posX}
              step={0.5}
              decimals={2}
              onChange={setPosX}
              onBlur={handlePosXBlur}
              startAdornment={'X'}
              alignment={'right'}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={posY}
              step={0.5}
              decimals={2}
              onChange={setPosY}
              onBlur={handlePosYBlur}
              startAdornment={'Y'}
              alignment={'right'}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={posZ}
              step={0.1}
              decimals={2}
              onChange={setPosZ}
              onBlur={handlePosZBlur}
              startAdornment={'Z'}
              alignment={'right'}
              readOnly={readonly}
            />
          </div>
        </div>
        <div className={styles['settings-row']}>
          <div className={styles['param-inputs-wrapper']}>
            <TrblNumberInput
              value={gridPitch}
              step={5}
              decimals={2}
              onChange={setGridPitch}
              onBlur={handlePitchBlur}
              startAdornment={<TrblAzimuthIcon />}
              endAdornment={'°'}
              alignment={'right'}
              min={0}
              max={360}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={gridYaw}
              step={5}
              decimals={2}
              onChange={setGridYaw}
              onBlur={handleYawBlur}
              startAdornment={<TrblElevationIcon />}
              endAdornment={'°'}
              alignment={'right'}
              min={-90}
              max={90}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={gridRoll}
              step={5}
              decimals={2}
              onChange={setGridRoll}
              onBlur={handleRollBlur}
              startAdornment={<TrblRotationIcon />}
              endAdornment={'°'}
              alignment={'right'}
              min={0}
              max={360}
              readOnly={readonly}
            />
          </div>
        </div>
        <div className={styles['settings-row']}>
          <div className={`${styles['param-inputs-wrapper']} ${styles['size-wrapper']}`}>
            <TrblNumberInput
              value={gridWidth}
              step={coarseness}
              onChange={setGridWidth}
              onBlur={handleWidthBlur}
              startAdornment={'Length'}
              alignment={'right'}
              decimals={1}
              min={coarseness}
              endAdornment={'m'}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={gridLength}
              step={coarseness}
              onChange={setGridLength}
              onBlur={handleLengthBlur}
              startAdornment={'Width'}
              alignment={'right'}
              decimals={1}
              min={coarseness}
              endAdornment={'m'}
              readOnly={readonly}
            />
          </div>
        </div>
        <div className={styles['settings-row']}>
          <SpaceSlider
            onSelectingValue={handleResolutionChange}
            min={minCoarseness}
            max={maxCoarseness}
            value={coarseness}
            isDisabled={isDisabled}
          />
        </div>
        <div className={`${styles['grid-receiver-extras']} ${styles['point-icons']}`}>
          <div className={`${(isHidden || isInResultsMode) && styles['show-visibility']}`}>
            <Visibility
              toggleVisibility={toggleVisibility}
              isHidden={isHidden}
              isHiddenTitle="Unhide"
              isShownTitle="Hide"
              isSelected={isSelected}
            />
          </div>
          {!isDisabled && (
            <div className={`${isInResultsMode && styles['hide-delete']}`}>
              <span onClick={() => onDelete()} title="Delete surface receiver">
                <TrblIcon icon="delete" color="#999999" hoverColor="#f5f5f5" />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
