import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblListIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.95833 2.91667H13.4167C13.7388 2.91667 14 2.6555 14 2.33333C14 2.01117 13.7388 1.75 13.4167 1.75H4.95833C4.63617 1.75 4.375 2.01117 4.375 2.33333C4.375 2.6555 4.63617 2.91667 4.95833 2.91667Z"
      fill={fill}
    />
    <path
      d="M13.4167 6.41675H4.95833C4.63617 6.41675 4.375 6.67792 4.375 7.00008C4.375 7.32225 4.63617 7.58342 4.95833 7.58342H13.4167C13.7388 7.58342 14 7.32225 14 7.00008C14 6.67792 13.7388 6.41675 13.4167 6.41675Z"
      fill={fill}
    />
    <path
      d="M13.4167 11.0833H4.95833C4.63617 11.0833 4.375 11.3444 4.375 11.6666C4.375 11.9888 4.63617 12.2499 4.95833 12.2499H13.4167C13.7388 12.2499 14 11.9888 14 11.6666C14 11.3444 13.7388 11.0833 13.4167 11.0833Z"
      fill={fill}
    />
    <path
      d="M0.583008 1.95728C0.583008 1.68113 0.806865 1.45728 1.08301 1.45728H1.83301C2.10915 1.45728 2.33301 1.68113 2.33301 1.95728V2.70728C2.33301 2.98342 2.10915 3.20728 1.83301 3.20728H1.08301C0.806865 3.20728 0.583008 2.98342 0.583008 2.70728V1.95728Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 0.875H2.04167C2.52492 0.875 2.91667 1.26675 2.91667 1.75V2.91667C2.91667 3.39992 2.52492 3.79167 2.04167 3.79167H0.875C0.391751 3.79167 0 3.39992 0 2.91667V1.75C0 1.26675 0.391751 0.875 0.875 0.875ZM2.04167 3.20833C2.20275 3.20833 2.33333 3.07775 2.33333 2.91667V1.75C2.33333 1.58892 2.20275 1.45833 2.04167 1.45833H0.875C0.713917 1.45833 0.583333 1.58892 0.583333 1.75V2.91667C0.583333 3.07775 0.713917 3.20833 0.875 3.20833H2.04167Z"
      fill={fill}
    />
    <path
      d="M0.583008 6.62378C0.583008 6.34764 0.806865 6.12378 1.08301 6.12378H1.83301C2.10915 6.12378 2.33301 6.34764 2.33301 6.62378V7.37378C2.33301 7.64992 2.10915 7.87378 1.83301 7.87378H1.08301C0.806865 7.87378 0.583008 7.64992 0.583008 7.37378V6.62378Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 5.54175H2.04167C2.52492 5.54175 2.91667 5.9335 2.91667 6.41675V7.58341C2.91667 8.06666 2.52492 8.45841 2.04167 8.45841H0.875C0.391751 8.45841 0 8.06666 0 7.58341V6.41675C0 5.9335 0.391751 5.54175 0.875 5.54175ZM2.04167 7.87508C2.20275 7.87508 2.33333 7.7445 2.33333 7.58341V6.41675C2.33333 6.25567 2.20275 6.12508 2.04167 6.12508H0.875C0.713917 6.12508 0.583333 6.25567 0.583333 6.41675V7.58341C0.583333 7.7445 0.713917 7.87508 0.875 7.87508H2.04167Z"
      fill={fill}
    />
    <path
      d="M0.583008 11.2905C0.583008 11.0144 0.806865 10.7905 1.08301 10.7905H1.83301C2.10915 10.7905 2.33301 11.0144 2.33301 11.2905V12.0405C2.33301 12.3167 2.10915 12.5405 1.83301 12.5405H1.08301C0.806865 12.5405 0.583008 12.3167 0.583008 12.0405V11.2905Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 10.2083H2.04167C2.52492 10.2083 2.91667 10.6 2.91667 11.0833V12.2499C2.91667 12.7332 2.52492 13.1249 2.04167 13.1249H0.875C0.391751 13.1249 0 12.7332 0 12.2499V11.0833C0 10.6 0.391751 10.2083 0.875 10.2083ZM2.04167 12.5416C2.20275 12.5416 2.33333 12.411 2.33333 12.2499V11.0833C2.33333 10.9222 2.20275 10.7916 2.04167 10.7916H0.875C0.713917 10.7916 0.583333 10.9222 0.583333 11.0833V12.2499C0.583333 12.411 0.713917 12.5416 0.875 12.5416H2.04167Z"
      fill={fill}
    />
  </svg>
);
