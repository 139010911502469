import React, { useState } from 'react';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';
import { ImpulseResponsePlot } from '../ImpulseResponsePlot';
import { SPLPerBandPlot } from '../SPLPerBandPlot';
import { PlotHeader } from './PlotHeader';

import { IRType, SingleReflectionPlotType, SPLType } from './types';
import { ReceiverResults } from '@/types';

import styles from './styles.module.scss';

type ReflectionsPlotProps = {
  data: ReflectionDetails | null;
  receiverIrResult: ReceiverResults | null;
  receiverIrNormalization: number | null;
  selectedFrequencyWeighting: FrequencyWeightingType;
  selectedFrequencyIndex: number | null;
  onFrequencySelected: (index: number) => void;
};

export const SingleReflectionPlots: React.FC<ReflectionsPlotProps> = ({
  data,
  receiverIrResult,
  receiverIrNormalization,
  selectedFrequencyWeighting,
  selectedFrequencyIndex,
  onFrequencySelected,
}) => {
  const [selectedPlotType, setSelectedPlotType] = useState<SingleReflectionPlotType>('spl');
  const [selectedSplType, setSelectedSplType] = useState<SPLType>('actual');
  const [selectedIrType, setSelectedIrType] = useState<IRType>('db');

  return (
    <div className={styles['plot-container']}>
      <PlotHeader
        selectedPlotType={selectedPlotType}
        onPlotTypeChange={setSelectedPlotType}
        selectedSplType={selectedSplType}
        onSplTypeChange={setSelectedSplType}
        selectedIrType={selectedIrType}
        onIrTypeChange={setSelectedIrType}
      />
      <div className={styles['plot']}>
        {selectedPlotType === 'spl' ? (
          <SPLPerBandPlot
            data={data}
            selectedSplType={selectedSplType}
            selectedFrequencyWeighting={selectedFrequencyWeighting}
            selectedFrequencyIndex={selectedFrequencyIndex}
            onFrequencySelected={onFrequencySelected}
          />
        ) : (
          <ImpulseResponsePlot
            receiverIrResult={receiverIrResult}
            selectedIrType={selectedIrType}
            irNormalization={receiverIrNormalization}
            selectedReflectionTimeOfArrival={data?.timeOfArrival ?? null}
          />
        )}
      </div>
    </div>
  );
};
