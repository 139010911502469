import { useSimulationContext } from '@/context/SimulationContext';

import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useSelectLayer } from './useSelectLayer';

export const useTabDownList = () => {
  const selectLayer = useSelectLayer();
  const { selectedLayer } = useLibraryPanelContext();
  const {
    simulationState: { surfaceLayers },
  } = useSimulationContext();

  const tabDownList = (event: KeyboardEvent, layersContainer: React.RefObject<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedLayer) {
      const currentElement = layersContainer?.current?.querySelector('.treble-layer-row.selected');
      const nextSibling = currentElement?.parentElement?.nextElementSibling;

      if (nextSibling?.classList.contains('child-layer')) {
        // if the next row is a child layer
        // @ts-expect-error Property 'dataset' does not exist on type 'Element'.
        selectLayer(surfaceLayers[selectedLayer.layerGroupIndex].children[nextSibling.dataset.index]);
      } else {
        // if the next row is a group layer
        selectLayer(surfaceLayers[selectedLayer.layerGroupIndex + 1]);
      }
    }
  };

  return tabDownList;
};
