import { Stack } from '@mui/material';

import { TrblWarningIcon } from '@/components/Icons';

import styles from '../../styles.module.scss';

export const ThumbnailandWarning = ({
  thumbnailSrc,
  warningCode,
  imgLoaded,
  setImgLoaded,
}: {
  thumbnailSrc: string | undefined;
  warningCode: number;
  imgLoaded: boolean;
  setImgLoaded: (value: boolean) => void;
}) => {
  return (
    <Stack position="relative">
      <img
        className={styles['space-card-thumbnail']}
        src={thumbnailSrc ?? ''}
        style={{ opacity: imgLoaded ? '1' : '0' }}
        onLoad={() => setImgLoaded(true)}
        alt="space thumbnail"
      />
      {warningCode == 1 ? (
        <div
          title="Model has warnings"
          className={styles['space-card-warning']}
          style={{ opacity: imgLoaded ? '1' : '0', display: 'flex' }}>
          <TrblWarningIcon width="14" height="14" />
        </div>
      ) : warningCode == 2 ? (
        <div
          title="Model not watertight"
          className={styles['space-card-warning']}
          style={{ opacity: imgLoaded ? '1' : '0', display: 'flex' }}>
          <TrblWarningIcon width="14" height="14" fill="#cb3bff" />
        </div>
      ) : null}
    </Stack>
  );
};
