import { useUpdateObjMats } from './useUpdateObjMats';
import { useUpdateObjScats } from './useUpdateObjScats';

import { MissingMaterialInfo } from '@/hooks';

import { getLayerMaterialName } from '../utils';

import { ModelLayerGroup } from '../ModelContext/types';
import { Material, MaterialLayer, Simulation } from '@/types';

export const useCreateSurfaceLayers = () => {
  const updateObjMats = useUpdateObjMats();
  const updateObjScats = useUpdateObjScats();

  const createSurfaceLayers = (
    layers: ModelLayerGroup[] | null,
    simulation: Simulation | null,
    materials: Material[],
    missingMaterials: MissingMaterialInfo[]
  ) => {
    if (!simulation?.modelSettings || !layers?.length || !materials.length) {
      return { newLayers: [], newSimulation: simulation };
    }

    const { materialIdByObjectId, scatteringByObjectId } = simulation.modelSettings;

    const undefinedIndex = materials.findIndex((material) => material.name === 'unassigned');

    const newLayers: MaterialLayer[] = [];
    let newObjMats = { ...materialIdByObjectId };
    let newObjScats = { ...scatteringByObjectId };

    layers.forEach((layer, layerGroupIndex) => {
      const parentLayer: MaterialLayer = {
        id: layer.id,
        layerIndex: layerGroupIndex,
        type: 'LayerGroup',
        name: layer.name,
        textName: layer.name,
        scatter: [0.1],
        children: [],
        isMissingMaterial: false,
        parentId: null,
        layerGroupIndex: layerGroupIndex,
      };

      let sameMat = true;
      let sameScat = true;

      layer.children.forEach((surface, childIndex) => {
        const objectId = surface.userData.attributes.id;
        const matIndex = materials.findIndex((material) => material.id == materialIdByObjectId[objectId]);

        const missingMatIndex =
          matIndex < 0 && missingMaterials.length
            ? missingMaterials.findIndex((material) => material.materialId == materialIdByObjectId[objectId])
            : -1;

        let scat: number[] | null = null;
        let mat: Material;

        if (missingMatIndex >= 0) {
          mat = {
            // Not sure what to set the rest of the properties to so we default to unassigned
            ...materials[undefinedIndex],
            name: missingMaterials[missingMatIndex].materialName,
            id: missingMaterials[missingMatIndex].materialId,
            isDeleted: missingMaterials[missingMatIndex].isDeleted,
            isSharedWithOrganization: missingMaterials[missingMatIndex].isSharedWithOrganization,
          };
          if (scatteringByObjectId[objectId] !== null) scat = scatteringByObjectId[objectId];
          else scat = [0.1];
        } else if (matIndex == undefinedIndex || matIndex < 0) {
          mat = materials[undefinedIndex];
          scat = null;
        } else {
          mat = materials[matIndex];
          if (scatteringByObjectId[objectId] !== null) scat = scatteringByObjectId[objectId];
          else scat = [0.1];
        }

        // Check if all children have the same material / scatter
        if (childIndex > 0 && sameMat && mat.id !== parentLayer.children[childIndex - 1].materialId) {
          sameMat = false;
        }
        if (childIndex > 0 && sameScat && scat !== parentLayer.children[childIndex - 1].scatter) {
          sameScat = false;
        }

        const childLayer: MaterialLayer = {
          id: objectId,
          layerIndex: childIndex,
          name: surface.name,
          scatter: scat,
          type: surface.type,
          children: [],
          materialId: mat.id,
          materialName: getLayerMaterialName(missingMatIndex >= 0, mat),
          textName: '— surface (' + (childIndex + 1) + ')',
          isMissingMaterial: missingMatIndex >= 0,
          parentId: layer.id,
          layerGroupIndex: layerGroupIndex,
        };

        parentLayer.children.push(childLayer);
        newObjMats = updateObjMats(newObjMats, [childLayer.id], mat.id);
        newObjScats = updateObjScats(newObjScats, [childLayer.id], scat);
      });

      parentLayer.materialId = sameMat ? parentLayer.children[0].materialId : null;
      parentLayer.materialName = sameMat ? parentLayer.children[0].materialName : '- multiple -';
      parentLayer.isMissingMaterial = sameMat ? parentLayer.children[0].isMissingMaterial : false;
      parentLayer.scatter = sameScat ? parentLayer.children[0].scatter : null;

      newLayers.push(parentLayer);
    });

    const newSimulation: Simulation = {
      ...simulation,
      modelSettings: {
        materialIdByObjectId: newObjMats,
        scatteringByObjectId: newObjScats,
      },
    };

    return { newLayers, newSimulation };
  };

  return createSurfaceLayers;
};
