import { useEffect, useState } from 'react';

import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import styles from './styles.module.scss';

type ColorScaleProps = {
  colorMap: string;
  setColorMap: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
  disabled: boolean;
};

const colorMapValues = [
  { label: 'Rainbow', value: 'rainbow' },
  { label: 'Viridis', value: 'viridis' },
  { label: 'Roma', value: 'roma' },
  { label: 'Roma inverted', value: 'romaInverted' },
  { label: 'Grayscale', value: 'grayC' },
  { label: 'Grayscale inverted', value: 'grayCInverted' },
];

export const ColorMapMenu: React.FC<ColorScaleProps> = ({ colorMap, setColorMap, options, disabled }) => {
  const [actions, setActions] = useState<{ value: string; key: string; onClick: () => void }[]>([]);

  useEffect(() => {
    if (options.length > 0) {
      const resultArray = colorMapValues
        .filter((item) => options.includes(item.value))
        .map((item) => ({
          value: item.label,
          key: item.value,
          onClick: () => setColorMap(item.value),
        }));

      setActions(resultArray);
    }
  }, [options]);

  return (
    <div className={styles['menu-container']}>
      <TrblActionsMenu
        disabled={disabled}
        id="ColorMapMenu"
        title="Color scale options"
        actions={actions}
        classNames={styles['color-map-menu']}
        selected={colorMapValues.find((map) => map.value === colorMap)?.label}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      />
    </div>
  );
};
