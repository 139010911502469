import { useEffect, useState } from 'react';

import { getMissingKeys } from '../utils/getMissingKeys';

import { ComplexData, SimpleData } from '../types';

import styles from '../styles.module.scss';

type AbsorptionCoefficientsProps = {
  data: SimpleData | ComplexData;
  keys: number[];
};

export const MissingKeys: React.FC<AbsorptionCoefficientsProps> = ({ data, keys }) => {
  const [missingKeys, setMissingKeys] = useState<number[]>([]);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const newMissingKeys = getMissingKeys(data, keys);
    setMissingKeys(newMissingKeys);
  }, [data]);

  useEffect(() => {
    // This needs to be in a useEffect and not in the JSX directly
    // because initially the missingKeys length is more than 0, and
    // then for a split second you can see the warning text flicker
    setShouldShow(missingKeys.length > 0 && Object.keys(data).length > 0);
  }, [missingKeys, data]);

  return (
    <>
      {shouldShow ? (
        <p className={styles.warning_message}>
          Missing {missingKeys.length === 1 ? 'frequency' : 'frequencies'}:{' '}
          {missingKeys.map((key, index) => (
            <span key={key + index} className={styles.missing_keys}>
              {key}
              {missingKeys.length - 1 !== index ? ', ' : ''}
            </span>
          ))}
        </p>
      ) : null}
    </>
  );
};
