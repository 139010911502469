import { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Skeleton, Stack } from '@mui/material';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { TextField } from '@/components/Shared/TextField';
import { TrblCombobox } from '@/components/Shared/TrblCombobox';
import { SelectOption } from '@/components/Shared/TrblSelect';
import { TrblChevronDownIcon } from '@/components/Icons';
import { Checkbox } from '../../../Shared/Checkbox';
import { SpaceInfo, SpaceInfoParams } from '../../MultiSpaceImportStep2';
import { Description } from './Description';
import { ThumbnailandWarning } from './ThumbnailAndWarning';

import { useGetThumbnail } from '../../hooks/useGetThumbnail';

import styles from '../../styles.module.scss';

export const SpaceCard = ({
  name,
  modelName,
  description,
  modelUploadId,
  thumbnailDownloadUrl,
  selected = false,
  setSpaceSelected,
  spacesChecked,
  setSpacesChecked,
  index,
  allSpacesInfo,
  updateAllSpacesInfo,
  availableSpacesInProject,
  warningCode,
}: {
  name: string;
  modelName: string;
  description?: string;
  modelUploadId: string;
  thumbnailDownloadUrl?: string;
  selected: boolean;
  setSpaceSelected: (value: number) => void;
  spacesChecked: number[];
  setSpacesChecked: (value: number[]) => void;
  index: number;
  allSpacesInfo: SpaceInfo[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAllSpacesInfo: (value: any, param: SpaceInfoParams, index: number) => void;
  availableSpacesInProject: SelectOption[];
  warningCode: number;
}) => {
  const checkName = (name: string) => {
    // if name is empty then give it the name "Space " + index+1
    return name || 'Space ' + (index + 1).toString();
  };
  const [thisSpace, setThisSpace] = useState(checkName(name));
  const [thisSpaceName, setThisSpaceName] = useState(checkName(name));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [spacesInProject, setSpacesInProject] = useState<any[]>([]);
  const [thisModelName, setThisModelName] = useState(modelName);
  const [thisDescription, setThisDescription] = useState(description);

  const { thumbnailSrc, thumbnailFile } = useGetThumbnail(modelUploadId);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(allSpacesInfo.length > 1 ? false : true);
  const [existingSpace, setExistingSpace] = useState(false);
  const [newSpace, setNewSpace] = useState('');

  const handleSelected = (e: SyntheticEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;

    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement ||
      target.classList.contains('MuiMenuItem-root') ||
      target.parentElement?.classList.contains('MuiMenuItem-root') ||
      target.classList.contains('MuiBackdrop-root') ||
      document.querySelector('.add-space-popup')?.contains(target)
    ) {
      return;
    }

    setSpaceSelected(index);
  };

  const handleCheck = (checked: boolean) => {
    if (checked === true) setSpacesChecked([...spacesChecked, index]);
    else setSpacesChecked(spacesChecked.filter((spaceIndex) => spaceIndex !== index));
  };

  const handleUpdateSpace = (value: string) => {
    if (value === '') {
      toast.warning('Space name has to contain at least 1 character');
      // hack to revert the InputSelect component to revert to previous state
      setThisSpace(value);
      setTimeout(() => setThisSpace(thisSpace), 10);
      return;
    }
    if (value === thisSpace) return;

    const otherSpaceHasSameName = allSpacesInfo.some((space) => space.spaceName.toLowerCase() == value.toLowerCase());
    if (otherSpaceHasSameName) {
      toast.warning('Another space already has the name "' + value + '". Please choose another one.');
      // hack to revert the InputSelect component to revert to previous state
      setThisSpace(value);
      setTimeout(() => setThisSpace(thisSpace), 10);
      return;
    }

    const existingSpace = checkIfSpaceExistInProject(value);

    if (existingSpace) {
      updateAllSpacesInfo(value, 'spaceName', index);
      setThisSpaceName(value);
      setThisSpace(value);
      updateAllSpacesInfo(existingSpace.description, 'description', index);
      setThisDescription(existingSpace.description);
      setExistingSpace(true);
    } else {
      addNewSpace(value);
    }
  };

  useEffect(() => {
    if (thumbnailFile) {
      updateAllSpacesInfo(thumbnailFile, 'thumbnailFile', index);
    }
  }, [thumbnailFile]);

  // on first load, add initial name to list of available Spaces
  useEffect(() => {
    const spaceName = checkName(name);
    const existingSpace = checkIfSpaceExistInProject(spaceName);

    if (existingSpace) {
      setSpacesInProject(availableSpacesInProject);
      setThisSpace(existingSpace.name);
      setThisSpaceName(existingSpace.name);
      setThisDescription(existingSpace.description);
      setExistingSpace(true);
    } else {
      addNewSpace(spaceName);
    }
  }, []);

  // if Project is changed, add current name to list of available Spaces
  useEffect(() => {
    const existingSpace = checkIfSpaceExistInProject(thisSpaceName);

    if (existingSpace) {
      setSpacesInProject(availableSpacesInProject);
      setThisSpace(existingSpace.name);
      setThisDescription(existingSpace.description);
      setExistingSpace(true);
    } else {
      addNewSpace(thisSpaceName);
    }
  }, [availableSpacesInProject]);

  const addNewSpace = (spaceName: string) => {
    const currentSpaces = [...availableSpacesInProject];

    setThisSpace(spaceName);
    currentSpaces.push({ id: spaceName, name: spaceName });

    if (spaceName !== name) {
      updateAllSpacesInfo(spaceName, 'spaceName', index);
      if (existingSpace) updateAllSpacesInfo('', 'description', index);
    }

    setThisSpaceName(spaceName);
    if (existingSpace) setThisDescription('');
    setSpacesInProject(currentSpaces);
    setExistingSpace(false);
    setNewSpace(spaceName);
  };

  useEffect(() => {
    if (modelName !== thisModelName) {
      setThisModelName(modelName);
      updateAllSpacesInfo('', 'modelName', index);
    }
  }, [modelName]);

  const handleUpdateModelName = (value: string) => {
    setThisModelName(value);
    updateAllSpacesInfo(value, 'modelName', index);
  };

  const handleModelNameBlur = (value: string) => {
    if (value == '') setThisModelName(modelName);
  };

  const checkIfSpaceExistInProject = (name: string) => {
    return availableSpacesInProject
      .filter((space) => space.name.toLowerCase() === name.toLowerCase())
      .map((space) => {
        return {
          name: space.name,
          description: space.description,
        };
      })[0];
  };

  return (
    <div
      title={allSpacesInfo.length > 1 ? 'Click to view space' : ''}
      className={`${styles['space-card']} ${selected ? styles['selected'] : ''} ${
        isExpanded ? styles['expanded'] : ''
      }`}
      style={allSpacesInfo.length > 1 ? { cursor: 'pointer' } : {}}
      onClick={(e) => handleSelected(e)}
      tabIndex={allSpacesInfo.length > 1 ? 0 : 1}>
      <Stack flexDirection="row" width="100%" height="66px" alignItems="center">
        <ThumbnailandWarning
          thumbnailSrc={thumbnailSrc || thumbnailDownloadUrl}
          warningCode={warningCode}
          imgLoaded={imgLoaded}
          setImgLoaded={setImgLoaded}
        />
        {!imgLoaded ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ width: '100%', height: '80%', margin: '10px -10px 10px -52px', opacity: 0.15 }}
          />
        ) : (
          <div className={styles['space-card-content']}>
            <Stack flexDirection="column" gap="2px">
              <TrblCombobox
                placeholder="Space name"
                title="Edit or select space"
                value={thisSpace ?? ''}
                setValue={handleUpdateSpace}
                menuItems={spacesInProject.map((space) => space.name)}
                className={styles['space-card-combobox']}
                extra={existingSpace ? '' : '(New)'}
                notShowItem={newSpace}
                hideChevronOnFocus
              />

              <TextField
                placeholder="Model name"
                title="Edit model name"
                value={thisModelName}
                onChange={handleUpdateModelName}
                onBlur={handleModelNameBlur}
                blurOnEnter={true}
                className={styles['space-card-textfield']}
              />
            </Stack>
            {allSpacesInfo.length > 1 && (
              <>
                <Stack flexDirection="row" gap="12px" alignItems="center" mt="4px">
                  <Checkbox
                    id={index.toString()}
                    onChange={handleCheck}
                    isChecked={spacesChecked.includes(index)}
                    title="Select space to import"
                    style={{ background: 'none' }}></Checkbox>
                </Stack>
                <TrblIconButton
                  className={styles['space-card-chevron']}
                  icon={<TrblChevronDownIcon fill="#DADADA" />}
                  label="Expand"
                  title="Expand to see more properties"
                  edge="start"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                  }}
                />
              </>
            )}
          </div>
        )}
      </Stack>

      <Description
        isExpanded={isExpanded}
        description={thisDescription}
        index={index}
        existingSpace={existingSpace}
        updateAllSpacesInfo={updateAllSpacesInfo}
      />
    </div>
  );
};
