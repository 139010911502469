import { Stack } from '@mui/material';

import { Trbl3DWireframeIcon, TrblMaterialIcon, TrblSimulationIcon } from '@/components/Icons';

import { roundFloat } from '@/utils/trebleFunctions';

import { GeometryInfo } from '../types';

import styles from '../styles.module.scss';

export const MultiSpaceInfoIcons = ({ geometryInfo }: { geometryInfo: GeometryInfo | null }) => {
  return geometryInfo ? (
    <Stack flexDirection={'row'} className={styles['info-icons-container']}>
      <div className={styles['info-icons-item']}>
        <TrblMaterialIcon width="14" height="14" />
        <p>
          {geometryInfo.objectCount + ' surfaces'} <br />
          {'on ' + geometryInfo.layerCount + ' ' + (geometryInfo.layerCount > 1 ? ' layers' : ' layer')}
        </p>
      </div>
      <div className={styles['info-icons-item']}>
        <Trbl3DWireframeIcon width="14" height="14" />

        <p>
          {geometryInfo.watertight
            ? geometryInfo.modelVolume
              ? 'Model volume ' + roundFloat(geometryInfo.modelVolume, 2) + ' m3'
              : 'No volume in model'
            : 'Model not watertight'}
        </p>
      </div>
      <div className={styles['info-icons-item']}>
        <TrblSimulationIcon width="14" height="14" />
        <p>{geometryInfo.watertight ? 'Wave solver enabled' : 'Geometrical solver only'}</p>
      </div>
    </Stack>
  ) : (
    <Stack flexDirection={'row'} className={styles['info-icons-container']} style={{ opacity: 0 }}></Stack>
  );
};
