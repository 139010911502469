import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';

import { AudioObject, ChannelBuffers } from '@/components/Auralizer/types';
import { AudioEngine } from '../AudioEngine';
import { createConvolver } from './createConvolver';
import { deinterleaveBuffers } from './deinterleaveBuffers';

export const createAudioConvolverChain = async (
  url: string,
  firstSimNormMax: number,
  sourceNormFactor: number,
  simulationId: string,
  sourceId: string
): Promise<AudioObject> => {
  const audioEngine = AudioEngine.getInstance();

  const channelCount = 9;
  const sampleRate = 32000;

  const audioObject: AudioObject = {
    merger: audioEngine.audioContext.createChannelMerger(channelCount),
    inputGain: audioEngine.audioContext.createGain(),
    convolverArray: [],
    channelCount: channelCount,
    sampleRate: sampleRate,
  };

  const ratio = sourceNormFactor / firstSimNormMax;

  if (!isFinite(ratio) || ratio === null) {
    // We need to figure out why some simulations don't have a numberFactor
    // and this is also an experiment to see how custom errors work in DD :)
    const error = new Error(`NumberFactor is infinite for sim ${simulationId} and source ${sourceId}`);
    datadogRum.addError(error);
  }

  audioObject.inputGain!.gain.value = ratio;
  audioObject.inputGain!.channelCount = 1;

  // Start fetching the audio data
  try {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();

    const start = new Date().getTime();
    const channelBuffers: ChannelBuffers | undefined = deinterleaveBuffers(arrayBuffer);
    const end = new Date().getTime() - start;
    console.log('[Auralization] worker time', end, 'ms');

    if (channelBuffers) {
      channelBuffers.buffers.forEach((buffer, index) => {
        const convolver = createConvolver(
          buffer,
          channelBuffers.datatype,
          audioObject.sampleRate,
          audioEngine.audioContext
        );
        audioObject.convolverArray[index] = convolver;

        if (audioObject.inputGain && audioObject.merger) {
          try {
            audioObject.inputGain.connect(convolver);
            convolver.connect(audioObject.merger, 0, index);
          } catch (error) {
            datadogRum.addError(`An error occurred while connecting audio nodes: ${error}`);
          }
        }
      });
    }
  } catch (error) {
    toast.error(
      `An error occurred while fetching audio data. Contact us through the Support button if the issue persists.`,
      { toastId: simulationId }
    );
    datadogRum.addError(`An error occurred while fetching audio data: ${error}`);
  }

  return audioObject;
};
