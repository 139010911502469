import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblIcon } from '../Icons';

export const Visibility = ({
  isHidden,
  isHiddenTitle,
  isShownTitle,
  isSelected,
  toggleVisibility,
}: {
  isHidden: boolean;
  isHiddenTitle: string;
  isShownTitle: string;
  isSelected?: boolean;
  toggleVisibility: React.MouseEventHandler;
}) => {
  const [showTooltip, setShowTooltip] = useState(true);
  const [color, setColor] = useState('');

  useEffect(() => {
    if (isSelected) {
      setColor('#dadada');
    } else if (isHidden) {
      setColor('#dadada');
    } else {
      setColor('#616161');
    }
  }, [isSelected, isHidden]);

  return (
    <TrblTooltip title={isHidden ? isHiddenTitle : isShownTitle} hidden={!showTooltip} disableInteractive>
      <span
        className="visibility"
        onClick={toggleVisibility}
        onMouseDown={() => setShowTooltip(false)}
        onMouseLeave={() => setShowTooltip(true)}>
        <TrblIcon color={color} icon={isHidden ? 'closeEye' : 'openEye'} />
      </span>
    </TrblTooltip>
  );
};
