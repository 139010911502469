import { toast } from 'react-toastify';
import linspace from 'exact-linspace';
import { AxisType, Data, DataTitle, Layout, PlotData, PlotType as PlotlyPlotType } from 'plotly.js';
import { WaveFile } from 'wavefile';

import {
  A_WEIGHTING,
  DEFAULT_LAYOUT_CONFIG,
  FREQUENCY_OPTIONS,
  FREQUENCY_OPTIONS_SPL,
  FREQUENCY_VALUES,
  FREQUENCY_VALUES_FOR_RESPONSE_PLOT,
  MANUAL_RANGE_MARGIN_PERCENT,
  NC_CURVE_OPTIONS,
  OCTAVE_BANDS,
  PARAMETERS_WITH_TRENDLINES,
  TICK_TEXT,
  TICK_TEXT_FOR_RESPONSE_PLOT,
} from './constants';

import { getYTitle as getYTitleForParameter } from '../ParameterPlot/utils';

import { ParsedResponseData, PlotType, ResultType } from './types';
import { ReceiverResults } from '@/types';

export const createPlotlyObject = async (
  results: ReceiverResults,
  irNormalization: number,
  color: string,
  title: string,
  receiverId?: string
): Promise<ParsedResponseData | null> => {
  switch (results.resultType) {
    case ResultType['Impulse response']: {
      const toastId = `ir-download-error`;
      const { samples, finalTime } = await downloadAndDecodeWavFiles(results.uploadUrl, toastId, 'Impulse response');
      if (samples) {
        const { xAxis, yAxis } = parseWaveData(
          samples,
          finalTime,
          // NOTE: division by FS
          irNormalization / 32000
        );

        // Crate a normal number array out of the float64Data
        const yAxisNormal = [].slice.call(yAxis);

        const newTrace = createScatterTrace(xAxis, yAxisNormal, color, title, results.resultType, receiverId);
        return newTrace;
      }
      return null;
    }

    case ResultType['EDC']: {
      const toastId = `edc-download-error`;
      // the Frequency can be 0 for some reason, which we filter out
      if (results.frequency > 0) {
        const { samples, finalTime } = await downloadAndDecodeWavFiles(results.uploadUrl, toastId, 'EDC');
        if (samples) {
          const { xAxis, yAxis } = parseWaveData(samples, finalTime);

          // Crate a normal number array out of the float64Data
          const yAxisNormal = [].slice.call(yAxis);

          const newTrace = createScatterTrace(
            xAxis,
            yAxisNormal,
            color,
            title,
            results.resultType,
            receiverId,
            results.frequency
          );
          return newTrace;
        }
      } else {
        return null;
      }
      return null;
    }

    case ResultType['Frequency response']: {
      const toastId = `frequency-response-download-error`;
      const [xAxis, yAxis] = await downloadAndDecodeTextFiles(results.uploadUrl, toastId, 'Frequency response');
      const newTrace = createScatterTrace(
        xAxis,
        yAxis,
        color,
        title,
        results.resultType,
        receiverId,
        results.frequency
      );
      return newTrace;
    }

    default:
      return null;
  }
};

const downloadAndDecodeTextFiles = async (uploadUrl: string, toastId: string, type: string) => {
  try {
    const response = await fetch(uploadUrl);
    // read the response as text
    const text = await response.text();

    const splitStringArray = text.split(/\b\s+/);
    // @ts-expect-error because filter can't handle that we are parsing numbers and filtering
    const xAxis: number[] = splitStringArray.filter((splitString, index) => {
      if (index % 2 === 0) {
        return parseInt(splitString) + 1;
      }
    });
    // @ts-expect-error because filter can't handle that we are parsing numbers and filtering
    const yAxis: number[] = splitStringArray.filter((splitString, index) => {
      if (index % 2 !== 0) {
        return parseFloat(splitString);
      }
    });

    return [xAxis, yAxis];
  } catch (error) {
    toast.error(`Error reading results for ${type} graph. Please refresh the browser.`, {
      toastId,
    });
    return [];
  }
};

const downloadAndDecodeWavFiles = async (uploadUrl: string, toastId: string, type: string) => {
  try {
    const response = await fetch(uploadUrl);

    // read it as an array buffer
    const arrayBuffer = await response.arrayBuffer();

    // create a uInt8 array
    const uInt8Array = new Uint8Array(arrayBuffer);

    // so that we can create a Wave file to read from
    const wav = new WaveFile(uInt8Array);

    // get the samples to get an array with floating point numbers
    const samples = wav.getSamples(false, Float32Array);

    // @ts-expect-error because for some reason this library hadn't typed this object
    const samplingRate: number = wav?.fmt?.sampleRate;

    // get the final time from our custom made formula a la Ingimar
    const finalTime = samples.length * (1.0 / samplingRate);

    return {
      samples,
      finalTime,
    };
  } catch (error) {
    toast.error(`Error decoding Waveform audio file for ${type} graph. Please refresh the browser.`, {
      toastId,
    });
    return {};
  }
};

const calculateLogarithm = (rawIR: number, maxIR: number) => {
  return 20 * Math.log10(rawIR / maxIR);
};

// Formula from Ólafur to convert pressure based impulse response to dB
export const convertPressureBasedImpulseResponseToDb = (irPressure: number[] | string[]) => {
  const referenceValue = 2e-5;
  const rawIRs = [...irPressure].map((y) => Math.abs(Number(y)) / referenceValue);

  // Avoiding using Math.max to avoid "Maximum call stack size exceeded" since the rawIRs array can be very large
  let maxIR = -Infinity;
  for (const value of rawIRs) {
    if (value > maxIR) {
      maxIR = value;
    }
  }

  const irDb = rawIRs.map((rawIR) => calculateLogarithm(rawIR, maxIR));
  return irDb;
};

const parseWaveData = (float64Data: Float64Array, finalTime: number, irNormalization?: number) => {
  const normalizedYAxis = float64Data.map((float: number) => (irNormalization ? float * irNormalization : float));

  const linearXAxis = linspace(0.0, finalTime, float64Data.length);

  return {
    xAxis: linearXAxis,
    yAxis: normalizedYAxis,
  };
};

const createScatterTrace = (
  xAxis: number[],
  yAxis: number[],
  color: string,
  name: string,
  resultType: string,
  receiverId: string | undefined,
  frequency?: number
): ParsedResponseData => {
  return {
    marker: {
      color,
    },
    y: yAxis,
    x: xAxis,
    type: 'scatter' as PlotlyPlotType,
    mode: 'lines' as const,
    resultType,
    name: resultType === 'edc' ? `${frequency} Hz · ${name}` : name,
    frequency: frequency || 0,
    receiverId: receiverId ?? '',
  };
};

export const sortData = (firstElement: ParsedResponseData, secondElement: ParsedResponseData) => {
  if (firstElement.frequency < secondElement.frequency) {
    return -1;
  }
  if (secondElement.frequency > firstElement.frequency) {
    return 1;
  }
  return 0;
};

export const getFrequenzyLabelByValue = (filterValue: number) => {
  return `${FREQUENCY_VALUES[filterValue]} Hz`;
};

export const calculateFrequenzyFilterValue = (filterValue: number) => {
  return FREQUENCY_VALUES[filterValue];
};

export const frequenzyFilterMarks = TICK_TEXT.map((tickText, i) => ({
  value: i,
  label: `${tickText} Hz`,
}));

const calculateAxisRange = (plotData: Data[], xIsLogarithmic = false) => {
  let xAxisRange: number[] | null = null;
  let yAxisRange: number[] | null = null;

  if (plotData && plotData.length > 0) {
    // Find all x and y values, but filter out the trendlines
    const xValues = (plotData as PlotData[]).filter((x) => x.mode !== 'lines').flatMap((data) => data.x as number[]);
    const yValues = (plotData as PlotData[]).filter((x) => x.mode !== 'lines').flatMap((data) => data.y as number[]);

    // Compute margin values for the axis range, taking into consideration that if we have SPL we need to account for a logarithmic x axis
    const xMargin = !xIsLogarithmic
      ? ((Math.max(...xValues) - Math.min(...xValues)) * MANUAL_RANGE_MARGIN_PERCENT) / 100
      : ((Math.max(...xValues.map((x) => Math.log10(x))) - Math.min(...xValues.map((x) => Math.log10(x)))) *
          MANUAL_RANGE_MARGIN_PERCENT) /
        100;
    const yMargin = ((Math.max(...yValues) - Math.min(...yValues)) * MANUAL_RANGE_MARGIN_PERCENT) / 100;

    // Define axis ranges with margin
    xAxisRange = !xIsLogarithmic
      ? [Math.min(...xValues) - xMargin, Math.max(...xValues) + xMargin]
      : [
          Math.min(...xValues.map((x) => Math.log10(x))) - xMargin,
          Math.max(...xValues.map((x) => Math.log10(x))) + xMargin,
        ];
    yAxisRange = [Math.min(...yValues) - yMargin, Math.max(...yValues) + yMargin];
  }

  return {
    xAxisRange,
    yAxisRange,
  };
};

const getImpulseResponseConfig = (layoutConfig: Partial<Layout>, yAxisTitle: string) => ({
  ...layoutConfig,
  xaxis: {
    ...layoutConfig.xaxis,
    autorange: true,
    hoverformat: '.4f',
    title: {
      ...(layoutConfig.xaxis!.title as Partial<DataTitle>),
      text: 'TIME [s]',
    },
  },
  yaxis: {
    ...layoutConfig.yaxis,
    autorange: true,
    hoverformat: '.6f',
    title: {
      ...(layoutConfig.yaxis!.title as Partial<DataTitle>),
      text: yAxisTitle,
    },
  },
});

export const getPlotLayoutConfig = (
  selectedPlotType: PlotType,
  selectedParameter: string,
  plotData: Data[]
): Partial<Layout> => {
  let layoutConfig = { ...DEFAULT_LAYOUT_CONFIG };

  switch (selectedPlotType) {
    case 'EDC': {
      layoutConfig = {
        ...layoutConfig,
        xaxis: {
          ...layoutConfig.xaxis,
          autorange: true,
          title: {
            ...(layoutConfig.xaxis!.title as Partial<DataTitle>),
            text: 'TIME [s]',
          },
        },
        yaxis: {
          ...layoutConfig.yaxis,
          autorange: false,
          range: [-80, 0],
          title: {
            ...(layoutConfig.yaxis!.title as Partial<DataTitle>),
            text: 'ENERGY DECAY CURVE [dB]',
          },
        },
      };
      break;
    }
    case 'Frequency response': {
      layoutConfig = {
        ...layoutConfig,
        xaxis: {
          ...layoutConfig.xaxis,
          range: [Math.log10(20), Math.log10(12000)],
          tickmode: 'array' as 'array' | 'linear' | 'auto' | undefined,
          type: 'log' as AxisType,
          ticktext: TICK_TEXT_FOR_RESPONSE_PLOT,
          tickvals: FREQUENCY_VALUES_FOR_RESPONSE_PLOT,
          hoverformat: '',
          title: {
            ...(layoutConfig.xaxis!.title as Partial<DataTitle>),
            text: 'FREQUENCY [Hz]',
          },
        },
        yaxis: {
          ...layoutConfig.yaxis,
          autorange: true,
          title: {
            ...(layoutConfig.yaxis!.title as Partial<DataTitle>),
            text: 'SPL [dB] re. 20 μPa',
          },
        },
      };
      break;
    }
    case 'Impulse response': {
      layoutConfig = getImpulseResponseConfig(layoutConfig, 'PRESSURE');

      break;
    }
    case 'Impulse response dB': {
      layoutConfig = getImpulseResponseConfig(layoutConfig, 'dB');

      break;
    }

    case 'Spatial decay': {
      const { xAxisRange, yAxisRange } = calculateAxisRange(plotData, selectedParameter === 'spl');

      layoutConfig = {
        ...layoutConfig,
        xaxis: {
          ...layoutConfig.xaxis,
          type: selectedParameter === 'spl' ? ('log' as AxisType) : ('linear' as AxisType),
          autorange: !PARAMETERS_WITH_TRENDLINES.includes(selectedParameter),
          range: PARAMETERS_WITH_TRENDLINES.includes(selectedParameter) && xAxisRange ? xAxisRange : undefined,
          title: {
            ...(layoutConfig.xaxis!.title as Partial<DataTitle>),
            text: 'DISTANCE FROM SOURCE [m]',
          },
        },
        yaxis: {
          ...layoutConfig.yaxis,
          autorange: !PARAMETERS_WITH_TRENDLINES.includes(selectedParameter),
          range: ['sti', 'spl'].includes(selectedParameter) && yAxisRange ? yAxisRange : undefined,
          hoverformat: '.2f',
          title: {
            ...(layoutConfig.yaxis!.title as Partial<DataTitle>),
            text: getYTitleForParameter(selectedParameter),
          },
        },
      };
      break;
    }
    default:
      break;
  }

  return layoutConfig;
};

// Calculate A-weighted SPL
export const calculateAWeightedSpl = (L_p_S_n_i: number[]) => {
  let sum = 0;
  for (let i = 0; i < OCTAVE_BANDS.length; i++) {
    const val = L_p_S_n_i[i];

    if (!isNaN(val)) {
      sum += Math.pow(10, (L_p_S_n_i[i] + A_WEIGHTING[i]) / 10);
    }
  }

  return 10 * Math.log10(sum);
};

// Get the appropriate options for the frequency / nc-curve filter
export const getFrequencyOrNcCurveOptions = (selectedParameter: string) => {
  if (selectedParameter === 'sti') {
    return NC_CURVE_OPTIONS.map((x) => ({ id: x, name: x }));
  } else if (selectedParameter === 'spl') {
    return FREQUENCY_OPTIONS_SPL.map((x) => ({ id: x.value, name: x.label }));
  } else {
    return FREQUENCY_OPTIONS.map((x) => ({ id: x.value, name: x.label }));
  }
};
