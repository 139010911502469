import * as React from 'react';
import { useGizmoContext } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { CanvasTexture, Vector3 } from 'three';

type AxisHelperCubeProps = {
  size: number;
  color?: string;
  hoverColor?: string;
  onClick?: (e: ThreeEvent<MouseEvent>) => null;
};
type FaceTypeProps = { hover: boolean; index: number } & AxisHelperCubeProps;
type EdgeCubeProps = { dimensions: [number, number, number]; position: Vector3 } & Omit<AxisHelperCubeProps, 'color'>;

const colors = { bg: '#f0f0f0', hover: '#999999' };

const FaceMaterial = ({ hover, index, color = colors.bg, hoverColor = colors.hover }: FaceTypeProps) => {
  const gl = useThree((state) => state.gl);
  const texture = React.useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 128;
    canvas.height = 128;
    const context = canvas.getContext('2d')!;
    context.fillStyle = index == 0 || index == 1 ? '#555555' : index == 2 || index == 3 ? '#696969' : '#7a7a7a';
    context.fillRect(0, 0, canvas.width, canvas.height);

    return new CanvasTexture(canvas);
  }, [index, color]);

  return (
    <meshBasicMaterial
      map={texture}
      map-anisotropy={gl.capabilities.getMaxAnisotropy() || 1}
      attach={`material-${index}`}
      color={hover ? hoverColor : 'white'}
      transparent
      opacity={0.8}
    />
  );
};

const FaceCube = (props: AxisHelperCubeProps) => {
  const { tweenCamera } = useGizmoContext();
  const [hover, setHover] = React.useState<number | null>(null);
  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(null);
  };
  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    tweenCamera(e.face!.normal);
  };
  const handlePointerMove = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(Math.floor(e.faceIndex! / 2));
  };
  return (
    <mesh onPointerOut={handlePointerOut} onPointerMove={handlePointerMove} onClick={props.onClick || handleClick}>
      {[...Array(6)].map((_, index) => (
        <FaceMaterial key={index} index={index} hover={hover === index} {...props} />
      ))}
      <boxGeometry />
    </mesh>
  );
};

const EdgeCube = ({ onClick, dimensions, position, hoverColor = colors.hover }: EdgeCubeProps): JSX.Element => {
  const { tweenCamera } = useGizmoContext();
  const [hover, setHover] = React.useState<boolean>(false);
  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(false);
  };
  const handlePointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(true);
  };
  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    tweenCamera(position);
  };
  return (
    <mesh
      scale={1.01}
      position={position}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onClick={onClick || handleClick}>
      <meshBasicMaterial color={hover ? hoverColor : 'white'} transparent opacity={0.6} visible={hover} />
      <boxGeometry args={dimensions} />
    </mesh>
  );
};

export const AxisHelperCube = (props: AxisHelperCubeProps) => {
  return (
    <group scale={props.size}>
      <FaceCube {...props} />
      {edges.map((edge, index) => (
        <EdgeCube key={index} position={edge} dimensions={edgeDimensions[index]} {...props} />
      ))}
      {corners.map((corner, index) => (
        <EdgeCube key={index} position={corner} dimensions={[0.25, 0.25, 0.25]} {...props} />
      ))}
    </group>
  );
};

// Utils //
const makePositionVector = (xyz: number[]) => new Vector3(...xyz).multiplyScalar(0.38);

const corners: Vector3[] = [
  [1, 1, 1],
  [1, 1, -1],
  [1, -1, 1],
  [1, -1, -1],
  [-1, 1, 1],
  [-1, 1, -1],
  [-1, -1, 1],
  [-1, -1, -1],
].map(makePositionVector);

const edges: Vector3[] = [
  [1, 1, 0],
  [1, 0, 1],
  [1, 0, -1],
  [1, -1, 0],
  [0, 1, 1],
  [0, 1, -1],
  [0, -1, 1],
  [0, -1, -1],
  [-1, 1, 0],
  [-1, 0, 1],
  [-1, 0, -1],
  [-1, -1, 0],
].map(makePositionVector);

const edgeDimensions = edges.map(
  (edge) => edge.toArray().map((axis: number): number => (axis == 0 ? 0.5 : 0.25)) as [number, number, number]
);
