import { AudioSettings } from '@/components/Auralizer/types';

export const getFirstSimNormMax = (audioSettings: AudioSettings, firstSimId: string) => {
  let relMax = 0;
  let foundSourceId = null;
  const newAudioSettings: AudioSettings = { ...audioSettings };

  audioSettings.firstSimNormMax.firstSim = firstSimId;

  for (const sourceId in newAudioSettings[firstSimId]) {
    const normFactor = newAudioSettings[firstSimId][sourceId].normFactor;

    if (normFactor > relMax) {
      relMax = normFactor;
      foundSourceId = sourceId;
    }
  }

  // if relMax is 0 we want it to default to 1 instead because relMax can't
  // be 0 since we are using it to scale simulations in the Auralizer
  relMax = relMax === 0 ? 1 : relMax;
  newAudioSettings.firstSimNormMax.relMax = relMax;
  newAudioSettings.firstSimNormMax.rulingSource = foundSourceId;

  return newAudioSettings;
};
