import { useSimulationContext } from '@/context/SimulationContext';

export const useConfirmEdit = () => {
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();

  const confirmEdit = async () => {
    return new Promise<boolean>((resolve) => {
      if (selectedSimulation) {
        const shouldConfirm =
          selectedSimulation.lastSimulationRun &&
          selectedSimulation.extra.status === 2 &&
          (!selectedSimulation.hasBeenEdited || selectedSimulation.hasBeenEdited === null);
        if (shouldConfirm) {
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  };

  return confirmEdit;
};
