/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import {
  FREQUENCY_TEXT_TICKS,
  FREQUENCY_VALUES,
  FREQUENCY_VALUES_PER_THIRD_OCTAVE,
  FREQUENCY_VALUES_TICKS,
} from './constants';

type SPLPlot = {
  splData: number[];
  eqData?: number[] | null;
};

export const SPLPlot = ({ splData, eqData }: SPLPlot) => {
  const [plotlSplData, setPlotlSplData] = useState<Data[]>([] as Data[]);
  const [yAxisRange, setYAxisRange] = useState<number[]>([]);

  useEffect(() => {
    if (splData?.length) {
      const [min, max] = getMinMax(splData);
      // set min and max with margin of 5 , let smallest min be -0.6 so that the dotted zero line is visible
      setYAxisRange([min > 5 ? min - 5 : -0.6, max + 5]);

      const plotData = [
        {
          x: FREQUENCY_VALUES_PER_THIRD_OCTAVE,
          y: splData,
          type: 'scatter',
          mode: 'lines+markers',
          name: 'SPL on axis',
          marker: {
            size: 5,
          },
          line: {
            color: '#00F5BA',
            width: 1.5,
          },
        },
      ] as Data[];

      if (eqData?.length) {
        const splWithEQ = applyEQToSPL(splData, eqData, FREQUENCY_VALUES_PER_THIRD_OCTAVE, FREQUENCY_VALUES);

        plotData.push({
          x: FREQUENCY_VALUES_PER_THIRD_OCTAVE,
          y: splWithEQ,
          type: 'scatter',
          mode: 'lines+markers',
          name: "EQ'ed SPL on axis",
          marker: {
            size: 4,
          },
          line: {
            width: 1.5,
            color: '#f2d367',
          },
        });
      }
      setPlotlSplData(plotData);
    }
  }, [splData, eqData]);

  function getMinMax(data: any[]) {
    const numberArray: any = [];

    data.forEach((number: number) => {
      if (!isNaN(number) && number !== null) numberArray.push(number);
    });

    return [Math.min(...numberArray), Math.max(...numberArray)];
  }

  function applyEQToSPL(splArray: any, eqArray: any, splFrequencies: any, eqFrequencies: any) {
    const result = [];

    // go though SPL values and apply EQ to them
    for (let i = 0; i < splArray.length; i++) {
      const spl = splArray[i];
      const freqIndex = eqFrequencies.findIndex((v: any) => v >= splFrequencies[i]);

      let eqSpl = spl;

      if (freqIndex == 0) {
        // if frequency is 63 or bellow
        eqSpl = spl + eqArray[0];
      } else if (freqIndex == -1) {
        // if frequency is 8k or above
        eqSpl = spl + eqArray[7];
      } else {
        // if frequency is between 63 and 8k, then split the values into 3 parts and interpolate
        // the difference of the two values between the frequencies bands by multiplying
        // with 0.333 or 0.666

        const eqValue = eqArray[freqIndex];
        const eqDiff = eqArray[freqIndex - 1] - eqArray[freqIndex];

        if (eqFrequencies[freqIndex] == splFrequencies[i]) {
          eqSpl = spl + eqValue;
        } else if (i + 1 < splArray.length && eqFrequencies[freqIndex] == splFrequencies[i + 1]) {
          eqSpl = spl + (eqValue + eqDiff * 0.333);
        } else if (i + 2 < splArray.length && eqFrequencies[freqIndex] == splFrequencies[i + 2]) {
          eqSpl = spl + (eqValue + eqDiff * 0.666);
        }
      }

      result.push(eqSpl);
    }

    return result;
  }

  return (
    <Plot
      data={plotlSplData}
      config={{
        displaylogo: false,
      }}
      layout={{
        width: 360,
        height: 254,
        autosize: true,
        legend: {
          y: 1.15,
          orientation: 'h',
          font: {
            color: '#DADADA',
            size: 10,
          },
        },
        xaxis: {
          title: {
            text: 'FREQUENCY [Hz]',
            font: {
              size: 10,
              color: '#adadad',
            },
          },
          tickmode: 'array',
          ticktext: FREQUENCY_TEXT_TICKS,
          tickvals: FREQUENCY_VALUES_TICKS,
          type: 'log',
          gridcolor: '#3c3c3c',
          griddash: 'dot',
          range: [Math.log10(22), Math.log10(23000)],
        },
        yaxis: {
          range: yAxisRange,
          title: {
            text: 'SPL ON AXIS [dB]',
            font: {
              size: 10,
              color: '#adadad',
            },
            standoff: 10,
          },

          gridcolor: '#3c3c3c',
          griddash: 'dot',
          zeroline: false,
        },
        margin: {
          l: 38,
          r: 0,
          b: 38,
          t: 15,
          pad: 5,
        },
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        font: {
          family: 'Inter, Helvetica, Arial, sans-serif',
          color: '#F5F5F5',
          size: 10,
        },
      }}
    />
  );
};
