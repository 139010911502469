import { useEffect, useRef, useState } from 'react';

import { useSimulationContext } from '@/context/SimulationContext';

import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { HideLayerCell } from './HideLayerCell';
import { LayerNameCell } from './LayerNameCell';
import { MaterialNameCell } from './MaterialNameCell';
import { ScatterCell } from './ScatterCell/ScatterCell';

import { useHandleRowClick } from './hooks/useHandleRowClick';
import { useScrollIntoView } from './hooks/useScrollIntoView';

import { MaterialLayer } from '@/types';

export const LayerRowChildContent = ({
  layer,
  parentLayer,
  textName,
  updateLayerChildren,
}: {
  layer: MaterialLayer;
  textName: string;
  updateLayerChildren: (layer: MaterialLayer) => void;
  parentLayer: MaterialLayer;
}) => {
  const layerItem = useRef(null);

  const {
    simulationState: { hiddenLayers },
  } = useSimulationContext();
  const { selectedLayer, multiSelectedItemIds } = useLibraryPanelContext();
  const [isSelected, setIsSelected] = useState(false);
  const handleRowClick = useHandleRowClick();
  useScrollIntoView(layer, layerItem);

  useEffect(() => {
    setIsSelected(
      selectedLayer?.id === layer.id || multiSelectedItemIds?.includes(layer.id) || parentLayer.id === selectedLayer?.id
    );
  }, [multiSelectedItemIds, selectedLayer]);

  useEffect(() => {
    if (layerItem.current && isSelected) {
      (layerItem.current as HTMLElement)?.focus();
    }
  }, [layerItem, isSelected]);

  return (
    <div
      ref={layerItem}
      className={`treble-layer-row ${isSelected ? 'selected' : ''} ${isSelected ? 'child-selected' : ''}`}
      onClick={(event) => handleRowClick(event, layer, layer.layerGroupIndex, layer.layerIndex)}>
      <div className="treble-layers-cell first-cell"></div>
      <LayerNameCell layer={layer} />
      <MaterialNameCell
        layer={layer}
        isSelected={isSelected}
        index={layer.layerGroupIndex}
        childIndex={layer.layerIndex}
      />
      <ScatterCell layerName={textName} layer={layer} updateLayerChildren={updateLayerChildren} />
      <HideLayerCell
        isSelected={isSelected}
        layer={layer}
        parentLayer={parentLayer}
        isHidden={hiddenLayers.findIndex((x) => x.id === layer.id) > -1}
      />
    </div>
  );
};
