import { ResultComparisonState } from '../../types';
import { ResultType, ResultTypeOption, StatusType } from '@/types';

export enum ResultComparisonLabels {
  LABEL = 'Label',
  PROJECT = 'Space',
  MODEL = 'Model',
  SIMULATION = 'Simulation',
  SOURCE = 'Source',
  RECEIVER = 'Receiver',
  RECEIVERS = 'Receivers',
  SURFACE_RECEIVERS = 'Surface receivers',
  RESULT_TYPE = 'Result type',
}

export const resultTypes: Array<ResultTypeOption> = [
  { name: 'Geometrical', id: ResultType.GA },
  { name: 'Wave-based', id: ResultType.DG },
  { name: 'Hybrid', id: ResultType.Hybrid },
];

export const initialState: ResultComparisonState = {
  availableResultTypes: [],
  title: '',
  modelName: '',
  spaceName: '',
  lastSolveResults: null,
  lastGridResults: null,
  lastSourceSummingResults: null,
  lastGridReceiverSourceSummingResults: null,
  simulationId: '',
  selectedSimulation: null,
  availableSources: [],
  availableSummedSources: [],
  sourcePointIds: [],
  availableReceivers: [],
  resultType: '',
  lastSelectedResultType: '',
  simulationData: null,
  sourceSummingSelectionEnabled: false,
  selectedSourcesForSumming: [],
};

export enum ActionType {
  SELECT_SIMULATION = 'SELECT_SIMULATION',
  SELECT_SOURCE = 'SELECT_SOURCE',
  SELECT_RECEIVERS = 'SELECT_RECEIVERS',
  UPDATE_SOLVE_RESULTS = 'UPDATE_SOLVE_RESULTS',
  SELECT_GRID_SOURCE = 'SELECT_GRID_SOURCE',
  SELECT_GRID_RECEIVERS = 'SELECT_GRID_RECEIVERS',
  UPDATE_GRID_RECEIVERS_SOLVE_RESULTS = 'UPDATE_GRID_RECEIVERS_SOLVE_RESULTS',
  UPDATE_GRID_RECEIVER_RESULT_PARAMETERS = 'UPDATE_GRID_RECEIVER_RESULT_PARAMETERS',
  UPDATE_GRID_RECEIVER_RESULT_PARAMETERS_FOR_SUMMED_SOURCES = 'UPDATE_GRID_RECEIVER_RESULT_PARAMETERS_FOR_SUMMED_SOURCES',
  UPDATE_SOURCE_SUMMING_RESULTS = 'UPDATE_SOURCE_SUMMING_RESULTS',
  UPDATE_GRID_RECEIVER_SOURCE_SUMMING_RESULTS = 'UPDATE_GRID_RECEIVER_SOURCE_SUMMING_RESULTS',
  SELECT_RESULT_TYPE = 'SELECT_RESULT_TYPE',
  UPDATE_LABEL = 'UPDATE_LABEL',
  SET_SOURCE_SUMMING_SELECTION_ENABLED = 'SET_SOURCE_SUMMING_SELECTION_ENABLED',
  SET_SELECTED_SOURCES_FOR_SUMMING = 'SET_SELECTED_SOURCES_FOR_SUMMING',
  ADD_IN_PROGRESS_SOURCE_SUMMING = 'ADD_IN_PROGRESS_SOURCE_SUMMING',
  REMOVE_IN_PROGRESS_SOURCE_SUMMING = 'REMOVE_IN_PROGRESS_SOURCE_SUMMING',
  UPDATE_SUMMED_SOURCE_LABEL = 'UPDATE_SUMMED_SOURCE_LABEL',
}

export const SourceSummingProgressStates = [
  StatusType.Created,
  StatusType.Pending,
  StatusType.InProgress,
  StatusType.ProcessingResults,
  StatusType.Queued,
];

export const SOURCE_SUMMING_REFETCH_INTERVAL = 3000;
