import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useEditorContext } from '@/context/EditorContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Drawer, IconButton } from '@mui/material';

import { TrblCloseIcon, TrblRecentSimulation, TrblRecentSimulations } from '../Icons';
import { RecentSimulationsContent } from './RecentSimulationsContent';
import { RecentSimulationStatusDisplay } from './RecentSimulationStatusDisplay';

import { useGetAllSimulationsForSpaces } from '../Results/hooks/useGetAllSimulationsForSpaces';
import { getSimulationRunById, useGetSimulationRunStatusesByUser } from '@/hooks';

import { RunStatus, SimulationRunDto, SimulationRunStatusDto, SourceStatus, TaskStatus } from '@/types';

import classes from './styles.module.scss';

let hasLoadedOnce = false;

const isRunningStatus = (status: RunStatus) => {
  return [RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults, RunStatus.Queued].includes(status);
};

export const RecentSimulationNav = () => {
  const location = useLocation();
  const {
    simulationState: { selectedSimulation },
    dispatch,
  } = useSimulationContext();
  const { isAuralizerOpen } = useEditorContext();
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  function openSidebar() {
    setIsSidebarActive(true);
  }

  function closeSidebar() {
    setIsSidebarActive(false);
  }

  const { data: recentSimulations, isFetching: isFetchingTaskStatuses, refetch } = useGetSimulationRunStatusesByUser(6);
  const { refetch: refetchAllSimulationsForSpaces } = useGetAllSimulationsForSpaces(false);

  const [runningSimulations, setRunningSimulations] = useState<Array<SimulationRunStatusDto>>([]);
  const [latestSimRunStatus, setLatestSimRunStatus] = useState<SimulationRunStatusDto | null>(null);
  const [latestTaskRun, setLatestTaskRun] = useState<TaskStatus | null>(null);

  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    if (selectedSimulation?.lastSimulationRun && selectedSimulation?.extra.status === 1) {
      refetch();
    }
  }, [selectedSimulation?.lastSimulationRun, selectedSimulation?.extra.status]);

  if (!isFetchingTaskStatuses && recentSimulations) {
    if (recentSimulations.length) {
      hasLoadedOnce = true;
    }
  }

  useEffect(() => {
    // every time route params change we close the recent simulation sidebar so it doesn't overlap the content
    setIsSidebarActive(false);
  }, [routeParams]);

  const currentModelId = useMemo(() => {
    if (location.pathname.includes('/editor')) {
      const { id: paramModelId = '' } = routeParams;
      const queryModelId = searchParams.get('mid') || '';

      return paramModelId || queryModelId;
    }
  }, [location.pathname]);

  // Map through all recent simulations that were previously running but are have now stopped running
  // Load the simulation run object for each of the completed ones to update in simulation context
  const completedSimulationRuns = useQueries({
    queries: recentSimulations?.length
      ? recentSimulations
          .filter(
            (simRunStatus) =>
              simRunStatus.status != null &&
              !isRunningStatus(simRunStatus.status) &&
              runningSimulations.findIndex((s) => s.id === simRunStatus.id) > -1
          )
          .map((simRunStatus) => {
            return {
              queryKey: ['simulationRun', simRunStatus.id],
              queryFn: () => getSimulationRunById(simRunStatus.id),

              onSuccess: (simRun: SimulationRunDto) => {
                // Only trigger update if this is a simulation for current model
                if (currentModelId === simRunStatus.modelId) {
                  dispatch({
                    type: ActionType.SET_LAST_SIMULATION_RUN,
                    simulationRun: simRun,
                  });
                }

                if (simRun.status === RunStatus.Completed) {
                  if (location.pathname.includes('/results')) {
                    refetchAllSimulationsForSpaces();
                  }

                  toast.success(
                    <>
                      <Link to={`/results?mid=${simRunStatus.modelId}&sid=${simRunStatus.simulationId}`}>
                        <p>Simulation completed</p>
                        <span> '{simRunStatus.simulationName}'</span>
                      </Link>
                    </>,
                    { autoClose: 10000 }
                  );
                }
              },
            };
          })
      : [],
  });
  const isFetchingCompletedSimulationRun = completedSimulationRuns.some((r) => r.isFetching);

  const getLatestTaskRun = (sources: SourceStatus[]): TaskStatus | null => {
    let latestTaskStatus: null | TaskStatus = null;

    sources.forEach((item) => {
      if (item.taskStatuses?.length) {
        // Sort the tasks for each source by completedAt
        item.taskStatuses?.sort(
          (taskA: TaskStatus, taskB: TaskStatus) => dayjs(taskB.completedAt).unix() - dayjs(taskA.completedAt).unix()
        );

        // If the latestTaskStatus is not set or this source contains a later completedAt date we assign it
        if (
          latestTaskStatus === null ||
          dayjs(item.taskStatuses[0].completedAt).unix() > dayjs(latestTaskStatus.completedAt).unix()
        ) {
          latestTaskStatus = item.taskStatuses[0];
        }
      }
    });

    return latestTaskStatus;
  };

  useEffect(() => {
    // We need to wait for the simulation run fetch to complete so we don't mess up the useQueries above
    if (!isFetchingCompletedSimulationRun) {
      const orderedSimulationsInProgress =
        recentSimulations
          ?.filter((s) => s.status != null && s.sources?.length && isRunningStatus(s.status))
          .sort((a, b) => +b.createdAt - +a.createdAt) || [];
      setRunningSimulations(orderedSimulationsInProgress);

      const latestSimRunStatus = orderedSimulationsInProgress.length ? orderedSimulationsInProgress[0] : null;
      setLatestSimRunStatus(latestSimRunStatus);

      // show 99% done before showing 100% processing results
      if (
        latestSimRunStatus &&
        latestSimRunStatus.status !== RunStatus.ProcessingResults &&
        latestSimRunStatus.progressPercentage == 100
      ) {
        setPercentage(99);
      } else {
        setPercentage(latestSimRunStatus?.progressPercentage || 0);
      }

      const latestTaskRun = latestSimRunStatus ? getLatestTaskRun(latestSimRunStatus.sources!) : null;
      setLatestTaskRun(latestTaskRun);
    }
  }, [recentSimulations, isFetchingCompletedSimulationRun]);

  const handleChildClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {hasLoadedOnce && (
        <>
          <div className={classes.recent_simulation_nav_container}>
            <button disabled={isAuralizerOpen} onClick={openSidebar} className={classes.recent_simulations_button}>
              {latestSimRunStatus ? (
                <>
                  <TrblRecentSimulation></TrblRecentSimulation>
                  <div
                    className={classes.recent_simulation_count}
                    title={runningSimulations.length.toString() + ' simulations running'}>
                    {runningSimulations.length.toString()}
                  </div>
                  <div
                    className={classes.recent_simulation_nav}
                    title={latestSimRunStatus.spaceName + ' › ' + latestSimRunStatus.modelBaseName}>
                    <>
                      {currentModelId && latestSimRunStatus.modelId === currentModelId ? (
                        <p className={classes.simulation_name}>{latestSimRunStatus.simulationName}</p>
                      ) : (
                        <a
                          href={`/editor?mid=${latestSimRunStatus.modelId}`}
                          onClick={handleChildClick}
                          title={'Go to ' + latestSimRunStatus.spaceName + ' › ' + latestSimRunStatus.modelBaseName}>
                          <p className={classes.simulation_name}>{latestSimRunStatus.simulationName}</p>
                        </a>
                      )}
                    </>
                    {(latestTaskRun || latestSimRunStatus.status === RunStatus.Created) && (
                      <RecentSimulationStatusDisplay
                        simulationStatus={latestSimRunStatus.status || RunStatus.Completed}
                        taskStatus={latestTaskRun}
                        percentage={percentage}
                        timeEstimate={latestSimRunStatus.timeEstimate || null}
                        startedAt={latestSimRunStatus.createdAt}
                        completedAt={latestSimRunStatus.completedAt}
                        parent="Nav"
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <TrblRecentSimulations width="17" height="17"></TrblRecentSimulations>
                  <div className={classes.recent_simulation_nav} title="Open Recent simulations">
                    <h4>Recent simulations</h4>
                  </div>
                </>
              )}
            </button>
          </div>
          <Drawer
            open={isSidebarActive}
            anchor="right"
            onClose={closeSidebar}
            PaperProps={{
              style: {
                position: 'absolute',
                width: '360px',
                background: '#272727',
                boxShadow: '0px 6px 20px rgb(0 0 0 / 50%)',
                top: '-60px',
              },
            }}
            BackdropProps={{ style: { position: 'absolute', backdropFilter: 'blur(5px)' } }}
            ModalProps={{
              container: document.getElementsByClassName('page-layout')[0],
              style: {
                position: 'fixed',
                height: '100%',
                width: '100%',
                inset: 'unset',
              },
              keepMounted: true,
            }}
            variant="temporary">
            <RecentSimulationsContent showHeader={true} />
            <IconButton className={classes.close_btn} aria-label="close" onClick={closeSidebar}>
              <TrblCloseIcon />
            </IconButton>
          </Drawer>
        </>
      )}
    </>
  );
};
