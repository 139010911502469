import { useMemo } from 'react';

import {
  FrequencyWeightingType,
  ReflectionDetails,
} from '@/components/Results/context/ReflectogramResultsContext/types';

import { getSplValues } from '../../utils';

export const useDbAxisRange = (
  data: ReflectionDetails[],
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): [number, number] => {
  return useMemo(() => {
    const splData = getSplValues(data, selectedFrequencyWeighting, selectedFrequencyBandIndex);
    const minDb = Math.min(...splData) - 10;
    const maxDb = Math.max(...splData);
    return [Math.max(minDb, -150), Math.max(maxDb, 0)];
  }, [data, selectedFrequencyWeighting, selectedFrequencyBandIndex]);
};
