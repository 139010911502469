import { useMemo } from 'react';

import { FilterTag } from '../Shared/Tags';

import styles from './styles.module.scss';

export const SoundLibraryFilters = ({
  categories,
  selectCategory,
  selectedCategories,
}: {
  categories: string[];
  selectCategory: (tag: string, event: React.MouseEvent<HTMLElement>) => void;
  selectedCategories: string[];
}) => {
  const sortedCategories = useMemo(
    () =>
      [...categories].sort((a, b) => {
        const nameA = a.toLowerCase();
        const nameB = b.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }),
    [categories]
  );

  return (
    <div className={styles['filter-container']}>
      {sortedCategories.map((category) => (
        <FilterTag
          key={category}
          tag={category}
          selectTag={selectCategory}
          isSelected={selectedCategories.includes(category)}
        />
      ))}
    </div>
  );
};
