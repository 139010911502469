import { useState } from 'react';

import { TrblCaretDownIcon } from '@/components/Icons';

import { SUBSCRIPTIONS } from '../constants';

import { SubscriptionLength, SubscriptionType } from '../types';

import styles from '../styles.module.scss';

type SubscriptionOption = {
  type: SubscriptionType;
  selectedSubscription: SubscriptionType | null;
  yearlyPeriod: boolean;
  selectSubscription: (type: SubscriptionType, subscriptionLength: SubscriptionLength) => void;
};

export const SubscriptionOption = ({
  type,
  selectedSubscription,
  yearlyPeriod,
  selectSubscription,
}: SubscriptionOption) => {
  const [foldableIsOpen, setFoldableIsOpen] = useState(false);

  const openFoldable = () => {
    setFoldableIsOpen(!foldableIsOpen);
  };

  return (
    <div
      className={`${styles['subscription-card']} ${
        selectedSubscription?.toLowerCase() === type ? styles['selected'] : ''
      }`}>
      <div style={{ marginBottom: '20px' }}>
        <h3>{type}</h3>
        <div className={styles['card-description']}>
          <p> {SUBSCRIPTIONS[type].description} </p>
        </div>
        <h3>
          {yearlyPeriod && SUBSCRIPTIONS[type].monthlyPrice && (
            <span className={styles['strikethrough-price']}>
              {' '}
              {SUBSCRIPTIONS[type].monthlyPrice} <span className={styles['strikethrough-line']}></span>
            </span>
          )}
          <span className={styles['card-price']}>
            {yearlyPeriod
              ? SUBSCRIPTIONS[type].yearlyPrice
              : !SUBSCRIPTIONS[type].monthlyPrice
              ? SUBSCRIPTIONS[type].yearlyPrice
              : SUBSCRIPTIONS[type].monthlyPrice}
          </span>
          <span className={styles['card-per']}>/ {SUBSCRIPTIONS[type].per}</span>
        </h3>
      </div>

      <div>
        <ul>
          {yearlyPeriod
            ? SUBSCRIPTIONS[type].includedYearly.map((incl, index) => (
                <li className={styles['card-text']} key={index}>
                  {incl}
                </li>
              ))
            : SUBSCRIPTIONS[type].includedMonthly.map((incl, index) => (
                <li className={styles['card-text']} key={index}>
                  {incl}
                </li>
              ))}
        </ul>
        <p className={styles['card-highlight']}> {SUBSCRIPTIONS[type].highlight}</p>
      </div>

      {SUBSCRIPTIONS[type].tokenBundlePricing && (
        <div className={styles['token-price']}>
          <button onClick={openFoldable}>
            Token bundle pricing
            <span style={{ marginLeft: '6px' }}>
              <TrblCaretDownIcon />
            </span>
          </button>
          <div className={`${styles['foldable']} ${foldableIsOpen ? styles['open'] : ''}`}>
            <ul>
              {SUBSCRIPTIONS[type].tokenBundlePricing?.map((pricing, index) => (
                <li className={styles['card-text']} key={index}>
                  {pricing}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div>
        <ul className={styles['access-text']}>
          {SUBSCRIPTIONS[type].access.map((acc, index) => (
            <li className={styles['card-text']} key={index}>
              {acc}
            </li>
          ))}
        </ul>
        {type === 'enterprise' ? (
          <a
            className={styles['subscription-action']}
            target="_blank"
            rel="noopener noreferrer"
            href={SUBSCRIPTIONS[type].actionLink}>
            {SUBSCRIPTIONS[type].actionText}
          </a>
        ) : (
          <button
            disabled={selectedSubscription ? !['Trial', 'EarlyBird'].includes(selectedSubscription) : false}
            className={styles['subscription-action']}
            onClick={() => selectSubscription(type, yearlyPeriod ? 'yearly' : 'monthly')}>
            {selectedSubscription?.toLowerCase() === type ? 'Your subscription' : SUBSCRIPTIONS[type].actionText}
          </button>
        )}
      </div>
    </div>
  );
};
