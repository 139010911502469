import { ReactNode } from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { Marker } from '@/components/SourceRecieverSettings/Marker';

import classes from './styles.module.scss';

interface SimulationFormSelect {
  label: string;
  value: string;
  options: {
    id?: string | undefined;
    name: string;
    coords?: string | undefined;
  }[];
  formControlChangeEvent: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  disabled?: boolean;
  placeholder?: string;
}
export const SimulationFormSelect = (formData: SimulationFormSelect) => {
  const { label, value, options, formControlChangeEvent, disabled, placeholder } = formData;
  const formControlId = `select-${label}-label`;

  return (
    <FormControl fullWidth disabled={disabled} className={classes.simulation_form_control__container}>
      <InputLabel id={formControlId}> {label} </InputLabel>
      {/* TODO: create a Treble Select for forms to standardize the styling and behavior (?) */}
      <Select
        displayEmpty
        name={label}
        className={classes.treble_select}
        labelId={formControlId}
        id={formControlId}
        value={value}
        label={label}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }

          const index = options.map((item) => item.id).indexOf(selected);

          let selectedName;
          if (index > -1) selectedName = options[index].name;
          else selectedName = '--';

          if (index > -1 && options && options[index].coords) {
            return (
              <Box component="span" display={'flex'} alignItems="center">
                <Marker color={label == 'Source' ? 'green' : 'blue'} label={(index + 1).toString()} small />
                <span className={classes.label} title={selectedName.length > 19 ? selectedName : ''}>
                  {selectedName}
                </span>{' '}
              </Box>
            );
          } else return <span>{selectedName}</span>;
        }}
        onChange={formControlChangeEvent}
        onClick={(e) => e.stopPropagation()}>
        {options?.map((option, index) => (
          <MenuItem key={option.id} value={option.id} className={classes.treble_select_menu}>
            <Box component="div" display="flex" alignItems="center" gap="7px">
              {(label == 'Source' || label == 'Receiver') && (
                <Marker color={label == 'Source' ? 'green' : 'blue'} label={(index + 1).toString()} small />
              )}
              <span className={classes.label}>{option.name}</span>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
