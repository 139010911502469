export const TrblRecentSimulation = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0997 4.2154L16.394 3.05943C16.1751 2.96685 15.924 2.99311 15.7291 3.12898C15.5341 3.26484 15.4225 3.49128 15.4335 3.72867L15.5833 6.67047C15.5953 6.91057 15.7315 7.12709 15.9427 7.24196C16.1539 7.35684 16.4096 7.3535 16.6177 7.23316C16.6504 7.2143 16.6129 7.23592 17.1748 6.84777C18.4184 9.67209 17.8897 12.9632 15.8241 15.2559C13.7585 17.5486 10.5401 18.4165 7.60188 17.4732C7.28244 17.3451 6.91822 17.4061 6.65788 17.6312C6.39753 17.8563 6.28462 18.2079 6.36523 18.5425C6.44584 18.877 6.70648 19.1386 7.04076 19.2205C10.7322 20.4084 14.7764 19.279 17.3182 16.3503C19.8601 13.4217 20.4093 9.25887 18.7138 5.77125L19.2272 5.4132C19.4319 5.26921 19.5424 5.02559 19.5159 4.77678C19.4894 4.52797 19.3301 4.31307 19.0997 4.2154Z"
      fill="#DADADA"
    />
    <path
      d="M3.33706 12.7944L2.82009 13.1556C1.54523 10.2643 2.12889 6.89035 4.30096 4.59532C6.47302 2.30029 9.80971 1.5319 12.7668 2.64577C13.2399 2.81348 13.76 2.57102 13.9358 2.10089C14.1116 1.63077 13.878 1.10653 13.411 0.92276C9.70121 -0.477539 5.51257 0.527238 2.84178 3.45811C0.171 6.38898 -0.441328 10.6527 1.2967 14.2167L0.779058 14.5804C0.572041 14.7236 0.459454 14.9682 0.485292 15.2186C0.51113 15.469 0.671292 15.6854 0.903191 15.7834L3.61006 16.9376C3.82891 17.0313 4.0806 17.0055 4.27591 16.8694C4.47123 16.7333 4.58259 16.5061 4.57052 16.2684L4.42119 13.3274C4.40868 13.0767 4.26121 12.8525 4.03597 12.7418C3.81074 12.6311 3.54317 12.6512 3.33706 12.7944Z"
      fill="#DADADA"
    />
    <path
      d="M9.11367 13.9275C9.27725 13.9879 9.46113 13.94 9.5745 13.8075L12.8587 9.9842C13.017 9.79878 13.053 9.53823 12.951 9.3168C12.849 9.09537 12.6275 8.95349 12.3837 8.95336H11.5503C11.4353 8.95336 11.342 8.86009 11.342 8.74503V6.0367C11.342 5.8625 11.2335 5.70671 11.0702 5.64614C10.9069 5.58556 10.7231 5.63299 10.6095 5.76503L7.3245 9.5892C7.16609 9.77473 7.13011 10.0355 7.23235 10.257C7.3346 10.4785 7.55637 10.6202 7.80033 10.62H8.63367C8.74872 10.62 8.842 10.7133 8.842 10.8284V13.5367C8.84191 13.711 8.95029 13.8669 9.11367 13.9275Z"
      fill="#00F5BA"
    />
    <rect x="5" y="5" width="10" height="10" rx="5" fill="#00F5BA" />
  </svg>
);
