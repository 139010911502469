import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { SharedAuralizationInfoDto } from '../types';

import axios from '@/axios';

type UpdateSharedAuralizationDto = {
  id: string;
  auralizationPresetId: string;
  emails: string[];
  expiresAt: string;
  createAnonymousUrl: boolean;
};

const updateSharedAuralization = async (
  sharedAuralization: UpdateSharedAuralizationDto
): Promise<SharedAuralizationInfoDto> => {
  const { data } = await axios.patch(`/api/SharedAuralization/UpdateSharedAuralization`, sharedAuralization);
  return data;
};

export const useUpdateSharedAuralization = () => {
  return useMutation(
    async (sharedAuralization: UpdateSharedAuralizationDto) => await updateSharedAuralization(sharedAuralization),
    {
      onError: () => {
        toast.error('An error occurred while sharing the auralization. Please refresh the browser.');
      },
    }
  );
};
