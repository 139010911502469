import { useQuery } from '@tanstack/react-query';

import { MeshTaskDto } from '../types';

import axios from '@/axios';

export const startMeshTask = async (crossoverFrequency: number, modelId: string): Promise<MeshTaskDto> => {
  const settings = {
    basisOrder: 4,
    elementsPerWavelength: 1.585,
    preferredTaskProvider: 'Unspecified',
    crossoverFrequency,
  };
  const { data } = await axios.patch<MeshTaskDto>(
    `/api/Mesh/StartMeshTask?modelId=${modelId}`,
    JSON.stringify(settings || {})
  );

  return data;
};

export const useStartMeshTask = (crossoverFrequency: number, modelId: string) => {
  return useQuery<MeshTaskDto>(['modelId', modelId], () => startMeshTask(crossoverFrequency, modelId), {
    enabled: !!modelId && !!crossoverFrequency,
    refetchOnWindowFocus: false,
  });
};
