/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { Divider } from '@/components/Shared/Divider';
import { TrblTruncate } from '@/components/SoundLibrary/TrblTruncate';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { RecentUploadStatusDisplay } from '../../../RecentSimulations/RecentUploadStatusDisplay';
import { Text } from '../../../Shared/Text';

import { SpaceExtractionTask } from '../../types';
import { IActions } from '@/types';

import styles from '../../styles.module.scss';

export const StatusSidebar = ({
  empty,
  isSidebarActive,
  spaceExtractionTasks,
  goNext,
  handleCancelUpload,
}: {
  empty?: boolean;
  isSidebarActive: boolean;
  spaceExtractionTasks?: SpaceExtractionTask[];
  goNext: (id: string, filename: string) => void;
  handleCancelUpload: (id: string) => void;
}) => {
  const [uploadsReady, setUploadsReady] = useState<any>([]);
  const [uploadsOther, setUploadsOther] = useState<any>([]);

  useEffect(() => {
    if (spaceExtractionTasks?.length) {
      const tempUploadsReady = [];
      const tempUploadsOther = [];
      for (const multiSpaceTask of spaceExtractionTasks) {
        if (multiSpaceTask.task.status === 'Completed' && !multiSpaceTask.hasImportedModel) {
          tempUploadsReady.push(multiSpaceTask);
        } else {
          tempUploadsOther.push(multiSpaceTask);
        }
      }
      setUploadsReady(tempUploadsReady);
      setUploadsOther(tempUploadsOther);
    }
  }, [spaceExtractionTasks]);

  const spaceActions = (id: string, status: string): IActions[] => [
    {
      value: 'Cancel',
      onClick: () => {
        handleCancelUpload(id);
      },
      key: 'cancel-upload',
      hidden: status !== 'InProgress' && status !== 'Pending' && status !== 'ProcessingResults',
    },
  ];

  return (
    <div
      className={`${styles['recent-uploads-sidepanel']} ${isSidebarActive ? styles['active'] : ''} ${
        empty ? styles['empty'] : ''
      } `}>
      <div className={styles['recent-uploads-list']}>
        {!spaceExtractionTasks?.length ? (
          <Box component="div" padding={'16px 20px 20px 20px'}>
            <Text type="regular-11px" color={'#999'}>
              There are no recent uploads
            </Text>
          </Box>
        ) : (
          <>
            {uploadsReady.length > 0 && (
              <>
                <div className={styles['ready-imports-box']}>
                  {uploadsReady.map((spaceExtractionTask: any) => (
                    <div className={styles['recent-upload-status']} key={spaceExtractionTask.id}>
                      <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                        <p className={styles['recent-uploads-filename']}>
                          <TrblTruncate text={spaceExtractionTask.inputModelFilename} strLen={45} />
                        </p>
                      </Stack>
                      <RecentUploadStatusDisplay
                        uploadStatus={spaceExtractionTask.task.status}
                        task={spaceExtractionTask.task}
                        hasBeenImported={false}
                        goNext={() => goNext(spaceExtractionTask.id, spaceExtractionTask.inputModelFilename)}
                      />
                    </div>
                  ))}
                </div>
                <Divider marginBottomOffset={4} />
              </>
            )}

            {uploadsOther.length > 0 && (
              <div className={styles['other-imports-box']}>
                {uploadsOther.map((spaceExtractionTask: any) => (
                  <div className={styles['recent-upload-status']} key={spaceExtractionTask.id}>
                    <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                      <p className={styles['recent-uploads-filename']}>
                        <TrblTruncate text={spaceExtractionTask.inputModelFilename} strLen={45} />
                      </p>
                      {(spaceExtractionTask.task.status === 'InProgress' ||
                        spaceExtractionTask.task.status === 'Pending' ||
                        spaceExtractionTask.task.status === 'ProcessingResults') && (
                        <TrblActionsMenu
                          actions={spaceActions(spaceExtractionTask.id, spaceExtractionTask.task.status)}
                          id={spaceExtractionTask.id}
                          title="View task actions"
                          classNames={styles['recent-uploads-menu']}
                        />
                      )}
                    </Stack>

                    <RecentUploadStatusDisplay
                      uploadStatus={spaceExtractionTask.task.status}
                      task={spaceExtractionTask.task}
                      hasBeenImported={spaceExtractionTask.hasImportedModel}
                      goNext={() => goNext(spaceExtractionTask.id, spaceExtractionTask.inputModelFilename)}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
