import { useCallback, useEffect, useState } from 'react';

declare global {
  interface Window {
    hubspotUnreadMessages: { type: string; uuid: string }[];
    hsConversationsOnReady: Array<() => void>;
    HubSpotConversations: {
      clear: () => void;
      debug: () => void;
      off: (eventName: string, listener: (payload: { unreadCount: number }) => void) => void;
      on: (eventName: string, listener: (payload: { unreadCount: number }) => void) => void;
      resetAndReloadWidget: () => void;
      widget: {
        close: () => void;
        load: (params?: { widgetOpen: boolean }) => void;
        open: () => void;
        refresh: (openToNewThread?: boolean) => void;
        remove: () => void;
        status: () => {
          loaded: boolean;
          pending: boolean;
        };
      };
    };
    hsConversationsSettings: {
      enableWidgetCookieBanner?: boolean;
      inlineEmbedSelector?: string;
      loadImmediately?: boolean;
      disableAttachment?: boolean;
    };
  }
}

type HubspotConversationsType = {
  toggleWidget: () => void;
  isOpen: boolean;
  isReady: boolean;
  unreadMessagesCount: number;
};

/*
  - Hook using the HubSpot Chat SDK to customize the chat widget UI
  - Documentation here https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
  - Current limitation is that if the user has not interacted with the widget in his current session
    but has unread messages they will not get notified.
    - The unreadConversationCountChanged event is only registered after the user interacts with the widget
    - We have a custom "hack" to make this less painful where we listen to message events from the iframe
    - A potential workaround is to use the notification value populated in the iframe through the following element:
      <span aria-label="notifications" class="VizExNotificationBadge__CountBadge-sc-18fi11w-1 iIeZBI">1</span>
      using document.querySelector('[aria-label="notifications"]').innerHTML
      But this is a hackity hack hack
*/
export const useHubspotChat = (): HubspotConversationsType => {
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const onConversationsReady = () => {
    window.HubSpotConversations.widget.load();
    setIsReady(true);
  };

  useEffect(() => {
    if (window.HubSpotConversations) {
      onConversationsReady();
    } else {
      window.hsConversationsOnReady = [onConversationsReady];
    }
    // Adding a custom listener here so we can get unread messages for the user before
    // they interact widht the widget for the first time. This does NOT solve the issue
    // where they do not see unread message count if they have started a new session.
    window.addEventListener('message', messageListener);
  }, []);

  const messageListener = (event: MessageEvent) => {
    if (event.origin === 'https://app.hubspot.com') {
      let data;
      // Check if event.data is a string so it can be parsed
      if (typeof event.data === 'string') {
        try {
          data = JSON.parse(event.data);
        } catch (error) {
          return;
        }
      }
      // the unreadConversationCountChanged event is only registered once the user has interacted with the chat widget for the first time...
      // so for us to show the user that someone has messaged them live, before the user opens the chat, we need to listen to this event
      if (data.type === 'show-page-title-notification') {
        window.hubspotUnreadMessages = [...window.hubspotUnreadMessages, data];
        // update the count, since we don't show the count number it doesn't matter though it is not exactly the right number
        const newCount = unreadMessagesCount + 1;
        setUnreadMessagesCount(newCount);
      }
    }
  };

  useEffect(() => {
    if (isReady) {
      window.HubSpotConversations.on('unreadConversationCountChanged', listener);
    }
  }, [isReady]);

  const listener = (payload: { unreadCount: number }) => {
    setUnreadMessagesCount(payload.unreadCount);
  };

  const hideWidget = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showWidget = useCallback(() => {
    //window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
    setIsOpen(true);
  }, [isReady]);

  const toggleWidget = useCallback(() => {
    if (isOpen) {
      hideWidget();
    } else {
      showWidget();
    }
  }, [hideWidget, isOpen, showWidget]);

  return {
    toggleWidget,
    isOpen,
    isReady,
    unreadMessagesCount,
  };
};
