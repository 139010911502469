import { useState } from 'react';

import { IconButton, Tab, Tabs } from '@mui/material';

import { TrblCaretDownIcon, TrblCaretRightIcon } from '@/components/Icons';
import { PointReceiversContent } from './PointReceiversContent';
import { SurfaceReceiversContent } from './SurfaceReceiversContent';
import { TabPanel } from './TabPanel';

import { Point } from '@/context/EditorContext/types';
import { GridReceiver } from '@/types';

import styles from '../styles.module.scss';

export const ReceiversTabs = ({
  receivers,
  gridReceivers,
  tabValue,
  isDisabled,
  readonly,
  setTabValue,
}: {
  tabValue: number;
  receivers: Point[];
  gridReceivers: GridReceiver[];
  isDisabled: boolean;
  readonly: boolean;
  setTabValue: (newValue: number) => void;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `receivers-tab-${index}`,
      'aria-controls': `receivers-tabpanel-${index}`,
    };
  }

  return (
    <div className={styles.receivers_container}>
      <IconButton
        className={styles.receivers__toggle_button}
        title={`Toggle ${isOpen ? 'close' : 'open'} receivers`}
        aria-label={`Toggle ${isOpen ? 'close' : 'open'} receivers`}
        onClick={() => setIsOpen((isOpen) => !isOpen)}>
        {isOpen ? <TrblCaretDownIcon /> : <TrblCaretRightIcon />}
      </IconButton>
      <Tabs value={tabValue} onChange={handleChange} aria-label="Receiver tabs" className={styles.receivers_tabs}>
        <Tab className={styles.receivers_tab} label="Point receivers" {...a11yProps(0)} />
        <Tab className={styles.receivers_tab} label="Surface receivers" {...a11yProps(1)} />
      </Tabs>
      {isOpen && (
        <div className={`${styles.receivers_container__content} ${isDisabled || readonly ? styles.disabled : ''}`}>
          <TabPanel value={tabValue} index={0}>
            <PointReceiversContent receivers={receivers} isDisabled={isDisabled} readonly={readonly} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SurfaceReceiversContent gridReceivers={gridReceivers} isDisabled={isDisabled} readonly={readonly} />
          </TabPanel>
        </div>
      )}
    </div>
  );
};
