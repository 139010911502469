import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblListViewIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z"
      fill={fill}
    />
    <path
      d="M0 11.0001C0 10.4478 0.447731 10.0001 1.00003 10.0001H11C11.5523 10.0001 12 10.4478 12 11.0001C12 11.5524 11.5523 12.0002 11 12.0002H1.00003C0.44773 12.0002 0 11.5524 0 11.0001Z"
      fill={fill}
    />
    <path
      d="M0 6.0001C0 5.44781 0.447715 5.0001 1 5.0001H11C11.5523 5.0001 12 5.44781 12 6.0001C12 6.55238 11.5523 7.0001 11 7.0001H1C0.447715 7.0001 0 6.55238 0 6.0001Z"
      fill={fill}
    />
  </svg>
);
