import { IconProps } from '@/types';

export const TrblFileIcon = ({ fill = '#DADADA', width = '18', height = '19' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.816 0.5C11.0731 0.5 11.3204 0.599076 11.5064 0.776644L16.4048 5.45236C16.6025 5.64106 16.7143 5.90244 16.7143 6.17572V16.8636C16.7143 17.7674 15.9468 18.5 15.0001 18.5H3.00005C2.05328 18.5 1.28577 17.7674 1.28577 16.8636V2.13636C1.28577 1.23262 2.05328 0.5 3.00005 0.5H10.816ZM7.21726 8.5133C7.27958 8.03239 7.29516 7.48735 7.29516 6.92857H9.25823C9.25304 8.50872 9.15437 9.83697 8.39095 10.8263C7.36787 12.1499 5.50347 12.3835 3.8572 12.3973V10.6477C5.85662 10.6202 6.48501 10.2125 6.77583 9.83697C7.02511 9.5072 7.14975 9.03544 7.21726 8.5133ZM7.45616 15.9286C7.46135 14.3484 7.56002 13.0156 8.32344 12.0309C9.34652 10.7072 11.2109 10.4736 12.8572 10.4599V12.2095C10.8578 12.237 10.2294 12.6492 9.93856 13.0248C9.68928 13.3499 9.56464 13.8217 9.49713 14.3438C9.43481 14.8248 9.41923 15.3698 9.41923 15.9286H7.45616Z"
      fill={fill}
    />
  </svg>
);
