import { Link } from 'react-router-dom';

import { TrblTooltip } from '@/components/Shared';
import { TrblAuralizeIcon, TrblResultsIcon } from '../Icons';

import classes from './styles.module.scss';

export const QuickLinks = ({ simulationId, modelId }: { simulationId: string; modelId: string }) => {
  return (
    <div className={classes.button_container}>
      <TrblTooltip title="Open auralizer">
        <Link to={`/auralizer?mid=${modelId}&sid=${simulationId}`}>
          <button className={classes.quick_btn}>
            <TrblAuralizeIcon fill="#999999" />
          </button>
        </Link>
      </TrblTooltip>
      <TrblTooltip title="Open results">
        <Link to={`/results?mid=${modelId}&sid=${simulationId}`}>
          <button className={classes.quick_btn}>
            <TrblResultsIcon fill="#999999" />
          </button>
        </Link>
      </TrblTooltip>
    </div>
  );
};
