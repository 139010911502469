import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCollapseIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="16" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.69943 4.5L6.79942 6.59999L8.89941 4.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.79962 6.59979V0.999816" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.79962 14.6V9.00006" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.89941 11.1001L6.79942 9.00011L4.69943 11.1001"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
