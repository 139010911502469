import { FrequencyWeightingType, ReflectionDetails } from '../../context/ReflectogramResultsContext/types';

const getRelativeSplByWeighting = (
  d: ReflectionDetails,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): number | undefined => {
  switch (selectedFrequencyWeighting) {
    case FrequencyWeightingType.AWeighting:
      return selectedFrequencyBandIndex !== null
        ? d.splAWeightedPerBandRelative?.[selectedFrequencyBandIndex]
        : d.splAWeightedRelative;
    case FrequencyWeightingType.CWeighting:
      return selectedFrequencyBandIndex !== null
        ? d.splCWeightedPerBandRelative?.[selectedFrequencyBandIndex]
        : d.splCWeightedRelative;
    default:
      return selectedFrequencyBandIndex !== null ? d.splPerBandRelative?.[selectedFrequencyBandIndex] : d.splRelative;
  }
};

const getSplByWeighting = (
  d: ReflectionDetails,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): number | undefined => {
  switch (selectedFrequencyWeighting) {
    case FrequencyWeightingType.AWeighting:
      return selectedFrequencyBandIndex !== null ? d.splAWeightedPerBand?.[selectedFrequencyBandIndex] : d.splAWeighted;
    case FrequencyWeightingType.CWeighting:
      return selectedFrequencyBandIndex !== null ? d.splCWeightedPerBand?.[selectedFrequencyBandIndex] : d.splCWeighted;
    default:
      return selectedFrequencyBandIndex !== null ? d.splPerBand?.[selectedFrequencyBandIndex] : d.spl;
  }
};

export const getSplValues = (
  data: ReflectionDetails[],
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): number[] => {
  return data.map((d) => getSplByWeighting(d, selectedFrequencyWeighting, selectedFrequencyBandIndex) ?? 0);
};

export const getSplValue = (
  d: ReflectionDetails,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): number => {
  return getSplByWeighting(d, selectedFrequencyWeighting, selectedFrequencyBandIndex) ?? 0;
};

export const getRelativeSplValue = (
  d: ReflectionDetails,
  selectedFrequencyWeighting: FrequencyWeightingType,
  selectedFrequencyBandIndex: number | null
): number => {
  return getRelativeSplByWeighting(d, selectedFrequencyWeighting, selectedFrequencyBandIndex) ?? 0;
};

export const getSPLUnit = (selectedFrequencyWeighting: FrequencyWeightingType): string => {
  switch (selectedFrequencyWeighting) {
    case FrequencyWeightingType.AWeighting:
      return 'dBA';
    case FrequencyWeightingType.CWeighting:
      return 'dBC';
    default:
      return 'dB';
  }
};
