import { useMutation } from '@tanstack/react-query';

import { Material } from '@/types';

import axios from '@/axios';

const shareMaterial = async (materialId: string): Promise<Material> => {
  const { data } = await axios.patch(`/api/Material/ShareMaterial?materialId=${materialId}`);
  return data;
};

export const useShareMaterial = () => {
  return useMutation(async (materialId: string) => await shareMaterial(materialId));
};
