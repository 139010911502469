import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblOpenLibraryIcon: FC<IconProps> = ({ fill = '#DADADA', width = '12', height = '12' }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.31207 4.12427V1.31177H4.12457" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.31207 1.31177L7.56207 7.56177" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.31207 3.18677H10.0621C10.4073 3.18677 10.6871 3.46659 10.6871 3.81177V10.0618C10.6871 10.4069 10.4073 10.6868 10.0621 10.6868H3.81207C3.46689 10.6868 3.18707 10.4069 3.18707 10.0618V6.31177"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
