import { useQuery } from '@tanstack/react-query';

import { MeshTaskDto } from '../types';

import axios from '@/axios';

export const getMeshTask = async (meshTaskId: string): Promise<MeshTaskDto> => {
  const { data } = await axios.get<MeshTaskDto>(`/api/Mesh/GetMeshTask?meshTaskId=${meshTaskId}`);

  return data;
};

export const useGetMeshTask = (meshTaskId: string) => {
  return useQuery<MeshTaskDto>(['meshTaskId', meshTaskId], () => getMeshTask(meshTaskId), {
    enabled: !!meshTaskId,
    refetchOnWindowFocus: false,
  });
};
