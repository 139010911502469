import { useSearchParams } from 'react-router-dom';
import { GridFilterModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';

export const useGridSortingAndFiltering = (defaultSorting: { field: string; sort: GridSortDirection }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const columnField = searchParams.get('columnField') || '';
  const operatorValue = searchParams.get('operatorValue') || '';
  const value = searchParams.get('value') || '';
  const field = searchParams.get('field') || defaultSorting.field;
  const sort: GridSortDirection = (searchParams.get('sort') as GridSortDirection) || defaultSorting.sort;

  const filterModelChange = (model: GridFilterModel) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const items: { columnField: string; operatorValue?: string | undefined; value?: any } = model.items[0];
    const existing = field && sort ? { field, sort } : null;
    if (items) {
      setSearchParams({ ...items, ...(existing as { field: string; sort: string }) });
    } else {
      setSearchParams({ ...existing });
    }
  };

  const sortModelChange = (model: GridSortModel) => {
    const items: { field: string; sort: string | null | undefined } = model[0];
    const existing = columnField && operatorValue && value ? { columnField, operatorValue, value } : null;

    if (items && items.field && items.sort) {
      setSearchParams({ ...existing, ...(items as { field: string; sort: string }) });
    } else {
      setSearchParams({ ...existing });
    }
  };
  return {
    sortModelChange,
    filterModelChange,
    sortModel: { field, sort },
    filterModel: { columnField, operatorValue, value },
  };
};
