import { useEffect, useState } from 'react';

import { EMPTY_GUID } from '@/components/Shared/constants';

import { MissingSourceDefinitionInfo, useGetMissingSourceDefinitionInfo } from '@/hooks';

import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';

export interface DisplayMissingSourceDefinition extends MissingSourceDefinitionInfo {
  id: string | undefined;
  name: string;
  manufacturer: string;
  noLongerAvailable: boolean;
}

interface DisplaySource extends Source {
  sourceDefinition: SourceDefinition | DisplayMissingSourceDefinition;
}

export const useGetDisplaySources = (sources: Source[], filteredSourceDefinitions: SourceDefinition[]) => {
  const [displaySources, setDisplaySources] = useState<DisplaySource[]>([]);
  const [newSources, setNewSources] = useState<DisplaySource[]>([]);
  const [missingSourceDefinitionIds, setMissingSourceDefinitionIds] = useState<string[]>([]);
  const { data } = useGetMissingSourceDefinitionInfo(missingSourceDefinitionIds);

  useEffect(() => {
    getDisplaySources(sources, filteredSourceDefinitions);
  }, [sources, filteredSourceDefinitions]);

  useEffect(() => {
    if (data !== undefined && newSources.length > 0) {
      const newSourcesWithMissingInfo = newSources.map((source) => {
        if (source.params.directivityPattern && source.sourceDefinition === undefined) {
          const sourceDefinition = data.find(
            (sourceDefinition: MissingSourceDefinitionInfo) =>
              sourceDefinition.sourceDefinitionId === source.params.directivityPattern
          );
          const isDeleted = sourceDefinition?.isDeleted;
          const isShared = sourceDefinition?.isSharedWithOrganization;
          const nameSuffix = isDeleted ? '(deleted)' : !isShared ? '(not shared)' : '';
          return {
            ...source,
            sourceDefinition: {
              ...sourceDefinition,
              // first we display if the definition is deleted and then if it is not shared anymore
              name: `${sourceDefinition?.sourceDefinitionName} ${nameSuffix}`,
              id: sourceDefinition?.sourceDefinitionId,
              manufacturer: '',
              noLongerAvailable: true,
            },
          } as DisplaySource;
        } else {
          return source;
        }
      });
      setDisplaySources(newSourcesWithMissingInfo);
    } else if (data === undefined && newSources.length > 0) {
      // if we can't get the data about the missing sources
      setDisplaySources(newSources);
    }
  }, [data, newSources]);

  const getDisplaySources = async (sources: Source[], filteredSourceDefinitions: SourceDefinition[]) => {
    const newMissingSourceDefinitionIds: string[] = [];

    // @ts-expect-error we are catching undefined so this warning does not apply
    const newSources: DisplaySource[] = sources.map((source) => {
      let sourceDefinition: SourceDefinition | undefined;
      if (source.params.directivityPattern === EMPTY_GUID) {
        sourceDefinition = filteredSourceDefinitions.find((def) => def.name === 'Omni');
      } else {
        sourceDefinition = filteredSourceDefinitions.find((def) => def.id === source.params.directivityPattern);
      }
      if (!sourceDefinition) {
        newMissingSourceDefinitionIds.push(source.params.directivityPattern);
      }

      return {
        ...source,
        sourceDefinition,
      };
    });
    // filter out EMPTY_GUID
    const filterOutEmptyGuid = newMissingSourceDefinitionIds.filter((item) => item !== EMPTY_GUID);
    if (filterOutEmptyGuid.length > 0) {
      setNewSources(newSources);
      setMissingSourceDefinitionIds(filterOutEmptyGuid);
    } else {
      setDisplaySources(newSources);
    }
  };

  return displaySources;
};
