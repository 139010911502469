import { FC, ReactNode, useState } from 'react';

import { TrblCloseIcon } from '../Icons';
import { TrblIconButton } from '../Shared/Buttons/TrblIconButton';

import styles from './styles.module.scss';

type SpeechBubbleProps = {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
  width?: number | string;
  triangleRightPos?: number | string;
  content?: ReactNode;
  className?: string;
};

export const SpeechBubble: FC<SpeechBubbleProps> = ({
  top,
  bottom,
  left,
  right,
  width,
  triangleRightPos,
  content,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return isOpen ? (
    <div
      className={`${styles['speech-bubble']} ${className && styles[className]}`}
      style={{ top, bottom, left, right, width }}>
      <div className={styles['triangle']} style={triangleRightPos ? { right: triangleRightPos } : {}}></div>
      <div className={styles['content']}>{content}</div>
      <TrblIconButton
        className={styles['close-btn']}
        icon={<TrblCloseIcon width="11" height="11" />}
        label="Close info bubble"
        edge="start"
        onClick={() => setIsOpen(false)}></TrblIconButton>
    </div>
  ) : null;
};
