import { Euler, Vector3 } from 'three';

import porousMaterial from './images/porousMaterial.svg';
import rigidBacking from './images/rigidBacking.svg';

import { Frequency, PorousAbsorberInputType, PorousAbsorberWoolType } from './types';

export const FREQUENCY_RANGE_FULL_OCTAVE = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];
export const FULL_OCTAVES: Frequency[] = [63, 125, 250, 500, 1000, 2000, 4000, 8000];
export const DEFAULT_SCATTERING = 0.3;

export const FREQUENCY_RANGE_THIRD_OCTAVE_NUM = [
  50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500, 3150, 4000, 5000, 6300,
  8000, 10000,
];

export const ORANGE_PLOT_COLOR = '#F8BB60';
export const GREEN_PLOT_COLOR = '#00F5BA';

export const FREQUENCY_RANGE_THIRD_OCTAVE = [
  '50',
  '63',
  '80',
  '100',
  '125',
  '160',
  '200',
  '250',
  '315',
  '400',
  '500',
  '630',
  '800',
  '1k',
  '1.25k',
  '1.6k',
  '2k',
  '2.5k',
  '3.15k',
  '4k',
  '5k',
  '6.3k',
  '8k',
  '10k',
];

export const INITIAL_VALUES_FULL_OCTAVE: { [freq in Frequency]: number } = {
  63: 0.1,
  125: 0.1,
  250: 0.1,
  500: 0.1,
  1000: 0.1,
  2000: 0.1,
  4000: 0.1,
  8000: 0.1,
};

export const EXAMPLE_INPUT_FULL_OCTAVE = `63        0.1
125       0.1
250       0.1
500       0.1
1000      0.1
2000      0.1
4000      0.1
8000      0.1`;

export const FORM_INITIAL = { name: '', category: '', description: '', defaultScattering: DEFAULT_SCATTERING };

export const VALID_CSV_TYPES = [
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

export const VALID_FILE_TYPES = [
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/plain',
];

export const PLOT_HEIGHT = 270;
export const PLOT_WIDTH = 450;

export const PLOT_WIDTH_SMALL = 350;
export const PLOT_HEIGHT_SMALL = 210;

export enum MaterialInputType {
  FullOctaveAbsorption = 'FullOctaveAbsorption',
  SurfaceImpedance = 'SurfaceImpedance',
  ReflectionCoefficient = 'ReflectionCoefficient',
  PorousAbsorberBuilder = 'PorousAbsorberBuilder',
}

export type BoxProps = {
  xPos: number;
  width: number;
  height: number;
  depth: number;
  title?: string;
  color?: string;
  textureUrl?: string;
  subTitle?: boolean;
};

export const INITIAL_XPOS = -0.5; // manually selected number that centers the box in the canvas
export const GRAY_COLOR = '#303030'; // this gray color translates to #1f1f1 when put into a threeJS canvas
export const LIGHT_GRAY_COLOR = '#999999';

const BOX_HEIGHT = 1.5;

export const DEFAULT_BOX_VALUES = [
  {
    xPos: INITIAL_XPOS,
    color: 'lightgrey',
    width: 8,
    height: BOX_HEIGHT,
    depth: 3.5,
    textureUrl: rigidBacking,
    title: 'RIGID BACKING',
    subTitle: false,
  },
  {
    xPos: INITIAL_XPOS,
    width: 3,
    height: BOX_HEIGHT,
    depth: 3.5,
    title: '',
    color: LIGHT_GRAY_COLOR,
  },
  {
    xPos: INITIAL_XPOS,
    color: '#cfc675',
    width: 2,
    height: BOX_HEIGHT,
    depth: 3.5,
    textureUrl: porousMaterial,
    title: '',
  },
];

export const INITIAL_VALUES_POROUS_ABSORBER = {
  flowResistivity: 60000,
  thickness: 100,
  density: 100,
  inputType: PorousAbsorberInputType.Density,
  woolType: PorousAbsorberWoolType.Rockwool,
};

export const INITIAL_VALUES_AIR_CAVITY = { thickness: 0 };

export const scaledBoxWidth = (width: number) => {
  return width / 10;
};

// Adjust x position by a small value so that the sides of the box do not collide and cause flickering
export const getAdjustedXPos = (xPos: number, index: number) => {
  return xPos + index * 0.01;
};

export const TEXT_CONFIG = {
  fontSize: 0.2,
  fontWeight: 500,
  color: '#dadada',
  maxWidth: 10,
  lineHeight: 1.1,
  letterSpacing: 0.2,
  rotation: new Euler(89.55, 0, 89.55),
  position: new Vector3(-0.6, -0.8, 0),
};

export const MAX_NUMBER_INPUT = 800;
export const MIN_DENSITY = 10;
export const MAX_DENSITY = 500;
export const SOLVIS_MAGIC_NUMBER = 414;
