import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';

import { useUpdateSpace } from '@/hooks';

import { SpaceDto } from '@/types';

interface PopupSpaceCardProps {
  showPopup: boolean;
  onClose?: () => void;
  onUpdate?: (response: SpaceDto) => void;
  name: string;
  description: string;
  id: string;
}
export const PopupSpaceCard = ({
  showPopup = false,
  onClose,
  onUpdate,
  name,
  description,
  id,
}: PopupSpaceCardProps) => {
  const [newName, setNewName] = useState<string>(name);
  const [newDescription, setNewDescription] = useState<string>(description);
  const [isFormValid, setIsFormValid] = useState(true);

  const { mutate: updateSpace } = useUpdateSpace();

  // Form validation
  useEffect(() => {
    if (newName && newName.length > 0 && (newName !== name || newDescription !== description)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [newName, name, newDescription, description]);

  useEffect(() => {
    if (showPopup) {
      setNewName(name);
      setNewDescription(description);
    }
  }, [showPopup]);

  const handleSubmitUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    updateSpace(
      {
        id: id,
        name: newName,
        description: newDescription,
      },
      {
        onSuccess: (response: SpaceDto) => {
          if (onUpdate) onUpdate(response);
          toast.info("'" + response.name + "' updated");
        },
        onError: () => {
          toast.error('An error occurred while updating Space');
        },
      }
    );
  };

  return (
    <>
      {showPopup && newName != undefined && (
        <TrblPopup hideBackdrop={false} aria-labelledby={'Edit space'} sx={{ fontSize: '12px' }} open={true}>
          <form>
            <TrblPopupTitle onClose={onClose}>{'Edit space'}</TrblPopupTitle>
            <TrblPopupContent>
              <Stack gap={1}>
                <TextField autoFocus maxLength={128} placeholder="Name" value={newName} onChange={setNewName} />
                <TextArea placeholder="Description" value={newDescription} onChange={setNewDescription} />
              </Stack>
            </TrblPopupContent>
            <TrblPopupActions>
              <Box component={'div'} display="flex" justifyContent="flex-end" width={'100%'}>
                <SecondaryButton
                  disabled={!isFormValid}
                  type="submit"
                  width={'auto'}
                  label="Update"
                  onClick={(e) => handleSubmitUpdate(e)}
                />
              </Box>
            </TrblPopupActions>
          </form>
        </TrblPopup>
      )}
    </>
  );
};
