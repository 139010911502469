import { Skeleton } from '@mui/material';

export const TrblSkeleton = ({
  width = '100%',
  height,
  numberOfWaves = 1,
  style,
}: {
  width?: string | number;
  height?: string | number;
  numberOfWaves?: number;
  style?: React.CSSProperties;
}) => {
  const numberOfLoops = Array.from(Array(numberOfWaves).keys());
  return (
    <>
      {numberOfLoops.map((loop) => (
        <Skeleton
          key={loop}
          width={width}
          height={height}
          animation="wave"
          variant="rectangular"
          style={style}
          sx={{ opacity: 0.15 }}
        />
      ))}
    </>
  );
};
