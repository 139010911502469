import { useQuery } from '@tanstack/react-query';

import { SharedAuralizationInfoDto } from '../types';

import axios from '@/axios';

export const getSharedAuralizations = async (): Promise<SharedAuralizationInfoDto[]> => {
  const { data } = await axios.get(`/api/SharedAuralization/GetSharedAuralizations`);
  return data;
};

export const useGetSharedAuralizations = (enabled: boolean = true) => {
  return useQuery<SharedAuralizationInfoDto[]>(['shared-auralizations'], () => getSharedAuralizations(), {
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};
