import { FC, useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useResultsContext } from '../Results/context/ResultsContext';
import { BaseContextActionType, useBaseContext } from '@/context/BaseContext';
import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';
import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { TrblTooltip } from '@/components/Shared';
import {
  Auralizer,
  EditSimulation,
  PageLayout,
  ProjectBreadcrumbs,
  RecentSimulationNav,
  SimulationSidePanel,
  TrblSharedProjectIcon,
} from '@/components';
import { EditorContent } from './components/EditorContent';
import { ResultsHeader } from './components/ResultsHeader';
import { CreateButton } from '../CreateButton';
import { AutoSaveText } from '../EditSimulation/AutoSaveText';
import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { MaterialLibrary } from '../MaterialLibrary';
import { SourceDefinitionLibrary } from '../SourceDefinitionLibrary';

import { useSaveUpdatedSimulation } from '../EditSimulation/hooks/useSaveUpdatedSimulation';
import { useGetSimulationFromRouteParam } from './hooks/useGetSimulationFromRouteParam';
import {
  useGetModelInformation,
  useGetProjectById,
  useGetSimulationsByModelId,
  useLoadAndExtractFileFromUrl,
} from '@/hooks';

import { nonWatertightMessage } from '@/utils/constants';

import { ResultsView } from '@/context/EditorContext/types';

import './styles.scss';

type EditorProps = {
  showResults: boolean;
  showAuralizer: boolean;
};

export const Editor: FC<EditorProps> = ({ showResults, showAuralizer }) => {
  const { user } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];
  const {
    dispatch: baseDispatch,
    state: { userInfo, sidepanelCollapsed },
  } = useBaseContext();

  const { id: routeModelId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const queryModelId = searchParams.get('mid') || '';
  const saveSimulation = useSaveUpdatedSimulation();
  const { pathname } = useLocation();

  const { dispatch: simDispatch } = useSimulationContext();
  const { isMaterialsLibraryOpen, isSourceDefinitionLibraryOpen } = useLibraryPanelContext();
  const { isLoading, editSimulation, readonly, dispatch: edDispatch } = useEditorContext();
  const { dispatch: modelDispatch, addModelFromFile, isModelLoaded } = useModelContext();
  const { emptyResults, availableComparisons, selectedComparisonIndex, selectedPreset } = useResultsContext();

  // if a result preset is selected we fetch the select simulation in the availableComparisons
  const resultPresetModelId =
    availableComparisons[selectedComparisonIndex]?.formState?.selectedSimulation?.modelId || '';
  // we always select the modelId from the route/url, but if it's not there we use the resultPresetModel id
  const currentModelId = routeModelId || queryModelId || resultPresetModelId;

  let thisBypassPointValidity = false;

  const {
    data: modelInformationData = null,
    isSuccess: modelInformationSuccess,
    isLoading: modelInformationLoading,
  } = useGetModelInformation(currentModelId);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(modelInformationData?.modelUrl || null, currentModelId);

  const { data: project } = useGetProjectById(modelInformationData?.projectId || '');
  const { data: modelSimulations, isLoading: isLoadingSimulations } = useGetSimulationsByModelId(currentModelId || '');
  useGetSimulationFromRouteParam();

  useEffect(() => {
    // On initial load we fetch all the simulations for a certain model id
    // and keep them in state variable called "availableSimulations"
    if (modelSimulations) {
      simDispatch({
        type: SimActionType.SET_MODEL_SIMULATIONS,
        simulations: modelSimulations,
      });
    }
  }, [modelSimulations]);

  useEffect(() => {
    if (pathname === '/editor') {
      // if currentModelId changes, then RESET Editor state to clear all Editor variables to prevent any
      // cross-contaminatin of objects between models
      edDispatch({
        type: EdActionType.RESET_STATE,
      });
    }
    modelDispatch({
      type: ModelActionType.SET_CURRENT_MODEL_ID,
      modelId: currentModelId,
    });
  }, [currentModelId]);

  useEffect(() => {
    if (!showResults) {
      // Reset to default view when exiting results mode
      edDispatch({
        type: EdActionType.SET_RESULTS_VIEW,
        payload: ResultsView.ResultsModelView,
      });
    }

    edDispatch({
      type: EdActionType.SET_IS_IN_RESULTS_MODE,
      payload: showResults,
    });
  }, [showResults]);

  useEffect(() => {
    edDispatch({
      type: EdActionType.SET_IS_IN_AURALIZER_MODE,
      isOpen: showAuralizer,
    });
  }, [showAuralizer]);

  // extra keydown listener for Treble users to bypass point validation
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'u') {
      thisBypassPointValidity = !thisBypassPointValidity;
      modelDispatch({
        type: ModelActionType.SET_BYPASS_POINT_VALIDITY,
        bypass: thisBypassPointValidity,
      });
    }
  };

  // Since we are storing sources, receivers etc in the editor context we need to reset the state when we unmount
  useEffect(() => {
    //if Treble user then add extra keydown listener
    if (userRoles.includes('Superuser')) window.addEventListener('keydown', handleKeyDown);

    return () => {
      //if Treble user then remove extra keydown listener when exiting Editor
      if (userRoles.includes('Superuser')) window.removeEventListener('keydown', handleKeyDown);
      edDispatch({
        type: EdActionType.RESET_STATE,
      });
    };
  }, []);

  useEffect(() => {
    if (modelInformationData) {
      modelDispatch({
        type: ModelActionType.UPDATE_MODEL_INFORMATION,
        modelInformation: modelInformationData,
      });
    }
  }, [modelInformationData]);

  useEffect(() => {
    if (!isModelLoaded && currentModelId && modelFile && queryModelId) {
      addModelFromFile(currentModelId, modelFile);
    }
  }, [isModelLoaded, currentModelId, modelFile]);

  const onConfirm = async () => {
    await saveSimulation(editSimulation.updatedSimulation, editSimulation.saveText);
    edDispatch({
      type: EdActionType.SHOW_EDIT_MODAL,
      editSimulation: { showModal: false, updatedSimulation: null },
    });
  };

  const handleCollapseSidepanel = () => {
    baseDispatch({
      type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
      sidepanelCollapsed: !sidepanelCollapsed,
    });
  };

  useEffect(() => {
    if (pathname === '/editor' && sidepanelCollapsed) {
      baseDispatch({
        type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
        sidepanelCollapsed: false,
      });
    }
  }, [pathname]);

  const sidepanelReady = !modelInformationLoading && modelInformationSuccess && currentModelId;

  return (
    <PageLayout
      isFetching={isLoading}
      emptyResults={emptyResults}
      extraHeader={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {modelInformationData && project && (
            <>
              <div style={{ display: 'flex' }}>
                <ProjectBreadcrumbs
                  projectUsers={project.projectUsers}
                  loggedInUserId={userInfo?.id}
                  creatorId={project.createdBy}
                  background={readonly ? '#1f1f1f' : '#262626'}
                  items={[
                    {
                      text: modelInformationData.projectName,
                      to: '/project/' + modelInformationData.projectId,
                      icon:
                        project.createdBy !== userInfo?.id ? (
                          <TrblTooltip title="Project shared with you">
                            <span>
                              <TrblSharedProjectIcon width="14" height="14" fill="#c0c0c0" />
                            </span>
                          </TrblTooltip>
                        ) : undefined,
                    },
                    {
                      text: modelInformationData.spaceName,
                      to: `/space/${modelInformationData.spaceId}`,
                      hidden: !!selectedPreset,
                    },
                    {
                      hidden: !!selectedPreset,
                      text: (
                        <>
                          {modelInformationData.modelBaseName}
                          {modelInformationData.nonWatertight && (
                            <TrblTooltip title={nonWatertightMessage}>
                              <div className="nonwatertight-symbol"></div>
                            </TrblTooltip>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </div>

              <div style={{ marginRight: '20px' }}>
                <CreateButton hidden={readonly} />
              </div>
            </>
          )}
        </div>
      }
      sidepanel={sidepanelReady ? <SimulationSidePanel isLoading={isLoadingSimulations} /> : <div></div>}
      sidepanelWidth={{
        isFixed: true,
        size: '352px',
      }}
      sidepanelCollapsible={readonly}
      onCollapseSidepanel={handleCollapseSidepanel}
      sidepanelCollapsed={sidepanelCollapsed}
      sidepanelExtraHeader={<RecentSimulationNav />}>
      <div className="editor-container">
        {showResults && <ResultsHeader />}
        <EditorContent showResults={showResults} />
        {!readonly && isMaterialsLibraryOpen ? (
          <MaterialLibrary />
        ) : !readonly && isSourceDefinitionLibraryOpen ? (
          <SourceDefinitionLibrary />
        ) : null}
        {showAuralizer ? <Auralizer /> : null}
        {!readonly ? (
          <EditSimulation
            showPopup={editSimulation.showModal}
            updatedSimulation={editSimulation.updatedSimulation}
            onConfirm={onConfirm}
            saveText={editSimulation.saveText}
          />
        ) : null}

        {!readonly ? <AutoSaveText /> : null}
      </div>
    </PageLayout>
  );
};
