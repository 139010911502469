import { useMutation } from '@tanstack/react-query';

import { Material } from '@/types';

import axios from '@/axios';

const revokeShareMaterial = async (materialId: string): Promise<Material> => {
  const { data } = await axios.patch(`/api/Material/RevokeShareMaterial?materialId=${materialId}`);
  return data;
};

export const useRevokeShareMaterial = () => {
  return useMutation(async (materialId: string) => await revokeShareMaterial(materialId));
};
