import { useGetDownloadUrl, useLoadAndExtractFileFromUrl } from '@/hooks';

export const useGetModelData = (modelUploadId?: string) => {
  // Setting the stale time to 30 min (probably could be a lot longer)
  const staleTime = 30 * 60 * 1000;

  const { data: downloadUrl } = useGetDownloadUrl(modelUploadId, staleTime);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(
    downloadUrl ?? null,
    'model_' + modelUploadId,
    undefined,
    staleTime
  );

  return { modelFile };
};
