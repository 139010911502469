import { FC } from 'react';
import { DateValidationError } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { DatePicker } from '@/components/Shared/DatePicker';
import { Text } from '@/components/Shared/Text';
import { EditControls } from './EditControls';

import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue';

import classes from './styles.module.scss';

type ExpiryDateControlsProps = {
  shareId: string | undefined;
  date: Dayjs | null;
  hasError: boolean;
  setDate: (date: Dayjs | null) => void;
  setHasError: (error: boolean) => void;
  isInEditMode: boolean;
  setIsInEditMode: (isInEditMode: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
};

export const ExpiryDateControls: FC<ExpiryDateControlsProps> = ({
  shareId,
  date,
  hasError,
  setDate,
  setHasError,
  isInEditMode,
  setIsInEditMode,
  onSave,
  onCancel,
}) => {
  const handleDateChanged = (date: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    setDate(date);
    if (context.validationError || !date) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <div className={classes['expires']}>
      <Text type="regular-11px">Expires </Text>
      <DatePicker
        disabled={!!(shareId && !isInEditMode)}
        value={date}
        minDate={dayjs()}
        onChange={handleDateChanged}
        required
      />
      {shareId && (
        <div style={{ marginLeft: '8px' }}>
          <EditControls
            editTitle="Edit expiry date"
            isInEditMode={isInEditMode}
            setIsInEditMode={() => setIsInEditMode(true)}
            disabled={hasError || !date}
            onSave={onSave}
            onCancel={onCancel}
          />
        </div>
      )}
    </div>
  );
};
