import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblInfoIcon: FC<IconProps> = ({ fill, width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM8.47254 3.67399C8.47254 3.02165 7.91129 2.5 7.20924 2.5C6.50719 2.5 5.9458 3.07446 5.9458 3.67399C5.9458 4.27352 6.50719 4.84785 7.20924 4.84785C7.91129 4.84785 8.47254 4.32634 8.47254 3.67399ZM8.80019 10.9088C8.80019 11.2353 8.51527 11.5001 8.16381 11.5001L6.47266 11.5001C5.83491 11.5001 5.37653 10.9301 5.55721 10.3618L6.32808 7.93701C6.43705 7.59423 6.16058 7.25047 5.77593 7.25047C5.45796 7.25047 5.2002 7.01096 5.2002 6.7155V6.57017C5.2002 6.24359 5.48511 5.97884 5.83658 5.97884H7.46253C8.11925 5.97884 8.57983 6.58074 8.36415 7.15712L7.5888 9.22922C7.44805 9.60538 7.74863 9.99819 8.17722 9.99819C8.52128 9.9982 8.80019 10.2574 8.80019 10.5771V10.9088Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
