import { useRef } from 'react';

import { ClickAwayListener, IconButton, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { TrblIcon } from '@/components/Icons';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { ReceiverRow } from './ReceiverRow';

import { usePoints } from '../hooks';
import { useReceivers } from '../hooks/useReceivers';

import { MAX_RECEIVERS } from '../constants';

import { Receiver } from '@/context/EditorContext/types';

import styles from '../styles.module.scss';

export const PointReceiversContent = ({
  receivers,
  isDisabled,
  readonly,
}: {
  receivers: Receiver[];
  isDisabled: boolean;
  readonly: boolean;
}) => {
  const {
    selectedPointId,
    newPointId,
    handleAddReceiver,
    handleLoadReceiversFromFile,
    handleRemoveAllReceivers,
    handleReceiverCoordinateChange,
    handleReceiverLabelChange,
    handleRemoveReceiver,
  } = useReceivers();
  const { handleSelectionChange } = usePoints();
  const { selectedSource } = useLibraryPanelContext();

  const { allow500ReceiversFeature } = useFeatureFlags();

  const maxReceivers = allow500ReceiversFeature ? 500 : MAX_RECEIVERS;

  const fileUploadRef = useRef<HTMLInputElement>(null);

  return (
    <Stack spacing={'3px'} mb={'15px'}>
      <div className={`${styles.add_receivers} ${readonly ? 'hidden' : ''}`}>
        <TrblTooltip title="Add new receiver">
          <IconButton
            disabled={receivers.length >= maxReceivers || isDisabled}
            onClick={handleAddReceiver}
            aria-label="Add new receiver">
            <TrblIcon
              icon="add"
              color={receivers.length >= maxReceivers ? '#474747' : undefined}
              hoverColor="#f5f5f5"
            />
          </IconButton>
        </TrblTooltip>
        <TrblActionsMenu
          disabled={isDisabled || readonly}
          id={'recievers-action-menu'}
          actions={[
            {
              key: 'load',
              value: 'Load from file',
              onClick: () => {
                if (fileUploadRef.current) {
                  fileUploadRef.current.click();
                }
              },
            },
            { key: 'delete', value: 'Delete all', onClick: () => handleRemoveAllReceivers() },
          ]}
        />

        <input
          id="upload-recievers-file"
          ref={fileUploadRef}
          type="file"
          style={{ display: 'none' }}
          accept=".txt, .csv"
          onChange={(e) => {
            if (e.target.files && fileUploadRef.current) {
              const file = e.target.files[0];
              handleLoadReceiversFromFile(file, maxReceivers);
              fileUploadRef.current.value = '';
            }
          }}
        />
      </div>
      {receivers.length > 0 ? (
        <>
          {receivers.map((r, index) => {
            return (
              <ClickAwayListener
                key={index}
                mouseEvent={selectedPointId === r.id ? undefined : false}
                onClickAway={(e) => {
                  if (!selectedSource && !(e.target instanceof Element && e.target.tagName === 'CANVAS')) {
                    handleSelectionChange(undefined);
                  }
                }}>
                <ReceiverRow
                  receiver={r}
                  index={index}
                  isSelected={selectedPointId === r.id}
                  isDisabled={isDisabled}
                  readonly={readonly}
                  isNewPoint={newPointId === r.id}
                  onSelect={() => handleSelectionChange(r.id, 'ReceiverPoint')}
                  onDelete={() => handleRemoveReceiver(r.id)}
                  onChangeAxis={(axis, value) =>
                    handleReceiverCoordinateChange(
                      index,
                      axis === 'x' ? value : r.x,
                      axis === 'y' ? value : r.y,
                      axis === 'z' ? value : r.z
                    )
                  }
                  onChangeLabel={(value) => handleReceiverLabelChange(index, value)}
                />
              </ClickAwayListener>
            );
          })}
        </>
      ) : (
        <p className={styles.no_receivers__message}> You have no point receivers</p>
      )}
    </Stack>
  );
};
