import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { TrblToggle } from '@/components/Shared';
import { SecondaryButton } from '@/components/Shared/Buttons';
import { BuilderMaterialFit } from './BuilderMaterialFit';
import { CreateMaterialButton } from './CreateMaterialButton';
import { LambdaMaterialFit } from './LambdaMaterialFit';
import { SimpleMaterialFit } from './SimpleMaterialFit';

import { MaterialInputType } from '../constants';

export const CreateMaterialActions = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const {
    formDisabled,
    impedanceFittedData,
    reflectionFittedData,
    builderFittedData,
    materialFit,
    inputType,
    shouldShare,
    impedanceData,
    reflectionData,
    dispatch,
  } = useCreateMaterialContext();
  const [hasFittedData, setHasFittedData] = useState<boolean>(false);

  useEffect(() => {
    setHasFittedData(
      impedanceFittedData !== null ||
        reflectionFittedData !== null ||
        builderFittedData !== null ||
        materialFit !== null
    );
  }, [impedanceFittedData, reflectionFittedData, builderFittedData, materialFit]);

  const resetForm = () => {
    dispatch({
      type: ActionType.RESET_FORM,
    });
  };

  const editForm = () => {
    dispatch({
      type: ActionType.EDIT_FORM,
    });
  };

  const setShouldShare = (shouldShare: boolean) => {
    dispatch({
      type: ActionType.SET_SHARE_MATERIAL,
      shouldShare,
    });
  };

  return (
    <>
      <div
        style={{
          flex: '1 1 auto',
        }}>
        <TrblToggle
          checked={shouldShare}
          ariaLabel="Share material toggle"
          onChangeToggle={() => setShouldShare(!shouldShare)}
        />
        <span style={{ marginLeft: '8px' }}> Share with organization </span>
      </div>

      <div>
        <SecondaryButton
          style={{ marginRight: '10px' }}
          width={'fit-content'}
          label={hasFittedData ? 'Edit' : 'Reset form'}
          disabled={formDisabled}
          onClick={hasFittedData ? editForm : resetForm}
        />
        {inputType === MaterialInputType.FullOctaveAbsorption && (
          <>
            {materialFit === null && <SimpleMaterialFit />}
            {materialFit !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.SurfaceImpedance && (
          <>
            {impedanceFittedData === null && <LambdaMaterialFit data={impedanceData} />}
            {impedanceFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.ReflectionCoefficient && (
          <>
            {reflectionFittedData === null && <LambdaMaterialFit data={reflectionData} />}
            {reflectionFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.PorousAbsorberBuilder && (
          <>
            {builderFittedData === null && <BuilderMaterialFit />}
            {builderFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
      </div>
    </>
  );
};
