import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

const resetPassword = async (userId: string) => {
  const response = await axios.post(`/api/Admin/ResendSetPasswordEmail`, null, {
    params: {
      userId,
    },
  });

  return response;
};

export const useResetPassword = () => {
  return useMutation(async (userId: string) => await resetPassword(userId), {
    onError: () => toast.error('An error occurred while resetting the password!'),
    onSuccess: () => toast.info('Set password email successfully sent!'),
  });
};
