import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type CancelSourceSummingTaskRequest = {
  summedSourceId: string;
  taskId: string;
};

const removeSourceSummingTask = async (taskId: string): Promise<string> => {
  const { data } = await axios.put<string>(`/api/SourceSumming/RemoveSourceSummingTask`, undefined, {
    params: { taskId },
  });
  return data;
};

export const useCancelSourceSummingTask = () => {
  return useMutation(async (request: CancelSourceSummingTaskRequest) => await removeSourceSummingTask(request.taskId));
};
