/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Autocomplete, MenuItem, TextField } from '@mui/material';

import { TrblChevronDownIcon } from '@/components/Icons';

import styles from '../styles.module.scss';

export const TrblCombobox = ({
  menuItems = [],
  value,
  setValue,
  disabled = false,
  placeholder = '',
  title,
  style,
  className,
  autoFocus,
  hideChevronOnFocus,
  extra,
  notShowItem,
}: {
  menuItems: string[];
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  title?: string;
  style?: CSSProperties;
  className?: string;
  autoFocus?: boolean;
  hideChevronOnFocus?: boolean;
  extra?: string;
  notShowItem?: string;
}) => {
  const internalInputElement = useRef<HTMLInputElement>(null);
  const [valueThis, setValueThis] = useState<any>(value);

  useEffect(() => {
    setValueThis(value);
  }, [value]);

  const updateValue = (event: SyntheticEvent, value: any) => {
    if (value !== null && value !== undefined) {
      setValue(value);
      setValueThis(value);
    }
  };

  return (
    <div className={styles['input-div']}>
      <Autocomplete
        ref={internalInputElement}
        disableClearable
        freeSolo={hideChevronOnFocus}
        options={menuItems}
        value={valueThis}
        onChange={updateValue}
        onBlur={(event: any) => {
          updateValue(event, event.target.value);
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              className={styles['text-field']}
              placeholder={placeholder}
              autoFocus={autoFocus}
              title={title}
            />
            {extra && !internalInputElement.current?.matches(':focus-within') && (
              <div className={styles['extra']}>
                <div>{valueThis}</div>
                <span title="New space name">{extra}</span>
              </div>
            )}
          </>
        )}
        renderOption={(props, option: string) =>
          option !== notShowItem && (
            <MenuItem {...props} className={styles['custom-select-item']}>
              {option}
            </MenuItem>
          )
        }
        disabled={disabled}
        style={{ ...style }}
        className={`${styles['combobox']} ${styles['custom-select']} ${className ?? ''} ${
          disabled ? styles['disabled'] : ''
        } `}
        popupIcon={<TrblChevronDownIcon width="10" height="10" fill="#ADADAD" />}
        sx={{
          '.MuiAutocomplete-endAdornment': {
            top: ' calc(50% - 10px)',
            right: '7px !important',
          },
        }}
        componentsProps={{
          paper: {
            sx: {
              position: 'relative',
              background: '#1f1f1f',
              marginTop: '2px',

              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              ul: {
                padding: 0,
                margin: 0,
                maxHeight: '75vh',
              },
              li: {
                padding: '10px 12px !important',
                "&[aria-selected='true']": {
                  background: '#313131 !important',
                },
              },
              '.MuiAutocomplete-noOptions': {
                display: 'none',
              },
            },
          },
        }}
      />
    </div>
  );
};
