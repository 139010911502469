import { Layout } from 'plotly.js';

import { FrequencyWeightingType } from '@/components/Results/context/ReflectogramResultsContext/types';

import { getSPLUnit } from '../utils';

export const getLayout = (
  dbAxisRange: number[],
  selectedFrequencyWeighting: FrequencyWeightingType,
  isSmallHeightScreen: boolean
): Partial<Layout> => ({
  xaxis: {
    color: 'black',
  },
  yaxis: {
    color: 'black',
  },
  polar: {
    bgcolor: '#1a1b1c',
    radialaxis: {
      angle: 90,
      visible: true,
      range: dbAxisRange,
      tickangle: 90,
      nticks: 5,
      tickmode: 'auto',
      color: '#dadada',
      gridcolor: '#444444',
      linecolor: '#dadada',
      title: {
        text: getSPLUnit(selectedFrequencyWeighting),
      },
    },
    angularaxis: {
      direction: 'clockwise',
      rotation: 180, // Place 0 at the top
      tickmode: 'array',
      tickvals: [0, 45, 90, 135, 180, 225, 270, 315, 360],
      ticktext: ['0°', '45°', '90°', '135°', '180°', '-135°', '-90°', '-45°', '0°'], // Custom tick labels
      tickwidth: 2,
      linewidth: 2,
      griddash: 'dot',
      linecolor: '#171717',
      gridcolor: '#444444',
      tickcolor: '#444444',
    },
  },
  font: {
    family: 'Inter, Helvetica, Arial, sans-serif',
    color: '#f5f5f5',
    size: 9,
  },
  legend: {
    xanchor: 'right',
    font: {
      size: 10,
      color: '#DADADA',
    },
  },
  paper_bgcolor: 'transparent',
  margin: isSmallHeightScreen ? { t: 28, b: 28, l: 0, r: 0, pad: 0 } : { t: 40, b: 40, l: 0, r: 0, pad: 0 },
});

export const shouldUpdateLayout = (prevLayout: Partial<Layout>, newLayout: Partial<Layout>): boolean => {
  return JSON.stringify(prevLayout) !== JSON.stringify(newLayout);
};
