import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAnalyticsIcon: FC<IconProps> = ({ fill }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.01378 6.70825C1.79853 6.70825 1.62469 6.83892 1.62469 6.99992V11.6666C1.62469 11.8277 1.75528 11.9583 1.91636 11.9583H3.66636C3.82744 11.9583 3.95803 11.8277 3.95803 11.6666V6.99992C3.95803 6.83892 3.78303 6.70825 3.56894 6.70825H2.01378Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M6.68077 8.45825C6.46552 8.45825 6.29169 8.58892 6.29169 8.74992V11.6666C6.29169 11.8277 6.42227 11.9583 6.58335 11.9583H8.33335C8.49444 11.9583 8.62502 11.8277 8.62502 11.6666V8.74992C8.62502 8.58892 8.45002 8.45825 8.23594 8.45825H6.68077Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M10.9584 11.9583H12.7084C12.8636 11.9639 12.994 11.8429 13 11.6877V4.64625C13 4.49633 12.825 4.375 12.6109 4.375H11.0558C10.8405 4.375 10.6667 4.49633 10.6667 4.64625V11.6877C10.6727 11.8429 10.8032 11.9639 10.9584 11.9583Z"
      fill={fill || '#DADADA'}
    />
    Stuff
    <path
      d="M13.5834 12.8333H0.75002C0.427854 12.8333 0.166687 13.0944 0.166687 13.4166C0.166687 13.7388 0.427854 13.9999 0.75002 13.9999H13.5834C13.9055 13.9999 14.1667 13.7388 14.1667 13.4166C14.1667 13.0944 13.9055 12.8333 13.5834 12.8333Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M11.2806 2.29892C11.3657 2.32136 11.4534 2.33293 11.5414 2.33333C12.1857 2.33333 12.708 1.811 12.708 1.16667C12.708 0.522334 12.1857 0 11.5414 0C10.897 0 10.3747 0.522334 10.3747 1.16667C10.3751 1.22884 10.3806 1.29088 10.391 1.35217C10.3996 1.40062 10.3829 1.45011 10.3467 1.48342L7.56188 4.08333C7.52573 4.11697 7.47507 4.13013 7.42713 4.11833C7.34198 4.09597 7.2544 4.08422 7.16638 4.08333C6.96391 4.08335 6.76499 4.13645 6.58946 4.23733C6.54706 4.26134 6.49554 4.26286 6.45179 4.24142L3.73113 2.8805C3.68801 2.85833 3.6584 2.81653 3.65179 2.7685C3.58475 2.2613 3.19401 1.85753 2.68927 1.7739C2.18454 1.69027 1.68446 1.94643 1.45741 2.40491C1.23036 2.86338 1.32971 3.4164 1.70215 3.76717C2.07459 4.11794 2.63257 4.18401 3.07663 3.92992C3.11903 3.90591 3.17054 3.90439 3.21429 3.92583L5.93496 5.28617C5.97816 5.30853 6.00776 5.35054 6.01429 5.39875C6.10786 5.99436 6.63984 6.42056 7.24151 6.38196C7.84319 6.34336 8.31634 5.85268 8.33304 5.25C8.33266 5.18802 8.32719 5.12617 8.31671 5.06508C8.30812 5.01646 8.32482 4.9668 8.36104 4.93325L11.1459 2.33333C11.1821 2.2999 11.2328 2.28696 11.2806 2.29892Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
