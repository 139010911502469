import { useQuery } from '@tanstack/react-query';

import { SpaceDetailsDto } from '@/types';

import axios from '@/axios';

const getSpacesByProjectId = async (projectId: string) => {
  const { data } = await axios.get<SpaceDetailsDto[]>(`/api/Space/GetSpacesByProjectId`, {
    params: {
      projectId,
    },
  });

  return data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useGetSpacesByProjectId = (projectId: string) => {
  const query = useQuery<SpaceDetailsDto[], boolean>(
    ['spaces-for-project', projectId],
    () => getSpacesByProjectId(projectId),
    {
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
