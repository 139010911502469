import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useResultComparisonContext } from '../context/ResultComparisonContext';

import { useGetModelInformation, useGetSimulationById } from '@/hooks';

import { ActionType } from '../constants';

import { ResultComparisonState } from '../../../types';

type UseLoadAndSelectSimulationProps = {
  simulationId: string;
  defaultState: ResultComparisonState | null;
  color: string;
};

export const useLoadAndSelectSimulation = ({ simulationId, defaultState, color }: UseLoadAndSelectSimulationProps) => {
  const { dispatch } = useResultComparisonContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: fetchedSimulation } = useGetSimulationById(simulationId, true);
  const { data: fetchedModelInformation } = useGetModelInformation(fetchedSimulation?.modelId || null);

  useEffect(() => {
    if (fetchedSimulation && fetchedModelInformation && defaultState) {
      // if no sid in url then it's empty results and we need to update the route to the newSimulationObject
      // (this is a catch for adding the first simulation to an empty results )
      if (!searchParams.get('sid') && !searchParams.get('resultsId')) {
        setSearchParams({ mid: fetchedSimulation.modelId, sid: fetchedSimulation.id });
      }

      const { title, resultType, sourcePointIds, receiverPointIds, gridReceiverPointIds, modelName, spaceName } =
        defaultState;

      dispatch({
        type: ActionType.SELECT_SIMULATION,
        payload: {
          modelName,
          spaceName,
          selectedSimulation: fetchedSimulation,
          title,
          resultType,
          sourcesSelected: sourcePointIds ?? [],
          receiversSelected: receiverPointIds || [],
          surfaceReceiversSelected: gridReceiverPointIds,
          projectId: fetchedModelInformation.projectId,
          color: color,
        },
      });
    }
  }, [fetchedSimulation, fetchedModelInformation]);
};
