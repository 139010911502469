import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteSimulation = async (simulationId: string): Promise<any> => {
  const { data } = await axios.delete(`/api/Simulation/Delete?id=${simulationId}`);
  return data;
};

export const useDeleteSimulation = () => {
  return useMutation(async (simulationId: string) => await deleteSimulation(simulationId));
};
