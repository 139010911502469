import { useEffect, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import { EditSimulationPopup } from './EditSimulationPopup';

import { useSaveUpdatedSimulation } from './hooks/useSaveUpdatedSimulation';

import { Simulation } from '@/types';

export const EditSimulation = ({
  showPopup,
  closePopup,
  updatedSimulation,
  onConfirm,
  saveText,
}: {
  showPopup: boolean;
  closePopup?: () => void;
  updatedSimulation: Simulation | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: any;
  saveText?: string;
}) => {
  const { dispatch } = useEditorContext();

  useEffect(() => {
    setShowConfirmPopup(showPopup);
  }, [showPopup]);

  const [showConfirmPopup, setShowConfirmPopup] = useState(showPopup);
  const saveSimulation = useSaveUpdatedSimulation();

  const closeModal = () => {
    if (closePopup) {
      closePopup();
    }
    setShowConfirmPopup(false);
    dispatch({
      type: ActionType.SHOW_EDIT_MODAL,
      editSimulation: { showModal: false, updatedSimulation: null },
    });
  };

  const onConfirmation = async () => {
    onConfirm(updatedSimulation);
    if (updatedSimulation) {
      const newSim = {
        ...updatedSimulation,
      };
      await saveSimulation(newSim, saveText);
    }
  };

  return (
    <EditSimulationPopup
      updatedSimulation={updatedSimulation}
      showEditConfirmation={showConfirmPopup}
      setShowEditConfirmation={closeModal}
      onConfirmation={onConfirmation}
    />
  );
};
