import styles from './styles.module.scss';

type TrblToggleProps = {
  checked: boolean;
  label: string;
  onChangeToggle: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const PeriodToggle = ({ checked, label = '', onChangeToggle }: TrblToggleProps) => {
  return (
    <label className={styles['switch']} htmlFor="toggle">
      <input aria-label={label} id="toggle" type="checkbox" checked={checked} onChange={onChangeToggle}></input>
      <div className={`${styles['slider']} ${styles['round']}`}>
        <div className={styles['slider-background']}></div>
        <div className={styles['label-container']}>
          <span id={styles['yearly-label']} className={styles['toggle-label']}>
            Yearly
          </span>
          <span id={styles['monthly-label']} className={styles['toggle-label']}>
            Monthly
          </span>
        </div>
      </div>
    </label>
  );
};
