import { useEffect, useRef, useState } from 'react';

import { LibraryRow } from '@/components/LibraryPanel';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { setElementFocus } from '@/components/LibraryPanel/utils';
import { ActionType, usePresetContext } from '../Auralizer/PresetContext';

import { LibrarySound } from '../Auralizer/constants/audioSounds';

import { AuralizerSound } from './types';

import styles from './styles.module.scss';

export const SoundRow = ({
  sound,
  sourceIndex,
  setShowPopup,
}: {
  sound: LibrarySound;
  sourceIndex: number;
  setShowPopup: (show: boolean) => void;
}) => {
  const listItemRef = useRef<HTMLLIElement>(null);

  const [isAssigned, setIsAssigned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const { highlightedItemId, dispatch: libDispatch } = useLibraryPanelContext();

  const { selectedSounds, dispatch } = usePresetContext();

  const [selectedSound, setSelectedSound] = useState<AuralizerSound | null>(null);

  useEffect(() => {
    const selectedSourceSound = selectedSounds[sourceIndex];
    setSelectedSound(selectedSourceSound);
  }, [selectedSounds]);

  useEffect(() => {
    if (selectedSound && selectedSound.id === sound.id) {
      setIsAssigned(true);
      setElementFocus(listItemRef);
    }
  }, [selectedSound, sound, listItemRef]);

  useEffect(() => {
    if (highlightedItemId === sound.id) {
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else if (highlightedItemId === null && selectedSound && selectedSound.id === sound.id) {
      setIsHighlighted(true);
    } else {
      setIsHighlighted(false);
    }
  }, [highlightedItemId, listItemRef, selectedSound]);

  const assignSound = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch({
      type: ActionType.SET_SELECTED_SOUND,
      sourceIndex,
      sound: { id: sound.id, name: sound.name, soundPath: sound.soundPath, urlObject: null },
    });

    libDispatch({ type: LibActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: sound.id });
    setShowPopup(false);
  };

  return (
    <LibraryRow
      listItemRef={listItemRef}
      itemId={sound.id}
      isHighlighted={isHighlighted}
      isAssigned={isAssigned}
      onAssign={assignSound}>
      <div className={styles.row_content}>
        <p className={styles.name_column} title={sound.name || ''}>
          {sound.name}
        </p>
        <p className={styles.category_column} title={sound.category || ''}>
          {sound.category}
        </p>
      </div>
    </LibraryRow>
  );
};
