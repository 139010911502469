import { useEffect, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import './style.scss';

export const AutoSaveText = () => {
  const { autoSaveText, dispatch } = useEditorContext();
  const [text, setText] = useState<string | undefined>();
  const [trialBannerHeight, setTrialBannerHeight] = useState<number>(0);

  useEffect(() => {
    setText(autoSaveText);
    setTimeout(() => {
      dispatch({ type: ActionType.SET_AUTO_SAVE_TEXT, text: '' });
    }, 1600);
  }, [autoSaveText]);

  useEffect(() => {
    const trialBanner = document.querySelector<HTMLElement>('.trial-expiring-header');
    if (trialBanner) {
      trialBanner.offsetHeight;
      setTrialBannerHeight(trialBanner.offsetHeight);
    }
  }, []);

  return (
    <div
      className={`autosave-text ${text && text.length > 0 ? 'show' : ''}`}
      style={{ top: `${trialBannerHeight + 80}px` }}>
      {text}
    </div>
  );
};
