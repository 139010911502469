import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { UserDto } from './types';

import axios from '@/axios';

const getUserById = async (userId: string): Promise<UserDto> => {
  const { data } = await axios.get(`/api/Admin/GetUser?id=${userId}`, {
    params: {
      id: userId,
    },
  });

  return data;
};

export const useGetUserById = (userId?: string) => {
  return useQuery<UserDto>(['user', userId], () => getUserById(userId!), {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    onError: () => toast.error('An error occurred while loading the user!'),
  });
};
