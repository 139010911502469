import { useEffect, useState } from 'react';

import { useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { FilterTag } from '@/components/Shared/Tags';
import { LibraryItemPreview, MaterialDetailsPopup, UserOwnedIconIndicator } from '@/components';
import { FrequencyTable } from '@/components/StatisticalEstimates/components/FrequencyTable';

import { Material } from '@/types';

import './style.scss';

export const MaterialDetailsPreview = ({ materialId }: { materialId: string }) => {
  const { dispatch } = useEditorContext();
  const {
    state: { userInfo },
  } = useBaseContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();

  const [showPopup, setShowPopup] = useState<undefined | boolean>(undefined);
  const [material, setMaterial] = useState<Material | undefined>(undefined);

  useEffect(() => {
    if (materialId && filteredMaterials) {
      const selectedMaterial = filteredMaterials.find((material) => material.id === materialId);
      setMaterial(selectedMaterial);
    }
  }, [materialId, filteredMaterials]);

  useEffect(() => {
    if (showPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showPopup,
      });
    }
  }, [showPopup]);

  return (
    <>
      <LibraryItemPreview title="Absorption coefficient" type="Material" setShowDetailsPopup={setShowPopup}>
        <div style={{ width: '90%', margin: `12px -1px 6px -8px` }}>
          <FrequencyTable
            data={material?.absorptionCoefficients ?? []}
            showData={material?.absorptionCoefficients ? material.absorptionCoefficients.length > 0 : false}
          />
        </div>
        {material && (
          <div className="material-preview-footer">
            <p className="scatter-text" style={{ fontWeight: '600' }}>
              Default scattering: {material?.defaultScattering ?? '-'}{' '}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', marginRight: '16px' }}>
                <UserOwnedIconIndicator
                  showUserIcon={material.isUserCreated && userInfo?.id === material.userId}
                  showOrganization={material.isSharedWithOrganization}
                  size="medium"
                  color="#DADADA"
                />
              </div>
              <FilterTag tag={material?.category} />
            </div>
          </div>
        )}
      </LibraryItemPreview>
      {showPopup && material && (
        <MaterialDetailsPopup material={material} showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
    </>
  );
};
