import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

const deleteSharedAuralization = async (auralizationShareId: string): Promise<void> => {
  await axios.post(`/api/SharedAuralization/DeleteSharedAuralization`, null, {
    params: {
      auralizationShareId,
    },
  });
};

export const useDeleteSharedAuralization = () => {
  return useMutation(async (auralizationShareId: string) => await deleteSharedAuralization(auralizationShareId), {
    onError: () => {
      toast.error('An error occurred while deleting the auralization share. Please refresh the browser.');
    },
  });
};
