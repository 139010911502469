import { useState } from 'react';

import { MultiSelect } from '@/components/Shared/MultiSelect';
import { TrblFilterIcon } from '@/components/Icons';

import './styles.scss';

// TODO: better implementation would be to extract the menu itself to a separate component
// so that it can be used standalone and in the "regular" multiselect

export const LibraryFilter = ({
  selectedItems,
  selectItems,
  itemOptions,
  title,
}: {
  selectedItems: string[];
  selectItems: (value: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemOptions: any[];
  title?: string;
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      <button
        title={title}
        className={`filter-btn ${selectedItems.length ? 'selected' : ''}`}
        onClick={() => setOpenMenu(true)}>
        <TrblFilterIcon fill="#999999" />
      </button>
      <MultiSelect
        options={itemOptions}
        selected={selectedItems}
        onChange={selectItems}
        style={{ width: 0, height: 0, opacity: 0, margin: '12px 0 0 -16px' }}
        menuOpen={openMenu}
        handleThisClose={() => setOpenMenu(false)}
      />
    </div>
  );
};
