import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblRecentSimulations: FC<IconProps> = ({ fill = '#DADADA', width = '18', height = '18' }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1901 3.79388L14.7549 2.75351C14.5579 2.67019 14.332 2.69383 14.1565 2.8161C13.981 2.93838 13.8806 3.14217 13.8905 3.35582L14.0253 6.00345C14.0362 6.21954 14.1587 6.41441 14.3488 6.51779C14.5388 6.62118 14.769 6.61818 14.9563 6.50987C14.9857 6.4929 14.952 6.51235 15.4577 6.16302C16.5769 8.70491 16.101 11.6669 14.242 13.7303C12.383 15.7937 9.48647 16.5749 6.84203 15.7259C6.55454 15.6106 6.22674 15.6655 5.99243 15.8681C5.75812 16.0707 5.6565 16.3871 5.72905 16.6882C5.80159 16.9894 6.03617 17.2248 6.33703 17.2985C9.65936 18.3676 13.2991 17.3511 15.5868 14.7153C17.8744 12.0795 18.3687 8.33301 16.8428 5.19415L17.3049 4.87191C17.489 4.74232 17.5885 4.52306 17.5646 4.29913C17.5408 4.0752 17.3974 3.88179 17.1901 3.79388Z"
      fill={fill}
    />
    <path
      d="M3.00369 11.515L2.53842 11.84C1.39105 9.23785 1.91635 6.20134 3.87121 4.13581C5.82606 2.07028 8.82908 1.37874 11.4905 2.38122C11.9162 2.53215 12.3844 2.31394 12.5426 1.89083C12.7007 1.46771 12.4906 0.9959 12.0702 0.830509C8.73143 -0.429761 4.96165 0.474539 2.55795 3.11232C0.154242 5.75011 -0.396853 9.58746 1.16737 12.7951L0.701495 13.1224C0.515179 13.2513 0.41385 13.4714 0.437105 13.6968C0.460359 13.9221 0.604505 14.1169 0.813214 14.205L3.24939 15.2439C3.44636 15.3282 3.67288 15.305 3.84866 15.1825C4.02445 15.06 4.12467 14.8555 4.11381 14.6416L3.97941 11.9946C3.96816 11.769 3.83543 11.5673 3.63272 11.4677C3.43001 11.368 3.1892 11.3861 3.00369 11.515Z"
      fill={fill}
    />
    <path
      d="M8.20298 12.5348C8.35021 12.5891 8.5157 12.546 8.61773 12.4268L11.5735 8.98578C11.716 8.8189 11.7484 8.58441 11.6566 8.38512C11.5647 8.18584 11.3654 8.05814 11.146 8.05803H10.396C10.2924 8.05803 10.2085 7.97408 10.2085 7.87053V5.43303C10.2084 5.27625 10.1109 5.13604 9.96388 5.08153C9.81689 5.02701 9.65149 5.06969 9.54923 5.18853L6.59273 8.63028C6.45016 8.79726 6.41778 9.03193 6.5098 9.23127C6.60182 9.43062 6.80142 9.55821 7.02098 9.55803H7.77098C7.87454 9.55803 7.95848 9.64197 7.95848 9.74553V12.183C7.9584 12.3399 8.05594 12.4802 8.20298 12.5348Z"
      fill={fill}
    />
  </svg>
);
