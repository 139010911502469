import { IconProps } from '@/types';

export const TrblInprogressIcon = ({ fill }: IconProps) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0C9.31222 0.00358242 11.9964 2.68778 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM1.00028 6.00007C1.00028 8.76149 3.23885 11.0001 6.00028 11.0001C8.76045 10.997 10.9972 8.76024 11.0003 6.00007C11.0003 3.23865 8.7617 1.00007 6.00028 1.00007C3.23885 1.00007 1.00028 3.23865 1.00028 6.00007Z"
      fill={fill || '#ADADAD'}
    />
    <path
      d="M6 2C5.86193 2 5.75 2.11193 5.75 2.25V6.25C5.75 6.38807 5.86193 6.5 6 6.5H9.7375C9.87565 6.47949 9.98021 6.36447 9.9875 6.225C10.0502 5.12766 9.65825 4.05292 8.90383 3.25357C8.14942 2.45423 7.09913 2.00082 6 2Z"
      fill={fill || '#ADADAD'}
    />
  </svg>
);
