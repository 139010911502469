import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type SetImportedModelIdRequest = {
  spaceExtractionTaskResultId: string;
  importedModelId: string;
};

const setImportedModelId = async (request: SetImportedModelIdRequest) => {
  const { data } = await axios.patch(`/api/SpaceExtraction/SetImportedModelIdForExtractionResult`, null, {
    params: {
      spaceExtractionTaskResultId: request.spaceExtractionTaskResultId,
      importedModelId: request.importedModelId,
    },
  });
  return data;
};

export const useSetImportedModelId = () => {
  return useMutation(async (data: SetImportedModelIdRequest) => await setImportedModelId(data));
};
