import { Text } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { ClampToEdgeWrapping, Euler, RepeatWrapping, TextureLoader, Vector3 } from 'three';

import { MeshWithColor } from './MeshWithColor';
import { MeshWithTexture } from './MeshWithTexture';

import { BoxProps, getAdjustedXPos, scaledBoxWidth, TEXT_CONFIG } from '../constants';

export const RigidBackingBox = ({ box, index }: { box: BoxProps; index: number }) => {
  const { textureUrl, title, width, height, depth, xPos } = box;
  const texture = useLoader(TextureLoader, textureUrl!);

  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.needsUpdate = true;
  texture.repeat.set(scaledBoxWidth(width), 1);
  texture.wrapS = ClampToEdgeWrapping;
  texture.wrapT = ClampToEdgeWrapping;

  const textConfig = {
    ...TEXT_CONFIG,
    rotation: new Euler(89.55, 0, 89.55),
    position: new Vector3(-0.6, -0.8, 0),
  };

  return (
    <group>
      <mesh position={new Vector3(getAdjustedXPos(xPos, index) || 0, 0, 0)}>
        <Text {...textConfig} anchorX="center" anchorY="bottom" textAlign="center">
          {title}
        </Text>
        <boxGeometry args={[scaledBoxWidth(width), height, depth]} />
        <MeshWithColor index={0} />
        <MeshWithColor index={1} />
        <MeshWithColor index={2} />
        <MeshWithTexture index={3} texture={texture} alphaTest={0.15} doubleSided />
        <MeshWithColor index={4} />
        <MeshWithColor index={5} />
      </mesh>
    </group>
  );
};
