import { useState } from 'react';

import { TertiaryButton } from '@/components/Shared/Buttons';
import { usePresetContext } from '@/components/Auralizer/PresetContext';
import { DeleteItemPopup } from '@/components/LibraryPanel/components/DeleteItemPopup';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { LoadPresetsPopup } from '../LoadPreset';
import { SavePresetPopup } from '../SavePresetPopup';
import { SharePresetPopup } from '../SharePreset';

import { useAuralizerPresetActions } from '../hooks';

export const PresetsActionMenu = () => {
  const { selectedPreset, selectedPresetEdited } = usePresetContext();

  const { createPreset, updatePresetDetails, updatePresetSettings, onConfirmDelete, onClearPreset } =
    useAuralizerPresetActions();

  const [showSavePresetPopup, setShowSavePresetPopup] = useState(false);
  const [showEditPresetPopup, setShowEditPresetPopup] = useState(false);
  const [showLoadPresetPopup, setShowLoadPresetPopup] = useState(false);
  const [pendingShare, setPendingShare] = useState(false);
  const [showSharePresetPopup, setShowSharePresetPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleSubmitPreset = async (name: string, description: string): Promise<boolean> => {
    const success = await createPreset(name, description);
    if (success) {
      setShowSavePresetPopup(false);
      if (pendingShare) {
        setShowSharePresetPopup(true);
        setPendingShare(false);
      }
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitEditPreset = async (name: string, description: string): Promise<boolean> => {
    const success = await updatePresetDetails(name, description);
    if (!success) {
      return false;
    }

    setShowEditPresetPopup(false);
    return true;
  };

  const actions = [
    {
      key: 'save',
      value: 'Save',
      hidden: selectedPreset === null || !selectedPresetEdited,
      onClick: updatePresetSettings,
    },
    { key: 'saveAs', value: 'Save as new', hidden: false, onClick: () => setShowSavePresetPopup(true) },
    { key: 'load', value: 'Load', hidden: false, onClick: () => setShowLoadPresetPopup(true) },
    { key: 'clear', value: 'Clear', hidden: selectedPreset === null, onClick: onClearPreset },
    {
      key: 'edit',
      value: 'Edit details',
      hidden: selectedPreset === null,
      onClick: () => setShowEditPresetPopup(true),
    },
    {
      key: 'delete',
      value: 'Delete',
      hidden: selectedPreset === null,
      onClick: () => setShowDeleteConfirmation(true),
    },
  ];

  const handleShareButtonClick = () => {
    if (selectedPreset) {
      setShowSharePresetPopup(true);
    } else {
      setPendingShare(true);
      setShowSavePresetPopup(true);
    }
  };

  const handleCloseSavePresetPopup = () => {
    setShowSavePresetPopup(false);
    setShowEditPresetPopup(false);
    setPendingShare(false);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        <TertiaryButton sx={{ minHeight: '24px', height: '24px' }} label="Share" onClick={handleShareButtonClick} />
        <TrblActionsMenu
          id="preset-actions"
          classNames={'preset-actions'}
          aria-haspopup="true"
          label="Presets"
          actions={actions}
          title="Open preset menu"
        />
      </div>
      {showSavePresetPopup && (
        <SavePresetPopup
          onClose={handleCloseSavePresetPopup}
          onSubmit={handleSubmitPreset}
          saveButtonLabel={pendingShare ? 'Save and continue' : undefined}
        />
      )}
      {showEditPresetPopup && (
        <SavePresetPopup
          defaultName={selectedPreset?.name}
          defaultDescription={selectedPreset?.description}
          isEditing={true}
          onClose={handleCloseSavePresetPopup}
          onSubmit={handleSubmitEditPreset}
        />
      )}
      {showLoadPresetPopup && (
        <LoadPresetsPopup showPopup={showLoadPresetPopup} setShowPopup={setShowLoadPresetPopup} />
      )}
      {showSharePresetPopup && selectedPreset?.id && (
        <SharePresetPopup presetId={selectedPreset.id} onClose={() => setShowSharePresetPopup(false)} />
      )}
      {showDeleteConfirmation && selectedPreset && (
        <DeleteItemPopup
          onCancelDelete={() => setShowDeleteConfirmation(false)}
          onConfirmDelete={() => {
            setShowDeleteConfirmation(false);
            onConfirmDelete();
          }}
          type="preset"
          showWarning={false}
          name={selectedPreset.name}
        />
      )}
    </>
  );
};
