import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblResetIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33089 0.734145C4.76417 0.836497 2.51543 2.4839 1.64397 4.90031C1.62056 4.96554 1.55448 5.00529 1.48589 4.9954L0.500638 4.84723C0.390609 4.83312 0.281781 4.88092 0.217721 4.97148C0.154808 5.0637 0.150051 5.18374 0.205471 5.28065L1.76822 8.02231C1.81322 8.10102 1.89225 8.15432 1.98208 8.16655C2.07191 8.17878 2.16232 8.14854 2.22672 8.08473L4.42589 5.89956C4.50443 5.82181 4.53227 5.70634 4.49781 5.60133C4.46334 5.49632 4.37248 5.41981 4.26314 5.40373L3.26272 5.2544C3.21743 5.24781 3.17786 5.22034 3.15585 5.18022C3.13384 5.14009 3.13194 5.09196 3.15072 5.05023C4.11253 2.87262 6.52061 1.72396 8.81824 2.3468C11.1159 2.96964 12.6141 5.17724 12.3445 7.54247C12.0748 9.9077 10.1181 11.7214 7.73922 11.8111C7.47871 11.8158 7.2405 11.9591 7.11431 12.187C6.98811 12.415 6.99312 12.6929 7.12743 12.9162C7.26175 13.1394 7.50496 13.2741 7.76547 13.2694H7.79289C10.0416 13.2004 12.0811 11.9321 13.1375 9.94584C14.1939 7.95953 14.1055 5.55953 12.9057 3.65638C11.7059 1.75324 9.57858 0.638584 7.33089 0.735312V0.734145Z"
      fill={fill}
    />
  </svg>
);
