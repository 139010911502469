import { CSSProperties, FC } from 'react';

import componentStyles from './styles.module.scss';

type ControlButtonProps = {
  icon: React.ReactElement;
  style?: CSSProperties;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const AmbisonicsButton: FC<ControlButtonProps> = ({ icon, style, ...props }) => (
  <button {...props} className={componentStyles['ambisonics-button']} style={style}>
    {icon}
  </button>
);
