import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SourceDefinition } from '@/types';

import axios from '@/axios';

const getSourceDefinitions = async () => {
  const { data } = await axios.get(`/api/SourceDefinition/GetSourceDefinitions`);

  return data;
};

export const useGetSourceDefinitions = (enabled = true) => {
  const query = useQuery<SourceDefinition[], boolean>(['source-definition'], () => getSourceDefinitions(), {
    enabled,
    refetchOnWindowFocus: false,
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.status !== 403) {
        toast.error('An error occurred while fetching source definitions. Please refresh the browser.');
      }
    },
  });

  return query;
};
