import { useEffect, useRef, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FilterTag } from '@/components/Shared/Tags';
import { TabFilters } from './TabFilters';

import './styles.scss';

export const CategoryFilter = ({
  categories,
  onSelectCategories,
}: {
  categories: string[];
  onSelectCategories: (category: string[]) => void;
}) => {
  const categoriesRef = useRef<HTMLDivElement>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    if (categoriesRef?.current) {
      const height = categoriesRef.current.offsetHeight;
      setElementHeight(height);
    }
  }, [categoriesRef]);

  const selectCategory = (category: string, event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'click') event.stopPropagation();

    let newArray = [...selectedCategories];

    const index = selectedCategories.findIndex((selected) => selected === category);
    if (index === -1) {
      newArray = [...selectedCategories, category];
    } else {
      newArray.splice(index, 1);
    }

    setSelectedCategories(newArray);
    onSelectCategories(newArray);
  };

  const selectFilters = (categories: string[]) => {
    setSelectedCategories([...categories]);
    onSelectCategories([...categories]);
  };

  return (
    <div style={{ position: 'relative', borderBottom: '1px solid #1c1c1c' }} onClick={() => setIsExpanded(!isExpanded)}>
      <button className="expand-button">
        {isExpanded ? <ExpandLessIcon color="disabled" /> : <ExpandMoreIcon color="disabled" />}
      </button>

      <div
        ref={categoriesRef}
        className={`categories-row ${isExpanded ? 'expanded' : ''} ${elementHeight >= 170 ? 'maxHeight' : ''}`}>
        <TabFilters selectFilters={selectFilters} selectedCategories={selectedCategories} />

        {categories.map((category) => (
          <FilterTag
            key={category}
            tag={category}
            selectTag={selectCategory}
            isSelected={selectedCategories.includes(category)}
          />
        ))}
      </div>
      {elementHeight >= 170 && <div className={`${isExpanded ? '' : 'fadeout'}`}></div>}
    </div>
  );
};
