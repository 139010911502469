import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useBaseContext } from '@/context/BaseContext';

import { Text } from '@/components/Shared/Text';
import { useFeatureFlags } from '../FeatureToggles';

import './styles.scss';

type TrialExpiringBannerProps = {
  daysUntilExpired: number | null;
  type: string;
};

export const TrialExpiringBanner: FC<TrialExpiringBannerProps> = ({ daysUntilExpired, type = 'trial' }) => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const { automaticBillingFeature } = useFeatureFlags();

  const isAdmin = userInfo?.roles.includes('Admin');

  return (
    <div className={'trial-expiring-header'}>
      <span style={{ lineHeight: '12px' }}>
        {daysUntilExpired === 0 ? (
          <Text color="#171717" type="regular-12px">
            {`Your ${type} ends today.`}
          </Text>
        ) : (
          <>
            <Text color="#171717" type="regular-12px">
              {`You have `}
            </Text>
            <Text color="#171717" type="semibold-12px">
              {daysUntilExpired && daysUntilExpired > 1 ? `${daysUntilExpired} days ` : `${daysUntilExpired} day `}
            </Text>
            <Text color="#171717" type="regular-12px">
              {`left in your ${type}.`}
            </Text>
          </>
        )}

        {!isAdmin && (
          <>
            <Text color="#171717" type="regular-12px">
              {' Contact your '}
            </Text>
            <Text color="#171717" type="semibold-12px">
              {' admin '}
            </Text>
            <Text color="#171717" type="regular-12px">
              {'to select a subscription.'}
            </Text>
          </>
        )}
      </span>
      {automaticBillingFeature && isAdmin && (
        <Link className="link-button" to="/subscription">
          Choose subscription
        </Link>
      )}
    </div>
  );
};
