import { FC } from 'react';

export const ToastInfoIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 8 8" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4ZM4.72547 7.00016C4.98011 7.00016 5.18653 6.80835 5.18653 6.57174V6.33143C5.18653 6.09981 4.98446 5.91204 4.73519 5.91204C4.42467 5.91204 4.2069 5.62744 4.30888 5.35491L4.99973 3.99988C5.15598 3.58229 4.82229 3.14621 4.34649 3.14621H3.16848C2.91385 3.14621 2.70742 3.33802 2.70742 3.57463V3.67992C2.70742 3.89398 2.89418 4.06751 3.12455 4.06751C3.40323 4.06751 3.60353 4.31657 3.52458 4.56491L2.83697 6.17547C2.70607 6.58722 3.03817 7.00015 3.50023 7.00016L4.72547 7.00016ZM4.875 1.62505C4.875 1.13882 4.48628 0.75 4.00005 0.75C3.51381 0.75 3.125 1.17818 3.125 1.62505C3.125 2.07192 3.51381 2.5 4.00005 2.5C4.48628 2.5 4.875 2.11128 4.875 1.62505Z"
      fill="#272727"
    />
  </svg>
);
