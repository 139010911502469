import { TrblTooltip } from '@/components/Shared';
import { TrblDownloadIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const ExportHeatmapButton = ({
  isExporting,
  setIsExporting,
}: {
  isExporting: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className={styles['export-heatmap-btn']}>
      <TrblTooltip title="Export heatmap to .png">
        <button onClick={() => setIsExporting(true)} disabled={isExporting}>
          <TrblDownloadIcon fill={`${isExporting ? '#999999' : '#dadada'}`} />
        </button>
      </TrblTooltip>
    </div>
  );
};
