import { useQuery } from '@tanstack/react-query';

import { AuralizationPresetDto } from '../types';

import axios from '@/axios';

export const getAuralizationPresetsForSpace = async (spaceId: string): Promise<AuralizationPresetDto[]> => {
  const { data } = await axios.get(`/api/AuralizationPreset/GetAuralizationPresetsForSpace?spaceId=${spaceId}`);
  return data;
};

export const useGetAuralizationPresetsForSpace = (spaceId: string) => {
  return useQuery<AuralizationPresetDto[]>(
    ['presets-by-space-id', spaceId],
    () => getAuralizationPresetsForSpace(spaceId),
    {
      enabled: spaceId.length > 0,
      refetchOnWindowFocus: false,
    }
  );
};
