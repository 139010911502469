import { useQuery } from '@tanstack/react-query';

import { ThumbnailsForModelBase } from '@/types';

import axios from '@/axios';

const getThumbnailsForModelBases = async (body: string[]) => {
  const { data } = await axios.post(`/api/Model/GetThumbnailsForModelBases`, body);

  return data;
};

export const useGetThumbnailsForModelBases = (body: string[]) =>
  useQuery<ThumbnailsForModelBase[], boolean>(
    ['thumbnails-model-bases', body],
    () => getThumbnailsForModelBases(body),
    {
      enabled: !!body.length,
      refetchOnWindowFocus: false,
    }
  );
