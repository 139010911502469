import axios from 'axios';

import { config } from '@/__config__/config';

const { apiUrl } = config;

// TODO: make this handle errors properly
export default axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
  },
});
