/* eslint-disable @typescript-eslint/no-explicit-any */
import { Duration } from 'dayjs/plugin/duration';

import { UserBasicDto } from '@/types';

export const deepCompare = (arg1: any, arg2: any) => {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (
      Object.prototype.toString.call(arg1) === '[object Object]' ||
      Object.prototype.toString.call(arg1) === '[object Array]'
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every((key): any => {
        if (typeof arg1[key] === 'string') arg1[key] = arg1[key].toLowerCase();
        if (typeof arg2[key] === 'string') arg2[key] = arg2[key].toLowerCase();

        return deepCompare(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
};

export function roundFloat(value: number | string, decimal: number): number {
  const decimalPow = Math.pow(10, decimal);
  return Math.round((parseFloat(value.toString()) + Number.EPSILON) * decimalPow) / decimalPow;
}

export const getTimeStringByDuration = (duration: Duration) => {
  let minutes = duration.seconds() > 30 ? duration.minutes() + 1 : duration.minutes();
  if (duration.days() > 0 || duration.hours() > 0 || minutes === 60) {
    let hours = duration.hours(); // this can be undefined
    const days = duration.days(); // this can be undefined

    if (days > 0) {
      hours = hours + days * 24;
    }
    if (minutes === 60) {
      hours = hours ? hours++ : 1;
      minutes = 0;
    }
    if (hours === 1) {
      return `${hours} hour` + (minutes ? ` ${minutes} min` : '');
    } else {
      return `${hours} hours` + (minutes ? ` ${minutes} min` : '');
    }
  } else if (minutes < 2) {
    return '< 2 min';
  } else {
    return `${minutes} min`;
  }
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Implements a stable sort without mutating the original array ()
export const toSorted = <T>(array: T[], compareFunction?: (a: T, b: T) => number): T[] => {
  return [...array].sort(compareFunction);
};

export const sortMembers = (members: UserBasicDto[]) => {
  const sortedByName = toSorted(members, (a, b) => {
    // Convert names to lowercase for case-insensitive sorting
    const firstNameA = a.firstName?.toLowerCase();
    const firstNameB = b.firstName?.toLowerCase();

    // Compare the names and return -1, 0, or 1
    if (firstNameA && firstNameB && firstNameA < firstNameB) {
      return -1;
    } else if (firstNameA && firstNameB && firstNameA > firstNameB) {
      return 1;
    } else {
      // Convert names to lowercase for case-insensitive sorting
      const lastNameA = a.lastName?.toLowerCase();
      const lastNameB = b.lastName?.toLowerCase();
      if (lastNameA && lastNameB && lastNameA < lastNameB) {
        return -1;
      } else if (lastNameA && lastNameB && lastNameA > lastNameB) {
        return 1;
      } else {
        // Convert emails to lowercase for case-insensitive sorting
        const emailA = a.email?.toLowerCase();
        const emailB = b.email?.toLowerCase();
        if (emailA && emailB && emailA < emailB) {
          return -1;
        } else if (emailA && emailB && emailA > emailB) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  });

  return sortedByName;
};

export const getParameterDecimals = (param: string) => {
  let decimals = 1;
  if (param == 'edt' || param == 't20' || param == 't30' || param == 'sti') {
    decimals = 2;
  } else if (param == 'ts') {
    decimals = 3;
  }

  return decimals;
};

// Converts large integers in JSON strings to quoted strings to avoid JavaScript number overflow.
export const enquoteLargeIntegersInJson = (jsonString: string) =>
  jsonString.replace(
    // Match positive integers not inside quotes
    /([:\s,]{0,5})(?<!")(\d+)([\s,]{0,5})/g,
    (match: string, prefix: string, numString: string, suffix: string) => {
      const num = Number(numString);

      // Only quote large integers that exceed JS safe integer limits
      return Number.isSafeInteger(num) ? match : `${prefix}"${numString}"${suffix}`;
    }
  );

// Parses JSON and safely converts quoted large integers back to BigInt.
export const parseJsonWithSafeIntegers = (jsonString: string) => JSON.parse(enquoteLargeIntegersInJson(jsonString));
