import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblResultsMenuIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.96875C15 1.93322 14.1605 1.09375 13.125 1.09375H1.875C0.839466 1.09375 0 1.93322 0 2.96875V12.0312C0 13.0668 0.839466 13.9062 1.875 13.9062H13.125C14.1605 13.9062 15 13.0668 15 12.0312V2.96875ZM6.03375 2.34375C6.13994 2.15109 6.34251 2.03144 6.5625 2.03144C6.78249 2.03144 6.98506 2.15109 7.09125 2.34375C7.15138 2.43712 7.18468 2.54523 7.1875 2.65625C7.18458 2.76726 7.15129 2.87534 7.09125 2.96875C6.98506 3.16141 6.78249 3.28106 6.5625 3.28106C6.34251 3.28106 6.13994 3.16141 6.03375 2.96875C5.97371 2.87534 5.94042 2.76726 5.9375 2.65625C5.94032 2.54523 5.97362 2.43712 6.03375 2.34375ZM4.375 2.03144C4.15501 2.03144 3.95244 2.15109 3.84625 2.34375C3.78612 2.43712 3.75282 2.54523 3.75 2.65625C3.75292 2.76726 3.78621 2.87534 3.84625 2.96875C3.95244 3.16141 4.15501 3.28106 4.375 3.28106C4.59499 3.28106 4.79756 3.16141 4.90375 2.96875C4.96379 2.87534 4.99708 2.76726 5 2.65625C4.99718 2.54523 4.96388 2.43712 4.90375 2.34375C4.79756 2.15109 4.59499 2.03144 4.375 2.03144ZM1.62 2.40062C1.72031 2.17682 1.94224 2.03236 2.1875 2.03125C2.40691 2.03352 2.60845 2.15264 2.71625 2.34375C2.77638 2.43712 2.80968 2.54523 2.8125 2.65625C2.80958 2.76726 2.77629 2.87534 2.71625 2.96875C2.61006 3.16141 2.40749 3.28106 2.1875 3.28106C1.96751 3.28106 1.76494 3.16141 1.65875 2.96875C1.59871 2.87534 1.56542 2.76726 1.5625 2.65625C1.56314 2.56791 1.58275 2.48073 1.62 2.40062ZM13.125 12.6562C13.4702 12.6562 13.75 12.3764 13.75 12.0312V4.37437C13.75 4.28808 13.68 4.21812 13.5937 4.21812H1.40625C1.31996 4.21812 1.25 4.28808 1.25 4.37437V12.0312C1.25 12.3764 1.52982 12.6562 1.875 12.6562H13.125Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11867 7.50261H4.15928C4.02682 7.50261 3.91943 7.60999 3.91943 7.74246V10.7127H5.35852V7.74246C5.35852 7.60999 5.25114 7.50261 5.11867 7.50261Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1649 5.875H7.20551C7.07305 5.875 6.96566 5.98238 6.96566 6.11485V10.7127H8.40475V6.11485C8.40475 5.98238 8.29737 5.875 8.1649 5.875Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2111 8.56227H10.2517C10.1193 8.56227 10.0119 8.66965 10.0119 8.80212V10.7127H11.451V8.80212C11.451 8.66965 11.3436 8.56227 11.2111 8.56227Z"
      fill={fill}
    />
  </svg>
);
