import { FC } from 'react';

import { Text } from '@/components/Shared/Text';

import styles from './styles.module.scss';

type ReflectionInfoProps = {
  label: string;
  value: string | number | null;
  minWidth?: string;
};

export const ReflectionInfo: FC<ReflectionInfoProps> = ({ label, value, minWidth }) => (
  <div className={`${styles['info-item']}`} style={{ minWidth }}>
    <div className={styles['label']}>{label}</div>
    <Text type="regular-12px">{value !== null ? value : '-'}</Text>
  </div>
);
