import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

export const useDeleteProject = () =>
  useMutation(async (projectId: string) => {
    const { data } = await axios.delete(`/api/v2/project/DeleteProject`, {
      params: {
        projectId,
      },
    });
    return data;
  });
