import { IconProps } from '@/types';

export const TrblOrganizationOwnedIcon = ({ width = '10', height = '10', fill = '#999999' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}>
    <path
      d="M1.85742 10.7154L1.85733 8.21021C1.85733 8.07849 1.96411 7.97171 2.09582 7.97171H11.904C12.0357 7.97171 12.1425 8.07849 12.1425 8.21021L12.1426 10.7154M6.99975 6.48242V10.7146"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="1.85713" cy="11.1431" rx="1.28571" ry="1.28571" fill={fill} />
    <circle cx="12.1428" cy="11.1431" r="1.28571" fill={fill} />
    <ellipse cx="6.99995" cy="11.1431" rx="1.28571" ry="1.28571" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00005 4.28599C7.94683 4.28599 8.71434 3.51848 8.71434 2.57171C8.71434 1.62493 7.94683 0.857422 7.00005 0.857422C6.05328 0.857422 5.28577 1.62493 5.28577 2.57171C5.28577 3.51848 6.05328 4.28599 7.00005 4.28599Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4286 6.21651C10.0292 5.41558 8.63682 4.32592 6.99998 4.28613C5.36315 4.32592 3.97078 5.41558 3.57141 6.21651"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
