export const keyBoardEventsForLibraryPanel = (
  event: React.KeyboardEvent,
  listItemElement: React.RefObject<HTMLLIElement>
) => {
  if (event.key === 'ArrowDown') {
    return handleArrowDown(listItemElement);
  } else if (event.key === 'ArrowUp') {
    return handleArrowUp(listItemElement);
  }
};

const handleArrowDown = (listItemElement: React.RefObject<HTMLLIElement>) => {
  const nextSibling = listItemElement.current?.nextElementSibling;
  if (nextSibling) {
    (nextSibling as HTMLElement)?.focus();
    return nextSibling;
  } else {
    const nextParentParentChild = listItemElement.current?.parentElement?.parentElement?.nextElementSibling;
    const firstNextChild = nextParentParentChild?.querySelector('.library-row');
    if (firstNextChild) {
      (firstNextChild as HTMLElement).focus();
      return firstNextChild;
    }
  }
};

const handleArrowUp = (listItemElement: React.RefObject<HTMLLIElement>) => {
  const previousSibling = listItemElement.current?.previousElementSibling;
  if (previousSibling) {
    (previousSibling as HTMLElement)?.focus();
    return previousSibling;
  } else {
    const previousParentParentChild = listItemElement.current?.parentElement?.parentElement?.previousElementSibling;
    const firstNextChild = previousParentParentChild?.querySelector('.library-category');
    if (firstNextChild) {
      (firstNextChild.lastChild as HTMLElement).focus();
      return firstNextChild.lastChild;
    }
  }
};

export const setElementFocus = (listItemRef: React.RefObject<HTMLLIElement>) => {
  if (document.activeElement?.classList.contains('search-library') && listItemRef?.current) {
    listItemRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  } else {
    listItemRef?.current?.focus();
  }
};
