import { TrblOrganizationOwnedIcon, TrblUserCreatedIcon } from '@/components/Icons';

import './styles.scss';

type UserOwnedIconIndicatorProps = {
  showUserIcon: boolean;
  showOrganization: boolean;
  shouldOverlap?: boolean;
  size?: 'small' | 'medium';
  color?: string;
};

export const UserOwnedIconIndicator = ({
  showUserIcon,
  showOrganization,
  shouldOverlap = false,
  size = 'small',
  color = '#999999',
}: UserOwnedIconIndicatorProps) => {
  const widthHeigth = size == 'small' ? '10' : '12';
  return (
    <div className="user-owned-indicator">
      {shouldOverlap && showUserIcon && showOrganization ? (
        <div className="content-overlap">
          <span className="first">
            <TrblOrganizationOwnedIcon fill={color} width={widthHeigth} height={widthHeigth} />
          </span>
          <span className="second">
            <TrblUserCreatedIcon fill={color} width={widthHeigth} height={widthHeigth} />
          </span>
        </div>
      ) : (
        <div className="content-side-by-side">
          {showOrganization && (
            <span className="first">
              <TrblOrganizationOwnedIcon fill={color} width={widthHeigth} height={widthHeigth} />
            </span>
          )}
          {showUserIcon && (
            <span className="second">
              <TrblUserCreatedIcon fill={color} width={widthHeigth} height={widthHeigth} />
            </span>
          )}
        </div>
      )}
    </div>
  );
};
