import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblReflectionIcon: FC<IconProps> = ({ fill = '#DADADA', height = 14, width = 14 }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.18072 3.66081L12.3145 3.34377M12.3145 3.34377L12.6299 6.47326M12.3145 3.34377L6.59437 11.476L2.5 7.5"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3793 12.8301H0.58171C0.260441 12.8301 0 13.0912 0 13.4134C0 13.7356 0.260441 13.9967 0.58171 13.9967H13.3793C13.7006 13.9967 13.961 13.7356 13.961 13.4134C13.961 13.0912 13.7006 12.8301 13.3793 12.8301Z"
      fill={fill}
    />
    <path
      d="M0 0.576328L0 13.2538C0 13.5721 0.26044 13.8301 0.58171 13.8301C0.90298 13.8301 1.16342 13.5721 1.16342 13.2538L1.16342 0.576328C1.16342 0.258075 0.90298 7.82013e-05 0.58171 7.82013e-05C0.26044 7.82013e-05 0 0.258075 0 0.576328Z"
      fill={fill}
    />
  </svg>
);
