import { Vector3 } from 'three';

import { useModelContext } from '@/context/ModelContext';

import { useFeatureFlags } from '@/components/FeatureToggles';

import {
  isPointCloseToSource,
  isPointInsideInternalVolume,
  isPointInsideModel,
  isPointInValidProximity,
} from '../utils';

import { Source, ValidationError } from '@/context/EditorContext/types';

export const useGetPointValidity = () => {
  const { innerMeshes, outerMeshes, meshes, bypassPointValidity } = useModelContext();
  const { sourceProximityGAFeature } = useFeatureFlags();

  const getPointValidity = (
    x: number,
    y: number,
    z: number,
    type: 'SourcePoint' | 'ReceiverPoint',
    sources?: Source[],
    taskType?: string | null
  ): Promise<null | ValidationError> => {
    return new Promise((resolve) => {
      if (bypassPointValidity) resolve(null);

      let sourceProximity = 0.5;
      if (sourceProximityGAFeature) {
        // if taskType is GA, then disable source proximity margin by setting it to 0.01 (1cm)
        sourceProximity = taskType == 'GA' ? 0.01 : 0.5;
      }

      const point3D = new Vector3(x, y, z);
      if (outerMeshes?.length && !isPointInsideModel(point3D, outerMeshes)) {
        resolve(ValidationError.NotInsideModel);
      } else if (type === 'ReceiverPoint' && sources && isPointCloseToSource(point3D, sources)) {
        resolve(ValidationError.CloseToSource);
      } else if (innerMeshes?.length && isPointInsideInternalVolume(point3D, innerMeshes)) {
        resolve(ValidationError.InsideInnerVolume);
      } else if (
        (type == 'SourcePoint' || type == 'ReceiverPoint') &&
        meshes?.length &&
        !isPointInValidProximity(point3D, type == 'SourcePoint' ? sourceProximity : 0.1, meshes)
      ) {
        resolve(ValidationError.CloseToSurface);
      }

      resolve(null);
    });
  };

  return getPointValidity;
};
