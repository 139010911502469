import { Checkbox } from '@/components/Shared/Checkbox';
import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext/types';

import { TimeOfArrivalGroup } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { ReceiverReflections, Reflection } from './types';

/**
 * Calculates the azimuth angle between two points in degrees, normalized to the range [0, 360).
 */
export const calculateAzimuth = (source: number[], receiver: number[]): number => {
  // Calculate the azimuth angle using the arctangent of the y and x differences and convert to degrees.
  let azimuth = Math.atan2(source[1] - receiver[1], source[0] - receiver[0]) * (180 / Math.PI);

  // Ensure azimuth is in the range [0, 360] degrees. If negative, adjust by adding 360.
  if (azimuth < 0) azimuth += 360;

  // Round the azimuth to 3 decimal places to avoid floating point errors.
  return roundFloat(azimuth, 3);
};

/**
 * Calculates the elevation angle between two points in degrees.
 */
export const calculateElevation = (source: number[], receiver: number[]): number => {
  // Calculate the horizontal distance between the source and receiver in the XY plane.
  const horizontalDistance = Math.sqrt((source[0] - receiver[0]) ** 2 + (source[1] - receiver[1]) ** 2);

  // Calculate the elevation angle using the arctangent of the z difference and horizontal distance, then convert to degrees.
  const elevation = Math.atan2(source[2] - receiver[2], horizontalDistance) * (180 / Math.PI);

  // Round the elevation to 3 decimal places to avoid floating point errors.
  return roundFloat(elevation, 3);
};

/**
 * Maps a single reflection to detailed reflection results.
 */
export const mapSingleReflection = (reflection: Reflection, firstReflection: Reflection): ReflectionDetails => {
  // Return the detailed reflection data including relative values.

  return {
    order: reflection.Order,
    timeOfArrival: reflection.TimeOfArrival,
    timeOfArrivalRelative: reflection.TimeOfArrival - firstReflection.TimeOfArrival,
    path: reflection.Path,
    azimuth: reflection.Azimuth,
    elevation: reflection.Elevation,
    spl: reflection.SPL,
    splRelative: reflection.SPL - firstReflection.SPL,
    splPerBand: reflection.SPLPerBand,
    splPerBandRelative: reflection.SPLPerBand.map((spl, index) => spl - firstReflection.SPLPerBand[index]),
    splAWeighted: reflection.SPLA,
    splAWeightedRelative: reflection.SPLA ? reflection.SPLA - firstReflection.SPLA : undefined,
    splAWeightedPerBand: reflection.SPLPerBandA,
    splAWeightedPerBandRelative: reflection.SPLPerBandA?.map((spl, index) => spl - firstReflection.SPLPerBandA[index]),
    splCWeighted: reflection.SPLC,
    splCWeightedRelative: reflection.SPLC ? reflection.SPLC - firstReflection.SPLC : undefined,
    splCWeightedPerBand: reflection.SPLPerBandC,
    splCWeightedPerBandRelative: reflection.SPLPerBandC?.map((spl, index) => spl - firstReflection.SPLPerBandC[index]),
    distance: reflection.Distance,
  };
};

/**
 * Maps reflection data to detailed reflection results, calculating relative azimuth and elevation.
 */
export const mapReflectionData = (data: ReceiverReflections): ReflectionDetails[] => {
  if (!data.Reflections.length) return [];

  // Sort reflections by time of arrival to process them in order.
  const reflectionsOrdered = [...data.Reflections].sort((a, b) => a.TimeOfArrival - b.TimeOfArrival);

  // Take the first reflection as the reference point for calculating relative times and SPL.
  const firstReflection = reflectionsOrdered[0];

  // Map each reflection using the extracted function
  return reflectionsOrdered.map((reflection) => mapSingleReflection(reflection, firstReflection));
};

export const renderTimeGroupMenuItem = (
  option: { value: string; label: string },
  index: number,
  timeOfArrivalGroups: TimeOfArrivalGroup[],
  selectedTimeOfArrivalGroupIndexes: number[]
) => (
  <div style={{ display: 'flex', gap: '6px', width: '100%', alignItems: 'center' }}>
    <hr
      style={{
        display: 'block',
        height: '1px',
        width: '10px',
        border: '0',
        borderTop: `2px solid ${timeOfArrivalGroups[index].color}`,
        margin: '1em 0',
        padding: '0',
      }}
    />
    <Checkbox
      id={option.value}
      label={option.label}
      spaceBetween={true}
      labelAlignment={'left'}
      isChecked={selectedTimeOfArrivalGroupIndexes.includes(Number(option.value))}
    />
  </div>
);
