import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

import { ToastCheckmarkIcon, ToastInfoIcon, ToastWarningIcon } from './icons';

import './styles.scss';

export const TrblToast = () => {
  const toastIcon = (type: string) => {
    if (type === 'success') return <ToastCheckmarkIcon />;
    if (type === 'warning') return <ToastWarningIcon />;
    if (type === 'info') return <ToastCheckmarkIcon />;
    if (type === 'error') return <ToastWarningIcon />;
    else return <ToastInfoIcon />;
  };
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      closeButton={false}
      pauseOnHover
      theme="colored"
      className="treble-toast"
      icon={({ type }) => toastIcon(type)}
    />
  );
};
