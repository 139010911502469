import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblModelsIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1178 10.5268L13.6124 11.2951L13.613 11.2927C13.9491 11.4782 14.0817 11.909 13.9119 12.2635C13.742 12.6179 13.3318 12.7665 12.9875 12.5982L11.3075 11.7337C11.2898 11.7249 11.2692 11.7249 11.2515 11.7337L7.45187 13.5228H7.44795C7.34121 13.5734 7.2187 13.5734 7.11195 13.5228H7.10691L3.30507 11.7337C3.28841 11.7255 3.2691 11.7255 3.25243 11.7337L1.57243 12.6087C1.22635 12.7885 0.805892 12.642 0.633314 12.2815C0.460736 11.921 0.60139 11.483 0.947474 11.3033L2.44267 10.5274C2.48995 10.5024 2.5198 10.452 2.51995 10.3967V5.54167C2.51999 5.31254 2.64881 5.10465 2.84923 5.01025L6.49931 3.29233C6.54947 3.26871 6.58168 3.21667 6.58163 3.15933V1.16667C6.58163 0.763959 6.89503 0.4375 7.28163 0.4375C7.66823 0.4375 7.98163 0.763959 7.98163 1.16667V3.15758C7.98187 3.21483 8.01399 3.26673 8.06395 3.29058L11.7096 5.00967C11.9106 5.10381 12.0399 5.31205 12.04 5.54167V10.3962C12.04 10.4516 12.0701 10.5022 12.1178 10.5268ZM4.63965 5.54178C4.63965 5.59918 4.67197 5.65124 4.72222 5.67478L7.2243 6.85311C7.26098 6.87031 7.30298 6.87031 7.33966 6.85311L9.85966 5.67478C9.90991 5.65124 9.94224 5.59918 9.94224 5.54178C9.94224 5.48438 9.90991 5.43232 9.85966 5.40878L7.33966 4.23045C7.30292 4.21355 7.26104 4.21355 7.2243 4.23045L4.72222 5.40878C4.67197 5.43232 4.63965 5.48438 4.63965 5.54178Z"
      fill={fill}
    />
  </svg>
);
