export enum PresetTypes {
  Survey = 0,
  Default = 1,
  Advanced = 2,
}

export type MeshTaskDto = {
  id: string;
  modelId: string;
  crossoverFrequency: number;
  task: TaskDto;
};

export enum TaskStatusDto {
  Created = 'Created',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Queued = 'Queued',
  ProcessingResults = 'ProcessingResults',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Error = 'Error',
  DispatchError = 'DispatchError',
}

enum TaskType {
  'GA',
  'DG',
  'Hybrid',
  'Mesh',
  'GeometryCheck',
}

export type TaskDto = {
  id: string;
  taskType: TaskType;
  status: TaskStatusDto;
  createdAt: string;
  startedAt: string;
  completedAt: string;
  completedAtEst: string;
  progressPercentage: number;
  userErrorMessage: string;
  errorDetails: string;
  userStatusMsg: string;
  taskProvider: string;
  taskGroupId: string;
  sourcePointId: string;
  workerVersion: string;
};

enum MeshQuality {
  'Good',
  'Poor',
  'Bad',
  'Invalid',
}

export type MeshResultDto = {
  id: string;
  taskId: string;
  faceCount: number;
  nodeCount: number;
  elementCount: number;
  runtimeMs: number;
  elementMinLength: number;
  elementMaxLength: number;
  elementMeanLength: number;
  elementStdLength: number;
  volume: number;
  solveEstimatedTimeSecondsPerSecond: number;
  meshQualityPercentage: number;
  meshQuality: MeshQuality;
  meshDownloadUrl: string;
  smallestElements: MeshElementDtoMeshElementDto | null;
};

type Points = {
  x: number;
  y: number;
  z: number;
};

type MeshElementDtoMeshElementDto = { points: Points[] };

export enum PreferredTaskProvider {
  'Unspecified',
  'AWSBatchJP',
  'AWSBatch',
  'Coreweave',
  'Local',
}
