import styles from '../../styles.module.scss';

export const EmptySidebar = ({ isSidebarActive }: { isSidebarActive: boolean }) => {
  return (
    <div
      className={`${styles['recent-uploads-sidepanel']} ${isSidebarActive ? styles['active'] : ''} ${
        styles['empty']
      } `}></div>
  );
};
