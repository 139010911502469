import { useQuery } from '@tanstack/react-query';

import { SolveTask } from '@/types';

import axios from '@/axios';

const getLastSolveResultsBySimulationId = async (simulationId: string): Promise<SolveTask> => {
  const { data } = await axios.get(
    `/api/SolveTask/GetLastSolveResultsBySimulationId?simulationId=${simulationId}&resultTypesCsv=edc%2Cir%2Ctf-db%2Cmono-ir%2Creflection`
  );

  return data;
};

export const useGetLastSolveResultsBySimulationId = (simulationId: string, enabled = true) => {
  return useQuery<SolveTask, boolean>(
    ['lastSolveResultsBySimulationId', simulationId],
    () => getLastSolveResultsBySimulationId(simulationId),
    {
      refetchOnWindowFocus: false,
      enabled: !!simulationId.length && enabled,
      staleTime: 60000, // stale time is set to 1 minute, after that time it refetches
    }
  );
};
