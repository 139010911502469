import { Canvas } from '@react-three/fiber';
import { Vector3 } from 'three';

import { BuilderBoxes } from './BuilderBoxes';
import { CameraController } from './CameraController';

export const BuilderCanvas = ({ height }: { height: number }) => {
  return (
    <Canvas
      style={{ height: `${height}px` }}
      frameloop="demand"
      camera={{
        position: new Vector3(Math.PI * 2, -17, Math.PI * 1),
        fov: 14,
      }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <BuilderBoxes />
      <CameraController />
    </Canvas>
  );
};
