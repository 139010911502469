import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // load on demand

import { TrblCancelledIcon, TrblCompletedIcon, TrblErrorIcon, TrblInprogressIcon, TrblWarningIcon } from '../Icons';

import { getProgressMessages } from './utils';
import { getTimeStringByDuration } from '@/utils/trebleFunctions';

import { RunStatus, SimulationRunStatusDto, TaskStatus } from '@/types';

import classes from './styles.module.scss';

interface RecentSimlationStatusDisplayProps {
  timeEstimate: number | null;
  taskStatus?: TaskStatus | SimulationRunStatusDto | null;
  simulationStatus?: RunStatus;
  percentage?: number;
  parent?: string;
  startedAt?: string;
  completedAt?: string;
}

export const RecentSimulationStatusDisplay = ({
  taskStatus,
  simulationStatus,
  percentage,
  parent,
  timeEstimate,
  startedAt,
  completedAt,
}: RecentSimlationStatusDisplayProps) => {
  const progressMessage = getProgressMessages(simulationStatus);

  const getTimeLeft = (percentage: number, timeEst: number | null) => {
    if (timeEst == null) return null;
    dayjs.extend(duration);

    // we use the percentage to adjust remaining time, since timeEst can sometimes a bit wrong
    const difference = timeEst * (1 - percentage / 100) * 1000;
    const differenceDuration = dayjs.duration(difference);

    if (difference > 0 && simulationStatus !== RunStatus.ProcessingResults) {
      return getTimeStringByDuration(differenceDuration);
    } else {
      return '< 1 min';
    }
  };
  const getSimulationRuntime = (started: string, completed: string) => {
    dayjs.extend(duration);
    const difference = dayjs(completed).diff(dayjs(started), 'ms');
    const differenceDuration = dayjs.duration(difference);

    return getTimeStringByDuration(differenceDuration);
  };

  return (
    <>
      <div className={classes.status}>
        {(!simulationStatus || simulationStatus === RunStatus.Created) && (
          <>
            {parent !== 'Nav' && simulationStatus && <TrblInprogressIcon />}
            <p className={classes.status_text}>
              {!simulationStatus ? `Created` : `Started`}
              {` `}
              {dayjs(taskStatus?.createdAt).format('MMM DD HH:mm')}
            </p>
          </>
        )}
        {simulationStatus === RunStatus.Completed && (
          <>
            <TrblCompletedIcon />
            <p className={classes.status_text}>
              Completed
              <span title="Finished at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(taskStatus?.completedAt).format('MMM DD HH:mm')}
              </span>
              {startedAt && completedAt && (
                <span title="Runtime">
                  {` ${String.fromCharCode(183)} `}
                  {getSimulationRuntime(startedAt, completedAt)}
                </span>
              )}
            </p>
          </>
        )}
        {/* "Pending" status is not part of RunStatus, not sure why we are checking for it?  */}
        {simulationStatus && [RunStatus.InProgress, RunStatus.Queued, 'Pending'].includes(simulationStatus) && (
          <>
            {parent !== 'Nav' && <TrblInprogressIcon />}
            {parent === 'ModelDetails' ? (
              <span className={classes.status_text}>{progressMessage}</span>
            ) : (
              <>
                <span className={classes.status_percentage}>{`${percentage}% ${progressMessage}`}</span>
                <p className={classes.status_text} title="Time remaining">
                  {timeEstimate && percentage ? (
                    <>
                      {` ${String.fromCharCode(183)} `}
                      {getTimeLeft(percentage, timeEstimate)} remaining
                    </>
                  ) : (
                    <>
                      {` ${String.fromCharCode(183)} `}
                      Initializing solver
                    </>
                  )}
                </p>
              </>
            )}
          </>
        )}
        {simulationStatus === RunStatus.ProcessingResults && (
          <>
            {parent !== 'Nav' && <TrblInprogressIcon />}
            {parent === 'ModelDetails' ? (
              <span className={classes.status_text}>{progressMessage}</span>
            ) : (
              <>
                <span className={classes.status_percentage}>{`${progressMessage}`}</span>
                <p className={classes.status_text} title="Time remaining">
                  {timeEstimate && percentage && (
                    <>
                      {` ${String.fromCharCode(183)} `}
                      {getTimeLeft(percentage, timeEstimate)} remaining
                    </>
                  )}
                </p>
              </>
            )}
          </>
        )}
        {simulationStatus === RunStatus.Cancelled && (
          <>
            <TrblCancelledIcon />
            <p className={classes.status_text}>
              Cancelled
              <span title="Started at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(taskStatus?.createdAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
        {simulationStatus && [RunStatus.Error, RunStatus.TaskError].includes(simulationStatus) && (
          <>
            <TrblErrorIcon />
            <p className={classes.status_text}>
              Error
              <span title="Started at">
                {` ${String.fromCharCode(183)} `}
                {dayjs(taskStatus?.createdAt).format('MMM DD HH:mm')}
              </span>
            </p>
          </>
        )}
        {simulationStatus === RunStatus.InsufficientTokens && (
          <>
            <TrblWarningIcon />
            <p className={classes.status_text}>
              Insufficient tokens
              {` ${String.fromCharCode(183)} `}
              {dayjs(taskStatus?.createdAt).format('MMM DD HH:mm')}
            </p>
          </>
        )}
      </div>
    </>
  );
};
