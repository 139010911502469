import { useEffect, useState } from 'react';

import { AuralizationPresetDto } from '@/components/AuralizerPresets/types';
import { AudioEngine } from '../AudioEngine';
import { useAuralizerContext } from '../AuralizerContext';
import { ActionType, usePresetContext } from '../PresetContext';
import { Gain } from './Gain';

import { DEFAULT_VALUE, MAX_VALUE, MIN_VALUE } from '../constants';

import '../style.scss';

export const MasterGain = ({ selectedPreset }: { selectedPreset: AuralizationPresetDto | null }) => {
  const audioEngine = AudioEngine.getInstance();

  const [masterValue, setMasterValue] = useState<number | undefined>(DEFAULT_VALUE);

  const { audioNodesDict } = useAuralizerContext();

  const { dispatch } = usePresetContext();

  useEffect(() => {
    if (audioNodesDict && Object.keys(audioNodesDict).length >= 1) {
      if (selectedPreset?.mixerSettings?.masterVolume !== undefined) {
        setMasterValue(selectedPreset?.mixerSettings?.masterVolume);
        audioEngine.setMasterGain(selectedPreset?.mixerSettings?.masterVolume, audioNodesDict);
      } else if (selectedPreset === null) {
        setMasterValue(DEFAULT_VALUE);
        audioEngine.setMasterGain(DEFAULT_VALUE, audioNodesDict);
      }
    }
  }, [selectedPreset, audioNodesDict]);

  const handleGainChange = (value: number) => {
    if (audioNodesDict) {
      audioEngine.setMasterGain(value, audioNodesDict);
      dispatch({
        type: ActionType.SET_MASTER_VOLUME,
        volume: value,
      });
    }
  };

  const handleInputChange = (newValue: number | undefined) => {
    setMasterValue(newValue === undefined ? undefined : Number(newValue));
    if (newValue !== undefined && newValue >= MIN_VALUE && newValue <= MAX_VALUE) {
      handleGainChange(Number(newValue));
    }
  };

  const handleSliderChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    setMasterValue(parseInt(newValue));
    handleGainChange(Number(newValue));
  };

  return (
    <>
      <p className="master-gain-label"> Overall gain</p>
      <Gain
        inputValue={masterValue}
        onHandleInputChange={handleInputChange}
        onHandleSliderChange={handleSliderChange}
      />
    </>
  );
};
