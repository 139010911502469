import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

export const DeleteItemPopup = ({
  onCancelDelete,
  onConfirmDelete,
  type,
  name,
  showWarning = true,
}: {
  onCancelDelete: () => void;
  onConfirmDelete: () => void;
  type: string;
  name: string;
  showWarning?: boolean;
}) => {
  return (
    <ConfirmationDialog
      title={`Delete ${type}?`}
      message={() => (
        <span style={{ width: '100%', overflowWrap: 'break-word' }}>
          <span>
            Are you sure you want to delete <b>{name}</b>?
          </span>
          <br></br>
          <br></br>
          {showWarning && <span>You can not run a new simulation with a deleted {type}.</span>}
        </span>
      )}
      onConfirm={onConfirmDelete}
      onCancel={onCancelDelete}
    />
  );
};
