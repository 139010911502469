import { cloneDeep } from 'lodash';

import { useAppContext } from '@/context/AppContext';

import { deepCompare } from '@/utils/trebleFunctions';

import { Material, Simulation } from '@/types';

// Returns true if the user has made any changes after running a simulation
export const useHasUserTouched = () => {
  const {
    appState: { filteredMaterials },
  } = useAppContext();

  const hasUserTouched = (sim: Simulation) => {
    const runStatus = sim.extra.status;
    const checkSim = cloneDeep(sim);
    const lastSimulationRun = cloneDeep(sim.lastSimulationRun);

    if ((runStatus === 2 || runStatus === 5) && lastSimulationRun) {
      checkSim.sources.forEach((source) => {
        // removing the custom prop only used in the frontend
        // TODO: create a separate Simulation type and UI-specific Simulation type
        // @ts-expect-error Property 'params' does not exist on type 'Source'
        delete source.params;
      });
      checkSim.receivers.forEach((receiver) => {
        // removing the custom prop only used in the frontend
        // TODO: create a separate Simulation type and UI-specific Simulation type
        // @ts-expect-error Property 'validationError' does not exist on type 'Receiver'.
        delete receiver.validationError;
        // @ts-expect-error Property 'isValid' does not exist on type 'Receiver'.
        delete receiver.isValid;
      });

      // lastSimulationRun has gridReceivers as null by default, but the simulation object
      // has it as an empty array as default. We need to catch that initial state for both.
      const gridReceiversAreValid =
        (lastSimulationRun.gridReceivers === null && checkSim.gridReceivers.length === 0) ||
        deepCompare(lastSimulationRun.gridReceivers, checkSim.gridReceivers);
      if (
        deepCompare(lastSimulationRun.sources, checkSim.sources) &&
        deepCompare(lastSimulationRun.receivers, checkSim.receivers) &&
        gridReceiversAreValid &&
        deepCompare(lastSimulationRun.sourceParameters, checkSim.sourceParameters) &&
        lastSimulationRun.taskType == checkSim.taskType &&
        lastSimulationRun.settingsPreset == checkSim.settingsPreset
      ) {
        // comparing materialIdByObject separately in case we have a
        // deleted material because then we don't want to show the edit/revert mode
        if (
          deepCompare(
            lastSimulationRun.modelSettings?.materialIdByObjectId,
            checkSim.modelSettings?.materialIdByObjectId
          )
        ) {
          // if scattering or solverSettings has changed
          if (
            !deepCompare(
              lastSimulationRun.modelSettings?.scatteringByObjectId,
              checkSim.modelSettings?.scatteringByObjectId
            ) ||
            !deepCompare(lastSimulationRun.solverSettings, checkSim.solverSettings)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          // do not show edit mode if we have deleted material AND all the
          // other materials are the same as in the last simulation run
          let doNotShowEditMode = true;
          Object.keys(checkSim.modelSettings?.materialIdByObjectId || {}).forEach((materialIdByObjectId) => {
            const currentMaterialId = checkSim.modelSettings?.materialIdByObjectId[materialIdByObjectId];
            const oldMaterialId = lastSimulationRun?.modelSettings?.materialIdByObjectId[materialIdByObjectId];
            if (currentMaterialId !== oldMaterialId) {
              const oldMaterial = filteredMaterials.find((material: Material) => material.id === oldMaterialId);
              // if material is not part of filtered materials (is deleted) and scatter values are the same we do not want to show edit mode
              if (
                oldMaterial === undefined &&
                deepCompare(
                  lastSimulationRun.modelSettings?.scatteringByObjectId,
                  checkSim.modelSettings?.scatteringByObjectId
                )
              ) {
                if (doNotShowEditMode) {
                  doNotShowEditMode = true;
                }
              } else {
                doNotShowEditMode = false;
              }
            } else {
              return false;
            }
          });
          if (doNotShowEditMode) {
            // the impulseLengthSeconds changes when we delete a material so we want to
            // remove that prop from the comparison if we have a deleted material
            const newSimToCompare = cloneDeep(sim);
            // @ts-expect-error The operand of a 'delete' operator must be optional.
            delete newSimToCompare.solverSettings.dgSettings.impulseLengthSeconds;
            // @ts-expect-error The operand of a 'delete' operator must be optional.
            delete newSimToCompare.solverSettings.gaSettings.impulseLengthSeconds;
            // @ts-expect-error 'newSimToCompare.lastSimulationRun' is possibly 'null' or 'undefined'.
            delete newSimToCompare.lastSimulationRun.solverSettings.dgSettings.impulseLengthSeconds;
            // @ts-expect-error 'newSimToCompare.lastSimulationRun' is possibly 'null' or 'undefined'.
            delete newSimToCompare.lastSimulationRun.solverSettings.gaSettings.impulseLengthSeconds;
            if (deepCompare(newSimToCompare.solverSettings, newSimToCompare.lastSimulationRun?.solverSettings)) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    } else {
      // this simulation has no lastSimulationRun so the check
      // for if the use has made changes does not apply
      return false;
    }
  };

  return hasUserTouched;
};
