import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { ResultPresetDto, UpdateResultPresetDto } from '../types';

import axios from '@/axios';

const updatePreset = async (preset: UpdateResultPresetDto): Promise<ResultPresetDto> => {
  const { data } = await axios.put(`/api/ResultPreset/UpdateResultPreset`, preset);
  return data;
};

export const useUpdateResultPreset = () => {
  return useMutation(async (preset: UpdateResultPresetDto) => await updatePreset(preset), {
    onError: () => {
      toast.error('An error occurred while saving changes. Please refresh the browser.');
    },
    onSuccess: (data) => {
      toast.info(`Changes to '${data.name}' saved`, { className: 'editor-toast' });
    },
  });
};
