import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserDto } from './types';

import axios from '@/axios';

type UpdateUserRequest = {
  userId: string;
  firstName?: string;
  lastName?: string;
  trialExpiresAt?: string;
};

type UpdateUserResponse = UserDto;

const updateUser = async (user: UpdateUserRequest) => {
  const { data } = await axios.patch<UpdateUserResponse>(`/api/Admin/UpdateUser`, user, {
    params: {
      userId: user.userId,
    },
  });

  return data;
};

export const useUpdateUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpdateUserRequest) => await updateUser(data), {
    onError: () => toast.error('An error occurred while updating the user!'),
    onSuccess: () => {
      queryClient.invalidateQueries(['product-accesses', organizationId]);
    },
  });
};
