import { ReactElement } from 'react';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblCloseIcon } from '@/components';
import { ActionType, useLibraryPanelContext } from '../LibraryPanelContext';

import './styles.scss';

export const LibraryPanelHeader = ({ title, icon }: { title: string; icon: ReactElement }) => {
  const { dispatch } = useLibraryPanelContext();

  const closePanel = () => {
    dispatch({
      type: ActionType.CLOSE_LIBRARY_PANEL,
    });
  };

  return (
    <div className="panel-header">
      <div>
        <span>{icon}</span>
        <h3 className="library-panel-header"> {title} </h3>
      </div>

      <TrblIconButton icon={<TrblCloseIcon />} label="Close panel" onClick={closePanel}></TrblIconButton>
    </div>
  );
};
