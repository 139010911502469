import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblFileUploadIcon: FC<IconProps> = ({ fill = '#313131', width = '29', height = '28' }) => (
  <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 4.66667C3.35567 4.66667 2.83334 4.14433 2.83334 3.5V2.33333C2.83334 1.04467 3.878 0 5.16667 0H6.91667C7.561 0 8.08334 0.522334 8.08334 1.16667C8.08334 1.811 7.561 2.33333 6.91667 2.33333H5.16667V3.5C5.16667 4.14433 4.64433 4.66667 4 4.66667Z"
      fill={fill}
    />
    <path
      d="M6.91667 25.6667C7.561 25.6667 8.08334 26.189 8.08334 26.8334C8.08334 27.4777 7.561 28 6.91667 28H5.16667C3.878 28 2.83334 26.9554 2.83334 25.6667V24.5C2.83334 23.8557 3.35567 23.3334 4 23.3334C4.64433 23.3334 5.16667 23.8557 5.16667 24.5V25.6667H6.91667Z"
      fill={fill}
    />
    <path
      d="M23.8333 28H22.0833C21.439 28 20.9167 27.4777 20.9167 26.8334C20.9167 26.189 21.439 25.6667 22.0833 25.6667H23.8333V24.5C23.8333 23.8557 24.3557 23.3334 25 23.3334C25.6443 23.3334 26.1667 23.8557 26.1667 24.5V25.6667C26.1667 26.9554 25.122 28 23.8333 28Z"
      fill={fill}
    />
    <path
      d="M20.3333 3.5C20.0239 3.49993 19.7272 3.37697 19.5085 3.15817L18.6837 2.33333H17.4167C16.7723 2.33333 16.25 1.811 16.25 1.16667C16.25 0.522334 16.7723 0 17.4167 0H18.6837C19.3021 0.00203754 19.8948 0.247668 20.3333 0.683667L21.1582 1.5085C21.4917 1.84216 21.5915 2.34388 21.411 2.77976C21.2304 3.21565 20.8051 3.4999 20.3333 3.5Z"
      fill={fill}
    />
    <path
      d="M4 10.5C3.35567 10.5 2.83334 9.97762 2.83334 9.33329V7.58329C2.83334 6.93896 3.35567 6.41663 4 6.41663C4.64433 6.41663 5.16667 6.93896 5.16667 7.58329V9.33329C5.16667 9.97762 4.64433 10.5 4 10.5Z"
      fill={fill}
    />
    <path
      d="M13.0417 2.33333H11.2917C10.6473 2.33333 10.125 1.811 10.125 1.16667C10.125 0.522334 10.6473 0 11.2917 0H13.0417C13.686 0 14.2083 0.522334 14.2083 1.16667C14.2083 1.811 13.686 2.33333 13.0417 2.33333Z"
      fill={fill}
    />
    <path
      d="M12.75 28H11C10.3557 28 9.83334 27.4776 9.83334 26.8333C9.83334 26.189 10.3557 25.6666 11 25.6666H12.75C13.3943 25.6666 13.9167 26.189 13.9167 26.8333C13.9167 27.4776 13.3943 28 12.75 28Z"
      fill={fill}
    />
    <path
      d="M18 28H16.25C15.6057 28 15.0833 27.4776 15.0833 26.8333C15.0833 26.189 15.6057 25.6666 16.25 25.6666H18C18.6443 25.6666 19.1667 26.189 19.1667 26.8333C19.1667 27.4776 18.6443 28 18 28Z"
      fill={fill}
    />
    <path
      d="M4 15.75C3.35567 15.75 2.83334 15.2276 2.83334 14.5833V12.8333C2.83334 12.189 3.35567 11.6666 4 11.6666C4.64433 11.6666 5.16667 12.189 5.16667 12.8333V14.5833C5.16667 15.2276 4.64433 15.75 4 15.75Z"
      fill={fill}
    />
    <path
      d="M4 21.5833C3.35567 21.5833 2.83334 21.061 2.83334 20.4167V18.6667C2.83334 18.0223 3.35567 17.5 4 17.5C4.64433 17.5 5.16667 18.0223 5.16667 18.6667V20.4167C5.16667 21.061 4.64433 21.5833 4 21.5833Z"
      fill={fill}
    />
    <path
      d="M25 10.4999C24.3557 10.4999 23.8333 9.97761 23.8333 9.33328V8.06628L23.0085 7.24145C22.5664 6.78367 22.5727 6.05601 23.0227 5.60599C23.4727 5.15597 24.2004 5.14965 24.6582 5.59178L25.483 6.41661C25.9221 6.85307 26.1683 7.44713 26.1667 8.06628V9.33328C26.1667 9.97761 25.6443 10.4999 25 10.4999Z"
      fill={fill}
    />
    <path
      d="M25 15.75C24.3557 15.75 23.8333 15.2276 23.8333 14.5833V12.8333C23.8333 12.189 24.3557 11.6666 25 11.6666C25.6443 11.6666 26.1667 12.189 26.1667 12.8333V14.5833C26.1667 15.2276 25.6443 15.75 25 15.75Z"
      fill={fill}
    />
    <path
      d="M25 21.5833C24.3557 21.5833 23.8333 21.061 23.8333 20.4167V18.6667C23.8333 18.0223 24.3557 17.5 25 17.5C25.6443 17.5 26.1667 18.0223 26.1667 18.6667V20.4167C26.1667 21.061 25.6443 21.5833 25 21.5833Z"
      fill={fill}
    />
  </svg>
);
