import { useSimulationContext } from '@/context/SimulationContext';

import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useSelectLayer } from './useSelectLayer';

export const useTabUpList = () => {
  const selectLayer = useSelectLayer();
  const { selectedLayer } = useLibraryPanelContext();
  const {
    simulationState: { surfaceLayers },
  } = useSimulationContext();

  const tabUpList = (event: KeyboardEvent, layersContainer: React.RefObject<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedLayer) {
      const currentElement = layersContainer?.current?.querySelector('.treble-layer-row.selected');
      const prevSibling = currentElement?.parentElement?.previousElementSibling;

      if (prevSibling?.classList.contains('child-layer')) {
        if (selectedLayer.parentId === null) {
          // if the prev layer is a child layer and the current layer is a
          // parent layer we need to select the last child layer in the list
          const surfaceChildren = surfaceLayers[selectedLayer.layerGroupIndex - 1].children;
          selectLayer(surfaceChildren[surfaceChildren.length - 1]);
        } else {
          // @ts-expect-error Property 'dataset' does not exist on type 'Element'.
          selectLayer(surfaceLayers[selectedLayer.layerGroupIndex].children[prevSibling.dataset.index]);
        }
      } else if (selectedLayer.layerIndex === 0) {
        // if the prev layer is a parent layer and the current layer is a
        // child layer then
        selectLayer(surfaceLayers[selectedLayer.layerGroupIndex]);
      } else {
        selectLayer(surfaceLayers[selectedLayer.layerGroupIndex - 1]);
      }
    }
  };

  return tabUpList;
};
