import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';

import { useGetMaterials, useGetSourceDefinitions, useGetSpaces } from '@/hooks';

import { filterTrebleMaterials, getSourceDefinitionGroups, sortSourceDefinitions } from './utils';

import { Material, ModelBaseDto, SelectedModel, Simulation, SourceDefinition, SpaceDto } from '@/types';

export type SimulationsInSpace = {
  name: string;
  id: string;
  createdAt: string;
  simulations: Simulation[];
};

export type SourceDefinitionCategories =
  | 'Amplified'
  | 'Natural'
  | 'Other'
  | 'Default'
  | 'Organization'
  | 'Created by me';

enum ActionType {
  SET_SELECTED_MODEL = 'SET_SELECTED_MODEL',
  SET_MODELS_IN_SPACE = 'SET_MODELS_IN_SPACE',
  SET_SIMULATIONS_IN_SPACE = 'SET_SIMULATIONS_IN_SPACE',
  SET_SPACES = 'SET_SPACES',
  SET_MATERIALS = 'SET_MATERIALS',
  SET_SOURCE_DEFINITIONS = 'SET_SOURCE_DEFINITIONS',
}

type State = {
  selectedModel: SelectedModel | null;
  modelBases: ModelBaseDto[];
  simulationsInSpace: SimulationsInSpace[];
  isFetching: boolean;
  availableSpaces: SpaceDto[];
  filteredMaterials: Material[];
  materialCategories: string[];
  filteredSourceDefinitions: SourceDefinition[];
  sourceDefinitionManufacturers: string[];
  sourceDefinitionCategories: SourceDefinitionCategories[];
  sourceDefinitionSubCategories: Record<SourceDefinitionCategories, string[]>;
};

const initialState: State = {
  selectedModel: null,
  modelBases: [],
  simulationsInSpace: [],
  isFetching: false,
  availableSpaces: [],
  filteredMaterials: [],
  materialCategories: [],
  filteredSourceDefinitions: [],
  sourceDefinitionManufacturers: [],
  sourceDefinitionCategories: ['Amplified', 'Natural', 'Other'],
  sourceDefinitionSubCategories: {
    Amplified: [
      'Full range',
      'Line array',
      'Portable',
      'Installation',
      'Ceiling',
      'EN 54',
      'Studio and Broadcast monitors',
      'Subwoofers',
      'Cinema Speakers',
      'Other',
    ],
    Natural: ['Speech', 'Instrument', 'Other'],
    Other: ['Test sources', 'Noise sources', 'Other'],
    Default: [],
    Organization: [],
    'Created by me': [],
  },
};

type AppProviderProps = { children: ReactNode };

type AppContextAction = {
  type: ActionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

type Dispatch = (action: AppContextAction) => void;

const AppContext = createContext<{ appState: State; dispatch: Dispatch; isFetching: boolean } | undefined>(undefined);

const appReducer = (state: State, action: AppContextAction): State => {
  switch (action.type) {
    case ActionType.SET_MATERIALS: {
      const { filteredMaterials, materialCategories } = filterTrebleMaterials(action.payload);
      return {
        ...state,
        filteredMaterials,
        materialCategories,
      };
    }

    case ActionType.SET_SOURCE_DEFINITIONS: {
      const sortedSourceDefinition = sortSourceDefinitions(action.payload);
      const { sourceDefinitionManufacturers } = getSourceDefinitionGroups(action.payload);

      return {
        ...state,
        filteredSourceDefinitions: sortedSourceDefinition,
        sourceDefinitionManufacturers,
      };
    }

    case ActionType.SET_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload,
      };
    // only used in the space details components
    case ActionType.SET_MODELS_IN_SPACE: {
      return {
        ...state,
        modelBases: action.payload,
        // when a new model is selected we remove all previously fetched simulations in space
        simulationsInSpace: [],
      };
    }
    // Currently only used for the create new simulation popup
    case ActionType.SET_SIMULATIONS_IN_SPACE:
      return {
        ...state,
        simulationsInSpace: action.payload,
      };
    // only used for initial loading the app
    case ActionType.SET_SPACES: {
      const availableSpaces = action.payload;

      // sort the Spaces by Name and Tags and give empty Tags the value "None"
      availableSpaces.sort((a: SpaceDto, b: SpaceDto) => {
        return a.name.localeCompare(b.name);
      });
      availableSpaces.sort((a: SpaceDto, b: SpaceDto) => {
        if (!a.tag) a.tag = 'None';
        if (!b.tag) b.tag = 'None';
        return a.tag.localeCompare(b.tag);
      });

      return {
        ...state,
        availableSpaces,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AppProvider = ({ children }: AppProviderProps) => {
  const [appState, dispatch] = useReducer(appReducer, initialState);
  const { data: availableSpaces = [], isFetching, isFetched } = useGetSpaces();
  const { data: materials = [] } = useGetMaterials();

  const { data: sourceDefinitions = [] } = useGetSourceDefinitions(true);

  const value = { appState, isFetching, dispatch };

  useEffect(() => {
    if (availableSpaces.length > 0 && isFetched) {
      dispatch({ type: ActionType.SET_SPACES, payload: availableSpaces });
    }
  }, [availableSpaces]);

  useEffect(() => {
    if (materials.length > 0) {
      dispatch({
        type: ActionType.SET_MATERIALS,
        payload: materials,
      });
    }
  }, [materials]);

  useEffect(() => {
    if (sourceDefinitions.length > 0) {
      dispatch({
        type: ActionType.SET_SOURCE_DEFINITIONS,
        payload: sourceDefinitions,
      });
    }
  }, [sourceDefinitions]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
};

export { ActionType, AppProvider, useAppContext };
