import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

export const RevokeSharedPopup = ({
  item,
  onConfirm,
  onCancel,
  message,
}: {
  item: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <ConfirmationDialog
      title="Are you sure you want to revoke sharing?"
      message={() => (
        <span>
          {message
            ? message
            : `This ${item} will no longer be accessible for other users and they can not re-run simulations using this ${item}
          .`}
        </span>
      )}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
