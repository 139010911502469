import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblUploadInProgressIcon: FC<IconProps> = ({ fill = '#DADADA', width = '16', height = '17' }) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4.5C7 2.01472 9.01472 0 11.5 0C13.9842 0.00268682 15.9973 2.01583 16 4.5C16 6.98528 13.9853 9 11.5 9C9.01472 9 7 6.98528 7 4.5ZM7.75 4.5C7.75 6.57107 9.42893 8.25 11.5 8.25C13.5701 8.24773 15.2477 6.57013 15.25 4.5C15.25 2.42893 13.5711 0.75 11.5 0.75C9.42893 0.75 7.75 2.42893 7.75 4.5Z"
      fill="#ADADAD"
    />
    <path
      d="M11.5 1.5C11.3964 1.5 11.3125 1.58395 11.3125 1.6875V4.6875C11.3125 4.79105 11.3964 4.875 11.5 4.875H14.3031C14.4067 4.85962 14.4852 4.77335 14.4906 4.66875C14.5376 3.84574 14.2437 3.03969 13.6779 2.44018C13.1121 1.84067 12.3243 1.50062 11.5 1.5Z"
      fill="#ADADAD"
    />
    <path
      d="M13.1518 11.4854C12.7614 11.4854 12.4448 11.7892 12.4448 12.1641V13.2029C12.4442 13.6884 12.0342 14.0818 11.5284 14.0821H2.33042C1.82465 14.0818 1.41464 13.6884 1.414 13.2029V12.1641C1.414 11.7892 1.09747 11.4854 0.707 11.4854C0.316535 11.4854 0 11.7892 0 12.1641V13.2029C-1.00588e-06 14.4389 1.04289 15.4413 2.33042 15.4429H11.5284C12.8171 15.4429 13.8618 14.44 13.8618 13.2029V12.1641C13.8618 11.9836 13.7869 11.8105 13.6536 11.6832C13.5204 11.5558 13.3398 11.4846 13.1518 11.4854Z"
      fill={fill}
    />
    <path
      d="M6.92963 12.5117C7.55431 12.5117 8.06071 12.0256 8.06071 11.4259V7.34892C8.06071 7.2716 8.12601 7.20892 8.20655 7.20892H9.75763C9.97922 7.20883 10.1804 7.08458 10.2723 6.89101C10.3642 6.69744 10.3299 6.47018 10.1846 6.30956L7.35663 3.187C7.24927 3.06817 7.09348 2.99992 6.92963 2.99992C6.76578 2.99992 6.60999 3.06817 6.50263 3.187L3.67463 6.30956C3.52934 6.47018 3.49508 6.69744 3.58699 6.89101C3.67889 7.08458 3.88004 7.20883 4.10163 7.20892H5.65271C5.73326 7.20892 5.79855 7.2716 5.79855 7.34892L5.79855 11.4259C5.79855 12.0256 6.30495 12.5117 6.92963 12.5117Z"
      fill={fill}
    />
  </svg>
);
