import { DoubleSide } from 'three';

export const MeshWithColor = ({ color, index }: { color?: string; index: number }) => (
  <meshBasicMaterial
    attach={`material-${index}`}
    color={color}
    opacity={color ? 1 : 0}
    alphaTest={0.5}
    transparent
    side={DoubleSide}
  />
);
