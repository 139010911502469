import { useAppContext } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';

/*
 * Calculates and returns the sabine equation based on the current model details (from rhino file) and assigned material.
 * Open surfaces are given double the value since it has two sides
 */
export const useSabineEstimate = () => {
  const {
    appState: { filteredMaterials },
  } = useAppContext();

  const { areaByObjectId, groupIntegerByObjectId, modelVolume } = useModelContext();

  const calculateSabineEstimate = (materialByObjectId: { [key: string]: string }) => {
    if (materialByObjectId && groupIntegerByObjectId && modelVolume) {
      const absorptionAreas: number[] = new Array(8).fill(0);
      const estimate: number[] = [];
      Object.keys(materialByObjectId).forEach(function (objectId) {
        const materialId = materialByObjectId[objectId];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const materialAbsorption = filteredMaterials.find((key: any) => key.id === materialId)?.absorptionCoefficients;
        if (areaByObjectId) {
          let area = areaByObjectId[objectId];
          if (area == null) {
            area = 0;
          }
          const geometryGroupInt = groupIntegerByObjectId[objectId];
          let openSurfaceMultiplier = 1;
          if (geometryGroupInt == 3) {
            openSurfaceMultiplier = 2;
          }

          if (materialAbsorption) {
            materialAbsorption.forEach(function (absorption: number, index: number) {
              absorptionAreas[index] += area * openSurfaceMultiplier * absorption;
            });
          }
        }
      });

      absorptionAreas.forEach(function (absorptionArea) {
        if (absorptionArea !== 0) {
          // divide with 0 and you will get Infinity - if layers are unassigned the absorptionArea is 0
          const num = +((0.161 * (modelVolume || 0)) / absorptionArea).toFixed(2);
          estimate.push(num);
        }
      });

      return estimate;
    }
  };

  return calculateSabineEstimate;
};
