import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { CreateResultPresetDto, ResultPresetDto } from '../types';

import axios from '@/axios';

const createPreset = async (preset: CreateResultPresetDto): Promise<ResultPresetDto> => {
  const { data } = await axios.post(`/api/ResultPreset/CreateResultPreset`, preset);
  return data;
};

export const useCreateResultPreset = () => {
  return useMutation(async (preset: CreateResultPresetDto) => await createPreset(preset), {
    onError: () => {
      toast.error('An error occurred while saving the results. Please refresh the browser.');
    },
    onSuccess: (data) => {
      toast.success(`Results "${data.name}" saved!`, { className: 'editor-toast' });
    },
  });
};
