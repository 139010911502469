import { SyntheticEvent } from 'react';

import { DisplayChildLayer, DisplayLayer, ListColumnProps } from './types';

import styles from './styles.module.scss';

export const ListColumn = ({
  column,
  rowData,
  toggleVisibility,
  isSelected,
  isHidden,
}: {
  column: ListColumnProps;
  rowData: DisplayLayer | DisplayChildLayer;
  toggleVisibility: (event: SyntheticEvent, id: string, parentId: string) => void;
  isSelected: boolean;
  isHidden: boolean;
}) => {
  return (
    <div className={styles['list-row-cell']} style={column.style}>
      {column.onRenderCell
        ? column.onRenderCell({
            rowId: rowData.id,
            parentId: rowData?.parentId,
            isSelected,
            isHidden,
            toggleVisibility,
          })
        : // @ts-expect-error  No index signature with a parameter of type 'string' was found on type '{ name: string; }'.ts(7053)
          rowData.data[column.fieldName]}
    </div>
  );
};
