import { ReactNode } from 'react';

import { Box } from '@mui/material';

export const DarkBox = ({
  children,
  mt,
  mb,
  ml,
  mr,
  height,
  minHeight,
}: {
  children: ReactNode;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  height?: number | string;
  minHeight?: number | string;
}) => {
  return (
    <Box
      component="div"
      style={{
        position: 'relative',
        background: '#1f1f1f',
        margin: ' 0 -20px',
        padding: '20px',
        borderTop: '1px solid #171717',
        marginTop: mt ?? 0,
        marginBottom: mb ?? 0,
        marginLeft: ml ?? -20,
        marginRight: mr ?? -20,
        height,
        minHeight,
      }}>
      {children}
    </Box>
  );
};
