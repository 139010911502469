import { has } from 'lodash';

import { EMPTY_MATERIAL } from '@/components/Shared/constants';

import { Material, RunStatus, Simulation, SimulationRunDto, SourceDefinition } from '@/types';

export const sortAndOrderSimulation = (simulations: Simulation[]) => {
  const sortedSimulations: Simulation[] = [...simulations].sort((a: Simulation, b: Simulation) => {
    return a.createdAt > b.createdAt ? 1 : -1;
  });
  const newSimulations = sortedSimulations.map((sim: Simulation) => {
    if (sim.sources === null) sim.sources = [];
    if (sim.sourceParameters === null) sim.sourceParameters = [];
    if (sim.receivers === null) sim.receivers = [];
    if (sim.gridReceivers === null) sim.gridReceivers = [];
    if (!has(sim, 'extra')) sim.extra = {};
    if (sim.taskType === null) sim.taskType = 'Hybrid';
    return sim;
  });
  return newSimulations;
};

export const filterTrebleMaterials = (materials: Material[]) => {
  const sortedMaterials = sortMaterials(materials);

  const { materialCategories, parsedMaterials } = parseMaterials(sortedMaterials);

  const filteredMaterials = filterMaterials(parsedMaterials as Material[]);

  return {
    filteredMaterials,
    materialCategories,
  };
};

export const getSourceDefinitionGroups = (sourceDefinitions: SourceDefinition[]) => {
  const manufacturers: string[] = [];

  for (const sourceDefinition of sourceDefinitions) {
    if (sourceDefinition.manufacturer) {
      manufacturers.push(sourceDefinition.manufacturer);
    }
  }

  let sourceDefinitionManufacturers = [...new Set(manufacturers)];
  sourceDefinitionManufacturers = sourceDefinitionManufacturers.sort((a: string, b: string) => {
    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
  });

  return { sourceDefinitionManufacturers };
};

export const sortSourceDefinitions = (sourceDefinitions: SourceDefinition[]) => {
  const sortedSourceDefinitionsByName = sourceDefinitions.sort(
    (sourceDefinitionA: SourceDefinition, sourceDefinitionB: SourceDefinition) => {
      // if object doesn't have a value for a prop we are trying to sort the trick is to set it
      // as Infinity and that will end up at the bottom of the list. We still need to make sure
      // to sort after other props though..
      const aManufacturer = sourceDefinitionA.manufacturer
        ? sourceDefinitionA.manufacturer.trim().toLowerCase()
        : Infinity;
      const bManufacturer = sourceDefinitionB.manufacturer
        ? sourceDefinitionB.manufacturer.trim().toLowerCase()
        : Infinity;

      if (aManufacturer === bManufacturer) {
        // if the manufacturer is the same, sort after name
        const aName = sourceDefinitionA.name.trim().toLowerCase();
        const bName = sourceDefinitionB.name.trim().toLowerCase();

        if (aName === bName) {
          // if the names are the same, sort after sub category
          const aSubCategory = sourceDefinitionA.subCategory
            ? sourceDefinitionA.subCategory.trim().toLowerCase()
            : Infinity;
          const bSubCategory = sourceDefinitionB.subCategory
            ? sourceDefinitionB.subCategory.trim().toLowerCase()
            : Infinity;
          if (aSubCategory !== Infinity && bSubCategory !== Infinity) {
            if (typeof aSubCategory === 'string' && typeof bSubCategory === 'string') {
              return aSubCategory.localeCompare(bSubCategory, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            } else if (typeof aSubCategory === 'number' && typeof bSubCategory === 'number') {
              return aSubCategory - bSubCategory;
            }
          }
          return aSubCategory > bSubCategory ? 1 : -1;
        }

        return aName.localeCompare(bName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }

      if (aManufacturer === Infinity) {
        return 1;
      } else if (bManufacturer === Infinity) {
        return -1;
      }

      return aManufacturer > bManufacturer ? 1 : aManufacturer < bManufacturer ? -1 : 0;
    }
  );

  return sortedSourceDefinitionsByName;
};

const sortMaterials = (materials: Material[]) => {
  const sortedMaterialsByName = materials.sort((a: Material, b: Material) => {
    const aName = a.name.trim().toLowerCase();
    const bName = b.name.trim().toLowerCase();
    const aCategory = a.category.trim().toLowerCase();
    const bCategory = b.category.trim().toLowerCase();
    if (aCategory < bCategory) {
      return -1;
    }
    if (aCategory > bCategory) {
      return 1;
    }
    if (aCategory === bCategory) {
      return aName.localeCompare(bName, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    }
    return 0;
  });

  return sortedMaterialsByName;
};

const filterMaterials = (materials: Material[]) => {
  const filteredMaterials = materials.filter((material) => material.absorptionCoefficients);
  return filteredMaterials;
};

const parseMaterials = (materials: Material[]) => {
  const parsedMaterials = materials;
  const categories: string[] = [];
  for (let i = 0; i < parsedMaterials.length; i++) {
    if (parsedMaterials[i].materialJson) {
      const matJson = JSON.parse(parsedMaterials[i].materialJson);
      parsedMaterials[i].matJson = matJson;
      parsedMaterials[i].absorptionCoefficients = matJson?.FittedAbsorptionCoefficients || undefined;

      categories.push(parsedMaterials[i].category);
    }
  }

  parsedMaterials.push(EMPTY_MATERIAL);

  const materialCategories = [...new Set(categories)];
  return { materialCategories, parsedMaterials };
};

export const getStatusBySimulationRun = (simulationRun: SimulationRunDto) => {
  let status = 0;

  if (simulationRun && simulationRun.status) {
    switch (simulationRun.status) {
      case RunStatus.Queued:
      case RunStatus.InProgress:
      case RunStatus.Created:
      case RunStatus.ProcessingResults:
        status = 1;
        break;
      case RunStatus.Completed:
        status = 2;
        break;
      case RunStatus.Cancelled:
        status = 3;
        break;
      case RunStatus.Error:
      case RunStatus.TaskError:
        status = 4;
        break;
      case RunStatus.InsufficientTokens:
        status = 5;
        break;
      default:
        break;
    }
  }

  return status;
};

export const getLayerMaterialName = (isMissingMaterial: boolean, material: Material) => {
  if (!isMissingMaterial) {
    return material.name;
  }

  if (material.isDeleted) {
    return `${material.name} (deleted)`;
  }

  if (!material.isSharedWithOrganization) {
    return `${material.name} (not shared)`;
  }

  return material.name;
};
