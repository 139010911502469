import { useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import html2canvas from 'html2canvas';
import {
  Box3,
  Camera,
  Color,
  HemisphereLight,
  Material,
  Mesh,
  Object3D,
  PerspectiveCamera,
  PlaneGeometry,
  Scene,
  Sprite,
  Vector3,
  WebGLRenderer,
} from 'three';

const SCALE_SIZE = 2;

export const useExportCanvas = (
  isExporting: boolean,
  colorScale: HTMLDivElement | undefined, // the numbered scale is html element
  colorBar: Sprite | null // the color bar itself is a sprite elements
) => {
  const { scene, camera, gl } = useThree();
  const [hasExported, setHasExported] = useState(false);

  useEffect(() => {
    if (isExporting && colorBar && colorScale) {
      setHasExported(false);
      exportModifiedScene();
    }
  }, [isExporting, colorBar, colorScale]);

  const exportModifiedScene = async () => {
    if (!colorBar) return;
    if (!colorScale) return;

    // Clone what we want from the current scene
    const clonedScene = cloneScene(scene);
    const clonedColorScale = cloneColorScale(colorScale);

    // Setup a new renderer with a new canvas
    const newRenderer = setupRenderer(clonedScene, camera);

    if (!newRenderer) return;

    const threeJsCanvas = newRenderer.domElement;

    // Use html2canvas to capture the HTML element
    const colorScaleCanvas = await html2canvas(clonedColorScale, {
      backgroundColor: 'transparent',
      scale: SCALE_SIZE,
    });

    // Render the sprite to its own canvas
    const colorBarCanvas = renderColorBarToCanvas(colorBar);

    // Create canvas to combine the 2 images
    const combinedCanvas = combineCanvases(threeJsCanvas, colorScaleCanvas, colorBarCanvas);

    if (!combinedCanvas) return;

    const dataUrl = combinedCanvas.toDataURL('image/png');

    downloadImage(dataUrl);

    // Cleanup
    disposeRenderer(newRenderer, clonedScene, colorBarCanvas);
    setHasExported(true);
  };

  const cloneColorScale = (colorScale: HTMLDivElement) => {
    const clonedElement = colorScale.cloneNode(true) as HTMLElement;
    clonedElement.setAttribute('id', 'ClonedColorScale');
    // Replace inputs with spans in the cloned element
    const inputs = clonedElement.querySelectorAll('input');
    inputs.forEach((input) => {
      const span = document.createElement('span');
      span.textContent = input.value; // Copy the value to the span
      if (input.parentNode) {
        input.parentNode.replaceChild(span, input); // Replace input with span
      }
    });
    const spans = clonedElement.querySelectorAll('span');
    spans.forEach((span) => {
      span.style.cssText = `
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.04em
        font-feature-settings: "cv02" 1, "cv04" 1, "cv09" 1, "ss02" 1, "cv01" 1;
        color: #333333;
      `;
    });

    // Temporarily add the cloned element to the body for html2canvas
    clonedElement.style.position = 'absolute';
    clonedElement.style.left = '-9999px'; // Position it out of the screen
    document.body.appendChild(clonedElement);
    return clonedElement;
  };

  const disposeRenderer = (renderer: WebGLRenderer, scene: Scene, colorBarCanvas: HTMLCanvasElement) => {
    // Dispose of the renderer and its DOM element
    renderer.dispose();

    if (renderer.domElement) {
      renderer.domElement.remove();
    }

    // Traverse the scene to dispose of geometries and materials
    scene.traverse((object) => {
      if ((object as Mesh).isMesh) {
        // @ts-expect-error Property 'geometry' does not exist on type 'Object3D<Event>'.
        object.geometry.dispose();
        // @ts-expect-error Property 'material' does not exist on type 'Object3D<Event>'.
        if (object.material.map) object.material.map.dispose();
        // @ts-expect-error Property 'material' does not exist on type 'Object3D<Event>'.
        object.material.dispose();
      }
    });

    const clonedColorScale = document.getElementById('ClonedColorScale');

    if (clonedColorScale) {
      document.body.removeChild(clonedColorScale);
    }

    // If the sprite canvas was added to the DOM, remove it
    if (colorBarCanvas?.parentElement) {
      colorBarCanvas.parentElement.removeChild(colorBarCanvas);
    }
  };

  const combineCanvases = (
    threeJsCanvas: HTMLCanvasElement,
    colorScaleCanvas: HTMLCanvasElement,
    colorBarCanvas: HTMLCanvasElement
  ) => {
    const combinedCanvas = document.createElement('canvas');
    combinedCanvas.width = Math.max(threeJsCanvas.width, colorScaleCanvas.width, colorBarCanvas.width);

    const padding = 80; // pixels of padding below the HTML image

    combinedCanvas.height = threeJsCanvas.height + colorScaleCanvas.height + colorBarCanvas.height + padding;

    const context = combinedCanvas.getContext('2d');
    if (!context) return;

    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);

    const xOffset = (combinedCanvas.width - colorScaleCanvas.width) / 2;

    context.drawImage(threeJsCanvas, 0, 0);
    // we want the scale to be on top of the bar because of the STI text
    context.drawImage(colorBarCanvas, xOffset, threeJsCanvas.height + 44);
    context.drawImage(colorScaleCanvas, xOffset, threeJsCanvas.height);

    return combinedCanvas;
  };

  const setupRenderer = (scene: Scene, camera: Camera) => {
    const contextAttribute = gl.getContextAttributes();
    const { outputEncoding } = gl;

    const renderer = new WebGLRenderer({
      antialias: contextAttribute.antialias,
      alpha: contextAttribute.alpha,
    });

    renderer.outputEncoding = outputEncoding;
    renderer.setClearColor(0x67686d);

    const bbox = new Box3().setFromObject(scene);
    const size = bbox.getSize(new Vector3());

    const aspectRatio = size.x / size.y;

    const canvasElement = document.getElementById('viewport');

    if (!canvasElement) return;

    renderer.setSize(
      canvasElement?.clientWidth * aspectRatio * SCALE_SIZE,
      canvasElement?.clientHeight * aspectRatio * SCALE_SIZE
    );
    const hemisphereLight = new HemisphereLight(0xffffff, 0xaaaaaa, 2.25);
    hemisphereLight.position.set(50, 100, 0);
    scene.add(hemisphereLight);

    renderer.render(scene, camera);
    return renderer;
  };

  const renderColorBarToCanvas = (colorBar: Sprite) => {
    const clonedColorBar = colorBar.clone();

    const colorBarCanvas = document.createElement('canvas');

    colorBarCanvas.width = clonedColorBar.scale.x * SCALE_SIZE;
    colorBarCanvas.height = clonedColorBar.scale.y * SCALE_SIZE;

    // Make the new renderer have the same settings as the existing one that we are duplicating
    const contextAttribute = gl.getContextAttributes();
    const { outputEncoding } = gl;

    const colorBarRenderer = new WebGLRenderer({
      canvas: colorBarCanvas,
      antialias: contextAttribute.antialias,
      alpha: contextAttribute.alpha,
    });

    colorBarRenderer.outputEncoding = outputEncoding;

    colorBarRenderer.setSize(colorBarCanvas.width, colorBarCanvas.height);

    // Create a new scene and camera for rendering the sprite
    const colorBarScene = new Scene();
    // These are just random numbers that worked
    const colorBarCamera = new PerspectiveCamera(70, colorBarCanvas.width / colorBarCanvas.height, 0.1, 1000);

    colorBarCamera.position.z = 10; // Position the camera as needed to frame the sprite

    // Add the sprite to the scene
    colorBarScene.add(clonedColorBar);
    // Render the sprite
    colorBarRenderer.render(colorBarScene, colorBarCamera);

    // Clean up if not needed further
    colorBarRenderer.dispose();

    return colorBarCanvas;
  };

  const cloneScene = (scene: Scene) => {
    const newScene = new Scene();

    scene.traverse((object: Object3D) => {
      if ((object as Mesh).isMesh && (object as Mesh<PlaneGeometry, Material>).material.visible) {
        const mesh = object as Mesh<PlaneGeometry, Material>;
        const clonedMesh = mesh.clone();
        newScene.add(clonedMesh);
      }
    });

    newScene.background = new Color('white');

    return newScene;
  };

  const downloadImage = (dataUrl: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.download = 'exported_heatmap.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setHasExported(true);
  };

  return hasExported;
};
