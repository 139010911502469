import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCalendarIcon: FC<IconProps> = ({ fill = '#ADADAD' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.375 1.5H10.75C11.3023 1.5 11.75 1.94772 11.75 2.5V11C11.75 11.5523 11.3023 12 10.75 12H1.25C0.697715 12 0.25 11.5523 0.25 11V2.5C0.25 1.94772 0.697715 1.5 1.25 1.5H2C2.13807 1.5 2.25 1.61193 2.25 1.75V2.875C2.25 3.08211 2.41789 3.25 2.625 3.25C2.83211 3.25 3 3.08211 3 2.875V0.5C3 0.223858 3.22386 0 3.5 0C3.77614 0 4 0.223858 4 0.5V1.3755C4.00028 1.44434 4.05616 1.5 4.125 1.5H7.25C7.38807 1.5 7.5 1.61193 7.5 1.75V2.875C7.5 3.08211 7.66789 3.25 7.875 3.25C8.08211 3.25 8.25 3.08211 8.25 2.875V0.5C8.25 0.223858 8.47386 0 8.75 0C9.02614 0 9.25 0.223858 9.25 0.5V1.375C9.25 1.44404 9.30596 1.5 9.375 1.5ZM1.5 11H10.5C10.6381 11 10.75 10.8881 10.75 10.75V4.75C10.75 4.61193 10.6381 4.5 10.5 4.5H1.5C1.36193 4.5 1.25 4.61193 1.25 4.75V10.75C1.25 10.8881 1.36193 11 1.5 11Z"
      fill={fill}
    />
  </svg>
);
