import { FC } from 'react';

import { IconProps } from '@/types';

export const HeadsetIRIcon: FC<IconProps> = ({ fill = '#DADADA', fillExtra = '#ADADAD' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 6.89543 0.895431 6 2 6V11C0.895431 11 0 10.1046 0 9V8Z" fill={fill} />
    <path d="M14 8C14 6.89543 13.1046 6 12 6V11C13.1046 11 14 10.1046 14 9V8Z" fill={fill} />
    <path d="M2 10.5V6C2 3.23858 4.23858 1 7 1V1C9.76142 1 12 3.23858 12 6V10.5" stroke={fill} strokeLinecap="round" />
    <path
      d="M5.29556 4.89392C5.10453 4.69962 4.80087 4.7024 4.61307 4.90017C4.42527 5.09793 4.42264 5.41771 4.60714 5.61888C5.33313 6.38279 5.74103 7.41924 5.74103 8.5C5.74103 9.58076 5.33313 10.6172 4.60714 11.3811C4.42264 11.5823 4.42527 11.9021 4.61307 12.0998C4.80087 12.2976 5.10453 12.3004 5.29556 12.1061C6.20421 11.1499 6.71474 9.8527 6.71474 8.5C6.71474 7.1473 6.20421 5.85005 5.29556 4.89392Z"
      fill={fillExtra}
    />
    <path
      d="M4.10306 6.19311C3.98017 6.06342 3.80092 6.01263 3.63283 6.05986C3.46473 6.10709 3.33334 6.24517 3.28813 6.42208C3.24293 6.599 3.29078 6.78787 3.41367 6.91755C4.24176 7.79351 4.24176 9.20958 3.41367 10.0855C3.2782 10.2122 3.2207 10.4075 3.26439 10.5926C3.30809 10.7778 3.44577 10.922 3.62177 10.9671C3.79777 11.0122 3.983 10.9507 4.10257 10.8074C5.30786 9.53125 5.30808 7.46956 4.10306 6.19311Z"
      fill={fillExtra}
    />
    <path
      d="M8.70444 4.89392C8.89547 4.69962 9.19913 4.7024 9.38693 4.90017C9.57473 5.09793 9.57736 5.41771 9.39286 5.61888C8.66687 6.38279 8.25897 7.41924 8.25897 8.5C8.25897 9.58076 8.66687 10.6172 9.39286 11.3811C9.57736 11.5823 9.57473 11.9021 9.38693 12.0998C9.19913 12.2976 8.89547 12.3004 8.70444 12.1061C7.79579 11.1499 7.28526 9.8527 7.28526 8.5C7.28526 7.1473 7.79579 5.85005 8.70444 4.89392Z"
      fill={fillExtra}
    />
    <path
      d="M9.89694 6.19311C10.0198 6.06342 10.1991 6.01263 10.3672 6.05986C10.5353 6.10709 10.6667 6.24517 10.7119 6.42208C10.7571 6.599 10.7092 6.78787 10.5863 6.91755C9.75824 7.79351 9.75824 9.20958 10.5863 10.0855C10.7218 10.2122 10.7793 10.4075 10.7356 10.5926C10.6919 10.7778 10.5542 10.922 10.3782 10.9671C10.2022 11.0122 10.017 10.9507 9.89743 10.8074C8.69214 9.53125 8.69192 7.46956 9.89694 6.19311Z"
      fill={fillExtra}
    />
  </svg>
);
