import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblChevronRight: FC<IconProps> = ({ width = 4, height = 6, fill = '#ADADAD' }) => (
  <svg width={width} height={height} viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.571533 0.5L3.42868 3L0.571533 5.5"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.9}
    />
  </svg>
);
