import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

export type StartGeometryCheckTask = {
  inputFileUploadId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const startGeometryCheckTask = async (request: StartGeometryCheckTask): Promise<any> => {
  const url = `/api/GeometryCheck/StartGeometryCheckTask?fileUploadId=${request.inputFileUploadId}`;

  const { data } = await axios.patch(`${url}`, request.body, {});
  return data.id;
};

export const useStartGeometryCheckTask = () => {
  return useMutation(async (request: StartGeometryCheckTask) => await startGeometryCheckTask(request));
};
