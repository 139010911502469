import { useEffect, useState } from 'react';

import { SourceDefinitionCategories, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { TrblSourceDefinitionIcon } from '@/components';
import { SourceDefinitionList } from './components/SourceDefinitionList';
import { SourceDefinitionPreview } from './components/SourceDefinitionPreview';
import { LibraryPanel } from '../LibraryPanel';
import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';

import { filterByCategories } from './utils';

import { SourceDefinition } from '@/types';

export const SourceDefinitionLibrary = ({ inPopup }: { inPopup?: boolean }) => {
  const { highlightedItemId } = useLibraryPanelContext();

  // state variable to keep track of all source definitions to show in the list
  const [sourceDefinitionsToUse, setSourceDefinitionsToUse] = useState<SourceDefinition[]>([]);

  // flat subCategories
  const [sourceDefinitionSubCategoriesAvailable, setSourceDefinitionSubCategoriesAvailable] = useState<string[]>([]);

  // state variable for keeping track of all selected category filters
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);

  // state variables for keeping track of all selected manufacturer and subCategory filters
  const [manufacturerFilters, setManufacturerFilters] = useState<string[]>([]);
  const [subCategoriesFilters, setSubCategoriesFilters] = useState<string[]>([]);

  // state variable for keeping track of the search value
  const [searchValue, setSearchValue] = useState<string>('');

  const {
    appState: {
      filteredSourceDefinitions: allAvailableSourceDefinitions,
      sourceDefinitionCategories,
      sourceDefinitionSubCategories,
      sourceDefinitionManufacturers,
    },
  } = useAppContext();

  const {
    state: { userInfo },
  } = useBaseContext();

  useEffect(() => {
    // this has to live inside of a useEffect
    filterListOfSourceDefinitions(
      categoryFilters,
      manufacturerFilters,
      subCategoriesFilters,
      searchValue,
      allAvailableSourceDefinitions
    );
  }, [categoryFilters, manufacturerFilters, subCategoriesFilters, searchValue, allAvailableSourceDefinitions]);

  const searchingForSourceDefinition = (key: string, inputValue: string) => {
    if (/^[ -~]+$/i.test(key)) {
      const parsedVal = inputValue.toLowerCase();
      setSearchValue(parsedVal);
    }
  };

  useEffect(() => {
    // set the Source definition categories and types as nothing selected
    updateSubCategoriesAvailable([]);
  }, []);

  const onSelectSourceDefinitionCategories = (categories: string[]) => {
    // filter list by categories and types
    setCategoryFilters(categories);
    // update subCategories based on what categories or types are selected
    updateSubCategoriesAvailable(categories);
  };

  const updateSubCategoriesAvailable = (categories: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const availableSubCategories: any = [];
    let types = categories;
    // remove the default categories so we only have the types to filter the subCategories by by
    types = types.filter((item) => {
      if (item !== 'Default' && item !== 'Organization' && item !== 'Created by me') {
        return item;
      }
    });

    // if no type is selected, then all subCategories are be available for filtering
    if (types.length === 0) {
      availableSubCategories.push(sourceDefinitionSubCategories['Amplified' as SourceDefinitionCategories]);
      availableSubCategories.push(sourceDefinitionSubCategories['Natural' as SourceDefinitionCategories]);
      availableSubCategories.push(sourceDefinitionSubCategories['Other' as SourceDefinitionCategories]);
    } else {
      // else then only subCategories under the type selected are available for filtering
      types.forEach((type: string) => {
        availableSubCategories.push(sourceDefinitionSubCategories[type as SourceDefinitionCategories]);
      });
    }

    // flatten list and remove duplicates for the filter drowdown
    let availableSubCategoriesFlat = [].concat(...availableSubCategories);
    availableSubCategoriesFlat = [...new Set(availableSubCategoriesFlat)];

    setSourceDefinitionSubCategoriesAvailable(availableSubCategoriesFlat);
  };

  const onSelectSourceDefinitionManufacturers = (selectedManufacturers: string[]) => {
    // filter list by manufacturers
    setManufacturerFilters(selectedManufacturers);
  };

  const onSelectSubCategories = (selectedSubs: string[]) => {
    // filter list by sub-categories
    setSubCategoriesFilters(selectedSubs);
  };

  const filterListOfSourceDefinitions = (
    categoryFilters: string[],
    manufacturersFilter: string[],
    subCategoriesFilter: string[],
    searchValue: string,
    allAvailableSourceDefinitions: SourceDefinition[]
  ) => {
    // if filter array is empty then add Source definition OR if the filter array has values
    // then add Source definition if it has the property of a value in the filter array

    const filteredSourceDefinitions = filterByCategories(
      allAvailableSourceDefinitions,
      categoryFilters,
      manufacturersFilter,
      subCategoriesFilter,
      userInfo?.id
    );

    let searchedList = filteredSourceDefinitions;

    if (searchValue && searchValue.length > 1) {
      searchedList = filteredSourceDefinitions.filter((sourceDefinition: SourceDefinition) => {
        const parsedName = sourceDefinition.name.toLowerCase();
        const parsedManufacturer = sourceDefinition.manufacturer ? sourceDefinition.manufacturer.toLowerCase() : null;
        const parsedCategory = sourceDefinition.category.toLowerCase();
        const parsedSubCategory = sourceDefinition.subCategory ? sourceDefinition.subCategory.toLowerCase() : null;

        return (
          parsedName.includes(searchValue) ||
          parsedCategory.includes(searchValue) ||
          parsedManufacturer?.includes(searchValue) ||
          parsedSubCategory?.includes(searchValue)
        );
      });
    }

    setSourceDefinitionsToUse(searchedList);
  };

  return (
    <LibraryPanel
      label="Source definitions"
      icon={<TrblSourceDefinitionIcon width="14" height="14" />}
      categories={sourceDefinitionCategories}
      onSearchLibrary={searchingForSourceDefinition}
      onSelectCategories={onSelectSourceDefinitionCategories}
      inPopup={inPopup}>
      <>
        <SourceDefinitionList
          availableSourceDefinitionList={sourceDefinitionsToUse}
          sourceDefinitionManufacturers={sourceDefinitionManufacturers}
          selectedSourceDefinitionManufacturers={manufacturerFilters}
          onSelectSourceDefinitionManufacturers={onSelectSourceDefinitionManufacturers}
          sourceDefinitionSubCategories={sourceDefinitionSubCategoriesAvailable}
          selectedSourceDefinitionSubCategories={subCategoriesFilters}
          onSelectSourceDefinitionSubCategories={onSelectSubCategories}
          inPopup={inPopup}
        />
        {highlightedItemId && !inPopup && <SourceDefinitionPreview sourceDefinitionId={highlightedItemId} />}
      </>
    </LibraryPanel>
  );
};
