import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblGeometryFolderIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '16' }) => (
  <svg width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5413 13.6875C12.5413 14.1707 12.1496 14.5625 11.6663 14.5625H2.33301C1.84976 14.5625 1.45801 14.1707 1.45801 13.6875V2.3125C1.45801 1.82925 1.84976 1.4375 2.33301 1.4375H9.91634C10.1445 1.43752 10.3637 1.52669 10.5271 1.686L12.2771 3.36775C12.446 3.53242 12.5413 3.75833 12.5413 3.99425V13.6875Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92404 10.0153L10.7781 10.4543L10.7784 10.453C10.9705 10.559 11.0463 10.8052 10.9492 11.0077C10.8522 11.2103 10.6177 11.2952 10.421 11.199L9.461 10.705C9.45089 10.6999 9.4391 10.6999 9.429 10.705L7.2578 11.7273H7.25556C7.19456 11.7562 7.12455 11.7562 7.06356 11.7273H7.06068L4.8882 10.705C4.87867 10.7003 4.86764 10.7003 4.85812 10.705L3.89812 11.205C3.70035 11.3077 3.46009 11.224 3.36148 11.018C3.26286 10.812 3.34323 10.5617 3.541 10.459L4.3954 10.0157C4.42241 10.0014 4.43947 9.97255 4.43956 9.941V7.16667C4.43958 7.03574 4.51318 6.91694 4.62772 6.863L6.71348 5.88133C6.74213 5.86783 6.76054 5.8381 6.76052 5.80533V4.66667C6.76052 4.43655 6.9396 4.25 7.16052 4.25C7.38143 4.25 7.56052 4.43655 7.56052 4.66667V5.80433C7.56065 5.83705 7.57901 5.8667 7.60756 5.88033L9.69076 6.86267C9.80564 6.91646 9.87954 7.03546 9.87956 7.16667V9.94067C9.87956 9.97234 9.89679 10.0013 9.92404 10.0153ZM5.65081 7.16673C5.65081 7.19953 5.66928 7.22928 5.69799 7.24273L7.12775 7.91607C7.14872 7.92589 7.17271 7.92589 7.19367 7.91607L8.63367 7.24273C8.66239 7.22928 8.68086 7.19953 8.68086 7.16673C8.68086 7.13393 8.66239 7.10419 8.63367 7.09073L7.19367 6.4174C7.17268 6.40774 7.14875 6.40774 7.12775 6.4174L5.69799 7.09073C5.66928 7.10419 5.65081 7.13393 5.65081 7.16673Z"
      fill={fill}
    />
  </svg>
);
