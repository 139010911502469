import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import { useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { TrblTooltip } from '@/components/Shared';
import { TabButtons } from '@/components/Shared/TabButtons';
import { TrblWarningIcon } from '@/components/Icons';
import { LibraryItemPreview, UserOwnedIconIndicator } from '@/components/LibraryPanel';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { SOURCE_DEFINITION_WARNING_TEXT, TOGGLE_2D_PLOT_HOVER_TEXT } from '@/components/SourceDefinition/constants';
import { FrequenciesTable } from '@/components/SourceDefinition/FrequenciesTable';
import { getPlotData, getSourceDefinitionPlotLayoutConfig } from '@/components/SourceDefinition/utils';
import { SourceDefinitionPopup } from './SourceDefinitionPopup/SourceDefinitionPopup';

import { SourceDefinition } from '@/types';

import './style.scss';

export const SourceDefinitionPreview = ({ sourceDefinitionId }: { sourceDefinitionId: string }) => {
  const { highlightedItemId } = useLibraryPanelContext();
  const {
    state: { userInfo },
  } = useBaseContext();
  const {
    appState: { filteredSourceDefinitions },
  } = useAppContext();
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition | undefined>(undefined);
  const [showDetailsPopup, setShowDetailsPopup] = useState<undefined | boolean>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sourceDefinitionJson, setSourceDefinitionJson] = useState<any>(null);
  const [plotSelected, setPlotSelected] = useState('Top');
  const [plotData, setPlotData] = useState<Data[] | null>(null);

  useEffect(() => {
    if (sourceDefinitionId) {
      const selectedSourceDef = filteredSourceDefinitions.find((sourceDef) => sourceDef.id === sourceDefinitionId);
      setSourceDefinition(selectedSourceDef);
    }
  }, [sourceDefinitionId]);

  const togglePlot = (value: string) => {
    if (value == 'Top') setPlotData(getPlotData(sourceDefinitionJson.angles, sourceDefinitionJson.directivity));
    else setPlotData(getPlotData(sourceDefinitionJson.angles, sourceDefinitionJson.directivity_XZ));
    setPlotSelected(value);
  };

  const title = (
    <>
      <span>
        {sourceDefinition?.manufacturer && sourceDefinition?.manufacturer + ': '}
        {sourceDefinition?.name}
      </span>
      {sourceDefinition?.subCategory && <span style={{ color: '#999999' }}> | {sourceDefinition.subCategory}</span>}
    </>
  );
  const type = 'Source definition';

  const downloadAndSetJson = async (url: string) => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setSourceDefinitionJson(myJson);
      });
  };

  useEffect(() => {
    if (sourceDefinition?.directivity2dJsonDownloadUrl) {
      downloadAndSetJson(sourceDefinition.directivity2dJsonDownloadUrl);
    }
  }, [sourceDefinition]);

  useEffect(() => {
    if (sourceDefinitionJson) {
      setPlotData(getPlotData(sourceDefinitionJson.angles, sourceDefinitionJson.directivity));
      setPlotSelected('Top');
    }
  }, [sourceDefinitionJson]);

  return (
    <>
      <LibraryItemPreview title={title} type={type} setShowDetailsPopup={setShowDetailsPopup} large>
        <div className="preview-content-container">
          {highlightedItemId && sourceDefinitionJson && (
            <>
              <FrequenciesTable sourceDefinitionJson={sourceDefinitionJson} small />
              {plotData && (
                <div style={{ marginTop: '12px' }}>
                  <Plot
                    data={plotData}
                    config={{ displaylogo: false, displayModeBar: false }}
                    layout={getSourceDefinitionPlotLayoutConfig(plotData, plotSelected, 'small')}
                  />
                  <div style={{ margin: '-58px 0 0', position: 'relative', zIndex: '1', width: '60px' }}>
                    <TabButtons
                      options={[
                        { key: 'Top', label: 'T', tooltip: TOGGLE_2D_PLOT_HOVER_TEXT[0] },
                        { key: 'Side', label: 'S', tooltip: TOGGLE_2D_PLOT_HOVER_TEXT[1] },
                      ]}
                      selectedValue={plotSelected}
                      onChange={togglePlot}
                      small
                      dark
                    />
                  </div>
                </div>
              )}
              <TrblTooltip title={SOURCE_DEFINITION_WARNING_TEXT}>
                <div className="preview-warning-icon">
                  <TrblWarningIcon fill="#999999" />
                </div>
              </TrblTooltip>
              <div className="preview-user-owned-icons">
                {sourceDefinition && (
                  <UserOwnedIconIndicator
                    showUserIcon={sourceDefinition.isUserCreated && userInfo?.id === sourceDefinition.userId}
                    showOrganization={sourceDefinition.isSharedWithOrganization}
                    size="medium"
                    color="#DADADA"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </LibraryItemPreview>
      {showDetailsPopup && highlightedItemId && (
        <SourceDefinitionPopup
          setShowPopup={setShowDetailsPopup}
          sourceDefinitionId={highlightedItemId}
          sourceDefinitionJson={sourceDefinitionJson}
        />
      )}
    </>
  );
};
