import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Drawer, Stack, TextField } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';

import { UserDto } from '../../hooks/types';

import classes from './styles.module.scss';

import axios from '@/axios';

export type UserFormState = {
  id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
};

type UserSidepanelProps = {
  user?: UserDto;
  onCancel: () => void;
  onSubmit: (values: UserFormState) => void;
};

const getValidationSchema = (originalEmailAddress?: string) => {
  return yup.object().shape({
    email: yup
      .string()
      .email('Not a valid email address')
      .required('Email is required')
      .test('checkDuplicateEmail', 'User with this email already exists', async (value) => {
        if (value && (value !== originalEmailAddress || !originalEmailAddress)) {
          try {
            const response = await axios.get('/api/Admin/GetUserByEmail', {
              params: {
                email: value,
              },
            });

            // Check if a user was found with that email
            if (response.data) {
              // User was found so we fail the validation
              return false;
            } else {
              return true;
            }
          } catch (error) {
            // If the error is a 404, it means the email doesn't exist, so we can return true.
            return true;
          }
        } else {
          return true;
        }
      }),
  });
};

export const UserSidepanel: FC<UserSidepanelProps> = ({ user, onCancel, onSubmit }) => {
  const { handleSubmit, control, register } = useForm<UserFormState>({
    resolver: yupResolver(getValidationSchema(user?.email)),
    reValidateMode: 'onSubmit',
    defaultValues: {
      id: user?.id || '',
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName,
    },
  });

  useEffect(() => {
    if (user?.id) {
      register('id', { value: user.id });
    }
  }, [user]);

  return (
    <Drawer open={true} anchor={'right'}>
      <div className={classes.sidepanel_layout}>
        <Stack flex={'auto 1 1'} spacing="20px">
          <p style={{ fontWeight: '600', margin: '8px 0 12px' }}>{user ? 'Edit user' : 'New user'}</p>

          <Controller
            name={'email'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                required
                disabled={user?.id !== undefined}
                onChange={onChange}
                onBlur={onBlur}
                label="Email"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
                variant={user?.id !== undefined ? 'filled' : undefined}
                InputProps={{ disableUnderline: true }}
              />
            )}
          />
          <Controller
            name={'firstName'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                onBlur={onBlur}
                label="First name"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name={'lastName'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                onBlur={onBlur}
                label="Last name"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
              />
            )}
          />
        </Stack>
        <div className={classes.sidepanel_actions}>
          <SecondaryButton width={150} label="Cancel" onClick={onCancel} />
          <PrimaryButton width={150} label={user ? 'Update' : 'Create'} onClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </Drawer>
  );
};
