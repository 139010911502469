import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblDuplicateIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7989 2.10408L11.0075 0.323167C10.7991 0.116567 10.5176 0.00045309 10.2241 0L5.19167 0C4.57955 0 4.08333 0.496218 4.08333 1.10833V2.91667L1.98333 2.91667C1.37122 2.91667 0.875 3.41288 0.875 4.025L0.875 12.8975C0.87821 13.5073 1.37349 14 1.98333 14L8.80833 14C9.41931 14 9.91506 13.5056 9.91667 12.8946V11.0833H12.0167C12.6276 11.0833 13.1234 10.5889 13.125 9.97792L13.125 2.88692C13.1255 2.59283 13.0081 2.31082 12.7989 2.10408ZM8.45833 12.8333L2.33333 12.8333C2.17225 12.8333 2.04167 12.7027 2.04167 12.5417L2.04167 4.375C2.04167 4.21392 2.17225 4.08333 2.33333 4.08333L6.874 4.08333C6.95035 4.08334 7.02365 4.11329 7.07817 4.16675L8.6625 5.71667C8.71845 5.77151 8.74999 5.84657 8.75 5.92492L8.75 12.5417C8.75 12.7027 8.61942 12.8333 8.45833 12.8333ZM10.0625 9.91667H11.6667C11.8277 9.91667 11.9583 9.78608 11.9583 9.625L11.9583 3.00825C11.9583 2.9299 11.9268 2.85485 11.8708 2.8L10.2708 1.23492C10.2161 1.18132 10.1426 1.15136 10.066 1.1515L5.54167 1.16667C5.38058 1.16667 5.25 1.29725 5.25 1.45833V2.77083C5.25 2.85137 5.31529 2.91667 5.39583 2.91667L7.01633 2.91667C7.30977 2.91651 7.59129 3.03273 7.79917 3.23983L9.59117 5.02192C9.79942 5.22898 9.91656 5.5105 9.91667 5.80417L9.91667 9.77083C9.91667 9.85137 9.98196 9.91667 10.0625 9.91667Z"
      fill={fill}
    />
  </svg>
);
