import { useMutation, useQuery } from '@tanstack/react-query';

import { ProjectAndUsersDto } from '@/types';

import axios from '@/axios';

const getProjects = async () => {
  const { data } = await axios.get<ProjectAndUsersDto[]>(`/api/v2/Project/GetProjects`);
  return data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useGetProjects = () => {
  return useQuery<ProjectAndUsersDto[]>(['projects'], getProjects, {
    refetchOnWindowFocus: false,
  });
};

export const useFetchProjects = () => {
  return useMutation(async () => await getProjects());
};
