import React, { RefObject } from 'react';

import { ActionType, useLibraryPanelContext } from '../LibraryPanelContext';

import { keyBoardEventsForLibraryPanel } from '../utils';

import './styles.scss';

export const LibraryRow = ({
  listItemRef,
  itemId,
  children,
  isHighlighted,
  isAssigned,
  onMouseEnter,
  onMouseLeave,
  onAssign,
  inPopup,
}: {
  listItemRef: RefObject<HTMLLIElement>;
  itemId: string;
  children: JSX.Element | JSX.Element[];
  isHighlighted: boolean;
  isAssigned: boolean;
  inPopup?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAssign: (event: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  const { dispatch } = useLibraryPanelContext();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onAssign(event);
    } else {
      // if the user uses the up and down arrow keys the focus and
      // highlighted item should change to that new item
      const newElement = keyBoardEventsForLibraryPanel(event, listItemRef);
      if (newElement) {
        const listElementId = (newElement as Element).getAttribute('data-elementid');
        if (listElementId) {
          dispatch({
            type: ActionType.SET_HIGHLIGHTED_ITEM,
            highlightedItemId: listElementId,
          });
        }
      }
    }
  };

  const onSingleClick = () => {
    dispatch({
      type: ActionType.SET_HIGHLIGHTED_ITEM,
      highlightedItemId: isHighlighted ? null : itemId,
    });
  };

  return (
    <li
      tabIndex={0}
      ref={listItemRef}
      // "assigned" class name is used for keyboard navigation not styling
      className={`library-row ${isHighlighted ? 'selected' : ''} ${isAssigned ? 'assigned' : ''}`}
      onClick={!inPopup ? onSingleClick : onAssign}
      onDoubleClick={onAssign}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={handleKeyDown}
      data-elementid={itemId}>
      {children}
    </li>
  );
};
