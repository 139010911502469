import {
  SourceSummingResultDto,
  SourceSummingResultForGridReceiverDto,
  TaskGroupSourceSummingResultsDto,
} from './hooks';

import { resultTypes, SourceSummingProgressStates } from './constants';

import { SummedSourceOption } from '../../types';
import {
  PointResult,
  ResultType,
  ResultTypeOption,
  SolveTask,
  SourceResultForGridReceiverDto,
  SourceResults,
} from '@/types';

const getAvailableSources = (sourceResult: SourceResults[] | SourceResultForGridReceiverDto[]) => {
  const uniqueSources: PointResult[] = [];

  sourceResult
    .sort(
      (a: SourceResults | SourceResultForGridReceiverDto, b: SourceResults | SourceResultForGridReceiverDto) =>
        a.orderNumber - b.orderNumber
    )
    .forEach((s) => {
      if (uniqueSources.findIndex((x) => x.id === s.sourcePointId) === -1) {
        uniqueSources.push({
          id: s.sourcePointId,
          name: `${s.label ? s.label : 'Source ' + (uniqueSources.length + 1)}`,
          coords: `(${s.sourceX}, ${s.sourceY}, ${s.sourceZ})`,
        });
      }
    });

  return uniqueSources;
};

export const getAvailableSummedSources = (sourceSummingResults: TaskGroupSourceSummingResultsDto) => {
  const summedSourceOptions: SummedSourceOption[] = [];

  [...sourceSummingResults.sourceResults]
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .forEach((s) => {
      // Create a unique ID by joining sorted sourceIds
      const sortedSourceIds = [...s.sources].sort((a, b) => a.id.localeCompare(b.id)).map((x) => x.id);
      const sourceIdKey = sortedSourceIds.join(';');

      const existingOptionIndex = summedSourceOptions.findIndex((x) => x.id === sourceIdKey);

      // Check if the source already exists in the list
      if (existingOptionIndex > -1) {
        const existingOption = summedSourceOptions[existingOptionIndex];
        if (!existingOption.completedTasks.some((t) => t.resultType === s.resultType)) {
          // If the option is already in the list, we need to update the supported result types of the existing option in the array
          summedSourceOptions[existingOptionIndex].completedTasks.push({
            taskId: s.taskId,
            resultType: s.resultType,
          });
        }
      } else {
        const defaultName = [...s.sources]
          .sort((a, b) => b.orderNumber - a.orderNumber)
          .map((s) => s.label)
          .join(', ');

        summedSourceOptions.push({
          id: sourceIdKey,
          name: s.label ?? defaultName,
          createdAt: new Date(s.createdAt),
          completedTasks: [{ taskId: s.taskId, resultType: s.resultType }],
          inProgressTasks: [],
          sourceIds: s.sources.map((x) => x.id),
        });
      }
    });

  if (sourceSummingResults.incompleteTasks?.length) {
    [...sourceSummingResults.incompleteTasks]
      .filter((x) => SourceSummingProgressStates.includes(x.status))
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .forEach((s) => {
        const sortedSourceIds = [...s.sources].sort((a, b) => a.id.localeCompare(b.id)).map((x) => x.id);
        const sourceIdKey = sortedSourceIds.join(';');

        const existingOptionIndex = summedSourceOptions.findIndex((x) => x.id === sourceIdKey);

        if (existingOptionIndex > -1) {
          summedSourceOptions[existingOptionIndex].inProgressTasks.push({
            taskId: s.id,
            resultType: s.taskType,
          });
        } else {
          const defaultName = [...s.sources]
            .sort((a, b) => b.orderNumber - a.orderNumber)
            .map((s) => s.label)
            .join(', ');

          summedSourceOptions.push({
            id: sourceIdKey,
            name: s.label ?? defaultName,
            createdAt: new Date(s.createdAt),
            completedTasks: [],
            inProgressTasks: [{ taskId: s.id, resultType: s.taskType }],
            sourceIds: s.sources.map((x) => x.id),
          });
        }
      });
  }

  return summedSourceOptions;
};

const getAvailableReceivers = (selectedSource: SourceResults | SourceSummingResultDto) => {
  const receiverResults = [...selectedSource.taskResultForReceiver]
    .sort((a, b) => (a?.orderNumber ?? 0) - (b?.orderNumber ?? 0))
    .map((receiver, index) => ({
      id: receiver.pointId,
      name: `${receiver.label ? receiver.label : 'Receiver ' + (index + 1)}`,
      coords: `(${receiver.x}, ${receiver.y}, ${receiver.z})`,
    }));

  return receiverResults;
};

const getAvailableGridReceivers = (
  selectedSource: SourceResultForGridReceiverDto | SourceSummingResultForGridReceiverDto
) => {
  const gridRecieverResults = [...selectedSource.gridReceiverResults]
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((receiver, index: number) => ({
      id: receiver.pointId,
      name: `${receiver.label ? receiver.label : 'Surface receiver ' + (index + 1)}`,
      coords: `(${receiver.userInput.centerPoint.x}, ${receiver.userInput.centerPoint.y}, ${receiver.userInput.centerPoint.z})`,
    }));

  return gridRecieverResults;
};

export const getSourcesAndReceivers = (
  lastSolveResults: SolveTask,
  lastSourceSummingResults: TaskGroupSourceSummingResultsDto | null,
  selectedResultType: string,
  selectedSourcePointIds?: string[],
  selectedReceiverPointIds?: string[]
) => {
  const availableSources = getAvailableSources(lastSolveResults.sourceResults);
  // If no source is selected we default to the first one in the list
  let sourcePointIds = selectedSourcePointIds?.length ? selectedSourcePointIds : [availableSources[0].id];

  let selectedSourceObject: SourceResults | undefined;
  let selectedSourceSummingObject: SourceSummingResultDto | undefined;

  if (sourcePointIds.length > 1) {
    selectedSourceSummingObject = lastSourceSummingResults?.sourceResults.find(
      (source) =>
        sourcePointIds.length === source.sources.length &&
        source.sources.every((s) => sourcePointIds.includes(s.id)) &&
        source.resultType === selectedResultType
    );

    // In case we didn't find a source summing object we default to the first source in the list (probably due to result type not being supported)
    sourcePointIds = selectedSourceSummingObject ? sourcePointIds : [availableSources[0].id];
  }

  if (selectedSourceSummingObject === undefined) {
    selectedSourceObject = lastSolveResults.sourceResults.find(
      (source: SourceResults) => source.sourcePointId === sourcePointIds[0] && source.resultType === selectedResultType
    );
  }

  const availableReceivers =
    selectedSourceObject || selectedSourceSummingObject
      ? getAvailableReceivers((selectedSourceSummingObject ?? selectedSourceObject)!)
      : [];

  // When initializing we default to having the first receiver selected (given that we have some receivers to select from)
  let receiverPointIds: string[] = [];
  if (selectedReceiverPointIds === undefined && availableReceivers.length) {
    receiverPointIds = [availableReceivers[0].id];
  } else if (selectedReceiverPointIds?.length) {
    receiverPointIds = selectedReceiverPointIds;
  }

  const selectedReceiverObjects = selectedSourceObject
    ? selectedSourceObject?.taskResultForReceiver?.filter((receiver) => receiverPointIds.includes(receiver.pointId))
    : selectedSourceSummingObject?.taskResultForReceiver?.filter((receiver) =>
        receiverPointIds.includes(receiver.pointId)
      );

  return {
    selectedSourceObject,
    selectedSourceSummingObject,
    selectedReceiverObjects,
    availableSources,
    availableReceivers,
  };
};

export const getSourcesAndGridReceivers = (
  lastGridResults: SourceResultForGridReceiverDto[],
  lastGridReceiverSourceSummingResults: SourceSummingResultForGridReceiverDto[] | null,
  selectedResultType: string,
  selectedSourcePointIds?: string[],
  selectedGridReceiverPointIds?: string[]
) => {
  const availableSources = getAvailableSources(lastGridResults);
  // If no source is selected we default to the first one in the list
  let sourcePointIds = selectedSourcePointIds?.length ? selectedSourcePointIds : [availableSources[0].id];

  let selectedSourceObject: SourceResultForGridReceiverDto | undefined;
  let selectedSourceSummingObject: SourceSummingResultForGridReceiverDto | undefined;

  if (sourcePointIds.length > 1) {
    selectedSourceSummingObject = lastGridReceiverSourceSummingResults?.find(
      (result) =>
        sourcePointIds.length === result.sources.length &&
        result.sources.every((s) => sourcePointIds.includes(s.id)) &&
        result.resultType === selectedResultType
    );

    // In case we didn't find a source summing object we default to the first source in the list (probably due to result type not being supported)
    sourcePointIds = selectedSourceSummingObject ? sourcePointIds : [availableSources[0].id];
  }

  if (selectedSourceSummingObject === undefined) {
    selectedSourceObject = lastGridResults?.find(
      (source) => source.sourcePointId === sourcePointIds[0] && source.resultType === selectedResultType
    );
  }

  const availableGridReceivers =
    selectedSourceObject || selectedSourceSummingObject
      ? getAvailableGridReceivers((selectedSourceSummingObject ?? selectedSourceObject)!)
      : [];

  // If no reciever is selected we default to them all selected
  const gridReceiverPointIds = selectedGridReceiverPointIds ?? availableGridReceivers.map((x) => x.id);

  const selectedGridReceiverObjects = selectedSourceObject
    ? selectedSourceObject?.gridReceiverResults?.filter((receiver) => gridReceiverPointIds.includes(receiver.pointId))
    : selectedSourceSummingObject?.gridReceiverResults?.filter((receiver) =>
        gridReceiverPointIds.includes(receiver.pointId)
      );

  return {
    selectedSourceObject,
    selectedSourceSummingObject,
    selectedGridReceiverObjects,
    availableSources,
    availableGridReceivers,
  };
};

/** Filter available result types based on what we have in the simulation results */
export const getAvailableResultTypes = (results: SourceResults[] | SourceResultForGridReceiverDto[]) => {
  const availableResultTypes: Array<ResultTypeOption> = [];

  resultTypes.forEach((x) => {
    if (results.some((r) => r.resultType === x.id)) {
      availableResultTypes.push(x);
    }
  });

  return availableResultTypes;
};

/** Sets the default result type when new results have been loaded.
 * If we already had a result type selected we try to keep that selection if it's in the list of available result types.
 * If nothing was selected previously we default to Hybrid.
 * If Hybrid is not in the simulation we default to whatever type we have there.
 */
export const getDefaultResultType = (availableResultTypes: Array<ResultTypeOption>, selectedResultType?: string) => {
  let defaultResultType = selectedResultType || ResultType.Hybrid;

  if (!availableResultTypes.some((x) => x.id === defaultResultType)) {
    defaultResultType = availableResultTypes[0].id;
  }

  return defaultResultType;
};

export const getSelectedSourcePointIdsBySelectedSourceObject = (
  selectedSourceObject?: SourceResults | SourceResultForGridReceiverDto,
  selectedSourceSummingObject?: SourceSummingResultDto | SourceSummingResultForGridReceiverDto
) => {
  let selectedSourcePointIds: string[] = [];

  if (selectedSourceObject) {
    selectedSourcePointIds = [selectedSourceObject.sourcePointId];
  } else if (selectedSourceSummingObject) {
    selectedSourcePointIds = selectedSourceSummingObject.sources.map((s) => s.id);
  }

  return selectedSourcePointIds;
};
