import { useQuery } from '@tanstack/react-query';

import { SpaceExtractionTask } from '@/components/MultiSpaceImport/types';

import { TaskStatus } from '@/types';

import axios from '@/axios';

let refetchInterval = 0;

const getTaskStatus = (task: TaskStatus) => {
  if (
    task.status === 'Pending' ||
    task.status === 'InProgress' ||
    task.status === 'Created' ||
    task.status === 'ProcessingResults'
  ) {
    return true;
  }

  return false;
};

const getLatestSpaceExtractionTasks = async (count = 10) => {
  const { data } = await axios.get(`/api/SpaceExtraction/GetLatestSpaceExtractionTasks?count=${count}`);

  return data;
};

export const useGetLatestSpaceExtractionTasks = (count?: number, enabled = true) =>
  useQuery<SpaceExtractionTask[], boolean>(
    ['latest-space-extraction-tasks'],
    () => getLatestSpaceExtractionTasks(count),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,

      refetchInterval,
      onSuccess: (data) => {
        let inProgress = false;
        for (const spaceExtractionTask of data) {
          inProgress = getTaskStatus(spaceExtractionTask.task);
          if (inProgress) break;
        }

        if (inProgress) {
          refetchInterval = 5000;
        } else {
          refetchInterval = 0;
        }
      },
    }
  );
