import { useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { TrblTooltip } from '@/components/Shared';
import { DeleteItemPopup } from '../LibraryPanel/components/DeleteItemPopup';
import { RevokeSharedPopup } from '../LibraryPanel/components/RevokeSharedPopup';
import { TrblDeleteIcon } from '../Icons';
import { ActionType as LibActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { SecondaryButton } from '../Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '../Shared/Popup';
import { TrblToggle } from '../Shared/TrblToggle';
import { MaterialDetailsContent } from './MaterialDetailsContent';

import { useOnDeleteMaterial } from '../MaterialLibrary/hooks/useOnDeleteMaterial';
import { useRevokeShareMaterial, useShareMaterial } from '@/hooks';

import { Material } from '@/types';

import styles from './styles.module.scss';

export const MaterialDetailsPopup = ({
  material,
  showPopup,
  setShowPopup,
}: {
  material: Material;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
}) => {
  const [isMaterialShared, setIsMaterialShared] = useState(material.isSharedWithOrganization);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRevokeMaterailPopup, setShowRevokeMaterailPopup] = useState(false);

  const { mutate: shareMaterial } = useShareMaterial();
  const { mutate: revokeShareMaterial } = useRevokeShareMaterial();
  const onDeleteMaterial = useOnDeleteMaterial();

  const {
    state: { userInfo },
  } = useBaseContext();
  const {
    appState: { filteredMaterials },
    dispatch,
  } = useAppContext();

  const { highlightedItemId, dispatch: libDispatch } = useLibraryPanelContext();

  const toggleShareMaterial = () => {
    const newValue = !isMaterialShared;
    if (newValue) {
      shareMaterialFunc();
    } else {
      setShowRevokeMaterailPopup(true);
    }
  };

  const shareMaterialFunc = () => {
    if (!material.id) return;
    shareMaterial(material.id, {
      onSuccess: (updatedMaterial) => {
        setIsMaterialShared(true);
        updateMaterials(updatedMaterial);
        toast.info("'" + updatedMaterial.name + "' has been shared with your organization!");
      },
      onError: () => {
        toast.error('Material could not be shared');
      },
    });
  };

  const revokeShared = () => {
    if (!material.id) return;
    revokeShareMaterial(material.id, {
      onSuccess: (updatedMaterial) => {
        setShowRevokeMaterailPopup(false);
        setIsMaterialShared(false);
        updateMaterials(updatedMaterial);
        toast.info("'" + updatedMaterial.name + "' is no longer shared with your organization.");
      },
      onError: () => {
        setShowRevokeMaterailPopup(false);
        toast.error('Sharing of material could not be revoked');
      },
    });
  };

  const updateMaterials = (updatedMaterial: Material) => {
    const materialIndex = filteredMaterials.findIndex((filteredMaterial) => filteredMaterial.id === material.id);
    const newMaterials = [...filteredMaterials];
    newMaterials[materialIndex] = updatedMaterial;
    dispatch({
      type: ActionType.SET_MATERIALS,
      payload: newMaterials,
    });

    if (updatedMaterial.id === highlightedItemId) {
      libDispatch({
        type: LibActionType.SET_HIGHLIGHTED_ITEM,
        highlightedItemId: updatedMaterial.id,
      });
    }
  };

  const onConfirmDelete = async () => {
    setShowDeleteConfirmation(false);
    await onDeleteMaterial(material.id, material.name);
    setShowPopup(false);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {material ? (
        <TrblPopup
          width="980px"
          hideBackdrop={false}
          aria-labelledby={'Material details'}
          sx={{ fontSize: '12px' }}
          onClose={handleClose}
          open={showPopup}>
          <TrblPopupTitle onClose={handleClose}>{'Material details'}</TrblPopupTitle>
          <TrblPopupContent>
            <MaterialDetailsContent material={material} />
          </TrblPopupContent>
          <TrblPopupActions>
            {material?.isUserCreated &&
              (material?.userId === userInfo?.id || (isMaterialShared && userInfo?.roles.includes('Admin'))) && (
                <div className={styles['share-material-action']}>
                  <TrblToggle
                    checked={isMaterialShared}
                    ariaLabel="Share material toggle"
                    onChangeToggle={toggleShareMaterial}
                  />
                  <span className={styles['share-text']}> Share with organization </span>
                </div>
              )}
            {material?.isUserCreated && material?.userId === userInfo?.id && (
              <TrblTooltip title="Delete material">
                <span>
                  <SecondaryButton
                    label=""
                    icon={<TrblDeleteIcon />}
                    onClick={() => setShowDeleteConfirmation(true)}></SecondaryButton>
                </span>
              </TrblTooltip>
            )}
          </TrblPopupActions>
        </TrblPopup>
      ) : null}
      {showDeleteConfirmation && (
        <DeleteItemPopup
          onCancelDelete={() => setShowDeleteConfirmation(false)}
          onConfirmDelete={onConfirmDelete}
          type="Material"
          name={material.name}
        />
      )}
      {showRevokeMaterailPopup && (
        <RevokeSharedPopup
          item="material"
          message={
            material?.userId !== userInfo?.id
              ? 'This material will no longer be accessible for you or other users and you will not be able to re-run simulations using this material.'
              : undefined
          }
          onConfirm={revokeShared}
          onCancel={() => setShowRevokeMaterailPopup(false)}
        />
      )}
    </>
  );
};
