import { FC, useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';

import { EmailTag } from './EmailTag';

import { scrollToBottom } from './utils';

import styles from './styles.module.scss';

type MultiEmailInputProps = {
  emails: string[];
  disabled?: boolean;
  onChange: (emails: string[]) => void;
};

const emailTag = (
  email: string,
  index: number,
  removeEmail: (index: number, isDisabled?: boolean | undefined) => void
) => {
  return <EmailTag key={email} index={index} email={email} removeEmail={removeEmail} />;
};

export const MultiEmailInput: FC<MultiEmailInputProps> = ({ emails, onChange, disabled = false }) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // A bit of a hacky hack to scroll to the bottom of the emails input container in case of overflow (so we can see what we are typing if we are adding more items)
    scrollToBottom('react-multi-email');
  }, [emails]);

  return (
    <ReactMultiEmail
      className={`${styles['emails-input']} ${isFocused ? styles['focused'] : ''} ${
        disabled ? styles['disabled'] : ''
      }`}
      placeholder="Input emails"
      emails={emails}
      onChange={(_emails: string[]) => {
        onChange(_emails);
      }}
      inputClassName={styles['input']}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      getLabel={emailTag}
    />
  );
};
