import { useState } from 'react';

import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TertiaryButton, TrblIconButton } from '@/components/Shared/Buttons';
import { Checkbox } from '@/components/Shared/Checkbox';
import { TextField } from '@/components/Shared/TextField';
import { TrblCancelledIcon, TrblCompletedIcon, TrblIcon } from '@/components/Icons';
import { Marker } from '@/components/SourceRecieverSettings/Marker';
import { MenuSubHeader } from './MenuSubHeader';
import { RenderedValue } from './RenderedValue';
import { SelectItem } from './SelectItem';
import { SumMarker } from './SumMarker';
import { SumMultipleSourcesMenuItem } from './SumMultipleSourcesMenuItem';

import { ResultComparisonLabels } from '../ResultComparison/constants';

import { SummedSourceOption } from '../../types';
import { BaseType } from '@/types';

import classes from './styles.module.scss';

interface SimulationFormSourceSelect {
  values: string[];
  availableSources: BaseType[];
  availableSummedSources: SummedSourceOption[];
  resultType: string;
  disabled?: boolean;
  sourceSummingAvailable?: boolean;
  sourceSummingDisabled: boolean;
  sourceSummingSelectionEnabled: boolean;
  selectedSourcesForSumming: string[];
  onChange: (sourcePointIds: string[]) => void;
  onChangeSelectedSourceForSumming: (value: string[]) => void;
  setSourceSummingSelectionEnabled: (value: boolean) => void;
  onStartSummingProcess: (name: string, sources: string[]) => void;
  onCancelSummingProcess: (summedSourceId: string, taskId: string) => void;
  onUpdateSourceSummingLabel: (summedSourceId: string, label: string) => void;
}

const CONTROL_ID = `select-source-control`;

export const SimulationFormSourceSelect = (formData: SimulationFormSourceSelect) => {
  const {
    values,
    availableSources,
    availableSummedSources,
    resultType,
    disabled,
    sourceSummingAvailable = true,
    sourceSummingDisabled,
    sourceSummingSelectionEnabled,
    selectedSourcesForSumming,
    onChange,
    onChangeSelectedSourceForSumming,
    setSourceSummingSelectionEnabled,
    onStartSummingProcess,
    onCancelSummingProcess,
    onUpdateSourceSummingLabel,
  } = formData;

  const [summedSourceInEditMode, setSummedSourceInEditMode] = useState<null | string>(null);
  const [summedSourceInEditModeLabel, setSummedSourceInEditModeLabel] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (sourceSummingSelectionEnabled) {
      onChangeSelectedSourceForSumming(event.target.value as string[]);
    } else {
      onChange(event.target.value as string[]);
    }
  };

  // Only applies when selecting sources for summing
  const handleSelectAll = () => {
    if (selectedSourcesForSumming.length === availableSources.length) {
      onChangeSelectedSourceForSumming([]);
    } else {
      onChangeSelectedSourceForSumming(availableSources.map((source) => source.id));
    }
  };

  const handleCancelLabelChange = () => {
    setSummedSourceInEditMode(null);
    setSummedSourceInEditModeLabel('');
  };

  const handleSaveLabel = () => {
    if (summedSourceInEditMode && summedSourceInEditModeLabel) {
      onUpdateSourceSummingLabel(summedSourceInEditMode, summedSourceInEditModeLabel);
    }

    handleCancelLabelChange();
  };

  const handleClose = () => {
    if (sourceSummingSelectionEnabled) {
      setSourceSummingSelectionEnabled(false);
    }

    if (summedSourceInEditMode) {
      handleCancelLabelChange();
    }
  };

  return (
    <FormControl fullWidth disabled={disabled} className={classes.simulation_form_control__container}>
      <InputLabel id={CONTROL_ID}> {ResultComparisonLabels.SOURCE} </InputLabel>
      <Select
        id={CONTROL_ID}
        labelId={CONTROL_ID}
        name={ResultComparisonLabels.SOURCE}
        label={ResultComparisonLabels.SOURCE}
        className={classes.treble_select}
        multiple={sourceSummingSelectionEnabled}
        value={sourceSummingSelectionEnabled ? selectedSourcesForSumming : values}
        displayEmpty
        onChange={handleChange}
        onClose={handleClose}
        renderValue={() => (
          <RenderedValue
            values={values}
            availableSources={availableSources}
            availableSummedSources={availableSummedSources}
          />
        )}>
        {sourceSummingAvailable && availableSources.length > 1 && (
          <SumMultipleSourcesMenuItem
            sourceSummingDisabled={sourceSummingDisabled}
            sourceSummingSelectionEnabled={sourceSummingSelectionEnabled}
            onEnableSourceSummingSelection={() => setSourceSummingSelectionEnabled(true)}
            onDisableSourceSummingSelection={() => setSourceSummingSelectionEnabled(false)}
            onStartSummingProcess={onStartSummingProcess}
            availableSources={availableSources}
            availableSummedSources={availableSummedSources}
            selectedSourcesForSumming={selectedSourcesForSumming}
            resultType={resultType}
          />
        )}
        {availableSources.length > 0 && availableSummedSources.length > 0 && (
          <MenuSubHeader
            title="Sources"
            showSelectAll={sourceSummingSelectionEnabled}
            allSourcesSelected={selectedSourcesForSumming.length === availableSources.length}
            onSelectAll={handleSelectAll}
          />
        )}
        {availableSources.map((option, index) => (
          <MenuItem
            key={option.id}
            value={sourceSummingSelectionEnabled ? option.id : [option.id]}
            className={classes.treble_select_menu}>
            {sourceSummingSelectionEnabled ? (
              <Checkbox
                id={option.id}
                label={
                  <SelectItem
                    label={option.name}
                    icon={<Marker color={'green'} label={(index + 1).toString()} small />}
                  />
                }
                spaceBetween={true}
                labelAlignment={'left'}
                isChecked={selectedSourcesForSumming.includes(option.id)}
              />
            ) : (
              <SelectItem label={option.name} icon={<Marker color={'green'} label={(index + 1).toString()} small />} />
            )}
          </MenuItem>
        ))}
        {availableSummedSources.length > 0 && <MenuSubHeader title="Summed sources" />}
        {availableSummedSources?.map((option, index) => {
          const inProgressTask = option.inProgressTasks.find((t) => t.resultType === resultType);
          const isInProgress = !!inProgressTask;
          const unsupportedResultType = !option.completedTasks.some((t) => t.resultType === resultType);
          const isBeingEdited = summedSourceInEditMode === option.id;
          const isDisabled = sourceSummingSelectionEnabled || unsupportedResultType || isInProgress;
          const canRerunSummingTask = unsupportedResultType && !isInProgress && !sourceSummingSelectionEnabled;
          const canCancelSummingTask = isInProgress && !sourceSummingSelectionEnabled;

          return isDisabled ? (
            <div
              key={option.id}
              className={`${classes.treble_select_menu} ${classes.item_with_button} ${classes.item_disabled}`}>
              <SelectItem
                label={option.name}
                icon={isInProgress ? <CircularProgress size={15} /> : <SumMarker order={index + 1} disabled={true} />}
              />
              {canRerunSummingTask && (
                <TrblTooltip title="Rerun summing for the selected result type">
                  <TertiaryButton
                    className={classes.menu_item_button}
                    style={{ padding: '0px 6px', minHeight: '22px', height: '22px', minWidth: '53px' }}
                    label="Rerun"
                    onClick={() => onStartSummingProcess(option.name, option.sourceIds)}
                  />
                </TrblTooltip>
              )}
              {canCancelSummingTask && (
                <TertiaryButton
                  color="#FD5B5B"
                  className={classes.menu_item_button}
                  style={{ padding: '0px 6px', minHeight: '22px', height: '22px', minWidth: '53px' }}
                  label="Cancel"
                  onClick={() => onCancelSummingProcess(option.id, inProgressTask.taskId)}
                />
              )}
            </div>
          ) : isBeingEdited ? (
            <div
              key={option.id}
              className={`${classes.treble_select_menu} ${classes.item_with_button} ${classes.item_disabled}`}>
              <Box component="div" display="flex" alignItems="center" gap="6px">
                <SumMarker order={index + 1} />
                <TextField
                  value={summedSourceInEditModeLabel}
                  onChange={setSummedSourceInEditModeLabel}
                  placeholder={option.name}
                  underlined
                  className={classes.edit_label_input}
                  autoFocus
                />
                <div className={classes.edit_label_input_controls}>
                  <TrblTooltip title={disabled ? 'No changes' : 'Confirm'}>
                    <span>
                      <TrblIconButton
                        size="small"
                        icon={<TrblCompletedIcon fill={disabled ? '#616161' : '#00F5BA'} height="14px" width="14px" />}
                        disabled={disabled}
                        onClick={handleSaveLabel}
                      />
                    </span>
                  </TrblTooltip>
                  <TrblTooltip title="Cancel">
                    <span>
                      <TrblIconButton
                        size="small"
                        icon={<TrblCancelledIcon fill="#fd5b5b" height="14px" width="14px" />}
                        onClick={handleCancelLabelChange}
                      />
                    </span>
                  </TrblTooltip>
                </div>
              </Box>
            </div>
          ) : (
            <MenuItem key={option.id} value={option.sourceIds} className={`${classes.treble_select_menu}`}>
              <SelectItem label={option.name} icon={<SumMarker order={index + 1} />} />
              <TrblTooltip title="Edit label">
                <span
                  className={classes.edit_label_icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSummedSourceInEditModeLabel(option.name);
                    setSummedSourceInEditMode(option.id);
                  }}>
                  <TrblIcon icon="edit" color="#999999" hoverColor="#DADADA" />
                </span>
              </TrblTooltip>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
