import { FREQUENCY_RANGE_THIRD_OCTAVE, FREQUENCY_RANGE_THIRD_OCTAVE_NUM } from '../constants';

import styles from '../styles.module.scss';

type ComplexInputTableProps = {
  firstRowLabel: string;
  secondRowLabel: string;
  data: Record<
    number,
    {
      real: number;
      imag: number;
    }
  >;
};

export const ComplexInputTable: React.FC<ComplexInputTableProps> = ({ firstRowLabel, secondRowLabel, data }) => {
  return (
    <table
      className={styles.material_input_table}
      style={{ marginBottom: Object.keys(data).length > 0 ? '-1px ' : '8px' }}>
      <tbody>
        <tr>
          <th className={styles.first_child}>
            <span className={styles.first_child_content}> Freq (Hz)</span>
          </th>
          {FREQUENCY_RANGE_THIRD_OCTAVE.map((freq: string) => (
            <th key={freq} className={styles.cell_center}>
              {freq}
            </th>
          ))}
        </tr>
        <tr>
          <td className={styles.first_child}>
            <span className={styles.first_child_content}>{firstRowLabel} </span>
          </td>
          {FREQUENCY_RANGE_THIRD_OCTAVE_NUM.map((freq, index) => (
            <td key={freq + index} className={styles.cell_center}>
              {data?.[freq]?.real}
            </td>
          ))}
        </tr>
        <tr>
          <td className={styles.first_child}>
            <span className={styles.first_child_content}>{secondRowLabel}</span>
          </td>
          {FREQUENCY_RANGE_THIRD_OCTAVE_NUM.map((freq, index) => (
            <td key={freq + index}>{data?.[freq]?.imag}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
