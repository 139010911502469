import { useRef } from 'react';

import { Box, Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblChevronDownIcon, TrblSourceDefinitionIcon } from '@/components/Icons';
import { SourceDefinitionLibraryPopup } from './SourceDefinitionLibraryPopup';

import { SourceDefinition } from '@/types';

import styles from './styles.module.scss';

export const SourceDefinitionInfo = ({
  sd,
  showDropdown = false,
  setShowDropdown,
  readonly = false,
}: {
  sd: SourceDefinition;
  showDropdown: boolean;
  setShowDropdown: (value: boolean) => void;
  readonly?: boolean;
}) => {
  const dropdownBtn = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Box component={'div'} display="flex" flexGrow={1} gap="20px" mb="20px">
        <Stack gap={1} width={'55%'}>
          <button
            disabled={readonly}
            ref={dropdownBtn}
            className={`${styles['dropdown-btn']} ${showDropdown ? styles['selected'] : ''}  ${
              readonly ? styles['readonly'] : ''
            }`}
            onClick={() => setShowDropdown(true)}>
            <Box component={'div'} display={'flex'} gap={'8px'}>
              <TrblSourceDefinitionIcon width="12" height="12" />
              {sd.manufacturer && sd.manufacturer + ': '} {sd.name}
            </Box>
            {!readonly && <TrblChevronDownIcon width="9" height="6" fill="#dadada" />}
          </button>
          <Box component={'div'} marginLeft={'10px'}>
            <Text type="semibold-12px">Type: </Text>
            <Text type="regular-12px">{sd.category}</Text>
          </Box>
          <Box component={'div'} marginLeft={'10px'}>
            <Text type="semibold-12px">Category: </Text>
            <Text type="regular-12px">{sd.subCategory}</Text>
          </Box>
        </Stack>
        <Stack gap={1} width={'45%'}>
          <Box component={'div'} maxHeight="80px" marginTop={'8px'} overflow="auto">
            <Text type="semibold-12px">Description: </Text>
            <Text type="regular-12px">{sd.description}</Text>
          </Box>
        </Stack>
      </Box>
      {dropdownBtn.current && (
        <SourceDefinitionLibraryPopup
          showPopup={showDropdown}
          setShowPopup={setShowDropdown}
          anchorEl={dropdownBtn.current}
        />
      )}
    </>
  );
};
