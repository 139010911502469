import { ChangeEventHandler, ReactNode } from 'react';

import { Box } from '@mui/material';

import { TrblNumberInput } from '@/components/Shared/NumberInput';

import { MAX_VALUE, MIN_VALUE, STEP_VALUE } from '../constants';

import '../style.scss';

type GainType = {
  inputValue: number | undefined;
  onHandleInputChange: (newValue: number | undefined) => void;
  onHandleSliderChange: ChangeEventHandler<HTMLInputElement>;
  children?: ReactNode;
};

export const Gain = ({ inputValue, onHandleInputChange, onHandleSliderChange, children }: GainType) => {
  return (
    <div className="source-column-content">
      <div className="source-column-left">
        <Box
          component={'div'}
          display="flex"
          justifyContent="start"
          alignItems={'center'}
          className="gain-number-input">
          <TrblNumberInput
            style={{ width: '50px', height: '22px', paddingRight: '20px' }}
            value={Number(inputValue)}
            step={STEP_VALUE}
            min={MIN_VALUE}
            max={MAX_VALUE}
            onChange={onHandleInputChange}
            endAdornment={'dB'}
            stepper={false}
          />
        </Box>
        {children}
      </div>
      <div className="source-column-right">
        <input
          className="slider-gain"
          type="range"
          value={inputValue}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={STEP_VALUE}
          onChange={onHandleSliderChange}></input>
      </div>
    </div>
  );
};
