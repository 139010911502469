import { useAppContext } from '@/context/AppContext';
import { useEditorContext } from '@/context/EditorContext';

import { TrblTooltip } from '@/components/Shared';
import { ActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';

import { useHandleRowClick } from './hooks/useHandleRowClick';

import { MaterialLayer } from '@/types';

const maxTextLength = 19;

export const MaterialNameCell = ({
  layer,
  isSelected,
  index,
  childIndex,
}: {
  layer: MaterialLayer;
  isSelected: boolean;
  index: number;
  childIndex?: number;
}) => {
  const { dispatch, isMaterialsLibraryOpen } = useLibraryPanelContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const handleRowClick = useHandleRowClick();
  const { readonly } = useEditorContext();

  const openMaterials = (event: React.MouseEvent<HTMLDivElement>, layer: MaterialLayer) => {
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }
    handleRowClick(event, layer, index, childIndex);
    // If the panel is already open and the user clicks on the same layer, close the panel
    const selectedMaterial = filteredMaterials.find((material) => material.id === layer?.materialId);
    dispatch({
      type: ActionType.SET_SELECTED_MATERIAL,
      material: selectedMaterial && selectedMaterial?.name !== 'unassigned' ? selectedMaterial : null,
      highlightedItemId: selectedMaterial ? selectedMaterial.id : null,
    });

    if (!isMaterialsLibraryOpen) {
      dispatch({
        type: ActionType.SET_MATERIALS_PANEL_OPEN,
        isOpen: true,
      });
    }
  };

  const materialName = layer.materialName ?? '';

  return (
    <div
      onClick={(event) => (!readonly ? openMaterials(event, layer) : {})}
      className="treble-layers-cell material-cell"
      role="gridcell"
      aria-label="material name">
      {materialName.length >= maxTextLength ? (
        <TrblTooltip disableInteractive title={materialName}>
          <p
            className={`material-name ${materialName === 'unassigned' || layer.isMissingMaterial ? 'unassigned' : ''} ${
              isSelected ? 'selected' : ''
            }`}>
            {materialName}
          </p>
        </TrblTooltip>
      ) : (
        <span
          className={`material-name  ${materialName === 'unassigned' || layer.isMissingMaterial ? 'unassigned' : ''} ${
            isSelected ? 'selected' : ''
          }`}>
          {materialName}
        </span>
      )}
    </div>
  );
};
