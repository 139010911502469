import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { PrimaryButton } from '@/components/Shared/Buttons';

import { useFitMaterial, useGetFitMaterial } from '@/hooks';

export const SimpleMaterialFit = () => {
  const { formDisabled, fullOctaveData, dispatch } = useCreateMaterialContext();

  const { mutate: fitMaterial, isLoading: isStartingMaterialFit } = useFitMaterial();
  const [ongoingMaterialFitId, setOngoingMaterialFitId] = useState<string | null>(null);

  const { data } = useGetFitMaterial(ongoingMaterialFitId, true);

  useEffect(() => {
    if (isStartingMaterialFit) {
      dispatch({
        type: ActionType.SET_DISABLED_FORM,
        formDisabled: true,
      });
    }
  }, [isStartingMaterialFit]);

  const handleFitMaterial = () => {
    // @ts-expect-error Type '(number | undefined)[]' is not assignable to type 'number[]'
    const inputTarget: number[] = Object.values(fullOctaveData);
    fitMaterial(inputTarget, {
      onSuccess: (fitResponse) => {
        if (fitResponse?.material_fit_id) {
          setOngoingMaterialFitId(fitResponse.material_fit_id);
        }
      },
      onError: () => {
        dispatch({
          type: ActionType.SET_DISABLED_FORM,
          formDisabled: false,
        });
      },
    });
  };

  useEffect(() => {
    if (ongoingMaterialFitId && data?.status === 40) {
      setOngoingMaterialFitId(null);
      dispatch({
        type: ActionType.SET_MATERIAL_FIT,
        materialFit: data,
      });
    }
  }, [ongoingMaterialFitId, data?.status]);

  return (
    <PrimaryButton
      width="fit-content"
      label="Start material fit"
      disabled={formDisabled || Object.keys(fullOctaveData).length !== 8}
      onClick={handleFitMaterial}
    />
  );
};
