import { FREQUENCY_RANGE_THIRD_OCTAVE_NUM, FULL_OCTAVES } from '../constants';

import { ComplexData, Frequency } from '../types';

export const parseInput = (input: string, isComplex: boolean = false): Record<number, number> | ComplexData => {
  const lines = input
    .trim()
    .split('\n')
    .filter((line) => line.trim() !== ''); // Split the input into lines and filter out empty lines
  const result: Record<number, number> | ComplexData = {};

  lines.forEach((line) => {
    let parts: string[];
    const matchOnWhitespace = line.trimStart().match(/\s+/);
    if (matchOnWhitespace) {
      parts = line.trim().split(/\s+/); // Split each line by whitespace
      // to account for no space, split on "," when matchOnWhitespace is true
      if (parts.length === 1) {
        parts = parts[0].split(/[\s,]+/);
      }
    } else {
      const isEuropeanFormat = line.includes(';');
      parts = isEuropeanFormat ? line.trim().split(/[\s;]+/) : line.trim().split(/[\s,]+/);
    }

    const freq = parts[0] ? parseFloat(parts[0].replace(',', '.')) : null;

    if (isComplex) {
      const real = parts[1] ? parseFloat(parts[1].replace(',', '.')) : NaN;
      const imag = parts[2] ? parseFloat(parts[2].replace(',', '.')) : NaN;
      if (freq !== null && !isNaN(freq) && FREQUENCY_RANGE_THIRD_OCTAVE_NUM.includes(freq)) {
        (result as ComplexData)[freq] = { real, imag };
      }
    } else {
      const value = parts[1] ? parseFloat(parts[1].replace(',', '.')) : null;
      if (freq !== null && value !== null && !isNaN(freq) && FULL_OCTAVES.includes(freq as Frequency)) {
        (result as Record<number, number>)[freq] = isNaN(value) ? 0.1 : value;
      }
    }
  });

  return result;
};
