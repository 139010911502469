import React from 'react';

import { Marker } from '@/components/SourceRecieverSettings/Marker';

import styles from './styles.module.scss';

export const Stepper = ({ steps, stepSelected }: { steps: string[]; stepSelected: number }) => {
  return (
    <div className={styles['stepper']}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={`${styles['step']} ${stepSelected === index ? styles['selected'] : ''} `}>
            <Marker color="green" label={(index + 1).toString()} isSelected={stepSelected === index} />
            <span className={styles['label']}>{step}</span>
          </div>
          {index < steps.length - 1 && <span className={styles['line']} key={`input_${index}`}></span>}
        </React.Fragment>
      ))}
    </div>
  );
};
