import { FC, useState } from 'react';

import { Stack } from '@mui/material';

import { TrblSearchIcon } from '../Icons';
import { TextField } from '../Shared/TextField';
import { TrblSelect } from '../Shared/TrblSelect';
import { GeometryImportButton } from './GeometryImportButton';
import { ViewTypeSwitch } from './ViewTypeSwitch';

import { PROJECT_FILTER_OPTIONS } from '../Shared/constants';

import styles from './styles.module.scss';

let delayTimer: ReturnType<typeof setTimeout>;

type DashboardHeaderProps = {
  projectFilter: string;
  setProjectFilter: (id: string) => void;
  setProjectSearchString: (id: string) => void;
  viewType: string;
  setViewType: (viewType: string) => void;
  minimized: boolean;
  setMinimized: (value: boolean) => void;
};

export const DashboardHeader: FC<DashboardHeaderProps> = ({
  projectFilter,
  setProjectFilter,
  setProjectSearchString,
  viewType,
  setViewType,
  minimized,
  setMinimized,
}) => {
  const [searchString, setSearchString] = useState('');

  const updateSearchString = (input: string) => {
    setSearchString(input);

    // delay search so we don't run the filtering function on every keystroke
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      setProjectSearchString(input);
    }, 200);
  };
  return (
    <div className={styles['projects-header-container']}>
      <Stack direction="row" alignItems="center" gap="2px" width="300px" marginRight="-160px">
        <div style={{ position: 'relative' }}>
          <span className={styles['search-icon']}>
            <TrblSearchIcon />
          </span>
          <TextField
            value={searchString}
            className={styles['search-input']}
            placeholder="Search projects"
            onChange={updateSearchString}
          />
        </div>

        <TrblSelect
          menuItems={PROJECT_FILTER_OPTIONS}
          value={projectFilter}
          setValue={setProjectFilter}
          style={{
            minWidth: projectFilter == 'all' ? '70px' : '136px',
            height: '30px',
            paddingTop: '1px',
            backgroundColor: '#1c1c1c',
            border: '0.5px solid #171717',
          }}
        />
      </Stack>

      <GeometryImportButton />

      <ViewTypeSwitch viewType={viewType} setViewType={setViewType} minimized={minimized} setMinimized={setMinimized} />
    </div>
  );
};
