import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { LoadAllSavedResultsLibrary } from './LoadAllSavedResultsLibrary';

import { useGetResultPresets } from '../hooks/useGetResultPresets';
import { useResultPresetActions } from '../hooks/useResultPresetActions';

const popupTitle = 'Saved results';
export const LoadAllSavedResultsPopup = ({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (shouldShowPopup: boolean) => void;
}) => {
  const { data: allResultPresets = [], isLoading: isLoadingSharedPresets } = useGetResultPresets();
  const { highlightedItemId } = useLibraryPanelContext();
  const { onLoadResultPreset } = useResultPresetActions();

  const onLoadPreset = () => {
    if (highlightedItemId) {
      onLoadResultPreset(highlightedItemId);
      setShowPopup(false);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <TrblPopup
      width={1040}
      height={'auto'}
      minheight={350}
      aria-labelledby={popupTitle}
      open={showPopup}
      onClose={handleClose}>
      <TrblPopupTitle onClose={handleClose}>{popupTitle}</TrblPopupTitle>
      <TrblPopupContent>
        {!isLoadingSharedPresets && (
          <LoadAllSavedResultsLibrary allResultPresets={allResultPresets} setShowPopup={setShowPopup} />
        )}
      </TrblPopupContent>
      <TrblPopupActions>
        <Box component="div" display="flex" width="100%" justifyContent={'flex-end'}>
          <PrimaryButton disabled={!highlightedItemId} width={'fit-content'} label="Open" onClick={onLoadPreset} />
        </Box>
      </TrblPopupActions>
    </TrblPopup>
  );
};
