import { cloneDeep } from 'lodash';

import { useAppContext } from '@/context/AppContext';
import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { useCreateSurfaceLayers } from '@/context/hooks/useCreateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useUpdateSimulationAndSettings } from './useUpdatedSimulationAndSettings';

import { Simulation } from '@/types';

export const useRevertSimulation = () => {
  const { currentModel3dLayerGroups } = useModelContext();
  const { mutate: updateSimulationAndSettings } = useUpdateSimulationAndSettings();

  const {
    dispatch,
    simulationState: { missingMaterials },
  } = useSimulationContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const { dispatch: editorDispatch } = useEditorContext();
  const { dispatch: libDispatch, isSourceDefinitionLibraryOpen, isMaterialsLibraryOpen } = useLibraryPanelContext();

  const createSurfaceLayers = useCreateSurfaceLayers();

  const revertSimulation = async (
    simToRevert: Simulation | null,
    saveChanges: boolean = true
  ): Promise<Simulation | undefined> => {
    if (simToRevert) {
      const copyOfSim = cloneDeep(simToRevert);
      const copyOfSimRun = cloneDeep(simToRevert.lastSimulationRun);

      if (copyOfSimRun) {
        document.querySelector('.revert-btn')?.classList.add('spinning');

        copyOfSim.solverSettings = copyOfSimRun.solverSettings;
        copyOfSim.settingsPreset = copyOfSimRun.settingsPreset !== null ? copyOfSimRun.settingsPreset : 2;
        copyOfSim.modelSettings = copyOfSimRun.modelSettings;
        copyOfSim.sources = copyOfSimRun.sources;
        copyOfSim.receivers = copyOfSimRun.receivers;
        copyOfSim.gridReceivers = copyOfSimRun.gridReceivers;
        copyOfSim.sourceParameters = copyOfSimRun.sourceParameters;

        copyOfSim.taskType = copyOfSimRun.taskType;
        copyOfSim.hasBeenEdited = false;

        editorDispatch({
          type: EditorActionType.CLEAR_SELECTED,
        });

        if (saveChanges) {
          updateSimulationAndSettings(copyOfSim!);
          editorDispatch({
            type: EditorActionType.SET_AUTO_SAVE_TEXT,
            text: 'Simulation reverted..',
          });
          if (isSourceDefinitionLibraryOpen || isMaterialsLibraryOpen) {
            libDispatch({
              type: LibActionType.CLOSE_LIBRARY_PANEL,
            });
          }
          // There is some re-rendering issue with the SidePanel. This is a hack to fix that buggy behavior
          setTimeout(() => {
            dispatch({
              type: ActionType.UPDATE_SELECTED_SIMULATION,
              simulation: copyOfSim,
            });
          }, 0);
        }

        // if the materials are not the same we need to re-render layers
        if (currentModel3dLayerGroups) {
          const { newLayers } =
            createSurfaceLayers(currentModel3dLayerGroups, copyOfSim, filteredMaterials, missingMaterials) || [];
          dispatch({
            type: ActionType.UPDATE_SURFACE_LAYERS,
            newSurfaceLayers: newLayers,
          });
        }

        document.querySelector('.revert-btn')?.classList.remove('spinning');
        return copyOfSim;
      }
    }
  };

  return revertSimulation;
};
