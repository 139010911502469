import { useSimulationContext } from '@/context/SimulationContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { TrblRevertSimIcon } from '../Icons';

import { useCreateSimulationCopy } from './hooks/useCreateSimulationCopy';
import { useDuplicateSimulation } from './hooks/useDuplicateSimulation';
import { useRevertSimulation } from './hooks/useRevertSimulation';

import { Simulation } from '@/types';

type EditSimulation = {
  updatedSimulation: Simulation | null;
  showEditConfirmation: boolean;
  setShowEditConfirmation: (showEditConfirmation: boolean) => void;
  onConfirmation: () => void;
};

export const EditSimulationPopup = ({
  updatedSimulation,
  showEditConfirmation,
  setShowEditConfirmation,
  onConfirmation,
}: EditSimulation) => {
  const {
    simulationState: { selectedSimulation, availableSimulations },
  } = useSimulationContext();

  const duplicateSimulation = useDuplicateSimulation();
  const createSimulationCopy = useCreateSimulationCopy();
  const revertSimulation = useRevertSimulation();

  return (
    <>
      {showEditConfirmation && (
        <ConfirmationDialog
          title="Edit this simulation?"
          confirmLabel="Duplicate"
          secondOptionLabel="Edit"
          message={() => (
            <>
              <span>
                You are about to enter <b>edit mode</b> for "{selectedSimulation?.name}", which means that your current
                results will become unavailable.
              </span>
              <br></br>
              <br></br>
              <span>
                Instead of entering edit mode on "{selectedSimulation?.name}", you can also{' '}
                <b>duplicate your simulation settings into a new simulation (Recommended)</b>.
              </span>
              <br></br>
              <br></br>
              <span>
                Note: Click&nbsp;
                <TrblRevertSimIcon height="14px" width="14px" opacity="0.7" />
                &nbsp;to access your previous simulation results again. If you however edit and run the simulation
                again, the previous results will be lost.
              </span>
            </>
          )}
          onConfirm={async () => {
            // duplicate the current simulation
            const duplicate = await duplicateSimulation(updatedSimulation);
            // revert the changes to the current simulation
            const revertedSim = await revertSimulation(selectedSimulation, false);

            if (duplicate && availableSimulations) {
              const newName = `${duplicate.name} - Copy`;

              if (revertedSim) {
                // get all the available simulations and filter out the reverted sim
                const filteredSims =
                  availableSimulations?.filter((simulations) => simulations.id !== revertedSim?.id) || [];
                const newSimulations = [...filteredSims, revertedSim];
                // create a new simulation that is a duplicate of the current simulation
                await createSimulationCopy(
                  duplicate,
                  duplicate.modelId,
                  newName,
                  duplicate.description,
                  newSimulations
                );
              }

              setShowEditConfirmation(false);
            }
          }}
          onSecondOption={() => {
            // edit this simulation
            onConfirmation();
            setShowEditConfirmation(false);
          }}
          onCancel={() => setShowEditConfirmation(false)}
        />
      )}
    </>
  );
};
