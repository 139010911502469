import { FC, useState } from 'react';
import { DateValidationError, DesktopDatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import { TrblCalendarIcon } from '@/components/Icons';

import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue';

type DatePickerProps = {
  required?: boolean;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  value: Dayjs | null;
  onChange?: (date: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
};

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  disabled = false,
  required = false,
  minDate,
  maxDate,
}) => {
  const [errorInternal, setErrorInternal] = useState<null | DateValidationError>(null);

  return (
    <DesktopDatePicker
      value={value}
      slots={{
        openPickerIcon: TrblCalendarIcon,
      }}
      slotProps={{
        openPickerButton: {
          size: 'small',
        },
        layout: {
          sx: {
            '.MuiDateCalendar-root': {
              borderRadius: '4px',
              backgroundColor: '#272727',
              '.MuiPickersDay-root': {
                background: '#1f1f1f',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.08)',
                },
                '&.Mui-selected': {
                  background: '#39BD9D',
                },
              },
            },
          },
        },
        textField: {
          required,
          error: errorInternal !== null || (required && !value),
          sx: {
            '& .MuiInputBase-root': {
              paddingRight: '6px',
              borderRadius: '2px',
              background: 'rgba(28, 28, 28, 0.98)',
              boxShadow: '0.5px 0.5px 0px 0px rgba(96, 96, 96, 0.08)',
              '& fieldset': {
                border: `0.5px solid #171717`,
              },
              '&:hover fieldset': {
                border: `0.5px solid #171717`,
              },
              '&.Mui-error:hover fieldset': {
                border: '0.5px solid #fd5b5b',
              },
              '&.Mui-focused fieldset': {
                border: `0.5px solid #2b8f77`,
              },
              '&.Mui-focused.Mui-error fieldset': {
                border: '0.5px solid #fd5b5b',
              },

              input: {
                width: '85px',
                padding: '4.5px 0px 4.5px 8px',
                color: '#DADADA',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '12px',
                letterSpacing: '0.22px',
                fontFeatureSettings: "'cv01' on, 'cv09' on, 'cv04' on, 'ss02' on, 'cv02' on",
              },
              '.MuiInputAdornment-root': {
                marginLeft: '0px',
              },
            },
          },
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      onChange={(value, context) => {
        setErrorInternal(context.validationError);

        if (onChange) {
          onChange(value, context);
        }
      }}
    />
  );
};
