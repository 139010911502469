import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Simulation } from '@/types';

import axios from '@/axios';

const getSimulationsByModelId = async (modelId: string) => {
  const { data } = await axios.get(`/api/Simulation/GetByModelId?modelId=${modelId}`);
  return data;
};

export const useGetSimulationsByModelId = (modelId: string, staleTime?: number) =>
  useQuery<Simulation[], AxiosError>(['simulation-by-model-id', modelId], () => getSimulationsByModelId(modelId), {
    enabled: !!modelId.length,
    refetchOnWindowFocus: false,
    staleTime,
    cacheTime: 0,
    onError: () => {
      toast.error(`Could not get simulations for model with id ${modelId}`, {
        className: 'editor-toast',
        toastId: `simulation-by-model-id-${modelId}`,
      });
    },
  });
