import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '@/axios';

const deleteOrganization = async (organizationId: string) => {
  await axios.delete(`/api/Organization/Delete`, {
    params: {
      organizationId,
    },
  });
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(async (organizationId: string) => await deleteOrganization(organizationId), {
    onSettled: () => queryClient.invalidateQueries(['organizations']),
    onError: () => toast.error('An error occurred while deleting the organization!'),
  });
};
