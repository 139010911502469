import { useEffect, useState, useTransition } from 'react';

import { useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { MaterialDetailsPreview } from './components/MaterialDetailsPreview';
import { MaterialsList } from './components/MaterialsList';
import { TrblMaterialIcon } from '../Icons/TrblMaterialIcon';
import { LibraryPanel } from '../LibraryPanel';
import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';

import { getGroupsByCategories } from './utils';

import { GroupMaterialAfterCategory } from '../LibraryPanel/types';
import { Material } from '@/types';

export const MaterialLibrary = () => {
  const { highlightedItemId } = useLibraryPanelContext();

  const {
    appState: { filteredMaterials, materialCategories },
  } = useAppContext();

  const {
    state: { userInfo },
  } = useBaseContext();

  const [materialGroupsToUse, setMaterialGroupsToUse] = useState<string[]>([]);
  const [availableMaterialsByGrouping, setAvailableMaterialsByGrouping] = useState<GroupMaterialAfterCategory>(
    {} as GroupMaterialAfterCategory
  );

  const [searchValues, setSearchValues] = useState<string[]>([]);
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();

  useEffect(() => {
    filterMaterialList(searchValues, categoryFilters);
  }, [searchValues, categoryFilters, filteredMaterials]);

  const createMaterialsGroupingForPanel = (materials: Material[], categories?: string[]) => {
    if (categories) {
      setMaterialGroupsToUse(categories);
    }

    const groupByCategory = getGroupsByCategories(materials, materialGroupsToUse, userInfo?.id, categories);

    return groupByCategory;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterMaterialList = (searchValues: string[], categories: any[]) => {
    let searchedList = filteredMaterials;
    if (searchValues && searchValues.length > 0) {
      searchedList = filteredMaterials.filter((material) => {
        let wordMatches = 0;
        const parsedName = material.name.toLowerCase();
        const parsedCategory = material.category.toLowerCase();

        for (const value of searchValues) {
          if (parsedName.includes(value) || parsedCategory.includes(value)) {
            wordMatches++;
          }
        }
        // if all words in searchValues produce wordMatches, then then this search is a match
        return wordMatches == searchValues.length;
      });
    }

    const materialGrouping = createMaterialsGroupingForPanel(searchedList, categories);

    startTransition(() => {
      setAvailableMaterialsByGrouping(materialGrouping);
    });
  };

  const searchingForMaterial = (key: string, inputValue: string) => {
    if (/^[ -~]+$/i.test(key)) {
      // split input value by whitespaces and remove empty strings
      const parsedValues = inputValue
        .toLowerCase()
        .split(' ')
        .filter((i) => i);

      setSearchValues(parsedValues);
    }
  };

  const selectMaterialCategories = (categories: string[]) => {
    setCategoryFilters(categories);
  };

  return (
    <LibraryPanel
      label="Materials"
      icon={<TrblMaterialIcon width="14" height="14" fill="#dadada" />}
      categories={materialCategories}
      onSearchLibrary={searchingForMaterial}
      onSelectCategories={selectMaterialCategories}>
      <>
        <MaterialsList availableMaterialsByGrouping={availableMaterialsByGrouping} />
        {highlightedItemId && <MaterialDetailsPreview materialId={highlightedItemId} />}
      </>
    </LibraryPanel>
  );
};
