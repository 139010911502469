import { ReceiverDirectionType } from '@/components/Results/context/ReflectogramResultsContext/types';

export const VIEW_3D_OPTIONS = [
  { id: 'shaded', name: 'Shaded' },
  { id: 'ghosted', name: 'Ghosted' },
  { id: 'wireframe', name: 'Wireframe' },
];

export const RECEIVER_DIRECTION_OPTIONS = [
  { id: ReceiverDirectionType.AlongX, name: 'Along X' },
  { id: ReceiverDirectionType.AlongY, name: 'Along Y' },
  { id: ReceiverDirectionType.TowardsSource, name: 'Towards source' },
  { id: ReceiverDirectionType.Custom, name: 'Custom' },
];

export const PERFORMANCE_INFO_TEXT = `Due to the high number of sources and receivers in your current setup, Performance Mode has been activated.`;
export const PERFORMANCE_INFO_TEXT_2 = `Please note that while this mode allows you to handle a larger scale of data, it may result in audible artifacts the first time you select a source/receiver combination.`;
