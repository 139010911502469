import { SimSrcRecHash } from '../types';

export const getRecHashById = (simId: string, receiverId: string, simSrcRecHash: SimSrcRecHash | null) => {
  try {
    const map = simSrcRecHash?.[simId]?.recHashes;
    return Object.keys(map).find((key) => map[key].id === receiverId);
  } catch (error) {
    console.error(error);
    return null;
  }
};
