import { FC, ReactNode } from 'react';

import { Box, CircularProgress, SxProps } from '@mui/material';

import styles from './styles.module.scss';

type BufferLoadingScreenProps = {
  message?: string | ReactNode;
  subMessage?: string | ReactNode;
  top?: number;
  left?: number;
  height?: string;
  width?: string;
  sx?: SxProps;
  zIndex?: number;
  showAnimation?: boolean;
};

export const BufferLoadingScreen: FC<BufferLoadingScreenProps> = ({
  message,
  subMessage,
  top = 1,
  left = 1,
  height,
  width,
  sx,
  zIndex = 2,
  showAnimation = true,
}) => {
  return (
    <Box
      className={styles['loading-screen']}
      component="div"
      height={height ?? 'calc(100% - ' + top * 2 + 'px)'}
      width={width ?? 'calc(100% - ' + left * 2 + 'px)'}
      top={top}
      left={left}
      zIndex={zIndex}
      sx={sx}>
      {showAnimation && <CircularProgress />}
      {message && <div className={styles['message']}>{message}</div>}
      {subMessage && <div className={styles['sub-message']}>{subMessage}</div>}
    </Box>
  );
};
