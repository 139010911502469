// Based on Lut FROM three/examples/jsm/math/Lut.js
// https://github.com/mrdoob/three.js/blob/master/examples/jsm/math/Lut.js

import { Color, MathUtils } from 'three';

class ColorBar {
  constructor(colormap, interpolate = true, count = 256) {
    this.isLut = true;

    this.lut = [];
    this.map = [];
    this.n = 0;
    this.minV = 0;
    this.maxV = 1;

    this.setColorMap(colormap, interpolate, count);
  }

  set(value) {
    if (value.isLut === true) {
      this.copy(value);
    }

    return this;
  }

  setMin(min) {
    this.minV = min;

    return this;
  }

  setMax(max) {
    this.maxV = max;

    return this;
  }

  // colormap: A key to the predefined colormap
  // count: The number of colors to generate (default is 256)
  setColorMap(colormap, interpolate = true, count = 256) {
    this.map = ColorMapKeywords[colormap] || ColorMapKeywords.rainbow;
    this.n = count;

    // Calculates the step size for interpolation, which is the distance between consecutive colors in the range from 0.0 to 1.0
    const step = 1.0 / this.n;
    const minColor = new Color();
    const maxColor = new Color();

    this.lut.length = 0;
    if (interpolate) {
      // Adds the first color in the colormap to the LUT. The color is converted from linear to sRGB color space before being stored
      this.lut.push(new Color(this.map[0][1]).convertLinearToSRGB());

      // sample at 1/n, ..., (n-1)/n
      // Starts a loop to generate count-1 colors by interpolating between the defined points in the colormap
      // The loop variable i represents the index of the color in the LUT
      for (let i = 1; i < count; i++) {
        //  Calculates the position alpha (a value between 0.0 and 1.0) where the color should be sampled
        const alpha = i * step;

        // Loops through the colormap to find the two points between which alpha lies
        // These points are used to interpolate the color.
        for (let j = 0; j < this.map.length - 1; j++) {
          // Checks if alpha lies between the jth and (j + 1)th points in the colormap
          // If it does, the code inside this block is executed
          if (alpha > this.map[j][0] && alpha <= this.map[j + 1][0]) {
            const min = this.map[j][0];
            const max = this.map[j + 1][0];

            // Sets the minColor and maxColor objects to the colors at j and j + 1 positions in the colormap, converting them to sRGB
            minColor.set(this.map[j][1]).convertLinearToSRGB();
            maxColor.set(this.map[j + 1][1]).convertLinearToSRGB();

            // Interpolates between minColor and maxColor to find the color at the position alpha
            // The interpolation factor is (alpha - min) / (max - min), which gives a value between 0.0 and 1.0
            // representing the relative position between min and max.
            const color = new Color().lerpColors(minColor, maxColor, (alpha - min) / (max - min));

            this.lut.push(color);
          }
        }
      }
    } else {
      // Non-interpolated colormap
      for (let i = 0; i < this.map.length - 1; i++) {
        const start = Math.floor(this.map[i][0] * this.n);
        const end = Math.floor(this.map[i + 1][0] * this.n);

        const color = new Color(this.map[i][1]).convertLinearToSRGB();

        for (let j = start; j < end; j++) {
          this.lut[j] = color;
        }
      }
    }

    this.lut.push(new Color(this.map[this.map.length - 1][1]).convertLinearToSRGB());

    return this;
  }

  copy(lut) {
    this.lut = lut.lut;
    this.map = lut.map;
    this.n = lut.n;
    this.minV = lut.minV;
    this.maxV = lut.maxV;

    return this;
  }

  getColor(alpha) {
    let newAlpha = MathUtils.clamp(alpha, this.minV, this.maxV);

    if (this.maxV - this.minV == 0) {
      newAlpha = 0;
    } else {
      newAlpha = (newAlpha - this.minV) / (this.maxV - this.minV);
    }

    const colorPosition = Math.round(newAlpha * this.n);

    return this.lut[colorPosition];
  }

  addColorMap(name, arrayOfColors) {
    ColorMapKeywords[name] = arrayOfColors;

    return this;
  }

  getMin() {
    return this.minV;
  }

  getMax() {
    return this.maxV;
  }

  createCanvas(interpolate = true) {
    // eslint-disable-next-line no-undef
    const canvas = document.createElement('canvas');
    canvas.height = 1;
    canvas.width = this.n;

    this.updateCanvas(canvas, interpolate);

    return canvas;
  }

  updateCanvas(canvas, interpolate = true) {
    const ctx = canvas.getContext('2d', { alpha: false });
    const imageData = ctx.getImageData(0, 0, this.n, 1);
    const data = imageData.data;
    let k = 0;

    if (interpolate) {
      // Gradient mode
      const step = 1.0 / (this.n - 1);
      const minColor = new Color();
      const maxColor = new Color();
      const finalColor = new Color();

      for (let i = 0; i <= 1; i += step) {
        for (let j = this.map.length - 1; j > 0; j--) {
          if (i >= this.map[j - 1][0] && i < this.map[j][0]) {
            const min = this.map[j - 1][0];
            const max = this.map[j][0];

            minColor.set(this.map[j - 1][1]);
            maxColor.set(this.map[j][1]);

            finalColor.lerpColors(minColor, maxColor, (i - min) / (max - min));

            data[k * 4] = Math.round(finalColor.r * 255);
            data[k * 4 + 1] = Math.round(finalColor.g * 255);
            data[k * 4 + 2] = Math.round(finalColor.b * 255);
            data[k * 4 + 3] = 255;

            k += 1;
            break;
          }
        }
      }
    } else {
      // Section mode
      for (let i = 0; i < this.map.length - 1; i++) {
        const start = Math.floor(this.map[i][0] * this.n);
        const end = Math.floor(this.map[i + 1][0] * this.n);

        const color = new Color(this.map[i][1]);

        for (let j = start; j < end; j++) {
          data[j * 4] = Math.round(color.r * 255);
          data[j * 4 + 1] = Math.round(color.g * 255);
          data[j * 4 + 2] = Math.round(color.b * 255);
          data[j * 4 + 3] = 255;
        }

        k = end;
      }

      // Fill the remaining space with the last color
      const color = new Color(this.map[this.map.length - 1][1]);
      while (k < this.n) {
        data[k * 4] = Math.round(color.r * 255);
        data[k * 4 + 1] = Math.round(color.g * 255);
        data[k * 4 + 2] = Math.round(color.b * 255);
        data[k * 4 + 3] = 255;
        k += 1;
      }
    }

    ctx.putImageData(imageData, 0, 0);

    return canvas;
  }
}

const ColorMapKeywords = {
  // roma and grayC come from https://github.com/justdan96/fabio-crameri-palette-generator/blob/main/colormaps.js
  // and https://www.fabiocrameri.ch/colourmaps/
  romaInverted: [
    [0.0, 0x7e1700],
    [0.1, 0x974d13],
    [0.2, 0xab7525],
    [0.3, 0xc0a141],
    [0.4, 0xd2d17e],
    [0.5, 0xc4eabe],
    [0.6, 0x90ded7],
    [0.7, 0x53b8d1],
    [0.8, 0x308fc1],
    [0.9, 0x2167b0],
    [1.0, 0x0c3b9c],
  ],
  roma: [
    [0.0, 0x0c3b9c],
    [0.1, 0x2167b0],
    [0.2, 0x308fc1],
    [0.3, 0x53b8d1],
    [0.4, 0x90ded7],
    [0.5, 0xc4eabe],
    [0.6, 0xd2d17e],
    [0.7, 0xc0a141],
    [0.8, 0xab7525],
    [0.9, 0x974d13],
    [1.0, 0x7e1700],
  ],
  grayCInverted: [
    [0.0, 0xffffff],
    [0.09, 0xefefef],
    [0.21, 0xd7d7d7],
    [0.32, 0xb9b9b9],
    [0.41, 0x9b9b9b],
    [0.5, 0x838383],
    [0.59, 0x6b6b6b],
    [0.71, 0x535353],
    [0.82, 0x353535],
    [0.91, 0x171717],
    [1.0, 0x000000],
  ],
  grayC: [
    [0.0, 0x000000],
    [0.09, 0x171717],
    [0.21, 0x353535],
    [0.32, 0x535353],
    [0.41, 0x6b6b6b],
    [0.5, 0x838383],
    [0.59, 0x9b9b9b],
    [0.71, 0xb9b9b9],
    [0.82, 0xd7d7d7],
    [0.91, 0xefefef],
    [1.0, 0xffffff],
  ],
  rainbow: [
    [0.0, 0x191c66],
    [0.25, 0x1a87fa],
    [0.5, 0x1edef0],
    [0.75, 0xffda59],
    [1.0, 0xf8487d],
  ],
  viridis: [
    [0.0, '#440154'], // Dark blue
    [0.25, '#31688e'], // Cyan
    [0.5, '#21918c'], // Green
    [0.75, '#35b779'], // Yellowish-green
    [1.0, '#fde725'], // Bright yellow
  ],
  cooltowarm: [
    [0.0, 0x3c4ec2],
    [0.2, 0x9bbcff],
    [0.5, 0xdcdcdc],
    [0.8, 0xf6a385],
    [1.0, 0xb40426],
  ],
  blackbody: [
    [0.0, 0x000000],
    [0.2, 0x780000],
    [0.5, 0xe63200],
    [0.8, 0xffff00],
    [1.0, 0xffffff],
  ],
  grayscale: [
    [0.0, 0x000000],
    [0.2, 0x404040],
    [0.5, 0x7f7f80],
    [0.8, 0xbfbfbf],
    [1.0, 0xffffff],
  ],
  sti: [
    [0.0, 0xff2e2e],
    [0.3, 0xff4d4d],
    [0.45, 0xff9214],
    [0.6, 0xfefe00],
    [0.75, 0x29cf32],
    [1.0, 0x29cf32],
  ],
  targetValue: [
    [0, 0x39bd9d],
    [1.0, 0x39bd9d],
  ],
};

export { ColorBar, ColorMapKeywords };
