export enum NewSimulationTypes {
  New = 'New',
  Copy = 'Copy',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_SIM: any = {
  modelId: '',
  name: '',
  description: '',
  settingsPreset: 1,
  modelSettings: {
    materialIdByObjectId: {},
    scatteringByObjectId: {},
  },
  solverSettings: {
    dgSettings: {
      crossoverFrequency: 710,
      impulseLengthSeconds: 1.0,
      sourceWidth: null,
      basisOrder: 4,
      cfl: 1.0,
      precision: 0,
      gpuCount: 1,
    },
    gaSettings: {
      crossoverFrequency: 710,
      impulseLengthSeconds: 1.0,
      frequencyBands: [125, 250, 500, 1000, 2000, 4000, 8000],
      generateIR: true,
      maxReflections: 100,
      reflectionOrder: 2,
      methodType: 0,
      rayCount: 10000,
      receiverDiameter: 0.3,
    },
  },
};
