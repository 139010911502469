import { ComplexData } from '../types';

export const getValuesByPropName = (propName: 'real' | 'imag', input: ComplexData): number[] => {
  const imagValues: number[] = []; // Initialize an empty array to hold the imag values

  for (const key in input) {
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(key)) {
      imagValues.push(input[key][propName]); // Push the imag value to the array
    }
  }

  return imagValues; // Return the array of imag values
};
