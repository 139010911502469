import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { SourceDefinitionImportStatusDto } from '@/types';

import axios from '@/axios';

const MAX_POLL = 60;

const getSourceDefinitionStatus = async (
  sourceDefinitionId: string | null,
  shouldPoll: boolean
): Promise<SourceDefinitionImportStatusDto> => {
  if (shouldPoll && sourceDefinitionId) {
    const { data } = await axios.get(`/api/SourceDefinition/GetSourceDefinitionImportStatus`, {
      params: {
        sourceId: sourceDefinitionId,
      },
    });

    return data;
  } else {
    throw new Error('this is error');
  }
};

export const useGetSourceDefinitionStatus = (sourceDefinitionId: string | null, shouldRefetch: boolean) => {
  return useQuery<SourceDefinitionImportStatusDto, boolean>(
    ['source-definition-id', sourceDefinitionId],
    () => getSourceDefinitionStatus(sourceDefinitionId, shouldRefetch),
    {
      enabled: !!sourceDefinitionId && shouldRefetch,
      refetchOnWindowFocus: false,
      // Refetch the data every second
      refetchInterval: (data, query) => {
        if (query.state.dataUpdateCount > MAX_POLL || data?.importStatus.status === 'Completed') {
          if (query.state.dataUpdateCount > MAX_POLL) {
            toast.error('Creating source definition timed out. Please try again.');
          }
          return false;
        } else if (data?.importStatus.status === 'Error') {
          toast.error('An error occurred while creating the source definition. Please try again.', {
            toastId: 'source_definition_polling_error',
          });
          return false;
        } else {
          return 1000;
        }
      },
      onError: () => {
        toast.error('An error occurred while creating the source definition.');
      },
    }
  );
};
