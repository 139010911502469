import { useEffect, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { CreateNewSimulationPopup } from '../CreateNewSimulationPopup';
import { TertiaryButton } from '../Shared/Buttons';
import { SpeechBubble } from '../SpeechBubble';
import { Welcome } from '../Welcome';

import { isExampleModelCheck } from './utils';

export const EmptySimulations = () => {
  const { modelInformation } = useModelContext();
  const { dispatch, isPopupOpen, isCreateMenuOpen } = useEditorContext();
  const [showSimulationPopup, setShowSimulationPopup] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [isExampleModel, setIsExampleModel] = useState<boolean | null>(null);

  useEffect(() => {
    if (modelInformation) {
      setIsExampleModel(isExampleModelCheck(modelInformation));
    }
  }, [modelInformation]);

  useEffect(() => {
    if (isExampleModel) {
      if (!isCreateMenuOpen) {
        setShowInstructions(!isPopupOpen);
      } else {
        setShowInstructions(!isCreateMenuOpen);
      }
    }
  }, [isCreateMenuOpen, isPopupOpen]);

  // For keyboard navigation we need to keep track globally when popups
  // are open that should have their own keyboard navigation or shortcuts
  useEffect(() => {
    dispatch({
      type: ActionType.SET_IS_POPUP_OPEN,
      isOpen: showSimulationPopup,
    });
  }, [showSimulationPopup]);

  return isExampleModel === false ? (
    <>
      <div className="new-simulation-div">
        <h3>Hi there! You have not created any simulations here yet.</h3>
        <p>
          To create a simulation you can click the
          <br /> <span style={{ color: '#00f5ba' }}>New</span> button in the header and choose{' '}
          <span style={{ fontWeight: '600' }}>Simulation</span>
        </p>
        <p> ...or just go ahead and click the button below. </p>
        <TertiaryButton
          className="add-simulation-btn"
          label="Create new simulation"
          onClick={() => setShowSimulationPopup(true)}
        />
      </div>
      {showSimulationPopup && <CreateNewSimulationPopup setShowPopup={setShowSimulationPopup} />}
    </>
  ) : isExampleModel === true ? (
    <>
      <Welcome isNewUser={true} isExampleModel={true} />
      <SpeechBubble
        top={20}
        left={-240}
        width={220}
        className={showInstructions ? '' : 'hide'}
        content={
          <div>
            To create a simulation click the <span style={{ color: '#00f5ba' }}>New</span> button and choose{' '}
            <span style={{ fontWeight: '600' }}>Simulation</span>
          </div>
        }></SpeechBubble>
    </>
  ) : null;
};
