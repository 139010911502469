import { useEffect, useState } from 'react';

import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { Checkbox } from '@/components/Shared/Checkbox';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblAzimuthIcon, TrblElevationIcon } from '@/components/Icons';
import { ActionType, ReceiverDirectionType } from '@/components/Results/context/ReflectogramResultsContext/types';

import { RECEIVER_DIRECTION_OPTIONS } from './constants';

import styles from './styles.module.scss';

export const ReceiverDirectionOptions: React.FC = () => {
  const {
    state: { selectedReceiverDirection },
    dispatch: reflectogramDispatch,
  } = useReflectogramResultsContext();

  const [applyToAllReceivers, setApplyToAllReceivers] = useState<boolean>(true);

  // We keep the azimuth and elevation in state since we only update the context on blur (not on change)
  const [azimuth, setAzimuth] = useState<number | undefined>(selectedReceiverDirection?.azimuth ?? 0);
  const [elevation, setElevation] = useState<number | undefined>(selectedReceiverDirection?.elevation ?? 0);

  useEffect(() => {
    if (selectedReceiverDirection?.azimuth !== undefined && selectedReceiverDirection.azimuth !== azimuth) {
      setAzimuth(selectedReceiverDirection.azimuth);
    }
  }, [selectedReceiverDirection?.azimuth]);

  useEffect(() => {
    if (selectedReceiverDirection?.elevation !== undefined && selectedReceiverDirection.elevation !== elevation) {
      setElevation(selectedReceiverDirection.elevation);
    }
  }, [selectedReceiverDirection?.elevation]);

  const handleReceiverDirectionChange = (type: ReceiverDirectionType, azimuth?: number, elevation?: number) => {
    if (selectedReceiverDirection) {
      if (type === ReceiverDirectionType.Custom && azimuth !== undefined && elevation !== undefined) {
        reflectogramDispatch({
          type: ActionType.SET_RECEIVER_DIRECTION,
          simulationId: selectedReceiverDirection.simulationId,
          receiverId: !applyToAllReceivers ? selectedReceiverDirection.receiverId : undefined,
          direction: { type, azimuth, elevation },
        });
      } else if (type !== ReceiverDirectionType.Custom) {
        reflectogramDispatch({
          type: ActionType.SET_RECEIVER_DIRECTION,
          simulationId: selectedReceiverDirection.simulationId,
          receiverId: !applyToAllReceivers ? selectedReceiverDirection.receiverId : undefined,
          direction: { type },
        });
      }
    }
  };

  const handleReceiverDirectionTypeChange = (value: string) => {
    handleReceiverDirectionChange(
      value as ReceiverDirectionType,
      selectedReceiverDirection?.azimuth,
      selectedReceiverDirection?.elevation
    );
  };

  const handleReceiverAzimuthBlur = (value?: number) => {
    if (value !== undefined && selectedReceiverDirection && value !== selectedReceiverDirection.azimuth) {
      handleReceiverDirectionChange(selectedReceiverDirection.type, value, elevation);
    }
  };

  const handleReceiverElevationBlur = (value?: number) => {
    if (value !== undefined && selectedReceiverDirection && value !== selectedReceiverDirection.elevation) {
      handleReceiverDirectionChange(selectedReceiverDirection.type, selectedReceiverDirection?.azimuth, value);
    }
  };

  const handleApplyToAllReceivers = (value: boolean) => {
    setApplyToAllReceivers(value);

    // If we apply to all receivers, we update the direction for all receivers in the simulation
    if (value && selectedReceiverDirection) {
      handleReceiverDirectionChange(
        selectedReceiverDirection.type,
        selectedReceiverDirection?.azimuth,
        selectedReceiverDirection?.elevation
      );
    }
  };

  return selectedReceiverDirection ? (
    <div className={styles['reflectogram-receiver-direction']}>
      <Text type="medium-12px" style={{ marginLeft: '9px' }}>
        Receiver direction
      </Text>
      <div style={{ display: 'flex', gap: '8px' }}>
        <TrblSelect
          value={selectedReceiverDirection.type}
          setValue={handleReceiverDirectionTypeChange}
          menuItems={RECEIVER_DIRECTION_OPTIONS}
          minimal
          style={{
            width: selectedReceiverDirection.type === ReceiverDirectionType.Custom ? '265px' : '135px',
            height: '24px',
            fontSize: '11px',
            backgroundColor: 'transparent',
          }}
        />
        {selectedReceiverDirection.type === ReceiverDirectionType.Custom && (
          <div className={styles['custom-direction-inputs']}>
            <TrblNumberInput
              value={azimuth}
              step={15}
              decimals={2}
              onChange={setAzimuth}
              onBlur={handleReceiverAzimuthBlur}
              startAdornment={<TrblAzimuthIcon fill={'#dadada'} fillExtra="#dadada" />}
              endAdornment={'°'}
              alignment={'right'}
              min={0}
              max={360}
              underlined
              style={{ width: '80px' }}
            />
            <TrblNumberInput
              value={elevation}
              step={15}
              decimals={2}
              onChange={setElevation}
              onBlur={handleReceiverElevationBlur}
              startAdornment={<TrblElevationIcon fill={'#dadada'} />}
              endAdornment={'°'}
              alignment={'right'}
              min={-90}
              max={90}
              style={{ width: '80px' }}
              underlined
            />
          </div>
        )}
        <Checkbox
          id={'applyToAllReceivers'}
          onChange={handleApplyToAllReceivers}
          isChecked={applyToAllReceivers}
          label="Apply to all receivers"
          title="Apply to all receivers"
          style={{ background: 'none' }}
          checkboxColor="white"
        />
      </div>
    </div>
  ) : null;
};
