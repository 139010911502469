import { ListColumnProps } from './types';

import styles from './styles.module.scss';

export const LayersListHeader = ({
  listHasChildren,
  columns,
}: {
  listHasChildren: boolean;
  columns: ListColumnProps[];
}) => {
  return (
    <div className={styles['list-header']}>
      {listHasChildren && <div className={styles['expand-collapse-cell']}></div>}
      {columns.map((column) => (
        <div
          key={column.fieldName}
          className={styles['header']}
          style={{ width: column.width, flex: column.width ? undefined : '1 1 0', ...column.style }}>
          {column.headerLabel ?? ''}
        </div>
      ))}
    </div>
  );
};
