import { ChargebeeInstance, PortalCallbacks } from '@chargebee/chargebee-js-types';

export const getPortalOptions = (
  chargebeeInstance: ChargebeeInstance,
  onSubscriptionChanged: () => void
): PortalCallbacks => ({
  loaded: () => console.log('Portal loaded'),
  close: () => {
    console.log('Portal close');
    chargebeeInstance.logout();
  },
  visit: (sectionType: string) => console.log('Portal visit', sectionType),
  paymentSourceAdd: () => console.log('Portal payment source add'),
  paymentSourceUpdate: () => console.log('Portal payment source add'),
  paymentSourceRemove: () => console.log('Portal payment source add'),
  subscriptionChanged: (data: { subscription: { id: string } }) => {
    console.log('Portal subscription changed', data);
    onSubscriptionChanged();
  },
  subscriptionCustomFieldsChanged: (data: { subscription: { id: string } }) => {
    console.log('Portal subscription custom fields changed', data);
    onSubscriptionChanged();
  },
  subscriptionCancelled: (data: { subscription: { id: string } }) => {
    console.log('Portal subscription cancelled', data);
    onSubscriptionChanged();
  },
  subscriptionResumed: (data: { subscription: { id: string } }) => console.log('Portal subscription resumed', data),
  subscriptionPaused: (data: { subscription: { id: string } }) => console.log('Portal subscription paused', data),
  scheduledPauseRemoved: (data: { subscription: { id: string } }) => console.log('Portal schedule pause removed', data),
  scheduledCancellationRemoved: (data: { subscription: { id: string } }) => {
    console.log('Portal schedule cancellation removed', data);
    onSubscriptionChanged();
  },
  subscriptionReactivated: (data: { subscription: { id: string } }) =>
    console.log('Portal subscription reactivated', data),
});
