import './styles.scss';

export const TableHeader = () => {
  return (
    <div className="treble-layers-header" role="rowgroup">
      <div className="treble-layers-cell first-cell"></div>
      <div className="treble-layers-cell layer-cell" role="columnheader">
        <span>Layer</span>
      </div>
      <div className="treble-layers-cell material-cell" role="columnheader">
        <span>Material</span>
      </div>
      <div className="treble-layers-cell scatter-cell" role="columnheader">
        <span>Scatter</span>
      </div>
      <div className="treble-layers-cell last-cell" role="columnheader">
        {' '}
      </div>
    </div>
  );
};
