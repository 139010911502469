import { SyntheticEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { NameMarkerRow } from '@/components/Shared/NameMarker';
import { ShareProjectButton } from '@/components/Shared/ShareProjectButton/ShareProjectButton';
import { TrblChevronRight, TrblSharedProjectIcon } from '@/components/Icons';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { ManageAccessPopup } from './ManageAccessPopup';

import { IActions, ProjectAndUsersDto } from '@/types';

import classes from '../styles.module.scss';

interface ProjectGroupHeaderSharingProps {
  project: ProjectAndUsersDto;
  loggedInUserId: string;
  getProjectActions: (project: ProjectAndUsersDto) => IActions[];
  handleSetMinimized: (e: SyntheticEvent) => void;
  projectSearchString?: string;
}

export const ProjectGroupHeader = ({
  project,
  loggedInUserId,
  getProjectActions,
  handleSetMinimized,
  projectSearchString = '',
}: ProjectGroupHeaderSharingProps) => {
  const queryClient = useQueryClient();

  const projectLink = useRef<HTMLAnchorElement>(null);

  const [selected, setSelected] = useState(false);
  const [showMembersPopup, setShowMembersPopup] = useState(false);

  const openMembers = (event: SyntheticEvent) => {
    event.stopPropagation();
    setShowMembersPopup(true);
    setSelected(true);
  };

  const closeManageAccessPopup = (projectUpdated?: boolean) => {
    setShowMembersPopup(false);
    setSelected(false);
    if (projectUpdated) {
      queryClient.invalidateQueries(['projects']);
    }
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && event.target.tagName == 'DIV') {
      event.preventDefault();
      if (handleSetMinimized) handleSetMinimized(event);
    }
  }

  function handleKeyClickProjectHeader(event: SyntheticEvent) {
    // setTimout to not be triggered if header is double clicked
    setTimeout(() => handleSetMinimized(event), 150);
  }

  return (
    <>
      <div
        className={`${classes.project_header} ${classes.sharing} ${selected ? classes.selected : ''} `}
        onClick={handleKeyClickProjectHeader}
        onKeyDown={handleKeyDown}
        onDoubleClick={() => projectLink.current?.click()}
        tabIndex={0}>
        <TrblTooltip title="Open project">
          <Link ref={projectLink} to={'/project/' + project.id} className={classes.project_label}>
            <span>
              <HighlightedText text={project.name} highlight={projectSearchString} highlightColor="#00f5ba" />
            </span>

            {project.createdBy !== loggedInUserId && (
              <TrblTooltip title="Project shared with you">
                <span className={classes.shared_project_icon}>
                  <TrblSharedProjectIcon fill="#dadada" />
                </span>
              </TrblTooltip>
            )}
            <span className={classes.open_project_icon} style={{ margin: '-1px 0 0 2px' }}>
              <TrblChevronRight />
            </span>
          </Link>
        </TrblTooltip>

        <div className={classes.project_actions}>
          {project.projectUsers.length > 1 && (
            <NameMarkerRow
              projectUsers={project.projectUsers}
              loggedInUserId={loggedInUserId}
              creatorId={project.createdBy}
              background="#313131"
            />
          )}

          <ShareProjectButton onClick={openMembers} />

          <TrblActionsMenu
            classNames={classes.project_menu}
            actions={getProjectActions(project)}
            id={project.id}
            title="View project actions"
            size="x-large"
          />
        </div>
      </div>
      {showMembersPopup && <ManageAccessPopup onClose={closeManageAccessPopup} project={project} />}
    </>
  );
};
