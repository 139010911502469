import { SubscriptionContent, SubscriptionType } from './types';

export const SUBSCRIPTIONS: { [key: string]: SubscriptionContent } = {
  flexible: {
    name: 'Flexible',
    description: 'For small businesses and occasional users',
    yearlyPrice: '€119',
    monthlyPrice: '€149',
    per: 'month',
    includedMonthly: ['1 seat included', '€99 /month per added seat'],
    includedYearly: ['1 seat included', '€79 /month per added seat'],
    highlight: '10 tokens included on signup',
    tokenBundlePricing: ['10 tokens 100 EUR', '25 Tokens 240 EUR', '50 tokens for 475 EUR', '2 year expiry on bundles'],
    access: ['Access to all features', 'Access to live chat and email support'],
    actionText: 'Select',
  },
  unlimited: {
    name: 'Unlimited',
    description: 'For frequent and expert users',
    yearlyPrice: '€239',
    monthlyPrice: '€299',
    per: 'month',
    includedMonthly: ['1 seat included', '€199 /month per added seat'],
    includedYearly: ['1 seat included', '€159 /month per added seat'],
    highlight: 'Unlimited simulations',
    access: ['Access to all features', 'Access to live chat and email support'],
    actionText: 'Select',
  },
  enterprise: {
    name: 'Enterprise',
    description: 'Your custom enterprise solution',
    yearlyPrice: '5+ seats',
    per: 'organization',
    includedMonthly: [
      'Recommended for organizations who wish to discuss specific needs and optional access to the Treble SDK.',
    ],
    includedYearly: [
      'Recommended for organizations who wish to discuss specific needs and optional access to the Treble SDK.',
    ],
    access: ['Access to all features', 'Optional access to the Treble SDK', 'Access to live chat and email support'],
    actionText: 'Contact us',
    actionLink: 'https://www.treble.tech/contact?utm_campaign=Pricing&utm_source=ENTERPRISE',
  },
};

export const PLANS: {
  [key in SubscriptionType]: any;
} = {
  flexible: {
    planId: 'treble-pro-flexible',
    monthly: {
      priceId: 'treble-pro-flexible-EUR-Monthly',
      addonId: 'treble-pro-flexible-additional-seats-v2-EUR-Monthly',
    },
    yearly: {
      priceId: 'treble-pro-flexible-EUR-Yearly',
      addonId: 'treble-pro-flexible-additional-seats-v2-EUR-Yearly',
    },
  },
  unlimited: {
    planId: 'treble-pro-ultimate',
    monthly: {
      priceId: 'treble-pro-ultimate-EUR-Monthly',
      addonId: 'treble-pro-ultimate-additional-seats-v2-EUR-Monthly',
    },
    yearly: {
      priceId: 'treble-pro-ultimate-EUR-Yearly',
      addonId: 'treble-pro-ultimate-additional-seats-v2-EUR-Yearly',
    },
  },
  enterprise: {},
  tokens: {
    10: 'treble-pro-token-bundle-10-v2-EUR',
    25: 'treble-pro-token-bundle-25-v2-EUR',
    50: 'treble-pro-token-bundle-50-v2-EUR',
    volume: 'token-bundle-volume-EUR',
    tiers: 'token-bundle-tiers-EUR',
  },
};

export type SUBSCRIPTION_CONTENT = {
  name: string;
  description: string;
  price: string;
  included: string[];
  access: string[];
  highlight?: string;
  tokenBundlePricing?: string[];
};
