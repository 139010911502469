import { SyntheticEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useBaseContext } from '@/context/BaseContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { ShareProjectButton } from '@/components/Shared/ShareProjectButton/ShareProjectButton';
import { TextField } from '@/components/Shared/TextField';
import {
  PageLayout,
  ProjectBreadcrumbs,
  RecentSimulationsHeader,
  RightSidebar,
  TrblSearchIcon,
  TrblSharedProjectIcon,
} from '@/components';
import { ViewTypeSwitch } from '@/components/DashboardHeader/ViewTypeSwitch';
import { PopupProjectCard, PopupSpaceCard, SpaceCard } from '@/components/ProjectsView/components';
import { ManageAccessPopup } from '@/components/ProjectsView/components/ManageAccessPopup';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import { useDeleteProject, useDeleteSpace, useGetProjectById, useGetSpacesByProjectId } from '@/hooks';

import { IActions, ProjectAndUsersDto, SpaceDetailsDto, SpaceDto } from '@/types';

import classes from './styles.module.scss';

let delayTimer: ReturnType<typeof setTimeout>;

export const ProjectOverview = () => {
  const params = useParams();

  const {
    state: { userInfo },
  } = useBaseContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteSpace = useDeleteSpace();
  const deleteProject = useDeleteProject();

  const projectId = params.id as string;

  const { data: project } = useGetProjectById(projectId || '');
  const { data: spaces } = useGetSpacesByProjectId(projectId);
  const [selectedSpace, setSelectedSpace] = useState<SpaceDetailsDto>();
  const [selectedProject, setSelectedProject] = useState<ProjectAndUsersDto>();

  const [showSpacePopup, setShowSpacePopup] = useState(false);
  const [showProjectPopup, setShowProjectPopup] = useState(false);
  const [showDeleteSpaceDialog, setShowDeleteSpaceDialog] = useState(false);
  const [showDeleteProjectDialog, setShowDeleteProjectDialog] = useState(false);

  const [searchString, setSearchString] = useState('');
  const [spaceSearchString, setSpaceSearchString] = useState('');
  const [viewType, setViewType] = useState('cards');

  const [showMembersPopup, setShowMembersPopup] = useState(false);

  const openMembers = (event: SyntheticEvent) => {
    event.stopPropagation();
    setShowMembersPopup(true);
  };

  const closeManageAccessPopup = (projectUpdated?: boolean) => {
    setShowMembersPopup(false);

    if (projectUpdated) {
      queryClient.invalidateQueries(['projectById', projectId]);
    }
  };

  const getSpaceActions = (space: SpaceDetailsDto): IActions[] => [
    {
      value: 'Edit',
      onClick: () => {
        setSelectedSpace(space);
        setShowSpacePopup(true);
      },
      key: 'edit-space',
    },
    {
      value: 'Delete',
      onClick: () => {
        setSelectedSpace(space);
        setShowDeleteSpaceDialog(true);
      },
      key: 'delete-space',
    },
  ];

  const deleteSpaceFunc = (id: string, space: SpaceDetailsDto) => {
    deleteSpace.mutate(
      { spaceId: id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['spaces-for-project', space.projectId]);
          toast.info("'" + space.name + "' deleted");
        },
        onError: () => {
          toast.error('An error occurred while deleting Space');
        },
      }
    );
  };

  const updateSpaceCard = (updatedSpace: SpaceDto) => {
    setShowSpacePopup(false);
    queryClient.invalidateQueries(['spaces-for-project', updatedSpace.projectId]);
  };

  const getProjectActions = (project: ProjectAndUsersDto): IActions[] => {
    const actions = [
      {
        value: 'Edit',
        onClick: () => {
          setSelectedProject(project);
          setShowProjectPopup(true);
        },
        key: 'edit-project',
      },
    ];

    if (userInfo!.roles.includes('Admin') || project.createdBy === userInfo!.id) {
      actions.push({
        value: 'Delete',
        onClick: () => {
          setSelectedProject(project);
          setShowDeleteProjectDialog(true);
        },
        key: 'delete-project',
      });
    }

    return actions;
  };

  const onDeleteProject = (project: ProjectAndUsersDto) => {
    deleteProject.mutate(project.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects']);
        navigate('/');
        toast.info("'" + project.name + "' deleted");
      },
      onError: () => {
        toast.error('An error occurred while deleting Project');
      },
    });
  };

  const handleProjectPopupClosed = () => {
    setShowProjectPopup(false);
  };

  const handleProjectUpdated = () => {
    handleProjectPopupClosed();
    queryClient.invalidateQueries(['projectById', projectId]);
  };

  const updateSearchString = (input: string) => {
    setSearchString(input);

    // delay search so we don't run the filtering function on every keystroke
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      setSpaceSearchString(input);
    }, 200);
  };

  const filterSpace = (name: string) => {
    // if no search string or there is a search string and it's included in the space name, then show the space
    if (searchString == '' || name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }

    return false;
  };

  return (
    <PageLayout
      extraHeader={
        project && (
          <Stack direction="row" justifyContent="space-between" marginRight="40px">
            <ProjectBreadcrumbs
              projectUsers={project.projectUsers}
              loggedInUserId={userInfo?.id}
              creatorId={project.createdBy}
              items={[
                {
                  text: project?.name,
                  icon:
                    project.createdBy !== userInfo?.id ? (
                      <TrblTooltip title="Project shared with you">
                        <span>
                          <TrblSharedProjectIcon width="14" height="14" fill="#dadada" />
                        </span>
                      </TrblTooltip>
                    ) : undefined,
                },
              ]}
              action={
                <Stack direction="row" gap="12px" margin="0 -2px 0 -14px">
                  <TrblActionsMenu
                    classNames={classes.project_menu}
                    actions={getProjectActions(project)}
                    id={project.id}
                    title="View project actions"
                    size="medium"
                  />

                  <ShareProjectButton onClick={openMembers} size="22" />
                </Stack>
              }
            />

            <Stack direction="row" gap="16px" position="relative">
              <span className={classes.search_icon}>
                <TrblSearchIcon />
              </span>
              <TextField
                value={searchString}
                className={classes.search_input}
                placeholder="Search in project"
                onChange={updateSearchString}
              />

              <ViewTypeSwitch viewType={viewType} setViewType={setViewType} />
            </Stack>
          </Stack>
        )
      }
      sidepanel={<RightSidebar />}
      sidepanelExtraHeader={<RecentSimulationsHeader showBorder={true} />}>
      {project && spaces && (
        <>
          <div id={project.id} className={`${classes.project_overview_container}`}>
            <div
              className={`${classes.project_spaces}  ${
                viewType === 'cards' ? classes.cards_container : classes.list_container
              } `}>
              {spaces?.length
                ? spaces
                    .filter((space) => filterSpace(space.name))
                    .map((space) => (
                      <SpaceCard
                        viewType={viewType}
                        key={space.id}
                        id={space.id}
                        modelCount={space.modelCount}
                        title={space.name}
                        spaceActions={getSpaceActions(space)}
                        projectSearchString={spaceSearchString}
                      />
                    ))
                : null}
            </div>
          </div>
          <PopupSpaceCard
            showPopup={selectedSpace != undefined && showSpacePopup}
            onClose={() => setShowSpacePopup(false)}
            onUpdate={(reponse) => updateSpaceCard(reponse)}
            name={selectedSpace?.name ?? ''}
            description={selectedSpace?.description ?? ''}
            id={selectedSpace?.id ?? ''}
          />
          <PopupProjectCard
            showPopup={selectedProject != undefined && showProjectPopup}
            onClose={handleProjectPopupClosed}
            onUpdate={handleProjectUpdated}
            id={selectedProject?.id ?? ''}
            name={selectedProject?.name ?? ''}
            description={selectedProject?.description ?? ''}
          />
          {selectedSpace != undefined && showDeleteSpaceDialog && (
            <ConfirmationDialog
              title={`Delete space`}
              message={() => (
                <span>
                  Are you sure you want to delete <b>{selectedSpace.name}</b> ?
                </span>
              )}
              onConfirm={() => {
                deleteSpaceFunc(selectedSpace.id, selectedSpace);
                setShowDeleteSpaceDialog(false);
              }}
              onCancel={() => setShowDeleteSpaceDialog(false)}
            />
          )}
          {selectedProject! != undefined && showDeleteProjectDialog && (
            <ConfirmationDialog
              title={`Delete project`}
              message={() => (
                <span>
                  Are you sure you want to delete <b>{selectedProject.name}</b> ?
                </span>
              )}
              onConfirm={() => {
                if (selectedProject) {
                  onDeleteProject(selectedProject);
                  setShowDeleteProjectDialog(false);
                }
              }}
              onCancel={() => setShowDeleteProjectDialog(false)}
            />
          )}
          {showMembersPopup && <ManageAccessPopup onClose={closeManageAccessPopup} project={project} />}
        </>
      )}
    </PageLayout>
  );
};
