import { useEffect, useState } from 'react';

import { Text } from '@/components/Shared/Text';
import { getSPLValues } from '@/components/SourceDefinition/utils';
import { TrblNumberInput } from '../Shared/NumberInput/TrblNumberInput';
import { TableDivider } from './TableDivider';

import { aWeighting, DEFAULT_SPL_VALUE, DEFAULT_SPL_VALUES, ZERO_EQ_VALUES } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { Source } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const FrequenciesTableInput = ({
  source,
  sourceDefinitionJson,
  onOverallGainChange,
  onEQChange,
  setEqData,
  readonly = false,
}: {
  source: Source;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceDefinitionJson: any;
  onOverallGainChange: (value: number) => void;
  onEQChange: (targetCoefficients: number[]) => void;
  setEqData: (value: number[] | null) => void;
  readonly: boolean;
}) => {
  const splValues = getSPLValues(sourceDefinitionJson);
  const [overallGain, setOverallGain] = useState<number | undefined>(source.params.overallGain ?? 0);
  const [eq, setEQ] = useState<(number | undefined)[]>(source.params.eq ?? ZERO_EQ_VALUES);

  const fontType = 'regular-11px';
  const marginLeft = 12;
  const textAlign = 'center';
  const width = 40;
  const widthAll = 90;

  useEffect(() => {
    setOverallGain(source.params.overallGain ?? 0);
    setEQ(source.params.eq ?? ZERO_EQ_VALUES);
    if (!source.params.overallGain && !source.params.eq) {
      setEqData(null);
    }
  }, [source]);

  const calculateSPLValue = (spl: number, i: number) => {
    const thisEQ = source.params.eq ? source.params.eq[i] : 0;
    const newEQ = Number(spl) + (source.params.overallGain ?? 0) + thisEQ;
    return roundFloat(newEQ, 1);
  };
  const calculateTotalSPLWithGainAndEQ = (values: number[], isAWeighting = false) => {
    let sum = 0;
    const sourceGain = source.params.overallGain ?? 0;
    const sourceEQ = source.params.eq ?? ZERO_EQ_VALUES;

    for (let i = 0; i < values.length; i++) {
      const val = Number(values[i]) + sourceGain + sourceEQ[i];

      if (!isNaN(val)) {
        if (!isAWeighting) sum += Math.pow(10, val / 10);
        else sum += Math.pow(10, (val + aWeighting[i]) / 10);
      }
    }
    sum = 10 * Math.log10(sum);

    return roundFloat(sum, 1);
  };

  const handleChangeEQ = (value: number | undefined, index: number) => {
    const newEQ = [...eq];
    newEQ[index] = value;
    setEQ(newEQ);
  };

  const handleUpdateEQ = (value: number | undefined, index: number) => {
    // if value has not changed, then don't update object
    if (source.params.eq && source.params.eq[index] === value) return;

    const newEQ = [...eq] as number[];
    if (value !== undefined) {
      newEQ[index] = value;
    }
    onEQChange(newEQ);
    updateEqData();
  };

  const handleOverallGainChange = (value: number) => {
    // if value has not changed, then don't update object
    if (source.params.overallGain === value) return;

    onOverallGainChange(value);
    updateEqData();
  };

  const updateEqData = () => {
    const eqData = [];
    let isZero = true;
    for (let i = 0; i < eq.length; i++) {
      let eqPlusGain = (eq[i] || 0) + (overallGain || 0);
      eqPlusGain = roundFloat(eqPlusGain, 1);
      eqData.push(eqPlusGain);

      if (eqPlusGain !== 0) isZero = false;
    }
    // Set eqData as null if all EQ and Gain is zero
    // else set eqData as combined EQ and Gain
    if (isZero) setEqData(null);
    else setEqData(eqData);
  };

  useEffect(() => {
    updateEqData();
  }, []);

  return (
    <div className={styles['source-details-table']}>
      <div className={styles['row-container']}>
        <Text type={fontType} className={styles['label']}>
          Equalizer (dB)
        </Text>

        <div className={styles['inputs-container']}>
          {splValues.map((spl, i) => (
            <span style={{ marginLeft, width, display: 'flex', justifyContent: 'center' }} key={i}>
              <TrblNumberInput
                value={eq[i]}
                onChange={(value) => {
                  handleChangeEQ(value, i);
                }}
                onBlur={(value) => {
                  handleUpdateEQ(value, i);
                }}
                step={0.1}
                min={-100}
                max={100}
                decimals={1}
                blurOnStep={false}
                alignment="center"
                style={{ width: 47 }}
                readOnly={readonly}
              />
            </span>
          ))}
          <span style={{ marginLeft: '8px', width: widthAll, display: 'flex', justifyContent: 'center' }}>
            <TrblNumberInput
              value={overallGain}
              onChange={setOverallGain}
              onBlur={(value) => {
                if (value !== undefined) handleOverallGainChange(value);
              }}
              step={0.1}
              min={-100}
              max={100}
              decimals={1}
              blurOnStep={false}
              alignment="center"
              readOnly={readonly}
            />
          </span>
        </div>
      </div>

      <TableDivider double></TableDivider>
      <div className={styles['row-container']}>
        <Text type={fontType} className={styles['label']}>
          = SPL on axis at 1m (dB)
        </Text>
        <div className={styles['values-container']}>
          {splValues.map((spl, i) => (
            <Text
              key={`spl_${i}`}
              type={fontType}
              numberFontStyleEnabled={true}
              color="#dadada"
              style={{ width, marginLeft, textAlign }}>
              {calculateSPLValue(spl, i) ?? '-'}
            </Text>
          ))}

          <Text
            type={fontType}
            numberFontStyleEnabled={true}
            color="#dadada"
            style={{ marginLeft, textAlign, width: widthAll, lineHeight: 0.5 }}>
            {calculateTotalSPLWithGainAndEQ(splValues)} / {calculateTotalSPLWithGainAndEQ(splValues, true)}{' '}
            <sup>(A)</sup>
          </Text>
        </div>
      </div>

      {source && source.params.correctIrByOnAxisSpl === false && (
        <div className={styles['row-container']} style={{ marginTop: 8 }}>
          <Text type={fontType} className={styles['label']}>
            = Impulse response
          </Text>
          <div className={styles['values-container']}>
            {splValues.map((_, i) => (
              <Text
                key={`ir_${i}`}
                type={fontType}
                numberFontStyleEnabled={true}
                color="#dadada"
                style={{ width, marginLeft, textAlign }}>
                {calculateSPLValue(DEFAULT_SPL_VALUE, i) ?? '-'}
              </Text>
            ))}

            <Text
              type={fontType}
              numberFontStyleEnabled={true}
              color="#dadada"
              style={{ marginLeft, textAlign, width: widthAll, lineHeight: 0.5 }}>
              {calculateTotalSPLWithGainAndEQ(DEFAULT_SPL_VALUES)} /{' '}
              {calculateTotalSPLWithGainAndEQ(DEFAULT_SPL_VALUES, true)} <sup>(A)</sup>
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
