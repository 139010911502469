import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { usePresetContext } from '@/components/Auralizer/PresetContext';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { LoadPresetsLibrary } from './LoadPresetsLibrary';

import { useGetSharedAuralizations, useLoadPreset } from '../hooks';

const popupTitle = 'Load auralization preset';
export const LoadPresetsPopup = ({
  showPopup,
  setShowPopup,
}: {
  showPopup: boolean;
  setShowPopup: (shouldShowPopup: boolean) => void;
}) => {
  const { availablePresets } = usePresetContext();
  const { highlightedItemId } = useLibraryPanelContext();

  const loadPreset = useLoadPreset();

  const onLoadPreset = () => {
    const selectedPreset = availablePresets.find((preset) => preset.id === highlightedItemId);
    if (selectedPreset) {
      loadPreset(selectedPreset);
      setShowPopup(false);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  // TODO: Remove when we have this information on the preset object itself
  const { data = [], isLoading: isLoadingSharedPresets } = useGetSharedAuralizations();
  const sharedPresets = data.map((auralization) => auralization.auralizationPresetId);

  return (
    <TrblPopup
      width={800}
      hideBackdrop={false}
      aria-labelledby={popupTitle}
      sx={{ fontSize: '12px' }}
      open={showPopup}
      onClose={handleClose}>
      <TrblPopupTitle onClose={handleClose}>{popupTitle}</TrblPopupTitle>
      <TrblPopupContent>
        {availablePresets && !isLoadingSharedPresets && (
          <LoadPresetsLibrary
            sharedPresets={sharedPresets}
            availablePresets={availablePresets}
            setShowPopup={setShowPopup}
          />
        )}
      </TrblPopupContent>
      <TrblPopupActions>
        <Box component="div" display="flex" width="100%" justifyContent={'flex-end'}>
          <PrimaryButton disabled={!highlightedItemId} width={'fit-content'} label="Load" onClick={onLoadPreset} />
        </Box>
      </TrblPopupActions>
    </TrblPopup>
  );
};
