import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

const removeMember = async (projectId: string, userId: string): Promise<void> => {
  await axios.delete(`/api/v2/Project/RemoveMember`, {
    params: {
      projectId,
      userId,
    },
  });
};

export const useRemoveMemberFromProject = () => {
  return useMutation(
    async ({ projectId, userId }: { projectId: string; userId: string }) => await removeMember(projectId, userId),
    {
      onError: () => {
        toast.error('An error occured while removing member from project');
      },
    }
  );
};
