export const TrblLockIcon = () => (
  <svg width="11.667" height="14" viewBox="0 0 11.667 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.771 5.542h0.438a1.167 1.167 0 0 1 1.167 1.167V12.834a1.167 1.167 0 0 1 -1.167 1.167h-8.75a1.167 1.167 0 0 1 -1.167 -1.167V6.709a1.167 1.167 0 0 1 1.167 -1.167h6.563a0.292 0.292 0 0 0 0.292 -0.292V3.938a2.48 2.48 0 0 0 -4.855 -0.713 0.729 0.729 0 0 1 -1.397 -0.418A3.938 3.938 0 0 1 9.771 3.938V5.542Zm-4.144 2.346A1.167 1.167 0 0 0 5.25 10.047V11.375a0.583 0.583 0 1 0 1.167 0v-1.328a1.167 1.167 0 0 0 -0.789 -2.158Z"
      fill="#DADADA"
    />
  </svg>
);
