import { useQuery } from '@tanstack/react-query';

import { Simulation } from '@/types';

import axios from '@/axios';

export const getSimulationById = async (simulationId: string): Promise<Simulation> => {
  const { data } = await axios.get(`/api/Simulation/GetById`, {
    params: {
      id: simulationId,
    },
  });

  return data;
};

export const useGetSimulationById = (simulationId: string, refetch = true) => {
  return useQuery<Simulation>(['simulation', simulationId], () => getSimulationById(simulationId), {
    enabled: !!simulationId && simulationId.length > 0 && refetch,
    refetchOnWindowFocus: false,
  });
};
