import { ReactElement } from 'react';

import './styles.scss';

// This component should probably be renamed to something
// like "subcategory list item" because it is only used for the
// "Material library" UI/UX and is not needed if we only have 1 long list
export const LibraryListItem = ({
  categoryTitle,
  children,
  showTitle = true,
}: {
  categoryTitle: string;
  children: ReactElement | ReactElement[];
  showTitle?: boolean;
}) => {
  return (
    <li tabIndex={-1}>
      {showTitle && <p className="library-category-title"> {categoryTitle}</p>}
      {/* This className is used for keyboard navigation, not styling */}
      <ul className="library-category">{children}</ul>
    </li>
  );
};
