import { useNavigate } from 'react-router-dom';

import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

/** Hook to share logic for when closing the Auralizer */
export const useCloseAuralizer = () => {
  const navigate = useNavigate();

  const {
    simulationState: { originalSim, selectedSimulation },
    dispatch: simDispatch,
  } = useSimulationContext();
  const { dispatch: modelDispatch } = useModelContext();

  const { dispatch: libDispatch } = useLibraryPanelContext();

  const closeAuralizer = () => {
    // if we are closing the Auralizer and the simulation we have selected
    // is not the ORIGINAL simulation we opened the Auralizer with
    // we change the model to the original model
    if (originalSim && originalSim?.id !== selectedSimulation?.id) {
      modelDispatch({
        type: ModelActionType.SET_CURRENT_MODEL_ID,
        modelId: originalSim.modelId,
      });
    }

    if (originalSim) {
      // Re-render the selected simulation
      simDispatch({
        type: SimActionType.SET_SELECTED_SIMULATION,
        simulation: { ...originalSim },
      });
    }

    // Set the original simulation to null so it doesn't persist outside of the auralizer
    simDispatch({
      type: SimActionType.SET_ORIGINAL_SIMULATION,
      simulation: null,
    });

    libDispatch({
      type: LibActionType.SET_HIGHLIGHTED_ITEM,
      highlightedItemId: null,
    });
    // route out of the Auralizer
    if (originalSim) {
      navigate(`/editor?mid=${originalSim.modelId}&sid=${originalSim.id}`);
    }
  };
  return closeAuralizer;
};
