import { Object3DNode, Properties, ReactThreeFiber } from '@react-three/fiber';
import THREE, { Color, Raycaster, Scene, Vector3 } from 'three';

export const DEFAULT_RAYCASTER_SETTINGS: Partial<Raycaster> = {
  params: {
    Points: { threshold: 0.2 },
    Line: { threshold: 0.1 },
  },
};

export const DEFAULT_SCENE_SETTINGS: Partial<Object3DNode<Scene, typeof Scene>> = {
  background: new Color(0x555657),
};

export const DEFAULT_GL_SETTINGS: Partial<Properties<THREE.WebGLRenderer> | THREE.WebGLRendererParameters> = {
  alpha: true,
};

export const DEFAULT_RESIZE_SETTINGS = { debounce: 0, offsetSize: false };

export const DEFAULT_LAYER_COLOR = '#ffffff';

// coordinates to create a default viewing angle for the viewport
export const X_ANGLE = 16;
export const Y_ANGLE = 16;
export const Z_ANGLE = 16;
export const DEFAULT_CAMERA_POSITION = new Vector3(X_ANGLE, Y_ANGLE, Z_ANGLE);
export const VIEWPORT_CENTER = new Vector3(0, 0, 0);

export const DEFAULT_CAMERA_SETTINGS: Partial<
  ReactThreeFiber.Object3DNode<THREE.Camera, typeof THREE.Camera> &
    ReactThreeFiber.Object3DNode<THREE.PerspectiveCamera, typeof THREE.PerspectiveCamera> &
    ReactThreeFiber.Object3DNode<THREE.OrthographicCamera, typeof THREE.OrthographicCamera>
> = {
  name: 'Camera',
  position: DEFAULT_CAMERA_POSITION,
  up: new Vector3(0, 0, 1),
  fov: 50,
  /** The "near" value might need to be adjusted. The idea is to have it low enough to make sure that
   * when standing in a receiver position as close as possible to a wall, that while rotating the camera you don't see through the wall
   */
  near: 0.08,
  far: 1000,
};

export const STI_SCALE_RANGE = [0, 0.3, 0.45, 0.6, 0.75, 1.0];
