import { useMutation } from '@tanstack/react-query';

import axios from '@/axios';

type UpdateSpaceRequest = {
  id: string;
  name: string;
  description: string;
};

const updateSpace = async (request: UpdateSpaceRequest) => {
  const { data } = await axios.patch(`/api/Space/UpdateSpace`, request);
  return data;
};

export const useUpdateSpace = () => {
  return useMutation(async (data: UpdateSpaceRequest) => await updateSpace(data));
};
