import { FC } from 'react';
import { Vector3 } from 'three';

import { AxisHelperArrows } from './AxisHelperArrows';
import { AxisHelperCube } from './AxisHelperCube';

type AxisHelperProps = {
  size?: number;
  position?: Vector3;
};

export const AxisHelper: FC<AxisHelperProps> = ({ size = 30, position = new Vector3(0, 0, -3) }) => {
  return (
    <group position={position}>
      <AxisHelperCube size={size} />
      <AxisHelperArrows offset={new Vector3(-size / 2, -size / 2, -size / 2)} />
    </group>
  );
};
