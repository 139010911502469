import { TrblTooltip } from '@/components/Shared';
import { TrblTruncate } from '@/components/SoundLibrary/TrblTruncate';
import { Text } from '../../../Shared/Text';
import { usePresetContext } from '../../PresetContext';
import { PresetDetails } from './PresetDetails';

import '../../style.scss';

export const PresetInfo = () => {
  const { selectedPreset, selectedPresetEdited } = usePresetContext();

  return (
    <div className={`preset-name-container`}>
      <div className={`preset-name`}>
        {selectedPreset && (
          <TrblTooltip title={selectedPreset.name.length > 50 ? selectedPreset.name : ''}>
            <span>
              <TrblTruncate text={selectedPreset.name} strLen={50} />
              {selectedPresetEdited && (
                <Text
                  type="medium-12px"
                  style={{
                    fontStyle: 'italic',
                    visibility: selectedPresetEdited ? 'visible' : 'hidden',
                    marginLeft: '4px',
                  }}>
                  [Edited]
                </Text>
              )}
            </span>
          </TrblTooltip>
        )}
      </div>
      <div className="preset-description-container">{selectedPreset && <PresetDetails />}</div>
    </div>
  );
};
