import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

const MAX_POLL = 200;

let timedOut = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getGeometryCheckTask = async (geometryCheckTaskId: string | null, shouldPoll: boolean): Promise<any> => {
  if (!timedOut && shouldPoll && geometryCheckTaskId) {
    const { data } = await axios.get(`/api/GeometryCheck/GetGeometryCheckTask`, {
      params: {
        geometryCheckTaskId,
      },
    });

    return data;
  } else {
    if (timedOut) {
      const data = { task: { status: 'TimedOut' } };
      return data;
    } else {
      return { task: { status: 'WeirdError' } };
    }
  }
};

export const useGetGeometryCheckTask = (geometryCheckTaskId: string | null, shouldRefetch: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, boolean>(
    ['geometry-check-task-id', geometryCheckTaskId],
    () => getGeometryCheckTask(geometryCheckTaskId, shouldRefetch),
    {
      enabled: !!geometryCheckTaskId && shouldRefetch,
      refetchOnWindowFocus: false,
      // Refetch the data every 2 second
      refetchInterval: (data, query) => {
        if (query.state.dataUpdateCount > MAX_POLL) {
          timedOut = true;
        } else if (
          data?.task.status === 'Completed' ||
          data?.task.status === 'Error' ||
          data?.task.status === 'TimedOut'
        ) {
          return false;
        }

        return 2000;
      },
      onError: () => {
        toast.error('Error occurred while fetching geometry task');
      },
    }
  );
};
